import { Component, Input, OnInit } from '@angular/core';
import { DataSet, Paris, Repository } from '@microsoft/paris';
import { map } from 'rxjs/operators';
import { SuppressionRule, SuppressionRuleHistoryItem } from '@wcd/domain';
import { CommentModel } from '../../../comments/models/comment.model';
import { DialogsService } from '../../../dialogs/services/dialogs.service';
import { TrackingEventType } from '../../../insights/models/tracking-event-type.enum';
import { AppInsightsService } from '../../../insights/services/app-insights.service';
import { SuppressionRulesBackendService } from '../services/suppression-rules.backend.service';

@Component({
	selector: 'suppression-rule-comments',
	template: `
		<div
			class="wcd-full-height wcd-flex-vertical"
			data-track-component="Suppression Rule comments Panel"
			data-track-component-type="Side Panel"
		>
			<header
				class="wcd-flex-none wcd-padding-vertical wcd-padding-large-horizontal wcd-flex-horizontal"
			>
				<div class="page-header-icon wcd-flex-none">
					<wcd-shared-icon class="round-icon color-box-gray-200" iconName="suppressionRule">
					</wcd-shared-icon>
				</div>
				<div class="wcd-flex-1 wcd-flex-center-vertical">
					<h3 class="side-panel-title">Comments and history</h3>
				</div>
			</header>
			<div class="wcd-flex-1 wcd-scroll-vertical wcd-padding-vertical wcd-padding-large-horizontal">
				<ng-container *ngIf="isLoading || isError; else commentsList">
					<div class="wcd-full-height loading-overlay">
						<div class="error-message" *ngIf="error">
							<wcd-shared-icon [iconName]="'error'"> </wcd-shared-icon>
							Error loading history
						</div>
						<div *ngIf="isLoading">
							<i class="large-loader-icon"></i>
							Loading history...
						</div>
					</div>
				</ng-container>
				<ng-template #commentsList>
					<editable-comments-list
						[comments]="history"
						(save)="addCommentToRule($event)"
						[rbac]="{ permissions: ['securitySettings'], state: 'disabled' }"
						[isDisabled]="rule.isReadOnly"
						[readonly]="rule.isReadOnly"
						[isSaving]="isSavingComment"
					></editable-comments-list>
				</ng-template>
			</div>
		</div>
	`,
})
export class SuppressionRuleCommentsComponent implements OnInit {
	@Input() rule: SuppressionRule;
	isSavingComment: boolean = false;
	isLoading: boolean = false;
	isError: any;
	ruleHistoryItemsRepo: Repository<SuppressionRuleHistoryItem>;
	history: Array<any>;

	constructor(
		private paris: Paris,
		private suppressionRulesBackendService: SuppressionRulesBackendService,
		private dialogsService: DialogsService,
		private appInsightsService: AppInsightsService
	) {
		this.ruleHistoryItemsRepo = paris.getRepository(SuppressionRuleHistoryItem);
	}

	ngOnInit() {
		this.isLoading = true;
		this.setCommentsHistory();
	}

	setCommentsHistory(): void {
		this.ruleHistoryItemsRepo
			.query({ where: { id: this.rule.id } })
			.pipe(map((dataSet: DataSet<SuppressionRuleHistoryItem>) => dataSet.items))
			.subscribe(
				items => {
					this.history = items.map((ruleHistoryItem: SuppressionRuleHistoryItem) => {
						return new CommentModel({
							id: ruleHistoryItem.id,
							timestamp: ruleHistoryItem.timestamp,
							user: ruleHistoryItem.userName,
							message: ruleHistoryItem.message,
							icon: ruleHistoryItem.type ? ruleHistoryItem.type.icon : 'Comment',
						});
					});
					this.isLoading = false;
					this.isError = false;
				},
				error => {
					this.isLoading = false;
					this.isError = true;
				}
			);
	}

	addCommentToRule(comment: string): void {
		this.isSavingComment = true;
		const commentForSave = this.getCommentBackendData(comment);

		this.suppressionRulesBackendService.addCommentToRule(commentForSave).subscribe(
			() => {
				this.isSavingComment = false;
				this.appInsightsService.track({
					type: TrackingEventType.EntitySave,
					id: 'Suppression Rule',
				});
				this.dialogsService.showSuccessSnackbar({
					text: 'Comment saved for suppression rule',
				});

				this.setCommentsHistory();
			},
			error => {
				this.isSavingComment = false;
			}
		);
	}

	private getCommentBackendData(comment: string): any {
		return {
			ContextObjId: this.rule.id,
			NewValue: comment,
		};
	}
}
