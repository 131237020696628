import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { EMPTY, Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { AirsEmail, AirsEmailSubmission, AirsEntity, AirsEntityType, HuntingContext } from '@wcd/domain';
import { Feature, FeaturesService } from '@wcd/config';
import { QueryService } from '@wcd/hunting';
import { TimeRangeId } from '@wcd/date-time-picker';
import { ItemActionModelConfig } from '../../../dataviews/models/item-action.model';
import { GoHuntService } from '../../../hunting-go-hunt/services/go-hunt.service';
import { GoHuntActionOptions } from '../../../hunting-go-hunt/services/go-hunt-query-builder';
import { tenantContextCache } from '@wcd/auth';
import { sccHostService } from '@wcd/scc-interface';

@Injectable()
export class GoHuntAirsEntityService {
	constructor(
		private featuresService: FeaturesService,
		private goHuntService: GoHuntService,
		private queryService: QueryService,
		private readonly router: Router
	) {}

	getGoHuntAirsEntityObservable(
		entity: AirsEntity,
		options?: GoHuntActionOptions
	): Observable<ItemActionModelConfig> {
		if (
			sccHostService.isSCC &&
			(!tenantContextCache.hasMtpConsent || !tenantContextCache.appConfig.IsMdatpActive)
		) {
			return EMPTY;
		}

		if (
			!this.featuresService.isAnyEnabled([
				Feature.EvidenceGoHuntActionMtp,
				Feature.EvidenceGoHuntAction,
			])
		) {
			return EMPTY;
		}

		const isEmailEntity =
			entity.type &&
			(entity.type.id === AirsEntityType.MailMessage ||
				entity.type.id === AirsEntityType.SubmissionMail);

		const isInvestigationContext = options && options.huntingContext === HuntingContext.Investigation;

		// BE support mailMessage & SubmissionMail for investigation context,
		// hence, no need to use the patch of creating the query in FE
		// adding 'ExternalMailHuntingQuery' flag till the BE code will be in production
		if (isEmailEntity) {
			if (isInvestigationContext && !this.featuresService.isEnabled(Feature.ExternalMailHuntingQuery)) {
				return this.getMailMessageGoHuntObservable(<AirsEmail | AirsEmailSubmission>entity);
			} else if (!isInvestigationContext) {
				return this.getMailMessageGoHuntObservable(<AirsEmail | AirsEmailSubmission>entity);
			}
		}

		return this.goHuntService.getGoHuntActionObservable(entity, entity.type.id, options);
	}

	private getMailMessageGoHuntObservable(entity: AirsEmail): Observable<ItemActionModelConfig> {
		// build query for mailMessage entity type
		const subject = entity.subject || '';
		// Need to escape double quotes in email subject so we will not get syntx error in the go hunt query
		const escapedSubject = subject.replace(/(")/g, '\\$1');

		const senderFromAddress = entity.sender || '';
		const mailMessageQuery = `EmailEvents | where (Subject contains "${
			escapedSubject || ''
		}" or SenderFromAddress == "${senderFromAddress || ''}") | limit 100`;

		return this.queryService.getDeepLinkToQuery(mailMessageQuery).pipe(
			map((urlTreeData) => {
				urlTreeData.queryParams['runQuery'] = true;
				urlTreeData.queryParams['timeRangeId'] = TimeRangeId.month;
				urlTreeData.queryParams['tid'] = this.getTenantIdUrlParam();
				return {
					id: 'evidenceGoHunt',
					nameKey: 'entities_panel_action_goHunt_buttonTitle',
					icon: 'hunting',
					href: this.router.serializeUrl(urlTreeData),
					openLinkInNewTab: true,
				};
			})
		);
	}
	private getTenantIdUrlParam() : string  {
		const urlParams = new URLSearchParams(window.location.search);
		return urlParams.get('tid');
	};
}
