import { ApiCall, ApiCallModel } from '@microsoft/paris';
import { AssessmentJob } from '@wcd/domain';
import { TvmAuthenticatedScanSharedEntityConfigurations } from '../authenticatedScanEndPointUtil';

@ApiCall({
	...TvmAuthenticatedScanSharedEntityConfigurations,
	name: 'GET agents list',
	endpoint: 'agents',
	method: 'GET',
	cache: false,
})
export class GetAuthenticatedScanAgentsList extends ApiCallModel<any, void> {}

@ApiCall({
	...TvmAuthenticatedScanSharedEntityConfigurations,
	name: 'Delete authenticated scan',
	endpoint: 'scanDefinition',
	method: 'DELETE',
})
export class DeleteAuthenticatedScan extends ApiCallModel<any, Array<AssessmentJob>> {}
