import { ApiCall, ApiCallModel } from '@microsoft/paris';
import { WcdPortalParisConfig } from '../paris-config.interface';

@ApiCall({
	name: 'Get all the available actor',
	endpoint: 'actors',
	baseUrl: (config: WcdPortalParisConfig) => config.data.serviceUrls.threatIntel,
	cache: {
		time: 1000 * 60,
	},
})
export class GetActorsApiCall extends ApiCallModel<Array<string>> {}
