var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (b.hasOwnProperty(p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
/* tslint:disable:template-click-events-have-key-events */
import { ChangeDetectorRef, OnDestroy, OnInit } from '@angular/core';
import { EntityPanelComponentBase } from '../../../global_entities/components/entity-panels/entity-panel.component.base';
import { AlertHistoryItem, Investigation, Machine, MtpInvestigation, SuppressionRule, Incident, AlertDetectionSourceType, MachineTimelinePrefetchApiCall, OfficeUtils, ServiceSourceType, } from '@wcd/domain';
import { CommentModel } from '../../../comments/models/comment.model';
import { Paris } from '@microsoft/paris';
import { SuppressionRulePanelMode, SuppressionRulesService, } from '../../suppression_rules/services/suppression-rules.service';
import { finalize, map, mergeMap } from 'rxjs/operators';
import { MachinesService } from '../../machines/services/machines.service';
import { RbacService } from '../../../rbac/services/rbac.service';
import { GlobalEntityTypesService } from '../../../global_entities/services/global-entity-types.service';
import { AppContextService, Feature, FeaturesService, FlavorService } from '@wcd/config';
import { EntityDetailsMode } from 'app/global_entities/models/entity-details-mode.enum';
import { MachineEntityDetailsComponent } from '../../../global_entities/components/entity-details/machine.entity-details.component';
import { AlertLinkedByDetailsComponent } from './alert-linked-by-details.component';
import { MessageBarType } from 'office-ui-fabric-react';
import { AlertsService } from '../services/alerts.service';
import { I18nService } from '@wcd/i18n';
import { AppConfigService } from '@wcd/app-config';
import { AppFlavorConfig } from '@wcd/scc-common';
import { MCAS_HOST } from '@wcd/shared';
import { sccHostService } from '@wcd/scc-interface';
import { baseUrlsSettings } from '@wcd/shared';
var CollapsibleID;
(function (CollapsibleID) {
    CollapsibleID["AlertsManage"] = "alert-entity-panel-manage";
    CollapsibleID["Details"] = "alert-entity-panel-details";
    CollapsibleID["Description"] = "alert-entity-panel-description";
    CollapsibleID["RecommendedAction"] = "alert-entity-panel-recommended-action";
    CollapsibleID["Actor"] = "alert-entity-panel-actor";
    CollapsibleID["LinkByIncident"] = "alert-entity-panel-link-by-incident";
    CollapsibleID["ImpactedEntities"] = "alert-entity-panel-impacted-entities";
    CollapsibleID["ImpactedAssets"] = "alert-entity-panel-impacted-assets";
    CollapsibleID["RelatedEvidence"] = "alert-entity-panel-related-evidence";
    CollapsibleID["RelatedMachine"] = "alert-entity-panel-related-machine";
    CollapsibleID["RelatedInvestigation"] = "alert-entity-panel-related-investigation";
    CollapsibleID["AlertComments"] = "alert-entity-panel-comments";
    CollapsibleID["RelatedIncidentAlerts"] = "alert-entity-panel-related-incident-alerts";
})(CollapsibleID || (CollapsibleID = {}));
var investigationMessageBarStyle = {
    icon: {
        lineHeight: '20px',
    },
};
var AUTOMATION_COMMENT_USER = 'Automation';
var AlertEntityPanelComponent = /** @class */ (function (_super) {
    __extends(AlertEntityPanelComponent, _super);
    function AlertEntityPanelComponent(suppressionRulesService, paris, machinesService, rbacService, globalEntityTypesService, appContextService, featuresService, alertsService, changeDetectorRef, i18nService, appConfigService, flavorService) {
        var _this = _super.call(this, changeDetectorRef) || this;
        _this.suppressionRulesService = suppressionRulesService;
        _this.paris = paris;
        _this.machinesService = machinesService;
        _this.rbacService = rbacService;
        _this.globalEntityTypesService = globalEntityTypesService;
        _this.appContextService = appContextService;
        _this.featuresService = featuresService;
        _this.alertsService = alertsService;
        _this.i18nService = i18nService;
        _this.appConfigService = appConfigService;
        _this.investigationMessageBarStyle = investigationMessageBarStyle;
        _this.EntityDetailsMode = EntityDetailsMode;
        _this.MessageBarType = MessageBarType;
        _this.AlertDetectionSourceType = AlertDetectionSourceType;
        _this.isLoadingMachine = false;
        _this.isLoadingInvestigation = false;
        _this.isLoadingHistory = false;
        _this.isLoadingVariables = false;
        _this.loadMachineError = false;
        _this.isMtpEnabled = _this.appContextService.isMtp;
        _this.collapsibleID = CollapsibleID;
        _this.alertPanelExtended = flavorService.isEnabled(AppFlavorConfig.alerts.extraPanelDetails);
        _this.investigationEnabledByLicense = flavorService.isEnabled(AppFlavorConfig.alerts.investigation);
        _this.isMessageBarVisible = flavorService.isEnabled(AppFlavorConfig.alerts.messageBar);
        _this.isTimelineEnabled = flavorService.isEnabled(AppFlavorConfig.devices.isTimelineEnabled);
        return _this;
    }
    Object.defineProperty(AlertEntityPanelComponent.prototype, "alert", {
        get: function () {
            return this.entity;
        },
        enumerable: true,
        configurable: true
    });
    AlertEntityPanelComponent.prototype.ngOnInit = function () {
        var _this = this;
        _super.prototype.ngOnInit.call(this);
        this._onActionSubscription = this.action$.subscribe(function ($event) { return _this.onAction($event.action, $event.data); });
    };
    AlertEntityPanelComponent.prototype.ngOnDestroy = function () {
        this._onActionSubscription && this._onActionSubscription.unsubscribe();
    };
    AlertEntityPanelComponent.prototype.updateMachineDetailsTable = function () {
        if (this.machineDetailsCmp) {
            this.machineDetailsCmp.updateTableView();
        }
    };
    AlertEntityPanelComponent.prototype.updateLinkByTable = function () {
        if (this.alertLinkedByDetailsComponent) {
            this.alertLinkedByDetailsComponent.updateTableView();
        }
    };
    AlertEntityPanelComponent.prototype.onAction = function (action, data) {
        if (action.id === 'alertAssignToMe') {
            var clonedAlert = Object.create(this.alert);
            clonedAlert.assignedTo = data.name;
            this.setEntity(clonedAlert, true);
        }
        else if (action.id === 'alertSuppressionRule') {
            this.suppressionRule = data;
        }
        this.changeDetectorRef.markForCheck();
    };
    AlertEntityPanelComponent.prototype.initEntity = function (alert, isExtendedData) {
        if (isExtendedData === void 0) { isExtendedData = false; }
        _super.prototype.initEntity.call(this, alert);
        this.machine = this.history = this.investigation = this.shortInvestigationId = this.suppressionRule = null;
        var appId = alert.oAuthAlertPageLinkDetails && alert.oAuthAlertPageLinkDetails.oAuthAppId;
        if (appId) {
            var mcasBase = sccHostService.isSCC ? baseUrlsSettings.MCASPortalUrls : MCAS_HOST;
            // TODO: this is a temporary workaround until we have one unified application page in SCC portal
            this.oAuthAlertPageLink = alert.serviceSource.id === ServiceSourceType.Mcas ? mcasBase + "/#/app?oauthAppId=" + appId : "/cloudapps/app-governance?viewid=allApps&objid=" + appId;
        }
        this.changeDetectorRef.markForCheck();
        if (isExtendedData && this.isUserExposed$.value) {
            this.setMachine();
            this.setIncident();
            this.setHistory();
            this.setInvestigation();
            this.setVariables();
            this.setSuppressionRule();
            // Warm up BE with a prefetch call to prepare the device timeline
            if (this.isTimelineEnabled &&
                this.featuresService.isAnyEnabled([
                    Feature.NewTimeLineData,
                    Feature.MachineTimelineAlwaysPrefetch,
                ]) &&
                alert.machine) {
                var alertTime = new Date(alert.machine.lastEventTime || alert.machine.lastSeen);
                var alertFrom = new Date(alertTime);
                alertFrom.setDate(alertTime.getDate() - 7);
                var dataQuery = {
                    where: {
                        machineId: this.alert.machine.id,
                        machineDnsName: this.alert.machine.name,
                        entityType: 'machines',
                        entityId: this.alert.machine.id,
                        fromDate: alertFrom.toISOString(),
                        toDate: alertTime.toISOString(),
                        pageSize: 200,
                        useCyberData: true,
                        generateIdentityEvents: this.featuresService.isEnabled(Feature.MachineTimelineGenerateMdiEvents)
                    },
                };
                this.paris.apiCall(MachineTimelinePrefetchApiCall, dataQuery).subscribe();
            }
        }
    };
    AlertEntityPanelComponent.prototype.setVariables = function () {
        if (!this.options.showVariables) {
            this.variables = null;
            return;
        }
        this.isLoadingVariables = true;
    };
    AlertEntityPanelComponent.prototype.setMachine = function () {
        var _this = this;
        if (this.options.showMachine !== false && this.alert.machine && this.alert.machine.id) {
            this.isLoadingMachine = true;
            this.changeDetectorRef.markForCheck();
            this.loadMachineError = false;
            this.addExtraDataSubscription(this.rbacService
                .isUserExposedToEntity(this.globalEntityTypesService.getEntityType(Machine), this.alert.machine)
                .subscribe(function (userExposureResult) {
                if (userExposureResult.isExposed) {
                    _this.addExtraDataSubscription(_this.paris.getItemById(Machine, _this.alert.machine.id).subscribe(function (machine) {
                        _this.machine = machine;
                        _this.isLoadingMachine = false;
                        _this.changeDetectorRef.markForCheck();
                    }, function () {
                        _this.loadMachineError = true;
                        _this.isLoadingMachine = false;
                        _this.changeDetectorRef.markForCheck();
                    }));
                }
                else {
                    _this.isLoadingMachine = false;
                    _this.changeDetectorRef.markForCheck();
                }
            }, function (error) {
                _this.isLoadingMachine = false;
                _this.loadMachineError = true;
                _this.changeDetectorRef.markForCheck();
            }));
        }
        this.changeDetectorRef.markForCheck();
    };
    AlertEntityPanelComponent.prototype.setIncident = function () {
        var _this = this;
        if (this.alert.incidentId) {
            this.paris
                .getItemById(Incident, this.alert.incidentId)
                .subscribe(function (incident) { return (_this.incident = incident); });
        }
    };
    AlertEntityPanelComponent.prototype.showLoggedOnUsers = function () {
        this.machinesService.showMachineLoggedOnUsers(this.machine, true, {
            noShadow: true,
            hasCloseButton: false,
        });
    };
    AlertEntityPanelComponent.prototype.setInvestigation = function () {
        var _this = this;
        if (this.options.showInvestigation !== false && this.alert.investigationId) {
            this.isLoadingInvestigation = true;
            this.changeDetectorRef.markForCheck();
            var item$ = this.featuresService.isEnabled(Feature.UnifiedExperienceConvergence) &&
                this.alert.isOfficeInvestigation
                ? this.paris.getItemById(MtpInvestigation, this.alert.investigationId, null, {
                    tenantId: this.appConfigService.tenantId,
                })
                : this.paris.getItemById(Investigation, this.alert.investigationId);
            this.addExtraDataSubscription(item$
                .pipe(finalize(function () {
                _this.isLoadingInvestigation = false;
                _this.changeDetectorRef.markForCheck();
            }))
                .subscribe(function (investigation) {
                _this.investigation = investigation;
                _this.shortInvestigationId = OfficeUtils.getShortId(_this.investigation.id);
            }));
        }
        this.changeDetectorRef.markForCheck();
    };
    AlertEntityPanelComponent.prototype.setHistory = function () {
        var _this = this;
        this.changeDetectorRef.markForCheck();
        if (this.options && this.options.showHistory === false) {
            this.isLoadingHistory = false;
            return;
        }
        this.isLoadingHistory = true;
        this.addExtraDataSubscription(this.paris
            .query(AlertHistoryItem, { where: { id: this.alert.id } })
            .pipe(map(function (dataSet) { return dataSet.items; }), mergeMap(function (alertHistoryItems) {
            return _this.getAlertCreatedHistoryItem().pipe(map(function (createItem) {
                return alertHistoryItems.concat([createItem]).map(function (alertHistoryItem) {
                    return new CommentModel({
                        id: alertHistoryItem.id,
                        timestamp: alertHistoryItem.timestamp,
                        user: alertHistoryItem.userName,
                        message: _this.alertsService.getAlertHistoryMessage(alertHistoryItem),
                        icon: alertHistoryItem.type.icon,
                    });
                });
            }));
        }))
            .subscribe(function (historyItems) {
            _this.history = _this.alertPanelExtended
                ? historyItems
                : historyItems.filter(function (item) { return item.user !== AUTOMATION_COMMENT_USER; });
            _this.isLoadingHistory = false;
            _this.changeDetectorRef.markForCheck();
        }, function (error) {
            _this.isLoadingHistory = false;
            _this.changeDetectorRef.markForCheck();
        }));
    };
    AlertEntityPanelComponent.prototype.setSuppressionRule = function () {
        var _this = this;
        if (this.alert.suppressionRuleId) {
            this.addExtraDataSubscription(this.paris
                .getRepository(SuppressionRule)
                .getItemById(this.alert.suppressionRuleId)
                .subscribe(function (suppressionRule) {
                _this.suppressionRule = suppressionRule;
                _this.changeDetectorRef.markForCheck();
            }));
        }
    };
    AlertEntityPanelComponent.prototype.getAlertCreatedHistoryItem = function () {
        var alertHistoryItemsRepo = this.paris.getRepository(AlertHistoryItem);
        return alertHistoryItemsRepo.createItem({
            AuditId: "alert_create_" + this.alert.id,
            Type: 'Create',
            Timestamp: this.alert.firstSeen.toString(),
        });
    };
    AlertEntityPanelComponent.prototype.openSuppressionRulePanel = function () {
        this.suppressionRulesService.showRulePanel(SuppressionRulePanelMode.create, this.suppressionRule ? this.suppressionRule.id : null, this.alert);
    };
    AlertEntityPanelComponent.prototype.onStatusChanged = function (status) {
        var clonedAlert = Object.create(this.alert);
        clonedAlert.status = status;
        this.setEntity(clonedAlert, true);
        if (status.type === 'InProgress') {
            this.runEntityAction('alertAssignToMe');
        }
        this.refreshOnClose(true);
    };
    AlertEntityPanelComponent.prototype.onClassificationChanged = function (classification) {
        var clonedAlert = Object.create(this.alert);
        clonedAlert.classification = classification;
        this.setEntity(clonedAlert, true);
        this.refreshOnClose(true);
    };
    AlertEntityPanelComponent.prototype.onAssigneeChanged = function (assignee) {
        var clonedAlert = Object.create(this.alert);
        clonedAlert.assignedTo = assignee;
        this.setEntity(clonedAlert, true);
        this.refreshOnClose(true);
    };
    AlertEntityPanelComponent.prototype.onDeterminationChanged = function (determination) {
        var clonedAlert = Object.create(this.alert);
        clonedAlert.determination = determination;
        this.setEntity(clonedAlert, true);
        this.refreshOnClose(true);
    };
    AlertEntityPanelComponent.prototype.getAlertRelatedEvidenceCount = function (alert) {
        if (!alert.relatedEvidenceCount) {
            return 0;
        }
        return Object.values(alert.relatedEvidenceCount).reduce(function (acc, entitiesCount) { return acc + (entitiesCount ? entitiesCount : 0); }, 0);
    };
    return AlertEntityPanelComponent;
}(EntityPanelComponentBase));
export { AlertEntityPanelComponent };
