import { Injectable } from '@angular/core';
import { StoreBackendBase } from '../../../data/models/store.backend.base';
import { DownloadService } from '../../../shared/services/download.service';
import { HttpClient } from '@angular/common/http';
import { ServiceUrlsService } from '@wcd/app-config';
import { Observable } from 'rxjs';
import { Tag } from '@wcd/domain';
import { CsvService } from '../../../shared/services/csv.service';
import {Feature, FeaturesService} from "@wcd/config";

const EDIT_INCIDENTS_TAGS_API_ENDPOINT = 'EditIncidentTags';
const ALL_INCIDENT_TAGS_URL = 'incidents/tags';

@Injectable()
export class IncidentsBackendService extends StoreBackendBase {
	constructor(
		http: HttpClient,
		downloadService: DownloadService,
		private readonly csvService: CsvService,
		private serviceUrlsService: ServiceUrlsService,
		private featuresService: FeaturesService
	) {
		super(http, downloadService);
	}

	protected get apiUrl(): string {
		return this.featuresService.isEnabled(Feature.K8SMigrationIncidentTagsKW) ? this.serviceUrlsService.incidentTags : this.serviceUrlsService.threatIntel;
	}

	getIncidentTags(): Observable<IncidentTagsBackendData> {
		return this.get<IncidentTagsBackendData>(ALL_INCIDENT_TAGS_URL);
	}

	updateIncidentTags(incidentIds: ReadonlyArray<string>, tags: ReadonlyArray<Tag>): Observable<any> {
		const userDefinedTags = tags.filter(tag => tag.isEditable !== false);
		return this.post(EDIT_INCIDENTS_TAGS_API_ENDPOINT, {
			incidentIds: incidentIds,
			incidentTags: JSON.stringify(userDefinedTags.map(tag => tag.name)),
		});
	}
}

export interface IncidentTagsBackendData {
	UserDefinedTags: { [index: number]: string };
}
