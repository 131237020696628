import { FabricIconNames } from '@wcd/scc-common';
import { DataviewField } from '@wcd/dataview';
import { FilterValuesChecklistComponent, FilterValuesToggleComponent } from '@wcd/ng-filters';
import { WcdIconNames } from '@wcd/icons';
import { sortBy } from 'lodash-es';
import { CyberEventActionFilterGroupTypeName, CyberEventActionTypeName, FileInstance, Machine, NetworkEndpoint, Process, } from '@wcd/domain';
import { GlobalEntityTypesService } from '../../../global_entities/services/global-entity-types.service';
import { I18nService } from '@wcd/i18n';
import { TagsFieldComponent } from '../../../tags/components/tags-field/tags-field.component';
import { TzDateComponent } from '../../../shared/components/tz-date.component';
import { CyberEventsActionTypesService } from './cyber-events-action-types.service';
import { FlexDirection } from '@wcd/shared';
import { MachineEventMarkComponent } from '../../machines/components/machine-event-mark.component';
import { FeaturesService, Feature } from '@wcd/config';
import { CyberEventDataTypeFilterTypeName } from '@wcd/domain';
var CyberEventsFieldsService = /** @class */ (function () {
    function CyberEventsFieldsService(cyberEventsActionTypesService, i18nService, globalEntityTypesService, featuresService) {
        this.cyberEventsActionTypesService = cyberEventsActionTypesService;
        this.i18nService = i18nService;
        this.globalEntityTypesService = globalEntityTypesService;
        this.featuresService = featuresService;
    }
    Object.defineProperty(CyberEventsFieldsService.prototype, "fields", {
        get: function () {
            var _this = this;
            if (!this._fields) {
                var machineEntityType_1 = this.globalEntityTypesService.getEntityType(Machine);
                this._fields = DataviewField.fromList([
                    {
                        id: 'actionTime',
                        name: this.i18nService.get('events.fields.date.title'),
                        component: {
                            type: TzDateComponent,
                            getProps: function (event) { return ({
                                date: event.actionTime,
                                dateFormat: 'shortWithMillis',
                            }); },
                        },
                        className: 'nowrap wcd-text-overflow-medium',
                        sort: { enabled: false },
                    },
                    {
                        id: 'mark',
                        name: '',
                        component: {
                            type: MachineEventMarkComponent,
                            getProps: function (event) { return ({
                                cyberEvent: event,
                            }); },
                        },
                        fluidWidth: 0.1,
                        minWidth: 40,
                        sort: { enabled: false },
                        headerIcon: FabricIconNames.Flag,
                        headerClass: 'wcd-padding-small-left',
                        alwaysDisplay: true,
                    },
                    {
                        id: 'machine',
                        name: this.i18nService.get('machine'),
                        getDisplay: function (event) {
                            return event.machine ? machineEntityType_1.getEntityName(event.machine) : null;
                        },
                        icon: {
                            fabricIcon: this.globalEntityTypesService.getEntityTypeIcon(Machine),
                        },
                        className: 'nowrap wcd-text-overflow-medium',
                        sort: { enabled: false },
                    },
                    {
                        id: 'description',
                        name: this.i18nService.get('events.fields.description.title'),
                        getDisplay: function (event) {
                            return _this.cyberEventsActionTypesService.getEventDescription(event);
                        },
                        getTooltip: function (event) {
                            return _this.cyberEventsActionTypesService.getEventDescription(event);
                        },
                        maxWidth: 500,
                        icon: {
                            fabricIcon: function (event) {
                                if (event.isNotAlert) {
                                    var iconName = _this.cyberEventsActionTypesService.getEventIcon(event);
                                    if (!WcdIconNames[iconName])
                                        return iconName;
                                }
                            },
                            wcdIcon: function (event) {
                                if (event.isNotAlert) {
                                    var iconName = _this.cyberEventsActionTypesService.getEventIcon(event);
                                    if (WcdIconNames[iconName])
                                        return WcdIconNames[iconName];
                                }
                            },
                            className: function (event) {
                                var className = [];
                                if (event.IsBoldEvent) {
                                    className.push('ms-color-tealLight');
                                }
                                if (event.alertSeverity) {
                                    className.push('field-value-icon');
                                    className.push("wcd-severity-icon-outline-" + event.alertSeverity.type);
                                }
                                return className.join(' ');
                            },
                        },
                        getCssClass: function (event) {
                            var className = [];
                            if (event.IsBoldEvent) {
                                className.push('wcd-font-weight-bold');
                            }
                            if (event.actionType &&
                                event.actionType.id === CyberEventActionTypeName.Alert &&
                                event.relatedAlert) {
                                className.push("wcd-severity wcd-severity-" + event.relatedAlert.severity.type);
                            }
                            return className.join(' ');
                        },
                        sort: { enabled: false },
                        fluidWidth: 1,
                        minWidth: 400,
                    },
                    {
                        id: 'details',
                        name: this.i18nService.get('events.fields.details.title'),
                        component: {
                            type: TagsFieldComponent,
                            getProps: function (event) {
                                var tags = _this.cyberEventsActionTypesService.getEventTags(event);
                                var orientation = FlexDirection.Vertical;
                                return {
                                    tags: tags,
                                    orientation: orientation,
                                };
                            },
                        },
                        fluidWidth: 0.4,
                        minWidth: 150,
                        sort: { enabled: false },
                    },
                    {
                        id: 'user',
                        name: this.i18nService.get('events.fields.user.title'),
                        getDisplay: function (event) {
                            return event.isNotAlert ? event.initiatingUser && event.initiatingUser.accountName : null;
                        },
                        icon: {
                            fabricIcon: function (event) {
                                return event.isNotAlert && event.initiatingUser && FabricIconNames.Contact;
                            },
                        },
                        getTooltip: function (event) {
                            return event.isNotAlert ? event.initiatingUser && event.initiatingUser.fullName : null;
                        },
                        fluidWidth: 0.4,
                        minWidth: 150,
                        sort: { enabled: false },
                    },
                    {
                        id: 'entities',
                        name: this.i18nService.get('events.fields.entities.title'),
                        getDisplay: function (event) {
                            return event.isNotAlert ? _this.getEntitiesList(event) : null;
                        },
                        getTooltip: function (event) {
                            return event.isNotAlert ? _this.getEntitiesList(event) : null;
                        },
                        sort: { enabled: false },
                        fluidWidth: 0.4,
                        minWidth: 300,
                    },
                    {
                        id: 'actionTypes',
                        name: this.i18nService.get('events.fields.actionTypes.title'),
                        getDisplay: function (event) {
                            return event.isUnknownOrNotAlert
                                ? _this.cyberEventsActionTypesService.getActionType(event)
                                : null;
                        },
                        sort: { enabled: false },
                        custom: {
                            allowFilterValueTracking: true,
                        },
                    },
                    {
                        id: 'dataTypes',
                        name: this.i18nService.get('events.fields.eventsDataType.title'),
                        filterOnly: true,
                        filter: {
                            priority: 3,
                            requiresData: false,
                            isHidden: !(this.featuresService.isEnabled(Feature.NewTimeLineData) ||
                                this.featuresService.isEnabled(Feature.MachineTimelineDataCompleteness)),
                            component: {
                                type: FilterValuesChecklistComponent,
                                config: {
                                    values: sortBy(Object.keys(CyberEventDataTypeFilterTypeName).map(function (type) { return ({
                                        value: type,
                                        name: _this.i18nService.get("events.dataTypeFilter." + type),
                                    }); }), function (value) { return value.name; }),
                                    disableSelectAll: true,
                                },
                            },
                        },
                        custom: {
                            allowFilterValueTracking: true,
                        },
                    },
                    {
                        id: 'eventsGroups',
                        name: this.i18nService.get('events.fields.eventsGroups.title'),
                        filterOnly: true,
                        filter: {
                            priority: 4,
                            requiresData: false,
                            component: {
                                type: FilterValuesChecklistComponent,
                                config: {
                                    values: sortBy(Object.keys(CyberEventActionFilterGroupTypeName).map(function (group) { return ({
                                        value: group,
                                        name: _this.i18nService.get("events.actionTypesFilterGroups." + group),
                                    }); }), function (value) { return value.name; }),
                                },
                            },
                        },
                        custom: {
                            allowFilterValueTracking: true,
                        },
                    },
                    {
                        id: 'initiatingProcessName',
                        name: this.i18nService.get('events.fields.initiatingProcessName.title'),
                        enabledByDefault: false,
                        getDisplay: function (event) { return _this.getInitiatingProcessName(event); },
                        getTooltip: function (event) { return _this.getInitiatingProcessName(event); },
                        sort: { enabled: false },
                        fluidWidth: 0.4,
                        minWidth: 200,
                    },
                    {
                        id: 'initiatingProcessId',
                        name: this.i18nService.get('events.fields.initiatingProcessId.title'),
                        enabledByDefault: false,
                        getDisplay: function (event) {
                            return event.isUnknownOrNotAlert && event.initiatingProcess
                                ? event.initiatingProcess.id
                                : null;
                        },
                        sort: { enabled: false },
                    },
                    {
                        id: 'initiatingCommandLine',
                        name: this.i18nService.get('events.fields.initiatingCommandLine.title'),
                        enabledByDefault: false,
                        maxWidth: 400,
                        getDisplay: function (event) {
                            return event.isUnknownOrNotAlert && event.initiatingProcess
                                ? event.initiatingProcess.commandLine
                                : null;
                        },
                        getTooltip: function (event) {
                            return event.isUnknownOrNotAlert && event.initiatingProcess
                                ? event.initiatingProcess.commandLine
                                : null;
                        },
                        sort: { enabled: false },
                        fluidWidth: 0.4,
                        minWidth: 200,
                    },
                    {
                        id: 'initiatingSha1',
                        name: this.i18nService.get('events.fields.initiatingSha1.title'),
                        enabledByDefault: false,
                        getDisplay: function (event) {
                            return event.isUnknownOrNotAlert && event.initiatingProcess && event.initiatingProcess.file
                                ? event.initiatingProcess.file.sha1
                                : null;
                        },
                        getTooltip: function (event) {
                            return event.isUnknownOrNotAlert && event.initiatingProcess && event.initiatingProcess.file
                                ? event.initiatingProcess.file.sha1
                                : null;
                        },
                        sort: { enabled: false },
                        fluidWidth: 0.4,
                        minWidth: 100,
                    },
                    {
                        id: 'initiatingPath',
                        name: this.i18nService.get('events.fields.initiatingPath.title'),
                        enabledByDefault: false,
                        getDisplay: function (event) {
                            return event.isUnknownOrNotAlert && event.initiatingProcess && event.initiatingProcess.file
                                ? event.initiatingProcess.file.folderPath
                                : null;
                        },
                        getTooltip: function (event) {
                            return event.isUnknownOrNotAlert && event.initiatingProcess && event.initiatingProcess.file
                                ? event.initiatingProcess.file.folderPath
                                : null;
                        },
                        sort: { enabled: false },
                        fluidWidth: 0.4,
                        minWidth: 200,
                    },
                    {
                        id: 'initiatingUserDomain',
                        name: this.i18nService.get('events.fields.initiatingUserDomain.title'),
                        enabledByDefault: false,
                        getDisplay: function (event) {
                            return event.isNotAlert
                                ? event.initiatingUser && event.initiatingUser.accountDomainName
                                : null;
                        },
                        getTooltip: function (event) {
                            return event.isNotAlert
                                ? event.initiatingUser && event.initiatingUser.accountDomainName
                                : null;
                        },
                        sort: { enabled: false },
                        fluidWidth: 0.4,
                        minWidth: 200,
                    },
                    {
                        id: 'targetProcessFileName',
                        name: this.i18nService.get('events.fields.targetProcessFileName.title'),
                        enabledByDefault: false,
                        getDisplay: function (event) { return _this.getTargetProcessOrFileName(event); },
                        getTooltip: function (event) { return _this.getTargetProcessOrFileName(event); },
                        sort: { enabled: false },
                        fluidWidth: 0.4,
                        minWidth: 200,
                    },
                    {
                        id: 'targetProcessId',
                        name: this.i18nService.get('events.fields.targetProcessId.title'),
                        enabledByDefault: false,
                        getDisplay: function (event) {
                            return event.isUnknownOrNotAlert && event.process ? event.process.id : null;
                        },
                        sort: { enabled: false },
                    },
                    {
                        id: 'targetCommandLine',
                        name: this.i18nService.get('events.fields.targetCommandLine.title'),
                        enabledByDefault: false,
                        maxWidth: 400,
                        getDisplay: function (event) {
                            return event.isUnknownOrNotAlert && event.process ? event.process.commandLine : null;
                        },
                        getTooltip: function (event) {
                            return event.isUnknownOrNotAlert && event.process ? event.process.commandLine : null;
                        },
                        sort: { enabled: false },
                        fluidWidth: 0.4,
                        minWidth: 200,
                    },
                    {
                        id: 'targetEndpoint',
                        name: this.i18nService.get('events.fields.targetEndpoint.title'),
                        enabledByDefault: false,
                        getDisplay: function (event) {
                            return event.isUnknownOrNotAlert && event.remoteEndpoint
                                ? _this.globalEntityTypesService
                                    .getEntityType(NetworkEndpoint)
                                    .getEntityName(event.remoteEndpoint)
                                : null;
                        },
                        getTooltip: function (event) {
                            return event.isUnknownOrNotAlert && event.remoteEndpoint
                                ? _this.globalEntityTypesService
                                    .getEntityType(NetworkEndpoint)
                                    .getEntityName(event.remoteEndpoint)
                                : null;
                        },
                        sort: { enabled: false },
                        fluidWidth: 0.4,
                        minWidth: 200,
                    },
                    {
                        id: 'targetPort',
                        name: this.i18nService.get('events.fields.targetPort.title'),
                        enabledByDefault: false,
                        getDisplay: function (event) {
                            return event.isUnknownOrNotAlert && event.remoteEndpoint ? event.remoteEndpoint.port : null;
                        },
                        sort: { enabled: false },
                    },
                    {
                        id: 'targetProtocol',
                        name: this.i18nService.get('events.fields.targetProtocol.title'),
                        enabledByDefault: false,
                        getDisplay: function (event) {
                            return event.isUnknownOrNotAlert && event.remoteEndpoint
                                ? event.remoteEndpoint.protocol
                                : null;
                        },
                        sort: { enabled: false },
                    },
                    {
                        id: 'targetSha1',
                        name: this.i18nService.get('events.fields.targetSha1.title'),
                        enabledByDefault: false,
                        getDisplay: function (event) { return _this.getTargetProcessOrFileSha1(event); },
                        getTooltip: function (event) { return _this.getTargetProcessOrFileSha1(event); },
                        sort: { enabled: false },
                        fluidWidth: 0.4,
                        minWidth: 100,
                    },
                    {
                        id: 'targetPath',
                        name: this.i18nService.get('events.fields.targetPath.title'),
                        enabledByDefault: false,
                        getDisplay: function (event) {
                            return event.isUnknownOrNotAlert
                                ? event.process && event.process.file
                                    ? event.process.file.folderPath
                                    : event.file
                                        ? event.file.folderPath
                                        : event.registryModificationDetails.current
                                            ? event.registryModificationDetails.current.key
                                            : null
                                : null;
                        },
                        getTooltip: function (event) {
                            return event.isUnknownOrNotAlert
                                ? event.process && event.process.file
                                    ? event.process.file.folderPath
                                    : event.file
                                        ? event.file.folderPath
                                        : event.registryModificationDetails.current
                                            ? event.registryModificationDetails.current.key
                                            : null
                                : null;
                        },
                        sort: { enabled: false },
                        fluidWidth: 0.4,
                        minWidth: 200,
                    },
                    {
                        id: 'targetUser',
                        name: this.i18nService.get('events.fields.targetUser.title'),
                        enabledByDefault: false,
                        getDisplay: function (event) {
                            return event.isNotAlert ? event.user && event.user.accountName : null;
                        },
                        getTooltip: function (event) {
                            return event.isNotAlert ? event.user && event.user.accountName : null;
                        },
                        sort: { enabled: false },
                        fluidWidth: 0.4,
                        minWidth: 150,
                    },
                    {
                        id: 'targetUserDomain',
                        name: this.i18nService.get('events.fields.targetUserDomain.title'),
                        enabledByDefault: false,
                        getDisplay: function (event) {
                            return event.isNotAlert ? event.user && event.user.accountDomainName : null;
                        },
                        getTooltip: function (event) {
                            return event.isNotAlert ? event.user && event.user.accountDomainName : null;
                        },
                        sort: { enabled: false },
                        fluidWidth: 0.4,
                        minWidth: 200,
                    },
                    {
                        id: 'markedEventsOnly',
                        name: '',
                        filterOnly: true,
                        filter: {
                            priority: 1,
                            requiresData: false,
                            disableFilterSectionCollapse: true,
                            component: {
                                type: FilterValuesToggleComponent,
                                config: {
                                    trueLabel: this.i18nService.get('events.markedEvents.filterText'),
                                    falseLabel: this.i18nService.get('events.markedEvents.filterText'),
                                },
                            },
                        },
                        custom: {
                            allowFilterValueTracking: true,
                        },
                    },
                ]);
            }
            return this._fields;
        },
        enumerable: true,
        configurable: true
    });
    CyberEventsFieldsService.prototype.getEntitiesList = function (event) {
        return this.cyberEventsActionTypesService
            .getEventEntities(event)
            .map(function (item) { return item.name; })
            .join(' > ');
    };
    CyberEventsFieldsService.prototype.getInitiatingProcessName = function (event) {
        return event.isUnknownOrNotAlert && event.initiatingProcess
            ? this.globalEntityTypesService
                .getEntityType(Process)
                .getEntityName(event.initiatingProcess)
            : null;
    };
    CyberEventsFieldsService.prototype.getTargetProcessOrFileName = function (event) {
        return event.isUnknownOrNotAlert
            ? event.process
                ? this.globalEntityTypesService.getEntityType(Process).getEntityName(event.process)
                : event.file
                    ? this.globalEntityTypesService
                        .getEntityType(FileInstance)
                        .getEntityName(event.file)
                    : null
            : null;
    };
    CyberEventsFieldsService.prototype.getTargetProcessOrFileSha1 = function (event) {
        return event.isUnknownOrNotAlert
            ? event.process && event.process.file
                ? event.process.file.sha1
                : event.file
                    ? event.file.sha1
                    : null
            : null;
    };
    return CyberEventsFieldsService;
}());
export { CyberEventsFieldsService };
