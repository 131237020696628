var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
import { EntityRelationship, RelationshipType, } from '@microsoft/paris';
import { Url } from '../url.entity';
import { EntityStatistics } from '../../entity/entity-statistics.value-object';
var ɵ0 = function (config) { return config.data.serviceUrls.urls; }, ɵ1 = function (url) { return ({
    url: url.id,
}); }, ɵ2 = function (dataQuery) {
    var where = dataQuery && dataQuery.where ? dataQuery.where : {};
    return __assign({ lookingBackInDays: 30 }, where);
};
var UrlUrlStatsRelationship = /** @class */ (function () {
    function UrlUrlStatsRelationship() {
    }
    UrlUrlStatsRelationship = __decorate([
        EntityRelationship({
            sourceEntity: Url,
            dataEntity: EntityStatistics,
            allowedTypes: [RelationshipType.OneToOne],
            cache: {
                time: 1000 * 60 * 5,
                max: 10,
            },
            baseUrl: ɵ0,
            endpoint: 'urls/UrlStats',
            allItemsEndpointTrailingSlash: false,
            getRelationshipData: ɵ1,
            parseDataQuery: ɵ2,
        })
    ], UrlUrlStatsRelationship);
    return UrlUrlStatsRelationship;
}());
export { UrlUrlStatsRelationship };
export { ɵ0, ɵ1, ɵ2 };
