import { Component, OnInit } from '@angular/core';
import { ModelBase, Paris, RelationshipRepository } from '@microsoft/paris';
import {
	Alert,
	SuppressionRule,
	SuppressionRuleAlertRelationship,
} from '@wcd/domain';
import { filter } from 'rxjs/operators';
import { ActivatedEntity } from '../../../global_entities/services/activated-entity.service';
import { sccHostService } from '@wcd/scc-interface';
import { PerformanceSccService } from '../../../insights/services/performance.scc.service';


@Component({
	selector: 'suppression-rule-alerts',
	template: `
		<alerts-dataview
			*ngIf="this.associatedAlertsRepo?.sourceItem"
			[allowFilters]="false"
			[repository]="associatedAlertsRepo"
			[disabledFields]="['rbacGroup']"
			[sortDisabledFields]="sortDisabledAlertFields"
			[allowGrouping]="false"
			className="wcd-padding-large-horizontal"
			[showHeader]="false"
			[allowTimeRangeSelect]="false"
			dataViewId="suppression-rule-alerts"
			[responsiveActionBar]="true"
			[removePadding]="true"
			(onTableRenderComplete)="onTableRenderComplete()"
			[removePaddingRight]="isScc">
		</alerts-dataview>
	`,
})
export class SuppressionRuleAlertsComponent implements OnInit {
	rule: SuppressionRule;
	associatedAlertsRepo: RelationshipRepository<SuppressionRule, Alert>;
	sortDisabledAlertFields: Array<string> = ['firstSeen'];
	isScc = sccHostService.isSCC;

	constructor(
		private paris: Paris,
		private activatedEntity: ActivatedEntity,
		private performanceSccService: PerformanceSccService,
	) {
		this.associatedAlertsRepo = this.paris.getRelationshipRepository(SuppressionRuleAlertRelationship);
	}

	ngOnInit() {
		if (this.rule) {
			this.associatedAlertsRepo.sourceItem = this.rule;
		}
		else {
			this.activatedEntity.currentEntity$
				.pipe(filter((entity: ModelBase) => entity instanceof SuppressionRule))
				.subscribe((rule: SuppressionRule) => {
					this.associatedAlertsRepo.sourceItem = rule;
				});
		}
	}

	onTableRenderComplete(){
		this.performanceSccService.endNgPageLoadPerfSession('alerts');
	}
}
