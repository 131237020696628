import {
	ChangeDetectionStrategy,
	ChangeDetectorRef,
	Component,
	ComponentRef,
	EventEmitter,
	Injector,
	Input,
	Output,
	ViewChild,
} from '@angular/core';
import { DataQuerySortDirection, ModelBase, Paris, RelationshipRepository } from '@microsoft/paris';
import { ContentState } from '@wcd/contents-state';
import { BehaviorSubject, combineLatest, merge, Observable, of, Subject } from 'rxjs';
import { catchError, filter, map, mergeMap, shareReplay, startWith, switchMap, tap } from 'rxjs/operators';
import {
	AatpProfile,
	ActiveAlertStatuses,
	Alert,
	AlertsSeveritySummary,
	AlertStatusType,
	EnvironmentName,
	Machine,
	MachineAatpProfileRelationship,
	MachineAlertsRelationship,
	MachineAlertsSeveritySummaryRelationship,
	MachineIncidentCount,
	MachineIncidentsCountRelationship,
	MachineInterceptingMachinesRelationship,
	MachineSecurityAnalytics,
	MachineSecurityAnalyticsRelationship,
	MachineUserDetails,
	MachineUserDetailsRelationship,
	MdeAttachErrorToShortMessageKey,
	OperatingSystemPlatformCategories,
	SeenByMachines,
	Tag,
	getManagedByStringKey,
	getEnrollmentStatusStringKey,
	shouldShowMdeNotes,
} from '@wcd/domain';
import { MachinesService } from '../../../@entities/machines/services/machines.service';
import { AdvancedFeaturesService } from '../../../admin/integration-settings/advanced-features.service';
import {
	AppContextService,
	FlavorService,
	Feature,
	FeaturesService,
	TvmLicensesAngularService,
} from '@wcd/config';
import { RbacService } from '../../../rbac/services/rbac.service';
import { ActivatedEntity } from '../../services/activated-entity.service';
import { GlobalEntityTypesService } from '../../services/global-entity-types.service';
import { EntityDetailsComponentBase } from './entity-details.component.base';
import { I18nService } from '@wcd/i18n';
import { EntityDetailsMode } from '../../models/entity-details-mode.enum';
import { AlertsFields } from '../../../@entities/alerts/services/alerts.fields';
import { AlertsDataviewPanelComponent } from '../../../@entities/alerts/components/alerts.dataview-panel';
import { PanelType } from '@wcd/panels';
import { DialogsService } from '../../../dialogs/services/dialogs.service';
import { DataTableClickEvent, DataTableComponent } from '@wcd/datatable';
import { DataviewField } from '@wcd/dataview';
import { AppConfigService, ServiceUrlsService } from '@wcd/app-config';
import { EntityType } from '../../models/entity-type.interface';
import { toObservable } from '../../../utils/rxjs/utils';
import { ItpPropertyWithSidePaneLink } from './machine.device-details.component';
import { AppFlavorConfig, TvmLicenseType } from '@wcd/scc-common';
import { MemEnrollmentStatus, shouldShowMdeRecommendationsAndNotifications } from '@wcd/domain';
import { FabricIconNames } from '@wcd/scc-common';
import _ from 'lodash';
import { sccHostService } from '@wcd/scc-interface';

export const MACHINE_lOGGED_ON_USERS_DEFAULT_RANGE_IN_DAYS = 30,
	MACHINE_ALERTS_DEFAULT_RANGE_IN_DAYS = 180,
	MACHINE_ALERTS_DEFAULT_PARAMS = {
		lookingBackInDays: MACHINE_ALERTS_DEFAULT_RANGE_IN_DAYS,
		status: [AlertStatusType.New, AlertStatusType.InProgress],
	};

interface AatpDisplayData {
	count: number;
	url: string;
}

const ALERTS_FIELDS = ['title', 'severity', 'status', 'lasteventtime'];
const ALERTS_SORT_BY_FIELD = 'severity';
const MtpProdUrlPrefix = 'https://security.microsoft.com/';
const MtpStagingUrlPrefix = 'https://security.officeppe.com/';
const MdeAttachUpdatedSenseVersion = '10.8040';

export enum CollapsibleID {
	SecurityInfo = 'machine-entity-security-info',
	NetworkActivity = 'machine-entity-network-activity',
	Tags = 'machine-entity-tags',
	ActiveAlerts = 'machine-entity-active-alerts',
	Details = 'machine-entity-details',
	DirectoryData = 'machine-entity-directory-data',
	UserDetails = 'machine-entity-user-details',
	DeviceManagement = 'machine-entity-device-management',
}

@Component({
	selector: 'machine-entity-details',
	changeDetection: ChangeDetectionStrategy.OnPush,
	templateUrl: './machine.entity-details.component.html',
})
export class MachineEntityDetailsComponent extends EntityDetailsComponentBase<Machine> {
	@Input()
	set machine(machine: Machine) {
		const machineInputValue = this._machineInput$.getValue();
		if (!machineInputValue || machineInputValue.id !== machine.id) {
			this._machineInput$.next(machine);
		}
	}

	get machine(): Machine | undefined {
		return this._machineInput$.value;
	}

	@Input() alert: Alert;

	@Input() actionTime: Date;

	@Input() collapsibleSections: Boolean = true;

	@Input() includeMachineName: Boolean = false;

	@Output() readonly loggedOnUsersClick = new EventEmitter<Machine>();

	@ViewChild(DataTableComponent, { static: false }) dataTable: DataTableComponent;

	private readonly _machineInput$ = new BehaviorSubject<Machine | undefined>(undefined);

	machine$: Observable<Machine>;
	machineSecurityAnalytics$: Observable<MachineSecurityAnalytics>;
	alertSeveritySummary$: Observable<AlertsSeveritySummary>;
	incidentsCount$: Observable<number>;
	userDetails$: Observable<MachineUserDetails>;
	isUserLinkAllowed$: Observable<boolean>;
	aatpAlertsDetails$: Observable<AatpDisplayData | null>;
	isLoadingUsers: boolean = false;
	isLoadingAlertSeverities: boolean = false;
	isLoadingIncidents: boolean = false;
	aatpAlertsContentState: ContentState = ContentState.Loading;
	isMtp: boolean;
	isEndpointManagementEnabled: boolean;
	isEndpointManagementFeEnabled: boolean;
	isShowMdeAttachNotificationBar: boolean = true;
	withUsers: boolean;
	withExposureLevel: boolean;
	EntityDetailsMode = EntityDetailsMode;
	directoryDataEnabledByLicense: boolean;
	isSmbFlavor: boolean;
	isEndpointConfigManagementMdeWithSccmEnabled: boolean;
	isMdeAttachEnabled: boolean;

	collapsibleID = CollapsibleID;

	entityType: EntityType<Machine>;
	tags$: Observable<ReadonlyArray<Tag>>;
	tagsWithPseudoTags$: Observable<ReadonlyArray<Tag>>;
	seenBy$: Observable<SeenByMachines>;
	FabricIconNames = FabricIconNames;
	private _tagsSubject$: Subject<ReadonlyArray<Tag>> = new Subject<ReadonlyArray<Tag>>();
	private alertsSidePanel: ComponentRef<AlertsDataviewPanelComponent>;
	private allAlerts: Alert[];

	itpDirectoryDataProperties: Array<ItpPropertyWithSidePaneLink> = [
		{
			title: this.i18nService.get('machines.entityDetails.fields.uacFlags.title'),
			seeAllText: machine => this.i18nService.get('machines.entityDetails.fields.uacFlags.seeAll'),
			seeAllOnClick: machine => this.machinesService.showMachineDirectoryData(machine),
			dataTrackId: 'ShowMachineDirectoryDataPanel',
			getPropertyData: machine => machine.uacFlags,
			exposeProperty: machine => !!machine.uacFlags,
			noDataText: () => this.i18nService.get('machine.details.directory.uac.none.active'),
		},
		{
			title: this.i18nService.get('machines.entityDetails.fields.spns.title'),
			seeAllText: machine =>
				this.i18nService.get('machines.entityDetails.fields.spns.seeAll', {
					count: machine.spns && machine.spns.length,
				}),
			seeAllOnClick: machine => this.machinesService.showMachineDirectoryData(machine),
			dataTrackId: 'ShowMachineDirectoryDataPanel',
			getPropertyData: machine => machine.spns,
			exposeProperty: machine => machine.spns && machine.spns.length > 0,
			noDataText: () => this.i18nService.get('machine.details.directory.spn.no.spns.found'),
		},
		{
			title: this.i18nService.get('machines.entityDetails.fields.groupMembership.title'),
			seeAllText: machine =>
				this.i18nService.get('machines.entityDetails.fields.groupMembership.seeAll', {
					count: machine.groupMembership && machine.groupMembership.length,
				}),
			seeAllOnClick: machine => this.machinesService.showMachineDirectoryData(machine),
			dataTrackId: 'ShowMachineDirectoryDataPanel',
			getPropertyData: machine => machine.groupMembership,
			exposeProperty: machine => machine.groupMembership && machine.groupMembership.length > 0,
			noDataText: () => this.i18nService.get('machine.details.directory.group.membership.no.groups'),
		},
	];
	alerts$: Observable<Alert[]>;
	alertsColumns: DataviewField<Alert>[];
	MAX_ALERTS = 5;
	hasMdeLicenseWorkloads: boolean;

	constructor(
		injector: Injector,
		activatedEntity: ActivatedEntity,
		public globalEntityTypesService: GlobalEntityTypesService,
		rbacService: RbacService,
		public readonly machinesService: MachinesService,
		private readonly flavorService: FlavorService,
		readonly i18nService: I18nService,
		private readonly featuresService: FeaturesService,
		private readonly advancedFeaturesService: AdvancedFeaturesService,
		private readonly paris: Paris,
		private readonly changeDetectorRef: ChangeDetectorRef,
		private readonly dialogsService: DialogsService,
		private readonly machineService: MachinesService,
		private readonly appConfigService: AppConfigService,
		private readonly tvmLicensesService: TvmLicensesAngularService,
		private serviceUrlsService: ServiceUrlsService,
		alertsFields: AlertsFields,
		appContext: AppContextService
	) {
		super(injector);

		this.isMtp = appContext.isMtp;
		const activatedMachine$ = activatedEntity.currentEntity$.pipe(
			filter<ModelBase, Machine>((model): model is Machine => model instanceof Machine)
		);

		this.isSmbFlavor = this.flavorService.isEnabled(AppFlavorConfig.settings.mdeAttach);

		this.isEndpointManagementEnabled =
			(this.featuresService.isEnabled(Feature.EndpointConfigManagement) &&
				this.featuresService.isEnabled(Feature.EndpointConfigManagementFe)) ||
			this.isSmbFlavor;

		this.withUsers = this.flavorService.isEnabled(AppFlavorConfig.devices.users);
		this.withExposureLevel = this.shouldDisplayTvmBasicSection();
		this.directoryDataEnabledByLicense = this.flavorService.isEnabled(
			AppFlavorConfig.devices.directoryData
		);

		this.isEndpointConfigManagementMdeWithSccmEnabled = this.featuresService.isEnabled(
			Feature.EndpointConfigManagementMdeWithSccm
		);

		// merge the two ways of getting a machine - first get the info that's already on the machine object from the input, then make
		// another emission with the full data received from the BE, in case some of the data was missing on the input data.
		this.machine$ = merge(
			this._machineInput$.pipe(switchMap(machine => (machine ? of(machine) : activatedMachine$))),
			this._machineInput$.pipe(
				filter(Boolean),
				switchMap((machine: any) =>
					paris.getItemById<Machine, Machine['id'], Machine['id']>(Machine, machine.id)
				)
			)
		);

		this.seenBy$ = this.featuresService.isEnabled(Feature.MagellanDevicePageSeenByField)
			? this.machine$.pipe(
					mergeMap(device =>
						this.isDiscoveredDevice(device)
							? this.paris.getRelatedItem<Machine, SeenByMachines>(
									MachineInterceptingMachinesRelationship,
									device
							  )
							: (of(null) as Observable<SeenByMachines>)
					),
					catchError(() => of(null))
			  )
			: (of(null) as Observable<SeenByMachines>);

		this.machine$.subscribe((m: any) => {
			this.entityType = this.globalEntityTypesService.getEntityType(m.constructor);
			//TODO: When removing a tag the tag list doesn't get updated. need to support the after action refresh idea that is available for the dataview / panel (see refreshOnResolve, refreshEntityPanelOnResolve...)
			this.tags$ = merge(toObservable(this.entityType.getTags([m])), this._tagsSubject$).pipe(
				shareReplay(1)
			);

			/**
			 * the tags list shows merge of the tags and the pseudo tags. the tags edit component shows/edits only real tags.
			 */
			const tagsWithPseudoTagsSources = [...[this.tags$], ...[this.entityType.pseudoTags.get([m])]];
			this.tagsWithPseudoTags$ = combineLatest(...tagsWithPseudoTagsSources).pipe(
				map(([realTags, pseudoTags]) => [...pseudoTags, ...realTags]),
				shareReplay(1)
			);
		});

		this.machineSecurityAnalytics$ = this.featuresService.isEnabled(Feature.MachineSecurityScore)
			? this.machine$.pipe(
					mergeMap(machine =>
						this.paris.getRelatedItem<Machine, MachineSecurityAnalytics>(
							MachineSecurityAnalyticsRelationship,
							machine
						)
					),
					catchError(() => of(null))
			  )
			: of(null);

		const isUserExposedToMachine$ = this.machine$.pipe(
			mergeMap(machine =>
				rbacService.isUserExposedToEntity(
					this.globalEntityTypesService.getEntityType(Machine),
					machine
				)
			),
			map(userExposureResult => userExposureResult.isExposed)
		);

		const machineWhenUserExposed$ = combineLatest(this.machine$, isUserExposedToMachine$).pipe(
			filter(([_, isUserExposedToMachine]) => isUserExposedToMachine),
			map(([machine, _]) => machine)
		);

		this.alertSeveritySummary$ = machineWhenUserExposed$.pipe(
			tap(() => {
				this.isLoadingAlertSeverities = true;
				this.changeDetectorRef.markForCheck();
			}),
			mergeMap(machine =>
				this.paris.getRelatedItem<Machine, AlertsSeveritySummary>(
					MachineAlertsSeveritySummaryRelationship,
					machine,
					{
						where: MACHINE_ALERTS_DEFAULT_PARAMS,
					}
				)
			),
			tap(
				() => {
					this.isLoadingAlertSeverities = false;
					this.changeDetectorRef.markForCheck();
				},
				() => {
					this.isLoadingAlertSeverities = false;
					this.changeDetectorRef.markForCheck();
				}
			)
		);
		this.alerts$ = this.machine$.pipe(
			mergeMap(machine => {
				const repository: RelationshipRepository<
					Machine,
					Alert
				> = this.paris.getRelationshipRepository(MachineAlertsRelationship);
				repository.sourceItem = machine;

				const queryOptions = {
					sortBy: [
						{
							field: ALERTS_SORT_BY_FIELD,
							direction: DataQuerySortDirection.descending,
						},
					],
					where: {
						status: ActiveAlertStatuses,
					},
				};

				return repository.query(queryOptions).pipe(
					tap(result => (this.allAlerts = result.items)),
					map(result => result.items.slice(0, this.MAX_ALERTS))
				);
			})
		);

		this.incidentsCount$ = machineWhenUserExposed$.pipe(
			tap(() => {
				this.isLoadingIncidents = true;
				this.changeDetectorRef.markForCheck();
			}),
			mergeMap(machine =>
				this.paris.getRelatedItem<Machine, MachineIncidentCount>(
					MachineIncidentsCountRelationship,
					machine
				)
			),
			map(incidentsCount => incidentsCount.count),
			tap(
				() => {
					this.isLoadingIncidents = false;
					this.changeDetectorRef.markForCheck();
				},
				() => {
					this.isLoadingIncidents = false;
					this.changeDetectorRef.markForCheck();
				}
			)
		);

		this.userDetails$ = machineWhenUserExposed$.pipe(
			tap(() => {
				this.isLoadingUsers = true;
				this.changeDetectorRef.markForCheck();
			}),
			mergeMap(machine =>
				this.paris.getRelatedItem<Machine, MachineUserDetails>(
					MachineUserDetailsRelationship,
					machine
				)
			),
			tap(
				() => {
					this.isLoadingUsers = false;
					this.changeDetectorRef.markForCheck();
				},
				() => {
					this.isLoadingUsers = false;
					this.changeDetectorRef.markForCheck();
				}
			)
		);

		this.isUserLinkAllowed$ = this.machine$.pipe(
			map(machine =>
				machine && machine.os && machine.os.platform
					? machine.os.platform.category !== OperatingSystemPlatformCategories.Windows7
					: true
			),
			startWith(true)
		);

		this.aatpAlertsDetails$ = this.machine$.pipe(
			tap(() => {
				this.aatpAlertsContentState = ContentState.Loading;
				this.changeDetectorRef.markForCheck();
			}),
			mergeMap(machine => this.getAatpProfile(machine)),
			tap(
				result => {
					this.aatpAlertsContentState = result ? ContentState.Complete : ContentState.Empty;
					this.changeDetectorRef.markForCheck();
				},
				() => {
					this.aatpAlertsContentState = ContentState.Error;
					this.changeDetectorRef.markForCheck();
				}
			)
		);

		this.alertsColumns = alertsFields.allFields
			.filter(field => ALERTS_FIELDS.includes(field.id))
			.map(field => field.clone({ maxWidth: field.maxWidth || 200, sort: { enabled: false } }));

		this.isMdeAttachEnabled = false;
		if (sccHostService.isSCC) {
			sccHostService.ajax
				.get<boolean>(`${serviceUrlsService.k8s}/cloud/public/internal/Siam/MdeAttachEnabled`)
				.then(res => (this.isMdeAttachEnabled = res.data));
		}

		this.hasMdeLicenseWorkloads = flavorService.isEnabled(AppFlavorConfig.settings.mdeWithWorkloads);
	}

	private getMdeAttachTitle(machine: Machine): string {
		if (machine.memEnrollmentStatus === MemEnrollmentStatus.Unknown) {
			const osName = machine.os.osPlatformString;
			if (
				osName &&
				osName.startsWith('Windows') &&
				machine.senseClientVersion < MdeAttachUpdatedSenseVersion
			) {
				return this.i18nService.get('machines_memEnrollment_title_note');
			}
			if (osName && !osName.startsWith('Windows')) {
				return this.i18nService.get('machines_memEnrollment_title_note');
			}
		}
		if (machine.memEnrollmentStatus === MemEnrollmentStatus.NotEnrolled || machine.memEnrollmentStatus === MemEnrollmentStatus.Unenrolled) {
			return this.i18nService.get('machines_memEnrollment_title_note');
		}
		return this.i18nService.get('machines_memEnrollment_title');
	}

	private getMdeAttachError(machine: Machine): string {
		if (machine.memEnrollmentStatus in MemEnrollmentStatus) {
			const memEnrollmentStatus = machine.memEnrollmentStatus;
			if (memEnrollmentStatus === MemEnrollmentStatus.Unknown) {
				const osName = machine.os.osPlatformString;
				if (
					osName &&
					osName.startsWith('Windows') &&
					machine.senseClientVersion < MdeAttachUpdatedSenseVersion
				) {
					return this.i18nService.get('machines_memEnrollmentStatus_UnknownWindows');
				}
				if (osName && !osName.startsWith('Windows')) {
					return this.i18nService.get('machines_memEnrollmentStatus_Unknown_NotWindows');
				}
			}
			if (memEnrollmentStatus === MemEnrollmentStatus.NotEnrolled || memEnrollmentStatus === MemEnrollmentStatus.Unenrolled) {
				return this.i18nService.get('machines_memEnrollmentStatus_NotEnrolled');
			}
			return this.i18nService.get(
				`machines_memEnrollmentStatus_${MdeAttachErrorToShortMessageKey(machine.memEnrollmentStatus)}`
			);
		}
		return this.i18nService.get('machines_memEnrollmentStatus_GeneralError');
	}

	private shouldShowMdeRecommendationsAndNotifications(machine: Machine): boolean {
		return shouldShowMdeRecommendationsAndNotifications(machine.memEnrollmentStatus);
	}

	private shouldShowMdeNotes(machine: Machine): boolean {
		return shouldShowMdeNotes(machine, this.isMdeAttachEnabled);
	}

	private shouldShowDeviceManagementCollapsible(machine: Machine): boolean {
		return (
			this.hasMdeLicenseWorkloads &&
			this.isEndpointManagementEnabled &&
			!_.isNil(machine.memEnrollmentStatus)
		);
	}

	private getManagedByString(machine: Machine): string {
		return this.i18nService.get(
			getManagedByStringKey(
				machine.memEnrollmentStatus,
				this.isEndpointConfigManagementMdeWithSccmEnabled
			)
		);
	}

	private getEnrollmentStatusString(machine: Machine): string {
		return this.i18nService.get(getEnrollmentStatusStringKey(machine.memEnrollmentStatus));
	}

	private getMtpDevicePageLink(machine: Machine): string {
		const relativeMachinePath = this.machineService.getMachineLink(machine.name);
		return this.appConfigService.environmentName === EnvironmentName.Production
			? `${MtpProdUrlPrefix}${relativeMachinePath}`
			: `${MtpStagingUrlPrefix}${relativeMachinePath}`;
	}

	private getAatpProfile(machine: Machine): Observable<AatpDisplayData> {
		return this.advancedFeaturesService.getAdvancedFeaturesSettings().pipe(
			mergeMap(advancedFeatures =>
				advancedFeatures.aatpIntegrationEnabled && advancedFeatures.aatpWorkspaceExists
					? this.paris
							.getRelatedItem<Machine, AatpProfile>(MachineAatpProfileRelationship, machine)
							.pipe(
								map<AatpProfile, AatpDisplayData>(aatpProfile => ({
									count:
										aatpProfile.securityAlertSummary.high +
										aatpProfile.securityAlertSummary.medium +
										aatpProfile.securityAlertSummary.low,
									url: aatpProfile.url,
								}))
							)
					: of(null)
			)
		);
	}

	isDiscoveredDevice({ isManagedByMdatp }: Machine) {
		return isManagedByMdatp === false;
	}

	getDeviceLink(deviceId: string) {
		return this.machinesService.getMachineLink(deviceId, false);
	}

	onShowMoreAlerts() {
		this.dialogsService
			.showPanel(
				AlertsDataviewPanelComponent,
				{
					id: 'alerts-dataview-panel-dataview',
					type: PanelType.large,
					isModal: true,
					noBodyPadding: true,
					scrollBody: false,
					noShadow: true,
					back: {
						onClick: () => this.alertsSidePanel.destroy(),
					},
				},
				{
					alerts: this.allAlerts,
					defaultSortBy: ALERTS_SORT_BY_FIELD,
					onlyFields: ALERTS_FIELDS,
				}
			)
			.subscribe((panel: ComponentRef<AlertsDataviewPanelComponent>) => {
				this.alertsSidePanel = panel;

				panel.onDestroy(() => {
					this.alertsSidePanel = null;
				});
			});
	}

	showCollapsibleDirectoryDataSection(machine: Machine): boolean {
		return (
			this.directoryDataEnabledByLicense &&
			this.itpDirectoryDataProperties.some((itpProperty: ItpPropertyWithSidePaneLink) =>
				itpProperty.exposeProperty(machine)
			)
		);
	}

	viewAlert($event: DataTableClickEvent) {
		this.entityPanelsService.showEntity(Alert, $event.item);
	}

	updateTableView() {
		if (this.dataTable) {
			this.dataTable.updateHeaderCells();
		}
	}

	hideMessageBar() {
		this.isShowMdeAttachNotificationBar = false;
	}

	private shouldDisplayTvmBasicSection() {
		const tvmLicensesEnabled = this.featuresService.isEnabled(Feature.TvmPremium);
		const isEnabledByFlavor = this.flavorService.isEnabled(AppFlavorConfig.devices.exposureLevel);
		const isEnabledByTvmLicenses =
			this.tvmLicensesService.isEnabled(TvmLicenseType.TvmBasic) ||
			// elkamin: delete this code once the nibiru code is in
			this.flavorService.isEnabled({ mdeFlavors: ['Smb'] });
		return tvmLicensesEnabled ? isEnabledByTvmLicenses : isEnabledByFlavor;
	}
}
