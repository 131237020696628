import { SevilleModule } from '../../seville/seville.module';
import { AppInsightsService } from '../../../../insights/services/app-insights.service';
import { Feature } from '@wcd/config';
import { RegExpService } from '@wcd/shared';
import { AuthService } from '@wcd/auth';
import { cloneDeep, isEmpty, find } from 'lodash-es';

declare let angular: angular.IAngularStatic;

SevilleModule.factory('rbacMachineGroupService', rbacMachineGroupService);

rbacMachineGroupService.$inject = [
	'featuresService',
	'$uibModal',
	'$localStorage',
	'urlMapping',
	'$q',
	'$http',
	'$log',
	'appInsights',
	'authService',
];

//@TODO: Max - this will be upgraded to Angular newer version on another iteration.
//		 once upgraded - clear the local storage object when applying changes to rbac groups.
function rbacMachineGroupService(
	featuresService,
	$uibModal,
	$localStorage,
	urlMapping,
	$q,
	$http,
	$log,
	appInsights: AppInsightsService,
	authService: AuthService
) {
	function getUserExposedRbacGroups() {
		var dfd = $q.defer();
		if (
			$localStorage.userExposedRbacGroups &&
			isRbacGroupsLocalStorageObjectValid($localStorage.userExposedRbacGroups)
		) {
			dfd.resolve({ status: 200, data: $localStorage.userExposedRbacGroups });
		} else {
			return $http.get(urlMapping.getThreatIntelUrl() + '/UserExposedRbacGroups').then(
				function(response) {
					if (response && response.status === 200) {
						const candidate = {
							data: response.data,
							expirationSeconds: 300, //expires every 5 minutes
							timeStamp: Date.now(),
							sessionId: authService.sessionId,
						};

						$localStorage.userExposedRbacGroups = cloneDeep(candidate);
						return { status: response.status, data: candidate };
					} else {
						appInsights.trackException(
							new Error('Cannot get user exposed rbac groups. status:' + response.status)
						);
						dfd.reject(response);
					}
				},
				err => {
					appInsights.trackException(new Error('/UserExposedRbacGroups failed with error: ' + err));
				}
			);
		}
		return dfd.promise;
	}

	function isUserExposedToAlert(alertId: string) {
		const dfd = $q.defer();
		if (featuresService.isEnabled(Feature.RbacMachineGroups)) {
			//Flighting feature validation
			$http.get(urlMapping.getThreatIntelUrl() + '/IsUserExposedToAlert?alertId=' + alertId).then(
				response => {
					if (response.status === 200) {
						if (response.data === false) {
							appInsights.trackException(
								new Error("can't get /IsUserExposedToAlert?alertId=" + alertId)
							);
							dfd.reject();
							openModal($uibModal, 'Alert');
						}
						dfd.resolve({ data: response.data, status: 200 });
					} else {
						appInsights.trackException(
							new Error("can't get /IsUserExposedToAlert?alertId=" + response.status)
						);
						dfd.reject();
					}
				},
				function(e) {
					$log.error('Something went wrong, Cant check if user is authorized to view alert: ', e);
					dfd.reject();
				}
			);
		} else {
			dfd.resolve(true);
		}
		return dfd.promise;
	}

	function isUserExposedToMachine(machineId: string) {
		const dfd = $q.defer();
		if (featuresService.isEnabled(Feature.RbacMachineGroups)) {
			//Flighting feature validation

			// setting the machine id type - determines the current machine id is computerDnsName. defaults to computer dns name
			let machineIdType = 'Id';
			if (RegExpService.senseMachineIdRegex.test(machineId)) {
				machineIdType = 'SenseMachineId';
			} else if (RegExpService.guid.test(machineId)) {
				machineIdType = 'Guid';
			}

			$http
				.get(urlMapping.getThreatIntelUrl() + '/IsUserExposedToMachine', {
					params: {
						machineId: machineId,
						idType: machineIdType,
					},
				})
				.then(
					response => {
						if (response.status === 200) {
							if (response.data === false) {
								dfd.reject();
								openModal($uibModal, 'Machine');
							}
							dfd.resolve({ data: response.data, status: 200 });
						} else if (response.status === 404) {
							// machine not found in the tenant
							dfd.resolve({ data: response.data, status: response.status });
						} else {
							appInsights.trackException(
								new Error("can't get /IsUserExposedToMachine?machineId=" + response.status)
							);
						}
					},
					function(e) {
						$log.error(
							'Something went wrong, Cant check if user is authorized to view machine: ',
							e
						);
					}
				);
		} else {
			dfd.resolve(true);
		}
		return dfd.promise;
	}

	// validating that the user exposed rbac groups object:
	// 1. time not expired (every object is valid for expirationSeconds)
	// 2. has the same session id as the current session id, to avoid keep the same rbac groups stored for different users / tenants
	function isRbacGroupsLocalStorageObjectValid(userRbacGroupsObj) {
		//@TODO: Max - add validations
		const secondsPassed = Math.floor((Date.now() - userRbacGroupsObj.timeStamp) / 1000);
		return (
			(secondsPassed <= userRbacGroupsObj.expirationSeconds ? true : false) &&
			userRbacGroupsObj.sessionId === authService.sessionId
		);
	}

	function openModal(uibModal, pageName: string) {
		const modalInstance = uibModal.open({
			animation: false,
			backdrop: false,
			template: `<div class="unauth-modal">
							<div class="row">
								<div class="col-md-12 unauth-modal-header">No access to {{$ctrl.pageName}}</div>
							</div>
							<span class="unauth-modal-body">
								Contact a global administrator to gain access to the selected {{$ctrl.pageName}}
							</span>
							<div class="row">
								<div class="col-md-4 col-md-offset-8">
									<button class="btn btn-primary primary-button dismiss-btn" ng-click="$ctrl.closeModal()">OK</button>
								</div>
							</div>
						</div>`,
			size: 'md',
			controllerAs: '$ctrl',
			controller: [
				'$state',
				'ng2router',
				'appInsights',
				function($state, ng2router, appInsights: AppInsightsService) {
					const vm = this;
					vm.pageName = pageName.toLowerCase();
					vm.closeModal = function() {
						appInsights.trackEvent('UsageTrack', {
							ButtonType: 'DismissUnauth' + pageName + 'Modal',
							Page: 'Device',
							Component: pageName,
						});
						modalInstance.close(true);
						if (
							isEmpty($state.current.name) ||
							window.location.pathname.indexOf(pageName.toLowerCase() + '/') !== -1
						) {
							ng2router.navigate(['/dashboard']);
						}
					};
				},
			],
		});
	}

	// gets the user exposed groups, each constructed of lable and an id, and the required rbacgroup id to get its object
	// returns the rbac group object (label and id) of the rbac group id requested, or the unassigned rbac group object if the requested id is 0.
	function getRbacGroupObject(
		userExposedRbacGroups: Array<RbacGroupDetails>,
		rbacGroupId: number
	): RbacGroupDetails {
		if (rbacGroupId === 0) {
			return { label: 'Unassigned', id: 0, isUnassignedGroup: true };
		}

		return find(userExposedRbacGroups, item => {
			return item.id === rbacGroupId;
		});
	}

	var service = {
		isUserExposedToMachine: isUserExposedToMachine,
		isUserExposedToAlert: isUserExposedToAlert,
		getUserExposedRbacGroups: getUserExposedRbacGroups,
		getRbacGroupObject: getRbacGroupObject,
	};

	return service;
}

export class RbacGroupDetails {
	public label: string;
	public id: number;
	public isUnassignedGroup: boolean;
}
