<!-- tslint:disable:template-click-events-have-key-events -->
<div *ngIf="incident.alertCountForResource && (activatedEntity.currentEntity$ | async); else alertCount"
	 style="width: 388px">
	Alerts -
	<a (click)="toggleAlerts($event)">
		{{incident.alertCountForResource}}/{{incident.alertCount}}
	</a>
</div>
<ng-template #alertCount>
	{{incident.alertCount}}
</ng-template>
<div *ngIf="viewAlerts">
	<ng-container *ngIf="alerts$ | async as alerts; else loading">
		<div class="alerts-list wcd-scroll-vertical wcd-padding-small-right">
			<ul class="unstyled" >
				<li *ngFor="let alert of alerts.items" (click)="showAlert(alert, $event)">
					<span class="wcd-severity" [ngClass]="'wcd-severity-' + alert.severity.type">
						{{alert.severity.nameI18nKey | i18n}}
					</span>
					<div>
						<strong>
							{{alert.name}}
						</strong>
					</div>
					<div>
						First seen: {{ alert.firstSeen | date}}
						<a class="wcd-margin-left"
						   [routerLink]="['/machines', alert.machine.machineId, 'timeline']"
						   [queryParams]="machinesService.getMachineWithAlertParams(alert)">
							<fab-icon iconName="TimelineProgress"></fab-icon>
							See in timeline
						</a>
					</div>
				</li>
			</ul>
			<a (click)="toggleAlerts($event)">
				<fab-icon iconName="CaretUp"></fab-icon>
				Hide alerts
			</a>
		</div>
	</ng-container>
	<ng-template #loading>
		<div class="wcd-padding-small-all">
			<fab-spinner
				[size]="SpinnerSize.xSmall"
				[label]="'loading' | i18n"
				labelPosition="right"></fab-spinner>
		</div>
	</ng-template>
</div>
