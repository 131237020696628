import { Component, Input, ChangeDetectionStrategy, Output, EventEmitter, ViewChild } from '@angular/core';
import { WindowsAuthParams, WindowsAuthType } from '@wcd/domain';
import { FormControl, NgForm } from '@angular/forms';

@Component({
	selector: 'windows-auth-type',
	templateUrl: './windows-auth-type.component.html',
	changeDetection: ChangeDetectionStrategy.OnPush,
	exportAs: 'windowsAuthType',
})
export class WindowsAuthTypeComponent {
	@Input() parentForm?: FormControl;
	@Input() label?: string;
	@Input() auth?: WindowsAuthParams = new WindowsAuthParams();
	@Output() authChange: EventEmitter<WindowsAuthParams> = new EventEmitter<WindowsAuthParams>();
	@ViewChild('windowsAuthTypeForm', { static: false }) public form: NgForm;

	protocolList = Object.values(WindowsAuthType);
	WindowsAuthType = WindowsAuthType;

	//clear input fields when auth type change
	updateWindowsType(windowsAuthType: WindowsAuthType) {
		this.auth = new WindowsAuthParams(this.auth);
		this.auth.type = windowsAuthType;

		this.notifyAuthChanged();
	}

	notifyAuthChanged() {
		this.authChange.emit(this.auth);
	}

	updateIsKeyVault(isKeyVault: boolean) {
		const previousAuthType = this.auth.type;

		this.auth = new WindowsAuthParams();
		this.auth.type = previousAuthType;
		this.auth.isKeyVault = isKeyVault;

		this.notifyAuthChanged();
	}
}
