var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
import { EntityRelationship, RelationshipType } from '@microsoft/paris';
import { Alert } from '../alert/alert.entity';
import { CyberEvent } from './cyber-event.entity';
var ɵ0 = function (config, query) { return "machines/" + query.where['MachineId'] + "/event/AssociatedAlerts"; }, ɵ1 = function (event) {
    return {
        ActionTime: event.actionTime.toISOString(),
        ReportId: event.reportId,
        MachineId: event.machine.id,
    };
};
var CyberEventAlertsRelationship = /** @class */ (function () {
    function CyberEventAlertsRelationship() {
    }
    CyberEventAlertsRelationship = __decorate([
        EntityRelationship({
            sourceEntity: CyberEvent,
            dataEntity: Alert,
            endpoint: ɵ0,
            getRelationshipData: ɵ1,
            allItemsEndpointTrailingSlash: false,
            allowedTypes: [RelationshipType.OneToMany],
        })
    ], CyberEventAlertsRelationship);
    return CyberEventAlertsRelationship;
}());
export { CyberEventAlertsRelationship };
export { ɵ0, ɵ1 };
