var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
import { EntityRelationship, RelationshipType } from '@microsoft/paris';
import { File } from '../file.entity';
import { FileCurrentInstancesCount } from '../file.current-instances-count.value-object';
var ɵ0 = function (config) { return config.data.serviceUrls.fileprevalence; }, ɵ1 = function (rawData) { return ({
    machineCount: rawData.length,
    fileCount: rawData.reduce(function (_fileCount, machine) { return _fileCount + machine.Files.length; }, 0),
}); };
var FileCurrentInstancesRelationship = /** @class */ (function () {
    function FileCurrentInstancesRelationship() {
    }
    FileCurrentInstancesRelationship = __decorate([
        EntityRelationship({
            sourceEntity: File,
            dataEntity: FileCurrentInstancesCount,
            foreignKey: 'sha1',
            fixedData: { resultsLimit: 1000 },
            endpoint: 'FileObservedMachines',
            baseUrl: ɵ0,
            allItemsEndpointTrailingSlash: false,
            allowedTypes: [RelationshipType.OneToOne],
            parseData: ɵ1,
            cache: {
                max: 5,
                time: 5 * 60 * 1000,
            },
        })
    ], FileCurrentInstancesRelationship);
    return FileCurrentInstancesRelationship;
}());
export { FileCurrentInstancesRelationship };
export { ɵ0, ɵ1 };
