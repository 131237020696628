import { OperatingSystemPlatformCategories } from '@wcd/domain';
import { FeaturesService, Feature } from '@wcd/config';
export var TvmOsSupportLevel;
(function (TvmOsSupportLevel) {
    TvmOsSupportLevel[TvmOsSupportLevel["None"] = 0] = "None";
    TvmOsSupportLevel[TvmOsSupportLevel["Full"] = 1] = "Full";
    TvmOsSupportLevel[TvmOsSupportLevel["Partial"] = 2] = "Partial";
    TvmOsSupportLevel[TvmOsSupportLevel["TvmDataCookingEnableMacOS"] = 3] = "TvmDataCookingEnableMacOS";
    TvmOsSupportLevel[TvmOsSupportLevel["TvmDataCookingEnableWindows81"] = 4] = "TvmDataCookingEnableWindows81";
    TvmOsSupportLevel[TvmOsSupportLevel["TvmDataCookingEnableAndroid"] = 5] = "TvmDataCookingEnableAndroid";
    TvmOsSupportLevel[TvmOsSupportLevel["TvmDataCookingEnableiOS"] = 6] = "TvmDataCookingEnableiOS";
})(TvmOsSupportLevel || (TvmOsSupportLevel = {}));
var TvmOsSupportService = /** @class */ (function () {
    function TvmOsSupportService(featuresService) {
        var _this = this;
        var _a;
        this.featuresService = featuresService;
        this.platformToTvmSupportLevel = (_a = {},
            _a[OperatingSystemPlatformCategories.Windows11] = TvmOsSupportLevel.Full,
            _a[OperatingSystemPlatformCategories.Windows10] = TvmOsSupportLevel.Full,
            _a[OperatingSystemPlatformCategories.Windows10WVD] = TvmOsSupportLevel.Full,
            _a[OperatingSystemPlatformCategories.WindowsServer2019] = TvmOsSupportLevel.Full,
            _a[OperatingSystemPlatformCategories.WindowsServer2022] = TvmOsSupportLevel.Full,
            _a[OperatingSystemPlatformCategories.Windows8Blue] = TvmOsSupportLevel.TvmDataCookingEnableWindows81,
            _a[OperatingSystemPlatformCategories.Windows7] = TvmOsSupportLevel.Partial,
            _a[OperatingSystemPlatformCategories.WindowsServer2016] = TvmOsSupportLevel.Full,
            _a[OperatingSystemPlatformCategories.WindowsServer2012R2] = TvmOsSupportLevel.Full,
            _a[OperatingSystemPlatformCategories.WindowsServer2008R2] = TvmOsSupportLevel.Full,
            _a[OperatingSystemPlatformCategories.macOS] = TvmOsSupportLevel.TvmDataCookingEnableMacOS,
            _a[OperatingSystemPlatformCategories.Linux] = TvmOsSupportLevel.Full,
            _a[OperatingSystemPlatformCategories.Android] = TvmOsSupportLevel.TvmDataCookingEnableAndroid,
            _a[OperatingSystemPlatformCategories.iOS] = TvmOsSupportLevel.TvmDataCookingEnableiOS,
            _a);
        this.supportedOsPlatformCategories = Object.entries(this.platformToTvmSupportLevel)
            .map(function (_a) {
            var osPlatCat = _a[0], supportLevel = _a[1];
            var isSupported = false;
            switch (supportLevel) {
                case TvmOsSupportLevel.Full:
                case TvmOsSupportLevel.Partial:
                    isSupported = true;
                    break;
                case TvmOsSupportLevel.TvmDataCookingEnableMacOS:
                    isSupported = _this.featuresService.isEnabled(Feature.TvmDataCookingEnableMacOS);
                    break;
                case TvmOsSupportLevel.TvmDataCookingEnableWindows81:
                    isSupported = _this.featuresService.isEnabled(Feature.TvmEnableWindows81);
                    break;
                case TvmOsSupportLevel.TvmDataCookingEnableAndroid:
                    isSupported = _this.featuresService.isEnabled(Feature.TvmDataCookingEnableAndroid);
                    break;
                case TvmOsSupportLevel.TvmDataCookingEnableiOS:
                    isSupported = _this.featuresService.isEnabled(Feature.TvmDataCookingEnableiOS);
                    break;
            }
            return isSupported ? osPlatCat : null;
        })
            .filter(Boolean);
        this.isNotMangedDevicesFeatureFlagEnabled = this.featuresService.isEnabled(Feature.NdrEnableVulnerabilitiesAssessment);
    }
    TvmOsSupportService.prototype.isMachineSupported = function (machine) {
        if (this.isNotMangedDevicesFeatureFlagEnabled && this.isNotManagedDevice(machine)) {
            return true;
        }
        if (!machine.os || !machine.os.osPlatformString) {
            return false;
        }
        return this.supportedOsPlatformCategories.some(function (o) { return o === machine.os.osPlatformString; });
    };
    TvmOsSupportService.prototype.getSupportLevel = function (machine) {
        if (this.isNotMangedDevicesFeatureFlagEnabled && this.isNotManagedDevice(machine)) {
            return TvmOsSupportLevel.Full;
        }
        var osPlatform = OperatingSystemPlatformCategories[machine.os.osPlatformString];
        return this.supportedOsPlatformCategories.includes(osPlatform)
            ? TvmOsSupportLevel.Full
            : TvmOsSupportLevel.None;
    };
    TvmOsSupportService.prototype.isNotManagedDevice = function (machine) {
        // for itp devices this boolean will be always undefined so compering to false
        return machine.isManagedByMdatp === false;
    };
    return TvmOsSupportService;
}());
export { TvmOsSupportService };
