/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "@angular/core";
import * as i1 from "../../../../../../../projects/i18n/src/lib/pipes/i18n.pipe";
import * as i2 from "../../../../../../../projects/i18n/src/lib/services/i18n.service";
import * as i3 from "../../../../../../../projects/expander/src/lib/collapsible-section.component.ngfactory";
import * as i4 from "../../../../../../../projects/expander/src/lib/collapsible-section.component";
import * as i5 from "../../../global_entities/components/entity-details/mailbox-entity-details.component.ngfactory";
import * as i6 from "../../../global_entities/components/entity-details/mailbox-entity-details.component";
import * as i7 from "../../../global_entities/services/global-entity-types.service";
import * as i8 from "../../../../../../../projects/config/src/lib/services/features.service";
import * as i9 from "./mailbox-alerts.component.ngfactory";
import * as i10 from "./mailbox-alerts.component";
import * as i11 from "@microsoft/paris/dist/lib/paris";
import * as i12 from "./mailbox.entity-panel.component";
var styles_MailboxEntityPanelComponent = [];
var RenderType_MailboxEntityPanelComponent = i0.ɵcrt({ encapsulation: 2, styles: styles_MailboxEntityPanelComponent, data: {} });
export { RenderType_MailboxEntityPanelComponent as RenderType_MailboxEntityPanelComponent };
export function View_MailboxEntityPanelComponent_0(_l) { return i0.ɵvid(0, [i0.ɵpid(0, i1.I18nPipe, [i2.I18nService]), (_l()(), i0.ɵeld(1, 0, null, null, 6, "wcd-collapsible-section", [], null, null, null, i3.View_CollapsibleSectionComponent_0, i3.RenderType_CollapsibleSectionComponent)), i0.ɵdid(2, 49152, null, 0, i4.CollapsibleSectionComponent, [], { label: [0, "label"] }, null), i0.ɵppd(3, 1), (_l()(), i0.ɵted(-1, 0, ["\n\t"])), (_l()(), i0.ɵeld(5, 0, null, 0, 1, "mailbox-entity-details", [], null, null, null, i5.View_MailboxEntityDetailsComponent_0, i5.RenderType_MailboxEntityDetailsComponent)), i0.ɵdid(6, 638976, null, 0, i6.MailboxEntityDetailsComponent, [i0.Injector, i7.GlobalEntityTypesService, i8.FeaturesService], { entity: [0, "entity"], mode: [1, "mode"] }, null), (_l()(), i0.ɵted(-1, 0, ["\n"])), (_l()(), i0.ɵted(-1, null, ["\n"])), (_l()(), i0.ɵeld(9, 0, null, null, 6, "wcd-collapsible-section", [], null, null, null, i3.View_CollapsibleSectionComponent_0, i3.RenderType_CollapsibleSectionComponent)), i0.ɵdid(10, 49152, null, 0, i4.CollapsibleSectionComponent, [], { label: [0, "label"] }, null), i0.ɵppd(11, 1), (_l()(), i0.ɵted(-1, 0, ["\n\t"])), (_l()(), i0.ɵeld(13, 0, null, 0, 1, "mailbox-alerts", [], null, null, null, i9.View_MailboxAlertsComponent_0, i9.RenderType_MailboxAlertsComponent)), i0.ɵdid(14, 49152, null, 0, i10.MailboxAlertsComponent, [i11.Paris], { mailbox: [0, "mailbox"] }, null), (_l()(), i0.ɵted(-1, 0, ["\n"])), (_l()(), i0.ɵted(-1, null, ["\n"]))], function (_ck, _v) { var _co = _v.component; var currVal_0 = i0.ɵunv(_v, 2, 0, _ck(_v, 3, 0, i0.ɵnov(_v, 0), "mailboxes.entityDetails.header")); _ck(_v, 2, 0, currVal_0); var currVal_1 = _co.mailbox; var currVal_2 = _co.EntityDetailsMode.SidePane; _ck(_v, 6, 0, currVal_1, currVal_2); var currVal_3 = i0.ɵunv(_v, 10, 0, _ck(_v, 11, 0, i0.ɵnov(_v, 0), "alerts.active")); _ck(_v, 10, 0, currVal_3); var currVal_4 = _co.mailbox; _ck(_v, 14, 0, currVal_4); }, null); }
export function View_MailboxEntityPanelComponent_Host_0(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 1, "mailbox-entity-panel", [], null, null, null, View_MailboxEntityPanelComponent_0, RenderType_MailboxEntityPanelComponent)), i0.ɵdid(1, 245760, null, 0, i12.MailboxEntityPanelComponent, [i0.ChangeDetectorRef], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
var MailboxEntityPanelComponentNgFactory = i0.ɵccf("mailbox-entity-panel", i12.MailboxEntityPanelComponent, View_MailboxEntityPanelComponent_Host_0, { entities: "entities", options: "options", action$: "action$", isUserExposed$: "isUserExposed$", isLoadingEntity$: "isLoadingEntity$", entity: "entity", contextLog: "contextLog" }, { requestAction: "requestAction", refreshOnResolve: "refreshOnResolve", requestEntitiesRefresh: "requestEntitiesRefresh", requestMetadataRefresh: "requestMetadataRefresh" }, []);
export { MailboxEntityPanelComponentNgFactory as MailboxEntityPanelComponentNgFactory };
