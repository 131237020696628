var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (b.hasOwnProperty(p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
var __metadata = (this && this.__metadata) || function (k, v) {
    if (typeof Reflect === "object" && typeof Reflect.metadata === "function") return Reflect.metadata(k, v);
};
import { EntityField, ModelBase, ValueObject } from '@microsoft/paris';
var ɵ0 = ["ActorNames", "actorNames", "ActorName", "actorName"], ɵ1 = ["DeviceTags", "deviceTags"], ɵ2 = ["UserTags", "userTags"], ɵ3 = ["IsRansomware", "isRansomware"], ɵ4 = ["IsTelemetrySpoofing", "isTelemetrySpoofing"], ɵ5 = ["IsThreatExpert", "isThreatExpert"], ɵ6 = ["IsOfficeHVE", "isOfficeHVE"], ɵ7 = ["IsChainEventDetection", "isChainEventDetection"];
var EntityTags = /** @class */ (function (_super) {
    __extends(EntityTags, _super);
    function EntityTags() {
        return _super !== null && _super.apply(this, arguments) || this;
    }
    __decorate([
        EntityField({ data: ɵ0 }),
        __metadata("design:type", Object)
    ], EntityTags.prototype, "actorNames", void 0);
    __decorate([
        EntityField({ data: ɵ1 }),
        __metadata("design:type", Array)
    ], EntityTags.prototype, "deviceTags", void 0);
    __decorate([
        EntityField({ data: ɵ2 }),
        __metadata("design:type", Array)
    ], EntityTags.prototype, "userTags", void 0);
    __decorate([
        EntityField({ data: ɵ3 }),
        __metadata("design:type", Boolean)
    ], EntityTags.prototype, "isRansomware", void 0);
    __decorate([
        EntityField({ data: ɵ4 }),
        __metadata("design:type", Boolean)
    ], EntityTags.prototype, "isTelemetrySpoofing", void 0);
    __decorate([
        EntityField({ data: ɵ5 }),
        __metadata("design:type", Boolean)
    ], EntityTags.prototype, "isThreatExpert", void 0);
    __decorate([
        EntityField({ data: ɵ6 }),
        __metadata("design:type", Boolean)
    ], EntityTags.prototype, "isOfficeHVE", void 0);
    __decorate([
        EntityField({ data: ɵ7 }),
        __metadata("design:type", Boolean)
    ], EntityTags.prototype, "isChainEventDetection", void 0);
    EntityTags = __decorate([
        ValueObject({
            singularName: 'Entity tags',
            pluralName: '',
            readonly: true,
        })
    ], EntityTags);
    return EntityTags;
}(ModelBase));
export { EntityTags };
export { ɵ0, ɵ1, ɵ2, ɵ3, ɵ4, ɵ5, ɵ6, ɵ7 };
