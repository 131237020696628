var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : new P(function (resolve) { resolve(result.value); }).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g;
    return g = { next: verb(0), "throw": verb(1), "return": verb(2) }, typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (_) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
import { Paris } from '@microsoft/paris';
import { BackgroundCommandApiCall, CancelCommandApiCall, CancelCreateLiveResponseSessionApiCall, DownloadLiveResponseFileApiCall, GetCommandDefinitionsApiCall, LiveResponseCommand, LiveResponseCommandStatus, LiveResponseCommandType, LiveResponseSession, Machine, } from '@wcd/domain';
import { BehaviorSubject, combineLatest, merge, of, Subject, Subscription, throwError, timer, } from 'rxjs';
import { catchError, delay, finalize, last, map, mergeMap, share, shareReplay, startWith, switchMap, take, takeUntil, takeWhile, tap, } from 'rxjs/operators';
import { LiveResponseInputParserService } from './live-response-input-parser.service';
import { compact, orderBy, pull, uniq } from 'lodash-es';
import { ErrorsDialogService } from '../../../dialogs/services/errors-dialog.service';
import { I18nService } from '@wcd/i18n';
import { retry } from '../../../utils/rxjs/retry';
import { DownloadService } from '../../../shared/services/download.service';
import { LiveResponseOutputParserService } from './live-response-output-parser.service';
import { Feature, FeaturesService } from '@wcd/config';
import { AjaxError } from 'rxjs/ajax';
import { ConfirmationService } from '../../../dialogs/confirm/confirm.service';
import { LiveResponseScriptService } from './live-response-script.service';
import { LiveResponsePermissionsService } from './live-response-permissions.service';
import * as i0 from "@angular/core";
import * as i1 from "@microsoft/paris/dist/lib/paris";
import * as i2 from "../../../../../../../projects/i18n/src/lib/services/i18n.service";
import * as i3 from "../../../dialogs/services/errors-dialog.service";
import * as i4 from "../../../shared/services/download.service";
import * as i5 from "../../../../../../../projects/config/src/lib/services/features.service";
import * as i6 from "../../../dialogs/confirm/confirm.service";
import * as i7 from "./live-response-script.service";
import * as i8 from "./live-response-permissions.service";
var _SESSION_STATUS_CHECK_INTERVAL = 10000;
var _COMMAND_STATUS_CHECK_INTERVAL = 1400;
var FALLBACK_WORKING_DIRECTORY = 'C:\\';
var LIBRARY_COMMAND_ID = 'library';
var MAX_NO_COMMUNICATION_TIME_MINUTES = 40;
var LiveResponseService = /** @class */ (function () {
    function LiveResponseService(paris, i18nService, errorsDialogService, downloadService, featuresService, confirmationService, liveResponseScriptService, liveResponsePermissionsService) {
        var _this = this;
        this.paris = paris;
        this.i18nService = i18nService;
        this.errorsDialogService = errorsDialogService;
        this.downloadService = downloadService;
        this.featuresService = featuresService;
        this.confirmationService = confirmationService;
        this.liveResponseScriptService = liveResponseScriptService;
        this.liveResponsePermissionsService = liveResponsePermissionsService;
        this.sessionLeft$ = new Subject();
        this._clearCommands$ = new Subject();
        this.resetSession$ = new BehaviorSubject(undefined);
        this.COMPLETED_COMMAND_STATUS = this.paris.getValue(LiveResponseCommandStatus, function (status) { return status.type === 'completed'; });
        this.commandRepo = this.paris.getRepository(LiveResponseCommand);
        this.liveResponseSessionRepo = this.paris.getRepository(LiveResponseSession);
        this.sessionCommands = {};
        this.runningJobs = {};
        this.commandStop = {};
        this.backgroundCommandsSubscription = new Subscription();
        this.localCommands = [
            {
                id: 'trace',
                displayName: 'Enable Debug Mode',
                description: 'Sets logging on this console to debug mode',
                defaultAlias: 'trace',
                aliases: null,
                params: null,
                flags: null,
                runCommand: function (session, commandModifiers, rawCommand) {
                    _this.debugMode = !_this.debugMode;
                    return of(__assign({}, _this.getLocalCommandConfig(session.id, rawCommand, 'trace'), { outputs: [
                            {
                                outputType: 'string',
                                data: "Debug mode " + (_this.debugMode ? 'enabled' : 'disabled'),
                            },
                        ] }));
                },
            },
            {
                id: 'cls',
                displayName: 'Clear console',
                description: 'Clears the console screen',
                defaultAlias: 'cls',
                aliases: ['clear'],
                params: null,
                flags: null,
                runCommand: function (session, commandModifiers, rawCommand, terminal) {
                    var commandConfig = _this.getLocalCommandConfig(session.id, rawCommand, 'cls');
                    return of(commandConfig).pipe(finalize(function () {
                        terminal.clear();
                        _this._clearCommands$.next([commandConfig]);
                    }));
                },
            },
            {
                id: 'help',
                displayName: 'Help',
                description: 'Shows information about live response commands',
                defaultAlias: 'help',
                aliases: ['?'],
                params: [
                    {
                        paramId: 'command_def_id',
                        name: 'Command name',
                        description: 'Specific command to get information on',
                        isOptional: true,
                    },
                ],
                flags: null,
                runCommand: function (session, commandModifiers, rawCommand) {
                    var commandDefParam = commandModifiers &&
                        commandModifiers.params &&
                        commandModifiers.params.find(function (p) { return p.paramId === 'command_def_id'; }), commandDefId = commandDefParam && commandDefParam.value;
                    var localCommandConfig = _this.getLocalCommandConfig(session.id, rawCommand, 'help');
                    return _this.getAvailableCommands(session).pipe(map(function (commands) {
                        if (!commandDefId) {
                            return __assign({}, localCommandConfig, { outputs: [
                                    {
                                        outputType: 'string',
                                        data: 'For more information on a specific command, type HELP command-name',
                                    },
                                    {
                                        outputType: 'table',
                                        data: orderBy(commands, ['id']),
                                        keys: [{ id: 'id' }, { id: 'description' }],
                                        tableConfig: {
                                            showHeader: false,
                                        },
                                    },
                                ] });
                        }
                        else {
                            var desiredCommandType = _this.matchCommandString(commands, commandDefId);
                            if (!desiredCommandType) {
                                throw new Error(commandDefId + " is not a valid command");
                            }
                            var modifiersHelp_1 = [];
                            var commandIdentifier = desiredCommandType.defaultAlias || desiredCommandType.id;
                            var commandStrings_1 = '';
                            if (desiredCommandType.params) {
                                desiredCommandType.params.forEach(function (param) {
                                    var paramId = param.isOptional ? "[" + param.paramId + "]" : param.paramId;
                                    commandStrings_1 += " " + paramId;
                                    modifiersHelp_1.push({
                                        id: param.paramId,
                                        description: param.description,
                                    });
                                });
                            }
                            if (desiredCommandType.flags) {
                                desiredCommandType.flags.forEach(function (flag) {
                                    commandStrings_1 += " [-" + flag.flagId + "]";
                                    modifiersHelp_1.push({
                                        id: "-" + flag.flagId,
                                        description: flag.description,
                                    });
                                });
                            }
                            var outputs = [
                                {
                                    outputType: 'string',
                                    data: desiredCommandType.description,
                                },
                            ];
                            if (commandStrings_1) {
                                outputs.push({
                                    outputType: 'string',
                                    data: commandIdentifier + commandStrings_1,
                                });
                            }
                            if (modifiersHelp_1.length) {
                                outputs.push({
                                    outputType: 'table',
                                    data: modifiersHelp_1,
                                    keys: [{ id: 'id' }, { id: 'description' }],
                                    tableConfig: {
                                        showHeader: false,
                                    },
                                });
                            }
                            // Aliases
                            var allAliases = pull(compact(uniq((desiredCommandType.aliases || []).concat(desiredCommandType.defaultAlias, desiredCommandType.id))), commandIdentifier);
                            if (allAliases && allAliases.length) {
                                outputs.push({
                                    outputType: 'string',
                                    data: "Aliases:\n\t" + allAliases.join(', '),
                                });
                            }
                            // Examples
                            if (desiredCommandType.examples && desiredCommandType.examples.length) {
                                outputs.push({
                                    outputType: 'string',
                                    data: "Examples:\n\t" + desiredCommandType.examples
                                        .map(function (e) { return e.join('\n\t'); })
                                        .join('\n\n\t'),
                                });
                            }
                            return __assign({}, localCommandConfig, { outputs: outputs });
                        }
                    }));
                },
            },
            {
                id: LIBRARY_COMMAND_ID,
                displayName: 'Library',
                description: 'Lists or takes action on files in the live response library',
                defaultAlias: 'library',
                aliases: ['scripts'],
                params: [
                    {
                        paramId: 'action',
                        name: 'Action',
                        description: 'Action to perform (delete)',
                        isOptional: true,
                    },
                    {
                        paramId: 'filename',
                        name: 'File name',
                        description: 'Name of the file on which to perform the action',
                        isOptional: true,
                    },
                ],
                flags: null,
                examples: [
                    ['# List files in the library', 'library'],
                    ['# Delete a file from the library', 'library delete script.ps1'],
                ],
                runCommand: function (session, commandModifiers, rawCommand) {
                    var actionParam = commandModifiers &&
                        commandModifiers.params &&
                        commandModifiers.params.find(function (p) { return p.paramId === 'action'; });
                    var fileParam = commandModifiers &&
                        commandModifiers.params &&
                        commandModifiers.params.find(function (p) { return p.paramId === 'filename'; });
                    var localCommandConfig = _this.getLocalCommandConfig(session.id, rawCommand, 'library');
                    var isDelete;
                    if (actionParam || fileParam) {
                        if (!actionParam) {
                            throw new Error("'filename' option requires an action.");
                        }
                        isDelete = actionParam.value.toLowerCase() === 'delete';
                        if (!isDelete) {
                            throw new Error('Specify a supported action (delete).');
                        }
                        if (!fileParam) {
                            throw new Error('Specify a valid file name to take action on.');
                        }
                    }
                    return _this.liveResponseScriptService.scripts$.pipe(take(1), switchMap(function (files) {
                        if (isDelete) {
                            var file_1 = files.find(function (f) { return f.fileName && f.fileName.toLowerCase() === fileParam.value.toLowerCase(); });
                            if (!file_1) {
                                throw new Error("File not found. Specify a valid file name to take action on.");
                            }
                            return _this.liveResponseScriptService.removeItem(file_1).pipe(map(function () {
                                return (__assign({}, localCommandConfig, { outputs: [
                                        {
                                            outputType: 'string',
                                            data: "File '" + file_1.fileName + "' successfully removed.",
                                        },
                                    ] }));
                            }));
                        }
                        return of(__assign({}, localCommandConfig, { outputs: [
                                {
                                    outputType: 'table',
                                    keys: [
                                        { id: 'fileName', name: 'File name' },
                                        { id: 'description', name: 'Description' },
                                        { id: 'hasParams', name: 'Parameters' },
                                        { id: 'paramsDescription', name: 'Parameters description' },
                                        { id: 'creationTime', name: 'Uploaded on' },
                                        { id: 'creatingUser', name: 'Uploaded by' },
                                    ],
                                    data: files
                                        ? files.map(function (f) {
                                            return Object.assign({}, f, {
                                                hasParams: f.hasParams ? 'Yes' : 'No',
                                            });
                                        })
                                        : [],
                                },
                            ] }));
                    }));
                },
            },
            {
                id: 'jobs',
                displayName: 'Jobs',
                description: 'Lists background jobs',
                defaultAlias: 'jobs',
                params: null,
                flags: null,
                runCommand: function (session, commandModifiers, rawCommand) {
                    var localCommandConfig = _this.getLocalCommandConfig(session.id, rawCommand, 'jobs');
                    var commands = Object.values(_this.runningJobs);
                    if (!commands.length) {
                        return of(localCommandConfig);
                    }
                    return combineLatest(commands).pipe(take(1), map(function (commands) {
                        return (__assign({}, localCommandConfig, { outputs: [
                                {
                                    outputType: 'table',
                                    data: commands.map(function (command) { return ({
                                        id: "[" + command.id + "]",
                                        status: command.status.name,
                                        rawCommand: command.rawCommand,
                                    }); }),
                                    keys: [{ id: 'id' }, { id: 'status' }, { id: 'rawCommand' }],
                                    tableConfig: {
                                        showHeader: false,
                                    },
                                },
                            ] }));
                    }));
                },
            },
        ];
        this.debugMode = false;
        this.clearCommands$ = this._clearCommands$.asObservable();
    }
    Object.defineProperty(LiveResponseService.prototype, "currentDirectory", {
        get: function () {
            return this._currentDirectory || FALLBACK_WORKING_DIRECTORY;
        },
        set: function (value) {
            this._currentDirectory = value;
        },
        enumerable: true,
        configurable: true
    });
    LiveResponseService.prototype.getLocalCommandConfig = function (sessionId, rawCommand, commandTypeId) {
        return {
            id: undefined,
            status: this.COMPLETED_COMMAND_STATUS,
            rawCommand: rawCommand,
            startTime: new Date(),
            sessionId: sessionId,
            commandTypeId: commandTypeId,
        };
    };
    LiveResponseService.prototype.createSession = function (machine) {
        return __awaiter(this, void 0, void 0, function () {
            var useV2Api, useV3Api, currentDate, diff, minutesDiff, e, newSession;
            var _this = this;
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        useV2Api = this.featuresService.isEnabled(Feature.LiveResponseTransitionCodeSeparation);
                        useV3Api = this.featuresService.isEnabled(Feature.CloudLiveResponseV3);
                        if (!(typeof machine === 'string')) return [3 /*break*/, 1];
                        machine = new Machine({ id: machine, machineId: machine });
                        return [3 /*break*/, 3];
                    case 1:
                        if (!(this.featuresService.isEnabled(Feature.LiveResponseReportCancelCreateSession) &&
                            machine &&
                            machine.lastSeen)) return [3 /*break*/, 3];
                        currentDate = new Date();
                        diff = (currentDate.getTime() - machine.lastSeen.getTime()) / 1000 / 60;
                        minutesDiff = Math.round(diff);
                        if (!(minutesDiff >= MAX_NO_COMMUNICATION_TIME_MINUTES)) return [3 /*break*/, 3];
                        return [4 /*yield*/, this.confirmationService.confirm({
                                title: this.i18nService.strings.liveResponse_createSession_offlineDevice_popupTitle,
                                text: this.i18nService.strings.liveResponse_createSession_offlineDevice_popupMessage,
                                confirmText: this.i18nService.strings
                                    .liveResponse_createSession_offlineDevice_popupConfirmButtonText,
                            })];
                    case 2:
                        e = _a.sent();
                        if (!e.confirmed) {
                            this.paris
                                .apiCall(CancelCreateLiveResponseSessionApiCall, {
                                machineId: machine.id,
                                machineLastSeen: machine.lastSeen.toISOString(),
                                useV2Api: useV2Api,
                                useV3Api: useV3Api,
                            })
                                .toPromise();
                            return [2 /*return*/, Promise.reject()];
                        }
                        _a.label = 3;
                    case 3:
                        newSession = new LiveResponseSession({
                            id: undefined,
                            machine: machine,
                            useV2Api: useV2Api,
                            useV3Api: useV3Api,
                        });
                        return [2 /*return*/, this.liveResponseSessionRepo
                                .save(newSession, { params: { useV3Api: useV3Api } })
                                .pipe(tap({
                                error: function (err) {
                                    _this.errorsDialogService.showError({
                                        title: _this.i18nService.strings
                                            .machines_entityDetails_actions_createLiveResponse_failureMessage,
                                        data: err instanceof AjaxError ? err : err.response,
                                    });
                                },
                            }), take(1))
                                .toPromise()];
                }
            });
        });
    };
    LiveResponseService.prototype.getAvailableCommands = function (sessionData) {
        var _this = this;
        var sessionId = sessionData.id;
        var sessionCommands$ = this.sessionCommands[sessionId] ||
            this.paris
                .apiCall(GetCommandDefinitionsApiCall, {
                sessionId: sessionId,
                useV2Api: sessionData.useV2Api,
                useV3Api: sessionData.useV3Api,
            })
                .pipe(switchMap(function (commands) {
                return combineLatest(commands.map(function (c) { return _this.paris.createItem(LiveResponseCommandType, c); }));
            }), map(function (commands) {
                var filteredlocalCommands = _this.localCommands.filter(function (c) { return (LIBRARY_COMMAND_ID != c.id) || _this.liveResponsePermissionsService.hasLibraryPermissions(); });
                return commands.concat(filteredlocalCommands);
            }), shareReplay(1));
        this.sessionCommands[sessionId] = sessionCommands$;
        return sessionCommands$;
    };
    LiveResponseService.prototype.findMatchingCommand = function (sessionData, commandStr) {
        var _this = this;
        return this.getAvailableCommands(sessionData).pipe(map(function (commands) { return _this.matchCommandString(commands, commandStr); }));
    };
    LiveResponseService.prototype.runCommand = function (commandStr, session, terminal) {
        var _this = this;
        var commandDefId = LiveResponseInputParserService.getCommandDefIdFromRawString(commandStr);
        return this.findMatchingCommand(session, commandDefId).pipe(switchMap(function (commandType) {
            if (!commandType) {
                throw new Error("'" + commandDefId + "' is not recognized as a command");
            }
            var commandModifiers = LiveResponseInputParserService.buildCommandModifiers(commandType, commandStr);
            var runCommand$;
            if (commandType.runCommand) {
                runCommand$ = commandType.runCommand(session, commandModifiers, commandStr, terminal);
            }
            else {
                runCommand$ = _this.runBackendCommand(commandType, commandModifiers, commandStr, session).pipe(catchError(function (err) { return throwError(LiveResponseOutputParserService.parseCommandError(err)); }), switchMap(function (command) {
                    return commandModifiers.runInBackground &&
                        _this.featuresService.isEnabled(Feature.LiveResponseBackgroundEnabled)
                        ? _this.getCommandInBackground(command, commandModifiers)
                        : _this.getCommandUntilDone(command, commandModifiers);
                }));
            }
            return runCommand$.pipe(map(function (command) { return ({
                command: command,
                commandModifiers: commandModifiers,
            }); }));
        }));
    };
    LiveResponseService.prototype.getCommandUntilDone = function (command, modifiers, allowCurrentDirChange) {
        var _this = this;
        if (allowCurrentDirChange === void 0) { allowCurrentDirChange = true; }
        var command$ = timer(0, _COMMAND_STATUS_CHECK_INTERVAL).pipe(mergeMap(function () {
            return _this.commandRepo.getItemById(command.id, undefined, { session_id: command.sessionId, useV2Api: command.useV2Api, useV3Api: command.useV3Api });
        }), retry(10, true), startWith(command), takeWhile(function (command_) { return command_.status.isRunning; }, true), catchError(function (err) { return throwError(LiveResponseOutputParserService.parseCommandError(err)); }), delay(1), share());
        var lastCommand$ = command$.pipe(last());
        return combineLatest([command$, lastCommand$.pipe(startWith(undefined))]).pipe(switchMap(function (_a) {
            var command = _a[0], lastCommand = _a[1];
            var res$ = of([command, lastCommand]);
            if (lastCommand !== undefined &&
                allowCurrentDirChange &&
                lastCommand.newCurrentDirectory &&
                _this.currentDirectory !== lastCommand.newCurrentDirectory) {
                _this.currentDirectory = lastCommand.newCurrentDirectory;
                // add delay to allow the directory change to take effect
                res$ = res$.pipe(delay(1));
            }
            return res$;
        }), tap(function (_a) {
            var _ = _a[0], lastCommand = _a[1];
            // TODO: check if should keep running in background (for download)
            if (lastCommand !== undefined) {
                if (lastCommand.downloadUrl) {
                    _this.downloadService.downloadFromUrl(lastCommand.downloadUrl, {
                        isAuthenticated: true,
                        downloadedFileName: lastCommand.downloadFileName,
                    });
                }
                else if (lastCommand.downloadToken) {
                    _this.downloadService.download(_this.paris.apiCall(DownloadLiveResponseFileApiCall, lastCommand), lastCommand.downloadFileName);
                }
                if (modifiers.outputTo) {
                    _this.downloadService.download(of(new Blob([LiveResponseOutputParserService.getCommandOutput(lastCommand)], {
                        type: 'application/octet-stream',
                    })), modifiers.outputTo);
                }
            }
        }), map(function (_a) {
            var command = _a[0];
            return command;
        }));
    };
    LiveResponseService.prototype.getCommandInBackground = function (command, commandModifiers) {
        var _this = this;
        this.commandStop[command.id] = new Subject();
        var command$ = this.getCommandUntilDone(command, commandModifiers, false).pipe(takeUntil(merge(this.sessionLeft$, this.commandStop[command.id])), shareReplay({ bufferSize: 1, refCount: true }));
        this.runningJobs[command.id] = command$;
        this.backgroundCommandsSubscription.add(command$.subscribe({
            complete: function () {
                delete _this.runningJobs[command.id];
            },
        }));
        return of(__assign({}, this.getLocalCommandConfig(command.sessionId, command.rawCommand, command.commandTypeId), { outputs: [
                {
                    outputType: 'table',
                    data: [
                        {
                            id: "[" + command.id + "]",
                            status: command.status.name,
                            rawCommand: command.rawCommand,
                        },
                    ],
                    keys: [{ id: 'id' }, { id: 'status' }, { id: 'rawCommand' }],
                    tableConfig: {
                        showHeader: false,
                    },
                },
            ] }));
    };
    LiveResponseService.prototype.cancelCommand = function (command) {
        if (this.commandStop[command.id]) {
            this.commandStop[command.id].next();
            this.commandStop[command.id].complete();
            delete this.commandStop[command.id];
        }
        return this.paris.apiCall(CancelCommandApiCall, command).pipe(retry(10));
    };
    LiveResponseService.prototype.moveCommandToBackground = function (command) {
        return this.paris.apiCall(BackgroundCommandApiCall, command).pipe(retry(10));
    };
    LiveResponseService.prototype.matchCommandString = function (commands, commandStr) {
        var commandLower = commandStr.toLowerCase();
        return commands.find(function (c) {
            return (c.id.toLowerCase() === commandLower ||
                (c.defaultAlias && c.defaultAlias.toLowerCase() === commandLower) ||
                (c.aliases && c.aliases.map(function (a) { return a.toLowerCase(); }).includes(commandLower)));
        });
    };
    LiveResponseService.prototype.runBackendCommand = function (commandType, commandModifiers, rawCommand, session) {
        return this.commandRepo
            .save(new LiveResponseCommand(Object.assign({
            id: undefined,
            sessionId: session.id,
            commandTypeId: commandType.id,
            currentDirectory: this.currentDirectory,
            rawCommand: rawCommand,
            useV2Api: session.useV2Api,
            useV3Api: session.useV3Api,
        }, commandModifiers)), { params: { session_id: session.id, useV3Api: session.useV3Api } })
            .pipe(map(function (command) {
            return Object.assign(command, commandModifiers);
        }));
    };
    LiveResponseService.prototype.getSessionUntilDone = function (session) {
        var _this = this;
        if (this.currentSessionId !== session.id) {
            this.resetSession(session);
        }
        return this.resetSession$.pipe(switchMap(function () { return _this.currentSession$; }));
    };
    LiveResponseService.prototype.resetSession = function (session) {
        var _this = this;
        this.currentSessionId = session.id;
        var session$ = new Subject();
        var timer$ = session$.pipe(startWith(session), switchMap(function (_session) {
            if (_session.status.isPending) {
                return timer(_COMMAND_STATUS_CHECK_INTERVAL, _COMMAND_STATUS_CHECK_INTERVAL);
            }
            return timer(_SESSION_STATUS_CHECK_INTERVAL, _SESSION_STATUS_CHECK_INTERVAL);
        }));
        var data$ = timer$.pipe(mergeMap(function () {
            return _this.liveResponseSessionRepo.getItemById(session.id, undefined, {
                useV2Api: session.useV2Api,
                useV3Api: session.useV3Api,
            });
        }), tap(function (_session) {
            session$.next(_session);
        }), retry(10, true), takeWhile(function (_session) { return _session.status.isRunning; }, true));
        this.currentSession$ = data$.pipe(shareReplay({ bufferSize: 1, refCount: true }), finalize(function () {
            session$.complete();
        }));
        this.resetSession$.next(session);
    };
    LiveResponseService.prototype.leaveSession = function () {
        this.sessionLeft$.next();
        this.currentDirectory = null;
    };
    LiveResponseService.ngInjectableDef = i0.ɵɵdefineInjectable({ factory: function LiveResponseService_Factory() { return new LiveResponseService(i0.ɵɵinject(i1.Paris), i0.ɵɵinject(i2.I18nService), i0.ɵɵinject(i3.ErrorsDialogService), i0.ɵɵinject(i4.DownloadService), i0.ɵɵinject(i5.FeaturesService), i0.ɵɵinject(i6.ConfirmationService), i0.ɵɵinject(i7.LiveResponseScriptService), i0.ɵɵinject(i8.LiveResponsePermissionsService)); }, token: LiveResponseService, providedIn: "root" });
    return LiveResponseService;
}());
export { LiveResponseService };
