var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (b.hasOwnProperty(p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { OnDestroy, OnInit, ChangeDetectorRef, } from '@angular/core';
import { AggregatedIncidentLinkedBy } from '@wcd/domain';
import { PanelContainer } from '@wcd/panels';
import { GetAlertLinkedAlertsApiCall } from '@wcd/domain';
import { Paris } from '@microsoft/paris';
import { Router } from '@angular/router';
import { AlertsFields } from '../../services/alerts.fields';
import { DataviewField } from '@wcd/dataview';
import { FeaturesService } from '@wcd/config';
import { AlertLinkedFields } from '../../services/alert.linked.fields';
var AlertLinkedAlertsPanelComponent = /** @class */ (function (_super) {
    __extends(AlertLinkedAlertsPanelComponent, _super);
    function AlertLinkedAlertsPanelComponent(router, paris, alertFields, changeDetectorRef, featuresService, alertLinkedFields) {
        var _this = _super.call(this, router) || this;
        _this.router = router;
        _this.paris = paris;
        _this.alertFields = alertFields;
        _this.changeDetectorRef = changeDetectorRef;
        _this.featuresService = featuresService;
        _this.alertLinkedFields = alertLinkedFields;
        _this.tableFields = [];
        _this.alerts = [];
        _this.unexposedAlertsCount = 0;
        _this.loading = true;
        return _this;
    }
    AlertLinkedAlertsPanelComponent.prototype.ngOnDestroy = function () {
        this.unsubscribe();
        _super.prototype.ngOnDestroy.call(this);
    };
    AlertLinkedAlertsPanelComponent.prototype.ngOnInit = function () {
        this.init();
    };
    AlertLinkedAlertsPanelComponent.prototype.ngOnChanges = function (changes) {
        if ((changes.alertId && this.alertId !== changes.alertId.currentValue) ||
            (changes.linkedByReason && changes.linkedByReason.currentValue !== this.linkedByReason)) {
            this.init();
        }
    };
    AlertLinkedAlertsPanelComponent.prototype.init = function () {
        var _this = this;
        if (this.alertId && this.linkedByReason && this.linkedByReason.sourceEntity) {
            var fields = this.alertLinkedFields.getAlertLinkedFields(this.linkedByReason.sourceEntity.type);
            this.tableFields = fields
                .map(function (field) {
                return __assign({}, field, { sort: __assign({}, field.sort, { enabled: false }) });
            })
                .map(function (field) { return new DataviewField(field); });
            this.unsubscribe();
            this.apiCallSubscription = this.paris
                .apiCall(GetAlertLinkedAlertsApiCall, __assign({}, this.linkedByReason, { alertId: this.alertId }))
                .subscribe(function (_a) {
                var linkedAlertsAndEntities = _a.linkedAlertsAndEntities, unexposedAlertsCount = _a.unexposedAlertsCount;
                _this.alerts = linkedAlertsAndEntities;
                _this.unexposedAlertsCount = unexposedAlertsCount;
                _this.endLoading();
            }, function () {
                _this.endLoading();
            });
        }
    };
    AlertLinkedAlertsPanelComponent.prototype.unsubscribe = function () {
        if (this.apiCallSubscription) {
            this.apiCallSubscription.unsubscribe();
        }
    };
    AlertLinkedAlertsPanelComponent.prototype.endLoading = function () {
        this.loading = false;
        this.changeDetectorRef.markForCheck();
    };
    return AlertLinkedAlertsPanelComponent;
}(PanelContainer));
export { AlertLinkedAlertsPanelComponent };
