import { Identifiable } from '../../../data/models/identifiable.model';

export class FolderExclusionModel extends Identifiable<number | string> {
	folder: string;
	_extensions: Array<string>;
	_fileNames: Array<string>;
	description: string;
	creationTime: Date;
	creatorName: string;

	constructor(data?: any) {
		super(data);

		if (!data) {
			this._extensions = [];
			this._fileNames = [];
		}
	}

	get extensions() {
		return this._extensions ? this._extensions.join(', ') : '';
	}

	set extensions(value: string) {
		this._extensions = value ? value.split(/(?:\s+)?,(?:\s+)?/).filter(val => val !== '') : [];
	}

	get fileNames() {
		return this._fileNames ? this._fileNames.join(', ') : '';
	}

	set fileNames(value: string) {
		this._fileNames = value ? value.split(/(?:\s+)?,(?:\s+)?/).filter(val => val !== '') : [];
	}

	setData(data: any) {
		super.setData(data);

		this.folder = data.properties.folder;
		this._extensions = data.properties.extensions || [];
		this._fileNames = data.properties.file_names || [];
		this.description = data.properties.description || null;
		this.creationTime = data.creation_time ? new Date(data.creation_time) : new Date();
		this.creatorName = data.creator_name;
	}

	getBackendData(): any {
		return {
			folder: this.folder,
			extensions: this._extensions,
			file_names: this._fileNames,
			description: this.description || '',
		};
	}
}
