/* tslint:disable:template-click-events-have-key-events */
import { ChangeDetectionStrategy, Component, Input, OnInit } from '@angular/core';
import { Incident, Alert, IncidentAlertsRelationship, Machine } from '@wcd/domain';
import { Paris, DataSet, ModelBase } from '@microsoft/paris';
import { ActivatedEntity } from '../../../../global_entities/services/activated-entity.service';
import { filter, switchMap } from 'rxjs/operators';
import { Observable } from 'rxjs';
import { SpinnerSize } from 'office-ui-fabric-react';
import { EntityPanelsService } from '../../../../global_entities/services/entity-panels.service';
import { MachinesService } from '../../../machines/services/machines.service';

@Component({
	selector: 'incident-alerts-list',
	templateUrl: './incident.alerts-list.component.html',
	changeDetection: ChangeDetectionStrategy.OnPush,
	styleUrls: ['./incident.alerts-list.component.scss'],
})
export class IncidentAlertsListComponent implements OnInit {
	@Input() incident: Incident;

	alerts$: Observable<DataSet<Alert>>;
	viewAlerts = false;
	SpinnerSize = SpinnerSize;

	constructor(
		private paris: Paris,
		public machinesService: MachinesService,
		public activatedEntity: ActivatedEntity,
		private entityPanelsService: EntityPanelsService
	) {}

	ngOnInit() {
		this.alerts$ = this.activatedEntity.currentEntity$.pipe(
			filter((entity: ModelBase) => entity instanceof Machine),
			switchMap((machine: Machine) =>
				this.paris.queryForItem<Incident, Alert>(IncidentAlertsRelationship, this.incident, {
					where: {
						WcdMachineId: machine.id,
					},
				})
			)
		);
	}

	toggleAlerts($event: MouseEvent) {
		$event.stopPropagation();
		$event.preventDefault();
		this.viewAlerts = !this.viewAlerts;
		return false;
	}
	showAlert(alert: Alert, $event: MouseEvent) {
		if ((<HTMLElement>$event.target).nodeName === 'A') return true;

		$event.stopPropagation();
		$event.preventDefault();
		this.entityPanelsService.showEntity(Alert, alert);
		return false;
	}
}

export interface IncidentAlertsOptions {
	WcdMachineId?: string;
}
