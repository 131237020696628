import { ApiCall, ApiCallModel } from '@microsoft/paris';
import { WcdPortalParisConfig } from '../../paris-config.interface';

@ApiCall({
	name: 'User is exposed to alert',
	endpoint: 'IsUserExposedToAlert',
	baseUrl: (config: WcdPortalParisConfig) => config.data.serviceUrls.threatIntel,
	parseQuery: (alertId: string) => {
		return { params: { alertId: alertId } };
	},
	cache: {
		time: 1000 * 60 * 2,
		max: 100,
	},
})
export class AlertEntityExposureApiCall extends ApiCallModel<boolean, string> {}
