var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (b.hasOwnProperty(p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : new P(function (resolve) { resolve(result.value); }).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g;
    return g = { next: verb(0), "throw": verb(1), "return": verb(2) }, typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (_) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
import { EventEmitter, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { ModalContainer } from '../../../../dialogs/modals/models/modal-container.model';
import { I18nService } from '@wcd/i18n';
import { Paris } from '@microsoft/paris';
import { File, FileCurrentInstancesRelationship, FileBlockApiCall, } from '@wcd/domain';
import { map, finalize } from 'rxjs/operators';
import { DimensionsModel } from '../../../../dialogs/models/dimensions.model';
import { MachinesService } from '../../../machines/services/machines.service';
import { AuthService } from '@wcd/auth';
import { FilesService } from '../../services/files.service';
var BlockFileModalComponent = /** @class */ (function (_super) {
    __extends(BlockFileModalComponent, _super);
    function BlockFileModalComponent(router, paris, machinesService, authService, i18nService, filesService) {
        var _this = _super.call(this, router) || this;
        _this.paris = paris;
        _this.machinesService = machinesService;
        _this.authService = authService;
        _this.i18nService = i18nService;
        _this.filesService = filesService;
        _this.isSaving = false;
        _this.openActionCenterOnSubmit = false;
        _this.onConfirm = new EventEmitter();
        _this.onCancel = new EventEmitter();
        return _this;
    }
    BlockFileModalComponent.prototype.ngOnInit = function () {
        this.settings = __assign({}, this.settings, { className: 'wcd-flex-vertical', title: this.i18nService.get("file." + this.action + ".title"), dimensions: new DimensionsModel(500, this.action === 'block' ? 420 : 340) });
        this.currentInstancesCount$ = this.paris.getRelatedItem(FileCurrentInstancesRelationship, this.file);
    };
    BlockFileModalComponent.prototype.onConfirmHandler = function () {
        this.onConfirm.emit({});
        this.onConfirm.complete();
    };
    BlockFileModalComponent.prototype.onCancelHandler = function () {
        this.onCancel.emit();
        this.onCancel.complete();
    };
    BlockFileModalComponent.prototype.submit = function () {
        return __awaiter(this, void 0, void 0, function () {
            var machineGroupIds, _a;
            var _this = this;
            return __generator(this, function (_b) {
                switch (_b.label) {
                    case 0:
                        this.isSaving = true;
                        if (!this.authService.currentUser.isMdeAdmin) return [3 /*break*/, 1];
                        _a = [0];
                        return [3 /*break*/, 3];
                    case 1: return [4 /*yield*/, this.machinesService
                            .getFullUserExposedMachineGroups()
                            .pipe(map(function (machineGroups) { return machineGroups.map(function (machineGroup) { return machineGroup.id; }); }))
                            .toPromise()];
                    case 2:
                        _a = _b.sent();
                        _b.label = 3;
                    case 3:
                        machineGroupIds = _a;
                        this.paris
                            .apiCall(FileBlockApiCall, {
                            file: this.file,
                            reason: this.reason,
                            action: this.action,
                            rbacGroups: machineGroupIds,
                        })
                            .pipe(finalize(function () {
                            _this.isSaving = false;
                            _this.onConfirmHandler();
                        }))
                            .subscribe(function () {
                            if (_this.openActionCenterOnSubmit)
                                _this.filesService.showFileActionCenter(_this.file);
                        });
                        return [2 /*return*/];
                }
            });
        });
    };
    return BlockFileModalComponent;
}(ModalContainer));
export { BlockFileModalComponent };
