import { ChangeDetectionStrategy, Component, Injector, ChangeDetectorRef } from '@angular/core';
import {
	Url,
	UrlUrlStatsRelationship,
	UrlActiveAlertsSummaryRelationship,
	EntityStatistics,
	AlertsSeveritySummary,
	WebContentFilteringCategoryLookup,
	DomainCategoryLookupRequest,
	WebContentFilteringCategoryTypes,
} from '@wcd/domain';
import { EntityDetailsComponentBase } from './entity-details.component.base';
import { Observable, of, Subscription } from 'rxjs';
import { finalize, tap } from 'rxjs/operators';
import { ContentState } from '@wcd/contents-state';
import { Paris } from '@microsoft/paris';
import { TimeRangeValue, TimeRangeId, SpecificTimeRangeValue } from '@wcd/date-time-picker';
import { TimeRangesService } from '../../../shared/services/time-ranges.service';
import { EntityDetailsMode } from '../../models/entity-details-mode.enum';
import { catchHttpError } from '../../../utils/rxjs/rxjs-custom-operators';
import { I18nService } from '@wcd/i18n';
import { SidePanelService } from '../../../@entities/@tvm/common/side-panel.service';
import { Feature, FeaturesService } from '@wcd/config';
import _ from 'lodash';

enum CollapsibleID {
	SecurityInfo = 'url-entity-details-security-info',
	DomainDetails = 'url-entity-details-domain-details',
}

@Component({
	selector: 'url-entity-details',
	changeDetection: ChangeDetectionStrategy.OnPush,
	templateUrl: './url.entity-details.component.html',
})
export class UrlEntityDetailsComponent extends EntityDetailsComponentBase<Url> {
	collapsibleID = CollapsibleID;
	readonly dataAsset = this.i18nService.get('help_externalLoadError_data_asset');

	get url(): Url {
		return this.entity;
	}

	get whoIsLink(): string {
		return `https://www.whois.com/whois/${this.url.name}`;
	}

	get showUrlStats(): boolean {
		// don't show url stats in entity page - there's a widget dedicated for them
		return this.mode !== EntityDetailsMode.EntityPage;
	}

	private get showUrlAlertsCount(): boolean {
		return this.mode === EntityDetailsMode.EntityPage;
	}

	urlDetails$: Observable<AlertsSeveritySummary>;
	urlStats$: Observable<EntityStatistics>;
	urlStatsContentState: ContentState = ContentState.Loading;

	currentRange: TimeRangeValue;
	timeRanges: ReadonlyArray<TimeRangeValue> = this.timeRangesService.standard;

	categoryDetails$: Observable<number>;
	urlCategoryDetails$: Observable<any>;
	urlCategoryLookupSubscription: Subscription;
	categoryAsString: string;
	category2AsString: string;
	urlPrimaryCategory: number;
	shouldHideIfLoadingOrNoData: boolean = false;
	isWebContentFilteringFeatureEnabled: boolean;

	constructor(
		injector: Injector,
		private changeDetectorRef: ChangeDetectorRef,
		private readonly paris: Paris,
		private timeRangesService: TimeRangesService,
		private readonly i18nService: I18nService,
		private sidePanelService: SidePanelService,
		private featuresService: FeaturesService,
	) {
		super(injector);
	}

	setEntity(entity: Url) {
		super.setEntity(entity);

		this.setUrlDetails();
		this.isWebContentFilteringFeatureEnabled = this.featuresService.isEnabled(
			Feature.WebContentFilteringPolicy
		);
		this.setSmartScreenCategoryLookupDetails();

		if (this.showUrlStats) {
			this.currentRange = this.timeRanges.find(range => range.id === TimeRangeId.month);
			this.onStatRangeChange();
		}

		this.changeDetectorRef.markForCheck();
	}

	setUrlDetails() {
		this.urlDetails$ = this.showUrlAlertsCount
			? this.paris
					.getRelatedItem<Url, AlertsSeveritySummary>(UrlActiveAlertsSummaryRelationship, this.url)
					.pipe(
						catchHttpError(404, new AlertsSeveritySummary()),
						finalize(() => this.changeDetectorRef.markForCheck())
					)
			: of(null);
	}

	setSmartScreenCategoryLookupDetails() {
		if (this.isWebContentFilteringFeatureEnabled) {
		this.shouldHideIfLoadingOrNoData = true;
		this.categoryAsString = this.category2AsString = null;
		this.paris.apiCall<number[], DomainCategoryLookupRequest>(
			WebContentFilteringCategoryLookup,
			{
				Url: this.url.name
			}
		).subscribe(res => {
			if (res != null && res.length != 0) {
				this.shouldHideIfLoadingOrNoData = false;
				this.urlPrimaryCategory = res[0];
				const categoryKey =  _.findKey(WebContentFilteringCategoryTypes, v => v === this.urlPrimaryCategory);
				this.categoryAsString = this.i18nService.get(`webCategories_categories_${categoryKey.charAt(0).toLowerCase() + categoryKey.substring(1)}`);

				// It is possible that we have 2 categories. If so, get the second category. Otherwise, the main category is the first element in the array
				if (res.length == 2) {
					const category2AsNumber = res[1];
					const category2Key =  _.findKey(WebContentFilteringCategoryTypes, v => v === category2AsNumber);
					this.category2AsString = this.i18nService.get(`webCategories_categories_${category2Key.charAt(0).toLowerCase() + category2Key.substring(1)}`);
				}
			} else {
				this.shouldHideIfLoadingOrNoData = true;
			}

			this.changeDetectorRef.markForCheck();
		},
		(err => {
			// Possibility of error when unknown geo, we fail gracefully here on purpose
			this.shouldHideIfLoadingOrNoData = true;
		}));
		}
	}

	onStatRangeChange() {
		this.urlStatsContentState = ContentState.Loading;
		this.urlStats$ = this.paris
			.getRelatedItem<Url, EntityStatistics>(UrlUrlStatsRelationship, this.entity, {
				where: { lookingBackInDays: (<SpecificTimeRangeValue>this.currentRange).value },
			})
			.pipe(
				tap(
					urlStats =>
						(this.urlStatsContentState = urlStats ? ContentState.Complete : ContentState.Empty),
					() => (this.urlStatsContentState = ContentState.Error)
				),
				finalize(() => this.changeDetectorRef.markForCheck())
			);
	}


	openDisputeCategory() {
		this.sidePanelService.showDisputeCategorySidePanel(this.url.name, this.urlPrimaryCategory, this.categoryAsString);
	}
}
