import { Injectable } from '@angular/core';
import {
	CyberEvent,
	CyberEventGoHuntQueryRequest,
	CyberEventGoHuntQueryApiCall,
	GoHuntQueryResponse,
} from '@wcd/domain';
import { EntityTypeService } from '../../../global_entities/models/entity-type-service.interface';
import { EntityType } from '../../../global_entities/models/entity-type.interface';
import { CyberEventEntityPanelComponent } from '../components/cyber-event.entity-panel.component';
import { I18nService } from '@wcd/i18n';
import { CyberEventsActionTypesService } from './cyber-events-action-types.service';
import { CyberEventsEntityPanelComponent } from '../components/cyber-events.entity-panel.component';
import { CyberEventsUtilsService } from './cyber-events-utils.service';
import { FeaturesService, Feature } from '@wcd/config';
import { Router } from '@angular/router';
import { HUNTING_ROUTE } from '@wcd/shared';
import { Paris } from '@microsoft/paris';
import { map, filter, startWith } from 'rxjs/operators';
import { Observable, of, combineLatest, empty } from 'rxjs';
import { ItemActionModel, ItemActionModelConfig } from '../../../dataviews/models/item-action.model';
import { TimeRangeId } from '@wcd/date-time-picker';

@Injectable()
export class CyberEventEntityTypeService implements EntityTypeService<CyberEvent> {
	constructor(
		private readonly i18nService: I18nService,
		private cyberEventsActionTypesService: CyberEventsActionTypesService,
		private cyberEventsUtilsService: CyberEventsUtilsService,
		private featuresService: FeaturesService,
		private readonly router: Router,
		private paris: Paris
	) {}

	getActionTypeActionsObservable(event: CyberEvent): Observable<ReadonlyArray<ItemActionModel>> {
		return of(this.cyberEventsActionTypesService.getEventActions(event)).pipe(
			filter(actionTypeActions => actionTypeActions && actionTypeActions.length > 0),
			startWith([])
		);
	}

	getGoHuntActionObservable(event: CyberEvent): Observable<ItemActionModelConfig> {
		if (!this.featuresService.isEnabled(Feature.MachineTimelineEventGoHunt)) {
			return of(null);
		}

		const goHuntItemAction: ItemActionModelConfig = {
			id: 'goHunt',
			nameKey: 'events.actions.goHunt.title',
			icon: 'hunting',
			allowDisplayWhileLoading: true,
			tooltip: this.i18nService.get('events.actions.goHunt.descriptionTooltip'),
			openLinkInNewTab: true,
		};
		const monthAgo = new Date();
		monthAgo.setDate(monthAgo.getDate() - 30);

		if (event.actionTime <= monthAgo) {
			goHuntItemAction.tooltip = this.i18nService.get('events.actions.goHunt.availableWithin30Days');
			goHuntItemAction.disabled = true;
			return of(goHuntItemAction);
		}

		return this.paris
			.apiCall<GoHuntQueryResponse, CyberEventGoHuntQueryRequest>(CyberEventGoHuntQueryApiCall, {
				machineId: event.machine.id,
				actionType: event.rawActionType,
				actionTimeIsoString: event.actionTimeIsoString,
			})
			.pipe(
				map((eventGoHuntResponse: GoHuntQueryResponse) => {
					goHuntItemAction.href = this.router.serializeUrl(
						this.router.createUrlTree([`/${HUNTING_ROUTE}`], {
							queryParams: {
								query: eventGoHuntResponse.encodedHuntingQuery,
								timeRangeId: TimeRangeId.month,
								runQuery: true,
							},
						})
					);
					return goHuntItemAction;
				}),
				startWith(null)
			);
	}

	readonly entityType: EntityType<CyberEvent> = {
		id: 'cyber-event',
		entity: CyberEvent,
		singleEntityPanelComponentType: CyberEventEntityPanelComponent,
		multipleEntitiesComponentType: CyberEventsEntityPanelComponent,
		getEntityName: (event: CyberEvent) => this.cyberEventsActionTypesService.getEventDescription(event),
		getIcon: (events: Array<CyberEvent>) =>
			events.length === 1 ? this.cyberEventsActionTypesService.getEventIcon(events[0]) : null,
		getEntityClassName: (event: CyberEvent) => {
			return (
				(event.alertSeverity && event.alertSeverity.className) ||
				(event.relatedAlert && event.relatedAlert.severity && event.relatedAlert.severity.className)
			);
		},
		getItemParams: (event: CyberEvent, options: {}) => {
			return this.cyberEventsUtilsService.createParamsForGetEvent(event, options);
		},
		getActions: (entities: Array<CyberEvent>): Observable<ReadonlyArray<ItemActionModel>> => {
			if (!entities || entities.length !== 1) {
				return empty();
			}

			const event = entities[0];
			return combineLatest(
				this.getActionTypeActionsObservable(event),
				this.getGoHuntActionObservable(event),
				(actionTypeActions, goHuntAction) =>
					[...actionTypeActions, goHuntAction] // combine actions of specific event with the go hunt action
						.filter(Boolean) // remove null actions
						.map(itemActionConfig => new ItemActionModel(itemActionConfig))
			);
		},
	};
}
