/* tslint:disable:template-click-events-have-key-events */
import {
	ChangeDetectorRef,
	Component,
	EventEmitter,
	Input,
	OnChanges,
	Output,
	ViewChild,
} from '@angular/core';
import { DatasetBackendOptions, DataViewConfig, DataviewField } from '@wcd/dataview';
import { DownloadService } from '../../../shared/services/download.service';
import { I18nService } from '@wcd/i18n';
import { PendingActionsService } from '../services/pending-actions.service';
import { AuthService, tenantContextCache } from '@wcd/auth';
import {
	HuntingContext,
	RemediationAction,
	RemediationActionType,
	RemediationActionTypeActionCount,
	RemediationActionTypeActionsRelationship,
	MdeUserRoleActionEnum,
	Investigation,
} from '@wcd/domain';
import { DataChangedPayload, DataViewComponent } from '../../../dataviews/components/dataview.component';
import { Paris, RelationshipRepository } from '@microsoft/paris';
import { RemediationActionTypeFieldsService } from '../services/remediation-action-type.fields.service';
import { AppConfigService, ServiceUrlsService } from '@wcd/app-config';
import { merge } from 'lodash-es';
import { EntityType } from '../../../global_entities/models/entity-type.interface';
import { GlobalEntityTypesService } from '../../../global_entities/services/global-entity-types.service';
import { EntityPanelsService } from '../../../global_entities/services/entity-panels.service';
import { DialogsService } from '../../../dialogs/services/dialogs.service';
import { Feature, FeaturesService, AppContextService } from '@wcd/config';
import { RbacService } from '../../../rbac/services/rbac.service';
import { defer, Observable, of } from 'rxjs';
import { shareReplay, startWith } from 'rxjs/operators';
import { SccExportFormInput, SccExportService } from '../../../shared/services/scc-export.service';
import { sccHostService, SccRoles } from '@wcd/scc-interface';
import { PerformanceSccService } from '../../../insights/services/performance.scc.service';

@Component({
	selector: 'pending-actions-type',
	templateUrl: './pending-actions-type.component.html',
})
export class PendingActionsTypeComponent implements OnChanges {
	showComments = tenantContextCache.hasMtpConsent || tenantContextCache.appConfig.IsMdatpActive;

	@Input() remediationActionTypeActions: RemediationActionTypeActionCount;

	@Input()
	fixedOptions: { [index: string]: any };
	@Input() investigation: Investigation;
	@Input() showInvestigationData: boolean = true;
	@Input() allowStatusSplit: boolean = false;
	@Input() checkNewData: any;

	@Input() externalData: Array<RemediationAction>;

	@Output() onAction: EventEmitter<void> = new EventEmitter();
	@Output() refreshClick: EventEmitter<void> = new EventEmitter();
	@Input() huntingContext?: HuntingContext;

	@ViewChild(DataViewComponent, { static: false }) dataView: DataViewComponent<RemediationAction>;

	hasNewerData: boolean;
	mergedFixedOptions: { [p: string]: any };
	dataViewConfig: DataViewConfig<RemediationAction>;
	isUserAllowed$: Observable<boolean>;
	remediationActionsRepository: RelationshipRepository<RemediationActionType, RemediationAction>;
	fields: Array<DataviewField<RemediationAction>>;
	remediationActionEntityType: EntityType<RemediationAction>;
	private searchAndPurgeRole$: Observable<boolean>;

	setIsUserAllowed(dataChangedPayload: DataChangedPayload<RemediationAction>) {
		const remediationActions = dataChangedPayload.data ? dataChangedPayload.data.items : [];

		this.isUserAllowed$ = defer(async () => {
			const isUserAllowedActions = this.authService.currentUser.hasMdeAllowedUserRoleAction(
				MdeUserRoleActionEnum.remediationActions
			);

			if (isUserAllowedActions) {
				return true;
			}

			const isSecAdmin = this.authService.currentUser.isSecAdmin;
			const isAllowed = isSecAdmin || remediationActions.every(action => !action.isOfficeInvestigation);

			if (!isAllowed && this.appContextService.isSCC) {
				return await this.searchAndPurgeRole$.toPromise();
			}

			return isAllowed;
		}).pipe(startWith(false));

		this.changeDetectorRef.markForCheck();
	}

	onDataReceived(dataChangedPayload: DataChangedPayload<RemediationAction>) {
		this.hasNewerData = false;
		this.setIsUserAllowed(dataChangedPayload);
		this.changeDetectorRef.markForCheck();
	}

	constructor(
		private paris: Paris,
		public pendingActionsService: PendingActionsService,
		public i18nService: I18nService,
		private appContextService: AppContextService,
		private sccExportService: SccExportService,
		private serviceUrlsService: ServiceUrlsService,
		private downloadService: DownloadService,
		private entityPanelsService: EntityPanelsService,
		private dialogsService: DialogsService,
		private featuresService: FeaturesService,
		private authService: AuthService,
		private rbacService: RbacService,
		private appConfigService: AppConfigService,
		private remediationActionTypeFieldsService: RemediationActionTypeFieldsService,
		private changeDetectorRef: ChangeDetectorRef,
		private performanceSccService: PerformanceSccService,
		globalEntityTypesService: GlobalEntityTypesService
	) {
		this.remediationActionEntityType = globalEntityTypesService.getEntityType(RemediationAction);
		this.remediationActionsRepository = paris.getRelationshipRepository(
			RemediationActionTypeActionsRelationship
		);

		this.searchAndPurgeRole$ = defer(() => sccHostService.auth.isInRole(SccRoles.searchAndPurge)).pipe(
			shareReplay({ bufferSize: 1, refCount: true })
		);
	}

	ngOnChanges(changes) {
		if (changes.remediationActionTypeActions) {
			if (
				(changes.remediationActionTypeActions.previousValue &&
					changes.remediationActionTypeActions.previousValue.remediationActionType) !==
				changes.remediationActionTypeActions.currentValue.remediationActionType
			) {
				this.refreshDataviewType();
			} else if (
				(changes.remediationActionTypeActions.previousValue &&
					changes.remediationActionTypeActions.previousValue.actionCount) !==
				changes.remediationActionTypeActions.currentValue.actionCount
			) {
				this.hasNewerData = true;
			}
		} else if (changes.checkNewData) {
			this.hasNewerData = true;
		}
		if (changes.fixedOptions) {
			this.mergedFixedOptions = merge(
				this.featuresService.isEnabled(Feature.AirsApiOffloading) ? { useSevilleApi: true } : null,
				this.fixedOptions
			);
		}
	}

	refreshDataviewType() {
		this.entityPanelsService.closeEntityPanel(RemediationAction);
		this.fields = this.remediationActionTypeFieldsService.getFieldsByRemediationType(
			this.remediationActionTypeActions.remediationActionType.type,
			this.showInvestigationData
		);
		this.remediationActionsRepository.sourceItem = this.remediationActionTypeActions.remediationActionType;
		this.setDataviewConfig();
		this.hasNewerData = false;
	}

	onRefreshClick() {
		this.refreshClick.emit();
		this.refreshDataviewType();
	}

	onDataLoadError(error) {
		this.pendingActionsService.handleDataLoadError(error);
		if (error.status !== 401 && error.status !== 405) {
			this.dialogsService.showError({
				title: `Error loading ${this.remediationActionsRepository.entity.pluralName}`,
				message: error.message || error,
				data: error,
			});
		}
	}

	onTableRenderComplete() {
		this.performanceSccService.endNgPageLoadPerfSession('pending-actions-type');
	}

	private exportResults() {
		if (this.investigation && this.investigation.isOfficeInvestigation) {
			const url = '/api/AirModels/ExportActionList';
			// need added form inputs to specify the investigation ID in the outgoing POST request
			const formInputs: Array<SccExportFormInput> = [
				{
					name: 'ModelType',
					value: 1,
				},
				{
					name: 'ContainerUrn',
					value: this.investigation.id,
				},
				{
					name: 'IncludeFacts',
					value: true,
				},
			];
			this.sccExportService.exportSccData(url, formInputs);
		} else {
			const api: string =
				this.serviceUrlsService.automatedIr +
				(this.remediationActionTypeActions.remediationActionType.api ||
					`/remediation_action/${
						this.remediationActionTypeActions.remediationActionType.id
					}/actions`);
			return this.downloadService.downloadFromUrl(`${api}${/\/$/.test(api) ? 'all' : ''}.csv`);
		}
	}

	private setDataviewConfig() {
		this.dataViewConfig = {
			id: 'pending_' + this.remediationActionTypeActions.remediationActionType.type,
		};

		const isOfficeInvestigation = this.investigation && this.investigation.isOfficeInvestigation;
		if (
			!this.featuresService.isEnabled(Feature.AirsApiOffloading) ||
			this.appConfigService.isExposedToAllMachineGroups ||
			isOfficeInvestigation
		) {
			this.dataViewConfig = Object.assign({}, this.dataViewConfig, {
				exportResults: this.exportResults.bind(this),
				showModalOnExport: !isOfficeInvestigation,
			});
		}

		if (this.externalData) {
			this.dataViewConfig = Object.assign({}, this.dataViewConfig, {
				loadResults: (options: DatasetBackendOptions) => {
					let processedActions = this.externalData;

					if (options.page_size || options.page) {
						const pageSize = options.page_size || this.dataView.pageSize;
						const page = options.page || 1;
						const start = (page - 1) * pageSize;
						processedActions = processedActions.slice(start, start + pageSize);
					}

					return of({ count: this.externalData.length, items: processedActions });
				},
			});
		}
	}
}
