<form ngForm="windowsAuthTypeForm" #windowsAuthTypeForm="ngForm">
       <section class="wcd-flex-vertical tvm-list">
              <div class="form-group tvm-list__block_item">
                     <label class="wcd-font-weight-semibold required" for="authenticated-scan-windows-auth-type">{{ label ? label : ('tvm.authenticatedScan.creationPanel.windowsAuth.type.label' | i18n) }}</label>
                     <div>
                            <fancy-select
                            [(ngModel)]="auth.type"
                            (ngModelChange)="updateWindowsType($event)"
                            id="authenticated-scan-windows-auth-type"
                            name="windowsAuthType"
                            [placeholder]="('tvm.authenticatedScan.creationPanel.windowsAuth.type.placeholder' | i18n)"
                            [isFullWidth]="true"
                            [isBordered]="true"
                            [values]="protocolList">
                            </fancy-select>
                     </div>
              </div>

              <div class="form-group tvm-list__block_item">
                <wcd-checkbox
                    [(ngModel)]="auth.isKeyVault"
                    (ngModelChange)="updateIsKeyVault($event)"
                    [checked]="auth.isKeyVault"
                    [label]="('tvm.authenticatedScan.creationPanel.auth.isKeyVault.label' | i18n)"
                    name="authIsKeyVault">
                </wcd-checkbox>
                <wcd-shared-icon class="icon small-icon wcd-margin-small-right" [iconName]="'Info'"></wcd-shared-icon>
                <span>{{ 'tvm.authenticatedScan.creationPanel.auth.isKeyVault.tooltip' | i18n }}<a href="https://aka.ms/WASDocumentation">{{ 'tvm.authenticatedScan.creationPanel.auth.isKeyVault.tooltip.linkText' | i18n }}</a></span>
              </div>

              <div class="tvm-list__key_val_item wcd-padding-right" *ngIf="auth.isKeyVault">
                <label class="wcd-font-weight-semibold required" for="authenticated-scan-keyVault-uri">{{'tvm.authenticatedScan.creationPanel.auth.keyVault.uri' | i18n}}</label>
                <input type="text"
                       id="authenticated-scan-keyVault-uri"
                       [(ngModel)]="auth.keyVaultUri"
                       (ngModelChange)="notifyAuthChanged()"
                       name="keyVaultUri"
                       class="form-control"
                       maxlength="100"
                       [placeholder]="('tvm.authenticatedScan.creationPanel.auth.keyVault.uriPlaceholder' | i18n)"
                       required/>
              </div>

              <div class="tvm-list__key_val_item wcd-padding-right" *ngIf="auth.isKeyVault">
                     <label class="wcd-font-weight-semibold required" for="authenticated-scan-keyVault-secretName">{{'tvm.authenticatedScan.creationPanel.auth.keyVault.secretName' | i18n}}</label>
                     <input type="text"
                            id="authenticated-scan-keyVault-secretName"
                            [(ngModel)]="auth.keyVaultSecretName"
                            (ngModelChange)="notifyAuthChanged()"
                            name="keyVaultSecretName"
                            class="form-control"
                            maxlength="100"
                            [placeholder]="('tvm.authenticatedScan.creationPanel.auth.keyVault.secretNamePlaceholder' | i18n)"
                            required/>
              </div>

              <div class="tvm-list__key_val_item wcd-padding-right" *ngIf="!auth.isKeyVault && (auth.type === WindowsAuthType.DomainNtlm || auth.type === WindowsAuthType.Kerberos)">
                <label class="wcd-font-weight-semibold required" for="authenticated-scan-domain">{{'tvm.authenticatedScan.creationPanel.windowsAuth.domain' | i18n}}</label>
                <input type="text"
                       id="authenticated-scan-domain"
                       [(ngModel)]="auth.domain"
                       (ngModelChange)="notifyAuthChanged()"
                       name="domain"
                       class="form-control"
                       maxlength="100"
                       [placeholder]="('tvm.authenticatedScan.creationPanel.windowsAuth.domainPlaceholder' | i18n)"
                       required/>
              </div>

              <div class="tvm-list__key_val_item wcd-padding-right" *ngIf="!auth.isKeyVault && (auth.type === WindowsAuthType.LocalNtlm || auth.type === WindowsAuthType.DomainNtlm || auth.type === WindowsAuthType.Kerberos)">
                     <label class="wcd-font-weight-semibold required" for="authenticated-scan-username">{{'tvm.authenticatedScan.creationPanel.windowsAuth.username' | i18n}}</label>
                     <input type="text"
                            id="authenticated-scan-username"
                            [(ngModel)]="auth.username"
                            (ngModelChange)="notifyAuthChanged()"
                            name="username"
                            class="form-control"
                            maxlength="100"
                            [placeholder]="('tvm.authenticatedScan.creationPanel.windowsAuth.usernamePlaceholder' | i18n)"
                            required/>
              </div>

              <div class="tvm-list__key_val_item wcd-padding-right" *ngIf="!auth.isKeyVault && (auth.type === WindowsAuthType.LocalNtlm || auth.type === WindowsAuthType.DomainNtlm || auth.type === WindowsAuthType.Kerberos)">
                     <label class="wcd-font-weight-semibold required" for="authenticated-scan-auth-password">{{'tvm.authenticatedScan.creationPanel.windowsAuth.authPassword' | i18n}}</label>
                     <input type="text"
                            id="authenticated-scan-auth-password"
                            [(ngModel)]="auth.password"
                            (ngModelChange)="notifyAuthChanged()"
                            name="password"
                            class="form-control"
                            maxlength="100"
                            [placeholder]="('tvm.authenticatedScan.creationPanel.windowsAuth.authPasswordPlaceholder' | i18n)"
                            type="password"
                            required/>
              </div>
       </section>
</form>
