/* tslint:disable:template-click-events-have-key-events */
import { Component, OnInit, ViewChild, ChangeDetectionStrategy, ChangeDetectorRef } from '@angular/core';
import { MessageBarType } from 'office-ui-fabric-react';
import { SHARED_FORM_PROVIDER, WizardBaseStep } from '@wcd/wizard';
import { SnmpAuthParams, GetAuthenticatedScanAgentsList, AuthenticationParamsSerializationType, WindowsAuthParams, AssessmentType, AuthenticationParams, WindowsAuthType, SnmpAuthType } from '@wcd/domain';
import { NgForm } from '@angular/forms';
import { RegExpService } from '@wcd/shared';
import { Paris } from '@microsoft/paris';
import { AssessmentJobModel } from '../../models/assessment-job.model';
import { Subject } from 'rxjs';
import { debounceTime } from 'rxjs/operators';
import { AssessmentJobService } from '../../services/assessment-job.service';

@Component({
	viewProviders: [SHARED_FORM_PROVIDER],
	templateUrl: './assessment-job-job-details-step.component.html',
	changeDetection: ChangeDetectionStrategy.OnPush,
})
export class AssessmentJobJobDetailsStepComponent extends WizardBaseStep<AssessmentJobModel>
	implements OnInit {
	@ViewChild('snmpAuthTypeForm', { static: false }) snmpAuthTypeForm: NgForm = new NgForm([], []);
	@ViewChild('windowsAuthTypeForm', { static: false }) windowsAuthTypeForm: NgForm = new NgForm([], []);

	MessageBarType = MessageBarType;
	agentNamesList: Array<string> = [];
	agentsList: Array<{ id: string; machineName: string }> = [];
	readonly RegExpService = RegExpService;

	isCredentialEditMode = false;

	targetChanged$: Subject<void> = new Subject();

	constructor(private paris: Paris, public assessmentJobService: AssessmentJobService, private readonly changeDetection: ChangeDetectorRef) {
		super();
	}

	ngOnInit(): void {
		this.setStepValidation(true);

		this.paris.apiCall(GetAuthenticatedScanAgentsList).subscribe(agents => {
			agents.map(agent => {
				this.agentsList.push({ id: agent.id, machineName: agent.machineName });
				this.agentNamesList.push(agent.machineName);
			});
		});

		this.isCredentialEditMode = this.data.isNewDraft || this.data.overrideAuth;

		// New scan, first time arriving to this step
		if (this.data.isNewDraft && !this.data.assessmentJob.agentId) {
			this.setStepValidation(false);
			this.data.assessmentJob.auth = this.isWindowsScan() ? new WindowsAuthParams() : new SnmpAuthParams();
		}

		this.targetChanged$.pipe(debounceTime(50)).subscribe(() => {
			this.setStepValidation(this.validAuthParams(this.data.assessmentJob.auth) && this.validTargetAddresses());
		});

		this.changeDetection.markForCheck();
	}

	isWindowsScan = (): boolean => this.data.assessmentJob.scanType === AssessmentType.WindowsAssessment;

	displaySnmpCredentialComponent = (): boolean => this.data.assessmentJob.auth && this.isCredentialEditMode && !this.isWindowsScan();

	displayWindowsCredentialComponent = (): boolean => this.data.assessmentJob.auth && this.isCredentialEditMode && this.isWindowsScan();

	onAgentNameChange(agentName: string): void {
		this.data.assessmentJob.agentId = this.getAgentIdByAgentName(agentName);
		this.data.requiredNewScan = true;
	}

	editExistingAuth(): void {
		this.data.assessmentJob.auth = this.isWindowsScan() ? new WindowsAuthParams() : new SnmpAuthParams();
		this.isCredentialEditMode = true;
		this.data.overrideAuth = true;
		this.setStepValidation(false);
	}

	reevaluateStepValidityForAuth(authParams: AuthenticationParams): void {
		this.data.requiredNewScan = true;
		this.setStepValidation(this.validAuthParams(authParams) && this.validTargetAddresses());
	}

	getAgentIdByAgentName(name: string): string {
		const agent = this.agentsList.filter(x => x.machineName === name);
		return agent && agent[0].id;
	}

	onAssessmentJobChange() {
		this.data.requiredNewScan = true;
		this.targetChanged$.next();
	}

	validTargetAddresses(): boolean {
		const targetAddresses = this.data.assessmentJob.originalTargetRanges.split(/,\s*|\s+/);
		return targetAddresses.every(address => this.RegExpService.ipv4OrRangeCidr.test(address));
	}

	validAuthParams(authParams: AuthenticationParams): boolean {
		if (!authParams) {
			return true; // we allow empty auth as when we don't edit existing auth, it is practically null
		}

		switch(authParams.$type) {
			case AuthenticationParamsSerializationType.SnmpAuthParams: {
				const scanAuthAsSnmp = authParams as SnmpAuthParams;
				return this.validSnmpAuth(scanAuthAsSnmp);
			}
			case AuthenticationParamsSerializationType.WindowsAuthParams: {
				const scanAuthAsWindows = authParams as WindowsAuthParams;
				return this.validWindowsAuth(scanAuthAsWindows);
			}
		}
	}

	validSnmpAuth(snmpAuth: SnmpAuthParams): boolean {
		const hasCreds = !!snmpAuth.username || !!snmpAuth.communityString;
		const hasKeyVault = snmpAuth.isKeyVault && !!snmpAuth.keyVaultUri && !!snmpAuth.keyVaultSecretName;
		const validProtocol = !!snmpAuth.type &&
			(!!snmpAuth.authProtocol || (snmpAuth.type !== SnmpAuthType.AuthNoPriv && snmpAuth.type !== SnmpAuthType.AuthPriv)) &&
			(!!snmpAuth.privProtocol || snmpAuth.type !== SnmpAuthType.AuthPriv);

		return !this.snmpAuthTypeForm.form.invalid && validProtocol && (hasCreds || hasKeyVault);
	}

	validWindowsAuth(windowsAuth: WindowsAuthParams): boolean {
		const isDomainValid = !!windowsAuth.domain || (windowsAuth.type !== WindowsAuthType.DomainNtlm && windowsAuth.type !== WindowsAuthType.Kerberos);
		const hasCreds = !!windowsAuth.username && !!windowsAuth.password && isDomainValid;
		const hasKeyVault = windowsAuth.isKeyVault && !!windowsAuth.keyVaultUri && !!windowsAuth.keyVaultSecretName;
		const validProtocol = !!windowsAuth.type;

		return !this.windowsAuthTypeForm.form.invalid && validProtocol && (hasCreds || hasKeyVault);
	}
}
