var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : new P(function (resolve) { resolve(result.value); }).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g;
    return g = { next: verb(0), "throw": verb(1), "return": verb(2) }, typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (_) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
/* tslint:disable:template-click-events-have-key-events */
import { ChangeDetectorRef, EventEmitter, OnChanges, } from '@angular/core';
import { DownloadService } from '../../../shared/services/download.service';
import { I18nService } from '@wcd/i18n';
import { PendingActionsService } from '../services/pending-actions.service';
import { AuthService, tenantContextCache } from '@wcd/auth';
import { RemediationAction, RemediationActionTypeActionCount, RemediationActionTypeActionsRelationship, MdeUserRoleActionEnum, Investigation, } from '@wcd/domain';
import { DataViewComponent } from '../../../dataviews/components/dataview.component';
import { Paris } from '@microsoft/paris';
import { RemediationActionTypeFieldsService } from '../services/remediation-action-type.fields.service';
import { AppConfigService, ServiceUrlsService } from '@wcd/app-config';
import { merge } from 'lodash-es';
import { GlobalEntityTypesService } from '../../../global_entities/services/global-entity-types.service';
import { EntityPanelsService } from '../../../global_entities/services/entity-panels.service';
import { DialogsService } from '../../../dialogs/services/dialogs.service';
import { Feature, FeaturesService, AppContextService } from '@wcd/config';
import { RbacService } from '../../../rbac/services/rbac.service';
import { defer, of } from 'rxjs';
import { shareReplay, startWith } from 'rxjs/operators';
import { SccExportService } from '../../../shared/services/scc-export.service';
import { sccHostService, SccRoles } from '@wcd/scc-interface';
import { PerformanceSccService } from '../../../insights/services/performance.scc.service';
var PendingActionsTypeComponent = /** @class */ (function () {
    function PendingActionsTypeComponent(paris, pendingActionsService, i18nService, appContextService, sccExportService, serviceUrlsService, downloadService, entityPanelsService, dialogsService, featuresService, authService, rbacService, appConfigService, remediationActionTypeFieldsService, changeDetectorRef, performanceSccService, globalEntityTypesService) {
        this.paris = paris;
        this.pendingActionsService = pendingActionsService;
        this.i18nService = i18nService;
        this.appContextService = appContextService;
        this.sccExportService = sccExportService;
        this.serviceUrlsService = serviceUrlsService;
        this.downloadService = downloadService;
        this.entityPanelsService = entityPanelsService;
        this.dialogsService = dialogsService;
        this.featuresService = featuresService;
        this.authService = authService;
        this.rbacService = rbacService;
        this.appConfigService = appConfigService;
        this.remediationActionTypeFieldsService = remediationActionTypeFieldsService;
        this.changeDetectorRef = changeDetectorRef;
        this.performanceSccService = performanceSccService;
        this.showComments = tenantContextCache.hasMtpConsent || tenantContextCache.appConfig.IsMdatpActive;
        this.showInvestigationData = true;
        this.allowStatusSplit = false;
        this.onAction = new EventEmitter();
        this.refreshClick = new EventEmitter();
        this.remediationActionEntityType = globalEntityTypesService.getEntityType(RemediationAction);
        this.remediationActionsRepository = paris.getRelationshipRepository(RemediationActionTypeActionsRelationship);
        this.searchAndPurgeRole$ = defer(function () { return sccHostService.auth.isInRole(SccRoles.searchAndPurge); }).pipe(shareReplay({ bufferSize: 1, refCount: true }));
    }
    PendingActionsTypeComponent.prototype.setIsUserAllowed = function (dataChangedPayload) {
        var _this = this;
        var remediationActions = dataChangedPayload.data ? dataChangedPayload.data.items : [];
        this.isUserAllowed$ = defer(function () { return __awaiter(_this, void 0, void 0, function () {
            var isUserAllowedActions, isSecAdmin, isAllowed;
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        isUserAllowedActions = this.authService.currentUser.hasMdeAllowedUserRoleAction(MdeUserRoleActionEnum.remediationActions);
                        if (isUserAllowedActions) {
                            return [2 /*return*/, true];
                        }
                        isSecAdmin = this.authService.currentUser.isSecAdmin;
                        isAllowed = isSecAdmin || remediationActions.every(function (action) { return !action.isOfficeInvestigation; });
                        if (!(!isAllowed && this.appContextService.isSCC)) return [3 /*break*/, 2];
                        return [4 /*yield*/, this.searchAndPurgeRole$.toPromise()];
                    case 1: return [2 /*return*/, _a.sent()];
                    case 2: return [2 /*return*/, isAllowed];
                }
            });
        }); }).pipe(startWith(false));
        this.changeDetectorRef.markForCheck();
    };
    PendingActionsTypeComponent.prototype.onDataReceived = function (dataChangedPayload) {
        this.hasNewerData = false;
        this.setIsUserAllowed(dataChangedPayload);
        this.changeDetectorRef.markForCheck();
    };
    PendingActionsTypeComponent.prototype.ngOnChanges = function (changes) {
        if (changes.remediationActionTypeActions) {
            if ((changes.remediationActionTypeActions.previousValue &&
                changes.remediationActionTypeActions.previousValue.remediationActionType) !==
                changes.remediationActionTypeActions.currentValue.remediationActionType) {
                this.refreshDataviewType();
            }
            else if ((changes.remediationActionTypeActions.previousValue &&
                changes.remediationActionTypeActions.previousValue.actionCount) !==
                changes.remediationActionTypeActions.currentValue.actionCount) {
                this.hasNewerData = true;
            }
        }
        else if (changes.checkNewData) {
            this.hasNewerData = true;
        }
        if (changes.fixedOptions) {
            this.mergedFixedOptions = merge(this.featuresService.isEnabled(Feature.AirsApiOffloading) ? { useSevilleApi: true } : null, this.fixedOptions);
        }
    };
    PendingActionsTypeComponent.prototype.refreshDataviewType = function () {
        this.entityPanelsService.closeEntityPanel(RemediationAction);
        this.fields = this.remediationActionTypeFieldsService.getFieldsByRemediationType(this.remediationActionTypeActions.remediationActionType.type, this.showInvestigationData);
        this.remediationActionsRepository.sourceItem = this.remediationActionTypeActions.remediationActionType;
        this.setDataviewConfig();
        this.hasNewerData = false;
    };
    PendingActionsTypeComponent.prototype.onRefreshClick = function () {
        this.refreshClick.emit();
        this.refreshDataviewType();
    };
    PendingActionsTypeComponent.prototype.onDataLoadError = function (error) {
        this.pendingActionsService.handleDataLoadError(error);
        if (error.status !== 401 && error.status !== 405) {
            this.dialogsService.showError({
                title: "Error loading " + this.remediationActionsRepository.entity.pluralName,
                message: error.message || error,
                data: error,
            });
        }
    };
    PendingActionsTypeComponent.prototype.onTableRenderComplete = function () {
        this.performanceSccService.endNgPageLoadPerfSession('pending-actions-type');
    };
    PendingActionsTypeComponent.prototype.exportResults = function () {
        if (this.investigation && this.investigation.isOfficeInvestigation) {
            var url = '/api/AirModels/ExportActionList';
            // need added form inputs to specify the investigation ID in the outgoing POST request
            var formInputs = [
                {
                    name: 'ModelType',
                    value: 1,
                },
                {
                    name: 'ContainerUrn',
                    value: this.investigation.id,
                },
                {
                    name: 'IncludeFacts',
                    value: true,
                },
            ];
            this.sccExportService.exportSccData(url, formInputs);
        }
        else {
            var api = this.serviceUrlsService.automatedIr +
                (this.remediationActionTypeActions.remediationActionType.api ||
                    "/remediation_action/" + this.remediationActionTypeActions.remediationActionType.id + "/actions");
            return this.downloadService.downloadFromUrl("" + api + (/\/$/.test(api) ? 'all' : '') + ".csv");
        }
    };
    PendingActionsTypeComponent.prototype.setDataviewConfig = function () {
        var _this = this;
        this.dataViewConfig = {
            id: 'pending_' + this.remediationActionTypeActions.remediationActionType.type,
        };
        var isOfficeInvestigation = this.investigation && this.investigation.isOfficeInvestigation;
        if (!this.featuresService.isEnabled(Feature.AirsApiOffloading) ||
            this.appConfigService.isExposedToAllMachineGroups ||
            isOfficeInvestigation) {
            this.dataViewConfig = Object.assign({}, this.dataViewConfig, {
                exportResults: this.exportResults.bind(this),
                showModalOnExport: !isOfficeInvestigation,
            });
        }
        if (this.externalData) {
            this.dataViewConfig = Object.assign({}, this.dataViewConfig, {
                loadResults: function (options) {
                    var processedActions = _this.externalData;
                    if (options.page_size || options.page) {
                        var pageSize = options.page_size || _this.dataView.pageSize;
                        var page = options.page || 1;
                        var start = (page - 1) * pageSize;
                        processedActions = processedActions.slice(start, start + pageSize);
                    }
                    return of({ count: _this.externalData.length, items: processedActions });
                },
            });
        }
    };
    return PendingActionsTypeComponent;
}());
export { PendingActionsTypeComponent };
