import { Entity, EntityField, EntityGetMethod, EntityModelBase } from '@microsoft/paris';
import { WcdPortalParisConfig } from '../paris-config.interface';

@Entity({
	singularName: 'Sense endpoints management state',
	pluralName: 'Sense endpoints management state',
	endpoint: 'EndpointOnboardingStatus',
	parseItemQuery: () => 'EndpointOnboardingStatus',
	readonly: true,
	baseUrl: (config: WcdPortalParisConfig) => config.data.serviceUrls.management,
	supportedEntityGetMethods: [EntityGetMethod.getItem],
})
export class SenseEndpointsManagementState extends EntityModelBase {
	@EntityField({ data: 'MachineOnboarded', defaultValue: false })
	machineOnboarded: boolean;

	@EntityField({ data: 'RanTestAlert', defaultValue: false })
	ranTestAlert: boolean;
}
