import { Injectable } from '@angular/core';
import {
	CloseSessionApiCall,
	LiveResponseScript,
	LiveResponseSession,
	Machine,
	OperatingSystemPlatformCategories,
} from '@wcd/domain';
import { LiveResponseSessionEntityComponent } from '../components/live-response-session.entity.component';
import { LiveResponseSessionEntityDetailsComponent } from '../../../global_entities/components/entity-details/live-response-session.entity-details.component';
import { EntityTypeService } from '../../../global_entities/models/entity-type-service.interface';
import { EntityType } from '../../../global_entities/models/entity-type.interface';
import { FabricIconNames } from '@wcd/scc-common';
import { ItemActionModel } from '../../../dataviews/models/item-action.model';
import { RbacControlState } from '../../../rbac/models/rbac-control-settings.model';
import { RbacMdeAllowedActions } from '../../../rbac/enums/mde-allowed-actions.enum';
import { Paris } from '@microsoft/paris';
import { I18nService } from '@wcd/i18n';
import { DialogsService } from '../../../dialogs/services/dialogs.service';
import { DimensionsModel } from '../../../dialogs/models/dimensions.model';
import { LiveResponseScriptUploadComponent } from '../components/live-response-script-upload.component';
import { LiveResponseService } from './live-response.service';
import { GlobalEntityTypesService } from '../../../global_entities/services/global-entity-types.service';
import { Observable, of } from 'rxjs';
import { catchError, map } from 'rxjs/operators';
import { IsUserExposedToEntityResult } from '../../../rbac/services/rbac.service';
import { EntityPageViewMode } from '../../../global_entities/models/entity-page-view-mode.enum';
import { LiveResponseSessionHeaderBottomComponent } from '../../../global_entities/components/entity-header-bottom/live-response-session.header-bottom.component';
import { WcdIconNames } from '@wcd/icons-font';

@Injectable()
export class LiveResponseSessionEntityTypeService implements EntityTypeService<LiveResponseSession> {
	constructor(
		private paris: Paris,
		private i18nService: I18nService,
		private dialogsService: DialogsService,
		private liveResponseService: LiveResponseService,
		private globalEntityTypesService: GlobalEntityTypesService
	) {}

	readonly entityType: EntityType<LiveResponseSession> = {
		id: 'liveResponseSession',
		entity: LiveResponseSession,
		icon: FabricIconNames.Play,
		getIcon: sessions => {
			const icon = GlobalEntityTypesService.getCommonValue(sessions, session => {
				if (session.machine && session.machine.os) {
					if (session.machine.os.osPlatform.category === OperatingSystemPlatformCategories.macOS) {
						return WcdIconNames.mac;
					}
					if (session.machine.os.osPlatform.category === OperatingSystemPlatformCategories.Linux) {
						return WcdIconNames.linux;
					}
				}
				return FabricIconNames.WindowsLogo;
			});
			return icon || FabricIconNames.Play;
		},
		getIconCssClass: sessions => 'live-response-entity-icon',
		getImage: () => '/assets/images/icons/entities/live_response.svg',
		entityPageViewMode: EntityPageViewMode.Asset,
		entityContentsComponentType: LiveResponseSessionEntityComponent,
		entityDetailsComponentType: LiveResponseSessionEntityDetailsComponent,
		entityHeaderBottomComponentType: LiveResponseSessionHeaderBottomComponent,
		getEntityName: (session: LiveResponseSession) =>
			this.i18nService.get('liveResponse_session_name', {
				machineId:
					this.globalEntityTypesService.getEntityName(Machine, session.machine) ||
					session.machine.id,
			}),
		getActions: (sessions: Array<LiveResponseSession>) => {
			const actions = [];
			if (sessions.length === 1) {
				const session = sessions[0];
				if (session.status.isRunning) {
					actions.push(
						new ItemActionModel({
							id: 'closeSession',
							nameKey: 'liveResponse_actions_closeSession_title',
							rbac: [
								RbacMdeAllowedActions.liveResponseBasic,
								RbacMdeAllowedActions.liveResponseAdvanced,
							],
							rbacState: RbacControlState.disabled,
							icon: FabricIconNames.Stop,
							tooltip: this.i18nService.strings.liveResponse_actions_closeSession_tooltip,
							method: async (_sessions?: Array<LiveResponseSession>) => {
								const { confirmed } = await this.dialogsService.confirm({
									title: this.i18nService.strings.liveResponse_actions_closeSession_title,
									text: this.i18nService.strings.liveResponse_actions_closeSession_approve,
								});
								if (confirmed) {
									await this.paris.apiCall(CloseSessionApiCall, _sessions[0]).toPromise();
									return this.liveResponseService.resetSession(_sessions[0]);
								}
								return Promise.reject();
							},
						})
					);
					actions.push(
						new ItemActionModel({
							id: 'uploadScript',
							nameKey: 'liveResponse_actions_uploadScript_title',
							rbac: [RbacMdeAllowedActions.securitySettings],
							rbacState: RbacControlState.disabled,
							icon: FabricIconNames.Upload,
							tooltip: this.i18nService.strings.liveResponse_actions_uploadScript_tooltip,
							refreshOnResolve: false,
							method: (_sessions: Array<LiveResponseSession>) => {
								return this.dialogsService
									.showModal(
										LiveResponseScriptUploadComponent,
										{
											id: 'live-response-script-upload',
											title: this.i18nService.strings
												.liveResponse_actions_uploadScript_title,
											dimensions: new DimensionsModel(600, 370),
										},
										{
											script: new LiveResponseScript(null),
										}
									)
									.toPromise();
							},
						})
					);
				}
			}
			return actions;
		},
		isUserExposedToEntityById: (sessionId: string): Observable<boolean | IsUserExposedToEntityResult> => {
			return this.paris.getItemById(LiveResponseSession, sessionId, null,  { useV3Api: sessionId.startsWith('CLR')}).pipe(
				map(liveResponseSession => {
					return true;
				}),
				catchError(error => {
					return of({ isExposed: error.status !== 403, reason: error.message });
				})
			);
		},
		getItemParams: (entity : LiveResponseSession, options) => ({
			useV3Api: (options && options["routeId"] && options["routeId"].startsWith('CLR')) || entity && entity.useV3Api
		}),
	};
}
