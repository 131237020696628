import { Injectable } from '@angular/core';
import { Paris } from '@microsoft/paris';
import { map, shareReplay } from 'rxjs/operators';

import { Alert, FileVerdict, FileVerdictAlertsRelationship } from '@wcd/domain';

@Injectable()
export class FileVerdictAlertService {
	constructor(private readonly paris: Paris) {}

	getFileVerdictAlerts(sha1: string, fileVerdict: FileVerdict) {
		return this.paris
			.queryForItem<FileVerdict, Alert>(FileVerdictAlertsRelationship, fileVerdict, { where: { sha1 } })
			.pipe(
				map(result => result.items),
				shareReplay(1, 1000)
			);
	}
}
