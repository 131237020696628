import { ValueObject, EntityField } from '@microsoft/paris';
import { AuthenticationParams, AuthenticationParamsSerializationType } from './authentication-params.value-object';

export enum WindowsAuthType {
	LocalNtlm = 'NTLM (local)',
	DomainNtlm = 'NTLM (domain)',
	Kerberos = 'Kerberos',
}

@ValueObject({
	singularName: 'Windows authentication parameters',
	pluralName: 'Windows authentication parameters',
})
export class WindowsAuthParams extends AuthenticationParams {
	@EntityField()
	type?: string;

	@EntityField()
	username?: string;

	@EntityField()
	password?: string;

	@EntityField()
	domain?: string;

	constructor(previous?: WindowsAuthParams) {
		super(AuthenticationParamsSerializationType.WindowsAuthParams, previous);
	}
}
