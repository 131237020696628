import { EntityField, ModelBase, ValueObject, Entity } from '@microsoft/paris';
import { LogonType } from '../../user/logon-type.enum';
import { WcdPortalParisConfig } from '../../paris-config.interface';

@Entity({
	singularName: 'User Associated Info On Device',
	pluralName: '',
	readonly: true,
	fixedData: {
		lookingBackInDays: 180,
	},
	endpoint: 'UserAssociatedInfoOnMachine',
	allItemsEndpointTrailingSlash: false,
	baseUrl: (config: WcdPortalParisConfig) => config.data.serviceUrls.threatIntel,
})
export class LegacyUserMachineAssociatedInfo extends ModelBase {
	@EntityField({ data: 'FirstSeen' })
	readonly firstSeen: Date;

	@EntityField({ data: 'FirstSeen' })
	readonly lastSeen: Date;

	@EntityField({
		data: 'LogonType',
	})
	readonly logonType: LogonType;
}
