import { assignIn } from 'lodash-es';
/**
 * SCC Http client facade, leverages Axios:
 * API calls with with bearer token for free
 */
function hackToInferTenantsUntilCuB() {
    return new URLSearchParams(location.search).get('tenantIds') || JSON.parse(localStorage.getItem('tenantIds') || "null");
}
var SccAjaxService = /** @class */ (function () {
    function SccAjaxService(sccAjax, tenantId, mock, i18n, ui) {
        this.sccAjax = sccAjax;
        this.tenantId = tenantId;
        this.mock = mock;
        this.i18n = i18n;
        this.ui = ui;
    }
    Object.defineProperty(SccAjaxService.prototype, "apcToken", {
        get: function () {
            try {
                return sessionStorage.getItem(SccAjaxService.APC_HEADER);
            }
            catch (_a) {
                console.warn('Failed to access session storage');
                return null;
            }
        },
        set: function (token) {
            try {
                sessionStorage.setItem(SccAjaxService.APC_HEADER, token);
            }
            catch (_a) {
                console.warn('Failed to access session storage');
            }
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(SccAjaxService.prototype, "defaultConfig", {
        get: function () {
            var config = {
                withCredentials: true,
                customError: true,
                headers: {
                    // TODO(benjamingr) need to pass in tenantId
                    // 'MTO-Context': JSON object with tenantIds
                    // e.g. { tenantIds: ['<tenantId>', '<tenantId2']>
                    // Need to pass this header in MTO anyway for loggin in SCC in addition to MTO-Context
                    'Tenant-Id': this.tenantId,
                    // For scc ajax to work, we must support a service, in mock, we use MATP as default(
                    // dosnt really matter, this just tells the proxy what token/audinace to use, mock server dont care about such things only real server do
                    'X-ServiceType': this.mock.isMockMode ? 'MATP' : '',
                    'accept-language': this.i18n.language,
                },
            };
            if (this.apcToken) {
                config.headers[SccAjaxService.APC_HEADER] = this.apcToken;
            }
            return config;
        },
        enumerable: true,
        configurable: true
    });
    SccAjaxService.prototype.setRequestConfig = function (config) {
        var conf = config || {};
        var headers = assignIn({}, this.defaultConfig.headers, conf.headers);
        if (config && config.url) {
            config.url = this.transformUrl(config.url);
        }
        var isMto = (new URL(location.href).searchParams.get('mto')) || localStorage.getItem('mto');
        if (isMto) { // TODO(bgruenbaum) temporary hack to unblock
            headers['MTO-Context'] = JSON.stringify({
                targetTenantIds: (config && config.params && config.params.tenantIds) || hackToInferTenantsUntilCuB(),
            });
        }
        return assignIn({}, this.defaultConfig, conf, { headers: headers });
    };
    SccAjaxService.prototype.transformUrl = function (url) {
        if (this.mock.isMockMode && !url.startsWith(this.mock.mockHost)) {
            url = url.replace('<msgraph>/beta', '/api');
            url = url.replace(/(?=<)(.*?)(?=\/)/, '');
            return this.mock.mockHost + url;
        }
        return url;
    };
    SccAjaxService.prototype.afterRequest = function (options, res) {
        try {
            var apc = res && res.headers ? res.headers[SccAjaxService.APC_HEADER] : null;
            if (apc)
                this.apcToken = apc;
            if (!res) {
                return;
            }
            var isMto = res && res.data && res.data.isMtoResponse;
            var metadata = res.data.metadata;
            //TODO(benji): log these errors better
            if (isMto && Object.values(metadata).some(function (x) { return x.status > 299; })) {
                this.ui.showError(metadata.map(function (x) { return x.message; }).join('\n'));
            }
            if (isMto && !options.showFullResponse) {
                res.data = res.data.result; // remove metadata
            }
            // if (isMto && Array.isArray(res.data) && res.data.length === 1) {
            // 	return { ...res, data: res.data[0]};
            // }
            return res;
        }
        catch (e) {
            console.error('error unwrapping mto response', e);
            return res;
        }
    };
    SccAjaxService.prototype.request = function (config) {
        return this.sccAjax.request(this.setRequestConfig(config)).then(this.afterRequest.bind(this, {}));
    };
    SccAjaxService.prototype.get = function (url, config) {
        return this.sccAjax
            .get(this.transformUrl(url), this.setRequestConfig(config))
            .then(this.afterRequest.bind(this, {}));
    };
    SccAjaxService.prototype.getFull = function (url, config) {
        return this.sccAjax
            .get(this.transformUrl(url), this.setRequestConfig(config))
            .then(this.afterRequest.bind(this, { showFullResponse: true }));
    };
    SccAjaxService.prototype.delete = function (url, config) {
        return this.sccAjax
            .delete(this.transformUrl(url), this.setRequestConfig(config))
            .then(this.afterRequest.bind(this, {}));
    };
    SccAjaxService.prototype.post = function (url, data, config) {
        return this.sccAjax
            .post(this.transformUrl(url), data, this.setRequestConfig(config))
            .then(this.afterRequest.bind(this, {}));
    };
    SccAjaxService.prototype.patch = function (url, data, config) {
        return this.sccAjax
            .patch(this.transformUrl(url), data, this.setRequestConfig(config))
            .then(this.afterRequest.bind(this));
    };
    SccAjaxService.prototype.put = function (url, data, config) {
        return this.sccAjax
            .put(this.transformUrl(url), data, this.setRequestConfig(config))
            .then(this.afterRequest.bind(this, {}));
    };
    SccAjaxService.APC_HEADER = 'apc';
    return SccAjaxService;
}());
export { SccAjaxService };
