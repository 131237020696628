var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (b.hasOwnProperty(p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
import { ApiCall, ApiCallModel } from '@microsoft/paris';
var ɵ0 = function (config, query) {
    return "machines/" + query.where['machineId'] + "/eventMark";
}, ɵ1 = function (config) { return config.data.serviceUrls.threatIntel; }, ɵ2 = function (query) { return ({
    data: {
        isMarked: query.isMarked,
        actionTimeIsoString: query.cyberEvent.actionTimeIsoString,
        reportId: query.cyberEvent.reportId,
        actionType: query.cyberEvent.rawActionType,
        machineId: query.cyberEvent.machine.id,
        alertId: query.cyberEvent.relatedAlert && query.cyberEvent.relatedAlert.id,
    },
    params: {
        machineId: query.cyberEvent.machine.id,
    },
}); };
/**
 * Mark cyber event
 */
var CyberEventMarkApiCall = /** @class */ (function (_super) {
    __extends(CyberEventMarkApiCall, _super);
    function CyberEventMarkApiCall() {
        return _super !== null && _super.apply(this, arguments) || this;
    }
    CyberEventMarkApiCall = __decorate([
        ApiCall({
            name: 'Cyber event mark',
            endpoint: ɵ0,
            baseUrl: ɵ1,
            method: 'POST',
            cache: false,
            parseQuery: ɵ2,
        })
    ], CyberEventMarkApiCall);
    return CyberEventMarkApiCall;
}(ApiCallModel));
export { CyberEventMarkApiCall };
export { ɵ0, ɵ1, ɵ2 };
