import { SevilleAlertsModule } from '../alerts/seville.alerts.module';
import '../../seville/services/seville.sidepane';
import { AppInsightsService } from '../../../../insights/services/app-insights.service';
import { RbacMdeAllowedActions } from '../../../../rbac/enums/mde-allowed-actions.enum';
import { AlertsService } from '../../../../@entities/alerts/services/alerts.service';
import { EntityPanelsService } from '../../../../global_entities/services/entity-panels.service';

SevilleAlertsModule.factory('alertManagementService', alertsManagement);

alertsManagement.$inject = [
	'$log',
	'$http',
	'$window',
	'urlMapping',
	'sidePaneService',
	'$rootScope',
	'authenticationService',
	'$uibModal',
	'$q',
	'appInsights',
	'alertsService',
	'entityPanelsService',
];

function alertsManagement(
	$log,
	$http,
	$window,
	urlMapping,
	sidePaneService,
	$rootScope,
	authenticationService,
	$uibModal,
	$q,
	appInsights: AppInsightsService,
	alertsService: AlertsService,
	entityPanelsService: EntityPanelsService
) {
	var alertsInvestigationAllowed = authenticationService.isUserActionAllowed([
		RbacMdeAllowedActions.alertsInvestigation,
	]);

	var alerts = [];
	var alertIds = [];

	function openSidePane() {
		if (!alerts || !alerts.length) entityPanelsService.closeAllEntityPanels();
		else alertsService.showAlertsSidePanelByRawData(alerts);
	}

	function isExactSelection(selectedAlerts) {
		if (!alertIds) return false;
		if (alertIds.length != selectedAlerts.length) return false;

		for (var i = 0; i < selectedAlerts.length; i++) {
			if (alertIds.indexOf(selectedAlerts[i].AlertId) < 0) return false;
		}

		return true;
	}

	function raiseAlertsUpdated() {
		$rootScope.$broadcast('alertsMgmt:alertsUpdated', { alerts: alerts });
	}

	function addRemoveAlerts(records, addOnly?) {
		for (var i = 0; i < records.length; i++) {
			var alert = records[i];
			if (alertIds.indexOf(alert.AlertId) >= 0) {
				if (!addOnly) {
					alerts = alerts.filter(function(a) {
						return a.AlertId !== alert.AlertId;
					});
					var index = alertIds.indexOf(alert.AlertId);
					alertIds.splice(index, 1);
				}
			} else {
				alerts.push(alert);
				alertIds.push(alert.AlertId);
			}
		}
	}

	// gets a link to private blob containing actor report and an error message to log if not succeded.
	// address server to get link with access to the private blob and start download.
	function downlodReportUsingSasToken(linkToReport) {
		var errorMessage = 'Error while trying to download report.';
		$http
			.get(urlMapping.getThreatIntelUrl() + '/GetActorSasBlobLink', {
				params: {
					reportBlobLink: linkToReport,
				},
			})
			.then(function(response) {
				if (response && response.status === 200 && response.data) {
					// start the download of the actor report. will start the download and not open the file since the content type of this blobs is set to application/octet-stream.
					window.location.href = response.data;
				} else {
					$log(errorMessage + response);
				}
			})
			.catch(function(error) {
				$log(errorMessage + error);
			});
	}

	var service = {
		selectAlerts: function(alertsToSelect, noToggle) {
			var exactSelection = isExactSelection(alertsToSelect);

			if (exactSelection && noToggle) {
				return;
			}

			alerts = [];
			alertIds = [];

			if (!exactSelection) {
				addRemoveAlerts(alertsToSelect);
			}

			return openSidePane();
		},

		toggleInSelection: function(alertsToSelect, addOnly) {
			addRemoveAlerts(alertsToSelect, addOnly);
			openSidePane();
		},

		getSelectedIds: function() {
			return alertIds;
		},

		isManaged: function(alertId) {
			return alertIds && alertIds.indexOf(alertId) >= 0;
		},

		addComment: function(newComment, callback) {
			if (!alertsInvestigationAllowed) {
				$log.debug('user role is read only - cannot post a comment.');
				callback(null);
				return;
			}

			if (!newComment || newComment.length < 2) {
				$window.alert('Comment must be at least 2 characters long');
				callback(null);
				return;
			}

			$http
				.put(urlMapping.getThreatIntelUrl() + '/Alerts', {
					AlertIds: alertIds,
					UpdateType: 'userdata',
					ClientFeedback: newComment,
				})
				.then(function(response) {
					appInsights.trackEvent('UsageTrack', {
						ButtonType: 'AlertMgmtSidePane',
						Page: 'Alert',
						Component: 'AlertMgmtChangeAddComment',
					});
					raiseAlertsUpdated();
					if (callback) callback(response.data);
				});
		},

		getAlertHistory: function(pageIndex, pageSize, callback, alertId) {
			if (!alertId && (!alerts || alerts.length !== 1)) return;
			if (!callback) return;

			alertId = alertId || alerts[0].AlertId;
			$http
				.get(urlMapping.getThreatIntelUrl() + '/AuditHistory', {
					params: {
						id: alertId,
						pageIndex: pageIndex,
						pageSize: pageSize,
					},
				})
				.then(function(response) {
					if (response.status === 200) {
						callback(response.data);
					} else {
						callback(null);
					}
				});
		},

		downlodReportUsingSasToken: downlodReportUsingSasToken,
	};

	return service;
}
