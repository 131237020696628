import { Component, OnInit } from '@angular/core';
import { Alert } from '@wcd/domain';
import { Router, ActivatedRoute, Params } from '@angular/router';
import { Paris, Repository } from '@microsoft/paris';

@Component({
	selector: 'incident-by-alert',
	template: `
		<div *ngIf="isLoading" class="wcd-full-height loading-overlay">
			<i class="large-loader-icon"></i>
		</div>
	`,
})
export class IncidentByAlertComponent implements OnInit {
	isLoading: boolean = false;

	constructor(private paris: Paris, private router: Router, private route: ActivatedRoute) {}

	ngOnInit(): void {
		this.isLoading = true;
		const params: Params = this.route.snapshot.queryParams;
		const alertsRepo: Repository<Alert> = this.paris.getRepository(Alert);

		alertsRepo.getItemById(params.alertId).subscribe(
			(alert: Alert) => {
				this.isLoading = false;
				this.router.navigate(['/incidents', alert.incidentId]);
			},
			() => {
				this.isLoading = false;
				this.router.navigate(['/incidents']);
			}
		);
	}
}
