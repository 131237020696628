import { EntityField, ModelBase, ValueObject } from '@microsoft/paris';

@ValueObject({
	singularName: 'Entity tags',
	pluralName: '',
	readonly: true,
})
export class EntityTags extends ModelBase {

	@EntityField({ data: ["ActorNames", "actorNames", "ActorName", "actorName"] })
	readonly actorNames?: string | string[];

	@EntityField({ data: ["DeviceTags", "deviceTags"] })
	readonly deviceTags?: string[];

	@EntityField({ data: ["UserTags", "userTags"] })
	readonly userTags?: string[];

	@EntityField({ data: ["IsRansomware", "isRansomware"] })
	readonly isRansomware?: boolean;

	@EntityField({ data: ["IsTelemetrySpoofing", "isTelemetrySpoofing"] })
	readonly isTelemetrySpoofing?: boolean;

	@EntityField({ data: ["IsThreatExpert", "isThreatExpert"] })
	readonly isThreatExpert?: boolean;

	@EntityField({ data: ["IsOfficeHVE", "isOfficeHVE"] })
	readonly isOfficeHVE?: boolean;

	@EntityField({ data: ["IsChainEventDetection", "isChainEventDetection"] })
	readonly isChainEventDetection?: boolean;

}
