import { ChangeDetectionStrategy, Component, OnInit, ChangeDetectorRef } from '@angular/core';
import { VulnerabilityNotificationRuleFormData } from '../../models/vulnerability-notification-rule.models';
import { WizardBaseStep, SHARED_FORM_PROVIDER } from '@wcd/wizard';
import { I18nService } from '@wcd/i18n';
import { VulnerabilityChangeEventType, SeverityValue, MachineGroup } from '@wcd/domain';
import { ChecklistValue } from '@wcd/forms';
import { Repository, Paris } from '@microsoft/paris';
import { MachinesService } from '../../../../machines/services/machines.service';
import { Observable, of, combineLatest } from 'rxjs';
import { tap, mergeMap } from 'rxjs/operators';
import { AuthService } from '@wcd/auth';
import { TvmNotificationsTextsService } from '../../../../../tvm/services/tvm-notifications-texts.service';
import { AppConfigService } from '@wcd/app-config';
import { Feature, FeaturesService, TvmLicensesAngularService } from '@wcd/config';
import { FlavorService } from '@wcd/config';
import { AppFlavorConfig, TvmLicenseType } from '@wcd/scc-common';

enum SELECTABLE_MACHINE_GROUP_SCOPE_IDS {
	all = 'all',
	specific = 'specific',
}

@Component({
	selector: 'vulnerability-email-notification-settings-step',
	viewProviders: [SHARED_FORM_PROVIDER],
	changeDetection: ChangeDetectionStrategy.OnPush,
	templateUrl: './vulnerability-email-notification-settings-step.component.html',
	styleUrls: ['./vulnerability-email-notification-settings-step.component.scss'],
})
export class VulnerabilityEmailNotificationSettingsStepComponent
	extends WizardBaseStep<VulnerabilityNotificationRuleFormData>
	implements OnInit {
	isUserAllowedActions: boolean;
	selectableEventTypes: Array<ChecklistValue>;
	eventTypeLabels: Map<VulnerabilityChangeEventType, string>;
	severityLevels: Array<ChecklistValue>;
	shouldShowSeverityCheckBox: boolean;
	shouldShowSeveritySelection: boolean;
	currentSeverity: ChecklistValue;
	severityNote: string = '';
	loadingMachineGroups: boolean = true;
	machineGroupsRepo: Repository<MachineGroup>;
	allowSpecificMachineGroups: boolean;
	allowAllMachineGroups: boolean;
	allMachineGroups: Array<MachineGroup>;
	currentMachineGroups: Array<ChecklistValue> = [];
	availableMachineGroups: Array<ChecklistValue> = [];
	currentMachineGroupScope: ChecklistValue;
	selectableMachineGroupScopes: Array<ChecklistValue>;
	machineGroupLabelText: string;
	changeEventTypes: VulnerabilityChangeEventType[] =
		[VulnerabilityChangeEventType.NewCve,
		VulnerabilityChangeEventType.CveHasVerifiedExploit,
		VulnerabilityChangeEventType.CveHasPubliclyDisclosedExploit,
		VulnerabilityChangeEventType.CveHasExploitInKit];
	eventTypesCount = 0;
	tenantHasMachineGroups: boolean;
	isDeviceScopeEnabled: boolean;

	constructor(
		private i18n: I18nService,
		public machinesService: MachinesService,
		private changeDetectorRef: ChangeDetectorRef,
		private authService: AuthService,
		paris: Paris,
		tvmNotificationsTextsService: TvmNotificationsTextsService,
		appConfigService: AppConfigService,
		flavorService: FlavorService,
		tvmLicensesService: TvmLicensesAngularService
	) {
		super();
		this.tenantHasMachineGroups = appConfigService.hasMachineGroups;
		this.isDeviceScopeEnabled = flavorService.isEnabled(AppFlavorConfig.settings.deviceGroups) || 
			(!flavorService.isEnabled(AppFlavorConfig.settings.mdeAttach) && tvmLicensesService.isEnabled(TvmLicenseType.TvmBasic));
		this.severityLevels = Object.values(SeverityValue).map(severityLevel => ({
			id: severityLevel,
			name: this.i18n.get('tvm_notifications_vulnerability_' + severityLevel),
		}));
		this.selectableMachineGroupScopes = Object.values(SELECTABLE_MACHINE_GROUP_SCOPE_IDS).map(
			scopeId => ({
				id: scopeId,
				name: this.i18n.get('email_notification_wizard_deviceGroupScopeOption_' + scopeId),
			})
		);
		this.eventTypeLabels = tvmNotificationsTextsService.getChangeEventTypesLabels(this.changeEventTypes);
		this.machineGroupsRepo = paris.getRepository(MachineGroup);
	}

	ngOnInit(): void {
		this.initializeSeverityInformation();
		this.eventTypesCount = this.changeEventTypes.filter(e => this.data.eventTypes[e]).length;
		this.setMachineGroups().subscribe(() => {
			this.setCurrentMachineGroupsScope();
			this.setCurrentMachineGroups(this.data.machineGroups);
			this.checkValidation();
		});
	}

	setMachineGroups(): Observable<Array<MachineGroup>> {
		return this.machineGroupsRepo.allItems$.pipe(
			tap((groups: Array<MachineGroup>) => {
				this.loadingMachineGroups = false;
				this.allMachineGroups = groups;
				this.setAvailableScopes();
			}),
			mergeMap(() => this.getUserExposedRbacGroups())
		);
	}

	onMachineGroupsChange(selectedMachineGroups: Array<ChecklistValue>) {
		if (!(selectedMachineGroups && selectedMachineGroups.length))
			this.setCurrentMachineGroups((this.data.machineGroups = []));
		else {
			of(selectedMachineGroups)
				.pipe(
					mergeMap((_selectedMachineGroups: Array<ChecklistValue>) =>
						combineLatest(
							_selectedMachineGroups.map(selectedGroup =>
								this.machineGroupsRepo.getItemById(selectedGroup.id)
							)
						)
					)
				)
				.subscribe((machineGroups: Array<MachineGroup>) => {
					this.setCurrentMachineGroups((this.data.machineGroups = machineGroups));
				});
		}
	}

	onMachineGroupScopeChange(selectedScope: ChecklistValue) {
		this.data.allRbacGroups = selectedScope.id === 'all';
		this.checkValidation();
		setTimeout(() => this.changeDetectorRef.detectChanges(), 0);
	}

	private setAvailableScopes() {
		if (this.allMachineGroups.length) {
			this.allowSpecificMachineGroups = true;
			if (this.authService.currentUser.isMdeAdmin) {
				this.allowAllMachineGroups = true;
			}
		} else {
			this.data.allRbacGroups = true;
		}
	}

	private getUserExposedRbacGroups(): Observable<Array<MachineGroup>> {
		return this.machinesService.getFullUserExposedMachineGroups().pipe(
			tap((userExposedMachineGroups?: Array<MachineGroup>) => {
				this.availableMachineGroups = userExposedMachineGroups.map((machineGroup: MachineGroup) =>
					this.getMachineGroupCheckListValue(machineGroup)
				);
			})
		);
	}

	private setCurrentMachineGroupsScope() {
		if (this.data.allRbacGroups) {
			this.currentMachineGroupScope = this.selectableMachineGroupScopes.find(
				scope => scope.id === SELECTABLE_MACHINE_GROUP_SCOPE_IDS.all
			);
		} else if (this.data.machineGroups && this.data.machineGroups.length) {
			this.currentMachineGroupScope = this.selectableMachineGroupScopes.find(
				scope => scope.id === SELECTABLE_MACHINE_GROUP_SCOPE_IDS.specific
			);
		}
	}

	private setCurrentMachineGroups(machineGroups: Array<MachineGroup>) {
		this.currentMachineGroups = machineGroups.map((machineGroup: MachineGroup) =>
			this.getMachineGroupCheckListValue(machineGroup)
		);
		this.updateMachineGroupLabel(machineGroups);
		this.checkValidation();
		setTimeout(() => this.changeDetectorRef.detectChanges(), 0);
	}

	private updateMachineGroupLabel(machineGroups: Array<MachineGroup>) {
		if (machineGroups.length > 3) {
			this.machineGroupLabelText = this.i18n.get('notificationRules_editRule_manyValues', {
				length: machineGroups.length,
			});
		} else if (machineGroups.length > 0) {
			this.machineGroupLabelText = this.currentMachineGroups.map(machine => machine.name).join(', ');
		} else {
			this.machineGroupLabelText = this.i18n.strings.notificationRules_machineGroups_selectValues;
		}
	}

	private getMachineGroupCheckListValue(machineGroup: MachineGroup): ChecklistValue {
		return {
			id: machineGroup.id,
			name: machineGroup.isUnassignedMachineGroup
				? this.i18n.strings.machineGroup_unassignedGroup_name
				: machineGroup.name,
		};
	}

	initializeSeverityInformation() {
		const defaultSeverity = this.data.severityLevelForNewCve || SeverityValue.Critical;
		this.currentSeverity = this.severityLevels.find(severity => severity.id === defaultSeverity);
		this.shouldShowSeveritySelection = !!this.data.severityLevelForNewCve;
		this.shouldShowSeverityCheckBox = this.data.eventTypes[VulnerabilityChangeEventType.NewCve];
		this.setSeverityNote();
	}

	onEventTypeChange(eventType: VulnerabilityChangeEventType, checked: boolean) {
		if (checked) {
			this.eventTypesCount++;
			if (eventType == VulnerabilityChangeEventType.NewCve) {
				this.shouldShowSeverityCheckBox = true;
				this.updateSeverityLevel();
			}
		} else {
			this.eventTypesCount--;
			if (eventType == VulnerabilityChangeEventType.NewCve) {
				this.shouldShowSeverityCheckBox = false;
				this.data.severityLevelForNewCve = undefined;
			}
		}
		this.checkValidation();
		this.changeDetectorRef.detectChanges();
	}

	onSeverityCheckboxSelection() {
		this.updateSeverityLevel();
	}

	updateSeverityLevel() {
		this.data.severityLevelForNewCve = this.shouldShowSeveritySelection
			? this.currentSeverity.id
			: undefined;
		this.setSeverityNote();
		this.changeDetectorRef.detectChanges();
	}

	private setSeverityNote() {
		this.severityNote = this.i18n.get(
			`vulnerability_notifications_severity_threshold_note_${this.currentSeverity.id}`
		);
	}

	private checkValidation() {
		this.setStepValidation(
			this.eventTypesCount > 0 && (!this.tenantHasMachineGroups || this.data.machineGroups.length > 0 || this.data.allRbacGroups)
		);
	}

	onModelChange() {
		this.changeDetectorRef.detectChanges();
	}
}
