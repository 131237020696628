import { Injectable } from '@angular/core';
import { Machine, OperatingSystemPlatformCategories } from '@wcd/domain';
import { FeaturesService, Feature } from '@wcd/config';

export enum TvmOsSupportLevel {
	None,
	Full,
	Partial,
	TvmDataCookingEnableMacOS,
	TvmDataCookingEnableWindows81,
	TvmDataCookingEnableAndroid,
	TvmDataCookingEnableiOS,
}

@Injectable()
export class TvmOsSupportService {
	readonly supportedOsPlatformCategories: OperatingSystemPlatformCategories[];
	isNotMangedDevicesFeatureFlagEnabled: boolean;

	private readonly platformToTvmSupportLevel: Partial<
		Record<OperatingSystemPlatformCategories, TvmOsSupportLevel>
	> = {
		[OperatingSystemPlatformCategories.Windows11]: TvmOsSupportLevel.Full,
		[OperatingSystemPlatformCategories.Windows10]: TvmOsSupportLevel.Full,
		[OperatingSystemPlatformCategories.Windows10WVD]: TvmOsSupportLevel.Full,
		[OperatingSystemPlatformCategories.WindowsServer2019]: TvmOsSupportLevel.Full,
		[OperatingSystemPlatformCategories.WindowsServer2022]: TvmOsSupportLevel.Full,
		[OperatingSystemPlatformCategories.Windows8Blue]: TvmOsSupportLevel.TvmDataCookingEnableWindows81,
		[OperatingSystemPlatformCategories.Windows7]: TvmOsSupportLevel.Partial,
		[OperatingSystemPlatformCategories.WindowsServer2016]: TvmOsSupportLevel.Full,
		[OperatingSystemPlatformCategories.WindowsServer2012R2]: TvmOsSupportLevel.Full,
		[OperatingSystemPlatformCategories.WindowsServer2008R2]: TvmOsSupportLevel.Full,
		[OperatingSystemPlatformCategories.macOS]: TvmOsSupportLevel.TvmDataCookingEnableMacOS,
		[OperatingSystemPlatformCategories.Linux]: TvmOsSupportLevel.Full,
		[OperatingSystemPlatformCategories.Android]: TvmOsSupportLevel.TvmDataCookingEnableAndroid,
		[OperatingSystemPlatformCategories.iOS]: TvmOsSupportLevel.TvmDataCookingEnableiOS,
	};

	constructor(private featuresService: FeaturesService) {
		this.supportedOsPlatformCategories = Object.entries(this.platformToTvmSupportLevel)
			.map(([osPlatCat, supportLevel]) => {
				let isSupported = false;
				switch (supportLevel) {
					case TvmOsSupportLevel.Full:
					case TvmOsSupportLevel.Partial:
						isSupported = true;
						break;
					case TvmOsSupportLevel.TvmDataCookingEnableMacOS:
						isSupported = this.featuresService.isEnabled(Feature.TvmDataCookingEnableMacOS);
						break;
					case TvmOsSupportLevel.TvmDataCookingEnableWindows81:
						isSupported = this.featuresService.isEnabled(Feature.TvmEnableWindows81);
						break;
					case TvmOsSupportLevel.TvmDataCookingEnableAndroid:
						isSupported = this.featuresService.isEnabled(Feature.TvmDataCookingEnableAndroid);
						break;
					case TvmOsSupportLevel.TvmDataCookingEnableiOS:
						isSupported = this.featuresService.isEnabled(Feature.TvmDataCookingEnableiOS);
						break;
				}
				return isSupported ? osPlatCat : null;
			})
			.filter(Boolean) as OperatingSystemPlatformCategories[];

		this.isNotMangedDevicesFeatureFlagEnabled = this.featuresService.isEnabled(
			Feature.NdrEnableVulnerabilitiesAssessment
		);
	}

	isMachineSupported(machine: Machine): boolean {
		if (this.isNotMangedDevicesFeatureFlagEnabled && this.isNotManagedDevice(machine)) {
			return true;
		}

		if (!machine.os || !machine.os.osPlatformString) {
			return false;
		}

		return this.supportedOsPlatformCategories.some(o => o === machine.os.osPlatformString);
	}

	getSupportLevel(machine: Machine): TvmOsSupportLevel {
		if (this.isNotMangedDevicesFeatureFlagEnabled && this.isNotManagedDevice(machine)) {
			return TvmOsSupportLevel.Full;
		}
		const osPlatform: OperatingSystemPlatformCategories =
			OperatingSystemPlatformCategories[machine.os.osPlatformString];
		return this.supportedOsPlatformCategories.includes(osPlatform)
			? TvmOsSupportLevel.Full
			: TvmOsSupportLevel.None;
	}

	private isNotManagedDevice(machine: Machine) {
		// for itp devices this boolean will be always undefined so compering to false
		return machine.isManagedByMdatp === false;
	}
}
