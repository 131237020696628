import { Component, ChangeDetectionStrategy } from '@angular/core';
import { EntityPrevalenceWidgetBase } from '../../../../global_entities/components/entity-widgets/prevalence/entity-prevalence.widget.base';
import { Url, UrlUrlStatsRelationship } from '@wcd/domain';
import { Paris } from '@microsoft/paris';
import { ActivatedEntity } from '../../../../global_entities/services/activated-entity.service';
import { ReportsService } from '../../../../shared-reports/services/reports.service';
import { I18nService } from '@wcd/i18n';
import { TimeRangesService } from '../../../../shared/services/time-ranges.service';

@Component({
	changeDetection: ChangeDetectionStrategy.OnPush,
	templateUrl:
		'../../../../global_entities/components/entity-widgets/prevalence/entity-prevalence.widget.html',
})
export class UrlPrevalenceWidgetComponent extends EntityPrevalenceWidgetBase<Url> {
	constructor(
		paris: Paris,
		activatedEntity: ActivatedEntity,
		reportsService: ReportsService,
		i18nService: I18nService,
		timeRangeService: TimeRangesService
	) {
		super(paris, activatedEntity, reportsService, i18nService, timeRangeService);
	}

	id = 'url-prevalence-widget';

	entityType = Url;

	entityStatisticsRelationship = UrlUrlStatsRelationship;
}
