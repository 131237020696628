import { Injectable, OnDestroy } from '@angular/core';
import { Repository, Paris } from '@microsoft/paris';
import { AssessmentJob, AssessmentType,  DeleteAuthenticatedScan, SnmpAuthParams, WindowsAuthParams, WindowsAuthType } from '@wcd/domain';
import { Subscription } from 'rxjs';
import { ConfirmEvent } from '../../../../dialogs/confirm/confirm.event';
import { I18nService } from '@wcd/i18n';
import { DialogsService } from '../../../../dialogs/services/dialogs.service';
import { Feature, FeaturesService, TvmLicensesAngularService } from '@wcd/config';
import { TvmLicenseType } from '@wcd/scc-common';

@Injectable()
export class AssessmentJobService implements OnDestroy {
	repository: Repository<AssessmentJob>;
	scanNames: Set<string> = new Set<string>();
	maxAllowedIpAddressesForScan = 1024;

	_scanNamesSubscription: Subscription;

	constructor(
		private paris: Paris,
		private i18nService: I18nService,
		private dialogsService: DialogsService,
		private featuresService: FeaturesService,
		private tvmLicensesService: TvmLicensesAngularService
	) {
		this.repository = this.paris.getRepository(AssessmentJob);
		this._scanNamesSubscription = this.repository.allItems$.subscribe((scans: Array<AssessmentJob>) => {
			scans.map(scan => this.scanNames.add(scan.scanName));
		});
	}

	getRepository() {
		return this.repository;
	}

	isScanNameInUse(scanName: string, itemBeforeChange?: AssessmentJob): boolean {
		if ((itemBeforeChange && itemBeforeChange.scanName === scanName) || !this.scanNames.has(scanName)) {
			return false;
		}
		return true;
	}

	updateScanNames(scanName: string, scanNameBeforeChange?: string) {
		if (scanNameBeforeChange) {
			this.scanNames.delete(scanNameBeforeChange);
		}
		this.scanNames.add(scanName);
	}

	deleteItems(items: Array<AssessmentJob>): any {
		const itemName: string = (items.length === 1
			? this.repository.entity.singularName
			: this.repository.entity.pluralName
		).toLowerCase();
		return this.dialogsService
			.confirm({
				title: this.i18nService.get('deleteItem', { itemName: itemName }),
				text: this.i18nService.get('deleteConfirm', { itemPluralName: itemName }),
				confirmText: this.i18nService.get('delete'),
			})
			.then((e: ConfirmEvent) => {
				return e.confirmed && this.paris.apiCall(DeleteAuthenticatedScan, items).toPromise();
			})
			.then(() => items.map(scan => this.scanNames.delete(scan.scanName)));
	}

	saveItem(item: AssessmentJob, next: Function, errorHandling: Function, itemBeforeChange?: AssessmentJob) {
		const processedItem = this.preProcessAuthenticatedScanBeforeSendingToBackend(item)
		this.repository.save(processedItem).subscribe(
			() => {
				next();
				itemBeforeChange
					? this.updateScanNames(processedItem.scanName, itemBeforeChange.scanName)
					: this.updateScanNames(processedItem.scanName);
			},
			error => errorHandling(error)
		);
	}

	preProcessAuthenticatedScanBeforeSendingToBackend(assessmentJob: AssessmentJob): AssessmentJob{
		const scanCopy = JSON.parse(JSON.stringify(assessmentJob)) as AssessmentJob;

		scanCopy.originalTargetRanges = this.preProcessIpAddresses(scanCopy.originalTargetRanges);
		scanCopy.target = this.preProcessIpAddresses(scanCopy.target);

		if (scanCopy.auth) {
			switch(scanCopy.scanType) {
				case AssessmentType.NetworkAssessment: {
					const scanAuthAsSnmp = scanCopy.auth as SnmpAuthParams;
					scanAuthAsSnmp.type = this.preProcessType(scanAuthAsSnmp.type);
					break;
				}
				case AssessmentType.WindowsAssessment: {
					const scanAuthAsWindows = scanCopy.auth as WindowsAuthParams;
					scanAuthAsWindows.type = (scanAuthAsWindows.type === WindowsAuthType.LocalNtlm || scanAuthAsWindows.type === WindowsAuthType.DomainNtlm) ? "NTLM" : scanAuthAsWindows.type;
					break;
				}
			}
		}

		return scanCopy;
	}

	extractAuthTypeFromAssessment(assessmentJob: AssessmentJob): string {
		switch(assessmentJob.scanType) {
			case AssessmentType.NetworkAssessment: {
				const scanAuthAsSnmp = assessmentJob.auth as SnmpAuthParams;
				return scanAuthAsSnmp.type;
			}
			case AssessmentType.WindowsAssessment: {
				const scanAuthAsWindows = assessmentJob.auth as WindowsAuthParams;
				return scanAuthAsWindows.type;
			}
		}
	}

	//Remove white spaces or newlines and replace with Comma and space separation(, )
	preProcessIpAddresses(target: string) {
		return target ? target.replace(new RegExp(',\\s*|\\s+', 'g'), ', ') : target;
	}

	countIpAddresses(target: string): number {
		const listOfAddresses: string[] = this.preProcessIpAddresses(target).split(",");
		let deviceCount = 0;

        listOfAddresses.forEach(ip => {
            if (ip.includes("/")) {
                const mask = parseInt(ip.split("/")[1]);
                deviceCount += Math.pow(2, 32 - mask);
            } else {
                deviceCount++;
            }
        });

		return deviceCount;
	}

	//Remove white spaces or newlines and replace with Comma and space separation(, )
	preProcessType(type: string) {
		return type ? type.replace(/ /g, '') : type;
	}

	ngOnDestroy() {
		this._scanNamesSubscription && this._scanNamesSubscription.unsubscribe();
	}

	isWindowsScanLicenseEnabled(): boolean {
		return this.tvmLicensesService.isEnabled(TvmLicenseType.TvmPremium);
	}

	isWindowsScanFeatureEnabled(): boolean {
		return this.featuresService.isEnabled(Feature.NdrWindowsAuthScan);
	}
}
