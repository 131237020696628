var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
import { EntityRelationship, RelationshipType } from '@microsoft/paris';
import { Machine } from '../machine.entity';
import { MachineAnyRequestsActive } from './machine-any-requests-active.value-object';
import { MachineRequestPackageDownload } from './machine-package-download.value-object';
import { MachineRequestBase } from './machine-request.value-object';
import { MachineRequestsState } from './machine-requests-state.value-object';
import { MachineRequestLogsDownload } from './machine-logs-download.value-object';
var ɵ0 = function (_a) {
    var serviceUrls = _a.data.serviceUrls;
    return serviceUrls.userRequests;
}, ɵ1 = function (machine) { return ({
    machineId: machine.machineId,
}); };
var MachineMachineRequestLatestRelationship = /** @class */ (function () {
    function MachineMachineRequestLatestRelationship() {
    }
    MachineMachineRequestLatestRelationship = __decorate([
        EntityRelationship({
            sourceEntity: Machine,
            dataEntity: MachineRequestBase,
            allowedTypes: [RelationshipType.OneToMany],
            baseUrl: ɵ0,
            endpoint: 'requests/latest',
            allItemsEndpointTrailingSlash: false,
            getRelationshipData: ɵ1,
        })
    ], MachineMachineRequestLatestRelationship);
    return MachineMachineRequestLatestRelationship;
}());
export { MachineMachineRequestLatestRelationship };
var ɵ2 = function (_a) {
    var serviceUrls = _a.data.serviceUrls;
    return serviceUrls.userRequests;
}, ɵ3 = function (machine) { return ({
    machineId: machine.machineId,
}); };
var MachineMachineRequestsStateRelationship = /** @class */ (function () {
    function MachineMachineRequestsStateRelationship() {
    }
    MachineMachineRequestsStateRelationship = __decorate([
        EntityRelationship({
            sourceEntity: Machine,
            dataEntity: MachineRequestsState,
            allowedTypes: [RelationshipType.OneToOne],
            baseUrl: ɵ2,
            endpoint: 'requests/machinestate',
            allItemsEndpointTrailingSlash: false,
            getRelationshipData: ɵ3,
        })
    ], MachineMachineRequestsStateRelationship);
    return MachineMachineRequestsStateRelationship;
}());
export { MachineMachineRequestsStateRelationship };
var ɵ4 = function (_a) {
    var serviceUrls = _a.data.serviceUrls;
    return serviceUrls.userRequests;
}, ɵ5 = function (_a) {
    var requestGuid = _a.requestGuid, packageIdentity = _a.packageIdentity;
    return ({
        requestGuid: requestGuid,
        packageIdentity: packageIdentity,
    });
};
var MachineRequestPackageDownloadRelationship = /** @class */ (function () {
    function MachineRequestPackageDownloadRelationship() {
    }
    MachineRequestPackageDownloadRelationship = __decorate([
        EntityRelationship({
            sourceEntity: MachineRequestBase,
            dataEntity: MachineRequestPackageDownload,
            baseUrl: ɵ4,
            allowedTypes: [RelationshipType.OneToOne],
            allItemsEndpointTrailingSlash: false,
            endpoint: 'requests/forensics/downloaduribyguid',
            getRelationshipData: ɵ5,
        })
    ], MachineRequestPackageDownloadRelationship);
    return MachineRequestPackageDownloadRelationship;
}());
export { MachineRequestPackageDownloadRelationship };
var ɵ6 = function (_a) {
    var serviceUrls = _a.data.serviceUrls;
    return serviceUrls.userRequests;
}, ɵ7 = function (_a) {
    var requestGuid = _a.requestGuid;
    return ({
        requestGuid: requestGuid,
    });
};
var MachineRequestLogsCollectionDownloadRelationship = /** @class */ (function () {
    function MachineRequestLogsCollectionDownloadRelationship() {
    }
    MachineRequestLogsCollectionDownloadRelationship = __decorate([
        EntityRelationship({
            sourceEntity: MachineRequestBase,
            dataEntity: MachineRequestLogsDownload,
            baseUrl: ɵ6,
            allowedTypes: [RelationshipType.OneToOne],
            allItemsEndpointTrailingSlash: false,
            endpoint: 'requests/logscollection/downloaduribyguid',
            getRelationshipData: ɵ7,
        })
    ], MachineRequestLogsCollectionDownloadRelationship);
    return MachineRequestLogsCollectionDownloadRelationship;
}());
export { MachineRequestLogsCollectionDownloadRelationship };
var ɵ8 = function (_a) {
    var serviceUrls = _a.data.serviceUrls;
    return serviceUrls.userRequests;
}, ɵ9 = function (_a) {
    var machineId = _a.machineId;
    return ({
        machineId: machineId,
    });
};
var MachineAnyRequestsActiveRelationship = /** @class */ (function () {
    function MachineAnyRequestsActiveRelationship() {
    }
    MachineAnyRequestsActiveRelationship = __decorate([
        EntityRelationship({
            sourceEntity: Machine,
            dataEntity: MachineAnyRequestsActive,
            baseUrl: ɵ8,
            allowedTypes: [RelationshipType.OneToOne],
            allItemsEndpointTrailingSlash: false,
            endpoint: 'requests/machine/any',
            getRelationshipData: ɵ9,
        })
    ], MachineAnyRequestsActiveRelationship);
    return MachineAnyRequestsActiveRelationship;
}());
export { MachineAnyRequestsActiveRelationship };
export { ɵ0, ɵ1, ɵ2, ɵ3, ɵ4, ɵ5, ɵ6, ɵ7, ɵ8, ɵ9 };
