import { ApiCall, ApiCallModel, HttpOptions, ParisConfig } from '@microsoft/paris';
import * as CreateRequest from '../machine-requests.models';

export type CollectionInvestigationRawApiRequestBody = CreateRequest.RawApiRequestBody<'ForensicsRequest'>;
export type CollectionInvestigationRawApiResponse = CreateRequest.RawApiResponse;

export type CollectionInvestigationParameters = CreateRequest.Parameters;
export type CollectionInvestigationResponse = CreateRequest.Response;

@ApiCall({
	name: 'Collect investigation package',
	endpoint: 'requests/create',
	method: 'POST',
	baseUrl: (config: ParisConfig) => config.data.serviceUrls.userRequests,
	parseQuery: ({
		machine,
		comment,
	}: CollectionInvestigationParameters): HttpOptions<CollectionInvestigationRawApiRequestBody> => ({
		data: {
			MachineId: machine.machineId,
			RequestorComment: comment,
			OsPlatform: machine.os.platform.id,
			SenseClientVersion: machine.senseClientVersion,
			Type: 'ForensicsRequest',
		},
	}),
	parseData: (rawData: CollectionInvestigationRawApiResponse): CollectionInvestigationResponse => ({
		machineId: rawData.MachineId,
		requestGuid: rawData.RequestGuid,
	}),
})
export class MachineCollectInvestigationPackageApiCall extends ApiCallModel<
	CollectionInvestigationResponse,
	CollectionInvestigationParameters
> {}
