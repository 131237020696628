import { ChangeDetectorRef, Component, OnInit } from '@angular/core';
import { LegacyUserObservedMachine, LegacyUser, LegacyUserMachineAssociatedInfo } from '@wcd/domain';
import { EntityPanelComponentBase } from '../../../global_entities/components/entity-panels/entity-panel.component.base';
import { Paris, DataSet } from '@microsoft/paris';
import { GlobalEntityTypesService } from '../../../global_entities/services/global-entity-types.service';
import { ActivatedEntity } from '../../../global_entities/services/activated-entity.service';
import { tap, filter, map, switchMap } from 'rxjs/operators';
import { Observable } from 'rxjs';
import { MachinesService } from '../../machines/services/machines.service';
import { I18nService } from '@wcd/i18n';

@Component({
	selector: 'user-observed-machine-panel',
	templateUrl: './user-observed-machine-panel.component.html',
})
export class UserObservedMachinePanelComponent extends EntityPanelComponentBase<LegacyUserObservedMachine>
	implements OnInit {
	userLogons$: Observable<LegacyUserMachineAssociatedInfo[]>;
	user: LegacyUser;

	constructor(
		changeDetectorRef: ChangeDetectorRef,
		private machinesService: MachinesService,
		private readonly paris: Paris,
		private readonly activatedEntity: ActivatedEntity,
		private readonly globalEntityTypesService: GlobalEntityTypesService,
		private i18nService: I18nService
	) {
		super(changeDetectorRef);
	}

	get userObservedMachine(): LegacyUserObservedMachine {
		return this.entity;
	}

	ngOnInit() {
		this.userLogons$ = this.activatedEntity.currentEntity$.pipe(
			filter(entity => entity instanceof LegacyUser),
			tap((user: LegacyUser) => {
				this.user = user;
			}),
			switchMap((user: LegacyUser) =>
				this.paris
					.getRepository(LegacyUserMachineAssociatedInfo)
					.query({
						where: {
							userAccount: user.accountName,
							userAccountDomain: user.accountDomainName,
							senseMachineId: this.userObservedMachine.machine.senseMachineId,
						},
					})
					.pipe(map((dataSet: DataSet<LegacyUserMachineAssociatedInfo>) => dataSet.items))
			)
		);
	}

	showLoggedOnUsers() {
		this.machinesService.showMachineLoggedOnUsers(this.userObservedMachine.machine, true, {
			noShadow: true,
			hasCloseButton: false,
		});
	}

	trackByLogonType(index, item: LegacyUserMachineAssociatedInfo) {
		return item.logonType;
	}

	getLogonText(logonType) {
		return this.i18nService.strings['users_entityDetails_logonType_' + logonType] || logonType;
	}
}
