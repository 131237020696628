var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { Paris } from '@microsoft/paris';
import { Alert, AlertClassification, AlertStatus, Incident, IncidentAssignmentFilterValues, InvestigationStatus, Outbreak, Severity, TagType, } from '@wcd/domain';
import { AuthService } from '@wcd/auth';
import { DataviewField } from '@wcd/dataview';
import { compact, lowerFirst } from 'lodash-es';
import { I18nService } from '@wcd/i18n';
import { FilterValuesChecklistComponent, FilterValuesToggleComponent, } from '@wcd/ng-filters';
import { IconsService, WcdIconNames } from '@wcd/icons';
import { AppConfigService } from '@wcd/app-config';
import { TzDateComponent } from '../../../shared/components/tz-date.component';
import { GlobalEntityTypesService } from '../../../global_entities/services/global-entity-types.service';
import { ImpactedEntitiesCounterComponent } from '../../../impacted-entities/components/impacted-entities-counter/impacted-entities-counter.component';
import { AppContextService, Feature, FeaturesService, FlavorService } from '@wcd/config';
import { TagsFieldComponent } from '../../../tags/components/tags-field/tags-field.component';
import { EntityNameComponent } from '../../../global_entities/components/entity-name/entity-name.component';
import { IncidentEntityTypeService } from './incident.entity-type.service';
import { getServiceSourceFilterConfig } from '../../common/helpers/service-source-filters.helpers';
import { MachinesFiltersService } from '../../machines/services/machines.filters.service';
import { map } from 'rxjs/operators';
import { FILTER_PRIORITIES } from './incidents.filters.service';
import { OVERFLOW_TAGS_CONTAINER_CLASS } from '../../../tags/components/tags-list/overflow-tags-list.component';
import { EntityTagsService } from '../../common/services/entity-tags.service';
import { AppFlavorConfig } from '@wcd/scc-common';
import { sccHostService } from '@wcd/scc-interface';
export function isIncident(entity) {
    return entity instanceof Incident;
}
var IncidentFields = /** @class */ (function () {
    function IncidentFields(authService, paris, globalEntityTypesService, i18nService, appContextService, featuresService, incidentEntityTypeService, entityTagsService, machinesFiltersService, iconsService, appConfigService, flavorService) {
        var _this = this;
        this.authService = authService;
        this.paris = paris;
        this.globalEntityTypesService = globalEntityTypesService;
        this.i18nService = i18nService;
        this.appContextService = appContextService;
        this.featuresService = featuresService;
        this.incidentEntityTypeService = incidentEntityTypeService;
        this.entityTagsService = entityTagsService;
        this.machinesFiltersService = machinesFiltersService;
        this.iconsService = iconsService;
        this.appConfigService = appConfigService;
        this.flavorService = flavorService;
        this.showUpdatedIncidentQueue = this.featuresService.isEnabled(Feature.UpdatedIncidentQueue) || this.appContextService.isMtp;
        this.fieldsConfig = {
            expand: {
                id: 'expand',
                name: '',
                enabledByDefault: true,
                alwaysDisplay: true,
                allowResize: false,
                getFieldCssClass: function (entity) { return "" + (isIncident(entity) ? 'datatable-expand' : ''); },
                sort: { enabled: false },
                isTabbale: true,
            },
            name: {
                id: 'name',
                name: this.i18nService.strings.incident_fields_name_title,
                enabledByDefault: true,
                className: EntityNameComponent.entityNameDefaultCssClass,
                getFieldCssClass: function () { return "datatable-group-item-indent-left title-field"; },
                component: {
                    type: EntityNameComponent,
                    getProps: function (entity) {
                        var entityTypeId = isIncident(entity)
                            ? _this.globalEntityTypesService.getEntityType(Incident).id
                            : _this.globalEntityTypesService.getEntityType(Alert).id;
                        return {
                            entity: entity,
                            entityTypeId: entityTypeId,
                            entityName: entity.name,
                            hideIcon: true,
                        };
                    },
                },
            },
            tags: {
                id: 'tags',
                name: this.i18nService.strings.incident_fields_tags_title,
                enabledByDefault: true,
                maxWidth: 272,
                minWidth: 56,
                component: {
                    type: TagsFieldComponent,
                    getProps: function (entity) { return _this.entityTagsService.getEntityTagsFieldProps(entity); },
                },
                filter: this.entityTagsService.getIncidentQueueTagsFilterConfig(FILTER_PRIORITIES.tags),
                sort: { enabled: false },
                className: 'nowrap',
                getFieldCssClass: function () { return OVERFLOW_TAGS_CONTAINER_CLASS + " wcd-text-overflow-clip"; },
                custom: {
                    allowFilterValueTracking: true,
                },
            },
            severity: {
                id: 'severity',
                name: this.i18nService.strings.incident_fields_severity_title,
                enabledByDefault: true,
                getDisplay: function (entity) { return _this.i18nService.get(entity.severity.nameI18nKey); },
                getCssClass: function (entity) { return "wcd-severity wcd-severity-" + entity.severity.type; },
                custom: {
                    allowFilterValueTracking: true,
                },
                sort: {
                    sortDescendingByDefault: true,
                },
                filter: {
                    priority: FILTER_PRIORITIES.severity,
                    component: {
                        type: FilterValuesChecklistComponent,
                        config: {
                            mapFilterValue: function (severityId) {
                                var severity = _this.paris.getValue(Severity, function (_severity) { return _severity.isSelectable && _severity.id === severityId; });
                                return {
                                    id: severity.id,
                                    name: _this.i18nService.get("incident.severity.values." + severity.name),
                                    priority: severity.priority,
                                    nameClass: "wcd-severity wcd-severity-" + severity.type,
                                };
                            },
                        },
                    },
                },
            },
            id: {
                id: 'id',
                name: this.i18nService.strings.incident_fields_id_title,
                enabledByDefault: false,
                className: EntityNameComponent.entityNameDefaultCssClass,
                sort: { enabled: false },
                getDisplay: function (entity) {
                    if (isIncident(entity)) {
                        return entity.id;
                    }
                    return '-';
                },
            },
            status: {
                id: 'status',
                name: this.i18nService.strings.incident_fields_status_title,
                enabledByDefault: false,
                getDisplay: function (entity) {
                    return entity.status
                        ? entity.status.nameI18nKey
                            ? _this.i18nService.get(entity.status.nameI18nKey)
                            : entity.status.name
                        : null;
                },
                className: 'nowrap',
                custom: {
                    allowFilterValueTracking: true,
                },
            },
            investigationStates: {
                id: 'investigationStates',
                sort: { enabled: false },
                name: this.i18nService.get('alerts.fields.investigationState.title'),
                description: this.appConfigService.isAutomatedIrEnabled
                    ? null
                    : this.i18nService.get('enableAirs.description'),
                headerClass: this.appConfigService.isAutomatedIrEnabled ? null : 'disabled',
                className: 'nowrap',
                custom: {
                    allowFilterValueTracking: true,
                },
                filter: {
                    priority: FILTER_PRIORITIES.investigationStates,
                    component: {
                        type: FilterValuesChecklistComponent,
                        config: {
                            mapFilterValue: function (statusId) {
                                var statusIdInt = parseInt(statusId, 10);
                                if (!isNaN(statusIdInt)) {
                                    var status_1 = _this.paris.getValue(InvestigationStatus, statusIdInt);
                                    if (status_1) {
                                        return {
                                            icon: status_1.iconName && {
                                                wcdIconName: _this.iconsService.getIcon(status_1.iconName).name,
                                                className: "color-text-" + status_1.className,
                                            },
                                            image: status_1.image,
                                            name: _this.i18nService.strings['investigations_status_' + status_1.type + '_title'] || status_1.name,
                                            id: status_1.id,
                                            priority: status_1.priority,
                                        };
                                    }
                                }
                                return null;
                            },
                        },
                    },
                },
            },
            classification: {
                id: 'classification',
                name: this.i18nService.strings.incident_fields_classification_title,
                enabledByDefault: true,
                getDisplay: function (entity) {
                    return entity.classification
                        ? _this.i18nService.get(entity.classification.nameI18nKey)
                        : _this.i18nService.strings.common_unclassified;
                },
                className: 'nowrap',
                filter: {
                    priority: FILTER_PRIORITIES.classification,
                    component: {
                        type: FilterValuesChecklistComponent,
                        config: {
                            mapFilterValue: function (classificationType) {
                                var classification = _this.paris.getValue(AlertClassification, function (_classification) { return _classification.type === classificationType; });
                                return {
                                    id: classification.type,
                                    rawValue: classification.type,
                                    name: _this.i18nService.get(classification.nameI18nKey),
                                    priority: classification.priority,
                                };
                            },
                        },
                    },
                },
            },
            determination: {
                id: 'determination',
                name: this.i18nService.strings.incident_fields_determination_title,
                enabledByDefault: false,
                getDisplay: function (entity) {
                    return entity.determination
                        ? entity.determination.nameI18nKey
                            ? _this.i18nService.get(entity.determination.nameI18nKey)
                            : entity.determination.name
                        : _this.i18nService.strings.common_unclassified;
                },
                className: 'nowrap',
                custom: {
                    allowFilterValueTracking: true,
                },
            },
            category: {
                id: 'category',
                name: this.i18nService.strings.incident_fields_category_title,
                enabledByDefault: true,
                className: 'nowrap',
                getDisplay: function (entity) {
                    return (entity.categories &&
                        entity.categories
                            .map(function (x) { return _this.i18nService.get('reporting_alertsByCategory_' + lowerFirst(x)); })
                            .join(', '));
                },
                maxWidth: 200,
                sort: { enabled: false },
                filter: {
                    priority: FILTER_PRIORITIES.category,
                },
            },
            impactedEntities: {
                id: 'impactedEntities',
                name: this.i18nService.strings.incident_fields_impactedEntities_title,
                enabledByDefault: true,
                className: 'wcd-text-overflow-medium',
                component: {
                    type: ImpactedEntitiesCounterComponent,
                    getProps: function (entity) {
                        var machine = 0;
                        var aaduser = 0;
                        var mailbox = 0;
                        var app = 0;
                        if (entity.impactedEntities) {
                            machine =
                                entity.impactedEntities.machineCount ||
                                    (entity.impactedEntities.machines && entity.impactedEntities.machines.length) ||
                                    0;
                            aaduser =
                                entity.impactedEntities.userCount ||
                                    (entity.impactedEntities.users && entity.impactedEntities.users.length) ||
                                    0;
                            mailbox =
                                entity.impactedEntities.mailboxCount ||
                                    (entity.impactedEntities.mailboxes && entity.impactedEntities.mailboxes.length) ||
                                    0;
                            app =
                                entity.impactedEntities.appCount ||
                                    (entity.impactedEntities.apps && entity.impactedEntities.apps.length) ||
                                    0;
                        }
                        return {
                            entities: entity.impactedEntities,
                            entitiesCount: { machine: machine, aaduser: aaduser, mailbox: mailbox, app: app },
                        };
                    },
                },
                sort: { enabled: true },
            },
            serviceSources: {
                id: 'serviceSource',
                name: this.i18nService.strings.incident_fields_serviceSources_title,
                enabledByDefault: true,
                getDisplay: function (entity) {
                    if (isIncident(entity)) {
                        return (entity.serviceSources &&
                            entity.serviceSources
                                .filter(Boolean)
                                .map(function (source) { return _this.getShorthandTranslation(source.nameI18nKey); })
                                .join(', '));
                    }
                    var serviceSource = entity.serviceSource;
                    return serviceSource ? _this.getShorthandTranslation(serviceSource.nameI18nKey) : null;
                },
                sort: { enabled: false },
                filter: getServiceSourceFilterConfig(FILTER_PRIORITIES.serviceSource),
            },
            detectionSources: {
                id: 'detectionSource',
                name: this.i18nService.strings.incident_fields_detectionSources_title,
                enabledByDefault: false,
                getDisplay: function (entity) {
                    if (isIncident(entity)) {
                        return (entity.detectionSources &&
                            entity.detectionSources
                                .filter(Boolean)
                                .map(function (source) { return _this.getShorthandTranslation(source.nameI18nKey); })
                                .join(', '));
                    }
                    var key = entity.detectionSource.nameI18nKey;
                    return _this.getShorthandTranslation(key);
                },
                filter: !this.shouldShowServiceSources() && {
                    priority: FILTER_PRIORITIES.serviceSource,
                    component: { type: FilterValuesChecklistComponent },
                    requiresData: true,
                },
                sort: { enabled: false },
            },
            activeAlerts: {
                id: 'alertCount',
                name: this.i18nService.strings.incident_fields_alertCount_title,
                enabledByDefault: true,
                getDisplay: function (entity) {
                    if (isIncident(entity)) {
                        return entity.activeAlertCount + "/" + entity.alertCount;
                    }
                    return '-';
                },
                sort: { enabled: true },
            },
            machineGroups: {
                id: 'rbacGroup',
                name: this.i18nService.strings.incident_fields_machineGroups_title,
                enabledByDefault: true,
                className: 'nowrap',
                getDisplay: function (entity) {
                    var getMachineGroupName = function (machineGroup) {
                        return machineGroup.isUnassignedMachineGroup
                            ? _this.i18nService.strings.machineGroup_unassignedGroup_groupName
                            : machineGroup.name;
                    };
                    if (isIncident(entity)) {
                        return (entity.machineGroups &&
                            entity.machineGroups
                                .filter(Boolean)
                                .map(function (group) { return getMachineGroupName(group); })
                                .join(', '));
                    }
                    else {
                        if (entity.machineGroup) {
                            return getMachineGroupName(entity.machineGroup);
                        }
                    }
                },
                filter: {
                    priority: FILTER_PRIORITIES.rbacGroup,
                    component: {
                        config: {
                            allowSingleValueDeselection: true,
                        },
                        type: FilterValuesChecklistComponent,
                    },
                },
                sort: { enabled: false },
            },
            systemTags: {
                id: 'systemTags',
                name: this.i18nService.strings.incident_fields_systemTags_title,
                enabledByDefault: false,
                component: {
                    type: TagsFieldComponent,
                    getProps: function (entity) {
                        var tags;
                        if (isIncident(entity)) {
                            tags = [
                                entity.sensitivity,
                                entity.threatExpertI18nNameKey
                                    ? {
                                        name: _this.i18nService.get(entity.threatExpertI18nNameKey),
                                        type: TagType.threatExpert,
                                    }
                                    : null
                            ].concat(entity.actors.map(function (actor) { return ({
                                name: actor,
                                type: TagType.behavior,
                            }); }));
                        }
                        else {
                            tags = entity.systemTags || [];
                        }
                        return {
                            tags: tags.filter(Boolean),
                            allowEmptyState: false,
                        };
                    },
                },
                sort: { enabled: false },
            },
            customTags: {
                id: 'incidentTags',
                name: this.i18nService.strings.incident_fields_incidentTags_title,
                enabledByDefault: false,
                component: {
                    type: TagsFieldComponent,
                    getProps: function (entity) {
                        if (isIncident(entity)) {
                            return {
                                tags: entity.tags.filter(Boolean).map(function (tag) { return ({
                                    name: tag,
                                    type: TagType.user,
                                }); }),
                                allowEmptyState: false,
                            };
                        }
                    },
                },
                getIsEmptyField: function (entity) { return !isIncident(entity); },
                sort: { enabled: false },
                className: 'nowrap',
                custom: {
                    allowFilterValueTracking: true,
                },
                filter: {
                    priority: FILTER_PRIORITIES.legacyIncidentTags,
                    component: {
                        type: FilterValuesChecklistComponent,
                        config: {
                            allowSingleValueDeselection: true,
                            mapFilterValue: function (tag, value) { return ({
                                id: tag,
                                nameClass: tag === null ? 'subtle' : 'tag',
                                name: tag || _this.i18nService.strings.incident_fields_incidentTags_untagged,
                                priority: tag ? 2 : 1,
                                data: value.custom,
                                rawValue: 'false',
                            }); },
                        },
                    },
                },
            },
            dataSensitivity: {
                // Mutually exclusive with dataSensitivityFilter column
                id: 'sensitivity',
                name: this.i18nService.strings.incident_fields_sensitivity_title,
                enabledByDefault: true,
                maxWidth: 272,
                component: {
                    type: TagsFieldComponent,
                    getProps: function (entity) { return _this.entityTagsService.getIncidentSensitivityTagFieldProps(entity); },
                },
                filter: {
                    priority: FILTER_PRIORITIES.sensitivity,
                    component: { type: FilterValuesChecklistComponent },
                },
                sort: { enabled: false },
                className: 'nowrap',
                custom: {
                    allowFilterValueTracking: true,
                },
            },
            firstActivity: {
                id: 'firstEventTime',
                name: this.i18nService.strings.incident_fields_firstEventTime_title,
                enabledByDefault: false,
                className: 'nowrap',
                component: {
                    type: TzDateComponent,
                    getProps: function (entity) {
                        var props = { date: entity.firstEventTime };
                        if (sccHostService.isSCC && _this.featuresService.isEnabled(Feature.SccDateFormat)) {
                            props.dateFormat = 'fullDateTime';
                        }
                        return props;
                    },
                },
                sort: {
                    sortDescendingByDefault: true,
                },
            },
            lastActivity: {
                id: 'lastEventTime',
                name: this.i18nService.strings.incident_fields_lastEventTime_title,
                enabledByDefault: true,
                className: 'nowrap',
                sort: {
                    sortDescendingByDefault: true,
                },
                component: {
                    type: TzDateComponent,
                    getProps: function (entity) {
                        var props = { date: entity.lastEventTime };
                        if (sccHostService.isSCC && _this.featuresService.isEnabled(Feature.SccDateFormat)) {
                            props.dateFormat = 'fullDateTime';
                        }
                        return props;
                    },
                },
            },
            // faux columns used only for filters in the updated incidents queue
            dataSensitivityFilter: {
                // Mutually exclusive with dataSensitivity column
                id: 'sensitivity',
                name: this.i18nService.strings.incident_fields_sensitivity_title,
                filter: {
                    priority: FILTER_PRIORITIES.sensitivity,
                    component: { type: FilterValuesChecklistComponent },
                },
                filterOnly: true,
            },
            actors: {
                id: 'actorName',
                name: this.i18nService.strings.incident_fields_actorName_title,
                filter: {
                    priority: FILTER_PRIORITIES.actorName,
                    component: {
                        config: {
                            allowSingleValueDeselection: true,
                        },
                        type: FilterValuesChecklistComponent,
                    },
                },
                filterOnly: true,
            },
            multipleServiceSource: {
                id: 'multipleProductSource',
                name: this.i18nService.strings.incident_fields_multipleServiceSource_title,
                filter: {
                    priority: FILTER_PRIORITIES.multipleServiceSource,
                    component: {
                        type: FilterValuesToggleComponent,
                        config: {
                            trueLabel: this.i18nService.strings.common_yes,
                            falseLabel: this.i18nService.strings.common_no,
                            ariaLabel: this.i18nService.strings.incident_fields_multipleServiceSource_title,
                        },
                    },
                    requiresData: false,
                },
                filterOnly: true,
            },
            multipleDetectionSource: {
                id: 'multipleDetectionSource',
                name: this.i18nService.strings.incident_fields_multipleDetectionSource_title,
                filter: {
                    priority: FILTER_PRIORITIES.multipleServiceSource,
                    component: {
                        type: FilterValuesToggleComponent,
                        config: {
                            trueLabel: this.i18nService.strings.common_yes,
                            falseLabel: this.i18nService.strings.common_no,
                            ariaLabel: this.i18nService.strings.incident_fields_multipleDetectionSource_title,
                        },
                    },
                    requiresData: false,
                },
                filterOnly: true,
            },
            multipleCategory: {
                id: 'multipleCategory',
                name: this.i18nService.strings.incident_fields_multipleCategory_title,
                filter: {
                    priority: FILTER_PRIORITIES.multipleCategory,
                    component: {
                        type: FilterValuesToggleComponent,
                        config: {
                            trueLabel: this.i18nService.strings.common_yes,
                            falseLabel: this.i18nService.strings.common_no,
                            ariaLabel: this.i18nService.strings.incident_fields_multipleCategory_title,
                        },
                    },
                    requiresData: false,
                },
                filterOnly: true,
            },
            osPlatform: {
                id: 'osPlatform',
                name: this.i18nService.strings.alerts_fields_osPlatform_title,
                custom: {
                    allowFilterValueTracking: true,
                },
                filter: {
                    priority: FILTER_PRIORITIES.osPlatform,
                    component: {
                        type: FilterValuesChecklistComponent,
                        config: {
                            mapFilterValue: function (category) {
                                var osPlatform = _this.machinesFiltersService.osPlatformCategoriesMap[category];
                                return {
                                    id: osPlatform.values.map(function (value) { return value.id; }).join(','),
                                    name: osPlatform.name,
                                    priority: osPlatform.priority,
                                };
                            },
                        },
                    },
                },
                filterOnly: true,
            },
            incidentAssignment: {
                id: 'incidentAssignment',
                name: this.i18nService.strings.incident_fields_assignedTo_title,
                enabledByDefault: true,
                getDisplay: function (entity) {
                    return entity.assignedTo
                        ? entity.assignedTo.split('@')[0]
                        : _this.i18nService.strings.assigned_unassigned;
                },
                getCssClass: function (entity) { return (entity.assignedTo ? '' : 'subtle'); },
                custom: {
                    allowFilterValueTracking: true,
                },
                filterName: this.i18nService.strings.incident_fields_incidentAssignment_title,
                sort: { enabled: false },
                filter: {
                    requiresData: false,
                    priority: FILTER_PRIORITIES.incidentAssignment,
                    component: {
                        type: FilterValuesChecklistComponent,
                        config: {
                            mapFilterValue: function (incidentAssignment) {
                                return {
                                    id: incidentAssignment,
                                    name: _this.i18nService.get(IncidentAssignmentFilterValues[incidentAssignment].nameI18nKey),
                                };
                            },
                        },
                    },
                    serializeFilterValues: function (incidentAssignment) {
                        return incidentAssignment && !!incidentAssignment.length
                            ? {
                                AssignedTo: _this.authService.currentUser.username.toLowerCase(),
                                incidentAssignment: incidentAssignment,
                            }
                            : null;
                    },
                    deserializeFilterValues: function (serializedValues) {
                        if (serializedValues.incidentAssignment === null) {
                            return null;
                        }
                        return serializedValues.incidentAssignment;
                    },
                },
            },
            IoaDefinitionIds: {
                id: 'IoaDefinitionIds',
                name: this.i18nService.strings.alerts_fields_associatedThreat_title,
                className: 'nowrap',
                filterOnly: true,
                filter: {
                    priority: FILTER_PRIORITIES.associatedThreats,
                    component: {
                        type: FilterValuesChecklistComponent,
                        config: {
                            mapFilterValue: function (outbreakId) {
                                return _this.paris.getItemById(Outbreak, outbreakId).pipe(map(function (outbreak) {
                                    return outbreak
                                        ? {
                                            id: outbreak.id,
                                            name: outbreak.displayName,
                                        }
                                        : null;
                                }));
                            },
                        },
                    },
                },
            },
            alertStatus: {
                id: 'AlertStatus',
                name: this.i18nService.strings.status,
                className: 'nowrap',
                filterOnly: true,
                filter: {
                    priority: FILTER_PRIORITIES.status,
                    component: {
                        type: FilterValuesChecklistComponent,
                        config: {
                            mapFilterValue: function (statusType) {
                                var status = _this.paris.getValue(AlertStatus, function (_status) { return _status.type === statusType; });
                                return {
                                    id: status.type,
                                    rawValue: status.type,
                                    name: _this.i18nService.get(status.nameI18nKey),
                                    priority: status.priority,
                                };
                            },
                        },
                    },
                },
            },
        };
        this.INVESTIGATION_STATE_EXTRA_DATA = this.getInvestigationExtraData();
    }
    IncidentFields.prototype.getShorthandTranslation = function (key) {
        return this.i18nService.strings["shorthand_" + key] || this.i18nService.get(key);
    };
    Object.defineProperty(IncidentFields.prototype, "fields", {
        get: function () {
            if (this._fields) {
                return this._fields;
            }
            this._fields = DataviewField.fromList(this.getFieldsConfig());
            return this._fields;
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(IncidentFields.prototype, "dashboardWidgetFields", {
        get: function () {
            if (this._fieldsDashboardWidget) {
                return this._fieldsDashboardWidget;
            }
            var fieldsConfig = this.fieldsConfig;
            this._fieldsDashboardWidget = DataviewField.fromList([
                fieldsConfig.name,
                fieldsConfig.tags,
                fieldsConfig.severity,
                fieldsConfig.alertCount,
                fieldsConfig.lastActivity,
                fieldsConfig.impactedEntities,
            ]);
            return this._fieldsDashboardWidget;
        },
        enumerable: true,
        configurable: true
    });
    IncidentFields.prototype.getInvestigationExtraData = function () {
        var _this = this;
        return {
            enabled: {
                getImage: function (item) {
                    return item.investigationStatus && item.investigationStatus.image;
                },
                icon: {
                    wcdIcon: function (entity) {
                        var iconName = entity.investigationStatus &&
                            entity.investigationStatus.investigationQueued &&
                            entity.investigationStatus.iconName;
                        return iconName && WcdIconNames[_this.iconsService.getIcon(iconName).name];
                    },
                    className: function (item) {
                        return (item.investigationId ||
                            (item.investigationIds &&
                                item.investigationIds.length === 1)) &&
                            item.investigationStatus
                            ? "color-text-" + item.investigationStatus.className
                            : null;
                    },
                },
                getDisplay: function (item) {
                    if (isIncident(item)) {
                        if (item.investigationStatesCount &&
                            item.investigationStatesCount > 1) {
                            return _this.i18nService.get('incident_fields_incidentInvestigationStatesCount', {
                                count: item.investigationStatesCount,
                            });
                        }
                    }
                    else if (item.investigationCount > 1) {
                        return _this.i18nService.get('incident_fields_alertInvestigationsCount', {
                            count: item.investigationCount,
                        });
                    }
                    if (item.investigationStatus) {
                        return item.investigationStatus.name;
                    }
                    return _this.i18nService.strings.notAvailable_short;
                },
                getLink: function (item) {
                    return !isIncident(item)
                        ? item.investigationId &&
                            "/investigation/" + item.investigationId
                        : null;
                },
            },
            disabled: {
                getDisplay: function () { return _this.i18nService.strings.notAvailable_short; },
                tooltip: this.i18nService.strings.enableAirs_description,
                className: 'nowrap',
            },
        };
    };
    IncidentFields.prototype.getFieldsConfig = function () {
        var fieldsConfig = this.fieldsConfig;
        var showServiceSources = this.shouldShowServiceSources();
        return compact([
            fieldsConfig.expand,
            fieldsConfig.name,
            fieldsConfig.tags,
            fieldsConfig.severity,
            fieldsConfig.id,
            fieldsConfig.alertStatus,
            fieldsConfig.multipleCategory,
            this.shouldShowInvestigationStates() ? this.getInvestigationStateDefinision(fieldsConfig) : null,
            fieldsConfig.category,
            fieldsConfig.impactedEntities,
            fieldsConfig.activeAlerts,
            showServiceSources && fieldsConfig.multipleServiceSource,
            showServiceSources && fieldsConfig.serviceSources,
            !showServiceSources && fieldsConfig.multipleDetectionSource,
            fieldsConfig.detectionSources,
            fieldsConfig.firstActivity,
            fieldsConfig.lastActivity,
            fieldsConfig.dataSensitivity,
            fieldsConfig.status,
            fieldsConfig.incidentAssignment,
            fieldsConfig.classification,
            fieldsConfig.determination,
            fieldsConfig.osPlatform,
            fieldsConfig.machineGroups,
            this.shouldShowActorsFilter() ? fieldsConfig.actors : null,
            this.shouldShowAssociatedThreatFilter() ? fieldsConfig.IoaDefinitionIds : null,
        ]);
    };
    IncidentFields.prototype.shouldShowServiceSources = function () {
        return this.appContextService.isMtp;
    };
    IncidentFields.prototype.shouldShowInvestigationStates = function () {
        return this.flavorService.isEnabled(AppFlavorConfig.incidents.autoIr);
    };
    IncidentFields.prototype.shouldShowActorsFilter = function () {
        return this.flavorService.isEnabled(AppFlavorConfig.incidents.actors);
    };
    IncidentFields.prototype.shouldShowAssociatedThreatFilter = function () {
        return this.flavorService.isEnabled(AppFlavorConfig.incidents.associatedThreat);
    };
    IncidentFields.prototype.getInvestigationStateDefinision = function (fieldsConfig) {
        return Object.assign(__assign({}, fieldsConfig.investigationStates), this.appConfigService.isAutomatedIrEnabled
            ? this.INVESTIGATION_STATE_EXTRA_DATA.enabled
            : this.INVESTIGATION_STATE_EXTRA_DATA.disabled);
    };
    return IncidentFields;
}());
export { IncidentFields };
