import { Injectable } from '@angular/core';
import { Paris, Repository } from '@microsoft/paris';
import {
	BackgroundCommandApiCall,
	CancelCommandApiCall,
	CancelCreateLiveResponseSessionApiCall,
	DownloadLiveResponseFileApiCall,
	GetCommandDefinitionsApiCall,
	LiveResponseCommand,
	LiveResponseCommandFlag,
	LiveResponseCommandOutput,
	LiveResponseCommandParam,
	LiveResponseCommandStatus,
	LiveResponseCommandType,
	LiveResponseScript,
	LiveResponseSession,
	Machine,
} from '@wcd/domain';
import {
	BehaviorSubject,
	combineLatest,
	merge,
	Observable,
	of,
	Subject,
	Subscription,
	throwError,
	timer,
} from 'rxjs';
import {
	catchError,
	delay,
	finalize,
	last,
	map,
	mergeMap,
	share,
	shareReplay,
	startWith,
	switchMap,
	take,
	takeUntil,
	takeWhile,
	tap,
} from 'rxjs/operators';
import { CommandModifiers, LiveResponseInputParserService } from './live-response-input-parser.service';
import { compact, isNil, orderBy, pull, uniq } from 'lodash-es';
import { ErrorsDialogService } from '../../../dialogs/services/errors-dialog.service';
import { I18nService } from '@wcd/i18n';
import { WcdTerminalComponent } from '../components/wcd-terminal.component';
import { retry } from '../../../utils/rxjs/retry';
import { DownloadService } from '../../../shared/services/download.service';
import { LiveResponseOutputParserService } from './live-response-output-parser.service';
import { Feature, FeaturesService } from '@wcd/config';
import { AjaxError } from 'rxjs/ajax';
import { ConfirmationService } from '../../../dialogs/confirm/confirm.service';
import { LiveResponseScriptService } from './live-response-script.service';
import { LiveResponsePermissionsService } from './live-response-permissions.service';

const _SESSION_STATUS_CHECK_INTERVAL = 10000;
const _COMMAND_STATUS_CHECK_INTERVAL = 1400;
const FALLBACK_WORKING_DIRECTORY = 'C:\\';
const LIBRARY_COMMAND_ID = 'library'

type LiveResponseLocalCommandType = LiveResponseCommandType & {
	runCommand: (
		session: LiveResponseSession,
		commandModifiers: CommandModifiers,
		rawCommand: string,
		terminal: WcdTerminalComponent
	) => Observable<LiveResponseCommand>;
};

const MAX_NO_COMMUNICATION_TIME_MINUTES = 40;

@Injectable({
	providedIn: 'root',
})
export class LiveResponseService {
	constructor(
		private paris: Paris,
		private i18nService: I18nService,
		private errorsDialogService: ErrorsDialogService,
		private downloadService: DownloadService,
		private featuresService: FeaturesService,
		private confirmationService: ConfirmationService,
		private liveResponseScriptService: LiveResponseScriptService,
		private liveResponsePermissionsService: LiveResponsePermissionsService
	) {}

	get currentDirectory(): string {
		return this._currentDirectory || FALLBACK_WORKING_DIRECTORY;
	}

	set currentDirectory(value: string) {
		this._currentDirectory = value;
	}

	private _currentDirectory: string;
	private sessionLeft$ = new Subject<void>();
	private _clearCommands$ = new Subject<Array<LiveResponseCommand>>();
	private currentSessionId: number | string;
	private resetSession$ = new BehaviorSubject<LiveResponseSession>(undefined);
	private currentSession$: Observable<LiveResponseSession>;
	private readonly COMPLETED_COMMAND_STATUS = this.paris.getValue(
		LiveResponseCommandStatus,
		status => status.type === 'completed'
	);

	private commandRepo: Repository<LiveResponseCommand> = this.paris.getRepository(LiveResponseCommand);
	private liveResponseSessionRepo: Repository<LiveResponseSession> = this.paris.getRepository(
		LiveResponseSession
	);

	private sessionCommands: Record<number | string, Observable<Array<LiveResponseCommandType>>> = {};
	private runningJobs: Record<number, Observable<LiveResponseCommand>> = {};
	private commandStop: Record<number, Subject<void>> = {};
	private backgroundCommandsSubscription = new Subscription();

	private localCommands: Array<LiveResponseLocalCommandType> = [
		{
			id: 'trace',
			displayName: 'Enable Debug Mode',
			description: 'Sets logging on this console to debug mode',
			defaultAlias: 'trace',
			aliases: null,
			params: null,
			flags: null,
			runCommand: (session, commandModifiers, rawCommand) => {
				this.debugMode = !this.debugMode;
				return of(<LiveResponseCommand>{
					...this.getLocalCommandConfig(session.id, rawCommand, 'trace'),
					outputs: [
						{
							outputType: 'string',
							data: `Debug mode ${this.debugMode ? 'enabled' : 'disabled'}`,
						},
					],
				});
			},
		},
		{
			id: 'cls',
			displayName: 'Clear console',
			description: 'Clears the console screen',
			defaultAlias: 'cls',
			aliases: ['clear'],
			params: null,
			flags: null,
			runCommand: (session, commandModifiers, rawCommand, terminal) => {
				const commandConfig = this.getLocalCommandConfig(session.id, rawCommand, 'cls');
				return of(commandConfig).pipe(
					finalize(() => {
						terminal.clear();
						this._clearCommands$.next([commandConfig]);
					})
				);
			},
		},
		{
			id: 'help',
			displayName: 'Help',
			description: 'Shows information about live response commands',
			defaultAlias: 'help',
			aliases: ['?'],
			params: [
				{
					paramId: 'command_def_id',
					name: 'Command name',
					description: 'Specific command to get information on',
					isOptional: true,
				},
			],
			flags: null,
			runCommand: (
				session: LiveResponseSession,
				commandModifiers: CommandModifiers,
				rawCommand: string
			) => {
				const commandDefParam =
						commandModifiers &&
						commandModifiers.params &&
						commandModifiers.params.find(p => p.paramId === 'command_def_id'),
					commandDefId = commandDefParam && commandDefParam.value;
				const localCommandConfig = this.getLocalCommandConfig(session.id, rawCommand, 'help');
				return this.getAvailableCommands(session).pipe(
					map(commands => {
						if (!commandDefId) {
							return <LiveResponseCommand>{
								...localCommandConfig,
								outputs: [
									{
										outputType: 'string',
										data:
											'For more information on a specific command, type HELP command-name',
									},
									{
										outputType: 'table',
										data: orderBy(commands, ['id']),
										keys: [{ id: 'id' }, { id: 'description' }],
										tableConfig: {
											showHeader: false,
										},
									},
								],
							};
						} else {
							const desiredCommandType: LiveResponseCommandType = this.matchCommandString(
								commands,
								commandDefId
							);
							if (!desiredCommandType) {
								throw new Error(`${commandDefId} is not a valid command`);
							}

							const modifiersHelp: Array<{ id: string; description: string }> = [];
							const commandIdentifier =
								desiredCommandType.defaultAlias || desiredCommandType.id;

							let commandStrings = '';
							if (desiredCommandType.params) {
								desiredCommandType.params.forEach((param: LiveResponseCommandParam) => {
									const paramId = param.isOptional ? `[${param.paramId}]` : param.paramId;
									commandStrings += ` ${paramId}`;
									modifiersHelp.push({
										id: param.paramId,
										description: param.description,
									});
								});
							}
							if (desiredCommandType.flags) {
								desiredCommandType.flags.forEach((flag: LiveResponseCommandFlag) => {
									commandStrings += ` [-${flag.flagId}]`;
									modifiersHelp.push({
										id: `-${flag.flagId}`,
										description: flag.description,
									});
								});
							}

							const outputs: Array<LiveResponseCommandOutput<any>> = [
								{
									outputType: 'string',
									data: desiredCommandType.description,
								},
							];
							if (commandStrings) {
								outputs.push({
									outputType: 'string',
									data: commandIdentifier + commandStrings,
								});
							}
							if (modifiersHelp.length) {
								outputs.push({
									outputType: 'table',
									data: modifiersHelp,
									keys: [{ id: 'id' }, { id: 'description' }],
									tableConfig: {
										showHeader: false,
									},
								});
							}
							// Aliases
							const allAliases: Array<string> = pull(
								compact(
									uniq(
										(desiredCommandType.aliases || []).concat(
											desiredCommandType.defaultAlias,
											desiredCommandType.id
										)
									)
								),
								commandIdentifier
							);
							if (allAliases && allAliases.length) {
								outputs.push({
									outputType: 'string',
									data: `Aliases:\n\t${allAliases.join(', ')}`,
								});
							}

							// Examples
							if (desiredCommandType.examples && desiredCommandType.examples.length) {
								outputs.push({
									outputType: 'string',
									data: `Examples:\n\t${desiredCommandType.examples
										.map(e => e.join('\n\t'))
										.join('\n\n\t')}`,
								});
							}

							return <LiveResponseCommand>{
								...localCommandConfig,
								outputs: outputs,
							};
						}
					})
				);
			},
		},
		{
			id: LIBRARY_COMMAND_ID,
			displayName: 'Library',
			description: 'Lists or takes action on files in the live response library',
			defaultAlias: 'library',
			aliases: ['scripts'],
			params: [
				{
					paramId: 'action',
					name: 'Action',
					description: 'Action to perform (delete)',
					isOptional: true,
				},
				{
					paramId: 'filename',
					name: 'File name',
					description: 'Name of the file on which to perform the action',
					isOptional: true,
				},
			],
			flags: null,
			examples: [
				['# List files in the library', 'library'],
				['# Delete a file from the library', 'library delete script.ps1'],
			],
			runCommand: (session, commandModifiers, rawCommand) => {
				const actionParam =
					commandModifiers &&
					commandModifiers.params &&
					commandModifiers.params.find(p => p.paramId === 'action');
				const fileParam =
					commandModifiers &&
					commandModifiers.params &&
					commandModifiers.params.find(p => p.paramId === 'filename');
				const localCommandConfig = this.getLocalCommandConfig(session.id, rawCommand, 'library');
				let isDelete: boolean;
				if (actionParam || fileParam) {
					if (!actionParam) {
						throw new Error("'filename' option requires an action.");
					}
					isDelete = actionParam.value.toLowerCase() === 'delete';
					if (!isDelete) {
						throw new Error('Specify a supported action (delete).');
					}
					if (!fileParam) {
						throw new Error('Specify a valid file name to take action on.');
					}
				}
				return this.liveResponseScriptService.scripts$.pipe(
					take(1),
					switchMap((files: Array<LiveResponseScript>) => {
						if (isDelete) {
							const file = files.find(
								f => f.fileName && f.fileName.toLowerCase() === fileParam.value.toLowerCase()
							);
							if (!file) {
								throw new Error(
									`File not found. Specify a valid file name to take action on.`
								);
							}
							return this.liveResponseScriptService.removeItem(file).pipe(
								map(
									() =>
										<LiveResponseCommand>{
											...localCommandConfig,
											outputs: [
												{
													outputType: 'string',
													data: `File '${file.fileName}' successfully removed.`,
												},
											],
										}
								)
							);
						}
						return of(<LiveResponseCommand>{
							...localCommandConfig,
							outputs: [
								<LiveResponseCommandOutput<LiveResponseScript>>{
									outputType: 'table',
									keys: [
										{ id: 'fileName', name: 'File name' },
										{ id: 'description', name: 'Description' },
										{ id: 'hasParams', name: 'Parameters' },
										{ id: 'paramsDescription', name: 'Parameters description' },
										{ id: 'creationTime', name: 'Uploaded on' },
										{ id: 'creatingUser', name: 'Uploaded by' },
									],
									data: files
										? files.map(f =>
												Object.assign({}, f, {
													hasParams: f.hasParams ? 'Yes' : 'No',
												})
										  )
										: [],
								},
							],
						});
					})
				);
			},
		},
		{
			id: 'jobs',
			displayName: 'Jobs',
			description: 'Lists background jobs',
			defaultAlias: 'jobs',
			params: null,
			flags: null,
			runCommand: (session, commandModifiers, rawCommand) => {
				const localCommandConfig = this.getLocalCommandConfig(session.id, rawCommand, 'jobs');
				const commands: Array<Observable<LiveResponseCommand>> = Object.values(this.runningJobs);
				if (!commands.length) {
					return of(localCommandConfig);
				}
				return combineLatest(commands).pipe(
					take(1),
					map(
						commands =>
							<LiveResponseCommand>{
								...localCommandConfig,
								outputs: [
									{
										outputType: 'table',
										data: commands.map(command => ({
											id: `[${command.id}]`,
											status: command.status.name,
											rawCommand: command.rawCommand,
										})),
										keys: [{ id: 'id' }, { id: 'status' }, { id: 'rawCommand' }],
										tableConfig: {
											showHeader: false,
										},
									},
								],
							}
					)
				);
			},
		},
	];

	debugMode: boolean = false;
	clearCommands$: Observable<Array<LiveResponseCommand>> = this._clearCommands$.asObservable();

	private getLocalCommandConfig(
		sessionId: number | string,
		rawCommand: string,
		commandTypeId: string
	): LiveResponseCommand {
		return <LiveResponseCommand>{
			id: undefined,
			status: this.COMPLETED_COMMAND_STATUS,
			rawCommand: rawCommand,
			startTime: new Date(),
			sessionId: sessionId,
			commandTypeId: commandTypeId,
		};
	}

	async createSession(machine: Machine | string): Promise<LiveResponseSession> {
		const useV2Api = this.featuresService.isEnabled(Feature.LiveResponseTransitionCodeSeparation);
		const useV3Api = this.featuresService.isEnabled(Feature.CloudLiveResponseV3);

		if (typeof machine === 'string') {
			machine = new Machine({ id: machine, machineId: machine });
		} else if (
			this.featuresService.isEnabled(Feature.LiveResponseReportCancelCreateSession) &&
			machine &&
			machine.lastSeen
		) {
			const currentDate = new Date();
			const diff = (currentDate.getTime() - machine.lastSeen.getTime()) / 1000 / 60;
			const minutesDiff = Math.round(diff);

			if (minutesDiff >= MAX_NO_COMMUNICATION_TIME_MINUTES) {
				const e = await this.confirmationService.confirm({
					title: this.i18nService.strings.liveResponse_createSession_offlineDevice_popupTitle,
					text: this.i18nService.strings.liveResponse_createSession_offlineDevice_popupMessage,
					confirmText: this.i18nService.strings
						.liveResponse_createSession_offlineDevice_popupConfirmButtonText,
				});

				if (!e.confirmed) {
					this.paris
						.apiCall(CancelCreateLiveResponseSessionApiCall, {
							machineId: machine.id,
							machineLastSeen: machine.lastSeen.toISOString(),
							useV2Api,
							useV3Api,
						})
						.toPromise();
					return Promise.reject();
				}
			}
		}

		const newSession = new LiveResponseSession({
			id: undefined,
			machine: machine,
			useV2Api: useV2Api,
			useV3Api: useV3Api,
		});
		return this.liveResponseSessionRepo
			.save(newSession, { params: { useV3Api: useV3Api } })
			.pipe(
				tap({
					error: err => {
						this.errorsDialogService.showError({
							title: this.i18nService.strings
								.machines_entityDetails_actions_createLiveResponse_failureMessage,
							data: err instanceof AjaxError ? err : err.response, // currently, in SCC portal the error is AxiosError and not AjaxError, hence, we need to handle both cases.
						});
					},
				}),
				take(1)
			)
			.toPromise();
	}

	getAvailableCommands(sessionData: {
		id: number | string;
		useV2Api: boolean;
		useV3Api: boolean;
	}): Observable<Array<LiveResponseCommandType | LiveResponseLocalCommandType>> {
		const sessionId = sessionData.id;
		const sessionCommands$: Observable<Array<LiveResponseCommandType>> =
			this.sessionCommands[sessionId] ||
			this.paris
				.apiCall(GetCommandDefinitionsApiCall, {
					sessionId,
					useV2Api: sessionData.useV2Api,
					useV3Api: sessionData.useV3Api,
				})
				.pipe(
					switchMap(commands => {
						return combineLatest(
							commands.map(c => this.paris.createItem(LiveResponseCommandType, c))
						);
					}),
					map(commands => {
						const filteredlocalCommands = this.localCommands.filter(c => (LIBRARY_COMMAND_ID != c.id) || this.liveResponsePermissionsService.hasLibraryPermissions())
						return commands.concat(filteredlocalCommands)
					}),
					shareReplay(1)
				);
		this.sessionCommands[sessionId] = sessionCommands$;
		return sessionCommands$;
	}

	findMatchingCommand(
		sessionData: { id: number | string; useV2Api: boolean, useV3Api: boolean },
		commandStr: string
	): Observable<LiveResponseCommandType> {
		return this.getAvailableCommands(sessionData).pipe(
			map(commands => this.matchCommandString(commands, commandStr))
		);
	}

	runCommand(
		commandStr: string,
		session: LiveResponseSession,
		terminal: WcdTerminalComponent
	): Observable<{ command: LiveResponseCommand; commandModifiers: CommandModifiers }> {
		const commandDefId: string = LiveResponseInputParserService.getCommandDefIdFromRawString(commandStr);
		return this.findMatchingCommand(session, commandDefId).pipe(
			switchMap((commandType: LiveResponseCommandType) => {
				if (!commandType) {
					throw new Error(`'${commandDefId}' is not recognized as a command`);
				}

				const commandModifiers = LiveResponseInputParserService.buildCommandModifiers(
					commandType,
					commandStr
				);

				let runCommand$: Observable<LiveResponseCommand>;

				if ((<LiveResponseLocalCommandType>commandType).runCommand) {
					runCommand$ = (<LiveResponseLocalCommandType>commandType).runCommand(
						session,
						commandModifiers,
						commandStr,
						terminal
					);
				} else {
					runCommand$ = this.runBackendCommand(
						commandType,
						commandModifiers,
						commandStr,
						session
					).pipe(
						catchError(err => throwError(LiveResponseOutputParserService.parseCommandError(err))),
						switchMap((command: LiveResponseCommand) =>
							commandModifiers.runInBackground &&
							this.featuresService.isEnabled(Feature.LiveResponseBackgroundEnabled)
								? this.getCommandInBackground(command, commandModifiers)
								: this.getCommandUntilDone(command, commandModifiers)
						)
					);
				}
				return runCommand$.pipe(
					map(command => ({
						command,
						commandModifiers,
					}))
				);
			})
		);
	}

	getCommandUntilDone(
		command: LiveResponseCommand,
		modifiers: CommandModifiers,
		allowCurrentDirChange: boolean = true
	): Observable<LiveResponseCommand> {
		const command$ = timer(0, _COMMAND_STATUS_CHECK_INTERVAL).pipe(
			mergeMap(() =>
				this.commandRepo.getItemById(command.id, undefined, { session_id: command.sessionId, useV2Api: command.useV2Api, useV3Api: command.useV3Api })
			),
			retry(10, true),
			startWith(command),
			takeWhile(command_ => command_.status.isRunning, true),
			catchError(err => throwError(LiveResponseOutputParserService.parseCommandError(err))),
			delay(1),
			share()
		);

		const lastCommand$ = command$.pipe(last());
		return combineLatest([command$, lastCommand$.pipe(startWith(undefined))]).pipe(
			switchMap(([command, lastCommand]) => {
				let res$ = of([command, lastCommand]);
				if (
					lastCommand !== undefined &&
					allowCurrentDirChange &&
					lastCommand.newCurrentDirectory &&
					this.currentDirectory !== lastCommand.newCurrentDirectory
				) {
					this.currentDirectory = lastCommand.newCurrentDirectory;
					// add delay to allow the directory change to take effect
					res$ = res$.pipe(delay(1));
				}
				return res$;
			}),
			tap(([_, lastCommand]) => {
				// TODO: check if should keep running in background (for download)
				if (lastCommand !== undefined) {
					if (lastCommand.downloadUrl) {
						this.downloadService.downloadFromUrl(lastCommand.downloadUrl, {
							isAuthenticated: true,
							downloadedFileName: lastCommand.downloadFileName,
						});
					} else if (lastCommand.downloadToken) {
						this.downloadService.download(
							this.paris.apiCall(DownloadLiveResponseFileApiCall, lastCommand),
							lastCommand.downloadFileName
						);
					}
					if (modifiers.outputTo) {
						this.downloadService.download(
							of(
								new Blob([LiveResponseOutputParserService.getCommandOutput(lastCommand)], {
									type: 'application/octet-stream',
								})
							),
							modifiers.outputTo
						);
					}
				}
			}),
			map(([command]) => command)
		);
	}

	getCommandInBackground(
		command: LiveResponseCommand,
		commandModifiers: CommandModifiers
	): Observable<LiveResponseCommand> {
		this.commandStop[command.id] = new Subject<void>();
		const command$ = this.getCommandUntilDone(command, commandModifiers, false).pipe(
			takeUntil(merge(this.sessionLeft$, this.commandStop[command.id])),
			shareReplay({ bufferSize: 1, refCount: true })
		);
		this.runningJobs[command.id] = command$;
		this.backgroundCommandsSubscription.add(
			command$.subscribe({
				complete: () => {
					delete this.runningJobs[command.id];
				},
			})
		);
		return of(<LiveResponseCommand>{
			...this.getLocalCommandConfig(command.sessionId, command.rawCommand, command.commandTypeId),
			outputs: [
				{
					outputType: 'table',
					data: [
						{
							id: `[${command.id}]`,
							status: command.status.name,
							rawCommand: command.rawCommand,
						},
					],
					keys: [{ id: 'id' }, { id: 'status' }, { id: 'rawCommand' }],
					tableConfig: {
						showHeader: false,
					},
				},
			],
		});
	}

	cancelCommand(command: LiveResponseCommand): Observable<void> {
		if (this.commandStop[command.id]) {
			this.commandStop[command.id].next();
			this.commandStop[command.id].complete();
			delete this.commandStop[command.id];
		}
		return this.paris.apiCall(CancelCommandApiCall, command).pipe(retry(10));
	}

	moveCommandToBackground(command: LiveResponseCommand): Observable<void> {
		return this.paris.apiCall(BackgroundCommandApiCall, command).pipe(retry(10));
	}

	private matchCommandString(
		commands: Array<LiveResponseCommandType>,
		commandStr: string
	): LiveResponseCommandType {
		const commandLower = commandStr.toLowerCase();
		return commands.find(c => {
			return (
				c.id.toLowerCase() === commandLower ||
				(c.defaultAlias && c.defaultAlias.toLowerCase() === commandLower) ||
				(c.aliases && c.aliases.map(a => a.toLowerCase()).includes(commandLower))
			);
		});
	}

	private runBackendCommand(
		commandType: LiveResponseCommandType,
		commandModifiers: CommandModifiers,
		rawCommand: string,
		session: LiveResponseSession
	): Observable<LiveResponseCommand> {
		return this.commandRepo
			.save(
				new LiveResponseCommand(
					Object.assign(
						{
							id: undefined,
							sessionId: session.id,
							commandTypeId: commandType.id,
							currentDirectory: this.currentDirectory,
							rawCommand: rawCommand,
							useV2Api: session.useV2Api,
							useV3Api: session.useV3Api,
						},
						commandModifiers
					)
				),
				{ params: { session_id: session.id, useV3Api: session.useV3Api }}
			)
			.pipe(
				map((command: LiveResponseCommand) =>
					Object.assign<LiveResponseCommand, Partial<LiveResponseCommand>>(
						command,
						commandModifiers
					)
				)
			);
	}

	getSessionUntilDone(session: LiveResponseSession): Observable<LiveResponseSession> {
		if (this.currentSessionId !== session.id) {
			this.resetSession(session);
		}
		return this.resetSession$.pipe(switchMap(() => this.currentSession$));
	}

	resetSession(session: LiveResponseSession) {
		this.currentSessionId = session.id;
		const session$ = new Subject<LiveResponseSession>();
		const timer$ = session$.pipe(
			startWith(session),
			switchMap(_session => {
				if (_session.status.isPending) {
					return timer(_COMMAND_STATUS_CHECK_INTERVAL, _COMMAND_STATUS_CHECK_INTERVAL);
				}
				return timer(_SESSION_STATUS_CHECK_INTERVAL, _SESSION_STATUS_CHECK_INTERVAL);
			})
		);
		const data$ = timer$.pipe(
			mergeMap(() =>
				this.liveResponseSessionRepo.getItemById(session.id, undefined, {
					useV2Api: session.useV2Api,
					useV3Api: session.useV3Api,
				})
			),
			tap(_session => {
				session$.next(_session);
			}),
			retry(10, true),
			takeWhile(_session => _session.status.isRunning, true)
		);

		this.currentSession$ = data$.pipe(
			shareReplay({ bufferSize: 1, refCount: true }),
			finalize(() => {
				session$.complete();
			})
		);
		this.resetSession$.next(session);
	}

	leaveSession() {
		this.sessionLeft$.next();
		this.currentDirectory = null;
	}
}
