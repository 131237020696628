import { Injectable } from '@angular/core';
import { AppInsightsService } from '../../../insights/services/app-insights.service';
import { FeaturesService, Feature, AppContextService } from "@wcd/config";
import { OfficeIntegrationService } from '../../../admin/services/office-integration.service';
import { CyberEvent, CyberEventMarkApiCall, CyberEventMark } from '@wcd/domain';
import { CsvService } from '../../../shared/services/csv.service';
import { ServiceUrlsService } from '@wcd/app-config';
import { Paris } from '@microsoft/paris';
import { BehaviorSubject, Observable, ReplaySubject } from 'rxjs';
import { tap } from 'rxjs/operators';
import { CyberEventsActionTypesService } from './cyber-events-action-types.service';
import { I18nService } from '@wcd/i18n';
import { TrackingEventType } from '../../../insights/models/tracking-event-type.enum';
import { TrackingEventPropertiesBase } from '../../../insights/models/tracking-event.interface';

const EXPORT_TO_CSV_TOKEN_URL = 'GetAuthToken';
const EXPORT_TO_CSV_URL = 'exportEvents';

@Injectable()
export class CyberEventsUtilsService {
	private officeTenantPrefix: string;
	private markEventOperations$: ReplaySubject<CyberEventMark> = new ReplaySubject<CyberEventMark>();
	static correlationId$: BehaviorSubject<string> = new BehaviorSubject('');

	protected apiUrl(entityType: string = null): string {
		//service url depends on the entity type
		//once the vnext migration is completed thi can possibly be cleaned up a bit
		switch (entityType) {
			case 'files':
			case 'urls':
				return `${this.serviceUrlsService.filedownloads}/files`; //entitypagesservice filescontroller covers both urls, files
			default:
				return this.serviceUrlsService.threatIntel;
		}
	}

	constructor(
		private readonly featuresService: FeaturesService,
		private readonly officeIntegrationService: OfficeIntegrationService,
		private readonly appInsightsService: AppInsightsService,
		private readonly csvService: CsvService,
		private readonly serviceUrlsService: ServiceUrlsService,
		private readonly cyberEventsActionTypesService: CyberEventsActionTypesService,
		private readonly i18nService: I18nService,
		private readonly paris: Paris
	) {
		if (this.featuresService.isEnabled(Feature.OfficeAtpIntegration)) {
			this.officeIntegrationService.getOfficeTenantUrlPrefix().subscribe(tenantPrefix => {
				this.officeTenantPrefix = tenantPrefix;
			});
		}
	}

	createParamsForGetEvent(event: CyberEvent, options?: {}) {
		const officeParams = this.officeTenantPrefix ? { officeTenantPrefix: this.officeTenantPrefix } : {};
		if (
			this.featuresService.isEnabled(Feature.OfficeAtpIntegration) &&
			this.officeTenantPrefix === undefined
		) {
			this.appInsightsService.trackEvent('UI', {
				id: 'OfficeIntegrationOn_noOfficeParams',
				eventId: event.id,
			});
		}
		return Object.assign({}, event, options, officeParams);
	}

	downloadCsv(options?: Record<string, string>) {
	if (options.entityType == 'machines') {
		return this.csvService.downloadCsv(
			{
				tokenUrl: null,
				apiUrl: `${this.serviceUrlsService.cyberData}/${options.entityType}/${options.entityId}/${EXPORT_TO_CSV_URL}/`,
				params: options
			},
			true);
		} else {
			const tokenUrl = options.entityType === 'urls' ?
				`${this.apiUrl(options.entityType)}/${EXPORT_TO_CSV_TOKEN_URL}?exportUrl=${options.entityType}/${encodeURIComponent(options.entityId)}/${EXPORT_TO_CSV_URL}/` :
				`${this.apiUrl(options.entityType)}/${EXPORT_TO_CSV_TOKEN_URL}?exportUrl=${options.entityType}/${options.entityId}/${EXPORT_TO_CSV_URL}/`;

			return this.csvService.downloadCsv({
				tokenUrl,
				apiUrl: `${this.apiUrl(options.entityType)}/${options.entityType}/${options.entityId}/${EXPORT_TO_CSV_URL}/`,
				params: options,
			});
		}
	}

	markEvent(event: CyberEvent, isMarked: boolean): Observable<void> {
		this.updateOnMarkEventOperation(event, isMarked);

		return this.paris
			.apiCall(CyberEventMarkApiCall, {
				cyberEvent: event,
				isMarked,
			})
			.pipe(
				tap({
					error: _ => this.updateOnMarkEventOperation(event, !isMarked)
				})
			);
	}

	getMarkEventOperationObservable(): Observable<CyberEventMark> {
		return this.markEventOperations$;
	}

	trackCyberEventPanelEvent(id: string, type: TrackingEventType, events: Array<CyberEvent>) {
		if (events && events.length) {
			events.forEach((event: CyberEvent) => {
				const properties = CyberEventsUtilsService.getCyberEventPanelTrackProperties(id, type, event);
				this.appInsightsService.track(properties);
			})
		}
	}

	static getCyberEventPanelTrackProperties(value: string, type: TrackingEventType, event?: CyberEvent) {
		const properties: TrackingCyberEventProperties = {
			component: 'CyberEventEntityPanel',
			componentType: 'Side Panel',
			id: 'CyberEventPanelTrackEvent',
			type: type,
			value: value,
			correlationId: this.correlationId$.getValue(),
			deviceId: event && event.machine && event.machine.id,
			eventType: event && event.rawActionType,
			reportId: event && event.reportId,
			dataType: event && event.cyberActionType && event.cyberActionType.dataType,
			relatedObservationName: event && event.relatedObservationName
		};
		return properties;
	}

	private updateOnMarkEventOperation(event: CyberEvent, isMarked: boolean): void {
		this.markEventOperations$.next({
			actionTime: event.actionTime,
			actionType: this.cyberEventsActionTypesService.getActionType(event),
			deviceId: event.machine && event.machine.id,
			flagTime: new Date(),
			isFlagged: isMarked,
			alertId: event.relatedAlert && event.relatedAlert.id,
			actionTimeIsoString: event.actionTimeIsoString,
			reportId: event.reportId,
		});
	}

	static shouldTimelineUseOneCyber(featuresService: FeaturesService): boolean {
		return featuresService.isEnabled(Feature.NewTimeLineData) || featuresService.isEnabled(Feature.MachineTimelineFromCyber);
	}

	static shouldTimeUseDataCompleteness(featuresService: FeaturesService): boolean {
		return featuresService.isEnabled(Feature.NewTimeLineData) || featuresService.isEnabled(Feature.MachineTimelineDataCompleteness);
	}

	static shouldPrefetchTimeline(featuresService: FeaturesService): boolean {
		return featuresService.isEnabled(Feature.NewTimeLineData) || featuresService.isEnabled(Feature.MachineTimelineAlwaysPrefetch);
	}

	static shouldWarmUpResponse(featuresService: FeaturesService): boolean {
		return featuresService.isEnabled(Feature.MachineTimelineWarmUpResponse);
	}

	static getTimelineFlagParams(featuresService: FeaturesService, appContext: AppContextService) {
		const params = { correlationId: this.correlationId$.getValue() };

		if (this.shouldTimelineUseOneCyber(featuresService)) params['useCyberData'] = true;
		if (this.shouldTimeUseDataCompleteness(featuresService)) params['useDataCompleteness'] = true;
		if (featuresService.isEnabled(Feature.MachineTimelineDoNotUseCache)) params['doNotUseCache'] = true;

		if (!appContext.isAadIpActive) {
			return params;
		}

		if (featuresService.isEnabled(Feature.MachineTimelineGenerateMdiEvents)) params['generateIdentityEvents'] = true;
		if (featuresService.isEnabled(Feature.MachineTimelineIncludeMdiEvents)) params['includeIdentityEvents'] = true;

		return params;
	}
}

class TrackingCyberEventProperties implements TrackingEventPropertiesBase {
	id: string;
	type: TrackingEventType;
	component: string;
	componentType: string;
	value: any;
	correlationId: string;
	deviceId: string;
	eventType?: string;
	reportId?: number;
	dataType?: string;
	relatedObservationName?: string;
}
