var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (b.hasOwnProperty(p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
import { StoreBackendBase } from '../../../data/models/store.backend.base';
import { DownloadService } from '../../../shared/services/download.service';
import { HttpClient } from '@angular/common/http';
import { ServiceUrlsService } from '@wcd/app-config';
import { CsvService } from '../../../shared/services/csv.service';
import { Feature, FeaturesService } from "@wcd/config";
var EDIT_INCIDENTS_TAGS_API_ENDPOINT = 'EditIncidentTags';
var ALL_INCIDENT_TAGS_URL = 'incidents/tags';
var IncidentsBackendService = /** @class */ (function (_super) {
    __extends(IncidentsBackendService, _super);
    function IncidentsBackendService(http, downloadService, csvService, serviceUrlsService, featuresService) {
        var _this = _super.call(this, http, downloadService) || this;
        _this.csvService = csvService;
        _this.serviceUrlsService = serviceUrlsService;
        _this.featuresService = featuresService;
        return _this;
    }
    Object.defineProperty(IncidentsBackendService.prototype, "apiUrl", {
        get: function () {
            return this.featuresService.isEnabled(Feature.K8SMigrationIncidentTagsKW) ? this.serviceUrlsService.incidentTags : this.serviceUrlsService.threatIntel;
        },
        enumerable: true,
        configurable: true
    });
    IncidentsBackendService.prototype.getIncidentTags = function () {
        return this.get(ALL_INCIDENT_TAGS_URL);
    };
    IncidentsBackendService.prototype.updateIncidentTags = function (incidentIds, tags) {
        var userDefinedTags = tags.filter(function (tag) { return tag.isEditable !== false; });
        return this.post(EDIT_INCIDENTS_TAGS_API_ENDPOINT, {
            incidentIds: incidentIds,
            incidentTags: JSON.stringify(userDefinedTags.map(function (tag) { return tag.name; })),
        });
    };
    return IncidentsBackendService;
}(StoreBackendBase));
export { IncidentsBackendService };
