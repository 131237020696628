export enum SuppressionRulePredicateValueType {
	Unknown = 'Unknown',
	FileName = 'FileName',
	FolderPath = 'FolderPath',
	Sha1 = 'Sha1',
	Ip = 'Ip',
	Url = 'Url',
	CommandLine = 'CommandLine',
	UserSid = 'UserSid',
	SenseMachineId = 'SenseMachineId',
	RbacGroupId = 'RbacGroupId',
	ProcessSha256 = '3D6BE1D4-264C-4DAC-9DEE-AAAFDD5D498E',
	FileSha256 = 'CE3AB7EE-6002-4ADE-B956-78E4327BCE7A',
	AmsiContent = 'FE5EB4B1-2415-41C9-B351-99EB729A3926',
	ContentSha256 = '20BA6007-0793-49E8-A609-10E33066DFC7',
	UserName = '17FC6469-9914-48F0-80A6-68B1128EAC72',
	Consumer = 'DD39759A-5102-4869-B67F-38F4F567717B',
	Ess = '29CE382C-C613-4623-84F8-9317DA5C7A2A',
	Namespace = 'A27CAEEF-15A1-409C-A9A1-E829E7C18BAC',
	PossibleCause = '2997B7D2-3208-4AC6-83FF-C7CF74762615',
	TaskName = 'FA8284B0-3F19-4895-9909-31C1F7DDBE48',
	ParentProcessSha1 = 'C27C7233-8926-41D8-8E9D-59ED83E3C017',
	ParentProcessSha256 = '1BC91DAA-D8C9-490C-802B-81DE4A551327',
	ParentProcessFolderPath = '2B1598AB-4A8F-4505-8579-18404BDE7837',
	ParentProcessFileName = 'E88C36A5-FBA6-4968-8F75-E78DF4730FC0',
	ParentProcessCommandLine = '7684343D-EF0B-4860-993A-B3B4D52C545B',
	TaskExecutables = '47497D74-F603-4AF3-B18E-001430F665E9',
}
