export enum SuppressionRuleComplexConditionIocType {
	Unknown = 'Unknown',
	File = 'File',
	Process = 'Process',
	Ip = 'Ip',
	Url = 'Url',
	Machine = 'Machine',
	User = 'User',
	AmsiScript = 'AmsiScript',
	ScheduledTask = 'ScheduledTask',
	WmiEvent = 'WmiEvent',
}
