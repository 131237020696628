import { EntityField, ModelBase, ValueObject } from '@microsoft/paris';
import { Alert } from '../alert.entity'
import { IncidentLinkedByEntity } from '../linked-by/incident-linked-by-entity.value-object'

@ValueObject({
	singularName: 'Linked entity alert',
	pluralName: 'Linked entity alerts',
	readonly: true,
})
export class LinkedEntityAlert extends ModelBase {
	@EntityField({
		data: 'Alert',
	})
	readonly alert: Alert;

	@EntityField({
		data: 'Entity',
	})
	readonly entity?: IncidentLinkedByEntity;
}
