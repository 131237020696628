var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (b.hasOwnProperty(p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
import { ElementRef } from '@angular/core';
import { ChartComponent } from '../chart.component.base';
import { I18nService } from '@wcd/i18n';
import { format } from 'd3-format';
import * as d3 from 'd3';
import { TzDateService } from '@wcd/localization';
var TimeSeriesChartComponent = /** @class */ (function (_super) {
    __extends(TimeSeriesChartComponent, _super);
    function TimeSeriesChartComponent(elementRef, i18nService, tzDateService) {
        var _this = _super.call(this, elementRef) || this;
        _this.i18nService = i18nService;
        _this.tzDateService = tzDateService;
        _this.chartSettings = {
            useValues: true,
            xProperty: 'label',
            setColors: true,
            data: {
                order: 'desc',
            },
            options: {
                padding: {
                    right: 20,
                    top: 1,
                },
                data: {},
                axis: {
                    x: {
                        tick: {
                            format: '%Y-%m-%d',
                        },
                        type: 'timeseries',
                    },
                    y: {
                        tick: {
                            format: format('d'),
                        },
                        min: 0,
                        padding: {
                            bottom: 0,
                        },
                    },
                },
                grid: {
                    y: {
                        show: true,
                    },
                },
                oninit: _this.onInit.bind(_this),
            },
        };
        return _this;
    }
    TimeSeriesChartComponent.prototype.onInit = function () {
        var _this = this;
        setTimeout(function () {
            var options = _this.getChartOptions();
            options.bindto = _this.el;
            _this.setNavigationAndTooltipKeyboardSupport();
            _this.setScreenReaderSupport();
        }, 0);
    };
    TimeSeriesChartComponent.prototype.setScreenReaderSupport = function () {
        var _this = this;
        var options = this.getChartOptions();
        d3.select(this.el)
            .attr('role', 'document')
            .attr('aria-label', this.i18nService.get('charts.timeSeriesChart'));
        d3.selectAll(this.el.querySelectorAll("circle.c3-circle"))
            .each(function (dataPoint, index, nodesList) {
            d3.select(nodesList[index]).attr('aria-label', dataPoint.id + ",\n\t\t\t\t\t" + _this.i18nService.get('charts_x_axis') + " " + options.axis.x.categories[dataPoint.x] + ",\n\t\t\t\t\t" + _this.i18nService.get('charts_y_axis') + " " + dataPoint.value + ", ");
        });
    };
    TimeSeriesChartComponent.prototype.setNavigationAndTooltipKeyboardSupport = function () {
        var _this = this;
        var elements = this.el.querySelectorAll("circle.c3-circle");
        d3.selectAll(elements)
            .attr('tabindex', '-1')
            .attr('focusable', 'true')
            .on('keydown', function (item) {
            if (!d3.event)
                return;
            else if (d3.event.code === 'Space') {
                _this.showTooltip(item);
                d3.event.preventDefault();
                d3.event.stopPropagation();
            }
            else if (d3.event.code === 'Escape') {
                _this.hideTooltip();
            }
            else {
                _this.onNavigation(item, d3.event.code);
            }
        })
            .on('focus', function (item) {
            _this.showTooltip(item);
        })
            .on('blur', function () {
            _this.hideTooltip();
        });
        d3.selectAll([elements[0]]).attr('tabindex', '0');
    };
    TimeSeriesChartComponent.prototype.onNavigation = function (item, event) {
        if (event.code === 'ArrowRight') {
            event.preventDefault();
            event.stopImmediatePropagation();
            var target = event.target;
            var next = event.target.nextElementSibling;
            if (!next) {
                var line = target.parentElement.parentElement;
                next = line.querySelector("circle.c3-circle");
            }
            this.setTabindexAndFocus(target, next);
        }
        else if (event.code === 'ArrowLeft') {
            event.preventDefault();
            event.stopImmediatePropagation();
            var target = event.target;
            var next = target.previousElementSibling;
            if (!next) {
                var line = target.parentElement.parentElement;
                next = line.querySelector("circle.c3-circle:last-child");
            }
            this.setTabindexAndFocus(target, next);
        }
        else if (event.code === 'ArrowDown') {
            event.preventDefault();
            event.stopImmediatePropagation();
            var target = event.target;
            var nextLine = target.parentElement.parentElement.nextElementSibling;
            var next = nextLine && nextLine.querySelectorAll('circle.c3-circle')[item.index];
            if (!next) {
                var firstLine = target.parentElement.parentElement.parentElement.children[0];
                next = firstLine && firstLine.querySelectorAll('circle.c3-circle')[item.index];
            }
            this.setTabindexAndFocus(target, next);
        }
        else if (event.code === 'ArrowUp') {
            event.preventDefault();
            event.stopImmediatePropagation();
            var target = event.target;
            var prevLine = target.parentElement.parentElement.previousElementSibling;
            var next = prevLine && prevLine.querySelectorAll('circle.c3-circle')[item.index];
            if (!next) {
                var lines = target.parentElement.parentElement.parentElement.children;
                var lastLine = lines[lines.length - 1];
                next = lastLine && lastLine.querySelectorAll('circle.c3-circle')[item.index];
            }
            this.setTabindexAndFocus(target, next);
        }
    };
    TimeSeriesChartComponent.prototype.setTabindexAndFocus = function (target, next) {
        if (!next)
            return;
        target.setAttribute('tabindex', '-1');
        next.setAttribute('tabindex', '0');
        next.focus();
    };
    return TimeSeriesChartComponent;
}(ChartComponent));
export { TimeSeriesChartComponent };
