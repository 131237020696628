var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (b.hasOwnProperty(p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
var __metadata = (this && this.__metadata) || function (k, v) {
    if (typeof Reflect === "object" && typeof Reflect.metadata === "function") return Reflect.metadata(k, v);
};
import { Entity, EntityField, EntityModelBase } from '@microsoft/paris';
var ɵ0 = function (itemId, entity, config, params) {
    if (params && params['useV3Api']) {
        return 'cloud/live_response/get_properties';
    }
    return params && params['useV2Api'] ? 'v2/live_response/get_properties' : 'live_response/get_properties';
}, ɵ1 = function (item, entity, config, options) {
    if (options['useV3Api']) {
        return 'cloud/live_response/update_properties';
    }
    return options.params['useV2Api'] ? 'v2/live_response/update_properties' : 'live_response/update_properties';
}, ɵ2 = function (item, serializedItem) { return ({
    properties: serializedItem,
}); }, ɵ3 = function (config, query) { return query && query.where && query.where['useV3Api'] ? config.data.serviceUrls.k8s : config.data.serviceUrls.automatedIr; };
var LiveResponseSettings = /** @class */ (function (_super) {
    __extends(LiveResponseSettings, _super);
    function LiveResponseSettings() {
        return _super !== null && _super.apply(this, arguments) || this;
    }
    __decorate([
        EntityField({ data: 'AutomatedIrLiveResponse' }),
        __metadata("design:type", Boolean)
    ], LiveResponseSettings.prototype, "liveResponseEnabled", void 0);
    __decorate([
        EntityField({ data: 'AutomatedIrUnsignedScripts' }),
        __metadata("design:type", Boolean)
    ], LiveResponseSettings.prototype, "unsignedScriptsEnabled", void 0);
    __decorate([
        EntityField({ data: 'LiveResponseForServers', defaultValue: false }),
        __metadata("design:type", Boolean)
    ], LiveResponseSettings.prototype, "liveResponseForServers", void 0);
    LiveResponseSettings = __decorate([
        Entity({
            singularName: 'Live Response setting',
            pluralName: 'Live Response settings',
            endpoint: 'NOOP',
            parseItemQuery: ɵ0,
            parseSaveQuery: ɵ1,
            serializeItem: ɵ2,
            saveMethod: 'PATCH',
            baseUrl: ɵ3,
            cache: {
                max: 1,
                time: 1000 * 60 * 5,
            },
        })
    ], LiveResponseSettings);
    return LiveResponseSettings;
}(EntityModelBase));
export { LiveResponseSettings };
export { ɵ0, ɵ1, ɵ2, ɵ3 };
