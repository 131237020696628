import {
	EntityRelationship,
	EntityRelationshipRepositoryType,
	RelationshipType,
	DataQuery,
} from '@microsoft/paris';
import { FileVerdict } from '../file.file-verdict.value-object';
import { Alert } from '../../alert/alert.entity';

@EntityRelationship({
	sourceEntity: FileVerdict,
	dataEntity: Alert,
	endpoint: 'AssociatedAlerts',
	allowedTypes: [RelationshipType.OneToMany],
	getRelationshipData: (file: FileVerdict) => ({
		fileThreatName: file.threatName,
	}),
	allItemsProperty: 'Items',
	allItemsEndpointTrailingSlash: false,
	parseDataQuery: (dataQuery: DataQuery) => {
		const where: Record<string, string> =
			dataQuery && dataQuery.where ? (dataQuery.where as Record<string, string>) : {};

		return {
			detectionSource: 'WindowsDefenderAv',
			...where,
			lookBackInDays: 180,
		};
	},
})
export class FileVerdictAlertsRelationship implements EntityRelationshipRepositoryType<FileVerdict, Alert> {}
