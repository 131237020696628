import { DataviewField, DataviewFieldConfig } from '@wcd/dataview';
import { Injectable } from '@angular/core';
import { AuthenticatedScanResult } from '@wcd/domain';
import { I18nService } from '@wcd/i18n';
import { TvmColorService, TvmColor } from '../../../../tvm/services/tvm-color.service';
import { FabricIconNames } from '@wcd/scc-common';

@Injectable()
export class AuthenticatedScanResultFieldsService {
    private _networkScanResultFields: Array<DataviewField<AuthenticatedScanResult>>;
    private _windowsScanResultFields: Array<DataviewField<AuthenticatedScanResult>>;
    private _commonScanResultFields: Array<DataviewFieldConfig<AuthenticatedScanResult>> = [
		{
			id: 'ip',
			name: this.i18nService.get('tvm_authenticatedScan_wizard_fields_device_ip'),
			alwaysDisplay: true,
		},
		{
			id: 'scanSuccess',
			name: this.i18nService.get('tvm_authenticatedScan_wizard_fields_status'),
			getDisplay: (scanResult: AuthenticatedScanResult) => {
				if (scanResult.scanSuccess) {
					return this.i18nService.get('tvm_authenticatedScan_wizard_fields_status_success');
				}
				return this.i18nService.get('tvm_authenticatedScan_wizard_fields_status_fail');
			},
			icon: {
				fabricIcon: FabricIconNames.StatusCircleInner,
				className: (scanResult: AuthenticatedScanResult) => `ms-fontColor-${scanResult.scanSuccess ? this.tvmColorService.colorsMap.get(TvmColor.Green).name : this.tvmColorService.colorsMap.get(TvmColor.HighRisk).name}`,
			},
			alwaysDisplay: true,
		},
		{
			id: 'errorMessage',
			name: this.i18nService.get('tvm_authenticatedScan_wizard_fields_failure_reason'),
			alwaysDisplay: true,
		},
	];

    constructor(
        private i18nService: I18nService,
        private tvmColorService: TvmColorService,
    ) { }

	get windowsScanResultFields(): Array<DataviewField<AuthenticatedScanResult>> {
		if (!this._windowsScanResultFields) {
			// We set always display to all of them as we didn't allow columns customization
            this._windowsScanResultFields = DataviewField.fromList<AuthenticatedScanResult>(this._commonScanResultFields);
		}

		return this._windowsScanResultFields;
	}

    get networkScanResultFields(): Array<DataviewField<AuthenticatedScanResult>> {
        if (!this._networkScanResultFields) {
            // We set always display to all of them as we didn't allow columns customization
            this._networkScanResultFields = DataviewField.fromList<AuthenticatedScanResult>(
				[
				...this._commonScanResultFields,
				{
					id: 'hostName',
					name: this.i18nService.get('tvm_authenticatedScan_wizard_fields_device_name'),
					getDisplay: (scanResult: AuthenticatedScanResult) => scanResult.hostName || this.i18nService.get('tvm_network_devices_unknown_device_name'),
					alwaysDisplay: true,
				},
				{
					id: 'description',
					name: this.i18nService.get('tvm_authenticatedScan_wizard_fields_device_description'),
					alwaysDisplay: true,
				},
            ]);
        }

        return this._networkScanResultFields;
    }
}
