var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (b.hasOwnProperty(p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
import { Injector, ChangeDetectorRef } from '@angular/core';
import { UrlUrlStatsRelationship, UrlActiveAlertsSummaryRelationship, AlertsSeveritySummary, WebContentFilteringCategoryLookup, WebContentFilteringCategoryTypes, } from '@wcd/domain';
import { EntityDetailsComponentBase } from './entity-details.component.base';
import { of } from 'rxjs';
import { finalize, tap } from 'rxjs/operators';
import { ContentState } from '@wcd/contents-state';
import { Paris } from '@microsoft/paris';
import { TimeRangesService } from '../../../shared/services/time-ranges.service';
import { EntityDetailsMode } from '../../models/entity-details-mode.enum';
import { catchHttpError } from '../../../utils/rxjs/rxjs-custom-operators';
import { I18nService } from '@wcd/i18n';
import { SidePanelService } from '../../../@entities/@tvm/common/side-panel.service';
import { Feature, FeaturesService } from '@wcd/config';
import _ from 'lodash';
var CollapsibleID;
(function (CollapsibleID) {
    CollapsibleID["SecurityInfo"] = "url-entity-details-security-info";
    CollapsibleID["DomainDetails"] = "url-entity-details-domain-details";
})(CollapsibleID || (CollapsibleID = {}));
var UrlEntityDetailsComponent = /** @class */ (function (_super) {
    __extends(UrlEntityDetailsComponent, _super);
    function UrlEntityDetailsComponent(injector, changeDetectorRef, paris, timeRangesService, i18nService, sidePanelService, featuresService) {
        var _this = _super.call(this, injector) || this;
        _this.changeDetectorRef = changeDetectorRef;
        _this.paris = paris;
        _this.timeRangesService = timeRangesService;
        _this.i18nService = i18nService;
        _this.sidePanelService = sidePanelService;
        _this.featuresService = featuresService;
        _this.collapsibleID = CollapsibleID;
        _this.dataAsset = _this.i18nService.get('help_externalLoadError_data_asset');
        _this.urlStatsContentState = ContentState.Loading;
        _this.timeRanges = _this.timeRangesService.standard;
        _this.shouldHideIfLoadingOrNoData = false;
        return _this;
    }
    Object.defineProperty(UrlEntityDetailsComponent.prototype, "url", {
        get: function () {
            return this.entity;
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(UrlEntityDetailsComponent.prototype, "whoIsLink", {
        get: function () {
            return "https://www.whois.com/whois/" + this.url.name;
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(UrlEntityDetailsComponent.prototype, "showUrlStats", {
        get: function () {
            // don't show url stats in entity page - there's a widget dedicated for them
            return this.mode !== EntityDetailsMode.EntityPage;
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(UrlEntityDetailsComponent.prototype, "showUrlAlertsCount", {
        get: function () {
            return this.mode === EntityDetailsMode.EntityPage;
        },
        enumerable: true,
        configurable: true
    });
    UrlEntityDetailsComponent.prototype.setEntity = function (entity) {
        _super.prototype.setEntity.call(this, entity);
        this.setUrlDetails();
        this.isWebContentFilteringFeatureEnabled = this.featuresService.isEnabled(Feature.WebContentFilteringPolicy);
        this.setSmartScreenCategoryLookupDetails();
        if (this.showUrlStats) {
            this.currentRange = this.timeRanges.find(function (range) { return range.id === "month" /* month */; });
            this.onStatRangeChange();
        }
        this.changeDetectorRef.markForCheck();
    };
    UrlEntityDetailsComponent.prototype.setUrlDetails = function () {
        var _this = this;
        this.urlDetails$ = this.showUrlAlertsCount
            ? this.paris
                .getRelatedItem(UrlActiveAlertsSummaryRelationship, this.url)
                .pipe(catchHttpError(404, new AlertsSeveritySummary()), finalize(function () { return _this.changeDetectorRef.markForCheck(); }))
            : of(null);
    };
    UrlEntityDetailsComponent.prototype.setSmartScreenCategoryLookupDetails = function () {
        var _this = this;
        if (this.isWebContentFilteringFeatureEnabled) {
            this.shouldHideIfLoadingOrNoData = true;
            this.categoryAsString = this.category2AsString = null;
            this.paris.apiCall(WebContentFilteringCategoryLookup, {
                Url: this.url.name
            }).subscribe(function (res) {
                if (res != null && res.length != 0) {
                    _this.shouldHideIfLoadingOrNoData = false;
                    _this.urlPrimaryCategory = res[0];
                    var categoryKey = _.findKey(WebContentFilteringCategoryTypes, function (v) { return v === _this.urlPrimaryCategory; });
                    _this.categoryAsString = _this.i18nService.get("webCategories_categories_" + (categoryKey.charAt(0).toLowerCase() + categoryKey.substring(1)));
                    // It is possible that we have 2 categories. If so, get the second category. Otherwise, the main category is the first element in the array
                    if (res.length == 2) {
                        var category2AsNumber_1 = res[1];
                        var category2Key = _.findKey(WebContentFilteringCategoryTypes, function (v) { return v === category2AsNumber_1; });
                        _this.category2AsString = _this.i18nService.get("webCategories_categories_" + (category2Key.charAt(0).toLowerCase() + category2Key.substring(1)));
                    }
                }
                else {
                    _this.shouldHideIfLoadingOrNoData = true;
                }
                _this.changeDetectorRef.markForCheck();
            }, (function (err) {
                // Possibility of error when unknown geo, we fail gracefully here on purpose
                _this.shouldHideIfLoadingOrNoData = true;
            }));
        }
    };
    UrlEntityDetailsComponent.prototype.onStatRangeChange = function () {
        var _this = this;
        this.urlStatsContentState = ContentState.Loading;
        this.urlStats$ = this.paris
            .getRelatedItem(UrlUrlStatsRelationship, this.entity, {
            where: { lookingBackInDays: this.currentRange.value },
        })
            .pipe(tap(function (urlStats) {
            return (_this.urlStatsContentState = urlStats ? ContentState.Complete : ContentState.Empty);
        }, function () { return (_this.urlStatsContentState = ContentState.Error); }), finalize(function () { return _this.changeDetectorRef.markForCheck(); }));
    };
    UrlEntityDetailsComponent.prototype.openDisputeCategory = function () {
        this.sidePanelService.showDisputeCategorySidePanel(this.url.name, this.urlPrimaryCategory, this.categoryAsString);
    };
    return UrlEntityDetailsComponent;
}(EntityDetailsComponentBase));
export { UrlEntityDetailsComponent };
