var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (b.hasOwnProperty(p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
import { ChangeDetectorRef, ComponentRef, EventEmitter, Injector, } from '@angular/core';
import { DataQuerySortDirection, Paris } from '@microsoft/paris';
import { ContentState } from '@wcd/contents-state';
import { BehaviorSubject, combineLatest, merge, of, Subject } from 'rxjs';
import { catchError, filter, map, mergeMap, shareReplay, startWith, switchMap, tap } from 'rxjs/operators';
import { ActiveAlertStatuses, Alert, AlertStatusType, EnvironmentName, Machine, MachineAatpProfileRelationship, MachineAlertsRelationship, MachineAlertsSeveritySummaryRelationship, MachineIncidentsCountRelationship, MachineInterceptingMachinesRelationship, MachineSecurityAnalyticsRelationship, MachineUserDetailsRelationship, MdeAttachErrorToShortMessageKey, OperatingSystemPlatformCategories, getManagedByStringKey, getEnrollmentStatusStringKey, shouldShowMdeNotes, } from '@wcd/domain';
import { MachinesService } from '../../../@entities/machines/services/machines.service';
import { AdvancedFeaturesService } from '../../../admin/integration-settings/advanced-features.service';
import { AppContextService, FlavorService, Feature, FeaturesService, TvmLicensesAngularService, } from '@wcd/config';
import { RbacService } from '../../../rbac/services/rbac.service';
import { ActivatedEntity } from '../../services/activated-entity.service';
import { GlobalEntityTypesService } from '../../services/global-entity-types.service';
import { EntityDetailsComponentBase } from './entity-details.component.base';
import { I18nService } from '@wcd/i18n';
import { EntityDetailsMode } from '../../models/entity-details-mode.enum';
import { AlertsFields } from '../../../@entities/alerts/services/alerts.fields';
import { AlertsDataviewPanelComponent } from '../../../@entities/alerts/components/alerts.dataview-panel';
import { PanelType } from '@wcd/panels';
import { DialogsService } from '../../../dialogs/services/dialogs.service';
import { DataTableComponent } from '@wcd/datatable';
import { AppConfigService, ServiceUrlsService } from '@wcd/app-config';
import { toObservable } from '../../../utils/rxjs/utils';
import { AppFlavorConfig, TvmLicenseType } from '@wcd/scc-common';
import { MemEnrollmentStatus, shouldShowMdeRecommendationsAndNotifications } from '@wcd/domain';
import { FabricIconNames } from '@wcd/scc-common';
import _ from 'lodash';
import { sccHostService } from '@wcd/scc-interface';
export var MACHINE_lOGGED_ON_USERS_DEFAULT_RANGE_IN_DAYS = 30, MACHINE_ALERTS_DEFAULT_RANGE_IN_DAYS = 180, MACHINE_ALERTS_DEFAULT_PARAMS = {
    lookingBackInDays: MACHINE_ALERTS_DEFAULT_RANGE_IN_DAYS,
    status: [AlertStatusType.New, AlertStatusType.InProgress],
};
var ALERTS_FIELDS = ['title', 'severity', 'status', 'lasteventtime'];
var ALERTS_SORT_BY_FIELD = 'severity';
var MtpProdUrlPrefix = 'https://security.microsoft.com/';
var MtpStagingUrlPrefix = 'https://security.officeppe.com/';
var MdeAttachUpdatedSenseVersion = '10.8040';
export var CollapsibleID;
(function (CollapsibleID) {
    CollapsibleID["SecurityInfo"] = "machine-entity-security-info";
    CollapsibleID["NetworkActivity"] = "machine-entity-network-activity";
    CollapsibleID["Tags"] = "machine-entity-tags";
    CollapsibleID["ActiveAlerts"] = "machine-entity-active-alerts";
    CollapsibleID["Details"] = "machine-entity-details";
    CollapsibleID["DirectoryData"] = "machine-entity-directory-data";
    CollapsibleID["UserDetails"] = "machine-entity-user-details";
    CollapsibleID["DeviceManagement"] = "machine-entity-device-management";
})(CollapsibleID || (CollapsibleID = {}));
var MachineEntityDetailsComponent = /** @class */ (function (_super) {
    __extends(MachineEntityDetailsComponent, _super);
    function MachineEntityDetailsComponent(injector, activatedEntity, globalEntityTypesService, rbacService, machinesService, flavorService, i18nService, featuresService, advancedFeaturesService, paris, changeDetectorRef, dialogsService, machineService, appConfigService, tvmLicensesService, serviceUrlsService, alertsFields, appContext) {
        var _this = _super.call(this, injector) || this;
        _this.globalEntityTypesService = globalEntityTypesService;
        _this.machinesService = machinesService;
        _this.flavorService = flavorService;
        _this.i18nService = i18nService;
        _this.featuresService = featuresService;
        _this.advancedFeaturesService = advancedFeaturesService;
        _this.paris = paris;
        _this.changeDetectorRef = changeDetectorRef;
        _this.dialogsService = dialogsService;
        _this.machineService = machineService;
        _this.appConfigService = appConfigService;
        _this.tvmLicensesService = tvmLicensesService;
        _this.serviceUrlsService = serviceUrlsService;
        _this.collapsibleSections = true;
        _this.includeMachineName = false;
        _this.loggedOnUsersClick = new EventEmitter();
        _this._machineInput$ = new BehaviorSubject(undefined);
        _this.isLoadingUsers = false;
        _this.isLoadingAlertSeverities = false;
        _this.isLoadingIncidents = false;
        _this.aatpAlertsContentState = ContentState.Loading;
        _this.isShowMdeAttachNotificationBar = true;
        _this.EntityDetailsMode = EntityDetailsMode;
        _this.collapsibleID = CollapsibleID;
        _this.FabricIconNames = FabricIconNames;
        _this._tagsSubject$ = new Subject();
        _this.itpDirectoryDataProperties = [
            {
                title: _this.i18nService.get('machines.entityDetails.fields.uacFlags.title'),
                seeAllText: function (machine) { return _this.i18nService.get('machines.entityDetails.fields.uacFlags.seeAll'); },
                seeAllOnClick: function (machine) { return _this.machinesService.showMachineDirectoryData(machine); },
                dataTrackId: 'ShowMachineDirectoryDataPanel',
                getPropertyData: function (machine) { return machine.uacFlags; },
                exposeProperty: function (machine) { return !!machine.uacFlags; },
                noDataText: function () { return _this.i18nService.get('machine.details.directory.uac.none.active'); },
            },
            {
                title: _this.i18nService.get('machines.entityDetails.fields.spns.title'),
                seeAllText: function (machine) {
                    return _this.i18nService.get('machines.entityDetails.fields.spns.seeAll', {
                        count: machine.spns && machine.spns.length,
                    });
                },
                seeAllOnClick: function (machine) { return _this.machinesService.showMachineDirectoryData(machine); },
                dataTrackId: 'ShowMachineDirectoryDataPanel',
                getPropertyData: function (machine) { return machine.spns; },
                exposeProperty: function (machine) { return machine.spns && machine.spns.length > 0; },
                noDataText: function () { return _this.i18nService.get('machine.details.directory.spn.no.spns.found'); },
            },
            {
                title: _this.i18nService.get('machines.entityDetails.fields.groupMembership.title'),
                seeAllText: function (machine) {
                    return _this.i18nService.get('machines.entityDetails.fields.groupMembership.seeAll', {
                        count: machine.groupMembership && machine.groupMembership.length,
                    });
                },
                seeAllOnClick: function (machine) { return _this.machinesService.showMachineDirectoryData(machine); },
                dataTrackId: 'ShowMachineDirectoryDataPanel',
                getPropertyData: function (machine) { return machine.groupMembership; },
                exposeProperty: function (machine) { return machine.groupMembership && machine.groupMembership.length > 0; },
                noDataText: function () { return _this.i18nService.get('machine.details.directory.group.membership.no.groups'); },
            },
        ];
        _this.MAX_ALERTS = 5;
        _this.isMtp = appContext.isMtp;
        var activatedMachine$ = activatedEntity.currentEntity$.pipe(filter(function (model) { return model instanceof Machine; }));
        _this.isSmbFlavor = _this.flavorService.isEnabled(AppFlavorConfig.settings.mdeAttach);
        _this.isEndpointManagementEnabled =
            (_this.featuresService.isEnabled(Feature.EndpointConfigManagement) &&
                _this.featuresService.isEnabled(Feature.EndpointConfigManagementFe)) ||
                _this.isSmbFlavor;
        _this.withUsers = _this.flavorService.isEnabled(AppFlavorConfig.devices.users);
        _this.withExposureLevel = _this.shouldDisplayTvmBasicSection();
        _this.directoryDataEnabledByLicense = _this.flavorService.isEnabled(AppFlavorConfig.devices.directoryData);
        _this.isEndpointConfigManagementMdeWithSccmEnabled = _this.featuresService.isEnabled(Feature.EndpointConfigManagementMdeWithSccm);
        // merge the two ways of getting a machine - first get the info that's already on the machine object from the input, then make
        // another emission with the full data received from the BE, in case some of the data was missing on the input data.
        _this.machine$ = merge(_this._machineInput$.pipe(switchMap(function (machine) { return (machine ? of(machine) : activatedMachine$); })), _this._machineInput$.pipe(filter(Boolean), switchMap(function (machine) {
            return paris.getItemById(Machine, machine.id);
        })));
        _this.seenBy$ = _this.featuresService.isEnabled(Feature.MagellanDevicePageSeenByField)
            ? _this.machine$.pipe(mergeMap(function (device) {
                return _this.isDiscoveredDevice(device)
                    ? _this.paris.getRelatedItem(MachineInterceptingMachinesRelationship, device)
                    : of(null);
            }), catchError(function () { return of(null); }))
            : of(null);
        _this.machine$.subscribe(function (m) {
            _this.entityType = _this.globalEntityTypesService.getEntityType(m.constructor);
            //TODO: When removing a tag the tag list doesn't get updated. need to support the after action refresh idea that is available for the dataview / panel (see refreshOnResolve, refreshEntityPanelOnResolve...)
            _this.tags$ = merge(toObservable(_this.entityType.getTags([m])), _this._tagsSubject$).pipe(shareReplay(1));
            /**
             * the tags list shows merge of the tags and the pseudo tags. the tags edit component shows/edits only real tags.
             */
            var tagsWithPseudoTagsSources = [_this.tags$].concat([_this.entityType.pseudoTags.get([m])]);
            _this.tagsWithPseudoTags$ = combineLatest.apply(void 0, tagsWithPseudoTagsSources).pipe(map(function (_a) {
                var realTags = _a[0], pseudoTags = _a[1];
                return pseudoTags.concat(realTags);
            }), shareReplay(1));
        });
        _this.machineSecurityAnalytics$ = _this.featuresService.isEnabled(Feature.MachineSecurityScore)
            ? _this.machine$.pipe(mergeMap(function (machine) {
                return _this.paris.getRelatedItem(MachineSecurityAnalyticsRelationship, machine);
            }), catchError(function () { return of(null); }))
            : of(null);
        var isUserExposedToMachine$ = _this.machine$.pipe(mergeMap(function (machine) {
            return rbacService.isUserExposedToEntity(_this.globalEntityTypesService.getEntityType(Machine), machine);
        }), map(function (userExposureResult) { return userExposureResult.isExposed; }));
        var machineWhenUserExposed$ = combineLatest(_this.machine$, isUserExposedToMachine$).pipe(filter(function (_a) {
            var _ = _a[0], isUserExposedToMachine = _a[1];
            return isUserExposedToMachine;
        }), map(function (_a) {
            var machine = _a[0], _ = _a[1];
            return machine;
        }));
        _this.alertSeveritySummary$ = machineWhenUserExposed$.pipe(tap(function () {
            _this.isLoadingAlertSeverities = true;
            _this.changeDetectorRef.markForCheck();
        }), mergeMap(function (machine) {
            return _this.paris.getRelatedItem(MachineAlertsSeveritySummaryRelationship, machine, {
                where: MACHINE_ALERTS_DEFAULT_PARAMS,
            });
        }), tap(function () {
            _this.isLoadingAlertSeverities = false;
            _this.changeDetectorRef.markForCheck();
        }, function () {
            _this.isLoadingAlertSeverities = false;
            _this.changeDetectorRef.markForCheck();
        }));
        _this.alerts$ = _this.machine$.pipe(mergeMap(function (machine) {
            var repository = _this.paris.getRelationshipRepository(MachineAlertsRelationship);
            repository.sourceItem = machine;
            var queryOptions = {
                sortBy: [
                    {
                        field: ALERTS_SORT_BY_FIELD,
                        direction: DataQuerySortDirection.descending,
                    },
                ],
                where: {
                    status: ActiveAlertStatuses,
                },
            };
            return repository.query(queryOptions).pipe(tap(function (result) { return (_this.allAlerts = result.items); }), map(function (result) { return result.items.slice(0, _this.MAX_ALERTS); }));
        }));
        _this.incidentsCount$ = machineWhenUserExposed$.pipe(tap(function () {
            _this.isLoadingIncidents = true;
            _this.changeDetectorRef.markForCheck();
        }), mergeMap(function (machine) {
            return _this.paris.getRelatedItem(MachineIncidentsCountRelationship, machine);
        }), map(function (incidentsCount) { return incidentsCount.count; }), tap(function () {
            _this.isLoadingIncidents = false;
            _this.changeDetectorRef.markForCheck();
        }, function () {
            _this.isLoadingIncidents = false;
            _this.changeDetectorRef.markForCheck();
        }));
        _this.userDetails$ = machineWhenUserExposed$.pipe(tap(function () {
            _this.isLoadingUsers = true;
            _this.changeDetectorRef.markForCheck();
        }), mergeMap(function (machine) {
            return _this.paris.getRelatedItem(MachineUserDetailsRelationship, machine);
        }), tap(function () {
            _this.isLoadingUsers = false;
            _this.changeDetectorRef.markForCheck();
        }, function () {
            _this.isLoadingUsers = false;
            _this.changeDetectorRef.markForCheck();
        }));
        _this.isUserLinkAllowed$ = _this.machine$.pipe(map(function (machine) {
            return machine && machine.os && machine.os.platform
                ? machine.os.platform.category !== OperatingSystemPlatformCategories.Windows7
                : true;
        }), startWith(true));
        _this.aatpAlertsDetails$ = _this.machine$.pipe(tap(function () {
            _this.aatpAlertsContentState = ContentState.Loading;
            _this.changeDetectorRef.markForCheck();
        }), mergeMap(function (machine) { return _this.getAatpProfile(machine); }), tap(function (result) {
            _this.aatpAlertsContentState = result ? ContentState.Complete : ContentState.Empty;
            _this.changeDetectorRef.markForCheck();
        }, function () {
            _this.aatpAlertsContentState = ContentState.Error;
            _this.changeDetectorRef.markForCheck();
        }));
        _this.alertsColumns = alertsFields.allFields
            .filter(function (field) { return ALERTS_FIELDS.includes(field.id); })
            .map(function (field) { return field.clone({ maxWidth: field.maxWidth || 200, sort: { enabled: false } }); });
        _this.isMdeAttachEnabled = false;
        if (sccHostService.isSCC) {
            sccHostService.ajax
                .get(serviceUrlsService.k8s + "/cloud/public/internal/Siam/MdeAttachEnabled")
                .then(function (res) { return (_this.isMdeAttachEnabled = res.data); });
        }
        _this.hasMdeLicenseWorkloads = flavorService.isEnabled(AppFlavorConfig.settings.mdeWithWorkloads);
        return _this;
    }
    Object.defineProperty(MachineEntityDetailsComponent.prototype, "machine", {
        get: function () {
            return this._machineInput$.value;
        },
        set: function (machine) {
            var machineInputValue = this._machineInput$.getValue();
            if (!machineInputValue || machineInputValue.id !== machine.id) {
                this._machineInput$.next(machine);
            }
        },
        enumerable: true,
        configurable: true
    });
    MachineEntityDetailsComponent.prototype.getMdeAttachTitle = function (machine) {
        if (machine.memEnrollmentStatus === MemEnrollmentStatus.Unknown) {
            var osName = machine.os.osPlatformString;
            if (osName &&
                osName.startsWith('Windows') &&
                machine.senseClientVersion < MdeAttachUpdatedSenseVersion) {
                return this.i18nService.get('machines_memEnrollment_title_note');
            }
            if (osName && !osName.startsWith('Windows')) {
                return this.i18nService.get('machines_memEnrollment_title_note');
            }
        }
        if (machine.memEnrollmentStatus === MemEnrollmentStatus.NotEnrolled || machine.memEnrollmentStatus === MemEnrollmentStatus.Unenrolled) {
            return this.i18nService.get('machines_memEnrollment_title_note');
        }
        return this.i18nService.get('machines_memEnrollment_title');
    };
    MachineEntityDetailsComponent.prototype.getMdeAttachError = function (machine) {
        if (machine.memEnrollmentStatus in MemEnrollmentStatus) {
            var memEnrollmentStatus = machine.memEnrollmentStatus;
            if (memEnrollmentStatus === MemEnrollmentStatus.Unknown) {
                var osName = machine.os.osPlatformString;
                if (osName &&
                    osName.startsWith('Windows') &&
                    machine.senseClientVersion < MdeAttachUpdatedSenseVersion) {
                    return this.i18nService.get('machines_memEnrollmentStatus_UnknownWindows');
                }
                if (osName && !osName.startsWith('Windows')) {
                    return this.i18nService.get('machines_memEnrollmentStatus_Unknown_NotWindows');
                }
            }
            if (memEnrollmentStatus === MemEnrollmentStatus.NotEnrolled || memEnrollmentStatus === MemEnrollmentStatus.Unenrolled) {
                return this.i18nService.get('machines_memEnrollmentStatus_NotEnrolled');
            }
            return this.i18nService.get("machines_memEnrollmentStatus_" + MdeAttachErrorToShortMessageKey(machine.memEnrollmentStatus));
        }
        return this.i18nService.get('machines_memEnrollmentStatus_GeneralError');
    };
    MachineEntityDetailsComponent.prototype.shouldShowMdeRecommendationsAndNotifications = function (machine) {
        return shouldShowMdeRecommendationsAndNotifications(machine.memEnrollmentStatus);
    };
    MachineEntityDetailsComponent.prototype.shouldShowMdeNotes = function (machine) {
        return shouldShowMdeNotes(machine, this.isMdeAttachEnabled);
    };
    MachineEntityDetailsComponent.prototype.shouldShowDeviceManagementCollapsible = function (machine) {
        return (this.hasMdeLicenseWorkloads &&
            this.isEndpointManagementEnabled &&
            !_.isNil(machine.memEnrollmentStatus));
    };
    MachineEntityDetailsComponent.prototype.getManagedByString = function (machine) {
        return this.i18nService.get(getManagedByStringKey(machine.memEnrollmentStatus, this.isEndpointConfigManagementMdeWithSccmEnabled));
    };
    MachineEntityDetailsComponent.prototype.getEnrollmentStatusString = function (machine) {
        return this.i18nService.get(getEnrollmentStatusStringKey(machine.memEnrollmentStatus));
    };
    MachineEntityDetailsComponent.prototype.getMtpDevicePageLink = function (machine) {
        var relativeMachinePath = this.machineService.getMachineLink(machine.name);
        return this.appConfigService.environmentName === EnvironmentName.Production
            ? "" + MtpProdUrlPrefix + relativeMachinePath
            : "" + MtpStagingUrlPrefix + relativeMachinePath;
    };
    MachineEntityDetailsComponent.prototype.getAatpProfile = function (machine) {
        var _this = this;
        return this.advancedFeaturesService.getAdvancedFeaturesSettings().pipe(mergeMap(function (advancedFeatures) {
            return advancedFeatures.aatpIntegrationEnabled && advancedFeatures.aatpWorkspaceExists
                ? _this.paris
                    .getRelatedItem(MachineAatpProfileRelationship, machine)
                    .pipe(map(function (aatpProfile) { return ({
                    count: aatpProfile.securityAlertSummary.high +
                        aatpProfile.securityAlertSummary.medium +
                        aatpProfile.securityAlertSummary.low,
                    url: aatpProfile.url,
                }); }))
                : of(null);
        }));
    };
    MachineEntityDetailsComponent.prototype.isDiscoveredDevice = function (_a) {
        var isManagedByMdatp = _a.isManagedByMdatp;
        return isManagedByMdatp === false;
    };
    MachineEntityDetailsComponent.prototype.getDeviceLink = function (deviceId) {
        return this.machinesService.getMachineLink(deviceId, false);
    };
    MachineEntityDetailsComponent.prototype.onShowMoreAlerts = function () {
        var _this = this;
        this.dialogsService
            .showPanel(AlertsDataviewPanelComponent, {
            id: 'alerts-dataview-panel-dataview',
            type: PanelType.large,
            isModal: true,
            noBodyPadding: true,
            scrollBody: false,
            noShadow: true,
            back: {
                onClick: function () { return _this.alertsSidePanel.destroy(); },
            },
        }, {
            alerts: this.allAlerts,
            defaultSortBy: ALERTS_SORT_BY_FIELD,
            onlyFields: ALERTS_FIELDS,
        })
            .subscribe(function (panel) {
            _this.alertsSidePanel = panel;
            panel.onDestroy(function () {
                _this.alertsSidePanel = null;
            });
        });
    };
    MachineEntityDetailsComponent.prototype.showCollapsibleDirectoryDataSection = function (machine) {
        return (this.directoryDataEnabledByLicense &&
            this.itpDirectoryDataProperties.some(function (itpProperty) {
                return itpProperty.exposeProperty(machine);
            }));
    };
    MachineEntityDetailsComponent.prototype.viewAlert = function ($event) {
        this.entityPanelsService.showEntity(Alert, $event.item);
    };
    MachineEntityDetailsComponent.prototype.updateTableView = function () {
        if (this.dataTable) {
            this.dataTable.updateHeaderCells();
        }
    };
    MachineEntityDetailsComponent.prototype.hideMessageBar = function () {
        this.isShowMdeAttachNotificationBar = false;
    };
    MachineEntityDetailsComponent.prototype.shouldDisplayTvmBasicSection = function () {
        var tvmLicensesEnabled = this.featuresService.isEnabled(Feature.TvmPremium);
        var isEnabledByFlavor = this.flavorService.isEnabled(AppFlavorConfig.devices.exposureLevel);
        var isEnabledByTvmLicenses = this.tvmLicensesService.isEnabled(TvmLicenseType.TvmBasic) ||
            // elkamin: delete this code once the nibiru code is in
            this.flavorService.isEnabled({ mdeFlavors: ['Smb'] });
        return tvmLicensesEnabled ? isEnabledByTvmLicenses : isEnabledByFlavor;
    };
    return MachineEntityDetailsComponent;
}(EntityDetailsComponentBase));
export { MachineEntityDetailsComponent };
