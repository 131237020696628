import { Injectable } from '@angular/core';
import { Paris } from '@microsoft/paris';
import { countBy, flatMap, mapValues, sortBy, uniq } from 'lodash-es';
import {
	Machine,
	MachineTagsCollectionRelationship,
	Tag,
	TagsCollection,
	MachineDataSensitivityRelationship,
	DataSensitivity,
	OperatingSystemPlatformCategories,
	MachineTags,
	MachineValue,
	Incident,
	ServiceSourceType,
	FIRST_SEEN_DEFAULT_TIME_COMPARISON,
	FirstSeen,
	MachineExclusionState,
} from '@wcd/domain';
import { AppConfigService } from '@wcd/app-config';
import { FeaturesService, Feature, AppContextService } from '@wcd/config';
import { DataviewField } from '@wcd/dataview';
import { MachineEntityDetailsComponent } from '../../../global_entities/components/entity-details/machine.entity-details.component';
import { EntityTypeService } from '../../../global_entities/models/entity-type-service.interface';
import { EntityDataViewOptions, EntityType } from '../../../global_entities/models/entity-type.interface';
import { FabricIconNames } from '@wcd/scc-common';
import { TimeRangeId } from '@wcd/date-time-picker';
import { TimeRangesService } from '../../../shared/services/time-ranges.service';
import { Lazy } from '@wcd/utils';
import { MachineEntityPanelComponent } from '../components/machine.entity-panel.component';
import { MachineEntityComponent } from '../components/machine.entity.component';
import { MachineEntityTypeActionsService, MachineAction } from './machine.entity-type-actions.service';
import { MachinesFieldsService } from './machines.fields';
import { HealthStatusCategoryFilter, MachinesFiltersService } from './machines.filters.service';
import { MachinesService } from './machines.service';
import { map, catchError } from 'rxjs/operators';
import { of, combineLatest } from 'rxjs';
import { SerializedFilters } from '@wcd/ng-filters';
import { TagsService } from '../../../tags/services/tags.service';
import { I18nService } from '@wcd/i18n';
import { EntityPageViewMode } from '../../../global_entities/models/entity-page-view-mode.enum';
import { getMachineName } from '../helpers/machine.helpers';
import { MachineMinimizedDetailsComponent } from '../../../global_entities/components/entity-minimized/machine.minimized.details.component';
import { MachineEntityStatusComponent } from './machine-entity-status.component';
import { MachineHeaderBottomComponent } from '../../../global_entities/components/entity-header-bottom/machine.header-bottom.component';
import { EntityCommandBarDisplayMode } from '../../../global_entities/models/entity-command-bar-display-mode.enum';
import { EntityViewType } from '../../../global_entities/models/entity-view-type.enum';
import { AdvancedFeaturesService } from '../../../admin/integration-settings/advanced-features.service';

export interface MachineDataViewFixedOptions {
	lookingBackIndays: string;
	machineNamePrefix?: string;
	isManagedByMdatp?: boolean;
	deviceCategories?: string;
	machinesApiServiceMigration?: boolean;
	useTvmMachinesAvStatus?: boolean;
}

export interface MachineEntityTypeOptions {
	actionTime?: Date;

	incident?: Incident;
}

export const EXCLUSION_STATES_FIELD_ID = 'exclusionStates';
export const ONBOARDING_STATUSES_FIELD_ID = 'onBoardingStatuses';

export const FILTER_ONLY_MACHINE_FIELDS = [
	'securityPropertiesRequiringAttention',
	'threatCategory',
	'noTags',
	'rbacGroupIds',
	'machineGroups',
	'__groupsWithTags',
	'outbreakId',
	'mitigationTypes',
	'vulnerabilitySeverityLevels',
	'vulnerabilityAgeLevels',
	'exploitLevels',
];

export interface MachineValueConfig {
	id: MachineValue;
	displayName: string;
}

@Injectable()
export class MachineEntityTypeService implements EntityTypeService<Machine> {
	private readonly _timeRanges = new Lazy(() =>
		this.timeRangesService.pick([
			TimeRangeId.day,
			TimeRangeId['3days'],
			TimeRangeId.week,
			TimeRangeId.month,
			TimeRangeId['6months'],
		])
	);

	public machineValues: MachineValueConfig[];

	constructor(
		private readonly paris: Paris,
		private readonly machinesService: MachinesService,
		private readonly machinesFiltersService: MachinesFiltersService,
		private readonly timeRangesService: TimeRangesService,
		private readonly featuresService: FeaturesService,
		private readonly appConfigService: AppConfigService,
		private readonly machinesEntityTypeActionsService: MachineEntityTypeActionsService,
		private readonly tagsService: TagsService,
		private readonly i18nService: I18nService,
		private readonly machineFieldsService: MachinesFieldsService,
		private readonly advancedFeaturesService: AdvancedFeaturesService,
		private appContext: AppContextService
	) {
		this.machineValues = [
			{
				id: MachineValue.Low,
				displayName: i18nService.get('machines_entityDetails_actions_machineValue_values_low'),
			},
			{
				id: MachineValue.Normal,
				displayName: i18nService.get('machines_entityDetails_actions_machineValue_values_normal'),
			},
			{
				id: MachineValue.High,
				displayName: i18nService.get('machines_entityDetails_actions_machineValue_values_high'),
			},
		];
	}

	readonly entityType: EntityType<Machine> = {
		id: 'machine',
		entity: Machine,
		icon: FabricIconNames.System,
		getIcon: (machines: Array<Machine>) => {
			const getIcon = (machine) =>
				machine.os &&
				machine.os.platform &&
				(machine.os.platform.id === OperatingSystemPlatformCategories.iOS ||
					machine.os.platform.id === OperatingSystemPlatformCategories.Android)
					? FabricIconNames.CellPhone
					: FabricIconNames.System;

			const firstIcon = getIcon(machines[0]);
			if (machines.length === 1 || machines.every((machine) => getIcon(machine) === firstIcon))
				return firstIcon;
			else return FabricIconNames.System;
		},
		getImage: () => {
			return `/assets/images/icons/entities/machine.svg`;
		},
		entityContentsComponentType: MachineEntityComponent,
		entityDetailsComponentType: MachineEntityDetailsComponent,
		entityPanelStatusComponentType: MachineEntityStatusComponent,
		singleEntityPanelComponentType: MachineEntityPanelComponent,
		entityMinimizedComponentType: MachineMinimizedDetailsComponent,
		entityHeaderBottomComponentType: MachineHeaderBottomComponent,
		getEntityName: getMachineName,
		entityPluralNameKey: 'machine_entityType_pluralName',
		entitySingularNameKey: 'machine_entityType_singularName',
		getEntityTooltip: (machine) =>
			machine.senseMachineId ? null : this.i18nService.get('machines.notOnboarded'),
		getEntityDataviewLink: () => '/machines',
		getEntityNotification: (machine: Machine) => {
			if (machine.isDeleted) {
				return machine.mergedIntoMachineId
					? this.i18nService.get('DevicePageInvalidDeviceMetgedIntoOtherDeviceNotification', {
							link: `/machines/${machine.mergedIntoMachineId}`,
					  })
					: this.i18nService.strings.DevicePageInvalidDeviceNotification;
			}
		},
		getAsyncBottomEntityNotification: (machine) => {
			if (
				!this.featuresService.isEnabled(Feature.ExcludedDevices) ||
				machine.exclusionState === MachineExclusionState.Included
			) {
				return of(null);
			}
			return of(this.i18nService.get('DevicePageExcludedDeviceNotification'));
		},
		getEntitiesLink: (machines: ReadonlyArray<Machine>, options: MachineEntityTypeOptions) => {
			if (machines.length === 1) {
				if (!machines[0] || (!machines[0].senseMachineId && !machines[0].name)) {
					return null;
				}

				const senseMachineId = machines[0].senseMachineId;
				const machineId =
					!senseMachineId && this.featuresService.isEnabled(Feature.NewDevicePage)
						? machines[0].name
						: senseMachineId;

				return this.machinesService.getMachineLink(machineId, true, options && options.actionTime);
			}
			return null;
		},
		getNavigationModel: (machine: Machine, serviceSource?: ServiceSourceType) => {
			const link =
				this.featuresService.isEnabled(Feature.PortedSccPages) ||
				!serviceSource ||
				serviceSource === ServiceSourceType.Wdatp
					? this.entityType.getEntitiesLink([machine])
					: null;
			return link ? { routerLink: [link], id: 'machine' } : null;
		},
		getActions: (
			machines: Array<Machine>,
			options?: MachineEntityTypeOptions,
			entityViewType?: EntityViewType,
			entityCommandBarDisplayMode?: EntityCommandBarDisplayMode
		) =>
			this.machinesEntityTypeActionsService.getEntityActions(
				machines,
				this,
				options,
				entityCommandBarDisplayMode
			),
		dataViewOptions: <EntityDataViewOptions<Machine, MachineDataViewFixedOptions>>{
			fields: this.machineFieldsService.fields,
			dateRangeOptions: {
				supportedRanges: this._timeRanges.value,
				defaultTimeRangeId: this.appConfigService.isDemoTenant ? '6months' : 'month',
			},
			defaultSortFieldId: 'riskScores',
			disabledFields: FILTER_ONLY_MACHINE_FIELDS,
			fixedOptions: (currentRange, searchTerm?) =>
				Object.assign<
					Pick<MachineDataViewFixedOptions, 'lookingBackIndays'>,
					Pick<MachineDataViewFixedOptions, 'machineNamePrefix'>,
					Pick<MachineDataViewFixedOptions, 'machinesApiServiceMigration'>,
					Pick<MachineDataViewFixedOptions, 'useTvmMachinesAvStatus'>
				>(
					{
						lookingBackIndays: currentRange.value.toString(),
					},
					searchTerm && {
						machineNamePrefix: searchTerm,
					},
					{
						machinesApiServiceMigration: this.featuresService.isEnabled(
							Feature.MachinesApiServiceMigration
						),
					},
					{
						useTvmMachinesAvStatus: this.featuresService.isEnabled(
							Feature.UseTvmMachinesAvStatus
						),
					}
				),
			dataViewConfig: {
				getFiltersData: (fixedOptions) => this.machinesService.getMachinesFilters(fixedOptions),
				disabledVisibleFieldIds: FILTER_ONLY_MACHINE_FIELDS,
				searchFilterValues: (field: DataviewField, term: string, options: any) =>
					this.machinesFiltersService.searchFilterValues(field, term, options),
			},
			exportOptions: {
				showModalOnExport: false,
				exportResults: (options, fixedOptions) =>
					this.machinesService.downloadCsv({ ...options, ...fixedOptions }),
			},
			lifecycleHooks: {
				ngOnDestroy: () => this.machinesService.clearMachinesFilters(),
			},
			getFilterQueryOptions: (serializedFilters: SerializedFilters) => {
				const mappedFilters: SerializedFilters = mapValues(
					serializedFilters,
					(_value, param) => serializedFilters[param]
				);

				if (serializedFilters.firstseen) {
					Object.assign(
						mappedFilters,
						this.getFirstSeenQuery(serializedFilters.firstseen as FirstSeen[])
					);

					delete mappedFilters.firstseen;
				}

				if (serializedFilters.healthStatuses) {
					mappedFilters.healthStatuses = this.getHealthStatusesQuery(
						serializedFilters.healthStatuses
					);
				}

				return mappedFilters;
			},
		},
		searchOptions: {
			displayName: this.i18nService.strings.entity_type_display_name_machine,
			getSearchParams: (searchTerm: string) => {
				return { url: `/searchResults/machines/${searchTerm}` };
			},
			searchDropdownOrder: 0,
		},
		getTags: (machines: ReadonlyArray<Machine>) => {
			if (machines.length === 1) {
				const isInternetFacing = this.featuresService.isEnabled(Feature.InternetFacing) && machines[0].isInternetFacing;
				const machineTags$ = (this.featuresService.isEnabled(Feature.UnifiedMachineAPI)
					? of<TagsCollection | MachineTags>(machines[0].extendedTags)
					: this.paris.getRelatedItem<Machine, TagsCollection>(
							MachineTagsCollectionRelationship,
							machines[0],
							{
								where: {
									migrateToVNext: this.featuresService.isEnabled(Feature.MachinesControllerMigrationGetTags)
								}
							}
					  )
				).pipe(
					map((tags: TagsCollection | MachineTags) => [...tags ? tags.allTags : [], ...isInternetFacing ? [this.tagsService.getInternetFacingTag()] : []]),
					catchError(() => of([]))
				);

				if (!this.featuresService.isEnabled(Feature.FileSensitivity)) {
					return machineTags$;
				}

				const tooltip = this.i18nService.get('machines.entityDetails.fields.sensitivity.tooltip');
				const sensitivityTag$ = this.paris
					.getRelatedItem<Machine, DataSensitivity>(MachineDataSensitivityRelationship, machines[0])
					.pipe(
						map((sensitivity) =>
							sensitivity && !!sensitivity.label ? [this.tagsService.getDataSensitivityTag(sensitivity, tooltip)] : []
						),
						catchError(() => of([]))
					);

				return combineLatest(sensitivityTag$, machineTags$).pipe(
					map(([sensitivityTags, machineTags]: [Array<Tag>, Array<Tag>]) => [
						...sensitivityTags,
						...machineTags,
					])
				);
			}

			const machineTagCounts = countBy(
				flatMap(
					machines,
					(machine) => (machine.extendedTags && machine.extendedTags.allTags) || machine.tags
				)
			);
			const machinesTags = Object.entries(machineTagCounts).map(
				([tag, count]) =>
					new Tag({
						id: tag,
						name: tag,
						isPartial: count < machines.length,
						isEditable: machines.every((machine) => machine.builtInTag !== tag),
					})
			);

			return of(sortBy(machinesTags, (tag) => tag.name.toLowerCase()));
		},
		pseudoTags: {
			get: (machines: Array<Machine>) => {
				return this.machinesService.getMachineValuePseudoTag(machines);
			},
			set: (machines: Array<Machine>, action: MachineAction) => {
				if (action === MachineAction.MachineValue) {
					return this.machinesService.setMachinesValue(machines);
				}
			},
		},
		entityPageViewMode: EntityPageViewMode.Asset,
		hideTagsInEntityComponent: true,
	};

	private getHealthStatusesQuery(value: string | string[]) {
		value = value instanceof Array ? value : [value];
		return value.reduce((allHealthStatuses, healthStatusId) => {
			let healthStatusCategory: HealthStatusCategoryFilter = this.machinesFiltersService
				.machineHealthStatusCategoriesMap[healthStatusId];

			const isCategory = !!healthStatusCategory;

			if (!healthStatusCategory) {
				const category: [string, HealthStatusCategoryFilter] = Object.entries(
					this.machinesFiltersService.machineHealthStatusCategoriesMap
				).find(
					([, healthStatusCategoryFilter]) =>
						healthStatusCategoryFilter &&
						healthStatusCategoryFilter.children.map((child) => child.id).includes(healthStatusId)
				);

				if (category) healthStatusCategory = category[1];
			}

			if (healthStatusCategory) {
				let filterValues = healthStatusCategory.values
					? healthStatusCategory.values.map((_value) => _value.id)
					: [];
				if (isCategory) {
					if (healthStatusCategory.children)
						filterValues = [
							...filterValues,
							...healthStatusCategory.children.map((child) => child.id),
						];
				} else filterValues.push(healthStatusId);

				return uniq([...allHealthStatuses, ...filterValues]);
			} else return [...allHealthStatuses, healthStatusId];
		}, []);
	}

	private getFirstSeenQuery(firstseen: FirstSeen[]) {
		return firstseen.length == 1
			? {
					firstSeenLessThan: firstseen[0] === FirstSeen.Over7DaysAgo,
					firstSeenComparisonDays: FIRST_SEEN_DEFAULT_TIME_COMPARISON,
			  }
			: {};
	}
}
