import { ApiCall, ApiCallModel } from '@microsoft/paris';
import { IncidentHistoryItem } from './incident-comments-item.entity';
import { WcdPortalParisConfig } from '../paris-config.interface';

@ApiCall({
	name: 'Add comment to incidents',
	endpoint: 'incidents/addComment',
	method: 'POST',
	type: IncidentHistoryItem,
	baseUrl: (config: WcdPortalParisConfig) => config.data.serviceUrls.threatIntel,
	separateArrayParams: true,
	parseQuery: (incidentAddCommentOptions: IncidentsAddCommentApiParameters) => ({
		data: incidentAddCommentOptions,
	}),
})
export class IncidentsAddCommentApiCall extends ApiCallModel<
	IncidentHistoryItem,
	IncidentsAddCommentApiParameters
> {}

export interface IncidentsAddCommentApiParameters {
	Ids: Array<string>;
	Comment: string;
}
