import { EntityField, ModelBase, ValueObject } from '@microsoft/paris';
import { Alert } from '../../alert/alert.entity';
import { LinkedEntityAlert } from './linked-entity-alert.value-object';

@ValueObject({
	singularName: 'Linked alert',
	pluralName: 'Linked alerts',
	readonly: true,
})
export class LinkedAlerts extends ModelBase {
	@EntityField({ data: 'UnexposedAlertsCount' })
	readonly unexposedAlertsCount: number;

	@EntityField({
		data: 'LinkedAlerts',
		arrayOf: Alert,
	})
	readonly linkedAlerts?: Array<Alert>;

	@EntityField({
		data: 'LinkedAlertsAndEntities',
		arrayOf: LinkedEntityAlert
	})
	readonly linkedAlertsAndEntities?: Array<LinkedEntityAlert>;
}
