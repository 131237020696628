import { DataviewField } from '@wcd/dataview';
import { I18nService } from '@wcd/i18n';
import { TvmColorService, TvmColor } from '../../../../tvm/services/tvm-color.service';
import { FabricIconNames } from '@wcd/scc-common';
var AuthenticatedScanResultFieldsService = /** @class */ (function () {
    function AuthenticatedScanResultFieldsService(i18nService, tvmColorService) {
        var _this = this;
        this.i18nService = i18nService;
        this.tvmColorService = tvmColorService;
        this._commonScanResultFields = [
            {
                id: 'ip',
                name: this.i18nService.get('tvm_authenticatedScan_wizard_fields_device_ip'),
                alwaysDisplay: true,
            },
            {
                id: 'scanSuccess',
                name: this.i18nService.get('tvm_authenticatedScan_wizard_fields_status'),
                getDisplay: function (scanResult) {
                    if (scanResult.scanSuccess) {
                        return _this.i18nService.get('tvm_authenticatedScan_wizard_fields_status_success');
                    }
                    return _this.i18nService.get('tvm_authenticatedScan_wizard_fields_status_fail');
                },
                icon: {
                    fabricIcon: FabricIconNames.StatusCircleInner,
                    className: function (scanResult) { return "ms-fontColor-" + (scanResult.scanSuccess ? _this.tvmColorService.colorsMap.get(TvmColor.Green).name : _this.tvmColorService.colorsMap.get(TvmColor.HighRisk).name); },
                },
                alwaysDisplay: true,
            },
            {
                id: 'errorMessage',
                name: this.i18nService.get('tvm_authenticatedScan_wizard_fields_failure_reason'),
                alwaysDisplay: true,
            },
        ];
    }
    Object.defineProperty(AuthenticatedScanResultFieldsService.prototype, "windowsScanResultFields", {
        get: function () {
            if (!this._windowsScanResultFields) {
                // We set always display to all of them as we didn't allow columns customization
                this._windowsScanResultFields = DataviewField.fromList(this._commonScanResultFields);
            }
            return this._windowsScanResultFields;
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(AuthenticatedScanResultFieldsService.prototype, "networkScanResultFields", {
        get: function () {
            var _this = this;
            if (!this._networkScanResultFields) {
                // We set always display to all of them as we didn't allow columns customization
                this._networkScanResultFields = DataviewField.fromList(this._commonScanResultFields.concat([
                    {
                        id: 'hostName',
                        name: this.i18nService.get('tvm_authenticatedScan_wizard_fields_device_name'),
                        getDisplay: function (scanResult) { return scanResult.hostName || _this.i18nService.get('tvm_network_devices_unknown_device_name'); },
                        alwaysDisplay: true,
                    },
                    {
                        id: 'description',
                        name: this.i18nService.get('tvm_authenticatedScan_wizard_fields_device_description'),
                        alwaysDisplay: true,
                    },
                ]));
            }
            return this._networkScanResultFields;
        },
        enumerable: true,
        configurable: true
    });
    return AuthenticatedScanResultFieldsService;
}());
export { AuthenticatedScanResultFieldsService };
