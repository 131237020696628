var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (b.hasOwnProperty(p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
/* tslint:disable:template-accessibility-label-for */
import { OnInit, OnChanges, EventEmitter, AfterViewInit, QueryList } from '@angular/core';
import { CustomActionMachineIsolationType, HuntingRuleCustomSupportedActionType, HuntingRuleEntityType, CustomActionAssetType, EmailActionType, } from '@wcd/domain';
import { HuntingRuleScope } from '../../@entities/scheduled_hunting/models/hunting-rule-scope.model';
import { map } from 'rxjs/operators';
import { I18nService } from '@wcd/i18n';
import { RbacService } from '../../rbac/services/rbac.service';
import { Paris } from '@microsoft/paris';
import { AuthService } from '@wcd/auth';
import { NgForm, AbstractControl, ControlValueAccessor, Validator, ValidationErrors, } from '@angular/forms';
import { lowerFirst, keyBy, groupBy, mapValues } from 'lodash-es';
import { ExpanderComponent } from '@wcd/expander';
//#region View models
var EntitySelection = /** @class */ (function () {
    function EntitySelection(data) {
        Object.assign(this, data);
    }
    Object.defineProperty(EntitySelection.prototype, "selectedId", {
        get: function () {
            if (!this._selectedId &&
                this.selectableIds &&
                this.selectableIds.length === 1 &&
                this.hideSelectionOnSingleOption) {
                this._selectedId = this.selectableIds[0];
            }
            return this._selectedId;
        },
        set: function (value) {
            this._selectedId = value;
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(EntitySelection.prototype, "showSelection", {
        get: function () {
            return !(this.hideSelectionOnSingleOption && this.selectableIds && this.selectableIds.length === 1);
        },
        enumerable: true,
        configurable: true
    });
    return EntitySelection;
}());
var CustomActionSelection = /** @class */ (function () {
    function CustomActionSelection(config) {
        Object.assign(this, config);
    }
    CustomActionSelection.prototype.fromCustomActions = function (actions) {
        var _this = this;
        var action = actions && actions.find(function (a) { return a.actionType === _this.actionType; });
        this.checked = action && !this.disabled;
        if (this.entities) {
            this.entities.forEach(function (entity) {
                var actionEntity = action &&
                    action.entities &&
                    action.entities.find(function (e) { return e.entityType === entity.entityType; });
                entity.selectedId =
                    actionEntity && entity.selectableIds.find(function (id) { return id === actionEntity.entityIdField; });
            });
        }
    };
    CustomActionSelection.prototype.toCustomAction = function () {
        if (this.checked && (this.entities || []).every(function (e) { return e.selectedId != null; })) {
            return {
                actionType: this.actionType,
                entities: this.entities &&
                    this.entities.map(function (entity) { return ({
                        entityType: entity.entityType,
                        entityIdField: entity.selectedId,
                    }); }),
            };
        }
        return null;
    };
    return CustomActionSelection;
}());
var IsolateMachineActionSelection = /** @class */ (function (_super) {
    __extends(IsolateMachineActionSelection, _super);
    function IsolateMachineActionSelection() {
        return _super !== null && _super.apply(this, arguments) || this;
    }
    IsolateMachineActionSelection.prototype.fromCustomActions = function (actions) {
        var _this = this;
        _super.prototype.fromCustomActions.call(this, actions);
        var action = actions && actions.find(function (a) { return a.actionType === _this.actionType; });
        var isolationType = action && action.additionalFields && action.additionalFields.isolationType;
        this.selectedOption =
            (isolationType != null && this.options.find(function (type) { return type.id === isolationType; })) ||
                this.options[0];
    };
    IsolateMachineActionSelection.prototype.toCustomAction = function () {
        if (this.selectedOption != null) {
            var action = _super.prototype.toCustomAction.call(this);
            return (action && __assign({}, action, { additionalFields: {
                    isolationType: this.selectedOption.id,
                } }));
        }
        return null;
    };
    return IsolateMachineActionSelection;
}(CustomActionSelection));
var EmailActionSelection = /** @class */ (function (_super) {
    __extends(EmailActionSelection, _super);
    function EmailActionSelection() {
        return _super !== null && _super.apply(this, arguments) || this;
    }
    EmailActionSelection.prototype.fromCustomActions = function (actions) {
        var _this = this;
        _super.prototype.fromCustomActions.call(this, actions);
        var action = actions && actions.find(function (a) { return a.actionType === _this.actionType; });
        var emailActionType = action && action.additionalFields && action.additionalFields.emailActionType;
        this.selectedOption =
            (emailActionType != null && this.options.find(function (type) { return type.id === emailActionType; })) ||
                this.options[0];
    };
    EmailActionSelection.prototype.toCustomAction = function () {
        if (this.selectedOption != null) {
            var action = _super.prototype.toCustomAction.call(this);
            return (action && __assign({}, action, { additionalFields: {
                    emailActionType: this.selectedOption.id,
                } }));
        }
        return null;
    };
    return EmailActionSelection;
}(CustomActionSelection));
var AllowOrBlockFileActionSelection = /** @class */ (function (_super) {
    __extends(AllowOrBlockFileActionSelection, _super);
    function AllowOrBlockFileActionSelection(config) {
        var _this = _super.call(this, config) || this;
        _this.options = _this.supportedActions.map(function (action) { return ({
            id: action.actionType,
            name: action.name,
        }); });
        _this.disabled = _this.supportedActions[0].disabled;
        _this.entities = _this.supportedActions[0].entities;
        return _this;
    }
    Object.defineProperty(AllowOrBlockFileActionSelection.prototype, "selectedOption", {
        get: function () {
            return this._selectedActionType;
        },
        set: function (value) {
            this._selectedActionType = value;
            this.actionType = value.id;
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(AllowOrBlockFileActionSelection.prototype, "selectedScope", {
        get: function () {
            return this._selectedScope;
        },
        set: function (value) {
            if (value && value.id === HuntingRuleScope.all) {
                this.rbacGroupIds = null;
            }
            this._selectedScope = value;
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(AllowOrBlockFileActionSelection.prototype, "isAllMachinesScopeSelected", {
        get: function () {
            return this.selectedScope && this.selectedScope.id === HuntingRuleScope.all;
        },
        enumerable: true,
        configurable: true
    });
    AllowOrBlockFileActionSelection.prototype.fromCustomActions = function (actions) {
        var ids = this.options.map(function (t) { return t.id; });
        var action = actions && actions.find(function (a) { return ids.includes(a.actionType); });
        this.selectedOption =
            (action && this.options.find(function (_a) {
                var id = _a.id;
                return action.actionType === id;
            })) || this.options[0];
        _super.prototype.fromCustomActions.call(this, actions);
        this.rbacGroupIds = action && action.additionalFields && action.additionalFields.rbacGroupIds;
        this._selectedScope =
            (this.rbacGroupIds &&
                this.rbacGroupIds.length &&
                this.selectableScopes.find(function (scope) { return scope.id === HuntingRuleScope.specific; })) ||
                this.selectableScopes[0];
    };
    AllowOrBlockFileActionSelection.prototype.toCustomAction = function () {
        var customAction = _super.prototype.toCustomAction.call(this);
        return (customAction && __assign({}, customAction, { additionalFields: {
                rbacGroupIds: this.isAllMachinesScopeSelected ? null : this.rbacGroupIds,
            } }));
    };
    return AllowOrBlockFileActionSelection;
}(CustomActionSelection));
var HuntingCustomActionsComponent = /** @class */ (function () {
    function HuntingCustomActionsComponent(paris, i18nService, rbacService, authService) {
        this.paris = paris;
        this.i18nService = i18nService;
        this.rbacService = rbacService;
        this.authService = authService;
        this.allowMultiSelect = true;
        this.anyExpandedChange = new EventEmitter();
        this.CustomActionAssetType = CustomActionAssetType;
    }
    HuntingCustomActionsComponent.prototype.ngOnInit = function () {
        var _this = this;
        this.rbacGroups$ = this.rbacService.userExposedRbacGroups$.pipe(map(function (groups) {
            return (groups || []).map(function (group) { return ({
                id: group.id,
                name: group.isUnassignedMachineGroup
                    ? _this.i18nService.get('machineGroup.unassignedGroup.name')
                    : group.name,
            }); });
        }));
    };
    HuntingCustomActionsComponent.prototype.ngAfterViewInit = function () {
        this.expenders.first.focusExpender();
    };
    HuntingCustomActionsComponent.prototype.ngOnChanges = function () {
        this.initializeCustomActions();
    };
    //#region Angular forms integration: ngModel and validation
    HuntingCustomActionsComponent.prototype.writeValue = function (actions) {
        this.actions = actions;
        this.applySelection(actions);
    };
    HuntingCustomActionsComponent.prototype.registerOnChange = function (fn) {
        this.onActionsChange = fn;
    };
    HuntingCustomActionsComponent.prototype.registerOnTouched = function (fn) { };
    HuntingCustomActionsComponent.prototype.validate = function (control) {
        var valid = this.actionsForm && this.actionsForm.valid;
        return valid ? null : { error: 'invalid custom action selection' };
    };
    //#endregion
    HuntingCustomActionsComponent.prototype.initializeCustomActions = function () {
        var _this = this;
        var allActions = {
            isolateMachine: new IsolateMachineActionSelection({
                actionType: HuntingRuleCustomSupportedActionType.IsolateMachine,
                category: CustomActionAssetType.Machine,
                options: Object.keys(CustomActionMachineIsolationType)
                    .filter(function (key) { return parseInt(key) >= 0; })
                    .map(function (key) { return ({
                    id: +key,
                    name: _this.i18nService.get("hunting.scheduledMonitorSidePane.fields.customActions.machineIsolationTypes." + CustomActionMachineIsolationType[key].toLowerCase()),
                }); }),
            }),
            collectInvestigationPackage: new CustomActionSelection({
                actionType: HuntingRuleCustomSupportedActionType.CollectInvestigationPackage,
                category: CustomActionAssetType.Machine,
            }),
            runAntivirusScan: new CustomActionSelection({
                actionType: HuntingRuleCustomSupportedActionType.RunAntivirusScan,
                category: CustomActionAssetType.Machine,
            }),
            initiateInvestigation: new CustomActionSelection({
                actionType: HuntingRuleCustomSupportedActionType.InitiateInvestigation,
                category: CustomActionAssetType.Machine,
            }),
            restrictExecution: new CustomActionSelection({
                actionType: HuntingRuleCustomSupportedActionType.RestrictExecution,
                category: CustomActionAssetType.Machine,
            }),
            allowFile: new CustomActionSelection({
                actionType: HuntingRuleCustomSupportedActionType.AllowFile,
                category: CustomActionAssetType.File,
            }),
            blockFile: new CustomActionSelection({
                actionType: HuntingRuleCustomSupportedActionType.BlockFile,
                category: CustomActionAssetType.File,
            }),
            quarantineFile: new CustomActionSelection({
                actionType: HuntingRuleCustomSupportedActionType.QuarantineFile,
                category: CustomActionAssetType.File,
            }),
            markUserAsCompromised: new CustomActionSelection({
                actionType: HuntingRuleCustomSupportedActionType.MarkUserAsCompromised,
                category: CustomActionAssetType.User,
            }),
            moveEmailToFolder: new EmailActionSelection({
                actionType: HuntingRuleCustomSupportedActionType.MoveEmailToFolder,
                category: CustomActionAssetType.Email,
                options: [
                    EmailActionType.MoveToJunk,
                    EmailActionType.MoveToInbox,
                    EmailActionType.MoveToDeletedItems,
                ].map(function (key) { return ({
                    id: key,
                    name: _this.i18nService.get("hunting_customActions_emailSubActionTypes_" + lowerFirst(EmailActionType[key])),
                }); }),
                mutuallyExclusiveWith: ['deleteEmail']
            }),
            deleteEmail: new EmailActionSelection({
                actionType: HuntingRuleCustomSupportedActionType.DeleteEmail,
                category: CustomActionAssetType.Email,
                options: [EmailActionType.Delete, EmailActionType.SoftDelete].map(function (key) { return ({
                    id: key,
                    name: _this.i18nService.get("hunting_customActions_emailSubActionTypes_" + lowerFirst(EmailActionType[key])),
                }); }),
                mutuallyExclusiveWith: ['moveEmailToFolder']
            }),
            blockEmailSender: new CustomActionSelection({
                actionType: HuntingRuleCustomSupportedActionType.BlockEmailSender,
                category: CustomActionAssetType.Email,
            }),
            blockEmailUrl: new CustomActionSelection({
                actionType: HuntingRuleCustomSupportedActionType.BlockEmailUrl,
                category: CustomActionAssetType.Url,
            }),
        };
        var supportedActions = this.supportedActions || [];
        var entitiesWithSingleId = [
            HuntingRuleEntityType.Machine,
            HuntingRuleEntityType.MailMessage,
            HuntingRuleEntityType.Mailbox,
            HuntingRuleEntityType.Url,
        ];
        // Only supported actions are shown
        var visibleActions = supportedActions
            .map(function (supportedAction) {
            // Get action ID, based on action type, for finding the relevant action. e.g. BlockFile => blockFile
            var actionId = lowerFirst(HuntingRuleCustomSupportedActionType[supportedAction.actionType]);
            var action = allActions[actionId];
            if (action) {
                action.id = actionId;
                action.disabled = supportedAction.isEnabled === false;
                action.name =
                    action.name ||
                        _this.i18nService.get("hunting.customDetections.actions." + action.actionType);
                action.entities =
                    supportedAction.entities &&
                        supportedAction.entities.map(function (e) {
                            return new EntitySelection({
                                entityType: e.entityType,
                                selectableIds: e.idFields,
                                hideSelectionOnSingleOption: entitiesWithSingleId.includes(e.entityType),
                            });
                        });
                return action;
            }
        })
            .filter(Boolean);
        var actionsMap = keyBy(visibleActions, 'id');
        if (actionsMap.allowFile && actionsMap.blockFile) {
            actionsMap.allowOrBlockFile = new AllowOrBlockFileActionSelection({
                name: this.i18nService.get('hunting.scheduledMonitorSidePane.fields.customActions.allowOrBlock'),
                id: 'allowOrBlockFile',
                category: CustomActionAssetType.File,
                supportedActions: [actionsMap.allowFile, actionsMap.blockFile],
                selectableScopes: this.getSelectableMachineScopes(),
            });
            delete actionsMap.allowFile;
            delete actionsMap.blockFile;
        }
        this.userSelections = actionsMap;
        var actionsByCategory = groupBy(visibleActions, function (action) { return action.category; });
        this.actionCategories = mapValues(actionsByCategory, function (actions) { return ({ isExpanded: false }); });
        this.applySelection(this.actions);
    };
    HuntingCustomActionsComponent.prototype.applySelection = function (selectedActions) {
        var _this = this;
        if (this.userSelections) {
            selectedActions = selectedActions || [];
            if (!this.allowMultiSelect) {
                selectedActions = selectedActions.slice(0, 1);
            }
            Object.values(this.userSelections).forEach(function (action) {
                action.fromCustomActions(selectedActions);
                if (action.mutuallyExclusiveWith &&
                    action.mutuallyExclusiveWith.some(function (exclusiveActionId) { return _this.userSelections[exclusiveActionId].checked; })) {
                    // another mutually exclusive action is selected
                    action.checked = false;
                }
                if (action.checked) {
                    _this.actionCategories[action.category].isExpanded = true;
                }
            });
            this.onCustomActionChange();
        }
    };
    HuntingCustomActionsComponent.prototype.getSelectableMachineScopes = function () {
        var _this = this;
        var scopes = this.authService.currentUser.isMdeAdmin
            ? Object.keys(HuntingRuleScope)
            : Object.keys(HuntingRuleScope).filter(function (scope) { return scope != HuntingRuleScope.all; });
        return scopes.map(function (scope) { return ({
            id: scope,
            name: _this.i18nService.get("hunting.scheduledMonitorSidePane.fields.machineGroups." + scope),
        }); });
    };
    HuntingCustomActionsComponent.prototype.onSelectionChanged = function (action) {
        if (action && action.checked) {
            if (!this.allowMultiSelect) {
                // deselect other selected actions
                Object.values(this.userSelections).forEach(function (a) { return (a.checked = a.actionType === action.actionType); });
            }
            else if (action.mutuallyExclusiveWith) {
                // deselect actions that are mutually exclusive with the selected action
                for (var _i = 0, _a = action.mutuallyExclusiveWith; _i < _a.length; _i++) {
                    var exclusiveActionId = _a[_i];
                    this.userSelections[exclusiveActionId].checked = false;
                }
            }
        }
        this.onCustomActionChange();
    };
    HuntingCustomActionsComponent.prototype.onCustomActionChange = function () {
        var _this = this;
        var selectedActions = Object.values(this.userSelections)
            .filter(function (action) { return action.checked; })
            .map(function (a) { return a.toCustomAction(); })
            .filter(Boolean);
        setTimeout(function () { return _this.onActionsChange(selectedActions); });
    };
    HuntingCustomActionsComponent.prototype.getGroupsDropdownPlaceholder = function (selectedGroupIds) {
        var numSelectedGroups = selectedGroupIds ? selectedGroupIds.length : 0;
        return numSelectedGroups > 0
            ? this.i18nService.get("hunting.scheduledMonitorSidePane.fields.machineGroups.dropdown.placeholderWithInfo." + (numSelectedGroups === 1 ? 'singular' : 'plural'), {
                numGroups: numSelectedGroups,
            })
            : this.i18nService.get('hunting.scheduledMonitorSidePane.fields.machineGroups.dropdown.placeholder');
    };
    HuntingCustomActionsComponent.prototype.onAnyExpandedChange = function (state) {
        this.anyExpandedChange.emit(state);
    };
    return HuntingCustomActionsComponent;
}());
export { HuntingCustomActionsComponent };
