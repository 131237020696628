import { AppConfigService } from '../../app-config/services/app-config.service';
import * as i0 from "@angular/core";
import * as i1 from "../../app-config/services/app-config.service";
var AIRS_CUSTOM_URL_KEY = 'airsUrl';
var THREAT_INTEL_CUSTOM_URL_KEY = 'threatIntelUrl';
var CMS_CUSTOM_URL_KEY = 'cmsUrl';
var ServiceUrlsService = /** @class */ (function () {
    function ServiceUrlsService(appConfigService) {
        this.appConfigService = appConfigService;
    }
    Object.defineProperty(ServiceUrlsService.prototype, "authorization", {
        get: function () {
            return this.appConfigService.serviceUrls.authorization;
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(ServiceUrlsService.prototype, "feedback", {
        get: function () {
            return this.appConfigService.serviceUrls.feedback;
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(ServiceUrlsService.prototype, "management", {
        get: function () {
            return this.appConfigService.serviceUrls.management;
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(ServiceUrlsService.prototype, "settings", {
        get: function () {
            return this.appConfigService.serviceUrls.settings;
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(ServiceUrlsService.prototype, "officeOptIn", {
        get: function () {
            return this.appConfigService.serviceUrls.officeOptIn;
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(ServiceUrlsService.prototype, "connectors", {
        get: function () {
            return this.appConfigService.serviceUrls.connectors;
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(ServiceUrlsService.prototype, "packages", {
        get: function () {
            return this.appConfigService.serviceUrls.packages;
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(ServiceUrlsService.prototype, "deepAnalysis", {
        get: function () {
            return this.appConfigService.serviceUrls.deepAnalysis;
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(ServiceUrlsService.prototype, "cyberData", {
        get: function () {
            return this.appConfigService.serviceUrls.cyberData;
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(ServiceUrlsService.prototype, "serviceHealth", {
        get: function () {
            return this.appConfigService.serviceUrls.serviceHealth;
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(ServiceUrlsService.prototype, "cms", {
        get: function () {
            return localStorage.getItem(CMS_CUSTOM_URL_KEY) || this.appConfigService.serviceUrls.cms;
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(ServiceUrlsService.prototype, "onboarding", {
        get: function () {
            return this.appConfigService.serviceUrls.onboarding;
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(ServiceUrlsService.prototype, "serveronboarding", {
        get: function () {
            return this.appConfigService.serviceUrls.serveronboarding;
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(ServiceUrlsService.prototype, "rbacManagementApi", {
        get: function () {
            return this.appConfigService.serviceUrls.rbacManagementApi;
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(ServiceUrlsService.prototype, "threatIntel", {
        get: function () {
            return (localStorage.getItem(THREAT_INTEL_CUSTOM_URL_KEY) || this.appConfigService.serviceUrls.threatIntel);
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(ServiceUrlsService.prototype, "tvm", {
        get: function () {
            return this.appConfigService.serviceUrls.tvm;
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(ServiceUrlsService.prototype, "userRequests", {
        get: function () {
            return this.appConfigService.serviceUrls.userRequests;
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(ServiceUrlsService.prototype, "automatedIr", {
        get: function () {
            return localStorage.getItem(AIRS_CUSTOM_URL_KEY) || this.appConfigService.serviceUrls.automatedIr;
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(ServiceUrlsService.prototype, "securityAnalytics", {
        get: function () {
            return this.appConfigService.serviceUrls.securityAnalytics;
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(ServiceUrlsService.prototype, "threatAnalytics", {
        get: function () {
            return this.appConfigService.serviceUrls.threatAnalytics;
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(ServiceUrlsService.prototype, "publicApi", {
        get: function () {
            return this.appConfigService.serviceUrls.publicApi;
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(ServiceUrlsService.prototype, "analytics", {
        get: function () {
            return this.appConfigService.serviceUrls.analytics;
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(ServiceUrlsService.prototype, "k8s", {
        get: function () {
            return this.appConfigService.serviceUrls.k8s;
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(ServiceUrlsService.prototype, "suppressionRulesService", {
        get: function () {
            return this.appConfigService.serviceUrls.suppressionRulesService;
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(ServiceUrlsService.prototype, "webThreatProtection", {
        get: function () {
            return this.appConfigService.serviceUrls.webThreatProtection;
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(ServiceUrlsService.prototype, "wdatpApi", {
        get: function () {
            return this.appConfigService.serviceUrls.wdatpApi;
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(ServiceUrlsService.prototype, "wdatpInternalApi", {
        get: function () {
            return this.appConfigService.serviceUrls.wdatpInternalApi;
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(ServiceUrlsService.prototype, "assetsBaseUrl", {
        get: function () {
            return this.appConfigService.serviceUrls.assetsBaseUrl || '';
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(ServiceUrlsService.prototype, "userPreferences", {
        get: function () {
            return this.appConfigService.serviceUrls.userPreferences;
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(ServiceUrlsService.prototype, "incidentQueue", {
        get: function () {
            return this.appConfigService.serviceUrls.incidentQueue;
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(ServiceUrlsService.prototype, "incidents", {
        get: function () {
            return this.appConfigService.serviceUrls.incidents;
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(ServiceUrlsService.prototype, "incidentUpdate", {
        get: function () {
            return this.appConfigService.serviceUrls.incidentUpdate;
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(ServiceUrlsService.prototype, "huntingService", {
        get: function () {
            return this.appConfigService.serviceUrls.huntingService;
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(ServiceUrlsService.prototype, "secureScore", {
        get: function () {
            return this.appConfigService.serviceUrls.secureScore;
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(ServiceUrlsService.prototype, "CustomerSubmissionService", {
        get: function () {
            return this.appConfigService.serviceUrls.CustomerSubmissionService;
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(ServiceUrlsService.prototype, "activeAlertsSummary", {
        get: function () {
            return this.appConfigService.serviceUrls.activeAlertsSummary;
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(ServiceUrlsService.prototype, "cyberProfileService", {
        get: function () {
            return this.appConfigService.serviceUrls.cyberProfileService;
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(ServiceUrlsService.prototype, "MEMAdminCenterUrl", {
        get: function () {
            return this.appConfigService.serviceUrls.MEMAdminCenterUrl;
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(ServiceUrlsService.prototype, "mte", {
        get: function () {
            return this.appConfigService.serviceUrls.mte;
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(ServiceUrlsService.prototype, "alertsLinks", {
        get: function () {
            return this.appConfigService.serviceUrls.alertsLinks;
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(ServiceUrlsService.prototype, "alertsEmailNotifications", {
        get: function () {
            return this.appConfigService.serviceUrls.alertsEmailNotifications;
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(ServiceUrlsService.prototype, "defenderExpertsService", {
        get: function () {
            return this.appConfigService.serviceUrls.defenderExpertsService;
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(ServiceUrlsService.prototype, "incidentMachineSensitivities", {
        get: function () {
            return this.appConfigService.serviceUrls.incidentMachineSensitivities;
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(ServiceUrlsService.prototype, "incidentMachineGroups", {
        get: function () {
            return this.appConfigService.serviceUrls.incidentMachineGroups;
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(ServiceUrlsService.prototype, "files", {
        get: function () {
            return this.appConfigService.serviceUrls.files;
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(ServiceUrlsService.prototype, "filedownloads", {
        get: function () {
            return this.appConfigService.serviceUrls.filedownloads;
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(ServiceUrlsService.prototype, "fileprevalence", {
        get: function () {
            return this.appConfigService.serviceUrls.fileprevalence;
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(ServiceUrlsService.prototype, "urls", {
        get: function () {
            return this.appConfigService.serviceUrls.urls;
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(ServiceUrlsService.prototype, "incidentDevices", {
        get: function () {
            return this.appConfigService.serviceUrls.incidentDevices;
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(ServiceUrlsService.prototype, "incidentUsers", {
        get: function () {
            return this.appConfigService.serviceUrls.incidentUsers;
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(ServiceUrlsService.prototype, "incidentMailboxes", {
        get: function () {
            return this.appConfigService.serviceUrls.incidentMailboxes;
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(ServiceUrlsService.prototype, "incidentApps", {
        get: function () {
            return this.appConfigService.serviceUrls.incidentApps;
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(ServiceUrlsService.prototype, "incidentImpactedEntitiesView", {
        get: function () {
            return this.appConfigService.serviceUrls.incidentImpactedEntitiesView;
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(ServiceUrlsService.prototype, "incidentTags", {
        get: function () {
            return this.appConfigService.serviceUrls.incidentTags;
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(ServiceUrlsService.prototype, "outbreaks", {
        get: function () {
            return this.appConfigService.serviceUrls.outbreaks;
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(ServiceUrlsService.prototype, "userGroups", {
        get: function () {
            return this.appConfigService.serviceUrls.userGroups;
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(ServiceUrlsService.prototype, "sensitivityLabels", {
        get: function () {
            return this.appConfigService.serviceUrls.sensitivityLabels;
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(ServiceUrlsService.prototype, "relatedIncidents", {
        get: function () {
            return this.appConfigService.serviceUrls.relatedIncidents;
        },
        enumerable: true,
        configurable: true
    });
    ServiceUrlsService.ngInjectableDef = i0.ɵɵdefineInjectable({ factory: function ServiceUrlsService_Factory() { return new ServiceUrlsService(i0.ɵɵinject(i1.AppConfigService)); }, token: ServiceUrlsService, providedIn: "root" });
    return ServiceUrlsService;
}());
export { ServiceUrlsService };
