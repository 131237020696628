var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (b.hasOwnProperty(p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
var __metadata = (this && this.__metadata) || function (k, v) {
    if (typeof Reflect === "object" && typeof Reflect.metadata === "function") return Reflect.metadata(k, v);
};
import { EntityField, ModelBase, ValueObject } from '@microsoft/paris';
import { MachineSecurityScanStatusValue } from './machine-security-scan-status-value.enum';
import { MachineSecurityScanAvModeValue } from './machine-security-scan-av-mode-value.enum';
var ɵ0 = function (data) { return data === 'True'; }, ɵ1 = function (data) { return data === 'True'; }, ɵ2 = function (data) { return data === 'True'; };
var MachineSecurityHealthStatus = /** @class */ (function (_super) {
    __extends(MachineSecurityHealthStatus, _super);
    function MachineSecurityHealthStatus() {
        return _super !== null && _super.apply(this, arguments) || this;
    }
    __decorate([
        EntityField(),
        __metadata("design:type", String)
    ], MachineSecurityHealthStatus.prototype, "avMode", void 0);
    __decorate([
        EntityField(),
        __metadata("design:type", Date)
    ], MachineSecurityHealthStatus.prototype, "dataRefreshTimestamp", void 0);
    __decorate([
        EntityField(),
        __metadata("design:type", String)
    ], MachineSecurityHealthStatus.prototype, "avSignatureVersion", void 0);
    __decorate([
        EntityField(),
        __metadata("design:type", Date)
    ], MachineSecurityHealthStatus.prototype, "avSignatureUpdateTime", void 0);
    __decorate([
        EntityField(),
        __metadata("design:type", String)
    ], MachineSecurityHealthStatus.prototype, "avPlatformVersion", void 0);
    __decorate([
        EntityField(),
        __metadata("design:type", Date)
    ], MachineSecurityHealthStatus.prototype, "avPlatformUpdateTime", void 0);
    __decorate([
        EntityField(),
        __metadata("design:type", String)
    ], MachineSecurityHealthStatus.prototype, "avEngineVersion", void 0);
    __decorate([
        EntityField(),
        __metadata("design:type", Date)
    ], MachineSecurityHealthStatus.prototype, "avEngineUpdateTime", void 0);
    __decorate([
        EntityField(),
        __metadata("design:type", String)
    ], MachineSecurityHealthStatus.prototype, "quickScanResult", void 0);
    __decorate([
        EntityField(),
        __metadata("design:type", Date)
    ], MachineSecurityHealthStatus.prototype, "quickScanTime", void 0);
    __decorate([
        EntityField(),
        __metadata("design:type", String)
    ], MachineSecurityHealthStatus.prototype, "fullScanResult", void 0);
    __decorate([
        EntityField(),
        __metadata("design:type", Date)
    ], MachineSecurityHealthStatus.prototype, "fullScanTime", void 0);
    __decorate([
        EntityField({ parse: ɵ0 }),
        __metadata("design:type", Boolean)
    ], MachineSecurityHealthStatus.prototype, "avIsSignatureUpToDate", void 0);
    __decorate([
        EntityField({ parse: ɵ1 }),
        __metadata("design:type", Boolean)
    ], MachineSecurityHealthStatus.prototype, "avIsEngineUpToDate", void 0);
    __decorate([
        EntityField({ parse: ɵ2 }),
        __metadata("design:type", Boolean)
    ], MachineSecurityHealthStatus.prototype, "avIsPlatformUpToDate", void 0);
    MachineSecurityHealthStatus = __decorate([
        ValueObject({
            singularName: 'Machine Security Health Status',
            pluralName: 'Machine Security Health Statuses',
        })
    ], MachineSecurityHealthStatus);
    return MachineSecurityHealthStatus;
}(ModelBase));
export { MachineSecurityHealthStatus };
export { ɵ0, ɵ1, ɵ2 };
