import { DataViewConfig, DataviewField } from '@wcd/dataview';
import { IStore } from '../../data/models/store.interface';
import { DatasetBackendOptions } from '@wcd/dataview';
import { BehaviorSubject, merge, Observable, of, Subscription } from 'rxjs';
import { DataQuery, DataQuerySortDirection, DataSet, Paris, ReadonlyRepository } from '@microsoft/paris';
import { catchError, finalize, map, switchMap, tap, take } from 'rxjs/operators';
import { cloneDeep, compact, escapeRegExp, isEqual, isNil, isObject, keyBy, sortBy } from 'lodash-es';
import { FiltersField, FiltersState, SerializedFilters, FiltersService } from '@wcd/ng-filters';
import { toPromise } from '../../utils/rxjs/utils';
import { AppContextService, FeaturesService } from '@wcd/config';
import { CyberEventsUtilsService } from '../../@entities/cyber_events/services/cyber-events-utils.service';
import {isMachineExportResponse, MachineExportResponse} from "../../@entities/machines/services/machines.service";

const DEFAULT_PAGE_SIZE: number = 30;
export const AVAILABLE_PAGE_SIZES = [30, 50, 100];

export class DataViewModel<TData = any> {
	readonly id: string;
	readonly searchParamName: string;
	readonly allowFilters: boolean;
	readonly allowPaging: boolean;
	readonly dataTableFields: Array<DataviewField<TData>>;
	readonly defaultVisibleFields: Array<DataviewField<TData>>;
	readonly defaultPageSize: number;
	readonly dataSet$: BehaviorSubject<DataSet<TData>> = new BehaviorSubject(null);
	readonly disabledVisibleFieldIds: Set<DataviewFieldId>;
	readonly sortDisabledFieldIds: Set<DataviewFieldId>;
	readonly disabledFilterFieldIds: Set<DataviewFieldId>;
	readonly defaultSortField: DataviewField<TData>;
	readonly error$: BehaviorSubject<any> = new BehaviorSubject(null);
	readonly fixedOptions: { [index: string]: any };
	readonly fixedFilterValues: SerializedFilters;
	readonly page$: BehaviorSubject<number> = new BehaviorSubject(1);
	readonly pageSize$: BehaviorSubject<number> = new BehaviorSubject(DEFAULT_PAGE_SIZE);
	readonly isSearchOnly: boolean = false;
	readonly searchTerm$: BehaviorSubject<string> = new BehaviorSubject(null);
	readonly sortDescending$: BehaviorSubject<boolean> = new BehaviorSubject(false);
	readonly sortField$: BehaviorSubject<DataviewField<TData>> = new BehaviorSubject(null);
	readonly isLoading$: BehaviorSubject<boolean> = new BehaviorSubject(false);
	readonly isLoadingNext$: BehaviorSubject<boolean> = new BehaviorSubject(false);
	readonly isLoadingPrevious$: BehaviorSubject<boolean> = new BehaviorSubject(false);
	readonly visibleFields$: BehaviorSubject<Array<DataviewField<TData>>> = new BehaviorSubject([]);
	readonly sortableFieldIds: Array<string>;
	readonly infiniteScrolling: boolean;
	readonly forceGetDataFromApi: boolean;
	readonly filtersData$: Observable<Record<string, any>>;

	currentSearchTerm: string;
	private _newSearchTerm: string;
	fields: Array<DataviewField<TData>>;
	filterFields: Array<FiltersField>;
	filtersState: FiltersState;
	private _newFiltersState: FiltersState;
	sortDescending: boolean = false;
	error: any;
	filtersError: string;
	lastDataSet: DataSet<TData>;
	freeTextFilter: string;

	readonly changeSettings$ = merge(
		this.page$,
		this.pageSize$,
		this.searchTerm$,
		this.sortDescending$,
		this.sortField$,
		this.visibleFields$
	);

	private _config: DataViewConfig<TData>;
	private _exportResults: (
		options: DatasetBackendOptions,
		format: string,
		query: DataQuery
	) => Promise<any>;
	private _loadResults: (options?: DatasetBackendOptions) => Observable<DataSet<TData>>;
	private _loadNextResults: (nextResultsUrl: string) => Observable<DataSet<TData>>;
	private _loadPreviousResults: (previousResultsUrl: string) => Observable<DataSet<TData>>;
	private _getLoadNextResultsUrl: () => string;
	private _getLoadPreviousResultsUrl: () => string;
	private _visibleFields: Array<DataviewField<TData>>;
	private _page: number = 1;
	private _pageSize: number;
	private _sortField: DataviewField<TData>;
	private _fieldsIndex: Record<DataviewFieldId, DataviewField<TData>>;
	private _loadDataSetSubscription: Subscription;
	private _reloadFilterValues: boolean = false;
	private activeExportRequestOptions: DatasetBackendOptions;

	private readonly setFiltersData$: BehaviorSubject<void> = new BehaviorSubject<void>(null);
	private readonly _originalDataViewConfig: DataViewConfig<TData>;

	private get fieldsIndex(): Record<DataviewFieldId, DataviewField<TData>> {
		if (!this._fieldsIndex) this._fieldsIndex = keyBy(this.fields, 'id');

		return this._fieldsIndex;
	}

	get exportEnabled(): boolean {
		return !!this._exportResults;
	}

	get currentPage(): number {
		return this._page;
	}
	/**
	 * `true` when all data for the dataview is in the front-end, and sorting / search are done locally, without backend fetches.
	 * Requires that `allowPaging` is `false` and `forceGetDataFromApi` is `false` and `infiniteScrolling` isn't `true`.
	 */
	get isLocalData(): boolean {
		return this.allowPaging === false && this.forceGetDataFromApi === false && !this.infiniteScrolling;
	}

	/**
	 * 'true' when all default visible fields are checked.
	 */
	get allDefaultFieldsSelected(): boolean {
		if (this._visibleFields.length === this.defaultVisibleFields.length) {
			const fieldsSetsMap = this.defaultVisibleFields.reduce(
				(result: {}, field: DataviewField<TData>) => ({ ...result, [field.id]: false }),
				{}
			);
			this._visibleFields.forEach((field: DataviewField<TData>) => (fieldsSetsMap[field.id] = true));
			return Object.entries(fieldsSetsMap).reduce(
				(result: boolean, item: [string, boolean]) => (!result ? false : item[1]),
				true
			);
		}
		return false;
	}

	constructor(config: DataViewConfig<TData>, originalConfig?: DataViewConfig<TData>) {
		this._config = config;

		this.searchParamName = config.searchParamName || 'search';
		if (!config.id) throw new Error("Can't create DataView, missing id");

		this.id = config.id;

		if (!config.fields) this.throwMissingProperty('fields');

		this._originalDataViewConfig = originalConfig || this._config;

		this.allowFilters = config.allowFilters !== false;
		this.allowPaging = config.allowPaging !== false;
		this.fields = cloneDeep(config.fields);

		this.defaultPageSize = config.defaultPageSize || DEFAULT_PAGE_SIZE;
		this._pageSize =
			config.pageSize && ~AVAILABLE_PAGE_SIZES.indexOf(config.pageSize)
				? config.pageSize
				: this.defaultPageSize;
		if (this._pageSize !== DEFAULT_PAGE_SIZE) this.pageSize$.next(this._pageSize);

		this.isSearchOnly = config.searchOnly === true;
		this.defaultSortField = this.fields.find((field) => field.id === config.defaultSortFieldId);
		this.setSortField(this.defaultSortField);

		if (config.allowFilters !== false) {
			this.filterFields = this.fields
				.filter((field) => !this.disabledFilterFieldIds || !this.disabledFilterFieldIds.has(field.id))
				.map((field) => ({
					id: field.id,
					name: field.filterName || field.name,
					...field.filter,
				}));

			if (this._config.requireFiltersData !== false) {
				if (!this._config.getFiltersData)
					throw new Error("Can't set filters, 'getFiltersData' is missing from dataview config.");

				this.filtersData$ = this.setFiltersData$.pipe(
					tap(() => (this.filtersError = null)),
					switchMap(() =>
						this._config.getFiltersData({
							...this.fixedFilterValues,
							...(this.filtersState && this.filtersState.serialized),
						})
					),
					catchError((error) => {
						throw new Error((this.filtersError = error.error || error.message || error));
					})
				);
				this.setFiltersData$.next(null);
			} else this.filtersData$ = of({});
		}

		this._loadResults = config.loadResults;
		this._loadNextResults = config.loadNextResults;
		this._loadPreviousResults = config.loadPreviousResults;
		this._getLoadNextResultsUrl = config.getLoadNextResultsUrl;
		this._getLoadPreviousResultsUrl = config.getLoadPreviousResultsUrl;
		this._exportResults = config.exportResults;

		if (config.sortDisabledFieldIds) {
			this.sortDisabledFieldIds = new Set(config.sortDisabledFieldIds);
			this.fields.forEach((field) => {
				if (this.sortDisabledFieldIds.has(field.id)) field.sort.enabled = false;
			});
		}

		if (config.disabledVisibleFieldIds)
			this.disabledVisibleFieldIds = new Set(config.disabledVisibleFieldIds);

		if (this.fields.some((field) => field.filterOnly)) {
			if (!this.disabledVisibleFieldIds) this.disabledVisibleFieldIds = new Set<DataviewFieldId>();
			this.fields
				.filter((field) => field.filterOnly)
				.forEach((field) => {
					this.disabledVisibleFieldIds.add(field.id);
				});
		}

		this.dataTableFields = this.disabledVisibleFieldIds
			? this.fields.filter((field) => !this.disabledVisibleFieldIds.has(field.id))
			: this.fields;

		this.defaultVisibleFields = config.defaultVisibleFieldIds
			? compact(config.defaultVisibleFieldIds.map((fieldId) => this.fieldsIndex[fieldId]))
			: this.fields;
		if (this.disabledVisibleFieldIds)
			this.defaultVisibleFields = this.defaultVisibleFields.filter(
				(field) => !this.disabledVisibleFieldIds.has(field.id)
			);

		this.setVisibleFields(
			config.visibleFields || config.defaultVisibleFieldIds || config.fields.map((field) => field.id)
		);

		if (config.disabledFilterFieldIds) {
			this.disabledFilterFieldIds = new Set(
				compact(config.disabledFilterFieldIds.map((fieldId) => this.fieldsIndex[fieldId])).map(
					(field) => field.id
				)
			);
		}

		this.fixedOptions = config.fixedOptions;
		this.fixedFilterValues = config.fixedFilterValues;
		this.infiniteScrolling = !!config.infiniteScrolling;
		this.forceGetDataFromApi = !!config.forceGetDataFromApi;

		if (this.isLocalData)
			this.sortableFieldIds = this.fields
				.filter(
					(field) =>
						!field.sort || (field.sort.sortLocally !== false && field.sort.enabled !== false)
				)
				.map((field) => field.id);
	}

	private throwMissingProperty(propertyName: string): Error {
		throw new Error(`Can't create DataView ${this.id}, missing ${propertyName}.`);
	}

	destroy() {
		this._loadDataSetSubscription && this._loadDataSetSubscription.unsubscribe();
	}

	/**
	 * Sets the sort field of the DataviewModel. Resets the page to 1. If the specified sortField is the same as the current one, the sortDirection is toggled.
	 * @param sortField
	 * @param sortDescending (optional) Can be explicitly specified to set the direction of the sort.
	 */
	setSortField(
		sortField: DataviewField<TData>,
		sortDescending?: boolean,
		reloadData: boolean = false
	): DataViewModel {
		if (!sortField) {
			return this;
		}

		const isSameField: boolean = sortField && this._sortField && this._sortField.id === sortField.id;

		this._sortField = sortField;
		this.sortDescending = !isNil(sortDescending)
			? sortDescending
			: isSameField
			? !this.sortDescending
			: !isNil(this._sortField.sort && this._sortField.sort.sortDescendingByDefault)
			? this._sortField.sort.sortDescendingByDefault
			: false;

		if (!isSameField) {
			this.sortField$.next(this._sortField);
		}

		this.sortDescending$.next(this.sortDescending);

		if (this._page !== 1) this.page$.next((this._page = 1));

		if (this.isLocalData) {
			if (this.dataSet$.value) {
				this.dataSet$.next({
					...this.dataSet$.value,
					items: this.sortItems(this.dataSet$.value.items, this._sortField, this.sortDescending),
				});
			}
		} else if (reloadData) this.reloadData();

		return this;
	}

	/**
	 * Sets the data locally, when `isLocalData` is `true` (and data shouldn't be reloaded from backend).
	 */
	private setLocalData() {
		if (!this.lastDataSet) return;

		let results: Array<TData> = this.localSearchResults(this.lastDataSet.items, this.freeTextFilter);
		results = this.sortItems(results, this._sortField, this.sortDescending);

		this.dataSet$.next({
			...this.dataSet$.value,
			items: results,
		});
	}

	private localSearchResults(items: Array<TData>, searchTerm: string): Array<TData> {
		if (this._config.freeTextFilter) {
			return items.filter((item) => this._config.freeTextFilter(searchTerm, item));
		} else {
			const termRegExp = new RegExp(escapeRegExp(searchTerm), 'i');

			return items.filter((item) => {
				return this.fields.some((field) => termRegExp.test(field.display(item)));
			});
		}
	}

	/**
	 * This is the local sorting method, for when isLocalData === true.
	 * @param {DataviewField<TData>} sortField
	 * @param {boolean} sortDescending
	 */
	private sortItems(
		items: Array<TData>,
		sortField: DataviewField<TData>,
		sortDescending: boolean
	): Array<TData> {
		if (!items) {
			return;
		}

		const sortCompareFunction =
			sortField.sort.getLocalSortValue ||
			((value: TData) => sortField.display(value).toString().toLowerCase());

		const sortedResults = sortField.sort.sortCompareFunction
			? items.sort(sortField.sort.sortCompareFunction)
			: sortBy<TData>(items, sortCompareFunction);

		if (sortDescending) {
			return sortedResults.reverse();
		}

		return sortedResults;
	}

	/**
	 * Sets the fields that should be visible in the dataview
	 * @param fieldIds The Ids of the fields to display
	 * @returns {boolean} true if the visible fields changed as a result of the setVisibleFields request, false otherwise
	 */
	setVisibleFields(fieldIds: Array<DataviewFieldId>): boolean {
		if (!fieldIds) throw new Error("Can't set visible fields, fieldsIds weren't specified.");

		if (this.disabledVisibleFieldIds)
			fieldIds = fieldIds.filter((fieldId) => !this.disabledVisibleFieldIds.has(fieldId));

		const fieldIdsSet: Set<DataviewFieldId> = new Set(fieldIds);
		const fields: Array<DataviewField<TData>> = this.fields.filter(
			(field) => field.alwaysDisplay || fieldIdsSet.has(field.id)
		);
		let fieldsChanged: boolean;

		if (!this._visibleFields) fieldsChanged = true;
		else {
			fieldsChanged = fields.length !== this._visibleFields.length;
			if (!fieldsChanged) {
				const fieldIds: Array<DataviewFieldId> = fields.map((field) => field.id).sort(),
					currentVisibleFieldIds: Array<DataviewFieldId> = this._visibleFields
						.map((field) => field.id)
						.sort();

				fieldsChanged = !isEqual(fieldIds, currentVisibleFieldIds);
			}
		}
		if (fieldsChanged) this.visibleFields$.next((this._visibleFields = fields));

		return fieldsChanged;
	}

	/**
	 * Sets the visible fields to the default fields
	 */
	resetVisibleFields() {
		this.visibleFields$.next((this._visibleFields = this.defaultVisibleFields));
	}

	/**
	 * Sets the current page
	 * @param page {Number} positive integer
	 */
	setPage(page: number, updateData: boolean = true): boolean {
		if (isNaN(page) || page < 1)
			throw new TypeError('Invalid page. Expected a positive integer, but got ' + page + '.');

		if (page !== this._page) {
			this.page$.next((this._page = page));

			if (updateData) this.reloadData();

			return true;
		}

		return false;
	}

	/**
	 * Sets the current page size of the DataViewModel
	 * @param pageSize {Number} positive integer
	 */
	setPageSize(pageSize: number, updateData: boolean = true): boolean {
		if (isNaN(pageSize) || pageSize < 1)
			throw new TypeError('Invalid pageSize. Expected a positive integer, but got ' + pageSize + '.');

		if (pageSize !== this._pageSize) {
			this.page$.next((this._page = 1));
			this.pageSize$.next((this._pageSize = pageSize));

			if (updateData) this.reloadData();

			return true;
		}

		return false;
	}

	/**
	 * Sets the filters of the DataOptions and resets the page to 1.
	 * @param filters {FiltersModel}
	 * @returns {DataViewModel}
	 */
	setFilters(filtersState?: FiltersState): DataViewModel<TData> {
		if (this._page !== 1) this.page$.next((this._page = 1));
		this._newFiltersState = filtersState;
		this._reloadFilterValues = true;

		return this;
	}

	setSearchTerm(
		searchTerm: string,
		updateData: boolean = false,
		setCurrentSearchTerm: boolean = false
	): boolean {
		if (searchTerm === this.currentSearchTerm || (isNil(searchTerm) && isNil(this.currentSearchTerm)))
			return false;

		if (this.isLocalData) {
			this.setFreeTextFilter(searchTerm);
			return false;
		} else {
			let nextSearchTerm: string;

			if (setCurrentSearchTerm) nextSearchTerm = this.currentSearchTerm = searchTerm;
			else nextSearchTerm = this._newSearchTerm = searchTerm;

			this.searchTerm$.next(nextSearchTerm);

			if (updateData) {
				this.setFilters();
			}
		}

		return true;
	}

	setFreeTextFilter(searchTerm: string) {
		this.freeTextFilter = searchTerm;
		this.setLocalData();
	}

	async reloadData(): Promise<void> {
		this._loadDataSetSubscription && this._loadDataSetSubscription.unsubscribe();

		const options: DatasetBackendOptions = await this.getDataSetOptions();

		if (this.isSearchOnly && !options.search) {
			this.dataSet$.next(null);
			return;
		}

		this.isLoading$.next(true);
		this.error$.next(null);

		this._loadDataSetSubscription = this._loadResults(options)
			.pipe(
				tap(() => {
					this.isLoading$.next(false);
					this._loadDataSetSubscription = null;

					if (this._reloadFilterValues) {
						this._reloadFilterValues = false;
						if (this.allowFilters) this.setFiltersData$.next(null);
					}
				})
			)
			.subscribe(
				(dataSet: DataSet<TData>) => {
					this.dataSet$.next((this.lastDataSet = dataSet));
				},
				(error) => this.error$.next(error)
			);
	}

	/*
	 * Loads "next" batch of data. When user scrolls down to end of table, we get more data and concatenate it to bottom of table.
	 */
	loadNextData() {
		const url = this._getLoadNextResultsUrl();
		if (!url) {
			return;
		}

		this.isLoadingNext$.next(true);
		return this._loadNextResults(url)
			.pipe(
				finalize(() => this.isLoadingNext$.next(false)),
				map((dataSet: DataSet<TData>) => {
					this.dataSet$.next({
						...dataSet,
						items: [...this.dataSet$.value.items, ...dataSet.items],
					});
				}),
				take(1)
			)
			.toPromise();
	}

	/*
	 * Loads "previous" batch of data. When user clicks "Load newer data", we get more data and concatenate it to top of table.
	 */
	loadPreviousData() {
		const url = this._getLoadPreviousResultsUrl();
		if (url) {
			this.isLoadingPrevious$.next(true);
			return this._loadPreviousResults(url)
				.pipe(
					finalize(() => this.isLoadingPrevious$.next(false)),
					map((dataSet: DataSet<TData>) => {
						this.dataSet$.next({
							...dataSet,
							items: [...dataSet.items, ...this.dataSet$.value.items],
						});
					}),
					take(1)
				)
				.toPromise();
		} else return Promise.reject();
	}

	async exportData(format: string, maxRowCount?: number, optionsToOverride?: DatasetBackendOptions): Promise<any> {
		if (!this._exportResults) {
			return;
		}

		let options;
		if (this._originalDataViewConfig.supportPartialResponse && this.activeExportRequestOptions && optionsToOverride) {
			options = {
				...this.activeExportRequestOptions,
				...optionsToOverride
			}
		} else {
			options = Object.assign(await this.getDataSetOptions(), {
				page: 1,
				page_size: maxRowCount
			});
		}

		if (this._originalDataViewConfig.supportPartialResponse) {
			this.activeExportRequestOptions = options;
		}

		return this._exportResults(
			options,
			format,
			DataViewModel.getDataQueryFromOptions(this._originalDataViewConfig, options)
		).then(
			(response: string | MachineExportResponse) => {
				if (!isMachineExportResponse(response) || !response.isPartial) {
					this.activeExportRequestOptions = null;
				}

				return response;
			},
			(err) => {
				this.activeExportRequestOptions = null;
				throw err;
			});
	}

	setOptions(options: DatasetBackendOptions, allowReloadData: boolean = true): boolean {
		let reloadData: boolean = false;

		reloadData = reloadData || this.setPage(options.page || 1, false);
		reloadData = reloadData || this.setPageSize(options.page_size || this.defaultPageSize, false);

		let visibleFieldIds: Array<DataviewFieldId> = options.fields ? options.fields.split(',') : null;
		if (!visibleFieldIds) {
			const visibleFields: Array<DataviewField<TData>> = this.visibleFields$.value.length
				? this.visibleFields$.value
				: this.defaultVisibleFields;
			visibleFieldIds = visibleFields.map((field) => field.id);
		}

		this.setVisibleFields(visibleFieldIds);

		const fieldAndDirection = options.ordering && options.ordering.match(/^(-?)(.+)$/);
		if (fieldAndDirection) {
			const sortField = this.fieldsIndex[fieldAndDirection[2]];
			let sortDescending = !!fieldAndDirection[1];

			if (sortField && sortField.sort && sortField.sort.flipSortDirection)
				sortDescending = !sortDescending;

			if (sortField && (sortField !== this._sortField || sortDescending !== this.sortDescending)) {
				this.setSortField(sortField, sortDescending);
				reloadData = true;
			}
		} else if (
			this.defaultSortField &&
			(this._sortField !== this.defaultSortField ||
				this.sortDescending !==
					(this.defaultSortField.sort && this.defaultSortField.sort.sortDescendingByDefault))
		) {
			this.setSortField(
				this.defaultSortField,
				this.defaultSortField.sort && this.defaultSortField.sort.sortDescendingByDefault
			);
			reloadData = true;
		}

		if (this.allowFilters) {
			const serializedFilters: SerializedFilters = FiltersService.filtersQueryParamToSerializedFilters(
				options.filters
			);
			if (
				(serializedFilters && !this.filtersState) ||
				(this.filtersState && !isEqual(serializedFilters, this.filtersState.serialized))
			) {
				reloadData = true;
				this.filtersState = { selection: {}, serialized: serializedFilters };
			}
		}

		if (!this.isLocalData) {
			const searchChanged = this.setSearchTerm(options[this.searchParamName], false, true);
			reloadData = reloadData || searchChanged;
		}

		if (reloadData && allowReloadData) {
			this.reloadData();
		}

		return reloadData;
	}

	setFields(fields: Array<DataviewField<TData>>): void {
		this._fieldsIndex = null;

		if (fields && fields.length) {
			this.fields = fields;
			this.setSortField(this.defaultSortField || this.fields[0]);
			this.setVisibleFields(fields.map((field) => field.id));
		}
	}

	async getDataSetOptions(): Promise<DatasetBackendOptions> {
		let options: DatasetBackendOptions = {};

		if (!this.isLocalData) {
			if (this._page > 1) options.page = this._page;

			options.page_size = this._pageSize;
		}

		if (this._sortField)
			options.ordering =
				((
					this._sortField.sort && this._sortField.sort.flipSortDirection
						? !this.sortDescending
						: this.sortDescending
				)
					? '-'
					: '') + this._sortField.id;

		if (this.filtersState) {
			const filterQueryOptions = await this.getFilterQueryOptions(this.filtersState.serialized);
			Object.assign(options, filterQueryOptions);
		}

		if (this.currentSearchTerm) options[this.searchParamName] = this.currentSearchTerm;

		if (this.fixedOptions) options = DataViewModel.mergeOptions(options, this.fixedOptions);

		delete options.filters; // Cleaning up, this shouldn't be set, the individual filters should be in the options object.

		return options;
	}

	/**
	 * Once the options have been changed by the user, they are more explicit and include
	 * options that might coincidentally be default. This method returns those options
	 * as well.
	 */
	getNonDefaultDataSetOptions(): DatasetBackendOptions {
		const visibleFieldIds: Array<DataviewFieldId> = this._visibleFields.map((field) => field.id);
		const filtersState: FiltersState = this._newFiltersState || this.filtersState;
		const searchTerm: string =
			(isNil(this._newSearchTerm) ? this.currentSearchTerm : this._newSearchTerm) || null;

		// newFiltersState is single-use.
		this._newFiltersState = null;
		this._newSearchTerm = null;

		return {
			page: !this.isLocalData && this._page > 1 ? this._page : null,
			page_size: !this.isLocalData ? this._pageSize : null,
			filters: FiltersService.getFiltersQueryParam(filtersState && filtersState.serialized),
			[this.searchParamName]: searchTerm,
			ordering: this.isLocalData
				? null
				: this._sortField
				? `${
						(
							this._sortField.sort && this._sortField.sort.flipSortDirection
								? !this.sortDescending
								: this.sortDescending
						)
							? '-'
							: ''
				  }${this._sortField.id}`
				: null,
			fields: visibleFieldIds.join(','),
		};
	}

	private getFilterQueryOptions(serializedFilters: SerializedFilters): PromiseLike<SerializedFilters> {
		if (this._config.getFilterQueryOptions) {
			const filterQueryOptions = this._config.getFilterQueryOptions(serializedFilters);
			return toPromise(filterQueryOptions);
		}

		return Promise.resolve(serializedFilters);
	}

	private static mergeOptions(...args: Array<{ [index: string]: any }>): { [index: string]: any } {
		const mergedOptions: { [index: string]: any } = {};

		args.forEach(function (options: { [index: string]: any }) {
			if (options && isObject(options)) {
				for (const optionName in options) {
					if (!mergedOptions[optionName] || optionName !== 'filters')
						mergedOptions[optionName] = options[optionName];
				}
			}
		});

		return mergedOptions;
	}

	static fromStore<TData = any>(store: IStore, options?: DataViewConfig): DataViewModel<TData> {
		options = options || {};

		const fields: Array<DataviewField<TData>> =
			(store.options.dataViewOptions && store.options.dataViewOptions.fields) || options.fields;

		if (!fields) throw new Error("Can't create DataViewModel from store, missing fields.");

		const storeDataViewConfig: DataViewConfig<TData> = {
			id: DataViewModel.getDataViewId(store, null, options),
			allowPaging: options.allowPaging,
			allowFilters: options.allowFilters,
			defaultSortFieldId:
				(options && options.defaultSortFieldId) ||
				(store.options.dataViewOptions && store.options.dataViewOptions.sortField),
			defaultVisibleFieldIds:
				(store.options.dataViewOptions && store.options.dataViewOptions.visibleFields) ||
				(options && options.visibleFields),
			disabledVisibleFieldIds: options.disabledVisibleFieldIds,
			disabledFilterFieldIds: options.disabledFilterFieldIds,
			fields: fields,
			exportResults:
				store.options.dataViewOptions && store.options.dataViewOptions.exportEnabled
					? store.exportAllResults.bind(store)
					: null,
			loadResults: store.getItemsDataSet.bind(store),
			fixedFilterValues: options.fixedOptions,
			fixedOptions: options.fixedOptions,
			pageSize: options.pageSize,
			visibleFields: options.visibleFields,
		};

		return new DataViewModel(storeDataViewConfig, options);
	}

	static fromRepository<TData>(
		repository: ReadonlyRepository<TData>,
		dataViewConfig: DataViewConfig,
		paris: Paris,
		featuresService: FeaturesService,
		appContext: AppContextService
	): DataViewModel<TData> {
		const repositoryDataViewConfig: DataViewConfig<TData> = Object.assign(
			{
				id: DataViewModel.getDataViewId(null, repository, dataViewConfig),
				loadResults: dataViewConfig.data
					? () => of({ items: dataViewConfig.data })
					: (options: DatasetBackendOptions) => {
							const queryOptions = this.getDataQueryFromOptions(dataViewConfig, options);

							return repository.query(queryOptions);
					  },
				defaultVisibleFieldIds: dataViewConfig.fields
					.filter((field) => field.enabledByDefault !== false)
					.map((field) => field.id),
				loadNextResults: dataViewConfig.infiniteScrolling
					? (url: string) =>
							paris.callQuery(
								repository.entityConstructor,
								{
									endpoint: url,
									baseUrl: repository.entityConstructor.entityConfig.baseUrl,
									allItemsEndpointTrailingSlash: false,
								},
								CyberEventsUtilsService.shouldTimelineUseOneCyber(featuresService)
									? {
											where: {
												...CyberEventsUtilsService.getTimelineFlagParams(
													featuresService,
													appContext
												),
											},
									  }
									: null
							)
					: null,
				loadPreviousResults: dataViewConfig.loadItemsOnTableTop
					? (url: string) =>
							paris.callQuery(
								repository.entityConstructor,
								{
									endpoint: url,
									baseUrl: repository.entityConstructor.entityConfig.baseUrl,
									allItemsEndpointTrailingSlash: false,
								},
								CyberEventsUtilsService.shouldTimelineUseOneCyber(featuresService)
									? {
											where: {
												...CyberEventsUtilsService.getTimelineFlagParams(
													featuresService,
													appContext
												),
											},
									  }
									: null
							)
					: null,
			},
			dataViewConfig
		);

		return new DataViewModel(repositoryDataViewConfig, dataViewConfig);
	}

	static getDataQueryFromOptions(
		dataViewConfig: DataViewConfig,
		options: DatasetBackendOptions
	): DataQuery {
		const pagingOptions: Pick<DataQuery, 'page' | 'pageSize'> =
			dataViewConfig !== false
				? {
						page: options.page,
						pageSize: options.page_size,
				  }
				: {};

		const queryOptions: DataQuery = Object.assign(
			{
				where: Object.assign({}, options, dataViewConfig && dataViewConfig.fixedOptions),
			},
			pagingOptions
		);

		if (options.ordering) {
			const sortMatch: RegExpMatchArray = options.ordering.match(/^(-)?(.+)$/);
			queryOptions.sortBy = [
				{
					field: sortMatch[2],
					direction: sortMatch[1]
						? DataQuerySortDirection.descending
						: DataQuerySortDirection.ascending,
				},
			];
		}
		return queryOptions;
	}

	static getDataViewId<TData = any>(
		store?: IStore,
		repository?: ReadonlyRepository<TData>,
		options?: DataViewConfig
	): string {
		if (!store && !repository && !options) return 'DEFAULT__dataview';

		return (
			(options && options.id) ||
			`${store ? store.options.itemNamePlural : repository.modelConfig.pluralName}_dataview`
		);
	}
}

export type DataviewFieldId = string;
