/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "./incident.alerts-list.component.scss.shim.ngstyle";
import * as i1 from "@angular/core";
import * as i2 from "@angular/common";
import * as i3 from "../../../../../../../../projects/localization/src/lib/pipes/tz-date.pipe";
import * as i4 from "../../../../../../../../projects/localization/src/lib/services/tz-date.service";
import * as i5 from "../../../../../../../../projects/localization/src/lib/services/locale-config.service";
import * as i6 from "@angular/router";
import * as i7 from "../../../../../../../../node_modules/@angular-react/fabric/angular-react-fabric.ngfactory";
import * as i8 from "@angular-react/fabric";
import * as i9 from "../../../../../../../../projects/i18n/src/lib/pipes/i18n.pipe";
import * as i10 from "../../../../../../../../projects/i18n/src/lib/services/i18n.service";
import * as i11 from "./incident.alerts-list.component";
import * as i12 from "@microsoft/paris/dist/lib/paris";
import * as i13 from "../../../machines/services/machines.service";
import * as i14 from "../../../../global_entities/services/activated-entity.service";
import * as i15 from "../../../../global_entities/services/entity-panels.service";
var styles_IncidentAlertsListComponent = [i0.styles];
var RenderType_IncidentAlertsListComponent = i1.ɵcrt({ encapsulation: 0, styles: styles_IncidentAlertsListComponent, data: {} });
export { RenderType_IncidentAlertsListComponent as RenderType_IncidentAlertsListComponent };
function View_IncidentAlertsListComponent_1(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 4, "div", [["style", "width: 388px"]], null, null, null, null, null)), (_l()(), i1.ɵted(-1, null, ["\n\tAlerts -\n\t"])), (_l()(), i1.ɵeld(2, 0, null, null, 1, "a", [], null, [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.toggleAlerts($event) !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), (_l()(), i1.ɵted(3, null, ["\n\t\t", "/", "\n\t"])), (_l()(), i1.ɵted(-1, null, ["\n"]))], null, function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.incident.alertCountForResource; var currVal_1 = _co.incident.alertCount; _ck(_v, 3, 0, currVal_0, currVal_1); }); }
function View_IncidentAlertsListComponent_2(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵted(0, null, ["\n\t", "\n"]))], null, function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.incident.alertCount; _ck(_v, 0, 0, currVal_0); }); }
function View_IncidentAlertsListComponent_5(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 25, "li", [], null, [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.showAlert(_v.context.$implicit, $event) !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), (_l()(), i1.ɵted(-1, null, ["\n\t\t\t\t\t"])), (_l()(), i1.ɵeld(2, 0, null, null, 4, "span", [["class", "wcd-severity"]], null, null, null, null, null)), i1.ɵprd(512, null, i2.ɵNgClassImpl, i2.ɵNgClassR2Impl, [i1.IterableDiffers, i1.KeyValueDiffers, i1.ElementRef, i1.Renderer2]), i1.ɵdid(4, 278528, null, 0, i2.NgClass, [i2.ɵNgClassImpl], { klass: [0, "klass"], ngClass: [1, "ngClass"] }, null), (_l()(), i1.ɵted(5, null, ["\n\t\t\t\t\t\t", "\n\t\t\t\t\t"])), i1.ɵppd(6, 1), (_l()(), i1.ɵted(-1, null, ["\n\t\t\t\t\t"])), (_l()(), i1.ɵeld(8, 0, null, null, 4, "div", [], null, null, null, null, null)), (_l()(), i1.ɵted(-1, null, ["\n\t\t\t\t\t\t"])), (_l()(), i1.ɵeld(10, 0, null, null, 1, "strong", [], null, null, null, null, null)), (_l()(), i1.ɵted(11, null, ["\n\t\t\t\t\t\t\t", "\n\t\t\t\t\t\t"])), (_l()(), i1.ɵted(-1, null, ["\n\t\t\t\t\t"])), (_l()(), i1.ɵted(-1, null, ["\n\t\t\t\t\t"])), (_l()(), i1.ɵeld(14, 0, null, null, 10, "div", [], null, null, null, null, null)), (_l()(), i1.ɵted(15, null, ["\n\t\t\t\t\t\tFirst seen: ", "\n\t\t\t\t\t\t"])), i1.ɵpid(131072, i3.TzDatePipe, [i4.TzDateService, i5.LocaleConfigService, i1.ChangeDetectorRef]), (_l()(), i1.ɵeld(17, 0, null, null, 6, "a", [["class", "wcd-margin-left"]], [[1, "target", 0], [8, "href", 4]], [[null, "click"]], function (_v, en, $event) { var ad = true; if (("click" === en)) {
        var pd_0 = (i1.ɵnov(_v, 18).onClick($event.button, $event.ctrlKey, $event.metaKey, $event.shiftKey) !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), i1.ɵdid(18, 671744, null, 0, i6.RouterLinkWithHref, [i6.Router, i6.ActivatedRoute, i2.LocationStrategy], { queryParams: [0, "queryParams"], routerLink: [1, "routerLink"] }, null), i1.ɵpad(19, 3), (_l()(), i1.ɵted(-1, null, ["\n\t\t\t\t\t\t\t"])), (_l()(), i1.ɵeld(21, 0, null, null, 1, "fab-icon", [["iconName", "TimelineProgress"]], null, null, null, i7.View_FabIconComponent_0, i7.RenderType_FabIconComponent)), i1.ɵdid(22, 5816320, null, 0, i8.FabIconComponent, [i1.ElementRef, i1.ChangeDetectorRef, i1.Renderer2], { iconName: [0, "iconName"] }, null), (_l()(), i1.ɵted(-1, null, ["\n\t\t\t\t\t\t\tSee in timeline\n\t\t\t\t\t\t"])), (_l()(), i1.ɵted(-1, null, ["\n\t\t\t\t\t"])), (_l()(), i1.ɵted(-1, null, ["\n\t\t\t\t"]))], function (_ck, _v) { var _co = _v.component; var currVal_0 = "wcd-severity"; var currVal_1 = ("wcd-severity-" + _v.context.$implicit.severity.type); _ck(_v, 4, 0, currVal_0, currVal_1); var currVal_7 = _co.machinesService.getMachineWithAlertParams(_v.context.$implicit); var currVal_8 = _ck(_v, 19, 0, "/machines", _v.context.$implicit.machine.machineId, "timeline"); _ck(_v, 18, 0, currVal_7, currVal_8); var currVal_9 = "TimelineProgress"; _ck(_v, 22, 0, currVal_9); }, function (_ck, _v) { var currVal_2 = i1.ɵunv(_v, 5, 0, _ck(_v, 6, 0, i1.ɵnov(_v.parent.parent.parent, 0), _v.context.$implicit.severity.nameI18nKey)); _ck(_v, 5, 0, currVal_2); var currVal_3 = _v.context.$implicit.name; _ck(_v, 11, 0, currVal_3); var currVal_4 = i1.ɵunv(_v, 15, 0, i1.ɵnov(_v, 16).transform(_v.context.$implicit.firstSeen)); _ck(_v, 15, 0, currVal_4); var currVal_5 = i1.ɵnov(_v, 18).target; var currVal_6 = i1.ɵnov(_v, 18).href; _ck(_v, 17, 0, currVal_5, currVal_6); }); }
function View_IncidentAlertsListComponent_4(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 16, null, null, null, null, null, null, null)), (_l()(), i1.ɵted(-1, null, ["\n\t\t"])), (_l()(), i1.ɵeld(2, 0, null, null, 13, "div", [["class", "alerts-list wcd-scroll-vertical wcd-padding-small-right"]], null, null, null, null, null)), (_l()(), i1.ɵted(-1, null, ["\n\t\t\t"])), (_l()(), i1.ɵeld(4, 0, null, null, 4, "ul", [["class", "unstyled"]], null, null, null, null, null)), (_l()(), i1.ɵted(-1, null, ["\n\t\t\t\t"])), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_IncidentAlertsListComponent_5)), i1.ɵdid(7, 278528, null, 0, i2.NgForOf, [i1.ViewContainerRef, i1.TemplateRef, i1.IterableDiffers], { ngForOf: [0, "ngForOf"] }, null), (_l()(), i1.ɵted(-1, null, ["\n\t\t\t"])), (_l()(), i1.ɵted(-1, null, ["\n\t\t\t"])), (_l()(), i1.ɵeld(10, 0, null, null, 4, "a", [], null, [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.toggleAlerts($event) !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), (_l()(), i1.ɵted(-1, null, ["\n\t\t\t\t"])), (_l()(), i1.ɵeld(12, 0, null, null, 1, "fab-icon", [["iconName", "CaretUp"]], null, null, null, i7.View_FabIconComponent_0, i7.RenderType_FabIconComponent)), i1.ɵdid(13, 5816320, null, 0, i8.FabIconComponent, [i1.ElementRef, i1.ChangeDetectorRef, i1.Renderer2], { iconName: [0, "iconName"] }, null), (_l()(), i1.ɵted(-1, null, ["\n\t\t\t\tHide alerts\n\t\t\t"])), (_l()(), i1.ɵted(-1, null, ["\n\t\t"])), (_l()(), i1.ɵted(-1, null, ["\n\t"]))], function (_ck, _v) { var currVal_0 = _v.context.ngIf.items; _ck(_v, 7, 0, currVal_0); var currVal_1 = "CaretUp"; _ck(_v, 13, 0, currVal_1); }, null); }
function View_IncidentAlertsListComponent_6(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵted(-1, null, ["\n\t\t"])), (_l()(), i1.ɵeld(1, 0, null, null, 5, "div", [["class", "wcd-padding-small-all"]], null, null, null, null, null)), (_l()(), i1.ɵted(-1, null, ["\n\t\t\t"])), (_l()(), i1.ɵeld(3, 0, null, null, 2, "fab-spinner", [["labelPosition", "right"]], null, null, null, i7.View_FabSpinnerComponent_0, i7.RenderType_FabSpinnerComponent)), i1.ɵdid(4, 5816320, null, 0, i8.FabSpinnerComponent, [i1.ElementRef, i1.ChangeDetectorRef, i1.Renderer2], { size: [0, "size"], label: [1, "label"], labelPosition: [2, "labelPosition"] }, null), i1.ɵppd(5, 1), (_l()(), i1.ɵted(-1, null, ["\n\t\t"])), (_l()(), i1.ɵted(-1, null, ["\n\t"]))], function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.SpinnerSize.xSmall; var currVal_1 = i1.ɵunv(_v, 4, 1, _ck(_v, 5, 0, i1.ɵnov(_v.parent.parent, 0), "loading")); var currVal_2 = "right"; _ck(_v, 4, 0, currVal_0, currVal_1, currVal_2); }, null); }
function View_IncidentAlertsListComponent_3(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 7, "div", [], null, null, null, null, null)), (_l()(), i1.ɵted(-1, null, ["\n\t"])), (_l()(), i1.ɵand(16777216, null, null, 2, null, View_IncidentAlertsListComponent_4)), i1.ɵdid(3, 16384, null, 0, i2.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"], ngIfElse: [1, "ngIfElse"] }, null), i1.ɵpid(131072, i2.AsyncPipe, [i1.ChangeDetectorRef]), (_l()(), i1.ɵted(-1, null, ["\n\t"])), (_l()(), i1.ɵand(0, [["loading", 2]], null, 0, null, View_IncidentAlertsListComponent_6)), (_l()(), i1.ɵted(-1, null, ["\n"]))], function (_ck, _v) { var _co = _v.component; var currVal_0 = i1.ɵunv(_v, 3, 0, i1.ɵnov(_v, 4).transform(_co.alerts$)); var currVal_1 = i1.ɵnov(_v, 6); _ck(_v, 3, 0, currVal_0, currVal_1); }, null); }
export function View_IncidentAlertsListComponent_0(_l) { return i1.ɵvid(2, [i1.ɵpid(0, i9.I18nPipe, [i10.I18nService]), (_l()(), i1.ɵted(-1, null, ["\n"])), (_l()(), i1.ɵand(16777216, null, null, 2, null, View_IncidentAlertsListComponent_1)), i1.ɵdid(3, 16384, null, 0, i2.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"], ngIfElse: [1, "ngIfElse"] }, null), i1.ɵpid(131072, i2.AsyncPipe, [i1.ChangeDetectorRef]), (_l()(), i1.ɵted(-1, null, ["\n"])), (_l()(), i1.ɵand(0, [["alertCount", 2]], null, 0, null, View_IncidentAlertsListComponent_2)), (_l()(), i1.ɵted(-1, null, ["\n"])), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_IncidentAlertsListComponent_3)), i1.ɵdid(9, 16384, null, 0, i2.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i1.ɵted(-1, null, ["\n"]))], function (_ck, _v) { var _co = _v.component; var currVal_0 = (_co.incident.alertCountForResource && i1.ɵunv(_v, 3, 0, i1.ɵnov(_v, 4).transform(_co.activatedEntity.currentEntity$))); var currVal_1 = i1.ɵnov(_v, 6); _ck(_v, 3, 0, currVal_0, currVal_1); var currVal_2 = _co.viewAlerts; _ck(_v, 9, 0, currVal_2); }, null); }
export function View_IncidentAlertsListComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "incident-alerts-list", [], null, null, null, View_IncidentAlertsListComponent_0, RenderType_IncidentAlertsListComponent)), i1.ɵdid(1, 114688, null, 0, i11.IncidentAlertsListComponent, [i12.Paris, i13.MachinesService, i14.ActivatedEntity, i15.EntityPanelsService], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
var IncidentAlertsListComponentNgFactory = i1.ɵccf("incident-alerts-list", i11.IncidentAlertsListComponent, View_IncidentAlertsListComponent_Host_0, { incident: "incident" }, {}, []);
export { IncidentAlertsListComponentNgFactory as IncidentAlertsListComponentNgFactory };
