export enum SuppressionRuleConditionIocType {
	Any = -1,
	File = 0,
	Ip = 1,
	Url = 2,
	User = 3,
	Machine = 4,
	Process = 5,
	AmsiScript = 6,
	ScheduledTask = 7,
	WmiEvent = 8,
}
