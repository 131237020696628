import { Injectable } from '@angular/core';
import { Paris } from '@microsoft/paris';
import { Observable, of, throwError } from 'rxjs';
import { map, catchError } from 'rxjs/operators';
import {
	Machine,
	MachinePermission,
	MachinePermissions,
	MachinePermissionsApiCall,
	MachineResponseType,
	OperatingSystemPlatformCategories,
} from '@wcd/domain';
import { FeaturesService, Feature } from '@wcd/config';

export type MachineRequestsPermissionsStatus = Record<MachineResponseType, boolean>;
export const MACHINE_RESPONSE_FEATURE_MAP: Record<MachineResponseType, Feature> = {
	ForensicsResponse: Feature.ResponseForensic,
	IsolationResponse: Feature.ResponseIsolation,
	ScanResponse: Feature.ResponseScan,
	RestrictExecutionResponse: Feature.ResponseExecutionPolicy,
	TroubleshootResponse: Feature.TroubleshootingMachine,
	LogsCollectionResponse: Feature.LogsCollection,
} as const;

// TODO: This should really be moved to the backend
const supportedSelectiveIsolationSenseClientVersionMinorVersion = 3630;

const SELECTIVE_ISOLATION_OS_EXCLUSIONS =
	[
		OperatingSystemPlatformCategories.Linux,
		OperatingSystemPlatformCategories.macOS,
		OperatingSystemPlatformCategories.Android,
		OperatingSystemPlatformCategories.iOS
	];

@Injectable()
export class MachineRequestsPermissionsService {
	constructor(private readonly paris: Paris, private readonly features: FeaturesService) {}

	getPermissions(machine: Machine): Observable<MachinePermissions> {
		try {
			return this.paris.apiCall<MachinePermissions, Machine>(MachinePermissionsApiCall, machine, {
				allowCache: true,
			});
		} catch (error) {
			// This error happens when there's a problem parsing the machine, since all of this is synchronous Paris doesn't even create an Observable for it yet,
			// but still throw an error. However, getPermissions returns an Observable, and we don't want every one of the consumers of this API have to deal with both a catchError and a regular try/catch.
			// Therefore catching it "regularly" here and wrapping it in an Observable.
			return throwError(error);
		}
	}

	getFeaturesStatus(machine: Machine): Observable<MachineRequestsPermissionsStatus> {
		return this.getPermissions(machine).pipe(
			map<MachinePermissions, MachineRequestsPermissionsStatus>(
				({ ForensicsRequest, IsolationRequest, ScanRequest, RestrictExecutionRequest, TroubleshootRequest, LogsCollectionRequest }) => ({
					ForensicsResponse: this._evaluateApiAndFeature(
						ForensicsRequest,
						this.features.isEnabled(MACHINE_RESPONSE_FEATURE_MAP.ForensicsResponse)
					),
					IsolationResponse: this._evaluateApiAndFeature(
						IsolationRequest,
						this.features.isEnabled(MACHINE_RESPONSE_FEATURE_MAP.IsolationResponse)
					),
					ScanResponse: this._evaluateApiAndFeature(
						ScanRequest,
						this.features.isEnabled(MACHINE_RESPONSE_FEATURE_MAP.ScanResponse)
					),
					RestrictExecutionResponse: this._evaluateApiAndFeature(
						RestrictExecutionRequest,
						this.features.isEnabled(MACHINE_RESPONSE_FEATURE_MAP.RestrictExecutionResponse)
					),
					TroubleshootResponse: this._evaluateApiAndFeature(
						TroubleshootRequest,
						this.features.isEnabled(MACHINE_RESPONSE_FEATURE_MAP.TroubleshootResponse)
					),
					LogsCollectionResponse: this._evaluateApiAndFeature(
						LogsCollectionRequest,
						this.features.isEnabled(MACHINE_RESPONSE_FEATURE_MAP.LogsCollectionResponse)
					),
				})
			)
		);
	}

	isResponseFeatureEnabled(machine: Machine): Observable<boolean> {
		const isAnyValueTrue = (permissions: MachineRequestsPermissionsStatus) =>
			Object.values<boolean>(permissions as any).some(val => val);

		return this.getFeaturesStatus(machine).pipe(
			map(permissions => isAnyValueTrue(permissions)),
			catchError(() => of(false))
		);
	}

	isSelectiveIsolationSupported(machine: Machine): boolean {
		const doesMachineSupportSelectiveIsolation = () => {
			const [_major, minor] = machine.senseClientVersion.split('.');
			return parseInt(minor) >= supportedSelectiveIsolationSenseClientVersionMinorVersion;
		};

		const doesOSSupportSelectiveIsolation = () => {
			return !SELECTIVE_ISOLATION_OS_EXCLUSIONS.includes(machine.os.osPlatform.id as any) && machine.isManagedByMdatp;
		};

		return (
			this.features.isEnabled(Feature.ResponseSelectiveIsolation) &&
			doesMachineSupportSelectiveIsolation() &&
			doesOSSupportSelectiveIsolation()
		);
	}

	private _evaluateApiAndFeature(result: MachinePermission, featureEnabled: boolean): boolean {
		return featureEnabled && (result === 'Unknown' || result === 'Allowed');
	}
}
