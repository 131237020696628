import { Router, RoutesRecognized, ActivatedRouteSnapshot } from '@angular/router';
import { filter, map } from 'rxjs/operators';
import { Subscription } from 'rxjs';
import { Injectable, OnDestroy } from '@angular/core';

/**
 * Monitors navigation events and tracks specific query params, if the same path is
 * visited again without query params the previously used query params are
 * reloaded.
 *
 * Query params are only tracked for a given route if they are listed in the route config
 */
@Injectable({
	providedIn: 'root',
})
export class QueryParamsCacheService implements OnDestroy {
	private subscription: Subscription;
	private queryParamCache: {} = {};

	constructor(private readonly router: Router) {}

	public useCachedQueryParams(): void {
		this.subscription = this.router.events
			.pipe(
				filter(event => event instanceof RoutesRecognized),
				filter(() => !this.isReloadingWithPreviousQuery()),
				map((event: RoutesRecognized) => {
					const [nextPath, nextQuery] = event.url.split('?');
					const [currentPath, currentQuery] = this.router.url.split('?');
					const paramNames = this.getCachedQueryParamsNames(event.state.root);

					return {
						nextPath,
						nextQuery,
						currentPath,
						currentQuery,
						paramNames,
					};
				}),
				filter(data => !!data.paramNames.length)
			)
			.subscribe(({ nextPath, nextQuery, currentPath, paramNames }) => {
				// if any of the configured params are present, the query gets saved in the cache for next time,
				// otherwise we reload from cache if possible
				// but if the path didn't change and the params aren't present, it's because they were
				// explicitly cleared by the application, so delete them from the cache
				if (nextQuery && paramNames.some(paramName => nextQuery.includes(paramName))) {
					this.setQueryParamsForPath(nextQuery, paramNames, nextPath);
				} else if (nextPath !== currentPath) {
					this.loadPathWithPreviousQueryParams(nextPath);
				} else {
					delete this.queryParamCache[nextPath];
				}
			});
	}

	public destroy(): void {
		this.subscription.unsubscribe();
	}

	private setQueryParamsForPath(newQuery: string, paramNames: string[], newPath: string): void {
		this.queryParamCache[newPath] = paramNames.reduce((acc, paramName) => {
			const queryParam = this.getQueryParamValue(newQuery, paramName);
			return queryParam
				? {
						...acc,
						[paramName]: decodeURIComponent(queryParam),
				  }
				: acc;
		}, {});
	}

	private loadPathWithPreviousQueryParams(nextPath: string) {
		if (typeof this.queryParamCache[nextPath] !== 'undefined') {
			this.router.navigate([nextPath], {
				queryParams: this.queryParamCache[nextPath],
				state: {
					reloadingWithPreviousQuery: true,
				},
			});
		}
	}

	private isReloadingWithPreviousQuery() {
		const currentNavigation = this.router.getCurrentNavigation();
		return (
			currentNavigation &&
			currentNavigation.extras.state &&
			currentNavigation.extras.state.reloadingWithPreviousQuery
		);
	}

	private getQueryParamValue(newQuery: string, paramName: string): string | null {
		const param = newQuery.split('&').find(x => x.includes(paramName));
		if (param) {
			const [_, value] = param.split('=');
			return value;
		}
	}

	private getCachedQueryParamsNames(snapshot: ActivatedRouteSnapshot): string[] {
		if (snapshot.data && snapshot.data.cachedQueryParams) {
			return <string[]>snapshot.data.cachedQueryParams;
		}
		if (snapshot.firstChild) {
			return this.getCachedQueryParamsNames(snapshot.firstChild);
		}
		return [];
	}

	ngOnDestroy() {
		this.destroy();
	}
}
