var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : new P(function (resolve) { resolve(result.value); }).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g;
    return g = { next: verb(0), "throw": verb(1), "return": verb(2) }, typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (_) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) if (e.indexOf(p[i]) < 0)
            t[p[i]] = s[p[i]];
    return t;
};
import { isEmpty } from 'lodash-es';
/* tslint:disable:template-click-events-have-key-events */
import { ChangeDetectorRef, ComponentRef, ElementRef, EventEmitter, OnDestroy, OnInit, QueryList, } from '@angular/core';
import { mergeDataViewConfig } from '@wcd/dataview';
import { DialogsService } from '../../dialogs/services/dialogs.service';
import { merge, ReplaySubject, Subject } from 'rxjs';
import { PreferencesService, FeaturesService, AppContextService } from '@wcd/config';
import { Store } from '../../data/models/store.base';
import { DataTableComponent } from '@wcd/datatable';
import { AVAILABLE_PAGE_SIZES, DataViewModel } from '../models/dataview.model';
import { ExportDataSetModalComponent } from './export-dataset.modal';
import { DimensionsModel } from '../../dialogs/models/dimensions.model';
import { ActivatedRoute, Router } from '@angular/router';
import { PanelService } from '@wcd/panels';
import { DataviewRepositoryService } from '../services/dataview-repository.service';
import { AppInsightsService } from '../../insights/services/app-insights.service';
import { TrackingEventType } from '../../insights/models/tracking-event-type.enum';
import { ErrorsDialogService } from '../../dialogs/services/errors-dialog.service';
import { EntityPanelsService, } from '../../global_entities/services/entity-panels.service';
import { clone, differenceBy, isEqualWith, isNil, omit } from 'lodash-es';
import { Paris, ReadonlyRepository } from '@microsoft/paris';
import { debounceTime, first, map, skip, startWith, take } from 'rxjs/operators';
import { FiltersComponent, FiltersService } from '@wcd/ng-filters';
import { I18nService } from '@wcd/i18n';
import { LiveAnnouncer } from '@angular/cdk/a11y';
import { DataviewActionTypes } from './actions-components/dataview-actions.model';
import { FILTERS_PANEL_SETTINGS, } from './actions-components/dataview-action-filter.component';
import { FabricIconNames } from '@wcd/scc-common';
import { HttpParams } from '@angular/common/http';
import { sccHostService } from '@wcd/scc-interface';
import { panelService as AngularWrapperPanelService, WicdSanitizerService } from '@wcd/shared';
import { isMachineExportResponse } from "../../@entities/machines/services/machines.service";
import { ConfirmationService } from "../../dialogs/confirm/confirm.service";
var DEFAULT_EXPORT_FORMAT = 'csv';
var DEFAULT_SEARCH_PARAM_NAME = 'search';
var DEFAULT_UNIQUE_KEY = 'id';
var DEFAULT_PAGE_SIZE = 30;
var lastId = 0;
var DataViewComponent = /** @class */ (function () {
    function DataViewComponent(sanitizer, dialogsService, panelsService, preferencesService, route, dataviewRepositoryService, router, changeDetectionRef, appInsightsService, errorsDialogService, entityPanelsService, featuresService, appContext, paris, liveAnnouncer, confirmationService, i18nService) {
        var _this = this;
        this.sanitizer = sanitizer;
        this.dialogsService = dialogsService;
        this.panelsService = panelsService;
        this.preferencesService = preferencesService;
        this.route = route;
        this.dataviewRepositoryService = dataviewRepositoryService;
        this.router = router;
        this.changeDetectionRef = changeDetectionRef;
        this.appInsightsService = appInsightsService;
        this.errorsDialogService = errorsDialogService;
        this.entityPanelsService = entityPanelsService;
        this.featuresService = featuresService;
        this.appContext = appContext;
        this.paris = paris;
        this.liveAnnouncer = liveAnnouncer;
        this.confirmationService = confirmationService;
        this.i18nService = i18nService;
        this.customActionsLeft = [];
        this.customActionsRight = [];
        this.allowAdd = false;
        /**
         * Whether to have filters at all in the dataview
         * @default true
         * @type {boolean}
         */
        this.allowFilters = true;
        /**
         * If true, filters selected manually will be persisted to localStorage, and reloaded
         * when the dataview is loaded without filters. Only relevant if allowFilters is also true.
         */
        this.persistFilters = false;
        /**
         * If true, page size selected manually will be persisted to user preferences, and reloaded
         * when the dataview is loaded without page size..
         */
        this.persistPageSize = true;
        /**
         * Whether there are grouped items - items that can be clicked to retrieve nested items in the data table
         * @default false
         * @type {boolean}
         */
        this.allowGroupItems = false;
        /**
         * If allowGroupItems - allow group items parent to be selectable without auto selection of all the nested items
         * @default false
         * @type {boolean}
         */
        this.allowParentSelectionWithoutSelectingNestedItems = false;
        /**
         * Whether there's paging in the dataview. If set to false, there will be no paging controls and sorting will be done locally.
         * @default true
         * @type {boolean}
         */
        this.allowPaging = true;
        this.customControls = [];
        this.measurePerformance = false;
        this.loadGroupItemsOnLoad = false;
        this.showEmptyMessage = true;
        this.hideCommandBarOnEmptyData = false;
        this.navigateOnChange = true;
        this.selectAllItemsByDefault = false;
        this.selectOnItemClick = false;
        this.infiniteScrolling = false;
        this.forceGetDataFromApi = false;
        this.loadItemsOnTableTop = false;
        this.padLeft = true;
        // Remove command bar's horizontal padding.
        this.commandBarNoPadding = false;
        this.datatablePadTop = false;
        //toggle hide\display of Filters sidepane toggle button from command bar, by default the button is displayed
        this.hideFieldsFilter = false;
        //toggle hide\display of items limit selection dropdown from command bar, by default the button is displayed
        this.hideItemsLimitSelection = false;
        this.isSmallPadding = false;
        this.loadMoreOnEmpty = false;
        this.searchMinLength = 0;
        this.showHeader = true;
        this.hideHeaderOnEmptyData = false;
        this.withAddButton = false;
        this.allowMultipleSelection = true;
        this.itemUniqueKey = DEFAULT_UNIQUE_KEY;
        this.showDialogOnError = true;
        this.setFiltersOnDataRefresh = false;
        this.allowResize = true;
        this.hideTable = false;
        this.focusOnTable = false;
        this.selectAllEnabled = false;
        this.entitySidePanelSettings = {};
        // using false will be translated to null in the datatable which will eliminate the tabindex option
        this.tabIndex = -1;
        this.removePadding = false;
        this.removePaddingRight = false;
        this.removePaddingLeftOnResize = false;
        this.shouldShowPageNumbersOnPagination = false;
        /**
         * When displayed in a narrow area - hiding the 'Customize columns' button can free space for the search
         * @default true
         * @type {boolean}
         */
        this.allowColumnCustomization = true;
        /**
         * On narrow areas the order (from left) of search box, empty space and actions - can make the search box really short (1:1 ratio with the spacing)
         * Setting the flag to true will increase the size of the search box in favor of the space in a 3:1 ratio
         */
        this.giveSearchBoxMoreSpace = false;
        /**
         * A filter which will be used to decide which groups are expanded on init.
         */
        this.isGroupExpandedOnInit = function (item) { return false; };
        /**
         * Label to be announced by the narrator when reaching the table.
         */
        this.label = '';
        //======== m365 designed error message with reload button (currently adopted bpany TVM)
        this.m365DataError = false;
        //========
        this.disableAddButton = false;
        /**
         * Disable tooltip hover text for the dataview add button, by default would show generic text - "Add new {entity name}"
         */
        this.disableAddTooltip = false;
        /**
         * Make the width of the table fixed, no matter the content size. overflow items will be hidden with ellipsis.
         * Important: When set to true, allowResize option will be set to false (resize cannot work normally when fixedTable set to true)
         */
        this._fixedTable = false;
        /**
         * changes the order of the command bar buttons when in asset page
         */
        this.assetCommandBar = false;
        this.queueHeader = false;
        this.shouldShowEntityPanel = true;
        this.focusOnFirstMenuItem = false;
        //responsiveLayout indicates wither to apply responsive design to the component.
        // Set this to true when the dataview is used as the page main content (e.g. queue pages..)
        this.responsiveLayout = false;
        this.responsiveActionBar = false;
        this.fullHeight = true;
        this.focusOnFirstElement = false;
        this.onAction = new EventEmitter();
        this.onData = new EventEmitter();
        this.onItemsAddedOnTop = new EventEmitter();
        this.onItemClick = new EventEmitter();
        this.onNewItem = new EventEmitter();
        this.select = new EventEmitter();
        this.groupExpand = new EventEmitter();
        this.dataLoadError = new EventEmitter();
        this.filtersChange = new EventEmitter();
        this.afterTableInit = new EventEmitter();
        this.onSortChanged = new EventEmitter();
        this.onSearch = new EventEmitter();
        this.onTableRenderComplete = new EventEmitter();
        this.showFilters = false;
        this.pageSizes = AVAILABLE_PAGE_SIZES;
        this.selectEnabled = true;
        this.selectedItems = [];
        this.allItemsSelected = false;
        this.allPageItemsSelected = false;
        this.runningAction = false;
        this._visibleFieldIds$ = new ReplaySubject(1);
        this.visibleFieldIds$ = this._visibleFieldIds$.asObservable();
        this.filtersPanelSettings = FILTERS_PANEL_SETTINGS;
        this.modalsWidth = 0;
        this.commandBarLeft = [];
        this.commandBarRight = [];
        this._isInit = false;
        this._filterValues$ = new Subject();
        this._reloadOnEntityPanelClose = false;
        this.focusOnFirstCell = false;
        this.isTableLoading = false;
        this.isTableExporting = false;
        this.SearchSafePadding = 2;
        this.disableReset = false;
        this.showFilterButtonId = "dataview-show-filters-" + lastId++;
        this.isScc = sccHostService.isSCC;
        this._ignoreQueryParams = false;
        this._queryParamsSubscription = route.queryParams
            // adding debounce since "hideTable" update is asynced, and we should make this flow asynced as well
            // (fix for bug https://dev.azure.com/microsoft/OS/_workitems/edit/23724353)
            // decreasing the debounce time from 50 to 1, because when it was defined as 50, there was problem with filters for dataviews with default filter (which is not 'any'):
            // selectedValues function in filters-field component was called twice: once with the right value
            // and second time with undefined
            // (fix for bug https://microsoft.visualstudio.com/OS/_workitems/edit/24096503)
            .pipe(debounceTime(1))
            .subscribe(function (params) {
            if (!_this.hideTable) {
                _this.setDataViewOptionsFromLocation(params);
                _this.setUserPreferences();
            }
        });
        this.filtersPanelSettings.headerText = this.i18nService.strings.dataview_filter_panel_header_text;
        this.getBoundPageSizeLabel = this.getPageSizeLabel.bind(this);
    }
    Object.defineProperty(DataViewComponent.prototype, "disableSelection", {
        set: function (value) {
            this.selectEnabled = !value;
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(DataViewComponent.prototype, "itemName", {
        get: function () {
            return this._itemName;
        },
        set: function (value) {
            this._itemName = value;
            this.evaluateEntityNames();
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(DataViewComponent.prototype, "fixedTable", {
        get: function () {
            return this._fixedTable;
        },
        set: function (fixedTable) {
            this._fixedTable = fixedTable;
            if (fixedTable) {
                this.allowResize = !fixedTable;
            }
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(DataViewComponent.prototype, "allItems", {
        /**
         * Returns all the items and grouped items in the data table
         */
        get: function () {
            var groupedItems = [];
            if (this.groupItems)
                this.groupItems.forEach(function (items) { return (groupedItems = groupedItems.concat(items)); });
            return this.dataSet.items.concat(groupedItems);
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(DataViewComponent.prototype, "ignoreQueryParams", {
        get: function () {
            return this._ignoreQueryParams;
        },
        /**
         * When using multiple dataviews on the same url - they start to cause problems to each other
         * fixing it by ignoring the query params for additional dataviews (except the main on the url)
         * @default false
         * @type {boolean}
         */
        set: function (value) {
            if (value === true) {
                if (this._queryParamsSubscription)
                    this._queryParamsSubscription.unsubscribe();
                this._latestQueryParams = null;
            }
            this._ignoreQueryParams = value;
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(DataViewComponent.prototype, "refreshOn", {
        get: function () {
            return this._refreshOn;
        },
        set: function (value) {
            if (value instanceof Function)
                return;
            if (value && (!this._refreshOn || this._refreshOn !== value))
                this.refreshData();
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(DataViewComponent.prototype, "dataViewConfig", {
        get: function () {
            if (!this._dataViewConfig && !this._options)
                return null;
            return mergeDataViewConfig(this._dataViewConfig, this._options);
        },
        set: function (value) {
            this._dataViewConfig = value;
            if (value) {
                this._dataViewConfig.searchParamName = value.searchParamName || DEFAULT_SEARCH_PARAM_NAME;
            }
            if (this._isInit)
                this.setDataViewModel();
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(DataViewComponent.prototype, "itemNamePlural", {
        get: function () {
            return this._itemNamePlural;
        },
        set: function (value) {
            this._itemNamePlural = value;
            this.evaluateEntityNames();
            if (this._isInit)
                this.setDataViewModel();
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(DataViewComponent.prototype, "fields", {
        get: function () {
            return this._fields;
        },
        set: function (value) {
            this._fields = value;
            if (this._isInit)
                this.setDataViewModel();
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(DataViewComponent.prototype, "options", {
        get: function () {
            return this._options;
        },
        set: function (value) {
            this._options = value;
            if (this._isInit)
                this.setDataViewModel();
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(DataViewComponent.prototype, "store", {
        get: function () {
            return this._store;
        },
        set: function (value) {
            var _this = this;
            if (value === this._store)
                return;
            if (this._onStoreChanges)
                this._onStoreChanges.unsubscribe();
            this._store = value;
            this.evaluateEntityNames();
            this.itemActions = (this.itemActions || []).concat((this.store.options.getDataviewItemActions && this.store.options.getDataviewItemActions()) || []);
            this.setItemActions = this.store.getItemsActions.bind(this.store);
            this.searchEnabled =
                this.store.options.dataViewOptions && this.store.options.dataViewOptions.searchEnabled;
            this.itemSelectable =
                (this.store.options.dataViewOptions && this.store.options.dataViewOptions.isItemSelectable) ||
                    this.itemSelectable;
            var storeFields = this.store.options.dataViewOptions && this.store.options.dataViewOptions.fields;
            if (storeFields)
                this._fields = storeFields;
            if (this._isInit)
                this.setDataViewModel();
            if (this._store instanceof Store)
                this._onStoreChanges = this._store.changes$.subscribe(function () { return _this.refreshData(); });
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(DataViewComponent.prototype, "repository", {
        get: function () {
            return this._repository;
        },
        set: function (repository) {
            if (repository === this._repository)
                return;
            this._repository = repository;
            this.evaluateEntityNames();
            if (this._isInit)
                this.setDataViewModel();
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(DataViewComponent.prototype, "entityType", {
        get: function () {
            return this._entityType;
        },
        set: function (entityType) {
            var _this = this;
            if (this._entityType === entityType)
                return;
            this._entityType = entityType;
            this.evaluateEntityNames();
            this._onChangeDisplayedItems && this._onChangeDisplayedItems.unsubscribe();
            this._onChangeDisplayedItems = this.entityPanelsService
                .getCurrentlyDisplayedItems(entityType.entity)
                .subscribe(function (items) {
                _this.highlightedItems = items;
                _this.changeDetectionRef.markForCheck();
            });
            this._onCloseEntityPanelSubscription = this.entityPanelsService.onCloseEntityPanel.subscribe(function (entity) {
                //it's possible a panel was closed for a different entity, which is irrelevant here
                if (entity !== entityType.entity || AngularWrapperPanelService.hasOpenPanels())
                    return;
                _this.unSelectAllItems();
                if (_this._reloadOnEntityPanelClose) {
                    _this.refreshData();
                    _this._reloadOnEntityPanelClose = false;
                }
            });
            this._onEntityTypeActionSubscription = this.entityPanelsService.onAction.subscribe(function ($event) {
                if ($event.entityType === _this.entityType) {
                    if ($event.action.refreshOnResolve)
                        _this._reloadOnEntityPanelClose = true;
                    else if ($event.action.localRefreshOnResolve)
                        _this.changeDetectionRef.markForCheck();
                }
            });
        },
        enumerable: true,
        configurable: true
    });
    DataViewComponent.prototype.getExportActionConfig = function () {
        var _this = this;
        return {
            localizedTooltip: this.exportTooltipText ||
                this.i18nService.get('dataview_export_to_csv', {
                    entityName: this.itemNamePluralLowercase,
                }),
            onClickCallback: this.exportData.bind(this),
            icon: FabricIconNames.Download,
            localizedLabel: this.i18nService.get('dataview_export'),
            isBusyFn: function () { return _this.isTableExporting; },
            localizedBusyLabel: this.i18nService.get('dataview_exporting'),
            disabled: false,
            dataTrackId: 'export_' + this.dataViewModel.id,
            dataTrackType: 'Export',
            actionType: DataviewActionTypes.Button,
        };
    };
    DataViewComponent.prototype.getAddActionConfig = function () {
        var _this = this;
        return {
            localizedTooltip: this.disableAddTooltip
                ? this.disabledAddTooltip
                : this.i18nService.get('dataview_add_new_item', {
                    itemType: this.itemNameLowercase,
                }),
            onClickCallback: function () { return _this.onNewItem.emit(); },
            icon: FabricIconNames.Add,
            localizedLabel: this.i18nService.get('dataview_add_item', {
                itemType: this.itemNameLowercase,
            }),
            disabled: this.disableAddButton,
            elementId: this.itemName + '-add-btn',
            dataTrackId: 'addNew_' + this.dataViewModel.id,
            actionType: DataviewActionTypes.Button,
        };
    };
    DataViewComponent.prototype.getColumnsCustomizeActionConfig = function () {
        return {
            selectableFieldIds: this.selectableFieldIds,
            onColumnsChange: this.onColumnsChange.bind(this),
            resetVisibleFields: this.resetVisibleFields.bind(this),
            actionType: DataviewActionTypes.ColumnsCustomization,
            visibleFields: this.visibleFieldIds$,
        };
    };
    DataViewComponent.prototype.getFilterActionConfig = function () {
        return {
            showText: true,
            disableFilterPanelAutoFocus: this.disableFilterPanelAutoFocus,
            datatablePadTop: this.datatablePadTop,
            onFiltersChangedCallback: this.onFiltersChanged.bind(this),
            dataviewId: this.showFilterButtonId,
            actionType: DataviewActionTypes.Filter,
            defaultQueryFilters: this.defaultQueryFilters,
        };
    };
    DataViewComponent.prototype.getPageSizeCustomizationActionConfig = function () {
        return {
            onSelectionChanged: this.onPageSizeChange.bind(this),
            currentSelection: this.dataViewModel.pageSize$,
            selections: this.pageSizes,
            icon: FabricIconNames.PageList,
            formatLabel: this.getBoundPageSizeLabel,
            ariaLabel: this.i18nService.get('dataview.itemsPerPage'),
            dataTrackId: 'dataview-pagesize',
            actionType: DataviewActionTypes.FancySelect,
        };
    };
    DataViewComponent.prototype.getPaginationActionConfig = function () {
        return {
            onPageNumberChange: this.onPageNumberChange.bind(this),
            actionType: DataviewActionTypes.Pagination,
        };
    };
    DataViewComponent.prototype.getSearchActionConfig = function () {
        var _this = this;
        return {
            id: this.id,
            giveSearchBoxMoreSpace: this.giveSearchBoxMoreSpace,
            searchMinLength: this.searchMinLength,
            searchCallback: function () {
                _this.focusOnTable = true;
            },
            entityTypeName: this.itemNamePluralLowercase,
            actionType: DataviewActionTypes.Search,
            allowMultipleWords: this.dataViewConfig && this.dataViewConfig.allowSearchMultipleWords,
            searchLocalized: this.searchCommandTitle || this.i18nService.strings.dataview_search,
        };
    };
    DataViewComponent.prototype.buildLeftCommandBar = function () {
        var leftCommands = [];
        if (this.assetCommandBar) {
            if (this.dataViewModel.exportEnabled) {
                leftCommands.push(this.getExportActionConfig());
            }
            if (this.searchEnabled) {
                leftCommands.push(this.getSearchActionConfig());
            }
        }
        leftCommands = leftCommands.concat(this.customActionsLeft);
        if (this.allowAdd) {
            leftCommands.push(this.getAddActionConfig());
        }
        if (!this.assetCommandBar && this.searchEnabled) {
            leftCommands.push(this.getSearchActionConfig());
        }
        return leftCommands;
    };
    DataViewComponent.prototype.buildRightCommandBar = function () {
        var rightCommands = [];
        if (!this.infiniteScrolling && this.dataViewModel.allowPaging) {
            rightCommands.push(this.getPaginationActionConfig());
        }
        rightCommands = rightCommands.concat(this.customActionsRight);
        this.customControls.forEach(function (control) {
            rightCommands.push({
                localizedTooltip: control.tooltip,
                onClickCallback: control.method,
                icon: control.icon,
            });
        });
        if (this.dataViewModel && this.allowColumnCustomization) {
            rightCommands.push(this.getColumnsCustomizeActionConfig());
        }
        if (!this.assetCommandBar && this.dataViewModel.exportEnabled) {
            rightCommands.push(this.getExportActionConfig());
        }
        if (!this.infiniteScrolling && this.dataViewModel.allowPaging) {
            rightCommands.push(this.getPageSizeCustomizationActionConfig());
        }
        rightCommands.push(this.getFilterActionConfig());
        return rightCommands;
    };
    Object.defineProperty(DataViewComponent.prototype, "fixedOptions", {
        get: function () {
            return this._fixedOptions;
        },
        set: function (value) {
            this._fixedOptions = value;
            if (this._isInit)
                this.setDataViewModel();
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(DataViewComponent.prototype, "getFilterQueryOptions", {
        get: function () {
            return this._getFilterParams;
        },
        set: function (getFilterQueryOptions) {
            this._getFilterParams = getFilterQueryOptions;
            if (this._isInit)
                this.setDataViewModel();
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(DataViewComponent.prototype, "disabledFields", {
        set: function (value) {
            this._disabledFields = value;
            if (this._isInit)
                this.setDataViewModel();
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(DataViewComponent.prototype, "sortDisabledFields", {
        set: function (value) {
            this._sortDisabledFields = value;
            if (this._isInit)
                this.setDataViewModel();
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(DataViewComponent.prototype, "visibleFields", {
        get: function () {
            return this._visibleFields;
        },
        set: function (value) {
            this._visibleFields = value;
            if (this._isInit && this.dataViewModel) {
                this.dataViewModel.setVisibleFields(value);
            }
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(DataViewComponent.prototype, "userPreferencesId", {
        get: function () {
            return "dataView_" + DataViewModel.getDataViewId(this.store, this.repository, this.id ? { id: this.id } : this._dataViewConfig);
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(DataViewComponent.prototype, "selectedItemsCount", {
        get: function () {
            return this.allItemsSelected ? this.dataSet.count : this.selectedItems.length;
        },
        enumerable: true,
        configurable: true
    });
    DataViewComponent.prototype.ngOnInit = function () {
        var _this = this;
        this.isItemSelected = this.isItemSelected ? this.isItemSelected : function () { return false; };
        if (this.selectAllItemsByDefault) {
            this.isItemSelected = function () { return true; };
        }
        if (this.description)
            this.descriptionHtml = this.sanitizer.bypassSecurityTrustHtml(this.description);
        this.evaluateEntityNames();
        var userPreferences = this.getUserPreferencesForDataView();
        this.columnWidths = userPreferences && userPreferences.columnWidths;
        var urlParams = this.getUrlParams();
        if (!urlParams.has('filters')) {
            this.setFiltersStateForEmptyUrl(urlParams.has('innerRouteFilterParams'));
        }
        this.setDataViewModel();
        this._isInit = true;
        this.boundIsItemSelectable = this.isItemSelectable.bind(this);
        this.getLoadNextResultsUrl = this.getLoadNextResultsUrl.bind(this);
        this.getLoadPreviousResultsUrl = this.getLoadPreviousResultsUrl.bind(this);
        this.panelsService.activePanels$.subscribe(function (panels) { return _this.setModalWidth(panels); });
        this.visibleFieldIds$.subscribe(function () { return (_this.disableReset = _this.dataViewModel.allDefaultFieldsSelected); });
        this.groupItems = new Map();
        if (!this.dataViewModel)
            return;
    };
    DataViewComponent.prototype.ngOnDestroy = function () {
        this._locationChangeSubscription && this._locationChangeSubscription.unsubscribe();
        this._queryParamsSubscription && this._queryParamsSubscription.unsubscribe();
        this._onChangeDisplayedItems && this._onChangeDisplayedItems.unsubscribe();
        this._onCloseEntityPanelSubscription && this._onCloseEntityPanelSubscription.unsubscribe();
        this._onEntityTypeActionSubscription && this._onEntityTypeActionSubscription.unsubscribe();
        this.dataViewModel && this.dataViewModel.destroy();
        if (this._onStoreChanges)
            this._onStoreChanges.unsubscribe();
    };
    DataViewComponent.prototype.setDataViewModel = function () {
        var _this = this;
        if (!this.fields && !this.store)
            return;
        // First, debouncing, since setDataViewModel can be called a bunch of times together:
        clearTimeout(this._setDataViewModelTimeout);
        this._setDataViewModelTimeout = setTimeout(function () { return __awaiter(_this, void 0, void 0, function () {
            var dataViewConfig;
            var _this = this;
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        this.destroy();
                        dataViewConfig = mergeDataViewConfig({
                            pageSize: this.pageSize,
                            searchParamName: (this.dataViewConfig && this.dataViewConfig.searchParamName) ||
                                DEFAULT_SEARCH_PARAM_NAME,
                            disabledVisibleFieldIds: this._disabledFields,
                            disabledFilterFieldIds: this.disabledFilters,
                            sortDisabledFieldIds: this._sortDisabledFields,
                            fields: this.fields,
                            defaultSortFieldId: this.defaultSortFieldId,
                            fixedFilterValues: this.fixedOptions,
                            fixedOptions: this.fixedOptions,
                            allowFilters: this.allowFilters,
                            allowPaging: this.allowPaging,
                            visibleFields: this.visibleFields,
                            infiniteScrolling: this.infiniteScrolling,
                            forceGetDataFromApi: this.forceGetDataFromApi,
                            loadItemsOnTableTop: this.loadItemsOnTableTop,
                            getLoadNextResultsUrl: this.getLoadNextResultsUrl,
                            getLoadPreviousResultsUrl: this.getLoadPreviousResultsUrl,
                            getFilterQueryOptions: this.getFilterQueryOptions,
                        }, this.dataViewConfig, this.getUserPreferencesForDataView());
                        if (this.allowFilters &&
                            !this.store &&
                            this.repository &&
                            dataViewConfig.requireFiltersData !== false &&
                            !dataViewConfig.getFiltersData) {
                            Object.assign(dataViewConfig, {
                                getFiltersData: this.dataviewRepositoryService.getFilterValuesForRepository(this.repository, __assign({}, this.fixedOptions, dataViewConfig.fixedFilterValues)),
                            });
                        }
                        this.dataViewModel = this.store
                            ? DataViewModel.fromStore(this.store, dataViewConfig)
                            : this.repository
                                ? DataViewModel.fromRepository(this.repository, dataViewConfig, this.paris, this.featuresService, this.appContext)
                                : new DataViewModel(dataViewConfig);
                        if (this._dataViewOptionsToSet) {
                            this.dataViewModel.setOptions(this._dataViewOptionsToSet, false);
                            this._dataViewOptionsToSet = null;
                        }
                        this._onVisibleFieldsChangeSubscription = this.dataViewModel.visibleFields$.subscribe(function (visibleFields) { return _this.onFieldsChange(visibleFields); });
                        this._onErrorSubscription = this.dataViewModel.error$.subscribe(function (error) {
                            return _this.onDataError(error);
                        });
                        this._onDataSubscription = this.dataViewModel.dataSet$.subscribe(this.onDataHandler.bind(this));
                        this._isLoadingSubscription = this.dataViewModel.isLoading$.subscribe(function (isDataLoading) {
                            if (isDataLoading) {
                                _this.isTableLoading = true;
                            }
                            // isLoading starts as false, when data load starts, its turn in to true
                            // when data load ends turn back to false
                            // table done first init after is loading finished the cycle: false -> true -> false
                            if (!isDataLoading && _this.isTableLoading) {
                                if (_this.focusOnFirstMenuItem) {
                                    if (_this.focusOnFirstMenuItem) {
                                        _this.focusOnFirstFocusable();
                                        _this.focusOnFirstMenuItem = false;
                                        setTimeout(function () {
                                            _this.tabIndex = -1;
                                        });
                                    }
                                    _this.focusOnFirstMenuItem = false;
                                }
                                _this.afterTableInit.emit(true);
                            }
                        });
                        // When the first event is fired (if the page is loaded with search term in url) sets the value
                        this._onSearchChangeSubscription = this.dataViewModel.searchTerm$.pipe(take(1)).subscribe(function () {
                            if (_this.dataViewModel.currentSearchTerm) {
                                _this.onSearchBlur();
                            }
                        });
                        // Skipping the first event, to avoid the initial dataViewModel settings. Debouncing for performance.
                        this._onSettingsChangeSubscription = merge(this.dataViewModel.changeSettings$, this._filterValues$)
                            .pipe(debounceTime(50), skip(1))
                            .subscribe(this.onDataViewSettingsChange.bind(this));
                        this._visibleFieldsSubscription = this.dataViewModel.visibleFields$
                            .pipe(map(function (fields) { return fields.map(function (field) { return field.id; }); }), startWith(undefined), debounceTime(1))
                            .subscribe(function (fieldIds) {
                            _this._visibleFieldIds$.next(fieldIds);
                        });
                        if (!this.setDataViewOptionsFromLocation())
                            this.refreshData();
                        this.unSelectAllItems();
                        this.setViewModelOptions();
                        return [4 /*yield*/, this.setDataTableGetGroupedItems()];
                    case 1:
                        _a.sent();
                        if (this.responsiveActionBar) {
                            this.commandBarLeft = this.buildLeftCommandBar();
                            this.commandBarRight = this.buildRightCommandBar();
                        }
                        this.changeDetectionRef.markForCheck();
                        return [2 /*return*/];
                }
            });
        }); }, 1);
    };
    DataViewComponent.prototype.focusOnFirstFocusable = function () {
        var _this = this;
        setTimeout(function () {
            if (_this.focusable.first instanceof ElementRef) {
                _this.focusable.first.nativeElement.focus();
            }
            else if (_this.focusable.first && _this.focusable.first.setFocus) {
                _this.focusable.first.setFocus();
            }
        }, 0);
    };
    DataViewComponent.prototype.toggleFilters = function (isOpen) {
        var _this = this;
        this.disableFilterPanelAutoFocus = false;
        this.showFilters = isNil(isOpen) ? !this.showFilters : isOpen;
        if (!this.showFilters) {
            setTimeout(function () { return _this.filterButton.nativeElement.focus(); }, 0);
        }
    };
    DataViewComponent.prototype.setViewModelOptions = function () {
        if (!this.dataViewModel.isLocalData) {
            this.searchTerm = this.dataViewModel.currentSearchTerm;
            if (this._ignoreQueryParams) {
                // if the data isn't local it will be navigated on change and reloaded but with ignoring query params
                // it will not receive the events of the query param changes - which in this case is the search been pressed
                this.dataViewModel.reloadData();
            }
        }
    };
    DataViewComponent.prototype.destroy = function () {
        if (this.dataViewModel) {
            this.dataViewModel.destroy();
            this._onDataSubscription && this._onDataSubscription.unsubscribe();
            this._onErrorSubscription && this._onErrorSubscription.unsubscribe();
            this._onVisibleFieldsChangeSubscription && this._onVisibleFieldsChangeSubscription.unsubscribe();
            this._onSettingsChangeSubscription && this._onSettingsChangeSubscription.unsubscribe();
            this._onSearchChangeSubscription && this._onSearchChangeSubscription.unsubscribe();
            this._visibleFieldsSubscription && this._visibleFieldsSubscription.unsubscribe();
            this._isLoadingSubscription && this._isLoadingSubscription.unsubscribe();
        }
        if (this._storeItemsSubscription)
            this._storeItemsSubscription.unsubscribe();
    };
    DataViewComponent.prototype.onDataError = function (error) {
        if (!error)
            this.error = null;
        else if (error.status !== 401) {
            // In case of error, revert user preferences
            if (this.savedUserPreferences) {
                this.preferencesService.setPreference(this.userPreferencesId, this.savedUserPreferences);
                this.savedUserPreferences = null;
            }
            this.dataLoadError.emit(error);
            this.error = error;
            this.appInsightsService.trackException(error);
            if (this.showDialogOnError) {
                this.dialogsService.showError({
                    title: this.i18nService.get('dataview_loading_error', {
                        itemType: this.itemNamePluralLowercase,
                    }),
                    message: (error && error.message) || error,
                    data: error,
                });
            }
            this.changeDetectionRef.markForCheck();
        }
    };
    DataViewComponent.prototype.refreshData = function () {
        if (this.dataViewModel && !this._lockRefresh) {
            this._lockRefresh = true;
            if (this.setFiltersOnDataRefresh) {
                this.dataViewModel.setFilters();
            }
            this.dataViewModel.reloadData();
        }
    };
    DataViewComponent.prototype.reloadData = function () {
        this.dataViewModel.setFilters();
        this.dataViewModel.reloadData();
    };
    DataViewComponent.prototype.getUrlParams = function () {
        var fromString = window.location.search && window.location.search.includes('?')
            ? window.location.search.split('?')[1]
            : window.location.search;
        return new HttpParams({ fromString: fromString });
    };
    DataViewComponent.prototype.evaluateEntityNames = function () {
        var itemNamePlural = this.itemNamePlural
            ? this.itemNamePlural
            : this.entityType && this.entityType.entityPluralNameKey
                ? this.i18nService.get(this.entityType.entityPluralNameKey)
                : this.i18nService.get('dataview_items');
        this.itemNamePluralLowercase = itemNamePlural.toLowerCase();
        this.itemNameSingular = this.itemName
            ? this.itemName
            : this.entityType && this.entityType.entitySingularNameKey
                ? this.i18nService.get(this.entityType.entitySingularNameKey)
                : this.i18nService.get('dataview_item');
        this.itemNameLowercase = this.itemNameSingular.toLowerCase();
    };
    DataViewComponent.prototype.onFiltersChanged = function (filtersState) {
        // If we are in non responsive actions mode, then the filters are inlined in dataview template
        // So we handle the filters component state here
        if ((!filtersState.selection || Object.keys(filtersState.selection).length === 0) &&
            !this.responsiveActionBar) {
            this.resetFilters(filtersState);
        }
        this.dataViewModel.setFilters(filtersState);
        this._filterValues$.next(filtersState);
        this.filtersChange.emit(filtersState);
        if (this.dataViewModel.allowFilters)
            this.trackFilters(filtersState);
    };
    DataViewComponent.prototype.resetFilters = function (filtersState) {
        filtersState.selection = this.defaultQueryFilters;
        filtersState.serialized = this.defaultQueryFilters;
        this._filtersComponent.resetSelectedValues(this.defaultQueryFilters);
    };
    DataViewComponent.prototype.trackFilters = function (filtersState) {
        var _this = this;
        var filterTracking = Object.keys(filtersState.serialized || {}).map(function (filterParam) {
            var serializedFilterValues = filtersState.serialized[filterParam];
            var fieldTrackingData = {
                field: filterParam,
                valueCount: serializedFilterValues instanceof Array ? serializedFilterValues.length : 1,
            };
            var field = _this.dataViewModel.fields.find(function (_field) { return _field.id === filterParam; });
            if (field && field.custom && field.custom.allowFilterValueTracking)
                fieldTrackingData.values = serializedFilterValues;
            return fieldTrackingData;
        });
        this.trackEvent('DataViewFilterChanged', TrackingEventType.Filter, filterTracking);
    };
    DataViewComponent.prototype.onSortChange = function (sortField, sortDescending) {
        this.dataViewModel.setSortField(sortField, sortDescending, true);
        this.onSortChanged.emit({ sortField: sortField, sortDescending: this.dataViewModel.sortDescending });
    };
    /**
     * Toggles the given item selection status.
     * @param item Item to toggle the state of
     * @param isExclusive If isExclusive parameter is true, so all other items are de-selected (if any).
     * @param state Is state is specified, so set the item selection to the given state, otherwise it toggles it (negates it)
     */
    DataViewComponent.prototype.toggleItemSelection = function (item, isExclusive, state) {
        if (isExclusive === void 0) { isExclusive = false; }
        if (this._dataTableComponent) {
            if (isExclusive) {
                if (state === undefined) {
                    state = !this._dataTableComponent.selectedItemsIndex.has(item);
                }
                this.selectItems(state ? [item] : []);
            }
            else {
                this._dataTableComponent.toggleItemSelection(item, state);
            }
        }
    };
    /**
     * Select the given items (and de-select other items, if any).
     * @param items The items that should be selected
     */
    DataViewComponent.prototype.selectItems = function (items) {
        var _this = this;
        if (this.isItemSelectable)
            items = items.filter(function (item) { return _this.isItemSelectable(item); });
        if (this._dataTableComponent) {
            this._dataTableComponent.selectItems(items);
        }
    };
    DataViewComponent.prototype.selectItemsByIds = function (itemIds) {
        var currentDataSet = this.dataViewModel.dataSet$.value;
        if (currentDataSet) {
            var selectedItems = currentDataSet.items.filter(function (item) { return itemIds.includes(item.id); });
            this.selectItems(selectedItems);
        }
    };
    DataViewComponent.prototype.onSelectItems = function (selectedItems, previous, next) {
        if (this.isSameItemsById(selectedItems, this.selectedItems))
            return;
        this.selectedItems = selectedItems;
        this.allItemsSelected = null;
        var items = this.dataSet ? this.dataSet.items : [], count = this.dataSet ? this.dataSet.count : 0;
        this.allPageItemsSelected = this.dataSet
            ? selectedItems.length === items.length && selectedItems.length < count
            : false;
        if (!this.allPageItemsSelected && this.itemSelectable && selectedItems.length < count)
            this.allPageItemsSelected = items.length === this.selectedItems.length;
        var selectEvent = {
            items: selectedItems,
            previous: previous,
            next: next,
        };
        this.select.emit(selectEvent);
        if (this.entityType && this.shouldShowEntityPanel)
            this.showEntityPanel(selectEvent);
        else if (this.setItemActions)
            this.itemActions = this.setItemActions(this.selectedItems) || [];
        this.changeDetectionRef.detectChanges();
    };
    DataViewComponent.prototype.selectAllItems = function (applySelection) {
        var _this = this;
        if (applySelection === void 0) { applySelection = true; }
        if (applySelection) {
            setTimeout(function () {
                _this.selectItems(_this.dataSet.items);
                _this.changeDetectionRef.markForCheck();
            });
        }
    };
    DataViewComponent.prototype.unSelectAllItems = function () {
        this.allItemsSelected = false;
        this.allPageItemsSelected = false;
        this._dataTableComponent && this._dataTableComponent.selectNone();
        this.selectedItems = [];
        this.changeDetectionRef.markForCheck();
    };
    DataViewComponent.prototype.onFieldsChange = function (enabledFields) {
        var enabledFieldsIndex = {};
        enabledFields.forEach(function (field) { return (enabledFieldsIndex[field.id] = true); });
        this.changeDetectionRef.markForCheck();
    };
    DataViewComponent.prototype.runAction = function (action, actionValue) {
        var _this = this;
        var onAction = function (data) {
            if (action.refreshOnResolve && data !== false)
                _this.refreshData();
            _this.onAction.emit({ data: data });
        };
        this.runningAction = true;
        try {
            ((actionValue && actionValue.method) || action.method)(this.allItemsSelected ? null : this.selectedItems, this._filtersComponent ? this._filtersComponent.serialize() : {}, this.dataViewModel.dataSet$.value, actionValue).then(function (data) {
                if (data !== false) {
                    _this.unSelectAllItems();
                    onAction(data);
                }
                _this.runningAction = false;
            }, function (error) {
                if (error !== false) {
                    _this.dialogsService.showError({
                        title: _this.i18nService.get('dataview_error_dialog_title'),
                        errorTitle: _this.i18nService.get('dataview_error_dialog_error_title', {
                            actionName: action.name,
                        }),
                        data: error,
                    });
                }
                _this.runningAction = false;
            });
        }
        catch (e) {
            console.error(e, e.stack);
            this.runningAction = false;
        }
    };
    DataViewComponent.prototype.onDataHandler = function (dataSet) {
        var _this = this;
        this.dataSet = dataSet;
        this._lockRefresh = false;
        this.error = null;
        if (dataSet) {
            this.savedUserPreferences = null;
            var resultsCount_1 = (dataSet.items && dataSet.items.length) || 0;
            if (this.dataViewModel.currentPage === 1) {
                setTimeout(function () {
                    return _this.liveAnnouncer.announce(!resultsCount_1
                        ? _this.emptyDataMessage ||
                            _this.i18nService.get('dataview_empty_data_message', {
                                itemType: _this.itemNamePluralLowercase,
                            })
                        : _this.i18nService.get('dataview.result.found', { count: resultsCount_1 }), 'assertive');
                }, 0);
            }
            this.onData.emit({ data: dataSet, dataOptions: this, page: this.dataViewModel.currentPage });
            setTimeout(function () {
                _this.selectItems(_this.allItems.filter(_this.isItemSelected));
                _this.changeDetectionRef.detectChanges();
            });
            if (!this._shouldKeepNextResultsUrl) {
                this.setLoadNextResultsUrl(dataSet.next);
            }
            if (!this._shouldKeepPreviousResultsUrl) {
                this.setLoadPreviousResultsUrl(dataSet.previous);
            }
        }
        this.changeDetectionRef.markForCheck();
    };
    DataViewComponent.prototype.search = function (term) {
        var _this = this;
        // if ignoring the query params and the data isn't local, the search term won't be updated because navigate on change
        // won't happen so the search term must be set inside the data view model as the third argument (setCurrentSearchTerm)
        this.lastSearched = term;
        var setCurrentSearchTerm = this._ignoreQueryParams;
        this.dataViewModel.setSearchTerm(term, true, setCurrentSearchTerm);
        this.onSearch.emit(term);
        this.trackEvent('DataviewSearchGo', TrackingEventType.Button);
        setTimeout(function () { return (_this.focusOnTable = true); });
    };
    DataViewComponent.prototype.cancelSearch = function () {
        this.search('');
        this.searchTerm = null;
        this.onSearch.emit('');
        this.trackEvent('DataviewSearchClear', TrackingEventType.Button);
    };
    DataViewComponent.prototype.onSearchBlur = function () {
        if (!this.searchTerm && this.dataViewModel.currentSearchTerm)
            this.searchTerm = this.dataViewModel.currentSearchTerm;
    };
    DataViewComponent.prototype.isItemSelectable = function (item) {
        return this.itemSelectable ? this.itemSelectable(item) : true;
    };
    DataViewComponent.prototype.exportData = function (format, optionsValuesToOverride) {
        var _this = this;
        if (this.dataViewConfig && this.dataViewConfig.showModalOnExport === false) {
            this.isTableExporting = this.dataViewConfig.showBusyOnExport && true;
            this.dataViewModel.exportData(format || DEFAULT_EXPORT_FORMAT, null, optionsValuesToOverride).then(function (response) {
                _this.isTableExporting = false;
                _this.changeDetectionRef.markForCheck();
                if (isMachineExportResponse(response) && response.isPartial) {
                    _this.confirmationService
                        .confirm({
                        iconCssClass: 'color-text-error',
                        title: _this.i18nService.strings.dataview_export_data_partial_result_title,
                        icon: 'Notification',
                        showConfirm: true,
                        cancelText: _this.i18nService.strings.dataview_export_data_partial_result_close_button,
                        text: _this.i18nService.strings.dataview_export_data_partial_result_description,
                    })
                        .then(function (result) {
                        if (result.confirmed) {
                            _this.exportData(format, { toDate: response.minIncludedEventTime });
                        }
                    });
                }
            }, function (err) {
                _this.isTableExporting = false;
                _this.changeDetectionRef.markForCheck();
                _this.appInsightsService.trackException(err);
                _this.errorsDialogService.showError({
                    title: _this.i18nService.strings.dataview_export_data_failed,
                    data: err,
                });
            });
        }
        else {
            var modal_1;
            this.dialogsService
                .showModal(ExportDataSetModalComponent, {
                id: 'export-data-modal',
                title: this.i18nService.strings.dataview_export_data_dialog_title,
                dimensions: new DimensionsModel(280, 274),
            }, {
                totalCount: this.dataSet && this.dataSet.count,
                setExportCount: function (rowCount) {
                    return Promise.resolve(setTimeout(function () {
                        return (_this.isTableExporting = _this.dataViewConfig.showBusyOnExport && true);
                    })).then(function () {
                        return _this.dataViewModel
                            .exportData(format || DEFAULT_EXPORT_FORMAT, rowCount)
                            .then(function () {
                            _this.isTableExporting = false;
                            _this.changeDetectionRef.markForCheck();
                        });
                    });
                },
            })
                .subscribe(function (_modal) {
                modal_1 = _modal;
            });
        }
    };
    DataViewComponent.prototype.setFields = function (fields, setDataViewModel) {
        if (setDataViewModel === void 0) { setDataViewModel = true; }
        if (setDataViewModel)
            this.fields = fields;
        else {
            this._fields = fields;
            this.dataViewModel && this.dataViewModel.setFields(fields);
        }
    };
    DataViewComponent.prototype.trackById = function (index, item) {
        return item.id;
    };
    DataViewComponent.prototype.setUserPreferences = function () {
        var params = this.getUrlParams();
        var dataViewModelOptions = this.dataViewModel
            ? this.dataViewModel.getNonDefaultDataSetOptions()
            : {};
        var userPreferences = {};
        if (this.columnWidths)
            userPreferences.columnWidths = this.columnWidths;
        if (this.persistPageSize) {
            var pageSizeParam = params.get('page_size');
            if (pageSizeParam || dataViewModelOptions.page_size) {
                userPreferences.pageSize = pageSizeParam
                    ? parseInt(pageSizeParam)
                    : dataViewModelOptions.page_size;
            }
        }
        var fieldsToUpdate = params.get('fields') || dataViewModelOptions.fields;
        if (fieldsToUpdate) {
            userPreferences.visibleFields = fieldsToUpdate.split(',');
        }
        if (this.persistFilters) {
            var filtersToUpdate = FiltersService.filtersQueryParamToSerializedFilters(params.get('filters'));
            if (isEmpty(filtersToUpdate))
                filtersToUpdate =
                    this.dataViewModel &&
                        this.dataViewModel.filtersState &&
                        this.dataViewModel.filtersState.serialized;
            if (filtersToUpdate) {
                userPreferences.filters = JSON.stringify(filtersToUpdate);
            }
        }
        this.savedUserPreferences = this.preferencesService.getPreference(this.userPreferencesId) || {};
        this.preferencesService.setPreference(this.userPreferencesId, userPreferences);
    };
    DataViewComponent.prototype.getLoadNextResultsUrl = function () {
        return this._loadNextResultsUrl;
    };
    DataViewComponent.prototype.setLoadNextResultsUrl = function (url) {
        this._loadNextResultsUrl = url;
    };
    DataViewComponent.prototype.getLoadPreviousResultsUrl = function () {
        return this._loadPreviousResultsUrl;
    };
    DataViewComponent.prototype.setLoadPreviousResultsUrl = function (url) {
        this._loadPreviousResultsUrl = url;
    };
    DataViewComponent.prototype.getPageSizeLabel = function (value) {
        return this.i18nService.get('dataview_page_size_label', { count: value });
    };
    DataViewComponent.prototype.setModalWidth = function (panels) {
        if (panels) {
            this.modalsWidth = panels.reduce(function (maxWidth, panel) {
                return Math.max(maxWidth, panel.isModal ? PanelService.getPanelTypeWidth(panel.type) : 0);
            }, 0);
        }
        else
            this.modalsWidth = 0;
    };
    DataViewComponent.prototype.itemClick = function (item, previous, next) {
        this.onItemClick.emit({
            item: item,
            previous: previous,
            next: next,
        });
        if (this.selectOnItemClick || this.entityType)
            this.selectItems([item]);
    };
    DataViewComponent.prototype.showEntityPanel = function ($event) {
        var _this = this;
        var isSingleItem = $event.items.length === 1;
        var panelSettings = isSingleItem
            ? __assign({ previous: {
                    onClick: function () { return _this.selectItems([$event.previous]); },
                    isDisabled: !$event.previous,
                }, next: {
                    onClick: function () { return _this.selectItems([$event.next]); },
                    isDisabled: !$event.next,
                } }, this.entitySidePanelSettings) : null;
        var panelOptions = __assign({}, this.fixedOptions, this.entityPanelOptions);
        this.trackEvent("OpenEntityPanel_" + this.entityType.entity.singularName, TrackingEventType.SidePaneToggleButton);
        this.entityPanelsService.showEntities(this.entityType.entity, $event.items, panelOptions, panelSettings, this.id);
    };
    DataViewComponent.prototype.updateHeaders = function () {
        if (this._dataTableComponent) {
            this._dataTableComponent.updateHeaderCells();
        }
    };
    DataViewComponent.prototype.triggerItemClick = function (item) {
        if (this._dataTableComponent) {
            this._dataTableComponent.triggerItemClick(item);
        }
    };
    DataViewComponent.prototype.onGroupExpand = function ($event) {
        if (this.selectOnItemClick)
            this.selectItems($event.children.concat((this.selectedItems || []), [$event.group]));
        this.groupItems.set($event.group, $event.children);
        this.groupExpand.emit($event);
        this.trackEvent('ExpandItemGroupInResults', TrackingEventType.Toggle);
    };
    DataViewComponent.prototype.onPageSizeChange = function (pageSize) {
        this.dataViewModel.setPageSize(pageSize);
        this.trackEvent('DataviewPageSizeChanged', TrackingEventType.Button, pageSize);
    };
    DataViewComponent.prototype.onPageNumberChange = function (pageNumber) {
        this.dataViewModel.setPage(pageNumber);
        this.trackEvent('DataviewPageNumberChanged', TrackingEventType.Button, pageNumber);
    };
    DataViewComponent.prototype.onColumnsChange = function (fieldIds) {
        var _this = this;
        this.dataViewModel.setVisibleFields(fieldIds);
        var resultsCount = (this.dataSet && this.dataSet.items && this.dataSet.items.length) || 0;
        setTimeout(function () {
            return _this.liveAnnouncer.announce(!resultsCount
                ? _this.emptyDataMessage ||
                    _this.i18nService.get('dataview_empty_data_message', {
                        itemType: _this.itemNamePluralLowercase,
                    })
                : _this.loadingMessageText
                    ? _this.loadingMessageText
                    : _this.i18nService.get("dataview_loadingComplete", {
                        entityName: _this.itemNamePluralLowercase,
                    }), 'polite');
        }, 0);
        this.trackEvent('DataviewVisibleColumnsChanged', TrackingEventType.Selection, fieldIds);
    };
    DataViewComponent.prototype.loadNextResults = function () {
        return __awaiter(this, void 0, void 0, function () {
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        // When loading next results, we need to keep the last "previous" url
                        // (because new dataset returns with a new "previous" url that is related to the last batch)
                        this.measurePerformance &&
                            sccHostService.perf.createPerformanceSession('load-dataview-data', 'dataview-load-next-results');
                        this._shouldKeepPreviousResultsUrl = true;
                        return [4 /*yield*/, this.dataViewModel.loadNextData()];
                    case 1:
                        _a.sent();
                        this._shouldKeepPreviousResultsUrl = false;
                        this.trackEvent('DataviewLoadNextResults', TrackingEventType.Action);
                        return [2 /*return*/];
                }
            });
        });
    };
    DataViewComponent.prototype.loadPreviousResults = function () {
        return __awaiter(this, void 0, void 0, function () {
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        // When loading previous results, we need to keep the last "next" url
                        // (because new dataset returns with a new "next" url that is related to the last batch)
                        this.measurePerformance &&
                            sccHostService.perf.createPerformanceSession('load-dataview-data', 'dataview-load-previous-results');
                        this._shouldKeepNextResultsUrl = true;
                        return [4 /*yield*/, this.dataViewModel.loadPreviousData()];
                    case 1:
                        _a.sent();
                        if (this._dataTableComponent) {
                            this._dataTableComponent.onItemsAddedOnTop();
                        }
                        this._shouldKeepNextResultsUrl = false;
                        this.onItemsAddedOnTop.emit(this.dataSet);
                        this.trackEvent('DataviewLoadPreviousResults', TrackingEventType.Action);
                        return [2 /*return*/];
                }
            });
        });
    };
    DataViewComponent.prototype.onResizeColumn = function ($event) {
        var column = $event.column, insightsEvent = __rest($event, ["column"]);
        this.appInsightsService.trackEvent('dataViewColumnResize', __assign({}, insightsEvent, { column: column.id, dataviewId: this.id }));
        this.columnWidths = $event.columnsWidths;
        this.setUserPreferences();
    };
    DataViewComponent.prototype.trackEvent = function (eventId, eventType, value) {
        this.appInsightsService.track({
            id: eventId,
            component: this.id + "_DataView",
            type: eventType,
            componentType: 'DataView',
            value: value,
        });
    };
    DataViewComponent.prototype.isSameItemsById = function (items1, items2) {
        var _this = this;
        if (!items1 || !items2 || items1.length !== items2.length)
            return false;
        return !differenceBy(items1, items2, function (item) { return item[_this.itemUniqueKey]; }).length;
    };
    DataViewComponent.prototype.setDataViewOptionsFromLocation = function (params) {
        var _a;
        if (params)
            this._latestQueryParams = params;
        else
            params = this._latestQueryParams || {};
        var userPreferences = this.getUserPreferencesForDataView() || {};
        var pagingOptions = this.allowPaging !== false
            ? {
                page_size: params.page_size
                    ? parseInt(params.page_size, 10)
                    : this.persistPageSize
                        ? userPreferences.pageSize
                        : DEFAULT_PAGE_SIZE,
                page: params.page ? parseInt(params.page, 10) : 1,
            }
            : {};
        var searchParamName = (this.dataViewConfig && this.dataViewConfig.searchParamName) || DEFAULT_SEARCH_PARAM_NAME;
        var searchParam = params[searchParamName];
        var dataViewOptions = __assign((_a = { ordering: params.ordering }, _a[searchParamName] = searchParam, _a.filters = params.filters, _a.fields = params.fields || (userPreferences.visibleFields && userPreferences.visibleFields.join(',')), _a), pagingOptions);
        if (this.dataViewModel)
            return this.dataViewModel.setOptions(dataViewOptions);
        else {
            this._dataViewOptionsToSet = dataViewOptions;
            return true;
        }
    };
    DataViewComponent.prototype.onDataViewSettingsChange = function () {
        return __awaiter(this, void 0, void 0, function () {
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        this.measurePerformance &&
                            sccHostService.perf.createPerformanceSession('load-dataview-data', 'dataview-settings-changed');
                        this.setViewModelOptions();
                        return [4 /*yield*/, this.setUrlParams()];
                    case 1:
                        _a.sent();
                        this.changeDetectionRef.markForCheck();
                        setTimeout(this.setDataTableGetGroupedItems.bind(this), 0);
                        return [2 /*return*/];
                }
            });
        });
    };
    DataViewComponent.prototype.updateUrlParams = function (params) {
        this.router.navigate([], { relativeTo: this.route, queryParams: params });
    };
    DataViewComponent.prototype.setUrlParams = function () {
        return __awaiter(this, void 0, void 0, function () {
            var search, queryParams;
            return __generator(this, function (_a) {
                search = this.dataViewModel.getNonDefaultDataSetOptions();
                queryParams = clone(this.route.snapshot.queryParams);
                Object.assign(queryParams, search);
                if (this.navigateOnChange &&
                    !isEqualWith(search, this._latestQueryParams, function (val1, val2) { return val1 == val2; }))
                    return [2 /*return*/, this.router.navigate([], { relativeTo: this.route, queryParams: queryParams })];
                return [2 /*return*/];
            });
        });
    };
    DataViewComponent.prototype.getUserPreferencesForDataView = function () {
        return this.preferencesService.getPreference(this.userPreferencesId) || {};
    };
    DataViewComponent.prototype.setDataTableGetGroupedItems = function () {
        return __awaiter(this, void 0, void 0, function () {
            var options_1, _a, _b;
            var _this = this;
            return __generator(this, function (_c) {
                switch (_c.label) {
                    case 0:
                        if (!this.getGroupItems) return [3 /*break*/, 3];
                        _a = this.dataViewModel;
                        if (!_a) return [3 /*break*/, 2];
                        _b = omit;
                        return [4 /*yield*/, this.dataViewModel.getDataSetOptions()];
                    case 1:
                        _a = _b.apply(void 0, [_c.sent(), [
                                'page_size',
                                'page',
                                'ordering',
                                'fields',
                            ]]);
                        _c.label = 2;
                    case 2:
                        options_1 = _a;
                        this.dataTableGetGroupedItems = function (group) { return _this.getGroupItems(group, options_1); };
                        return [3 /*break*/, 4];
                    case 3:
                        this.dataTableGetGroupedItems = null;
                        _c.label = 4;
                    case 4: return [2 /*return*/];
                }
            });
        });
    };
    DataViewComponent.prototype.setFiltersStateForEmptyUrl = function (innerRouteFilterParams) {
        var _this = this;
        var filtersToSet;
        if (this.persistFilters) {
            var userPreferences = this.getUserPreferencesForDataView();
            filtersToSet =
                userPreferences &&
                    userPreferences.filters &&
                    FiltersService.getFiltersQueryParam(JSON.parse(userPreferences.filters));
        }
        filtersToSet = filtersToSet || FiltersService.getFiltersQueryParam(this.defaultQueryFilters);
        if (filtersToSet || innerRouteFilterParams) { // If filters were saved in preferences, or passed via input, or passed via url innerRoute
            this.route.queryParams.pipe(first()).subscribe(function (params) {
                _this.updateUrlParams(__assign({}, params, (filtersToSet ? { filters: filtersToSet } : {})));
            });
        }
    };
    DataViewComponent.prototype.resetVisibleFields = function (announce) {
        if (announce === void 0) { announce = false; }
        if (announce)
            this.liveAnnouncer.announce(this.i18nService.get('common.resetColumnsComplete'), 'assertive', 300);
        this.dataViewModel.resetVisibleFields();
    };
    DataViewComponent.prototype.onDataTableRenderComplete = function () {
        this.onTableRenderComplete.emit();
        var perf = this.measurePerformance && sccHostService.perf.getPerformanceSessionById('load-dataview-data');
        perf && sccHostService.perf.endPerfSession(perf);
    };
    return DataViewComponent;
}());
export { DataViewComponent };
