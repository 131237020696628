import { Entity, EntityField, EntityGetMethod, EntityModelBase } from '@microsoft/paris';
import { SuppressionRuleHistoryItemType } from './suppression-rule-history-item-type.entity';
import { WcdPortalParisConfig } from '../../paris-config.interface';

@Entity({
	singularName: 'Suppression Rule History Item',
	pluralName: 'Suppression Rule History Items',
	endpoint: 'AuditHistory',
	fixedData: { entityType: 'SuppressionRule', pageIndex: 1, pageSize: 100 },
	allItemsEndpointTrailingSlash: false,
	baseUrl: (config: WcdPortalParisConfig) => config.data.serviceUrls.threatIntel,
	supportedEntityGetMethods: [EntityGetMethod.query],
})
export class SuppressionRuleHistoryItem extends EntityModelBase {
	@EntityField({ data: 'AuditId' })
    // @ts-ignore shared between scc (useDefineForClassFields) and the old portal
	id: string;

	@EntityField({ data: 'Type' })
	type: SuppressionRuleHistoryItemType;

	@EntityField({ data: 'Timestamp' })
	timestamp: Date;

	@EntityField({ data: 'CreatedBy' })
	userName: string;

	@EntityField({ data: 'NewValue' })
	newValue: any;

	get message(): string {
		return this.type ? this.type.getMessage(this) : 'Comment';
	}
}
