var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
import { EntityRelationship, RelationshipType } from '@microsoft/paris';
import { EvidenceAlertsSummary, Mailbox } from '@wcd/domain';
var ɵ0 = function (mailbox) { return ({ id: mailbox.id }); }, ɵ1 = function (_, query) { return "evidence/mailbox/" + query.where['id'] + "/alerts"; }, ɵ2 = function (config) { return config.data.serviceUrls.threatIntel; };
var MailboxAlertsRelationship = /** @class */ (function () {
    function MailboxAlertsRelationship() {
    }
    MailboxAlertsRelationship = __decorate([
        EntityRelationship({
            sourceEntity: Mailbox,
            dataEntity: EvidenceAlertsSummary,
            allowedTypes: [RelationshipType.OneToOne],
            getRelationshipData: ɵ0,
            fixedData: { lookBackInDays: 180 },
            endpoint: ɵ1,
            baseUrl: ɵ2,
        })
    ], MailboxAlertsRelationship);
    return MailboxAlertsRelationship;
}());
export { MailboxAlertsRelationship };
export { ɵ0, ɵ1, ɵ2 };
