import { ApiCall, ApiCallModel, HttpOptions, ParisConfig } from '@microsoft/paris';
import * as IsolationCreateModels from './isolation-requests.models';

export type IsolateRawApiRequestBody = IsolationCreateModels.IsolationRawApiRequestBody<'Isolate'>;
export type IsolateRawApiResponse = IsolationCreateModels.IsolationRawApiResponse;

export type IsolateParameters = IsolationCreateModels.IsolationParameters;
export type IsolateResponse = IsolationCreateModels.IsolationResponse;

@ApiCall({
	name: 'Isolate machine',
	endpoint: 'requests/create',
	method: 'POST',
	baseUrl: (config: ParisConfig) => config.data.serviceUrls.userRequests,
	parseQuery: ({
		machine,
		comment,
		isolationType,
	}: IsolateParameters): HttpOptions<IsolateRawApiRequestBody> => ({
		data: {
			MachineId: machine.machineId,
			RequestorComment: comment,
			OsPlatform: machine.os.platform.id,
			SenseClientVersion: machine.senseClientVersion,
			Type: 'IsolationRequest',
			IsolationType: isolationType,
			Action: 'Isolate',
		},
	}),
	parseData: (rawData: IsolateRawApiResponse): IsolateResponse => ({
		machineId: rawData.MachineId,
		requestGuid: rawData.RequestGuid,
	}),
})
export class MachineIsolateApiCall extends ApiCallModel<IsolateResponse, IsolateParameters> {}
