var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (b.hasOwnProperty(p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
import { EntityRelatedWidgetBase } from '../entity-related-widget.base';
import { filter } from 'rxjs/operators';
var EntityPrevalenceWidgetBase = /** @class */ (function (_super) {
    __extends(EntityPrevalenceWidgetBase, _super);
    function EntityPrevalenceWidgetBase(paris, activatedEntity, reportsService, i18nService, timeRangeService) {
        var _this = _super.call(this, paris, activatedEntity, reportsService) || this;
        _this.i18nService = i18nService;
        _this.timeRangeService = timeRangeService;
        _this.currentOrgPrevDateRange = (_this.timeRangeService.pick(["month" /* month */])[0]);
        _this.pollingErrorSubscription = _this.pollingError$
            .pipe(filter(function (isPollingError) { return isPollingError; }))
            .subscribe(function (_) {
            _this.currentOrgPrevDateRange = _this.previousOrgPrevDateRange;
        });
        return _this;
    }
    EntityPrevalenceWidgetBase.prototype.ngOnDestroy = function () {
        this.pollingErrorSubscription.unsubscribe();
    };
    Object.defineProperty(EntityPrevalenceWidgetBase.prototype, "entityRelatedWidgetConfig", {
        get: function () {
            var _this = this;
            return {
                id: this.id,
                name: this.i18nService.get('reports.widgets.prevalence.title'),
                entityType: this.entityType,
                entityRelationshipType: this.entityStatisticsRelationship,
                getDataQuery: function () { return ({
                    where: {
                        lookingBackInDays: _this.currentOrgPrevDateRange.value,
                    },
                }); },
            };
        },
        enumerable: true,
        configurable: true
    });
    EntityPrevalenceWidgetBase.prototype.onTimeRangeChange = function (timeRangeValue) {
        if (this.currentOrgPrevDateRange !== timeRangeValue) {
            this.previousOrgPrevDateRange = this.currentOrgPrevDateRange;
            this.currentOrgPrevDateRange = timeRangeValue;
            this.reload();
        }
    };
    return EntityPrevalenceWidgetBase;
}(EntityRelatedWidgetBase));
export { EntityPrevalenceWidgetBase };
