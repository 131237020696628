var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : new P(function (resolve) { resolve(result.value); }).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g;
    return g = { next: verb(0), "throw": verb(1), "return": verb(2) }, typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (_) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
import { ChangeDetectorRef, OnInit, OnDestroy, EventEmitter, } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { Paris, RelationshipRepository, } from '@microsoft/paris';
import { DateRangeModel, FULL_DATE_WITH_MILLISECONDS_FORMAT, TzDateService } from '@wcd/localization';
import { cloneDeep, find, findIndex, minBy, omit } from 'lodash-es';
import { BehaviorSubject, of } from 'rxjs';
import { map, take } from 'rxjs/operators';
import { Alert, CyberEvent } from '@wcd/domain';
import { isCustomTimeRangeValue } from '@wcd/date-time-picker';
import { Lazy } from '@wcd/utils';
import { GlobalEntityTypesService } from '../../../global_entities/services/global-entity-types.service';
import { AppContextService, PreferencesService } from '@wcd/config';
import { AppInsightsService } from '../../../insights/services/app-insights.service';
import { I18nService } from '@wcd/i18n';
import { CyberEventsFieldsService } from '../../../@entities/cyber_events/services/cyber-events.fields';
import { TimeRangesService } from '../../../shared/services/time-ranges.service';
import { CyberEventsUtilsService } from '../../../@entities/cyber_events/services/cyber-events-utils.service';
import { DialogsService } from '../../../dialogs/services/dialogs.service';
import { SeverityTypeColorService } from '../../../shared/services/severity-type-color.service';
import { Feature, FeaturesService } from '@wcd/config';
import { DataviewActionTypes, } from '../../../dataviews/components/actions-components/dataview-actions.model';
import { v4 as uuid4 } from 'uuid';
import { ActivatedEntity } from '../../../global_entities/services/activated-entity.service';
import { FabricIconNames } from '@wcd/scc-common';
import { SeverityType } from '@wcd/domain';
export var TIME_RANGE_DEFAULT_PREFERENCE_ID = 'machine_events_time_range_default';
var ENTITY_TIMELINE_FIELDS_RESET = 'entity_timeline_fields_reset';
var DATAVIEW_FIELDS_PREFERENCE_KEY = 'dataView_Events_dataview';
var HIGHLIGHT_ALERT_ACTION_ID = 'highlightedAlert', EVENTS_HOT_STORAGE_IN_DAYS = 30, MAX_RANGE_IN_DAYS = 30, DEFAULT_ALERT_RANGE_IN_DAYS = 7, COLD_STORAGE_MAX_RANGE_IN_DAYS = 7, EXPORT_MAX_RANGE_IN_DAYS = 7, EVENTS_PAGE_SIZE = 200;
var EntityEventsTimelineComponent = /** @class */ (function () {
    function EntityEventsTimelineComponent(paris, globalEntityTypesService, router, route, preferencesService, appInsightsService, i18nService, cyberEventsFieldsService, cyberEventsUtilsService, timeRangesService, dialogsService, tzDateService, changeDetectionRef, severityTypeColorService, featuresService, activatedEntity, appContext) {
        var _this = this;
        this.paris = paris;
        this.globalEntityTypesService = globalEntityTypesService;
        this.router = router;
        this.route = route;
        this.preferencesService = preferencesService;
        this.appInsightsService = appInsightsService;
        this.i18nService = i18nService;
        this.cyberEventsFieldsService = cyberEventsFieldsService;
        this.cyberEventsUtilsService = cyberEventsUtilsService;
        this.timeRangesService = timeRangesService;
        this.dialogsService = dialogsService;
        this.tzDateService = tzDateService;
        this.changeDetectionRef = changeDetectionRef;
        this.severityTypeColorService = severityTypeColorService;
        this.featuresService = featuresService;
        this.activatedEntity = activatedEntity;
        this.appContext = appContext;
        this.isCustomTimeRangeValue = isCustomTimeRangeValue;
        this.newTimebarFeatureName = Feature.NewTimebar;
        this.legendStyles = {
            low: { root: { color: this.severityTypeColorService.colorsMap.get(SeverityType.Low).raw } },
            medium: { root: { color: this.severityTypeColorService.colorsMap.get(SeverityType.Medium).raw } },
            high: { root: { color: this.severityTypeColorService.colorsMap.get(SeverityType.High).raw } },
        };
        this.removePaddingRight = false;
        this.isSmallPadding = false;
        this.loadMoreOnEmpty = false;
        this.toggleFullScreenEnabled = false;
        this.measurePerformance = false;
        /**
         * Whether or not to display the column for the event's machine in the dataview.
         */
        this.showMachineColumn = true;
        /**
         *  Whether or not to enable search on timeline
         */
        this.searchEnabled = true;
        /**
         *  Whether or not to disable the export button while exporting
         */
        this.showBusyOnExport = false;
        /**
         *  Whether or not to support partial responses
         */
        this.supportPartialResponse = false;
        /**
         *  Whether or not to enable loading previous items on table top
         */
        this.allowLoadItemsOnTableTop = false;
        /**
         * Whether or not to enable infinite scrolling
         */
        this.infiniteScrolling = true;
        this.allowFilters = true;
        /**
         * Whether or not to show the time bar - allows setting date range and filter timeline by alerts
         */
        this.showTimebar = true;
        /**
         * Whether or not to show the expander button on top of the time bar
         */
        this.enableTimebarExpander = false;
        this.className = 'no-left-padding';
        this.padLeft = true;
        this.customFlags = [];
        this.showLegend = false;
        /**
         * changes the order of the command bar buttons when in asset page
         */
        this.assetCommandBar = false;
        this.onTableRenderComplete = new EventEmitter();
        this.allowTimeRangeSelect = true;
        this.flags = [];
        this.alertsItems$ = of([]);
        this.permanentHighlightedItems$ = new BehaviorSubject([]);
        this._timeRanges = new Lazy(function () {
            return _this.timeRangesService.pick([
                "day" /* day */,
                "week" /* week */,
                "month" /* month */,
                "custom" /* custom */,
            ]);
        });
        this.useVerboseTelemetryActionsFilter = false;
        this.isFullScreen = false;
        this.updateFlagsInTimebarOnFlagging = function (newFlagOperation) {
            var flagsUpdated = false;
            var indexOfExistingFlag = findIndex(_this.flags, function (flag) {
                return flag.actionTime.getTime() === newFlagOperation.actionTime.getTime() &&
                    (flag.reportId == newFlagOperation.reportId || flag.alertId == newFlagOperation.alertId);
            });
            if (newFlagOperation.isFlagged && indexOfExistingFlag == -1) {
                // adding missing flag symbol to timebar
                _this.flags.push(newFlagOperation);
                flagsUpdated = true;
            }
            if (!newFlagOperation.isFlagged && indexOfExistingFlag != -1) {
                // user unmarked event - remove from timebar
                _this.flags.splice(indexOfExistingFlag, 1);
                flagsUpdated = true;
            }
            if (flagsUpdated) {
                // reassign flags to allow update with onPush change detection strategy
                _this.flags = _this.flags.slice();
                _this.changeDetectionRef.markForCheck();
            }
        };
        this.onFiltersChange = function (filterChange) {
            var newUseVerboseTelemetryActionsFilterValue = !!(filterChange.selection && filterChange.selection.verboseTelemetryActions);
            if (newUseVerboseTelemetryActionsFilterValue !== _this.useVerboseTelemetryActionsFilter) {
                _this.useVerboseTelemetryActionsFilter = newUseVerboseTelemetryActionsFilterValue;
                _this.setLoadingText();
            }
        };
        this.getTimelineDateRange = function () {
            var from, to;
            if (isCustomTimeRangeValue(_this.currentRange)) {
                to = _this.customDateRange.to;
                from = _this.customDateRange.from;
            }
            else {
                to = new Date();
                from = moment(to)
                    .subtract(+_this.currentRange.value, 'days')
                    .toDate();
            }
            var fromValidatedDate = _this.validateFromDateRangeLimit(from, to);
            return { from: fromValidatedDate, to: to };
        };
        this.setLoadingText = function (options) {
            if (_this.useVerboseTelemetryActionsFilter) {
                _this.loadingText = _this.i18nService.get('events.loadingVerboseData');
                return;
            }
            var timelineDateRange = options && options.timelineDateRange;
            if (!timelineDateRange) {
                timelineDateRange = _this.getTimelineDateRange();
            }
            _this.loadingText = _this.i18nService.get("events.loading" + (timelineDateRange.from < _this.hotStorageLimitDate ? 'OnColdStorage' : ''));
            _this.stillLoadingText = _this.i18nService.get("events.loading" + (timelineDateRange.from < _this.hotStorageLimitDate ? 'OnColdStorage' : 'Still'));
        };
        this.cyberEventEntityType = globalEntityTypesService.getEntityType(CyberEvent);
        this.onData = this.onData.bind(this);
        this.validateRange = this.validateRange.bind(this);
        this.getAlertsTooltipTemplate = this.getAlertsTooltipTemplate.bind(this);
        this.exportTooltipText = this.i18nService.get('events.export.tooltip');
    }
    Object.defineProperty(EntityEventsTimelineComponent.prototype, "showLoadTopItemsButton", {
        get: function () {
            return (this.allowLoadItemsOnTableTop && this.currentRange && isCustomTimeRangeValue(this.currentRange));
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(EntityEventsTimelineComponent.prototype, "dataViewId", {
        get: function () {
            if (!this._dataViewId && this.entity) {
                var entityRepo = this.paris.getRepository(this.entity.constructor);
                this._dataViewId = entityRepo.entity.singularName.toLowerCase() + "-events";
            }
            return this._dataViewId;
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(EntityEventsTimelineComponent.prototype, "dataViewConfig", {
        get: function () {
            return this._dataViewConfig;
        },
        set: function (value) {
            this._originalDataViewConfig = cloneDeep(value);
            this._dataViewConfig = this.getDataViewConfig();
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(EntityEventsTimelineComponent.prototype, "currentRange", {
        get: function () {
            return this._currentRange;
        },
        set: function (range) {
            this._currentRange = range;
            if (!isCustomTimeRangeValue(range))
                this.customDateRange = DateRangeModel.fromDays(this.tzDateService, range.value, undefined, undefined, false);
            this.currentTimeBarRange = {
                to: this.customDateRange.to,
                from: this.customDateRange.from,
            };
        },
        enumerable: true,
        configurable: true
    });
    EntityEventsTimelineComponent.prototype.updateDateRangeConfigOnRangeChange = function () {
        if (this.customRangeConfig) {
            this.customRangeConfig.currentRange = this.currentRange;
            this.customRangeConfig.customDateRange = this.customDateRange;
        }
    };
    Object.defineProperty(EntityEventsTimelineComponent.prototype, "timeRanges", {
        get: function () {
            return this._timeRanges.value;
        },
        enumerable: true,
        configurable: true
    });
    EntityEventsTimelineComponent.prototype.ngOnChanges = function () {
        var _this = this;
        if (this.customFlags.length) {
            this.flags = this.flags.concat(this.customFlags);
            this.flags.forEach(function (flag) { return _this.updateFlagsInTimebarOnFlagging(flag); });
        }
    };
    EntityEventsTimelineComponent.prototype.ngOnDestroy = function () {
        this.markedEventsUpdateSubscription && this.markedEventsUpdateSubscription.unsubscribe();
    };
    EntityEventsTimelineComponent.prototype.getCustomRangeConfig = function () {
        return {
            currentRange: __assign({}, this.currentRange),
            onNewRangeSelectedCallback: this.onNewRangeSelectedCallback.bind(this),
            preferenceId: TIME_RANGE_DEFAULT_PREFERENCE_ID,
            timeRanges: this.timeRanges,
            customDateRange: this.customDateRange,
            dataViewId: this.dataViewId,
            actionType: DataviewActionTypes.CustomRange,
            validateRange: this.validateRange.bind(this),
            dateFormat: 'short',
        };
    };
    /**
     * Restore the machine id column to be exposed by default.
     * This is a bug fix for users who were exposed to the upgraded entity pages at their initial versions
     * where the machine id column was not visible by default and their local storage keeps it hidden for them.
     * Can be removed 1 month after the old entity pages (file / ip / url) were disabled to all slices
     */
    EntityEventsTimelineComponent.prototype.resetCustomFields = function () {
        var entityTimelineFieldsReset = this.preferencesService.getPreference(ENTITY_TIMELINE_FIELDS_RESET);
        var entityTimelineCustomizedColumnsPref = this.preferencesService.getPreference(DATAVIEW_FIELDS_PREFERENCE_KEY);
        if (!entityTimelineFieldsReset &&
            entityTimelineCustomizedColumnsPref &&
            entityTimelineCustomizedColumnsPref.visibleFields &&
            entityTimelineCustomizedColumnsPref.visibleFields.indexOf('machine') < 0) {
            this.preferencesService.setPreference(ENTITY_TIMELINE_FIELDS_RESET, true);
            entityTimelineCustomizedColumnsPref.visibleFields.push('machine');
            this.preferencesService.setPreference(DATAVIEW_FIELDS_PREFERENCE_KEY, entityTimelineCustomizedColumnsPref);
        }
    };
    EntityEventsTimelineComponent.prototype.ngOnInit = function () {
        var _this = this;
        this.resetCustomFields();
        this.fields = this.shownFields
            ? this.cyberEventsFieldsService.fields.filter(function (field) { return _this.shownFields.includes(field.id); })
            : this.cyberEventsFieldsService.fields;
        if (!this.repository)
            this.repository = this.paris.getRepository(CyberEvent);
        if (this.entityAlertsRepository) {
            this.entityAlertsRepository.sourceItem = this.entity;
            var alertsData$ = this.entityAlertsRepository.query();
            this.alerts$ = this.entityAlertsRepository.query().pipe(map(function (result) { return result.items; }));
            this.alertsItems$ = alertsData$.pipe(map(function (result) {
                return result.items
                    .sort(function (a, b) { return b.severity.priority - a.severity.priority; })
                    .map(function (alert) {
                    return {
                        name: alert.name,
                        itemData: alert,
                        date: alert.lastEventTime,
                        color: _this.severityTypeColorService.colorsMap.get(alert.severity.type),
                        getTooltipTemplate: _this.getAlertsTooltipTemplate,
                    };
                });
            }));
        }
        if (this.entityFlagsRepository) {
            this.entityFlagsRepository.sourceItem = this.entity;
            this.entityFlagsRepository
                .query()
                .pipe(take(1), map(function (result) { return result.items; }))
                .subscribe(function (flags) { return (_this.flags = flags.concat(_this.customFlags)); });
            this.markedEventsUpdateSubscription = this.cyberEventsUtilsService
                .getMarkEventOperationObservable()
                .subscribe(this.updateFlagsInTimebarOnFlagging);
        }
        else if (this.customFlags.length) {
            this.flags.concat(this.customFlags);
            this.updateFlagsInTimebarOnFlagging(this.customFlags[0]);
        }
        this.currentRange =
            this.timeRanges.find(function (timeRange) {
                return timeRange.id === _this.preferencesService.getPreference(TIME_RANGE_DEFAULT_PREFERENCE_ID);
            }) || this.timeRanges.find(function (range) { return range.id === "week" /* week */; });
        this.hotStorageLimitDate = new Date();
        this.hotStorageLimitDate.setDate(this.hotStorageLimitDate.getDate() - EVENTS_HOT_STORAGE_IN_DAYS);
        this.route.queryParams.subscribe(function (params) {
            var locationFixedRange = params.range, locationCustomRange = params.from || params.to;
            var locationRangeChange = false;
            _this.setSelectedAlert(params.alert);
            if (locationFixedRange) {
                var foundFixedRange = find(_this.timeRanges, function (range) { return range.id === locationFixedRange; });
                locationRangeChange = foundFixedRange !== _this.currentRange;
                if (locationRangeChange && foundFixedRange) {
                    _this.currentRange = foundFixedRange;
                    _this.updateDateRangeConfigOnRangeChange();
                }
            }
            if (locationCustomRange) {
                var customRangeType = _this.timeRanges.find(isCustomTimeRangeValue);
                locationRangeChange =
                    customRangeType !== _this.currentRange ||
                        (_this.customDateRange &&
                            (_this.customDateRange.from !== params.from || _this.customDateRange.to !== params.to));
                if (locationRangeChange && customRangeType) {
                    _this.customDateRange = new DateRangeModel(_this.tzDateService, new Date(params.from), new Date(params.to), false);
                    _this.currentRange = customRangeType;
                    _this.updateDateRangeConfigOnRangeChange();
                }
            }
            if (locationRangeChange && !_this._skipReloadOnLocationChange) {
                _this.setFixedOptions();
                _this.dataViewConfig = _this.getDataViewConfig();
            }
            _this._skipReloadOnLocationChange = false;
        });
        this.setFixedOptions();
        this.dataViewConfig = this.getDataViewConfig();
        this.customRangeConfig = this.getCustomRangeConfig();
        this.commandBarRight = this.getFullScreenAction().concat([this.customRangeConfig]);
        this.enableTimebarExpander =
            this.enableTimebarExpander && this.featuresService.isEnabled(Feature.TimelineFullScreenToggling);
    };
    EntityEventsTimelineComponent.prototype.onData = function (dataSet) {
        var _this = this;
        var highlightedItems = this.selectedAlertId
            ? dataSet.items.filter(function (event) {
                return (event.relatedAlert && event.relatedAlert.id === _this.selectedAlertId) ||
                    (event.alertIds && event.alertIds.some(function (id) { return id === _this.selectedAlertId; }));
            })
            : [];
        if (this.permanentHighlightedItems$.value !== highlightedItems) {
            this.permanentHighlightedItems$.next(highlightedItems);
            this.changeDetectionRef.markForCheck();
        }
        var supportedActionTypes = dataSet.items
            .filter(function (event) { return event.actionType; })
            .reduce(function (set, event) {
            var groupKey = event.isCyberData ? event.actionType.typeName + event.cyberActionType.typeName : event.actionType.typeName;
            set[groupKey] = set.hasOwnProperty(groupKey) ? set[groupKey] + 1 : 1;
            return set;
        }, {});
        var unsupportedActionTypes = dataSet.items
            .filter(function (event) { return !event.actionType; })
            .reduce(function (set, event) {
            var groupKey = event.rawActionType;
            set[groupKey] = set.hasOwnProperty(groupKey) ? set[groupKey] + 1 : 1;
            return set;
        }, {});
        if (supportedActionTypes || unsupportedActionTypes) {
            this.appInsightsService.trackEvent("Machine timeline - action types", {
                events: JSON.stringify({ unsupportedActionTypes: unsupportedActionTypes, supportedActionTypes: supportedActionTypes }),
            });
        }
    };
    EntityEventsTimelineComponent.prototype.onItemsAddedOnTop = function (dataChange) {
        if (dataChange.count) {
            var lastItem = dataChange.items[0];
            var newDateRange = new DateRangeModel(this.tzDateService, this.customDateRange.from, lastItem.actionTime, false);
            newDateRange.from = this.validateFromDateRangeLimit(newDateRange.from, newDateRange.to);
            var newRange = this.timeRanges.find(isCustomTimeRangeValue);
            newRange.customRange = this.customDateRange = newDateRange;
            this.currentRange = newRange;
            this._skipReloadOnLocationChange = true;
            this.refreshQueryParams({
                from: this.currentRange.customRange.from.toISOString(),
                to: this.currentRange.customRange.to.toISOString(),
            });
        }
    };
    EntityEventsTimelineComponent.prototype.onNewRangeSelectedCallback = function (currentRange, customDateRange) {
        this.refreshQueryParams({
            page: null,
            range: currentRange && currentRange.id,
            from: customDateRange && customDateRange.from.toISOString(),
            to: customDateRange && customDateRange.to.toISOString(),
        });
        this.setFixedOptions();
    };
    EntityEventsTimelineComponent.prototype.onTimebarSelect = function (timeRange) {
        this.appInsightsService.trackEvent('UsageTrack', {
            ButtonType: 'TimebarPickerRangeSelect',
            Component: this.dataViewId,
        });
        this.refreshQueryParams({
            page: null,
            range: null,
            from: timeRange.from.toISOString(),
            to: timeRange.to.toISOString(),
        });
    };
    EntityEventsTimelineComponent.prototype.onAlertSelected = function (alert) {
        this.appInsightsService.trackEvent('UsageTrack', {
            ButtonType: 'TimebarPickerAlertSelect',
            Component: this.dataViewId,
        });
        // adding extra millisec to the to time to guarantee that tha alert event won't be sliced in the BE.
        var toDate = alert.lastEventTime;
        toDate.setMilliseconds(alert.lastEventTime.getMilliseconds() + 1);
        this.scrollToTime({
            to: toDate,
            alertId: alert.id,
        });
    };
    EntityEventsTimelineComponent.prototype.onFlagSelected = function (flag) {
        this.appInsightsService.trackEvent('TimebarPickerFlagSelect', {
            ButtonType: 'TimebarPickerFlagSelect',
            Component: this.dataViewId,
            SenseMachineId: flag.deviceId,
            ActionType: flag.actionType,
            ActionTimeOfFlag: flag.actionTimeIsoString,
        });
        // adding extra millisec to the to time to guarantee that tha flag event won't be sliced in the BE.
        var toDate = flag.actionTime;
        toDate.setMilliseconds(flag.actionTime.getMilliseconds() + 1);
        this.scrollToTime({
            to: toDate,
        });
    };
    EntityEventsTimelineComponent.prototype.onQuickAction = function (action) {
        if (action.id === HIGHLIGHT_ALERT_ACTION_ID)
            this.unHighlightAlert();
    };
    EntityEventsTimelineComponent.prototype.unHighlightAlert = function () {
        this.setSelectedAlert(null);
        this.permanentHighlightedItems$.next([]);
    };
    EntityEventsTimelineComponent.prototype.scrollToTime = function (timeOptions) {
        var from = new Date(timeOptions.to);
        from.setDate(from.getDate() - DEFAULT_ALERT_RANGE_IN_DAYS);
        this.refreshQueryParams({
            page: null,
            range: null,
            from: from.toISOString(),
            to: timeOptions.to.toISOString(),
            alert: timeOptions.alertId,
        });
    };
    EntityEventsTimelineComponent.prototype.getTopItem = function (items) {
        return minBy(items, function (item) { return item.itemData.severity.priority; });
    };
    EntityEventsTimelineComponent.prototype.getAlertsTooltipTemplate = function (alerts) {
        var _this = this;
        var template = alerts
            .map(function (alert) { return "\n\t\t\t\t\t<div class=\"subtle wcd-margin-xsmall-bottom\">" + _this.tzDateService.format(alert.lastEventTime, FULL_DATE_WITH_MILLISECONDS_FORMAT) + "</div>\n\t\t\t\t\t<div class=\"wcd-margin-bottom\">\n\t\t\t\t\t\t<span class=\"wcd-severity wcd-severity-" + alert.severity.type + "\">" + _this.i18nService.get(alert.severity.nameI18nKey) + "\n\t\t\t\t\t\t</span>\n\t\t\t\t\t\t<span class=\"wcd-margin-left wcd-font-weight-bold\">" + alert.name + "</span>\n\t\t\t\t\t</div>\n\t\t\t\t"; })
            .join('');
        template += "<div>" + this.i18nService.get('events.clickOnAlert') + "</div>";
        return template;
    };
    EntityEventsTimelineComponent.prototype.validateRange = function (range) {
        var momentTo = moment(range.to), momentFrom = moment(range.from), diffInDays = moment.duration(momentTo.diff(momentFrom)).asDays(), rangeIsInColdStorage = range.from < this.hotStorageLimitDate;
        var updatedTo;
        var now = new Date();
        if (momentTo.valueOf() > now.valueOf()) {
            updatedTo = new Date(now);
        }
        if (!updatedTo && (!rangeIsInColdStorage || diffInDays <= COLD_STORAGE_MAX_RANGE_IN_DAYS)) {
            return null;
        }
        else {
            return {
                from: moment
                    .min(moment(this.hotStorageLimitDate), moment(updatedTo || momentTo).subtract(COLD_STORAGE_MAX_RANGE_IN_DAYS, 'days'))
                    .toDate(),
                to: updatedTo || momentTo.toDate(),
            };
        }
    };
    EntityEventsTimelineComponent.prototype.setFixedOptions = function () {
        // Change correlationId after each events timeline configuration change (i.e: filter, search, date picker, etc...)
        CyberEventsUtilsService.correlationId$.next(uuid4());
        var timelineDateRange = this.getTimelineDateRange();
        this.setLoadingText({ timelineDateRange: timelineDateRange });
        this.dataviewFixedOptions = Object.assign(this.getTarget(timelineDateRange), this.fixedOptions);
    };
    EntityEventsTimelineComponent.prototype.getTarget = function (timelineDateRange) {
        var target = __assign({}, this.cyberEventsQueryFixedOptions, { fromDate: timelineDateRange.from.toISOString(), toDate: timelineDateRange.to.toISOString(), pageSize: EVENTS_PAGE_SIZE });
        if (!CyberEventsUtilsService.shouldTimelineUseOneCyber(this.featuresService)) {
            return target;
        }
        return __assign({}, target, CyberEventsUtilsService.getTimelineFlagParams(this.featuresService, this.appContext));
    };
    EntityEventsTimelineComponent.prototype.getDataViewConfig = function () {
        var _this = this;
        if (this._originalDataViewConfig) {
            var dataViewConfig = cloneDeep(this._originalDataViewConfig);
            return dataViewConfig;
        }
        else {
            this._originalDataViewConfig = {
                requireFiltersData: false,
                showBusyOnExport: this.showBusyOnExport,
                supportPartialResponse: this.supportPartialResponse,
                showModalOnExport: false,
                exportResults: function (options) { return __awaiter(_this, void 0, void 0, function () {
                    var updatedOptions, momentTo, confirmModalResult, isMarkedEventsFilterOn, lastHotStorageDate, isDateRangeInHotStorage, maxRangeToExport, newFromDate;
                    return __generator(this, function (_a) {
                        switch (_a.label) {
                            case 0:
                                updatedOptions = Object.assign({}, options, {
                                    fromDate: this.customDateRange.from.toISOString(),
                                    toDate: this.customDateRange.to.toISOString(),
                                });
                                momentTo = moment(updatedOptions.toDate);
                                isMarkedEventsFilterOn = options.markedEventsOnly && options.markedEventsOnly[0] === 'true';
                                lastHotStorageDate = new Date();
                                lastHotStorageDate.setDate(lastHotStorageDate.getDate() - (EVENTS_HOT_STORAGE_IN_DAYS + 1));
                                isDateRangeInHotStorage = this.customDateRange.from.getTime() >= lastHotStorageDate.getTime();
                                maxRangeToExport = isMarkedEventsFilterOn && isDateRangeInHotStorage
                                    ? EVENTS_HOT_STORAGE_IN_DAYS
                                    : EXPORT_MAX_RANGE_IN_DAYS;
                                if (!(momentTo.diff(moment(updatedOptions.fromDate), 'days') > maxRangeToExport)) return [3 /*break*/, 2];
                                newFromDate = momentTo.subtract(maxRangeToExport, 'days').toISOString();
                                updatedOptions = Object.assign({}, updatedOptions, { fromDate: newFromDate });
                                return [4 /*yield*/, this.dialogsService.confirm({
                                        title: this.i18nService.get('events.export.confirm.title'),
                                        text: this.i18nService.get('events.export.confirm.limitDescription', {
                                            from: this.tzDateService.format(updatedOptions.fromDate, 'medium'),
                                            to: this.tzDateService.format(updatedOptions.toDate, 'medium'),
                                        }),
                                        confirmText: this.i18nService.get('events.export.confirm.limitExecute'),
                                    })];
                            case 1:
                                confirmModalResult = _a.sent();
                                _a.label = 2;
                            case 2:
                                if (!confirmModalResult || confirmModalResult.confirmed) {
                                    return [2 /*return*/, this.cyberEventsUtilsService.downloadCsv(__assign({}, omit(updatedOptions, ['page', 'page_size', 'pageSize'])))];
                                }
                                return [2 /*return*/, Promise.resolve()];
                        }
                    });
                }); },
            };
            return this._originalDataViewConfig;
        }
    };
    // returns "from" date after fixing
    EntityEventsTimelineComponent.prototype.validateFromDateRangeLimit = function (from, to) {
        var momentTo = moment(to), momentFrom = moment(from), diffInDays = moment.duration(momentTo.diff(momentFrom)).asDays();
        if (diffInDays > MAX_RANGE_IN_DAYS) {
            // force max days range and fix DST if needed
            return this.checkAndFixDSTOverTheRange(from, to, MAX_RANGE_IN_DAYS);
        }
        else if (from < this.hotStorageLimitDate && diffInDays > COLD_STORAGE_MAX_RANGE_IN_DAYS) {
            // force max days range on coldStorage and fix DST if needed
            return this.checkAndFixDSTOverTheRange(from, to, COLD_STORAGE_MAX_RANGE_IN_DAYS);
        }
        else {
            return momentFrom.toDate();
        }
    };
    // Forces "from" date to be less than a given range, while fixing Daylight Savings Time leap
    EntityEventsTimelineComponent.prototype.checkAndFixDSTOverTheRange = function (from, to, maxDaysAllowed) {
        // force max days range on coldStorage
        var newFrom = moment(to).subtract(maxDaysAllowed, 'days').toDate();
        // fix Daylight savings time differences: add 1 hour if there was a DST change in the range
        if (moment(newFrom).isDST() !== moment(to).isDST()) {
            newFrom = moment(newFrom).add(1, 'hours').toDate();
        }
        return newFrom;
    };
    EntityEventsTimelineComponent.prototype.setSelectedAlert = function (alertId) {
        var _this = this;
        if (alertId) {
            this.selectedAlertId = alertId;
            this.highlightAlertAction = null;
            this.paris.getItemById(Alert, alertId).subscribe(function (alert) {
                _this.setHighlightAlertAction(null, alert);
            }, function (error) {
                _this.setHighlightAlertAction(null);
            });
        }
        else {
            this.selectedAlertId = null;
            this.setHighlightAlertAction(null);
        }
    };
    EntityEventsTimelineComponent.prototype.setHighlightAlertAction = function (alertId, alert) {
        if (alertId || alert) {
            this.highlightAlertAction = {
                id: HIGHLIGHT_ALERT_ACTION_ID,
                itemText: this.i18nService.get('machines.highlightedAlert.title', {
                    alertName: (alert && alert.name) || alert.id,
                }),
                tooltipText: this.i18nService.get('machines.highlightedAlert.tooltip', {
                    alertName: (alert && alert.name) || alert.id,
                }),
            };
            this.changeDetectionRef.markForCheck();
        }
        else {
            if (this.highlightAlertAction) {
                this.highlightAlertAction = null;
                this.refreshQueryParams({
                    alert: null,
                });
            }
        }
    };
    EntityEventsTimelineComponent.prototype.refreshQueryParams = function (params) {
        this.router.navigate(['.'], {
            relativeTo: this.route,
            queryParams: params,
            queryParamsHandling: 'merge',
        });
    };
    EntityEventsTimelineComponent.prototype.onTableRenderCompleteLoaded = function () {
        this.onTableRenderComplete.emit();
    };
    EntityEventsTimelineComponent.prototype.getFullScreenAction = function () {
        var _this = this;
        var getFullScreenButtonText = function () {
            return _this.i18nService.get(_this.isFullScreen ? 'entities.fullScreen.collapse' : 'entities.fullScreen.expand');
        };
        return this.toggleFullScreenEnabled &&
            this.featuresService.isEnabled(Feature.TimelineFullScreenToggling)
            ? [
                {
                    localizedTooltipFn: getFullScreenButtonText,
                    onClickCallback: function () {
                        _this.isFullScreen = !_this.isFullScreen;
                        _this.activatedEntity.fullScreenEntity$.next(_this.isFullScreen);
                    },
                    iconFn: function () {
                        return _this.isFullScreen ? FabricIconNames.BackToWindow : FabricIconNames.FullScreen;
                    },
                    localizedLabelFn: getFullScreenButtonText,
                    actionType: DataviewActionTypes.Button,
                    dataTrackId: 'toggle-full-screen-mode',
                    dataTrackType: 'Button',
                },
            ]
            : [];
    };
    return EntityEventsTimelineComponent;
}());
export { EntityEventsTimelineComponent };
