/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "@angular/core";
import * as i1 from "../../alerts/components/alerts.dataview.ngfactory";
import * as i2 from "../../alerts/components/alerts.dataview";
import * as i3 from "@microsoft/paris/dist/lib/paris";
import * as i4 from "../../alerts/services/alerts.fields";
import * as i5 from "../../alerts/services/alerts.service";
import * as i6 from "../../../shared/services/title.service";
import * as i7 from "../../../../../../../projects/auth/src/lib/services/auth.service";
import * as i8 from "@angular/router";
import * as i9 from "../../../../../../../projects/config/src/lib/services/preferences.service";
import * as i10 from "../../../global_entities/services/global-entity-types.service";
import * as i11 from "../../../shared/services/time-ranges.service";
import * as i12 from "../../../rbac/services/rbac.service";
import * as i13 from "../../../../../../../projects/app-config/src/lib/app-config/services/app-config.service";
import * as i14 from "../../../dialogs/services/dialogs.service";
import * as i15 from "../../../../../../../projects/config/src/lib/services/features.service";
import * as i16 from "../../../../../../../projects/i18n/src/lib/services/i18n.service";
import * as i17 from "@angular/common";
import * as i18 from "./suppression-rule.alerts.component";
import * as i19 from "../../../global_entities/services/activated-entity.service";
import * as i20 from "../../../insights/services/performance.scc.service";
var styles_SuppressionRuleAlertsComponent = [];
var RenderType_SuppressionRuleAlertsComponent = i0.ɵcrt({ encapsulation: 2, styles: styles_SuppressionRuleAlertsComponent, data: {} });
export { RenderType_SuppressionRuleAlertsComponent as RenderType_SuppressionRuleAlertsComponent };
function View_SuppressionRuleAlertsComponent_1(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 3, "alerts-dataview", [["className", "wcd-padding-large-horizontal"], ["dataViewId", "suppression-rule-alerts"]], null, [[null, "onTableRenderComplete"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("onTableRenderComplete" === en)) {
        var pd_0 = (_co.onTableRenderComplete() !== false);
        ad = (pd_0 && ad);
    } return ad; }, i1.View_AlertsDataviewComponent_0, i1.RenderType_AlertsDataviewComponent)), i0.ɵdid(1, 638976, null, 0, i2.AlertsDataviewComponent, [i3.Paris, i4.AlertsFields, i5.AlertsService, i6.TitleService, i7.AuthService, i8.Router, i9.PreferencesService, i8.ActivatedRoute, i10.GlobalEntityTypesService, i11.TimeRangesService, i12.RbacService, i13.AppConfigService, i14.DialogsService, i0.ChangeDetectorRef, i15.FeaturesService, i16.I18nService], { dataViewId: [0, "dataViewId"], disabledFields: [1, "disabledFields"], sortDisabledFields: [2, "sortDisabledFields"], allowFilters: [3, "allowFilters"], allowGrouping: [4, "allowGrouping"], allowTimeRangeSelect: [5, "allowTimeRangeSelect"], showHeader: [6, "showHeader"], className: [7, "className"], repository: [8, "repository"], responsiveActionBar: [9, "responsiveActionBar"], removePadding: [10, "removePadding"], removePaddingRight: [11, "removePaddingRight"] }, { onTableRenderComplete: "onTableRenderComplete" }), i0.ɵpad(2, 1), (_l()(), i0.ɵted(-1, null, ["\n\t\t"]))], function (_ck, _v) { var _co = _v.component; var currVal_0 = "suppression-rule-alerts"; var currVal_1 = _ck(_v, 2, 0, "rbacGroup"); var currVal_2 = _co.sortDisabledAlertFields; var currVal_3 = false; var currVal_4 = false; var currVal_5 = false; var currVal_6 = false; var currVal_7 = "wcd-padding-large-horizontal"; var currVal_8 = _co.associatedAlertsRepo; var currVal_9 = true; var currVal_10 = true; var currVal_11 = _co.isScc; _ck(_v, 1, 1, [currVal_0, currVal_1, currVal_2, currVal_3, currVal_4, currVal_5, currVal_6, currVal_7, currVal_8, currVal_9, currVal_10, currVal_11]); }, null); }
export function View_SuppressionRuleAlertsComponent_0(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵted(-1, null, ["\n\t\t"])), (_l()(), i0.ɵand(16777216, null, null, 1, null, View_SuppressionRuleAlertsComponent_1)), i0.ɵdid(2, 16384, null, 0, i17.NgIf, [i0.ViewContainerRef, i0.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i0.ɵted(-1, null, ["\n\t"]))], function (_ck, _v) { var _co = _v.component; var currVal_0 = ((_co.associatedAlertsRepo == null) ? null : _co.associatedAlertsRepo.sourceItem); _ck(_v, 2, 0, currVal_0); }, null); }
export function View_SuppressionRuleAlertsComponent_Host_0(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 1, "suppression-rule-alerts", [], null, null, null, View_SuppressionRuleAlertsComponent_0, RenderType_SuppressionRuleAlertsComponent)), i0.ɵdid(1, 114688, null, 0, i18.SuppressionRuleAlertsComponent, [i3.Paris, i19.ActivatedEntity, i20.PerformanceSccService], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
var SuppressionRuleAlertsComponentNgFactory = i0.ɵccf("suppression-rule-alerts", i18.SuppressionRuleAlertsComponent, View_SuppressionRuleAlertsComponent_Host_0, {}, {}, []);
export { SuppressionRuleAlertsComponentNgFactory as SuppressionRuleAlertsComponentNgFactory };
