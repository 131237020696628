import { EntityRelatedWidgetBase } from '../entity-related-widget.base';
import { EntityStatistics } from '@wcd/domain';
import { Paris, EntityRelationshipRepositoryType, DataEntityType } from '@microsoft/paris';
import { ActivatedEntity } from '../../../services/activated-entity.service';
import { ReportsService } from '../../../../shared-reports/services/reports.service';
import { SpecificTimeRangeValue, TimeRangeId } from '@wcd/date-time-picker';
import { Type, OnDestroy } from '@angular/core';
import { I18nService } from '@wcd/i18n';
import { filter } from 'rxjs/operators';
import { TimeRangesService } from '../../../../shared/services/time-ranges.service';
import { Subscription } from 'rxjs';

export abstract class EntityPrevalenceWidgetBase<TEntity>
	extends EntityRelatedWidgetBase<TEntity, EntityStatistics>
	implements OnDestroy {
	constructor(
		paris: Paris,
		activatedEntity: ActivatedEntity,
		reportsService: ReportsService,
		private readonly i18nService: I18nService,
		private readonly timeRangeService: TimeRangesService
	) {
		super(paris, activatedEntity, reportsService);
		this.currentOrgPrevDateRange = <SpecificTimeRangeValue>(
			this.timeRangeService.pick([TimeRangeId.month])[0]
		);

		this.pollingErrorSubscription = this.pollingError$
			.pipe(filter(isPollingError => isPollingError))
			.subscribe(_ => {
				this.currentOrgPrevDateRange = this.previousOrgPrevDateRange;
			});
	}

	ngOnDestroy() {
		this.pollingErrorSubscription.unsubscribe();
	}

	protected abstract id: string;

	protected abstract entityType: DataEntityType<TEntity>;

	protected abstract entityStatisticsRelationship: Type<
		EntityRelationshipRepositoryType<TEntity, EntityStatistics>
	>;

	currentOrgPrevDateRange: SpecificTimeRangeValue;
	previousOrgPrevDateRange: SpecificTimeRangeValue;
	pollingErrorSubscription: Subscription;

	get entityRelatedWidgetConfig() {
		return {
			id: this.id,
			name: this.i18nService.get('reports.widgets.prevalence.title'),
			entityType: this.entityType,
			entityRelationshipType: this.entityStatisticsRelationship,
			getDataQuery: () => ({
				where: {
					lookingBackInDays: this.currentOrgPrevDateRange.value,
				},
			}),
		};
	}

	onTimeRangeChange(timeRangeValue: SpecificTimeRangeValue) {
		if (this.currentOrgPrevDateRange !== timeRangeValue) {
			this.previousOrgPrevDateRange = this.currentOrgPrevDateRange;
			this.currentOrgPrevDateRange = timeRangeValue;
			this.reload();
		}
	}
}
