var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (b.hasOwnProperty(p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
var __metadata = (this && this.__metadata) || function (k, v) {
    if (typeof Reflect === "object" && typeof Reflect.metadata === "function") return Reflect.metadata(k, v);
};
import { Entity, EntityField, EntityModelBase } from '@microsoft/paris';
import { ThreatReportType } from './threat-report-type.values';
var ɵ0 = function (config) { return config.data.serviceUrls.threatAnalytics; };
var Outbreak = /** @class */ (function (_super) {
    __extends(Outbreak, _super);
    function Outbreak() {
        return _super !== null && _super.apply(this, arguments) || this;
    }
    __decorate([
        EntityField({ data: 'Id' }),
        __metadata("design:type", String)
    ], Outbreak.prototype, "id", void 0);
    __decorate([
        EntityField({ data: 'DisplayName' }),
        __metadata("design:type", String)
    ], Outbreak.prototype, "displayName", void 0);
    __decorate([
        EntityField({ data: 'CreatedOn' }),
        __metadata("design:type", Date)
    ], Outbreak.prototype, "createdOn", void 0);
    __decorate([
        EntityField({ data: 'StartedOn' }),
        __metadata("design:type", Date)
    ], Outbreak.prototype, "startedOn", void 0);
    __decorate([
        EntityField({ data: 'LastUpdatedOn' }),
        __metadata("design:type", Date)
    ], Outbreak.prototype, "lastUpdatedOn", void 0);
    __decorate([
        EntityField({ data: 'Severity' }),
        __metadata("design:type", Number)
    ], Outbreak.prototype, "severity", void 0);
    __decorate([
        EntityField({ data: 'Keywords' }),
        __metadata("design:type", Array)
    ], Outbreak.prototype, "keywords", void 0);
    __decorate([
        EntityField({ data: 'Actors' }),
        __metadata("design:type", Array)
    ], Outbreak.prototype, "actors", void 0);
    __decorate([
        EntityField({ data: 'TargetIndustries' }),
        __metadata("design:type", Array)
    ], Outbreak.prototype, "targetIndustries", void 0);
    __decorate([
        EntityField({ data: 'Tags' }),
        __metadata("design:type", Array)
    ], Outbreak.prototype, "tags", void 0);
    __decorate([
        EntityField({ data: 'References' }),
        __metadata("design:type", Array)
    ], Outbreak.prototype, "references", void 0);
    __decorate([
        EntityField({ data: 'IOAIds' }),
        __metadata("design:type", Array)
    ], Outbreak.prototype, "ioaIds", void 0);
    __decorate([
        EntityField({ data: 'MitigationTypes' }),
        __metadata("design:type", Array)
    ], Outbreak.prototype, "mitigationTypes", void 0);
    __decorate([
        EntityField({ data: 'LastVisitTime' }),
        __metadata("design:type", Date)
    ], Outbreak.prototype, "lastVisitTime", void 0);
    __decorate([
        EntityField({ data: 'ReportType' }),
        __metadata("design:type", String)
    ], Outbreak.prototype, "reportType", void 0);
    __decorate([
        EntityField({ data: 'IsVNext' }),
        __metadata("design:type", Boolean)
    ], Outbreak.prototype, "isVNext", void 0);
    Outbreak = __decorate([
        Entity({
            singularName: 'Threat',
            pluralName: 'Threats',
            readonly: true,
            endpoint: 'outbreaks',
            loadAll: true,
            baseUrl: ɵ0,
        })
    ], Outbreak);
    return Outbreak;
}(EntityModelBase));
export { Outbreak };
export { ɵ0 };
