var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : new P(function (resolve) { resolve(result.value); }).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g;
    return g = { next: verb(0), "throw": verb(1), "return": verb(2) }, typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (_) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
import { OnDestroy } from '@angular/core';
import { Location } from '@angular/common';
import { AuthBackendService, WDATP_LOGOUT_URL, WDATP_REDIRECT_TO_LOGIN_URL } from './auth.backend.service';
import { EMPTY, of, ReplaySubject, Subject, timer } from 'rxjs';
import { BrowserDetectionService } from '@wcd/shared';
import { AppConfigModel, AppConfigService } from '@wcd/app-config';
import { ApiBaseModel } from '@wcd/data';
import { delay, map, switchMap, take, tap } from 'rxjs/operators';
import { AppContextService, Feature, FeaturesService } from '@wcd/config';
import { TelemetryService, TrackingEventType } from '@wcd/telemetry';
import { APC_HEADER_KEY, tenantContextCache, AuthUser } from '@wcd/auth';
import { sccHostService } from '@wcd/scc-interface';
var SESSION_STORAGE_KEY = 'wcdPortalSessionId';
var TID_STORAGE_KEY = 'tenantId';
var AuthService = /** @class */ (function () {
    function AuthService(authBackendService, browserDetectionService, appConfigService, appContext, featuresService, location, telemetryService) {
        this.authBackendService = authBackendService;
        this.browserDetectionService = browserDetectionService;
        this.appConfigService = appConfigService;
        this.appContext = appContext;
        this.featuresService = featuresService;
        this.location = location;
        this.telemetryService = telemetryService;
        this._logoutSubject = new Subject();
        this.redirectUrl$ = new ReplaySubject(1);
        this.logout$ = this._logoutSubject.asObservable();
        this.token$ = new Subject(); // used to push new token to seville.authentication. Can be removed once angularJs is removed.
        this.loginError = null;
        var initialUrl = this.location.path(true);
        this.redirectUrl$.next(initialUrl);
        this.setOnBehalfScenario(initialUrl);
    }
    Object.defineProperty(AuthService.prototype, "tenantId", {
        get: function () {
            return this.appConfigService.tenantId || this.onBehalfTenantId;
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(AuthService.prototype, "isLoggedIn", {
        get: function () {
            return !!this.token;
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(AuthService.prototype, "sessionId", {
        get: function () {
            return this._sessionId;
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(AuthService.prototype, "onBehalfTenantId", {
        get: function () {
            try {
                return sessionStorage.getItem(TID_STORAGE_KEY);
            }
            catch (e) {
                console.warn('Failed to access session storage');
            }
        },
        set: function (value) {
            try {
                sessionStorage.setItem(TID_STORAGE_KEY, value);
            }
            catch (e) {
                console.warn('Failed to access session storage');
            }
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(AuthService.prototype, "apcHeader", {
        get: function () {
            if (!this._apcHeader) {
                try {
                    this._apcHeader = sessionStorage.getItem(APC_HEADER_KEY);
                }
                catch (e) {
                    console.warn('Failed to access session storage');
                }
            }
            return this._apcHeader;
        },
        set: function (value) {
            if (this.apcHeader !== value) {
                this._apcHeader = value;
                try {
                    sessionStorage.setItem(APC_HEADER_KEY, value);
                }
                catch (e) {
                    console.warn('Failed to access session storage');
                }
            }
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(AuthService.prototype, "isGlobalAdmin", {
        get: function () {
            return this.currentUser && this.currentUser.isMdeAdmin;
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(AuthService.prototype, "isExecutiveReport", {
        /*
         * checks the browser's user agent in order to detect if the user is a pdf reports generator
         * returns true if this is a pdf reports generator and therefore allowed to use the app without login and token
         */
        get: function () {
            return this.browserDetectionService.browserData.hexaditeReports;
        },
        enumerable: true,
        configurable: true
    });
    AuthService.prototype.setOnBehalfScenario = function (url) {
        // support for MSSP/On-Behalf scenarios
        var tidMatch = url.match(/[?&]tid=([^?&]+)/i);
        if (tidMatch)
            this.onBehalfTenantId = tidMatch[1];
    };
    AuthService.prototype.generateSessionId = function () {
        return new Date().valueOf().toString();
    };
    AuthService.prototype.addTenantIdToUrl = function (url) {
        var prefix = /\?/.test(url) ? '&' : '?';
        return url + (this.tenantId ? prefix + ("tenant_id=" + this.tenantId) : '');
    };
    AuthService.prototype.setAuthToken = function (token) {
        this.token = token;
        this.token$.next(token);
    };
    AuthService.prototype.init = function () {
        var _this = this;
        if (!this.token) {
            return this.login().pipe(map(function () { return _this.currentUser; }));
        }
        else {
            var sessionId = sessionStorage.getItem(SESSION_STORAGE_KEY);
            if (!sessionId) {
                sessionId = this.generateSessionId();
                sessionStorage.setItem(SESSION_STORAGE_KEY, sessionId);
            }
            this._sessionId = sessionId;
        }
        return of(this.currentUser);
    };
    AuthService.prototype.handleAuthError = function (checkAuthStatus) {
        var _this = this;
        if (checkAuthStatus === void 0) { checkAuthStatus = true; }
        if (!checkAuthStatus)
            this.redirectToLogin();
        else {
            // avoid making multiple requests
            if (!this.checkingAuthStatus) {
                this.checkingAuthStatus = true;
                this.authBackendService.checkAuthStatus().subscribe(function (isAuthenticated) {
                    if (!isAuthenticated) {
                        _this.redirectToLogin();
                    }
                    _this.checkingAuthStatus = false;
                }, function (err) {
                    _this.checkingAuthStatus = false;
                }, function () {
                    _this.checkingAuthStatus = false;
                });
            }
        }
    };
    AuthService.prototype.redirectToLogin = function () {
        var _this = this;
        this.setAuthToken(null);
        this.redirectUrl$.pipe(take(1), delay(1)).subscribe(function (url) {
            url = url || '/';
            var options = ApiBaseModel.getUrlParams(Object.assign({}, _this.onBehalfTenantId && { tid: _this.onBehalfTenantId }, {
                state: url,
            }));
            var redirectToLoginUrl = WDATP_REDIRECT_TO_LOGIN_URL + "?" + options.params.toString();
            window.location.href = redirectToLoginUrl;
        });
    };
    AuthService.prototype.login = function () {
        var _this = this;
        return this.authBackendService.login().pipe(tap(function (appConfigBackendData) {
            _this.appConfigService.setData(appConfigBackendData);
            _this.handleLoginResponse(appConfigBackendData, true);
        }, function (err) {
            if (err.status === 401)
                _this.loginError = true;
        }));
    };
    AuthService.prototype.handleLoginResponse = function (appConfigBackendData, isLogin) {
        this.setAuthToken(appConfigBackendData.AuthInfo.AccessToken);
        var tokenExpirationStr = appConfigBackendData.AuthInfo &&
            appConfigBackendData.AuthInfo.TokenExpirationDate &&
            appConfigBackendData.AuthInfo.TokenExpirationDate.match(/\d+/);
        this.tokenExpirationDate = tokenExpirationStr && new Date(parseInt(tokenExpirationStr[0], 10));
        this.currentUser = new AuthUser(this.getAuthUserInfoFromAppConfigData(appConfigBackendData));
        if (isLogin) {
            this.telemetryService.trackEvent({
                type: TrackingEventType.Action,
                id: 'userLoggedIn',
                payload: { allowedActions: this.currentUser.mdeAllowedActions.toString() },
            });
        }
        sessionStorage.setItem(SESSION_STORAGE_KEY, this._sessionId || (this._sessionId = this.generateSessionId()));
    };
    AuthService.prototype.setRefreshToken = function () {
        var _this = this;
        if (!this.featuresService.isEnabled(Feature.RefreshToken) || this.appContext.isSCC) {
            return;
        }
        var callAt = new Date(this.tokenExpirationDate);
        // the call can only be made 5 minutes before the token expires
        callAt.setMinutes(callAt.getMinutes() - 4);
        var callIn = callAt.getTime() - new Date().getTime();
        timer(callIn)
            .pipe(switchMap(function () {
            // verify token is not expired
            return _this.tokenExpirationDate.getTime() - new Date().getTime() > 0
                ? _this.authBackendService.refreshToken(_this.tenantId)
                : EMPTY;
        }), tap(function (auth) {
            var newConfig = new AppConfigModel();
            newConfig.setData(auth);
            // TODO: check user model? Currently user changes are reflected immediately, so probably no need
            if (_this.appConfigService.equals(newConfig)) {
                _this.handleLoginResponse(auth);
                _this.setRefreshToken();
            }
            else {
                location.reload();
            }
        }))
            .toPromise();
    };
    AuthService.prototype.logout = function () {
        this.setAuthToken(null);
        this._logoutSubject.next();
        var urlSuffix = ApiBaseModel.getUrlParams(this.onBehalfTenantId && { tid: this.onBehalfTenantId }).params.toString();
        sessionStorage.removeItem(SESSION_STORAGE_KEY);
        sessionStorage.removeItem(TID_STORAGE_KEY);
        !sccHostService.isSCC && sessionStorage.removeItem(APC_HEADER_KEY);
        var url = WDATP_LOGOUT_URL;
        url = urlSuffix ? url + "?" + urlSuffix : url;
        window.location.href = url;
    };
    AuthService.prototype.isCurrentUser = function (userName) {
        return userName === this.currentUser.name;
    };
    AuthService.prototype.ngOnDestroy = function () {
        this.redirectUrl$.complete();
        this._logoutSubject.complete();
        this.token$.complete();
        this.authSub && this.authSub.unsubscribe();
        !sccHostService.isSCC && sessionStorage.removeItem(APC_HEADER_KEY);
    };
    //SCC login flow
    AuthService.prototype.initSCC = function () {
        return __awaiter(this, void 0, void 0, function () {
            var appConfig, err_1;
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        _a.trys.push([0, 5, , 7]);
                        return [4 /*yield*/, tenantContextCache.getTenantContext()];
                    case 1:
                        appConfig = _a.sent();
                        if (!appConfig.IsStubData) return [3 /*break*/, 2];
                        this.setOptOutSettings();
                        return [3 /*break*/, 4];
                    case 2: return [4 /*yield*/, this.onTenantContextLoaded(appConfig)];
                    case 3: return [2 /*return*/, _a.sent()];
                    case 4: return [3 /*break*/, 7];
                    case 5:
                        err_1 = _a.sent();
                        return [4 /*yield*/, this.onTenantContextLoadError(err_1)];
                    case 6:
                        _a.sent();
                        return [3 /*break*/, 7];
                    case 7: return [2 /*return*/];
                }
            });
        });
    };
    AuthService.prototype.onTenantContextLoaded = function (appConfigBackendData) {
        return __awaiter(this, void 0, void 0, function () {
            var isTvmStandaloneEnabled;
            return __generator(this, function (_a) {
                isTvmStandaloneEnabled = sccHostService.config.getVariantSetting({
                    value: false,
                    values: [{ variants: 'TvmFullStandalone', value: true }],
                });
                if (!appConfigBackendData.IsMtpEligible && !(isTvmStandaloneEnabled && appConfigBackendData.IsTvmEligible)) {
                    return [2 /*return*/, Promise.reject()];
                }
                sessionStorage.setItem(SESSION_STORAGE_KEY, this._sessionId || (this._sessionId = this.generateSessionId()));
                this.featuresService.applyFeatures(appConfigBackendData.Features);
                appConfigBackendData.ServiceUrls.assetsBaseUrl = sccHostService.getPackageBasePathByPkg();
                // This is only relevant for working with localhost, this replaces the localhost with the host injected from SCC devhost params(for tests)
                this.replaceServiceHostsWithDevhost(appConfigBackendData);
                this.appConfigService.setData(appConfigBackendData);
                this.currentUser = new AuthUser(this.getAuthUserInfoFromAppConfigData(appConfigBackendData));
                return [2 /*return*/, appConfigBackendData];
            });
        });
    };
    // TODO find a  way of doing this without changing url from localhost
    AuthService.prototype.replaceServiceHostsWithDevhost = function (appConfigBackendData) {
        // This is only relevant for working with localhost, this replaces the localhost with the host injected from SCC devhost params(for tests)
        Object.keys(appConfigBackendData.ServiceUrls)
            .filter(function (prop) { return appConfigBackendData.ServiceUrls[prop]; })
            .forEach(function (prop) {
            return (appConfigBackendData.ServiceUrls[prop] = appConfigBackendData.ServiceUrls[prop].replace('http://localhost:4200', appConfigBackendData.ServiceUrls.assetsBaseUrl));
        });
    };
    AuthService.prototype.onTenantContextLoadError = function (err) {
        return __awaiter(this, void 0, void 0, function () {
            return __generator(this, function (_a) {
                // Clear cache so next call might work
                tenantContextCache.invalidateCache();
                sccHostService.log.trackException(err);
                throw err;
            });
        });
    };
    AuthService.prototype.getAuthUserInfoFromAppConfigData = function (appConfigData) {
        return {
            DirRoles: appConfigData.DirRoles,
            IsItpActive: appConfigData.IsItpActive,
            IsMdatpActive: appConfigData.IsMdatpActive,
            IsOatpActive: appConfigData.IsOatpActive,
            IsMapgActive: appConfigData.IsMapgActive,
            IsAadIpActive: appConfigData.IsAadIpActive,
            IsDlpActive: appConfigData.IsDlpActive,
            IsMdiActive: appConfigData.IsMdiActive,
            ItpMtpPermissions: appConfigData.ItpMtpPermissions,
            MdatpMtpPermissions: appConfigData.MdatpMtpPermissions,
            OatpMtpPermissions: appConfigData.OatpMtpPermissions,
            MapGMtpPermissions: appConfigData.MapgMtpPermissions,
            AadIpMtpPermissions: appConfigData.AadIpMtpPermissions,
            DlpMtpPermissions: appConfigData.DlpMtpPermissions,
            MdiMtpPermissions: appConfigData.MdiMtpPermissions,
            UserName: appConfigData.AuthInfo.UserName,
            AadUserId: appConfigData.AuthInfo.AadUserId,
            MdeAllowedActions: appConfigData.AuthInfo.AllowedActions,
        };
    };
    AuthService.prototype.setOptOutSettings = function () {
        this.currentUser = new AuthUser({
            UserName: sccHostService.loginUser.upn,
            TenantId: sccHostService.loginUser.tenantId,
            AadUserId: sccHostService.loginUser.userId,
            IsMdatpActive: false,
            IsItpActive: false,
            IsOatpActive: true,
        });
        this.appConfigService.setData({
            AuthInfo: {
                TenantId: sccHostService.loginUser.tenantId,
                UserName: sccHostService.loginUser.upn,
            },
            AutomatedIrLiveResponse: false,
            IsMtpEligible: false,
        });
    };
    return AuthService;
}());
export { AuthService };
