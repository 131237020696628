var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { EventEmitter, OnInit } from '@angular/core';
import { NgForm } from '@angular/forms';
import { TabModel } from '../../../../shared/components/tabs/tab.model';
import { DialogsService } from '../../../../dialogs/services/dialogs.service';
import { AadGroup, UserRole, MdeUserRoleAction, DeprecatedUserRoleAction, MdeUserRoleActionEnum, } from '@wcd/domain';
import { Paris } from '@microsoft/paris';
import { AuthService, UserAuthEnforcementMode } from '@wcd/auth';
import { UserRoleActionsService } from '../../services/user-role-actions.service';
import { find } from 'lodash-es';
import { I18nService } from '@wcd/i18n';
import { FeaturesService, Feature, FlavorService } from '@wcd/config';
import { TrackingEventType } from '@wcd/telemetry';
import { AppInsightsService } from '../../../../insights/services/app-insights.service';
import { RbacComponentType } from '../shared/aad-groups-assignment-tab';
import { MessageBarType } from 'office-ui-fabric-react';
import { AppConfigService } from '@wcd/app-config';
import { sccHostService } from '@wcd/scc-interface';
import { AppFlavorConfig } from '@wcd/scc-common';
var UserRoleEditComponent = /** @class */ (function () {
    function UserRoleEditComponent(paris, dialogsService, i18nService, authService, userRoleActionsService, featuresService, appInsightsService, appConfigService, flavorService) {
        var _this = this;
        this.paris = paris;
        this.dialogsService = dialogsService;
        this.i18nService = i18nService;
        this.authService = authService;
        this.userRoleActionsService = userRoleActionsService;
        this.featuresService = featuresService;
        this.appInsightsService = appInsightsService;
        this.save = new EventEmitter();
        this.cancel = new EventEmitter();
        this.isScc = sccHostService.isSCC;
        this.RbacComponentType = RbacComponentType;
        this.selectedLiveResponsePermission = null;
        this.liveResponseFeatureName = Feature.LiveResponse;
        this.isLoadingAadGroups = false;
        this.isAadGroupsError = false;
        this.RbacMachineGroupsForMtpEnabled = false;
        this.isSaving = false;
        this.MessageBarType = MessageBarType;
        this.tabs = [
            {
                id: 'details',
                name: this.i18nService.get('userRoles.panel.tab.general'),
            },
            {
                id: 'assignment',
                name: this.i18nService.get('userRoles.panel.tab.assigned'),
            },
        ].map(function (tab) { return new TabModel(tab); });
        this.currentTab = this.tabs[0];
        this.repository = paris.getRepository(UserRole);
        this.aadGroupsRepository = paris.getRepository(AadGroup);
        this.userRoleActionsRepository = paris.getRepository(this.featuresService.isEnabled(Feature.TvmRoles) ? MdeUserRoleAction : DeprecatedUserRoleAction);
        this.isUrbacEnabled = appConfigService.userAuthEnforcementMode === UserAuthEnforcementMode.UnifiedRbac;
        this.RbacMachineGroupsForMtpEnabled = this.featuresService.isEnabled(Feature.RbacMachineGroupsForMtp);
        this.hasFlavor = flavorService.isEnabled(AppFlavorConfig.settings.mdeWithWorkloads);
        // filter out roles by features and flavors
        var userRoleActions = this.userRoleActionsRepository.entity.values.filter(function (userRoleAction) {
            var shouldKeep = (!userRoleAction.featureFlags || _this.featuresService.isEnabled(userRoleAction.featureFlags)) &&
                (_this.hasFlavor || !userRoleAction.flavorRequired);
            if (shouldKeep && userRoleAction.children) {
                var childrenToFilter = userRoleAction.children.filter(function (child) { return (child.featureFlags && !_this.featuresService.isEnabled(child.featureFlags)) ||
                    (!_this.hasFlavor && child.flavorRequired); });
                childrenToFilter.forEach(function (child) {
                    var index = userRoleAction.children.indexOf(child, 0);
                    if (index > -1) {
                        userRoleAction.children.splice(index, 1);
                    }
                });
            }
            return shouldKeep;
        });
        this.displayedUserRoleActions = userRoleActions.filter(function (userRoleAction) { return !userRoleAction.isLiveResponseAction && !userRoleAction.hidden; });
        this.displayedUserRoleActionsForGlobalAdmin = userRoleActions.filter(function (userRoleAction) {
            return !userRoleAction.isLiveResponseAction && !userRoleAction.isAdmin;
        });
        this.liveResponseUserRoleActions = userRoleActions
            .filter(function (userRoleAction) { return userRoleAction.isLiveResponseAction; })
            .map(function (userRoleAction) {
            return new MdeUserRoleAction(__assign({}, userRoleAction, { displayName: _this.i18nService.get(userRoleAction.displayNameKey) }));
        });
    }
    Object.defineProperty(UserRoleEditComponent.prototype, "isDirty", {
        get: function () {
            return this.userRoleForm.form.dirty;
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(UserRoleEditComponent.prototype, "confirmDeactivateText", {
        get: function () {
            return "Are you sure you wish to discard " + (this.userRole.id ? 'changes to ' : '') + "this " + this.repository.entity.singularName.toLowerCase() + "?";
        },
        enumerable: true,
        configurable: true
    });
    UserRoleEditComponent.prototype.ngOnInit = function () {
        var _this = this;
        this.editedUserRole = this.userRole ? new UserRole(this.userRole) : this.repository.createNewItem();
        var allAssignedAadGroups = this.editedUserRole.assignments.map(function (assignment) { return assignment.aadGroup; });
        var groupsDontExist = allAssignedAadGroups.filter(function (aadGroup) { return !aadGroup.name; });
        this.assignedAadGroups = allAssignedAadGroups.filter(function (aadGroup) { return aadGroup.name; });
        var newGroupsDontExist = groupsDontExist.map(function (group) {
            return {
                id: group.id,
                name: _this.i18nService.get('userRoles.groupDoesNotExist'),
                helpText: _this.i18nService.get('userRoles.groupDoesNotExistHelp'),
            };
        });
        this.assignedAadGroups = this.assignedAadGroups.concat(newGroupsDontExist);
        if (!this.userRole)
            this.editedUserRole.allowedActions = this.featuresService.isEnabled(Feature.TvmRoles)
                ? [this.displayedUserRoleActions[0]].concat(this.displayedUserRoleActions[0].children) : [this.displayedUserRoleActions[0]];
        if (this.editedUserRole.isGlobalAdmin) {
            this.editedUserRole.name = this.i18nService.get('userRoles.globalAdminName');
            this.editedUserRole.description = this.i18nService.get('userRoles.globalAdminDescription');
        }
        // look for LiveResponseAdvanced permission and fallback to LiveResponseBasic or null
        var roleLiveResponsePermissions = this.editedUserRole.allowedActions.filter(function (action) { return action.isLiveResponseAction; });
        var selectedLiveResponsePermissionId = this.userRole && roleLiveResponsePermissions.length > 0
            ? (roleLiveResponsePermissions.find(function (action) { return action.id === MdeUserRoleActionEnum.liveResponseAdvanced; }) || roleLiveResponsePermissions.pop()).id
            : 0;
        this.selectedLiveResponsePermission = this.liveResponseUserRoleActions.find(function (role) { return role.id === selectedLiveResponsePermissionId; });
        this.setAvailableAadGroups();
    };
    UserRoleEditComponent.prototype.saveUserRole = function () {
        var _this = this;
        if (!this.editedUserRole.assignments.length) {
            this.dialogsService
                .confirm({
                title: this.i18nService.get('userRoles_saveWarningDialog_title'),
                text: this.i18nService.get('userRoles_saveWarningDialog_text'),
                confirmText: this.i18nService.get('common_saveAndClose'),
                cancelText: this.i18nService.get('buttons_cancel'),
            })
                .then(function (e) { return e.confirmed && _this.doSave(); });
        }
        else
            this.doSave();
    };
    UserRoleEditComponent.prototype.doSave = function () {
        var _this = this;
        this.isSaving = true;
        // first remove previous live response permission selection and then add the new one if any
        this.editedUserRole.allowedActions = this.editedUserRole.allowedActions.filter(function (a) { return !a.isLiveResponseAction; });
        if (this.selectedLiveResponsePermission) {
            this.editedUserRole.allowedActions.push(this.selectedLiveResponsePermission);
        }
        this.editedUserRole.oldAllowedActions = this.editedUserRole.allowedActions;
        this.appInsightsService.trackEvent('RolesCreation', {
            type: TrackingEventType.Save,
            id: 'RolesCreation',
            allowedActions: this.editedUserRole.allowedActions.map(function (allowedAction) { return allowedAction.id; }),
        });
        this.repository.save(this.editedUserRole).subscribe(function (savedUserRole) {
            _this.isSaving = false;
            _this.userRoleForm.form.markAsPristine();
            _this.save.emit(savedUserRole);
        }, function (error) {
            _this.isSaving = false;
            _this.isError = true;
        });
    };
    UserRoleEditComponent.prototype.addSelectedGroups = function () {
        this.assignedAadGroups = this.assignedAadGroups.concat(this.selectedAadGroups);
        this.setAvailableAadGroupsWithoutAssigned();
        this.userRoleForm.form.markAsDirty();
        this.selectedAadGroups = [];
        this.assignSelectedGroupsToUserRole();
    };
    UserRoleEditComponent.prototype.removeSelectedGroups = function () {
        var _this = this;
        this.assignedAadGroups = this.assignedAadGroups.filter(function (assignedAadGroup) { return !~_this.selectedAssignedAadGroups.indexOf(assignedAadGroup); });
        this.selectedAssignedAadGroups = [];
        this.setAvailableAadGroupsWithoutAssigned();
        this.userRoleForm.form.markAsDirty();
        this.assignSelectedGroupsToUserRole();
    };
    // called when URBAC feature is enabled
    UserRoleEditComponent.prototype.applySelectedAadGroups = function (aadGroups) {
        this.assignedAadGroups = aadGroups;
        this.userRoleForm.form.markAsDirty();
        this.assignSelectedGroupsToUserRole();
    };
    UserRoleEditComponent.prototype.setAvailableAadGroups = function (filter) {
        var _this = this;
        if (filter === void 0) { filter = ''; }
        this.isLoadingAadGroups = true;
        this.isAadGroupsError = false;
        this.aadGroupsRepository.query({ where: { filter: filter } }).subscribe(function (aadGroups) {
            _this.aadGroupsFilterResults = aadGroups.items;
            _this.setAvailableAadGroupsWithoutAssigned();
            _this.isLoadingAadGroups = false;
        }, function (error) {
            _this.isLoadingAadGroups = false;
            _this.isAadGroupsError = true;
        });
    };
    UserRoleEditComponent.prototype.setAvailableAadGroupsWithoutAssigned = function () {
        var _this = this;
        this.availableAadGroups = this.aadGroupsFilterResults.filter(function (aadGroup) { return !find(_this.assignedAadGroups, function (assignedAadGroup) { return aadGroup.id === assignedAadGroup.id; }); });
    };
    UserRoleEditComponent.prototype.assignSelectedGroupsToUserRole = function () {
        this.editedUserRole.setGroups(this.assignedAadGroups);
    };
    return UserRoleEditComponent;
}());
export { UserRoleEditComponent };
