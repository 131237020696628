export enum Feature {
	AadIpUserRiskySignInStoryTab = 'AadIpUserRiskySignInStoryTab',
	AadIpUserRiskySignInStoryTabMtp = 'AadIpUserRiskySignInStoryTabMtp',
	AclOffloadingPhase1 = 'AclOffloadingPhase1',
	ActionCenterBulkIdColumn = 'ActionCenterBulkIdColumn',
	ActionCenterActionSourceColumn = 'ActionCenterActionSourceColumn',
	ActionCenterRemediationNameColumn = 'ActionCenterRemediationNameColumn',
	ActionCenterExport = 'ActionCenterExport',
	AclOffloadingPhase2 = 'AclOffloadingPhase2',
	ActionHistory = 'ActionHistory',
	AddIndicatorsPanelV2 = 'AddIndicatorsPanelV2',
	AipIntegration = 'AipIntegration',
	AirsApiOffloading = 'AirsApiOffloading',
	AlertSummaryAction = 'AlertSummaryAction',
	AllowBlockOnlyForUrlIndicator = 'AllowBlockOnlyForUrlIndicator',
	AllowNonAuthenticatedSense = 'AllowNonAuthenticatedSense',
	AltIncidentGraphLayout = 'AltIncidentGraphLayout',
	AmsiScriptDetection = 'AmsiScriptDetection',
	AndroidOnboarding = 'AndroidOnboarding',
	AndroidEndPointManagement = 'AndroidEndPointManagement',
	AndroidEndPointManagementPreview = 'AndroidEndPointManagementPreview',
	ApiExplorer = 'ApiExplorer',
	AscOnboarding = 'AscOnboarding',
	AutomatedIRInvestigateProcess = 'AutomatedIRInvestigateProcess',
	AutoResolveInvestigatedAlerts = 'AutoResolveInvestigatedAlerts',
	AzureDataExportSupport = 'AzureDataExportSupport',
	BilbaoRegistration = 'BilbaoRegistration',
	BilbaoExpertsOnDemand = 'BilbaoExpertsOnDemand',
	BilbaoExpertsOnDemand2 = 'BilbaoExpertsOnDemand2',
	BlockIpAndDomain = 'BlockIpAndDomain',
	BreakTheGlass = 'BreakTheGlass',
	BulkFileRemediation = 'BulkFileRemediation',
	BulkFileUndo = 'BulkFileUndo',
	BulkManageIncidentTags = 'BulkManageIncidentTags',
	CertificateBlock = 'CertificateBlock',
	CloudLiveResponseV3 = 'CloudLiveResponseV3',
	ComplianceAlertSharing = 'ComplianceAlertSharing',
	ConfigurationManagement = 'ConfigurationManagement',
	ConnectedApps = 'ConnectedApps',
	CustomerTI = 'CustomerTI',
	AutomatedIRAutoApproveByDefaultBanner = 'AutomatedIRAutoApproveByDefaultBanner',
	DeviceDiscoverySettings = 'DeviceDiscoverySettings',
	DemoOnboardingScript = 'DemoOnboardingScript',
	DemoPortalHacks = 'DemoPortalHacks',
	DevicesAtRiskWidgetV2 = 'DevicesAtRiskWidgetV2',
	DisablePortalRemediationNotifications = 'DisablePortalRemediationNotifications',
	DownloadFileSample = 'DownloadFileSample',
	DlpOfficeAudit = 'DlpOfficeAudit',
	NewMachinePage = 'NewMachinePage',
	DevicesListIotV1 = 'DevicesListIotV1',
	McasAlertPage = 'McasAlertPage',
	MagellanIpAndNameSearch = 'MagellanIpAndNameSearch',
	MagellanTotalDiscoveredDashboard = 'MagellanTotalDiscoveredDashboard',
	MagellanDeviceInventoryPage = 'MagellanDeviceInventoryPage',
	MagellanDiscoveredEndpointsWidget = 'MagellanDiscoveredEndpointsWidget',
	MagellanRecentlyDiscoveredDashboard = 'MagellanRecentlyDiscoveredDashboard',
	MagellanOptOut = 'MagellanOptOut',
	MagellanDataSourcesPage = 'MagellanDataSourcesPage',
	MagellanAdIotOnboarding = 'MagellanAdIotOnboarding',
	MagellanUpsellMessage = 'MagellanUpsellMessage',
	MagellanDevicePageOnboardingCta = 'MagellanDevicePageOnboardingCta',
	MagellanOptOutConfirmationModal = 'MagellanOptOutConfirmationModal',
	MagellanMonitoredNetworks = 'MagellanMonitoredNetworks',
	MagellanDataSources = 'MagellanDataSources',
	MagellanDiy = 'MagellanDiy',
	MagellanShieldsUp = 'MagellanShieldsUp',
	MagellanDeviceInventoryCounters = 'MagellanDeviceInventoryCounters',
	MagellanLog4jScanningToggle = 'MagellanLog4jScanningToggle',
	MagellanDeviceInventoryMigration = 'MagellanDeviceInventoryMigration',
	DataExportSettingsV2Page = 'DataExportSettingsV2Page',
	AutoProactiveDiscoveryMessage = 'AutoProactiveDiscoveryMessage',
	IotDevicesListMacAndIpv6 = 'IotDevicesListMacAndIpv6',
	DevicesListIotDisplayOsVersion = 'DevicesListIotDisplayOsVersion',
	EmailNotifications = 'EmailNotifications',
	EnableWdavAuditMode = 'EnableWdavAuditMode',
	EnableWdavPassiveModeRemediation = 'EnableWdavPassiveModeRemediation',
	EndpointConfigManagement = 'EndpointConfigManagement',
	EndpointConfigManagementFe = 'EndpointConfigManagementFe',
	EndpointConfigManagementFeV2 = 'EndpointConfigManagementFeV2',
	EndpointConfigManagementASC = 'EndpointConfigManagementASC',
	EndpointConfigManagementMdeWithSccm = 'EndpointConfigManagementMdeWithSccm',
	EndpointManagementNewServers = 'EndpointManagementNewServers',
	EndpointManagementOldWindows = 'EndpointManagementOldWindows',
	EntityDetectionContext = 'EntityDetectionContext',
	EvaluationActions = 'EvaluationActions',
	EvaluationExtendLab = 'EvaluationExtendLab',
	EvaluationLabConfiguration = 'EvaluationLabConfiguration',
	EvaluationOsTypeSupport = 'EvaluationOsTypeSupport',
	EvaluationSimulators = 'EvaluationSimulators',
	EvaluationLinuxSupport = 'EvaluationLinuxSupport',
	EvaluationWindowsServer2016Support = 'EvaluationWindowsServer2016Support',
	EvaluationWindows11Support = 'EvaluationWindows11Support',
	EvaluationDomainJoinSupport = 'EvaluationDomainJoinSupport',
	EvidenceFlatView = 'EvidenceFlatView',
	EvidenceGoHuntAction = 'EvidenceGoHuntAction',
	EvidenceGoHuntActionMtp = 'EvidenceGoHuntActionMtp',
	EvidenceMultiSelect = 'EvidenceMultiSelect',
	ExperienceMtp = 'ExperienceMtp',
	AatpIntegration = 'AatpIntegration',
	FairfaxAatpIntegration = 'FairfaxAatpIntegration',
	FairfaxAipIntegration = 'FairfaxAipIntegration',
	FairfaxLiveResponse = 'FairfaxLiveResponse',
	FairfaxLiveResponseUnsignedScripts = 'FairfaxLiveResponseUnsignedScripts',
	FairfaxMcasIntegration = 'FairfaxMcasIntegration',
	FairfaxOfficeIntegration = 'FairfaxOfficeIntegration',
	FairfaxPreviewExperience = 'FairfaxPreviewExperience',
	FairfaxSkypeIntegration = 'FairfaxSkypeIntegration',
	FairfaxWebCategories = 'FairfaxWebCategories',
	FairfaxThreatHunting = 'FairfaxThreatHunting',
	FairfaxIntuneIntegration = 'FairfaxIntuneIntegration',
	FileVerdict = 'FileVerdict',
	FilePrevalenceAndTopNamesNewApi = 'FilePrevalenceAndTopNamesNewApi',
	FilePrevalenceBuckets = 'FilePrevalenceBuckets',
	Flags = 'Flags',
	HighExposureCoachBubble = 'HighExposureCoachBubble',
	HistoricalDetection = 'HistoricalDetection',
	Hunting = 'Hunting',
	HuntingNavOnly = 'HuntingNavOnly',
	HuntingNLP = 'HuntingNLP',
	HuntingCommunityQueries = 'HuntingCommunityQueries',
	HuntingCustomActions = 'HuntingCustomActions',
	HuntingCustomActionsMTP = 'HuntingCustomActionsMTP',
	HuntingCustomDetectionUsingEvidence = 'HuntingCustomDetectionUsingEvidence',
	HuntingCustomDetectionMitreDropdown = 'HuntingCustomDetectionMitreDropdown',
	HuntingRecordSidePane = 'HuntingRecordSidePane',
	HuntingBulkActions = 'HuntingBulkActions',
	HuntingBulkActionsMtp = 'HuntingBulkActionsMtp',
	HuntingShareQuery = 'HuntingShareQuery',
	HuntingIntellisenseV2 = 'HuntingIntellisenseV2',
	HuntingDashboards = 'HuntingDashboards',
	HuntingEndpointMigrationHuntingDocumentation = 'HuntingEndpointMigrationHuntingDocumentation',
	HuntingEndpointMigrationGettingStartedApi = 'HuntingEndpointMigrationGettingStartedApi',
	HuntingEndpointMigrationQueriesController = 'HuntingEndpointMigrationQueriesController',
	HuntingEndpointMigrationQueriesControllerMtp = 'HuntingEndpointMigrationQueriesControllerMtp',
	HuntingEndpointMigrationActionsController = 'HuntingEndpointMigrationActionsController',
	HuntingEndpointMigrationValidateSchema = 'HuntingEndpointMigrationValidateSchema',
	HuntingGuidedQueries = 'HuntingGuidedQueries',
	HuntingEntitySidepane = 'HuntingEntitySidepane',
	HuntingFunctions = 'HuntingFunctions',
	HuntingStreamingDetection = 'HuntingStreamingDetection',
	HuntingSetTimeInQuery = 'HuntingSetTimeInQuery',
	HuntingNaturalLanguageAssistant = 'HuntingNaturalLanguageAssistant',
	HuntingUserPreferences = 'HuntingUserPreferences',
	HuntingSearchControl = 'HuntingSearchControl',
	HuntingManageAlerts = 'HuntingManageAlerts',
	HuntingPersistedSidePanel = 'HuntingPersistedSidePanel',
	HuntingQuotaReport = 'HuntingQuotaReport',
	HuntingCustomDetectionsByGuidedQuery = 'HuntingCustomDetectionsByGuidedQuery',
	HuntingRecordProcessTree = 'HuntingRecordProcessTree',
	HuntingGuidedHuntingGettingStartedQueries = 'HuntingGuidedHuntingGettingStartedQueries',
	ImportAlertProcessTree = 'ImportAlertProcessTree',
	ImprovedEntitySidePanels = 'ImprovedEntitySidePanels',
	ImprovedRemediationStatus = 'ImprovedRemediationStatus',
	ImprovedRemediationStatusSilentMode = 'ImprovedRemediationStatusSilentMode',
	ImprovedExecutionDetails = 'ImprovedExecutionDetails',
	Incidents = 'Incidents',
	IncidentV2 = 'IncidentV2',
	IncidentV2Tab = 'IncidentV2Tab',
	IncidentsPhase2 = 'IncidentsPhase2',
	IncidentAlertsGraph = 'IncidentAlertsGraph',
	IncidentGraphPoc = 'IncidentGraphPoc',
	IncidentGraph = 'IncidentGraph',
	IncidentPosture = 'IncidentPosture',
	IncidentOverview = 'IncidentOverview',
	IncidentOverviewMtp = 'IncidentOverviewMtp',
	IncidentPendingActionsWidget = 'IncidentPendingActionsWidget',
	IncidentTimeline = 'IncidentTimeline',
	IncidentEmailNotifications = 'MtpIncidentMailNotification',
	IndicatorMitreTechniques = 'IndicatorMitreTechniques',
	InternetFacing = 'InternetFacing',
	IntuneIntegration = 'IntuneIntegration',
	InvestigateMachine = 'InvestigateMachine',
	InvestigationActionAutoRefreshControl = 'InvestigationActionAutoRefreshControl',
	ItpStreamingApi = 'ItpStreamingApi',
	iOSOnboarding = 'iOSOnboarding',
	LiveResponse = 'LiveResponse',
	LiveResponseBackgroundEnabled = 'LiveResponseBackgroundEnabled',
	LiveResponseForMac = 'LiveResponseForMac',
	LiveResponseForLinux = 'LiveResponseForLinux',
	LinuxServerOnboarding = 'LinuxServerOnboarding',
	LinuxWorkstationOnboarding = 'LinuxWorkstationOnboarding',
	LogsCollection = 'LogsCollection',
	LsHashSimilarity = 'LsHashSimilarity',
	MachineHealthAndComplianceReport = 'MachineHealthAndComplianceReport',
	MachineGroupsRemediationLevelEdit = 'MachineGroupsRemediationLevelEdit',
	MachineRiskScore = 'MachineRiskScore',
	MachineSecurityScore = 'MachineSecurityScore',
	MachineTimelineAlwaysPrefetch = 'MachineTimelineAlwaysPrefetch',
	MachineTimelineDataCompleteness = 'MachineTimelineDataCompleteness',
	MachineTimelineDatePicker = 'MachineTimelineDatePicker',
	MachineTimelineDoNotUseCache = 'MachineTimelineDoNotUseCache',
	MachineTimelineEventGoHunt = 'MachineTimelineEventGoHunt',
	MachineTimelineFromCyber = 'MachineTimelineFromCyber',
	MachineTimelineGenerateMdiEvents = 'MachineTimelineGenerateMdiEvents',
	MachineTimelineIncludeMdiEvents = 'MachineTimelineIncludeMdiEvents',
	MachineTimelineMarkedEvents = 'MachineTimelineMarkedEvents',
	MachineTimelineMarkResponseEvents = 'MachineTimelineMarkResponseEvents',
	MachineTimelineObservations = 'MachineTimelineObservations',
	MachineTimelinePrefetchFromAlertSidePanel = 'MachineTimelinePrefetchFromAlertSidePanel',
	MachineTimelineVerboseTables = 'MachineTimelineVerboseTables',
	MachineTimelineWarmUpResponse = 'MachineTimelineWarmUpResponse',
	MacOsOnboarding = 'MacOsOnboarding',
	MapgAlerts = 'MapgAlerts',
	MdoMailMessagePanel = 'MdoMailMessagePanel',
	AadIp2Alerts = 'AadIp2Alerts',
	AadIp2AlertPage = 'AadIp2AlertPage',
	AadIp2AlertPageMtp = 'AadIp2AlertPageMtp',
	McasIntegration = 'McasIntegration',
	MtpActionCenter = 'MtpActionCenter',
	MtpActionCenterFilters = 'MtpActionCenterFilters',
	MtpCustomDetectionSccPage = 'MtpCustomDetectionSccPage',
	MtpCustomDetectionTimeline = 'MtpCustomDetectionTimeline',
	MtpCustomRolesSupport = 'MtpCustomRolesSupport',
	MtpIncidentMailNotificationDetectionSource = 'MtpIncidentMailNotificationDetectionSource',
	NonWindowsSupportControl = 'NonWindowsSupportControl',
	NonWindowsSupportControlV2 = 'NonWindowsSupportControlV2',
	NestedAlertsInIncident = 'NestedAlertsInIncident',
	NewTimebar = 'NewTimebar',
	O365SecureScore = 'O365SecureScore',
	OatpStreamingApi = 'OatpStreamingApi',
	OfficeAtpIntegration = 'OfficeAtpIntegration',
	OnboardingWizardUpgrade = 'OnboardingWizardUpgrade',
	OneClickSubmission = 'OneClickSubmission',
	ShowMixedLicensesStatus = 'ShowMixedLicensesStatus',
	Partners = 'Partners',
	PartnersPageV2 = 'PartnersPageV2',
	Playbooks = 'Playbooks',
	PowerBI = 'PowerBI',
	PuaAsSuspicious = 'PuaAsSuspicious',
	PuaMachineConfiguration = 'PuaMachineConfiguration',
	PrintAlert = 'PrintAlert',
	ProfessionalServicesPage = 'ProfessionalServicesPage',
	PreviewRbacGroupsMigrationEnabled = 'PreviewRbacGroupsMigrationEnabled',
	RbacMachineGroupsForMtp = 'RbacMachineGroupsForMtp',
	RbacMachineGroups = 'RbacMachineGroups',
	RbacUserRoles = 'RbacUserRoles',
	ReactEntityPanelsInAngular = 'ReactEntityPanelsInAngular',
	RefreshToken = 'RefreshToken',
	RemediationStatusSplit = 'RemediationStatusSplit',
	ReplaceSuspiciousActivitiesInDashboard = 'ReplaceSuspiciousActivitiesInDashboard',
	Reports = 'Reports',
	ResponseBlacklist = 'Response-Blacklist',
	ResponseExecutionPolicy = 'Response-ExecutionPolicy',
	ResponseForensic = 'Response-Forensic',
	ResponseIsolation = 'Response-Isolation',
	ResponseRemediation = 'Response-Remediation',
	ResponseScan = 'Response-Scan',
	ResponseSelectiveIsolation = 'Response-SelectiveIsolation',
	SecurityAnalyticsAggregationUX = 'SecurityAnalyticsAggregationUX',
	SecurityAnalyticsBitLockerControl = 'SecurityAnalyticsBitLockerControl',
	SecurityAnalyticsCredentialGuardControl = 'SecurityAnalyticsCredentialGuardControl',
	SecurityAnalyticsFirewallControl = 'SecurityAnalyticsFirewallControl',
	SecurityAnalyticsWinHelloControl = 'SecurityAnalyticsWinHelloControl',
	SecurityAnalyticsOsVersionUpgradeControl = 'SecurityAnalyticsOsVersionUpgradeControl',
	SelfContainedActionCenter = 'SelfContainedActionCenter',
	ServerEndpointManagement = 'ServerEndpointManagement',
	ShowAlertsQueueBannerForM365Incidents = 'ShowAlertsQueueBannerForM365Incidents',
	ShowDeprecatedMachinePageMessage = 'ShowDeprecatedMachinePageMessage',
	ShowDeprecatedIpPageMessage = 'ShowDeprecatedIpPageMessage',
	ShowDeprecatedFilePageMessage = 'ShowDeprecatedFilePageMessage',
	ShowDeprecatedUrlPageMessage = 'ShowDeprecatedUrlPageMessage',
	ShowMainSearch = 'ShowMainSearch',
	ShowScheduledHuntingOnMTP = 'ShowScheduledHuntingOnMTP',
	ShowUpgradeIpPageToggle = 'ShowUpgradeIpPageToggle',
	ShowUpgradeMachinePageToggle = 'ShowUpgradeMachinePageToggle',
	ShowUpgradeUrlPageToggle = 'ShowUpgradeUrlPageToggle',
	ShowUpgradeFilePageToggle = 'ShowUpgradeFilePageToggle',
	ShowUpgradeAlertPageToggle = 'ShowUpgradeAlertPageToggle',
	ShowNewHuntingPageToggle = 'ShowNewHuntingPageToggle',
	SimilarAlertsRecommendations = 'SimilarAlertsRecommendations',
	SiemOnboarding = 'SiemOnboarding',
	SiemOnboardingMssp = 'SiemOnboardingMssp',
	SimulationsAndTutorials = 'SimulationsAndTutorials',
	SkypeIntegration = 'SkypeIntegration',
	SupportCentral = 'SupportCentral',
	SupportDlpServiceSource = 'SupportDlpServiceSource',
	SystemGuardDIY = 'SystemGuardDIY',
	SuppressionRuleEditDelete = 'SuppressionRuleEditDelete',
	ThreatAnalytics2 = 'ThreatAnalytics2',
	ThreatAnalytics3 = 'ThreatAnalytics3',
	ThreatAnalyticsTabsView = 'ThreatAnalyticsTabsView',
	ThreatAnalyticsMTP = 'ThreatAnalyticsMTP',
	ThreatAnalyticsMTPForMDATP = 'ThreatAnalyticsMTPForMDATP',
	ThreatAnalyticsOutbreakTags = 'ThreatAnalyticsOutbreakTags',
	ThreatAnalyticsTimnaIntegration = 'ThreatAnalyticsTimnaIntegration',
	ThreatAnalyticsTimnaMitigations = 'ThreatAnalyticsTimnaMitigations',
	ThreatAnalyticsExposureLevel = 'ThreatAnalyticsExposureLevel',
	ThreatAnalyticsExposureLevelWidget = 'ThreatAnalyticsExposureLevelWidget',
	ThreatAnalyticsEmailNotifications = 'ThreatAnalyticsEmailNotifications',
	ThreatAnalyticsCustomDetection = 'ThreatAnalyticsCustomDetection',
	ThreatAnalyticsVNext = 'ThreatAnalyticsVNext',
	ThreatReport = 'ThreatReport',
	TiIndicators = 'TiIndicators',
	TimelineFullScreenToggling = 'TimelineFullScreenToggling',
	TroubleshootingMachine = 'TroubleshootingMachine',
	UnifiedRbac = 'UnifiedRbac',
	UnifiedRbacEnablementV2 = 'UnifiedRbacEnablementV2',
	UnifiedRbacMigration = 'UnifiedRbacMigration',
	UnifiedRbacEnforceByScope = 'UnifiedRbacEnforceByScope',
	UpdatedIncidentQueue = 'UpdatedIncidentQueue',
	UpdateMachinesValueMigration = 'UpdateMachinesValueMigration',
	UpgradeFilePage = 'UpgradeFilePage',
	UpgradeIpPage = 'UpgradeIpPage',
	UpgradeSearchFiles = 'UpgradeSearchFiles',
	UpgradeSearchMachines = 'UpgradeSearchMachines',
	UpgradeSearchUsers = 'UpgradeSearchUsers',
	UpgradeFileSidePane = 'UpgradeFileSidePane',
	UpgradeMachinePage = 'UpgradeMachinePage',
	UpgradeUrlPage = 'UpgradeUrlPage',
	UpgradeAlertPage = 'UpgradeAlertPage',
	UseSCCTimezoneSettings = 'UseSCCTimezoneSettings',
	UseSCCDomSanitizer = 'UseSCCDomSanitizer',
	UserDetails = 'UserDetails',
	UserLinks = 'User-Links',
	UserPreferencesPersistence = 'UserPreferencesPersistence',
	VdiEndpointManagement = 'VdiEndpointManagement',
	WebThreatProtectionReport = 'WebThreatProtectionReport',
	SmbPostOnboardingWizard = 'SmbPostOnboardingWizard',
	NewWindowsVersions = 'NewWindowsVersions',
	Windows7SupportControl = 'Windows7SupportControl',
	WindowsDefenderAtpEvaluation = 'WindowsDefenderAtpEvaluation',
	WindowsDefenderAtpEvaluationSummary = 'WindowsDefenderAtpEvaluationSummary',
	WindowsServer1803SupportControl = 'WindowsServer1803SupportControl',
	WindowsServer2019SupportControl = 'WindowsServer2019SupportControl',
	XPlatform = 'X-Platform',
	XplatIndicators = 'XplatIndicators',
	PetraNdrConvergenceUx = 'PetraNdrConvergenceUx',
	FileSensitivity = 'FileSensitivity',
	WdavAntiTampering = 'WdavAntiTampering',
	IncidentSensitivity = 'IncidentSensitivity',
	AlertSuppressionByCommandLine = 'AlertSuppressionByCommandLine',
	WebContentFilteringPolicy = 'WebContentFilteringPolicy',
	WS2012StandaloneAgentSupport = 'WS2012StandaloneAgentSupport',
	HuntingRuleFrequency = 'HuntingRuleFrequency',
	isHuntingSchemaChangePeriod = 'isHuntingSchemaChangePeriod',
	NewDevicePage = 'NewDevicePage',
	NewAlertProcessTree = 'NewAlertProcessTree',
	NewTimeLineData = 'NewTimeLineData',
	HuntingChartVisualization = 'HuntingChartVisualization',
	MTPDevicePage = 'MTPDevicePage',
	UnifiedMachineAPI = 'UnifiedMachineAPI',
	OfficeEntityDeepLinkInPanel = 'OfficeEntityDeepLinkInPanel',
	ReportsPlatform = 'ReportsPlatform',
	AlertDetailsMitreTechniques = 'AlertDetailsMitreTechniques',
	TVMApplicationBlock = 'TVMApplicationBlock',
	TvmApplicationUsage = 'TvmApplicationUsage',
	TvmPages = 'TvmPages',
	TvmApplicationControl = 'TvmApplicationControl',
	TvmRequiredAttention = 'TvmRequiredAttention',
	TvmEndOfLifeApplications = 'TvmEndOfLifeApplications',
	TvmProductEvidence = 'TvmProductEvidence',
	TvmEndOfLifeVersions = 'TvmEndOfLifeVersions',
	TvmDisplayRequester = 'TvmDisplayRequester',
	TvmServiceNowIntegration = 'TvmServiceNowIntegration',
	TvmScaIntuneIntegration = 'TvmScaIntuneIntegration',
	TvmExposeAsrRules = 'TvmExposeAsrRules',
	TvmTitleOrgConfigurationsAssessmentInsights = 'TvmTitleOrgConfigurationsAssessmentInsights',
	TvmRoles = 'TvmRoles',
	TvmScaEventInsights = 'TvmScaEventInsights',
	TvmExploits = 'TvmExploits',
	TvmDetectionLogic = 'TvmDetectionLogic',
	TvmMachinesReport = 'TvmMachinesReport',
	TvmComparisonKit = 'TvmComparisonKit',
	TvmRecommendationContext = 'TvmRecommendationContext',
	TvmThreatCampaigns = 'TvmThreatCampaigns',
	TvmMissingKbs = 'TvmMissingKbs',
	TvmRemediationODataFilters = 'TvmRemediationODataFilters',
	TvmVulnerabilitiesFilters = 'TvmVulnerabilitiesFilters',
	TvmNonCpePrograms = 'TvmNonCpePrograms',
	TvmMachineMissingKbs = 'TvmMachineMissingKbs',
	TvmFeedbackV2 = 'TvmFeedbackV2',
	NdrEnableVulnerabilitiesAssessment = 'NdrEnableVulnerabilitiesAssessment',
	NdrWindowsAuthScan = 'NdrWindowsAuthScan',
	TvmDataCookingEnableMacOS = 'TvmDataCookingEnableMacOS',
	TvmDataCookingEnableFastLane = 'TvmDataCookingEnableFastLane',
	TvmNetworkScan = 'TvmNetworkScan',
	TvmExportCsvFromSidePanel = 'TvmExportCsvFromSidePanel',
	TvmExposedOperatingSystems = 'TvmExposedOperatingSystems',
	TvmDepricatingSecureScoreMessage = 'TvmDepricatingSecureScoreMessage',
	TvmHideSecureScore = 'TvmHideSecureScore',
	TvmInventoriesMigration = 'TvmInventoriesMigration',
	TvmLog4jActionCard = 'TvmLog4jActionCard',
	TvmSpring4ShellActionCard = 'TvmSpring4ShellActionCard',
	TvmVulnerableDevicesActionCard = 'TvmVulnerableDevicesActionCard',
	TvmLog4jUberJars = 'TvmLog4jUberJars',
	TvmSpring4ShellAhQuery = 'TvmSpring4ShellAhQuery',
	TvmUnsupportedMachinesUI = 'TvmUnsupportedMachinesUI',
	TvmHumanOpRansomwareTags = 'TvmHumanOpRansomwareTags',
	TvmZeroDay = 'TvmZeroDay',
	TvmRemediationTaskDeviceGroups = 'TvmRemediationTaskDeviceGroups',
	TvmBaselineCompliance = 'TvmBaselineCompliance',
	TvmBaselineComplianceForLinux = 'TvmBaselineComplianceForLinux',
	TvmBaselineCustomizeValue = 'TvmBaselineCustomizeValue',
	TvmBaselineAssessProfiles = 'TvmBaselineAssessProfiles',
	TvmCommonExposedDevices = 'TvmCommonExposedDevices',
	PortedSccPages = 'PortedSccPages',
	TvmMachineValue = 'TvmMachineValue',
	TvmUpcomingEolVersions = 'TvmUpcomingEolVersions',
	TvmExceptionsPerRbac = 'TvmExceptionsPerRbac',
	TvmAdminEndpointManagementCenter = 'TvmAdminEndpointManagementCenter',
	TvmDataCookingEnableDebian = 'TvmDataCookingEnableDebianOs',
	TvmDataCookingEnableAndroid = 'TvmDataCookingEnableAndroid',
	TvmDataCookingEnableiOS = 'TvmDataCookingEnableiOS',
	TvmDataCookingEnableAmazonLinux = 'TvmDataCookingEnableAmazonLinux',
	TvmMigrationBaselineCompliancePage = 'TvmMigrationBaselineCompliancePage',
	TvmCertificateAssessment = 'TvmCertificateAssessment',
	TvmPremium = 'TvmPremium',
	TvmPremiumTrial = 'TvmPremiumTrial',
	TvmPremiumTrialPublicPreview = 'TvmPremiumTrialPublicPreview',
	TvmPremiumTrialNonEasyTrial = 'TvmPremiumTrialNonEasyTrial',
	TvmBrowserExtensions = 'TvmBrowserExtensions',
	TvmBrowserExtensionsFirefox = 'TvmBrowserExtensionsFirefox',
	TvmUsedFilesStats = 'TvmUsedFilesStats',
	TvmLog4jOpenPorts = 'TvmLog4jOpenPorts',
	TvmThreatCampaignWidget = 'TvmThreatCampaignWidget',
	TvmMitigationMacLinux = 'TvmMitigationMacLinux',
	TvmWeaknessesPanelTabs = 'TvmWeaknessesPanelTabs',
	TvmVulnerabilityDetailsPage = 'TvmVulnerabilityDetailsPage',
	TvmBaselineDevicesAssessment = 'TvmBaselineDevicesAssessment',
	TvmNoPatchVulnerabilities = 'TvmNoPatchVulnerabilities',
	MtpExposePreviewFeaturesToggle = 'MtpExposePreviewFeaturesToggle',
	HuntingDocumentation = 'HuntingDocumentation',
	TvmMEMPermission = 'TvmMEMPermission',
	MtpAutoDcSelection = 'MtpAutoDcSelection',
	MtpInvestigationDetails = 'MtpInvestigationDetails',
	HuntingTabSupport = 'HuntingTabSupport',
	HuntingNewPageByDefault = 'HuntingNewPageByDefault',
	KustoFileProfileEnrichment = 'KustoFileProfileEnrichment',
	TvmDisplayMessageBar = 'TvmDisplayMessageBar',
	OfficeEntityDetails = 'OfficeEntityDetails',
	ComponentProvisioningInScc = 'ComponentProvisioningInScc',
	MgmtAuditTrail = 'MgmtAuditTrail',
	MgmtLearningHub = 'MgmtLearningHub',
	MgmtEndpointsP1 = 'MgmtEndpointsP1',
	QuarantinedFileDownload = 'QuarantinedFileDownload',
	TvmVulnerabilityNotifications = 'TvmVulnerabilityNotifications',
	ShowHuntingNotificationBar = 'ShowHuntingNotificationBar',
	ShowHuntingNotificationBarMdatp = 'ShowHuntingNotificationBarMdatp',
	MtpForOptedOut = 'MtpForOptedOut',
	AatpAlertPageEnabled = 'AatpAlertPageEnabled',
	AlertToEventAlertPage = 'AlertToEventAlertPage',
	MteAlertPage = 'MteAlertPage',
	DlpAlertPageEnabled = 'DlpAlertPageEnabled',
	TvmEnableWindows81 = 'TvmDataCookingEnableWindows81',
	UseSccProxy = 'UseSccProxy',
	ExternalMailHuntingQuery = 'ExternalMailHuntingQuery',
	MdatpPortalRedirection = 'MdatpPortalRedirection',
	TvmCancelAllSelectedRemediationExceptionRbacs = 'TvmCancelAllSelectedRemediationExceptionRbacs',
	MachinesApiServiceMigration = 'MachinesApiServiceMigration',
	ExposedToOptOut = 'ExposedToOptOut',
	ForceExplicitMtpConsent = 'ForceExplicitMtpConsent',
	EnableControllingRbacConstraint = 'EnableControllingRbacConstraint',
	EnableDartDataCollection = 'EnableDartDataCollection',
	ReplaceDailyMachineCountInDashboard = 'ReplaceDailyMachineCountInDashboard',
	UseTvmMachinesAvStatus = 'UseTvmMachinesAvStatus',
	K8SMigrationIncidentTagsKW = 'K8SMigration-IncidentTags-kw',
	K8SMigrationThreatAnalytics = 'K8SMigration-ThreatAnalytics',
	K8SMigrationFileProfileKW = 'K8SMigration-FileProfile-KW',
	EmailClusterExtraData = 'EmailClusterExtraData',
	Server2019BackportSupportToRS6 = 'Server2019BackportSupportToRS6',
	Server2022BackportSupport = 'Server2022BackportSupport',
	LiveResponseForWindowsServer2016 = 'LiveResponseForWindowsServer2016',
	LiveResponseForWindowsServer2012R2 = 'LiveResponseForWindowsServer2012R2',
	LiveResponseForServerExcludeTenant = 'LiveResponseForServerExcludeTenant',
	ConvergencePromotion = 'ConvergencePromotion',
	ConvergencePromotionL2 = 'ConvergencePromotionL2',
	ConvergencePromotionL3 = 'ConvergencePromotionL3',
	DapDowngradeToPromotionL2Part1 = 'DapDowngradeToPromotionL2Part1',
	DapDowngradeToPromotionL2Part2 = 'DapDowngradeToPromotionL2Part2',
	SccDateFormat = 'SccDateFormat',
	AutoIrSccActionCenterWidget = 'AutoIrSccActionCenterWidget',
	DeviceInventoeryDisplayNotManagedDevices = 'DeviceInventoeryDisplayNotManagedDevices',
	EnableWebContentFilterUncategorizedV2 = 'EnableWebContentFilterUncategorizedV2',
	RbacGroupsProgressApiMigration = 'RbacGroupsProgressApiMigration',
	IncidentsDashboardWidget = 'IncidentsDashboardWidget',
	DeviceGroupsSupportDeviceType = 'DeviceGroupsSupportDeviceType',
	UnifiedExperienceConvergence = 'UnifiedExperienceConvergence',
	EnableCommunityNotifications = 'EnableCommunityNotifications',
	MdoManualActions = 'MdoManualActions',
	ActionCenterWidgetRefactor = 'ActionCenterWidgetRefactor',
	AssignToSomeoneElse = 'AssignToSomeoneElse',
	NewFilePage = 'NewFilePage',
	SecureScoreEntityLevel = 'SecureScoreEntityLevel',
	IncidentsSearchByTitle = 'IncidentsSearchByTitle',
	ConsiderApplicationAsAsset = 'ConsiderApplicationAsAsset',
	DataviewFilterWithHttpPost = 'DataviewFilterWithHttpPost',
	DataviewFilterWithHttpPostSilenceMode = 'DataviewFilterWithHttpPostSilenceMode',
	BulkCloudActions = 'BulkCloudActions',
	LiveResponseReportCancelCreateSession = 'LiveResponseReportCancelCreateSession',
	ExcludedDevices = 'ExcludedDevices',
	EvidenceApiV2 = 'EvidenceApiV2',
	CombinedClassification = 'CombinedClassification',
	HuntingEmailSidepane = 'HuntingEmailSidepane',
	LiveResponseDirectFileUpload = 'LiveResponseDirectFileUpload',
	UnifiedUrlPage = 'UnifiedUrlPage',
	ShowUnifiedUrlPageToggle = 'ShowUnifiedUrlPageToggle',
	HuntingChartVisualizationV2 = 'HuntingChartVisualizationV2',
	LiveResponseTransitionCodeSeparation = 'LiveResponseTransitionCodeSeparation',
	ImprovedIpSidePanel = 'ImprovedIpSidePanel',
	ImprovedIpSidePanelSilent = 'ImprovedIpSidePanelSilent',
	DeviceHealthReport = 'DeviceHealthReport',
	AlertRecommendedActions = 'AlertRecommendedActions',
	ReactIncidentsQueue = 'ReactIncidentsQueue',
	IncidentsQueueInPageFilters = 'IncidentsQueueInPageFilters',
	MagellanDevicePageSeenByField = 'MagellanDevicePageSeenByField',
	NewSuppressionRuleCreation = 'NewSuppressionRuleCreation',
	DeviceHealthReportPhase2 = 'DeviceHealthReportPhase2',
	MagellanDevicesToOnboardPercentageWidget = 'MagellanDevicesToOnboardPercentageWidget',
	MagellanDevicesToOnboardActionCard = 'MagellanDevicesToOnboardActionCard',
	ActionCenterApiV2 = 'ActionCenterApiV2',
	ServiceAlertSettings = 'ServiceAlertSettings',
	FilterIncidentsByRelatedEvidence = 'FilterIncidentsByRelatedEvidence',
	SMBDynamicGroups = 'SMBDynamicGroups',
	EnablePolicyTroubleshootingForDevice = 'EnablePolicyTroubleshootingForDevice',
	InProgressIncidentStatus = 'InProgressIncidentStatus',
	TvmPremiumMigration = 'TvmPremiumMigration',
	TvmPremiumMigrationShowBanner = 'TvmPremiumMigrationShowBanner',
	EnableAngularDarkTheme = 'EnableAngularDarkTheme',
	IncidentsGlobalSearch = 'IncidentsGlobalSearch',
	MachinesControllerMigrationGetTags = 'MachinesControllerMigrationGetTags',
	MachinesControllerMigrationEditTags = 'MachinesControllerMigrationEditTags',
	AadIp2AlertsMtp = 'AadIp2AlertsMtp',
	SmbGradualOnboarding = 'SmbGradualOnboarding',
	MdeAttachOnByDefaultWindowsServerForSmbTenants = 'MdeAttachOnByDefaultWindowsServerForSmbTenants',
	SOCPlaybook = 'SOCPlaybook',
	SmbUserPermissionsStep = 'SmbUserPermissionsStep',
	SmbMailNotificationsStep = 'SmbMailNotificationsStep',
	SmbOnboardingWizardMdeAttachToggle = 'SmbOnboardingWizardMdeAttachToggle',
	IncidentsQueueExportButton = 'IncidentsQueueExportButton',
	IncidentDisruptBanner = 'IncidentDisruptBanner',
	AutoIrUnifiedIpPanel = 'AutoIrUnifiedIpPanel',
	AutoIrUnifiedFilePanel = 'AutoIrUnifiedFilePanel',
	IncidentListSearchBox = 'IncidentListSearchBox',
	K8SMigrationAlertsLinksKW = 'K8SMigration-AlertsLinks-kw',
	FileSearchByTopFileNames = 'FileSearchByTopFileNames',
	EnableMteClassicPSFlow = 'EnableMteClassicPSFlow',
	AutoIrFolderExclusionService = 'AutoIrFolderExclusionService',
	PolicyGetDevicesEnrichingWithMdeDeviceId = 'PolicyGetDevicesEnrichingWithMdeDeviceId',
	AlertIocsFromApi = 'AlertIocsFromApi',
	EnableExpertsOnDemandPSFlow = 'EnableExpertsOnDemandPSFlow',
	EnableUserVnextMigration = 'EnableUserVnextMigration',
	ReactMachineTimeline = 'ReactMachineTimeline',
	HuntingMigrateGoHuntToVNext = 'HuntingMigrateGoHuntToVNext',
}

/*
Deleting FFs is temporarily disabled by management team - these feature are no longer in use and should be deleted once possible:

IncidentAssociatedIncidents
AutoInvestigationEnabled
AlertsInIncidentQueue

 */
