var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { ComponentRef } from '@angular/core';
import { DataCache, Paris } from '@microsoft/paris';
import { values, omit, countBy } from 'lodash-es';
import { BehaviorSubject, combineLatest, Observable, of } from 'rxjs';
import { map, mergeMap, tap, switchMap } from 'rxjs/operators';
import { Machine, MachineGroup, MachineSecurityAnalyticsRelationship, MachineTagsCollectionRelationship, Tag, TagType, MachineValue, OperatingSystemPlatformCategories, } from '@wcd/domain';
import { Router } from '@angular/router';
import { PanelType, PanelService } from '@wcd/panels';
import { DialogsService } from '../../../dialogs/services/dialogs.service';
import { RbacService } from '../../../rbac/services/rbac.service';
import { MachineIpsPanelComponent } from '../components/machine-ips.panel.component';
import { MachineLoggedOnUsersPanelComponent } from '../components/machine-logged-on-users.panel.component';
import { MachineSecurityStateComponent } from '../components/machine-score.component';
import { MachineCveComponent } from '../components/machine-security-cve.component';
import { DeviceCategory } from '../models/machine.model';
import { MachinesBackendService } from './machines.backend.service';
import { MachinesFiltersService } from './machines.filters.service';
import { FeaturesService, Feature, FlavorService } from '@wcd/config';
import { TvmOsSupportService } from '../../../tvm/services/tvm-os-support.service';
import { MachineResourcesPanelComponent, } from '../components/machine-itp-resources.panel.component';
import { MachineDirectoryDataPanelComponent, } from '../components/machine-itp-directory-data.panel.component';
import { I18nService } from '@wcd/i18n';
import { MachineValuePanelComponent } from '../components/panel/machine-value/machine-value-panel.component';
import { IotDevicesFieldsService } from './iot-fields-service';
import { DataviewField } from '@wcd/dataview';
import { RegExpService } from '@wcd/shared';
import { AppFlavorConfig } from '@wcd/scc-common';
var fieldIdOrderMap = {
    riskScores: 'riskscore',
    exposureScores: 'exposurescore',
    healthStatuses: 'healthstatus',
    deviceTypes: 'deviceType',
};
export var isMachineExportResponse = function (obj) { return !!(obj && obj.isPartial); };
var MachinesService = /** @class */ (function () {
    function MachinesService(dialogsService, machinesFiltersService, backendService, rbacService, paris, featuresService, osSupportService, router, i18nService, iotDevicesFieldsService, flavorService, panelService) {
        var _this = this;
        this.dialogsService = dialogsService;
        this.machinesFiltersService = machinesFiltersService;
        this.backendService = backendService;
        this.rbacService = rbacService;
        this.paris = paris;
        this.featuresService = featuresService;
        this.osSupportService = osSupportService;
        this.router = router;
        this.i18nService = i18nService;
        this.iotDevicesFieldsService = iotDevicesFieldsService;
        this.flavorService = flavorService;
        this.panelService = panelService;
        this._allMachineTagsCache = new DataCache({
            time: 1000 * 60 * 5,
            max: 1,
            getter: function () {
                return _this.backendService.getMachineGroups().pipe(tap(function () { return _this.machinesFiltersService.clearMachinesFilters(); }), map(function (data) {
                    return values(data.UserDefinedTags).map(function (tag) { return new Tag({ id: tag, name: tag, type: TagType.user }); });
                }));
            },
        });
        this.machineValuePseudoTags = [
            this.doGetMachineValuePseudoTag([MachineValue.High]),
            this.doGetMachineValuePseudoTag([MachineValue.Normal]),
            this.doGetMachineValuePseudoTag([MachineValue.Low]),
        ];
        this.displayedMachines$ = new BehaviorSubject([]);
        this.machineTagsRepo = this.paris.getRelationshipRepository(MachineTagsCollectionRelationship);
        this.machineGroupsRepo = this.paris.getRepository(MachineGroup);
        this.machineValues = [
            {
                id: MachineValue.Low,
                displayName: i18nService.get('machines_entityDetails_actions_machineValue_values_low'),
            },
            {
                id: MachineValue.Normal,
                displayName: i18nService.get('machines_entityDetails_actions_machineValue_values_normal'),
            },
            {
                id: MachineValue.High,
                displayName: i18nService.get('machines_entityDetails_actions_machineValue_values_high'),
            },
        ];
    }
    MachinesService.prototype.getMachinesFilters = function (options) {
        if (this.featuresService.isEnabled(Feature.TvmMachineValue)) {
            return this.machinesFiltersService.getMachinesFiltersWithPseudoTags(options, this.machineValuePseudoTags);
        }
        else {
            return this.machinesFiltersService.getMachinesFilters(options);
        }
    };
    MachinesService.prototype.clearMachinesFilters = function () {
        this.machinesFiltersService.clearMachinesFilters();
    };
    MachinesService.prototype.downloadCsv = function (options) {
        var sortByField = options.ordering ? options.ordering.replace(/^-/, '') : 'alerts';
        var sortDirection = options.ordering && options.ordering.startsWith('-') ? 'Descending' : 'Ascending';
        var parsedOptions = Object.assign({}, omit(options, ['page', 'page_size', 'ordering']), {
            sortByField: 'alerts',
        }, options.ordering
            ? {
                sortByField: fieldIdOrderMap[sortByField] || sortByField,
                sortOrder: sortDirection,
            }
            : undefined);
        var hasOutbreakId = !!options['outbreakId'];
        var hasMitigationTypes = !!options['mitigationTypes'];
        if (hasOutbreakId !== hasMitigationTypes) {
            parsedOptions = omit(parsedOptions, ['outbreakId', 'mitigationTypes']);
        }
        return this.backendService.downloadCsv(parsedOptions);
    };
    MachinesService.prototype.getMachineLatestSecurityState = function (senseMachineId) {
        var _this = this;
        return this.paris
            .getItemById(Machine, senseMachineId)
            .pipe(mergeMap(function (machine) {
            return _this.paris.getRelatedItem(MachineSecurityAnalyticsRelationship, machine);
        }));
    };
    MachinesService.prototype.getAllUserDefinedMachineTags = function () {
        return this._allMachineTagsCache.get(true);
    };
    MachinesService.prototype.clearAllUserDefinedMachineTags = function () {
        this._allMachineTagsCache.remove(true);
    };
    MachinesService.prototype.showMachineScores = function (machineSecurityAnalytics) {
        return this.dialogsService.showPanel(MachineSecurityStateComponent, {
            id: 'machine-scores',
            type: PanelType.large,
            noBodyPadding: true,
            persistOnNavigate: false,
        }, {
            machineSecurityAnalytics: machineSecurityAnalytics,
        });
    };
    MachinesService.prototype.getMachineWithAlertParams = function (alert) {
        if (this.featuresService.isEnabled(Feature.UpgradeMachinePage)) {
            var alertTime = alert && (alert.lastEventTime || alert.lastSeen);
            if (alertTime) {
                // adding extra millisec to the to time to guarantee that tha alert event won't be sliced in the BE.
                alertTime.setMilliseconds(alertTime.getMilliseconds() + 1);
                var alertFrom = new Date(alertTime);
                alertFrom.setDate(alertTime.getDate() - 7);
                return {
                    from: alertFrom.toISOString(),
                    to: alertTime.toISOString(),
                    alert: alert.id,
                };
            }
            return null;
        }
        else {
            return {
                alert: alert.id,
                time: alert && (alert.lastEventTime || alert.lastSeen).toISOString(),
            };
        }
    };
    MachinesService.prototype.getMachineLink = function (machineId, includeQueryParams, alertLastEventTime, alertId) {
        if (includeQueryParams === void 0) { includeQueryParams = true; }
        try {
            /*
            Cases of IP machine page - without a supported machine page - MCAS/AATP
            */
            if (RegExpService.ip.test(machineId)) {
                return null;
            }
            if (this.featuresService.isEnabled(Feature.UpgradeMachinePage)) {
                var alertParams = includeQueryParams &&
                    this.getMachineWithAlertParams({
                        lastEventTime: alertLastEventTime,
                        id: alertId,
                    });
                var route = ['machines', machineId];
                if (alertLastEventTime)
                    route.push('timeline');
                return this.router.serializeUrl(this.router.createUrlTree(route, includeQueryParams
                    ? {
                        queryParams: alertParams,
                    }
                    : {}));
            }
            return this.getLegacyMachineLink(machineId, alertLastEventTime);
        }
        catch (e) {
            return '';
        }
    };
    MachinesService.prototype.getLegacyMachineLink = function (machineId, alertLastEventTime) {
        return "/_machine/" + machineId + (alertLastEventTime ? '/' + alertLastEventTime.toISOString() : '');
    };
    MachinesService.prototype.showMachineCvesPanel = function (machineSecurityCves) {
        var _this = this;
        var panelSettings = Object.assign({
            id: 'machine-security-cves',
            type: PanelType.large,
            persistOnNavigate: false,
            scrollBody: true,
            back: { onClick: function () { return _this._machineCveComponent.destroy(); } },
        });
        var machinePanelInputs = {
            machineSecurityCves: machineSecurityCves,
        };
        this.dialogsService
            .showPanel(MachineCveComponent, panelSettings, machinePanelInputs)
            .subscribe(function (panel) {
            _this._machineCveComponent = panel;
            panel.onDestroy(function () {
                _this._machineCveComponent = null;
            });
        });
    };
    MachinesService.prototype.showMachineLoggedOnUsers = function (machine, allowBack, settings) {
        var _this = this;
        if (allowBack === void 0) { allowBack = false; }
        if (!(machine &&
            machine.os &&
            machine.os.platform &&
            machine.os.platform.category === OperatingSystemPlatformCategories.Linux)) {
            var panelSettings = Object.assign({
                id: 'machine-logged-on-users-panel',
                type: PanelType.large,
                isModal: true,
                showOverlay: false,
                isBlocking: false,
                noBodyPadding: true,
                scrollBody: false,
                headerElementId: 'machine-logged-on-users-panel-header',
                back: allowBack
                    ? {
                        onClick: function () { return _this._loggedOnUsersPanel.destroy(); },
                    }
                    : null,
            }, settings);
            var panelInputs = {
                machine: machine,
            };
            this.dialogsService
                .showPanel(MachineLoggedOnUsersPanelComponent, panelSettings, panelInputs)
                .subscribe(function (panel) {
                _this._loggedOnUsersPanel = panel;
                panel.onDestroy(function () {
                    _this._loggedOnUsersPanel = null;
                });
            });
        }
    };
    MachinesService.prototype.showMachineResources = function (machine, allowBack, settings) {
        var _this = this;
        if (allowBack === void 0) { allowBack = false; }
        var panelSettings = Object.assign({
            id: 'machine-resources-panel',
            type: PanelType.large,
            isModal: true,
            showOverlay: false,
            isBlocking: true,
            noBodyPadding: true,
            scrollBody: true,
            headerElementId: 'machine-resources-panel-header',
            back: allowBack
                ? {
                    onClick: function () { return _this._resourcesPanel.destroy(); },
                }
                : null,
        }, settings);
        var panelInputs = {
            resources: machine.resources,
        };
        return this.dialogsService.showPanel(MachineResourcesPanelComponent, panelSettings, panelInputs).pipe(tap(function (panel) {
            _this._resourcesPanel = panel;
            panel.onDestroy(function () {
                _this._resourcesPanel = null;
            });
        }));
    };
    MachinesService.prototype.showMachineDirectoryData = function (machine, allowBack, settings) {
        var _this = this;
        if (allowBack === void 0) { allowBack = false; }
        var panelSettings = Object.assign({
            id: 'machine-directory-data-panel',
            type: PanelType.large,
            isModal: true,
            showOverlay: false,
            isBlocking: true,
            noBodyPadding: true,
            scrollBody: true,
            headerElementId: 'machine-directory-data-panel-header',
            back: allowBack
                ? {
                    onClick: function () { return _this._directoryDataPanel.destroy(); },
                }
                : null,
        }, settings);
        var panelInputs = {
            machine: machine,
        };
        return this.dialogsService
            .showPanel(MachineDirectoryDataPanelComponent, panelSettings, panelInputs)
            .pipe(tap(function (panel) {
            _this._directoryDataPanel = panel;
            panel.onDestroy(function () {
                _this._directoryDataPanel = null;
            });
        }));
    };
    MachinesService.prototype.showMachineIpsPanel = function (machine) {
        return this.dialogsService.showPanel(MachineIpsPanelComponent, {
            id: 'machine-ips-panel',
            type: PanelType.largeFixed,
            width: 500,
            persistOnNavigate: false,
            noBodyPadding: true,
        }, {
            machine: machine,
        });
    };
    MachinesService.prototype.updateMachinesTags = function (machines, tags) {
        this.machinesFiltersService.clearMachineTagsApiCall();
        this._allMachineTagsCache.remove(true);
        return this.backendService.updateMachinesTags(machines.map(function (machine) { return machine.internalMachineId; }), tags);
    };
    MachinesService.prototype.clearCachedMachines = function () {
        this.paris.getRepository(Machine).clearCache();
    };
    // TODO: move to machine-groups.service/rbac.service
    MachinesService.prototype.getFullUserExposedMachineGroups = function (options) {
        var allMachineGroups$ = options
            ? this.machineGroupsRepo.query(options).pipe(map(function (dataSet) { return dataSet.items; }))
            : this.machineGroupsRepo.allItems$;
        return combineLatest([this.rbacService.userExposedRbacGroups$, allMachineGroups$]).pipe(map(function (_a) {
            var userExposedMachineGroups = _a[0], allMachineGroups = _a[1];
            return ((userExposedMachineGroups &&
                userExposedMachineGroups.length &&
                allMachineGroups.filter(function (machineGroup) {
                    return userExposedMachineGroups.find(function (userExposedMachineGroup) { return userExposedMachineGroup.id === machineGroup.id; });
                })) ||
                []);
        }));
    };
    MachinesService.prototype.supportTvmTabs = function (machine) {
        return this.osSupportService.isMachineSupported(machine);
    };
    MachinesService.prototype.setMachinesValue = function (machines) {
        var _this = this;
        var machineValue;
        var panel;
        return new Promise(function (resolve, reject) {
            _this.panelService
                .create(MachineValuePanelComponent, { id: 'machine-value-panel', type: PanelType.large, showOverlay: true }, { machines: machines })
                .pipe(switchMap(function (panel_) {
                panel = panel_;
                return panel.instance.onMachineValueChanged;
            }), switchMap(function (machineValue_) {
                machineValue = machineValue_;
                return _this.updateMachinesValues(machines, machineValue);
            }), switchMap(function (_) {
                machines.forEach(function (machine) { return (machine.assetValue = machineValue); });
                return of(null);
            }))
                .subscribe(function (_) {
                _this.clearCachedMachines();
                panel.instance.requestSuccess();
                resolve();
            }, function (e) {
                panel.instance.requestFail(e);
                reject(e);
            });
        });
    };
    MachinesService.prototype.getMachineValuePseudoTag = function (machines) {
        var pseudoTags = [];
        if (this.featuresService.isEnabled(Feature.TvmMachineValue)) {
            var machinesValues = Object.keys(countBy(machines, function (machine) { return machine.assetValue; }));
            if (Object.keys(machinesValues).length > 1 || machines[0].assetValue !== MachineValue.Normal) {
                pseudoTags.push(this.doGetMachineValuePseudoTag(machinesValues));
            }
        }
        return of(pseudoTags.filter(Boolean));
    };
    MachinesService.prototype.doGetMachineValuePseudoTag = function (machineValues) {
        var name = this.i18nService.get('machines_entityDetails_actions_machineValue_title') + ": " + machineValues.join();
        var isPartial = machineValues.length > 1;
        return new Tag({
            id: isPartial ? 'mixed' : machineValues[0],
            name: name,
            isPartial: isPartial,
            className: isPartial || machineValues[0] !== MachineValue.High
                ? 'tag-color-box-user'
                : 'tag-color-box-highValueAsset',
            type: TagType.user,
            tooltip: name + " - " + this.i18nService.get('machines_entityDetails_actions_machineValue_values_tag_tooltip'),
        });
    };
    MachinesService.prototype.updateMachinesValues = function (machines, value) {
        return this.backendService.updateMachinesValues(machines.map(function (machine) { return machine.senseMachineId; }), value);
    };
    MachinesService.prototype.getSearchViewConfig = function (dataViewOptions, magellanOptOut) {
        var displayOnlyMangedDevices = magellanOptOut || !this.flavorService.isEnabled(AppFlavorConfig.devices.iotDevices);
        var originalFields = dataViewOptions.fields;
        var additionalFilters = displayOnlyMangedDevices ? { isManagedByMdatp: true } : {};
        var fields = displayOnlyMangedDevices
            ? originalFields.filter(function (field) { return field.id !== 'onBoardingStatuses'; })
            : originalFields;
        var defaultDataViewConfig = dataViewOptions.dataViewConfig;
        var defaultFixedOptions = dataViewOptions.dataViewConfig['fixedOptions'];
        var getFiltersData = function (options) {
            return dataViewOptions.dataViewConfig.getFiltersData(__assign({}, options));
        };
        var fixedOptions = __assign({}, defaultFixedOptions, additionalFilters);
        var dataViewConfig = __assign({}, defaultDataViewConfig, { fixedOptions: fixedOptions, getFiltersData: getFiltersData });
        return __assign({}, dataViewOptions, { dataViewConfig: dataViewConfig, fields: fields });
    };
    MachinesService.prototype.getTabViewConfig = function (dataViewOptions, _a) {
        var onlyManagedDevices = _a.onlyManagedDevices, deviceCategories = _a.deviceCategories, mainCategory = _a.mainCategory;
        var tabParams = {};
        if (typeof onlyManagedDevices !== 'undefined') {
            tabParams.isManagedByMdatp = onlyManagedDevices;
        }
        if (deviceCategories.length > 0) {
            tabParams.deviceCategories = deviceCategories.join(',');
        }
        var fields = mainCategory !== DeviceCategory.Endpoint
            ? this.iotDevicesFieldsService.getFields(dataViewOptions.fields, mainCategory === DeviceCategory.NetworkDevice)
            : dataViewOptions.fields;
        var defaultDataViewConfig = dataViewOptions.dataViewConfig;
        var defaultFixedOptions = dataViewOptions.dataViewConfig['fixedOptions'];
        var getFiltersData = function (options) {
            return dataViewOptions.dataViewConfig.getFiltersData(__assign({}, options, { mainCategory: mainCategory }));
        };
        var fixedOptions = __assign({}, defaultFixedOptions, tabParams);
        var dataViewConfig = __assign({}, defaultDataViewConfig, { fixedOptions: fixedOptions, getFiltersData: getFiltersData });
        return __assign({}, dataViewOptions, { dataViewConfig: dataViewConfig, fields: fields });
    };
    MachinesService.prototype.isIotOrNetworkDevices = function (deviceCategories) {
        return (deviceCategories.includes(DeviceCategory.IoT) ||
            deviceCategories.includes(DeviceCategory.NetworkDevice));
    };
    return MachinesService;
}());
export { MachinesService };
