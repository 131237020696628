var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (b.hasOwnProperty(p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
import { ChangeDetectorRef, OnInit } from '@angular/core';
import { LegacyUser, LegacyUserMachineAssociatedInfo } from '@wcd/domain';
import { EntityPanelComponentBase } from '../../../global_entities/components/entity-panels/entity-panel.component.base';
import { Paris } from '@microsoft/paris';
import { GlobalEntityTypesService } from '../../../global_entities/services/global-entity-types.service';
import { ActivatedEntity } from '../../../global_entities/services/activated-entity.service';
import { tap, filter, map, switchMap } from 'rxjs/operators';
import { MachinesService } from '../../machines/services/machines.service';
import { I18nService } from '@wcd/i18n';
var UserObservedMachinePanelComponent = /** @class */ (function (_super) {
    __extends(UserObservedMachinePanelComponent, _super);
    function UserObservedMachinePanelComponent(changeDetectorRef, machinesService, paris, activatedEntity, globalEntityTypesService, i18nService) {
        var _this = _super.call(this, changeDetectorRef) || this;
        _this.machinesService = machinesService;
        _this.paris = paris;
        _this.activatedEntity = activatedEntity;
        _this.globalEntityTypesService = globalEntityTypesService;
        _this.i18nService = i18nService;
        return _this;
    }
    Object.defineProperty(UserObservedMachinePanelComponent.prototype, "userObservedMachine", {
        get: function () {
            return this.entity;
        },
        enumerable: true,
        configurable: true
    });
    UserObservedMachinePanelComponent.prototype.ngOnInit = function () {
        var _this = this;
        this.userLogons$ = this.activatedEntity.currentEntity$.pipe(filter(function (entity) { return entity instanceof LegacyUser; }), tap(function (user) {
            _this.user = user;
        }), switchMap(function (user) {
            return _this.paris
                .getRepository(LegacyUserMachineAssociatedInfo)
                .query({
                where: {
                    userAccount: user.accountName,
                    userAccountDomain: user.accountDomainName,
                    senseMachineId: _this.userObservedMachine.machine.senseMachineId,
                },
            })
                .pipe(map(function (dataSet) { return dataSet.items; }));
        }));
    };
    UserObservedMachinePanelComponent.prototype.showLoggedOnUsers = function () {
        this.machinesService.showMachineLoggedOnUsers(this.userObservedMachine.machine, true, {
            noShadow: true,
            hasCloseButton: false,
        });
    };
    UserObservedMachinePanelComponent.prototype.trackByLogonType = function (index, item) {
        return item.logonType;
    };
    UserObservedMachinePanelComponent.prototype.getLogonText = function (logonType) {
        return this.i18nService.strings['users_entityDetails_logonType_' + logonType] || logonType;
    };
    return UserObservedMachinePanelComponent;
}(EntityPanelComponentBase));
export { UserObservedMachinePanelComponent };
