import { Injectable } from '@angular/core';
import { ServiceUrls } from '@wcd/domain';

import { AppConfigService } from '../../app-config/services/app-config.service';

const AIRS_CUSTOM_URL_KEY = 'airsUrl';
const THREAT_INTEL_CUSTOM_URL_KEY = 'threatIntelUrl';
const CMS_CUSTOM_URL_KEY = 'cmsUrl';

@Injectable({ providedIn: 'root' })
export class ServiceUrlsService implements ServiceUrls {
	constructor(private readonly appConfigService: AppConfigService) { }

	get authorization(): string {
		return this.appConfigService.serviceUrls.authorization;
	}

	get feedback(): string {
		return this.appConfigService.serviceUrls.feedback;
	}

	get management(): string {
		return this.appConfigService.serviceUrls.management;
	}

	get settings(): string {
		return this.appConfigService.serviceUrls.settings;
	}

	get officeOptIn(): string {
		return this.appConfigService.serviceUrls.officeOptIn;
	}

	get connectors(): string {
		return this.appConfigService.serviceUrls.connectors;
	}

	get packages(): string {
		return this.appConfigService.serviceUrls.packages;
	}

	get deepAnalysis(): string {
		return this.appConfigService.serviceUrls.deepAnalysis;
	}

	get cyberData(): string {
		return this.appConfigService.serviceUrls.cyberData;
	}

	get serviceHealth(): string {
		return this.appConfigService.serviceUrls.serviceHealth;
	}

	get cms(): string {
		return localStorage.getItem(CMS_CUSTOM_URL_KEY) || this.appConfigService.serviceUrls.cms;
	}

	get onboarding(): string {
		return this.appConfigService.serviceUrls.onboarding;
	}

	get serveronboarding(): string {
		return this.appConfigService.serviceUrls.serveronboarding;
	}

	get rbacManagementApi(): string {
		return this.appConfigService.serviceUrls.rbacManagementApi;
	}

	get threatIntel(): string {
		return (
			localStorage.getItem(THREAT_INTEL_CUSTOM_URL_KEY) || this.appConfigService.serviceUrls.threatIntel
		);
	}

	get tvm(): string {
		return this.appConfigService.serviceUrls.tvm;
	}

	get userRequests(): string {
		return this.appConfigService.serviceUrls.userRequests;
	}

	get automatedIr(): string {
		return localStorage.getItem(AIRS_CUSTOM_URL_KEY) || this.appConfigService.serviceUrls.automatedIr;
	}

	get securityAnalytics(): string {
		return this.appConfigService.serviceUrls.securityAnalytics;
	}

	get threatAnalytics(): string {
		return this.appConfigService.serviceUrls.threatAnalytics;
	}

	get publicApi(): string {
		return this.appConfigService.serviceUrls.publicApi;
	}

	get analytics(): string {
		return this.appConfigService.serviceUrls.analytics;
	}

	get k8s(): string {
		return this.appConfigService.serviceUrls.k8s;
	}

	get suppressionRulesService(): string {
		return this.appConfigService.serviceUrls.suppressionRulesService;
	}

	get webThreatProtection(): string {
		return this.appConfigService.serviceUrls.webThreatProtection;
	}

	get wdatpApi(): string {
		return this.appConfigService.serviceUrls.wdatpApi;
	}

	get wdatpInternalApi(): string {
		return this.appConfigService.serviceUrls.wdatpInternalApi;
	}

	get assetsBaseUrl(): string {
		return this.appConfigService.serviceUrls.assetsBaseUrl || '';
	}

	get userPreferences(): string {
		return this.appConfigService.serviceUrls.userPreferences;
	}

	get incidentQueue(): string {
		return this.appConfigService.serviceUrls.incidentQueue;
	}

	get incidents(): string {
		return this.appConfigService.serviceUrls.incidents;
	}

	get incidentUpdate(): string {
		return this.appConfigService.serviceUrls.incidentUpdate;
	}

	get huntingService(): string {
		return this.appConfigService.serviceUrls.huntingService;
	}

	get secureScore(): string {
		return this.appConfigService.serviceUrls.secureScore;
	}

	get CustomerSubmissionService(): string {
		return this.appConfigService.serviceUrls.CustomerSubmissionService;
	}

	get activeAlertsSummary(): string {
		return this.appConfigService.serviceUrls.activeAlertsSummary;
	}

	get cyberProfileService(): string {
		return this.appConfigService.serviceUrls.cyberProfileService;
	}

	get MEMAdminCenterUrl(): string {
		return this.appConfigService.serviceUrls.MEMAdminCenterUrl;
	}

	get mte(): string {
		return this.appConfigService.serviceUrls.mte;
	}

	get alertsLinks(): string {
		return this.appConfigService.serviceUrls.alertsLinks;
	}

	get alertsEmailNotifications(): string {
		return this.appConfigService.serviceUrls.alertsEmailNotifications;
	}

	get defenderExpertsService(): string {
		return this.appConfigService.serviceUrls.defenderExpertsService;
	}

	get incidentMachineSensitivities(): string {
		return this.appConfigService.serviceUrls.incidentMachineSensitivities;
	}

	get incidentMachineGroups(): string {
		return this.appConfigService.serviceUrls.incidentMachineGroups;
	}

	get files(): string {
		return this.appConfigService.serviceUrls.files;
	}

	get filedownloads(): string {
		return this.appConfigService.serviceUrls.filedownloads;
	}

	get fileprevalence(): string {
		return this.appConfigService.serviceUrls.fileprevalence;
	}

	get urls(): string {
		return this.appConfigService.serviceUrls.urls;
	}
	get incidentDevices(): string {
		return this.appConfigService.serviceUrls.incidentDevices;
	}

	get incidentUsers(): string {
		return this.appConfigService.serviceUrls.incidentUsers;
	}

	get incidentMailboxes(): string {
		return this.appConfigService.serviceUrls.incidentMailboxes;
	}

	get incidentApps(): string {
		return this.appConfigService.serviceUrls.incidentApps;
	}

	get incidentImpactedEntitiesView(): string {
		return this.appConfigService.serviceUrls.incidentImpactedEntitiesView;
	}

	get incidentTags(): string {
		return this.appConfigService.serviceUrls.incidentTags;
	}

	get outbreaks(): string {
		return this.appConfigService.serviceUrls.outbreaks;
	}

	get userGroups(): string {
		return this.appConfigService.serviceUrls.userGroups;
	}

	get sensitivityLabels(): string {
		return this.appConfigService.serviceUrls.sensitivityLabels;
	}

	get relatedIncidents(): string {
		return this.appConfigService.serviceUrls.relatedIncidents;
	}
}
