/* tslint:disable:template-click-events-have-key-events */
import { ChangeDetectorRef, Component, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { EntityPanelComponentBase } from '../../../global_entities/components/entity-panels/entity-panel.component.base';
import {
	Alert,
	AlertHistoryItem,
	AlertStatus,
	AlertVariable,
	Investigation,
	Machine,
	MtpInvestigation,
	SuppressionRule,
	Incident,
	AlertDetectionSourceType,
	MachineTimelinePrefetchApiCall,
	AlertClassification,
	AlertDetermination,
	OfficeUtils, ServiceSourceType,
} from '@wcd/domain';
import { CommentModel } from '../../../comments/models/comment.model';
import { Observable, Subscription } from 'rxjs';
import { DataSet, Paris, Repository } from '@microsoft/paris';
import {
	SuppressionRulePanelMode,
	SuppressionRulesService,
} from '../../suppression_rules/services/suppression-rules.service';
import { finalize, map, mergeMap } from 'rxjs/operators';
import { ItemActionModel } from '../../../dataviews/models/item-action.model';
import { AuthUser } from '@wcd/auth';
import { MachinesService } from '../../machines/services/machines.service';
import { RbacService } from '../../../rbac/services/rbac.service';
import { GlobalEntityTypesService } from '../../../global_entities/services/global-entity-types.service';
import { AppContextService, Feature, FeaturesService, FlavorService } from '@wcd/config';
import { EntityDetailsMode } from 'app/global_entities/models/entity-details-mode.enum';
import { MachineEntityDetailsComponent } from '../../../global_entities/components/entity-details/machine.entity-details.component';
import { AlertLinkedByDetailsComponent } from './alert-linked-by-details.component';
import { MessageBarType, IMessageBarStyles } from 'office-ui-fabric-react';
import { AlertsService } from '../services/alerts.service';
import { I18nService } from '@wcd/i18n';
import { AppConfigService } from '@wcd/app-config';
import { AppFlavorConfig } from '@wcd/scc-common';
import { MCAS_HOST } from '@wcd/shared';
import { sccHostService } from '@wcd/scc-interface';
import { baseUrlsSettings } from '@wcd/shared';

enum CollapsibleID {
	AlertsManage = 'alert-entity-panel-manage',
	Details = 'alert-entity-panel-details',
	Description = 'alert-entity-panel-description',
	RecommendedAction = 'alert-entity-panel-recommended-action',
	Actor = 'alert-entity-panel-actor',
	LinkByIncident = 'alert-entity-panel-link-by-incident',
	ImpactedEntities = 'alert-entity-panel-impacted-entities',
	ImpactedAssets = 'alert-entity-panel-impacted-assets',
	RelatedEvidence = 'alert-entity-panel-related-evidence',
	RelatedMachine = 'alert-entity-panel-related-machine',
	RelatedInvestigation = 'alert-entity-panel-related-investigation',
	AlertComments = 'alert-entity-panel-comments',
	RelatedIncidentAlerts = 'alert-entity-panel-related-incident-alerts',
}

const investigationMessageBarStyle: IMessageBarStyles = {
	icon: {
		lineHeight: '20px',
	},
};

const AUTOMATION_COMMENT_USER = 'Automation';

@Component({
	selector: 'alert-entity-panel',
	templateUrl: './alert.entity-panel.component.html',
	styleUrls: ['./alert.entity-panel.component.scss'],
})
export class AlertEntityPanelComponent extends EntityPanelComponentBase<Alert, AlertEntityDetailsOptions>
	implements OnDestroy, OnInit {
	get alert(): Alert {
		return this.entity;
	}

	alertPanelExtended: boolean;
	isMessageBarVisible: boolean;
	isTimelineEnabled: boolean;
	investigationEnabledByLicense: boolean;

	@ViewChild(MachineEntityDetailsComponent, { static: false })
	machineDetailsCmp: MachineEntityDetailsComponent;
	@ViewChild(AlertLinkedByDetailsComponent, { static: false })
	alertLinkedByDetailsComponent: AlertLinkedByDetailsComponent;

	investigationMessageBarStyle = investigationMessageBarStyle;

	EntityDetailsMode = EntityDetailsMode;
	MessageBarType = MessageBarType;
	AlertDetectionSourceType = AlertDetectionSourceType;

	history: Array<CommentModel>;
	machine: Machine;
	incident: Incident;
	suppressionRule: SuppressionRule;
	investigation: Investigation;
	variables: Array<AlertVariable>;

	isLoadingMachine: boolean = false;
	isLoadingInvestigation: boolean = false;
	isLoadingHistory: boolean = false;
	isLoadingVariables: boolean = false;
	oAuthAlertPageLink: string;

	loadMachineError: boolean = false;
	isMtpEnabled: boolean = this.appContextService.isMtp;

	collapsibleID = CollapsibleID;
	shortInvestigationId: number | string;

	private _onActionSubscription: Subscription;

	constructor(
		private suppressionRulesService: SuppressionRulesService,
		private paris: Paris,
		private machinesService: MachinesService,
		private rbacService: RbacService,
		public globalEntityTypesService: GlobalEntityTypesService,
		private appContextService: AppContextService,
		private featuresService: FeaturesService,
		private alertsService: AlertsService,
		changeDetectorRef: ChangeDetectorRef,
		public i18nService: I18nService,
		private appConfigService: AppConfigService,
		flavorService: FlavorService
	) {
		super(changeDetectorRef);
		this.alertPanelExtended = flavorService.isEnabled(AppFlavorConfig.alerts.extraPanelDetails);
		this.investigationEnabledByLicense = flavorService.isEnabled(AppFlavorConfig.alerts.investigation);
		this.isMessageBarVisible = flavorService.isEnabled(AppFlavorConfig.alerts.messageBar);
		this.isTimelineEnabled = flavorService.isEnabled(AppFlavorConfig.devices.isTimelineEnabled);
	}

	ngOnInit() {
		super.ngOnInit();

		this._onActionSubscription = this.action$.subscribe(
			($event: { action: ItemActionModel; data: any }) => this.onAction($event.action, $event.data)
		);
	}

	ngOnDestroy() {
		this._onActionSubscription && this._onActionSubscription.unsubscribe();
	}

	updateMachineDetailsTable(): void {
		if (this.machineDetailsCmp) {
			this.machineDetailsCmp.updateTableView();
		}
	}

	updateLinkByTable(): void {
		if (this.alertLinkedByDetailsComponent) {
			this.alertLinkedByDetailsComponent.updateTableView();
		}
	}

	private onAction(action: ItemActionModel, data: any) {
		if (action.id === 'alertAssignToMe') {
			const clonedAlert: Alert = Object.create(this.alert);
			clonedAlert.assignedTo = (<AuthUser>data).name;
			this.setEntity(clonedAlert, true);
		} else if (action.id === 'alertSuppressionRule') {
			this.suppressionRule = data;
		}

		this.changeDetectorRef.markForCheck();
	}

	protected initEntity(alert: Alert, isExtendedData: boolean = false) {
		super.initEntity(alert);

		this.machine = this.history = this.investigation = this.shortInvestigationId = this.suppressionRule = null;
		const appId = alert.oAuthAlertPageLinkDetails && alert.oAuthAlertPageLinkDetails.oAuthAppId;
		if (appId) {
			const mcasBase = sccHostService.isSCC ? baseUrlsSettings.MCASPortalUrls : MCAS_HOST;
			// TODO: this is a temporary workaround until we have one unified application page in SCC portal
			this.oAuthAlertPageLink = alert.serviceSource.id ===  ServiceSourceType.Mcas ? `${mcasBase}/#/app?oauthAppId=${appId}` : `/cloudapps/app-governance?viewid=allApps&objid=${appId}`;
		}
		this.changeDetectorRef.markForCheck();

		if (isExtendedData && this.isUserExposed$.value) {
			this.setMachine();
			this.setIncident();
			this.setHistory();
			this.setInvestigation();
			this.setVariables();
			this.setSuppressionRule();

			// Warm up BE with a prefetch call to prepare the device timeline
			if (
				this.isTimelineEnabled &&
				this.featuresService.isAnyEnabled([
					Feature.NewTimeLineData,
					Feature.MachineTimelineAlwaysPrefetch,
				]) &&
				alert.machine
			) {
				const alertTime = new Date(alert.machine.lastEventTime || alert.machine.lastSeen);
				const alertFrom: Date = new Date(alertTime);
				alertFrom.setDate(alertTime.getDate() - 7);

				const dataQuery = {
					where: {
						machineId: this.alert.machine.id,
						machineDnsName: this.alert.machine.name,
						entityType: 'machines',
						entityId: this.alert.machine.id,
						fromDate: alertFrom.toISOString(),
						toDate: alertTime.toISOString(),
						pageSize: 200,
						useCyberData: true,
						generateIdentityEvents: this.featuresService.isEnabled(Feature.MachineTimelineGenerateMdiEvents)
					},
				};
				this.paris.apiCall(MachineTimelinePrefetchApiCall, dataQuery).subscribe();
			}
		}
	}

	private setVariables() {
		if (!this.options.showVariables) {
			this.variables = null;
			return;
		}

		this.isLoadingVariables = true;
	}

	private setMachine() {
		if (this.options.showMachine !== false && this.alert.machine && this.alert.machine.id) {
			this.isLoadingMachine = true;
			this.changeDetectorRef.markForCheck();
			this.loadMachineError = false;

			this.addExtraDataSubscription(
				this.rbacService
					.isUserExposedToEntity(
						this.globalEntityTypesService.getEntityType(Machine),
						this.alert.machine
					)
					.subscribe(
						userExposureResult => {
							if (userExposureResult.isExposed) {
								this.addExtraDataSubscription(
									this.paris.getItemById(Machine, this.alert.machine.id).subscribe(
										(machine: Machine) => {
											this.machine = machine;
											this.isLoadingMachine = false;
											this.changeDetectorRef.markForCheck();
										},
										() => {
											this.loadMachineError = true;
											this.isLoadingMachine = false;
											this.changeDetectorRef.markForCheck();
										}
									)
								);
							} else {
								this.isLoadingMachine = false;
								this.changeDetectorRef.markForCheck();
							}
						},
						error => {
							this.isLoadingMachine = false;
							this.loadMachineError = true;
							this.changeDetectorRef.markForCheck();
						}
					)
			);
		}

		this.changeDetectorRef.markForCheck();
	}

	private setIncident() {
		if (this.alert.incidentId) {
			this.paris
				.getItemById(Incident, this.alert.incidentId)
				.subscribe(incident => (this.incident = incident));
		}
	}

	showLoggedOnUsers() {
		this.machinesService.showMachineLoggedOnUsers(this.machine, true, {
			noShadow: true,
			hasCloseButton: false,
		});
	}

	private setInvestigation() {
		if (this.options.showInvestigation !== false && this.alert.investigationId) {
			this.isLoadingInvestigation = true;
			this.changeDetectorRef.markForCheck();

			const item$: Observable<Investigation> =
				this.featuresService.isEnabled(Feature.UnifiedExperienceConvergence) &&
				this.alert.isOfficeInvestigation
					? this.paris.getItemById(MtpInvestigation, this.alert.investigationId, null, {
							tenantId: this.appConfigService.tenantId,
					  })
					: this.paris.getItemById(Investigation, this.alert.investigationId);

			this.addExtraDataSubscription(
				item$
					.pipe(
						finalize(() => {
							this.isLoadingInvestigation = false;
							this.changeDetectorRef.markForCheck();
						})
					)
					.subscribe((investigation: Investigation) => {
						this.investigation = investigation;
						this.shortInvestigationId = OfficeUtils.getShortId(this.investigation.id);
					})
			);
		}

		this.changeDetectorRef.markForCheck();
	}

	private setHistory() {
		this.changeDetectorRef.markForCheck();
		if (this.options && this.options.showHistory === false) {
			this.isLoadingHistory = false;
			return;
		}
		this.isLoadingHistory = true;

		this.addExtraDataSubscription(
			this.paris
				.query(AlertHistoryItem, { where: { id: this.alert.id } })
				.pipe(
					map((dataSet: DataSet<AlertHistoryItem>) => dataSet.items),
					mergeMap((alertHistoryItems: Array<AlertHistoryItem>) => {
						return this.getAlertCreatedHistoryItem().pipe(
							map((createItem: AlertHistoryItem) => {
								return [...alertHistoryItems, createItem].map(
									(alertHistoryItem: AlertHistoryItem) => {
										return new CommentModel({
											id: alertHistoryItem.id,
											timestamp: alertHistoryItem.timestamp,
											user: alertHistoryItem.userName,
											message: this.alertsService.getAlertHistoryMessage(
												alertHistoryItem
											),
											icon: alertHistoryItem.type.icon,
										});
									}
								);
							})
						);
					})
				)
				.subscribe(
					(historyItems: Array<CommentModel>) => {
						this.history = this.alertPanelExtended
							? historyItems
							: historyItems.filter(item => item.user !== AUTOMATION_COMMENT_USER);
						this.isLoadingHistory = false;
						this.changeDetectorRef.markForCheck();
					},
					error => {
						this.isLoadingHistory = false;
						this.changeDetectorRef.markForCheck();
					}
				)
		);
	}

	private setSuppressionRule() {
		if (this.alert.suppressionRuleId) {
			this.addExtraDataSubscription(
				this.paris
					.getRepository(SuppressionRule)
					.getItemById(this.alert.suppressionRuleId)
					.subscribe((suppressionRule: SuppressionRule) => {
						this.suppressionRule = suppressionRule;
						this.changeDetectorRef.markForCheck();
					})
			);
		}
	}

	private getAlertCreatedHistoryItem(): Observable<AlertHistoryItem> {
		const alertHistoryItemsRepo: Repository<AlertHistoryItem> = this.paris.getRepository(
			AlertHistoryItem
		);

		return alertHistoryItemsRepo.createItem({
			AuditId: `alert_create_${this.alert.id}`,
			Type: 'Create',
			Timestamp: this.alert.firstSeen.toString(),
		});
	}

	openSuppressionRulePanel(): void {
		this.suppressionRulesService.showRulePanel(
			SuppressionRulePanelMode.create,
			this.suppressionRule ? this.suppressionRule.id : null,
			this.alert
		);
	}

	onStatusChanged(status: AlertStatus) {
		const clonedAlert: Alert = Object.create(this.alert);
		clonedAlert.status = status;
		this.setEntity(clonedAlert, true);

		if (status.type === 'InProgress') {
			this.runEntityAction('alertAssignToMe');
		}
		this.refreshOnClose(true);
	}

	onClassificationChanged(classification: AlertClassification) {
		const clonedAlert: Alert = Object.create(this.alert);
		clonedAlert.classification = classification;
		this.setEntity(clonedAlert, true);
		this.refreshOnClose(true);
	}

	onAssigneeChanged(assignee: string) {
		const clonedAlert: Alert = Object.create(this.alert);
		clonedAlert.assignedTo = assignee;
		this.setEntity(clonedAlert, true);
		this.refreshOnClose(true);
	}

	onDeterminationChanged(determination: AlertDetermination) {
		const clonedAlert: Alert = Object.create(this.alert);
		clonedAlert.determination = determination;
		this.setEntity(clonedAlert, true);
		this.refreshOnClose(true);
	}

	getAlertRelatedEvidenceCount(alert: Alert): number {
		if (!alert.relatedEvidenceCount) {
			return 0;
		}

		return Object.values(alert.relatedEvidenceCount).reduce(
			(acc: number, entitiesCount: number) => acc + (entitiesCount ? entitiesCount : 0),
			0
		);
	}
}

export interface AlertEntityDetailsOptions {
	showInvestigation: boolean;
	showMachine: boolean;
	showVariables: boolean;
	showHistory: boolean;
}
