var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (b.hasOwnProperty(p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
/* tslint:disable:template-click-events-have-key-events */
/* tslint:disable:template-accessibility-label-for */
import { Injector, OnInit } from '@angular/core';
import { lowerFirst } from 'lodash-es';
import { EntityDetailsComponentBase } from './entity-details.component.base';
import { Incident } from '@wcd/domain';
import { IncidentsService } from '../../../@entities/incidents/services/incidents.service';
import { AuthService, MtpPermission } from '@wcd/auth';
import { AppContextService, FeaturesService, Feature } from '@wcd/config';
import { EntityDetailsMode } from '../../models/entity-details-mode.enum';
import { EntityPageViewMode } from '../../models/entity-page-view-mode.enum';
import { TryItUrls } from '../../../mtp-promotion/enums/try-it-urls';
import { MtpPromotionService } from '../../../mtp-promotion/services/mtp-promotion.service';
import { I18nService } from '@wcd/i18n';
import { RbacControlService } from '../../../rbac/services/rbac-control.service';
import { RoutesService } from '@wcd/shared';
import { FlavorService } from '@wcd/config';
import { AppFlavorConfig } from '@wcd/scc-common';
import { AppInsightsService } from '../../../insights/services/app-insights.service';
var CollapsibleID;
(function (CollapsibleID) {
    CollapsibleID["Details"] = "incident-entity-details";
    CollapsibleID["Tags"] = "incident-entity-tags";
})(CollapsibleID || (CollapsibleID = {}));
var IncidentEntityDetailsComponent = /** @class */ (function (_super) {
    __extends(IncidentEntityDetailsComponent, _super);
    function IncidentEntityDetailsComponent(injector, incidentsService, authService, featuresService, mtpPromotionService, appContextService, i18nService, rbacControlService, flavorService, appInsightsService) {
        var _this = _super.call(this, injector) || this;
        _this.incidentsService = incidentsService;
        _this.authService = authService;
        _this.featuresService = featuresService;
        _this.mtpPromotionService = mtpPromotionService;
        _this.appContextService = appContextService;
        _this.i18nService = i18nService;
        _this.rbacControlService = rbacControlService;
        _this.flavorService = flavorService;
        _this.appInsightsService = appInsightsService;
        _this.EntityPageViewMode = EntityPageViewMode;
        _this.urls = TryItUrls;
        _this.EntityDetailsMode = EntityDetailsMode;
        _this.phishingPlaybook = false;
        _this.ransomwarePlaybook = false;
        _this.collapsibleID = CollapsibleID;
        _this.getClassification = function (incident) {
            return incident.classification
                ? _this.i18nService.get(incident.classification.nameI18nKey)
                : "(" + _this.i18nService.get('alerts.unclassified') + ")";
        };
        _this.getCategoryName = function (category) {
            return _this.i18nService.get('reporting_alertsByCategory_' + lowerFirst(category));
        };
        _this.incidentGraphPOCEnabled =
            featuresService.isEnabled(Feature.IncidentGraphPoc) &&
                _this.appContextService.isSCC && //link won't work outside of SCC
                flavorService.isEnabled(AppFlavorConfig.incidents.incidentGraph); // will only be seen to P2 license and other workloads
        return _this;
    }
    Object.defineProperty(IncidentEntityDetailsComponent.prototype, "incident", {
        get: function () {
            return this.entity;
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(IncidentEntityDetailsComponent.prototype, "showAdditionalAlertsMessage", {
        get: function () {
            return this.incident && this.incident.mtpAlertsExist && !this.appContextService.isMtp;
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(IncidentEntityDetailsComponent.prototype, "SCCIncidentUrl", {
        get: function () {
            return RoutesService.getSCCRootUrl() + "/incidents/" + this.incident.id + "/overview";
        },
        enumerable: true,
        configurable: true
    });
    IncidentEntityDetailsComponent.prototype.ngOnInit = function () {
        var rbacSettings = {
            mtpPermissions: [MtpPermission.SecurityData_Manage],
            mtpWorkloads: this.incident.mtpWorkloads,
            requireAllPermissions: true,
            ignoreNonActiveWorkloads: true,
        };
        this.isFullyMachineRbacExposed =
            this.incident.isFullyMachineRbacExposed &&
                this.rbacControlService.hasRequiredRbacPermissions(rbacSettings);
        this.showAssociatedIncidents = this.flavorService.isEnabled(AppFlavorConfig.incidents.associatedIncident);
        var SOCPlaybook = this.featuresService.isEnabled(Feature.SOCPlaybook);
        this.ransomwarePlaybook = window['SupportCentral'] && window['SupportCentral'].LinkTo && SOCPlaybook && this.isRansomware;
        this.phishingPlaybook =
            window['SupportCentral'] && window['SupportCentral'].LinkTo && SOCPlaybook && this.isPhishing;
    };
    IncidentEntityDetailsComponent.prototype.showSetClassificationPanel = function () {
        this.incidentsService.showSetClassificationPanel([this.incident]).subscribe();
    };
    Object.defineProperty(IncidentEntityDetailsComponent.prototype, "incidentStatusColor", {
        get: function () {
            var incidentColor = this.incidentsService.getIncidentsCommonColorClass([this.incident]);
            return this.incident.status.className === 'success' ? 'color-box-success' : incidentColor;
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(IncidentEntityDetailsComponent.prototype, "incidentGraphLink", {
        get: function () {
            return "/incident-graph/" + this.incident.id;
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(IncidentEntityDetailsComponent.prototype, "isPhishing", {
        get: function () {
            var phishing = this.incident.alerts.some(function (alert) { return PhishingAlertIds[alert.ioaDefinitionId]; });
            phishing && this.appInsightsService.trackEvent('displaying_phishing_recommendations_card');
            return phishing;
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(IncidentEntityDetailsComponent.prototype, "isRansomware", {
        get: function () {
            var reg = /ransomware/gim;
            var ransomware = reg.test(this.incident.name) || this.incident.incidentTags.isRansomware;
            ransomware && this.appInsightsService.trackEvent('displaying_ransomware_recommendations_card');
            return ransomware;
        },
        enumerable: true,
        configurable: true
    });
    IncidentEntityDetailsComponent.prototype.openSocPlayBook = function (searchTerm) {
        this.appInsightsService.trackEvent('soc_playbook_opened', {
            searchTerm: searchTerm,
        });
        window['SupportCentral'].LinkTo('SearchHelp', '', searchTerm);
    };
    return IncidentEntityDetailsComponent;
}(EntityDetailsComponentBase));
export { IncidentEntityDetailsComponent };
var PhishingAlertIds = {
    'a74bb32a-541b-47fb-adfd-f8c62ce3d59b': 1,
    '4b1820ec-39dc-45f3-abf6-5ee80df51fd2': 1,
    '8e6ba277-ef39-404e-aaf1-294f6d9a2b88': 1,
    'c8522cbb-9368-4e25-4ee9-08d8d899dfab': 1,
    'b8f6b088-5487-4c70-037c-08d8d71a43fe': 1,
    'a5c402b2-eba9-4f9d-a0dd-a0c65db97200': 1,
    'dd95beba-b950-4166-a983-5c2c136a9e6b': 1,
    'ce5b94b7-eafb-4b3f-8d44-a0a86245e62b': 1,
    '8bd89c8d-1425-45ba-838a-e15fb89808d2': 1,
    'c2a1f0cd-a669-49bc-a22b-e501350935e3': 1,
};
