var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (b.hasOwnProperty(p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
var __metadata = (this && this.__metadata) || function (k, v) {
    if (typeof Reflect === "object" && typeof Reflect.metadata === "function") return Reflect.metadata(k, v);
};
import { ApiCall, ApiCallModel, ModelBase, ValueObject, EntityField } from '@microsoft/paris';
import { HuntingRuleEntityType } from '../hunting-rule-impacted-entity.value-object';
import { HuntingRuleCustomSupportedActionType } from '../hunting-rule-custom-action.entity';
var HuntingQueryEntityIdentifiers = /** @class */ (function (_super) {
    __extends(HuntingQueryEntityIdentifiers, _super);
    function HuntingQueryEntityIdentifiers() {
        return _super !== null && _super.apply(this, arguments) || this;
    }
    __decorate([
        EntityField({ data: 'EntityType' }),
        __metadata("design:type", String)
    ], HuntingQueryEntityIdentifiers.prototype, "entityType", void 0);
    __decorate([
        EntityField({ data: 'AllSingleIdentifiers' }),
        __metadata("design:type", Array)
    ], HuntingQueryEntityIdentifiers.prototype, "idFields", void 0);
    HuntingQueryEntityIdentifiers = __decorate([
        ValueObject({
            pluralName: 'Hunting entity identifiers',
            singularName: 'Hunting entity identifiers',
        })
    ], HuntingQueryEntityIdentifiers);
    return HuntingQueryEntityIdentifiers;
}(ModelBase));
export { HuntingQueryEntityIdentifiers };
var HuntingQuerySupportedAction = /** @class */ (function (_super) {
    __extends(HuntingQuerySupportedAction, _super);
    function HuntingQuerySupportedAction() {
        return _super !== null && _super.apply(this, arguments) || this;
    }
    __decorate([
        EntityField({ data: 'ActionType' }),
        __metadata("design:type", Number)
    ], HuntingQuerySupportedAction.prototype, "actionType", void 0);
    __decorate([
        EntityField({ data: 'Entities', arrayOf: HuntingQueryEntityIdentifiers }),
        __metadata("design:type", Array)
    ], HuntingQuerySupportedAction.prototype, "entities", void 0);
    __decorate([
        EntityField({ data: 'IsEnabled', defaultValue: true }),
        __metadata("design:type", Boolean)
    ], HuntingQuerySupportedAction.prototype, "isEnabled", void 0);
    HuntingQuerySupportedAction = __decorate([
        ValueObject({
            pluralName: 'Hunting supported actions',
            singularName: 'Hunting supported action',
        })
    ], HuntingQuerySupportedAction);
    return HuntingQuerySupportedAction;
}(ModelBase));
export { HuntingQuerySupportedAction };
var ɵ0 = function (config) { return config.data.serviceUrls.huntingService; };
var HuntingQuerySupportedActionsApiCall = /** @class */ (function (_super) {
    __extends(HuntingQuerySupportedActionsApiCall, _super);
    function HuntingQuerySupportedActionsApiCall() {
        return _super !== null && _super.apply(this, arguments) || this;
    }
    HuntingQuerySupportedActionsApiCall = __decorate([
        ApiCall({
            name: 'Get supported custom actions for hunting rule',
            endpoint: 'customactions/supported',
            baseUrl: ɵ0,
            method: 'POST',
            type: HuntingQuerySupportedAction,
        })
    ], HuntingQuerySupportedActionsApiCall);
    return HuntingQuerySupportedActionsApiCall;
}(ApiCallModel));
export { HuntingQuerySupportedActionsApiCall };
var ɵ1 = function (config) { return config.data.serviceUrls.threatIntel; }, ɵ2 = function (record) { return ({
    data: { ColumnNames: Object.keys(record.dataItem) },
}); };
var HuntingSupportedBulkActionsApiCall = /** @class */ (function (_super) {
    __extends(HuntingSupportedBulkActionsApiCall, _super);
    function HuntingSupportedBulkActionsApiCall() {
        return _super !== null && _super.apply(this, arguments) || this;
    }
    HuntingSupportedBulkActionsApiCall = __decorate([
        ApiCall({
            name: 'Get supported bulk actions for hunting result set',
            endpoint: 'hunting/bulkActions/supported',
            baseUrl: ɵ1,
            method: 'POST',
            type: HuntingQuerySupportedAction,
            parseQuery: ɵ2,
        })
    ], HuntingSupportedBulkActionsApiCall);
    return HuntingSupportedBulkActionsApiCall;
}(ApiCallModel));
export { HuntingSupportedBulkActionsApiCall };
var ɵ3 = function (config) { return config.data.serviceUrls.threatIntel; }, ɵ4 = function (columnNames) { return ({
    data: { ColumnNames: columnNames },
}); };
var HuntingSupportedBulkActionsByColumnsApiCall = /** @class */ (function (_super) {
    __extends(HuntingSupportedBulkActionsByColumnsApiCall, _super);
    function HuntingSupportedBulkActionsByColumnsApiCall() {
        return _super !== null && _super.apply(this, arguments) || this;
    }
    HuntingSupportedBulkActionsByColumnsApiCall = __decorate([
        ApiCall({
            name: 'Get supported bulk actions for hunting result set',
            endpoint: 'hunting/bulkActions/supported',
            baseUrl: ɵ3,
            method: 'POST',
            type: HuntingQuerySupportedAction,
            parseQuery: ɵ4,
        })
    ], HuntingSupportedBulkActionsByColumnsApiCall);
    return HuntingSupportedBulkActionsByColumnsApiCall;
}(ApiCallModel));
export { HuntingSupportedBulkActionsByColumnsApiCall };
var ɵ5 = function (config) { return config.data.serviceUrls.huntingService; }, ɵ6 = function (columnNames) { return ({
    data: { ColumnNames: columnNames },
}); };
var HuntingSupportedBulkActionsByColumnsApiCallVNext = /** @class */ (function (_super) {
    __extends(HuntingSupportedBulkActionsByColumnsApiCallVNext, _super);
    function HuntingSupportedBulkActionsByColumnsApiCallVNext() {
        return _super !== null && _super.apply(this, arguments) || this;
    }
    HuntingSupportedBulkActionsByColumnsApiCallVNext = __decorate([
        ApiCall({
            name: 'Get supported bulk actions for hunting result set',
            endpoint: 'customactions/bulkActions/supported',
            baseUrl: ɵ5,
            method: 'POST',
            type: HuntingQuerySupportedAction,
            parseQuery: ɵ6,
        })
    ], HuntingSupportedBulkActionsByColumnsApiCallVNext);
    return HuntingSupportedBulkActionsByColumnsApiCallVNext;
}(ApiCallModel));
export { HuntingSupportedBulkActionsByColumnsApiCallVNext };
export { ɵ0, ɵ1, ɵ2, ɵ3, ɵ4, ɵ5, ɵ6 };
