var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
/* tslint:disable:template-accessibility-label-for */
import { ElementRef, EventEmitter, OnDestroy, OnInit, SimpleChanges, } from '@angular/core';
import { NgForm } from '@angular/forms';
import { Paris } from '@microsoft/paris';
import { clone } from 'lodash-es';
import { BehaviorSubject, of } from 'rxjs';
import { finalize, map, mergeMap, tap } from 'rxjs/operators';
import { Alert, Machine, MachineGroup, SuppressionRule, SuppressionRuleAction, SuppressionRuleCondition, SuppressionRuleConditionIdType, SuppressionRuleConditionIocType, SuppressionRuleConditionType, SuppressionRuleHistoryItem, SuppressionRuleScope, SuppressionRuleScopeType, SuppressionRuleType, } from '@wcd/domain';
import { AuthService, MtpPermission } from '@wcd/auth';
import { CommentModel } from '../../../comments/models/comment.model';
import { Feature, FeaturesService, FlavorService } from '@wcd/config';
import { DialogsService } from '../../../dialogs/services/dialogs.service';
import { I18nService } from '@wcd/i18n';
import { TrackingEventType } from '../../../insights/models/tracking-event-type.enum';
import { AppInsightsService } from '../../../insights/services/app-insights.service';
import { RbacService } from '../../../rbac/services/rbac.service';
import { MachinesService } from '../../machines/services/machines.service';
import { SuppressionRulesBackendService } from '../services/suppression-rules.backend.service';
import assertNever from 'assert-never';
import { GlobalEntityTypesService } from '../../../global_entities/services/global-entity-types.service';
import { SuppressionRulePanelMode, SuppressionRulesService } from '../services/suppression-rules.service';
import { RbacControlState } from '../../../rbac/models/rbac-control-settings.model';
import { FabricIconNames } from '@wcd/scc-common';
import { Router } from '@angular/router';
import { AppFlavorConfig } from '@wcd/scc-common';
import { TryItUrls } from '../../../mtp-promotion/enums/try-it-urls';
import { MtpPromotionService } from '../../../mtp-promotion/services/mtp-promotion.service';
var ANY_ALERT_IOC_TYPE = {
    type: SuppressionRuleConditionIocType.Any,
    context: { id: -1, name: 'Any IOC' },
};
var COMMAND_BAR_STYLES = {
    root: {
        backgroundColor: 'transparent',
    },
};
var SuppressionRuleEditComponent = /** @class */ (function () {
    function SuppressionRuleEditComponent(el, authService, paris, suppressionRulesBackendService, dialogsService, appInsightsService, i18nService, featuresService, flavorService, machinesService, rbacService, globalEntityTypesService, suppressionRulesService, router, mtpPromotionService) {
        var _this = this;
        this.el = el;
        this.authService = authService;
        this.paris = paris;
        this.suppressionRulesBackendService = suppressionRulesBackendService;
        this.dialogsService = dialogsService;
        this.appInsightsService = appInsightsService;
        this.i18nService = i18nService;
        this.featuresService = featuresService;
        this.flavorService = flavorService;
        this.machinesService = machinesService;
        this.rbacService = rbacService;
        this.globalEntityTypesService = globalEntityTypesService;
        this.suppressionRulesService = suppressionRulesService;
        this.router = router;
        this.mtpPromotionService = mtpPromotionService;
        this.save = new EventEmitter();
        this.changeStatus = new EventEmitter();
        this.closePanel = new EventEmitter();
        this.isSaving = false;
        this.isSavingComment = false;
        this.comment = '';
        this.relevantRuleGroups = '';
        this.userExposedMachineGroups = [];
        this.disableGroupsDropdown = true;
        this.isLoadingScopes = true;
        this.currentSelectedMachineGroupsIds = [];
        this.ruleNameEditedFromView = false;
        this.commentEditedFromView = false;
        this.errorLoadingScopeTypes = false;
        this.isThreatFamilyNameRelevant = false;
        this.overFlowCommandBarStyles = COMMAND_BAR_STYLES;
        this.suppressionRuleType = SuppressionRuleType;
        this.urls = TryItUrls;
        this.rulesRepo = paris.getRepository(SuppressionRule);
        this.ruleHistoryItemsRepo = paris.getRepository(SuppressionRuleHistoryItem);
        this.allAvailableConditions = (paris
            .getRepository(SuppressionRuleConditionType)
            .entity.values.filter(function (condition) {
            return condition.id !== SuppressionRuleConditionIdType.CommandLine ||
                _this.featuresService.isEnabled(Feature.AlertSuppressionByCommandLine);
        }));
        this.selectableActions = (paris.getRepository(SuppressionRuleAction).entity.values);
        this.machineGroupsRepo = paris.getRepository(MachineGroup);
        this.isDeviceScopeEnabled = this.flavorService.isEnabled(AppFlavorConfig.settings.deviceGroups);
    }
    Object.defineProperty(SuppressionRuleEditComponent.prototype, "rule", {
        get: function () {
            return this._suppressionRule;
        },
        set: function (suppressionRule) {
            this._suppressionRule = suppressionRule;
            this.setCommandBarItems(suppressionRule);
        },
        enumerable: true,
        configurable: true
    });
    SuppressionRuleEditComponent.prototype.setCommandBarItems = function (rule) {
        var _this = this;
        this.commandBarItems = [
            {
                name: this.i18nService.get('suppressionRules.edit.open.suppression.page'),
                key: 'SuppressionRuleDetailsGoToRulePage',
                onClick: function () { return _this.onOpenRulePage(); },
                iconProps: { iconName: FabricIconNames.PageRight },
            },
            {
                name: this.i18nService.get('suppressionRules.edit.confirmButton'),
                key: 'editRule',
                onClick: function () { return _this.onEdit(); },
                iconProps: { iconName: FabricIconNames.EditSolid12 },
            },
            {
                name: this.i18nService.get('suppressionRules.actions.changeStatus', {
                    status: rule && rule.isEnabled
                        ? this.i18nService.get('suppressionRules_actions_changeStatus_result_off')
                        : this.i18nService.get('suppressionRules_actions_changeStatus_result_on'),
                }),
                key: 'changeStatus',
                onClick: function () { return _this.onChangeRuleStatus(); },
                iconProps: {
                    iconName: rule && rule.isEnabled ? FabricIconNames.Cancel : FabricIconNames.Accept,
                },
            },
            {
                name: this.i18nService.get('delete'),
                key: 'delete',
                onClick: function () { return _this.onDelete(); },
                iconProps: { iconName: FabricIconNames.Delete },
            },
        ];
    };
    Object.defineProperty(SuppressionRuleEditComponent.prototype, "noIocUsedOption", {
        get: function () {
            return ANY_ALERT_IOC_TYPE;
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(SuppressionRuleEditComponent.prototype, "isDirty", {
        get: function () {
            return this.suppressionRuleForm.form.dirty;
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(SuppressionRuleEditComponent.prototype, "isValid", {
        get: function () {
            if (!this.createRuleMode && !this.isEditRuleMode) {
                return !!this.comment;
            }
            else {
                return (this.editedRule.name &&
                    this.comment &&
                    this.editedRule.action &&
                    this.editedRule.scope &&
                    (!this.useSelectedIoc ||
                        (this.currentAvailableConditions &&
                            this.currentAvailableConditions.some(function (condition) { return condition.enabled; }))));
            }
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(SuppressionRuleEditComponent.prototype, "formValidationMessage", {
        get: function () {
            var formControls = this.suppressionRuleForm.form.controls, firstErrorKey = Object.keys(formControls).find(function (controlId) { return formControls[controlId].invalid; }), firstErrorEl = firstErrorKey ? this.el.nativeElement.querySelector("#" + firstErrorKey) : null;
            return ((firstErrorEl && firstErrorEl.getAttribute('data-field-label')) || 'Form') + " is incomplete or contains errors.";
        },
        enumerable: true,
        configurable: true
    });
    SuppressionRuleEditComponent.prototype.ngOnInit = function () {
        var _this = this;
        if (this.refreshOn$) {
            this.suppressionRulesUpdateSubscription = this.refreshOn$.subscribe(function (date) {
                if (_this.rule && _this.refreshOn && date > _this.refreshOn) {
                    _this.save.emit(_this.editedRule);
                }
            });
        }
    };
    SuppressionRuleEditComponent.prototype.ngOnChanges = function (changes) {
        var _this = this;
        this.isSuppressionRuleEditDeleteEnabled = this.featuresService.isEnabled(Feature.SuppressionRuleEditDelete);
        if (this.rule && !this.isEditRuleMode) {
            // display rule mode
            this.setRuleFromRule();
            this.createRuleMode = false;
        }
        else if (this.alert) {
            // create rule mode
            this.setGroupsDropdownPlaceholder();
            this.placeHolderIoc = this.i18nService.get('suppressionRules.display.iocPlaceHolderCreate');
            this.initializeScopes();
            var alertsRepo = this.paris.getRepository(Alert);
            alertsRepo.getItemById(this.alert.id).subscribe(function (alert) {
                _this.alert = alert;
                _this.setRuleFromAlert();
                _this.createRuleMode = true;
            });
        }
        else if (this.isEditRuleMode) {
            this.setRuleFromRule();
            this.IocPlaceHolder();
            this.setAvailableConditions();
            this.initializeScopes();
            this.setRbacModel();
        }
        else {
            throw new Error('suppression rule or alert are required in order to show edit rule component.');
        }
    };
    SuppressionRuleEditComponent.prototype.IocPlaceHolder = function () {
        this.placeHolderIoc = this.i18nService.get('suppressionRules.display.iocPlaceHolderEdit');
        var currentIoc = {
            type: SuppressionRuleConditionIocType.Ip,
            context: { id: 1, name: this.i18nService.get('suppressionRules.display.iocPlaceHolderEdit') },
        };
        if (this.isEditRuleMode && this.editedRule.conditions.length === 0) {
            this.availableIocs = [ANY_ALERT_IOC_TYPE];
            this.selectedIoc = ANY_ALERT_IOC_TYPE;
        }
        else {
            this.selectedIoc = currentIoc;
            this.availableIocs = [ANY_ALERT_IOC_TYPE, currentIoc];
        }
    };
    SuppressionRuleEditComponent.prototype.ngOnDestroy = function () {
        this.suppressionRulesUpdateSubscription && this.suppressionRulesUpdateSubscription.unsubscribe();
    };
    SuppressionRuleEditComponent.prototype.setMachineDetails = function () {
        this.machineLink = this.globalEntityTypesService.getRouteLinkModel('machine', this.editedRule.machine);
        this.machineName = this.globalEntityTypesService.getEntityName('machine', this.editedRule.machine);
    };
    SuppressionRuleEditComponent.prototype.setRuleFromRule = function () {
        this.editedRule = new SuppressionRule(this.rule);
        if (this.editedRule.scope.type === SuppressionRuleScopeType.MachineGroups) {
            this.setRuleGroupsInfo();
        }
        this.useSelectedIoc = !!this.editedRule.ioaDefinitionId;
        this.isThreatFamilyNameRelevant = !!this.rule.threatFamilyName;
        this.setCommentsHistory();
        this.setMachineDetails();
        this.setOriginalRuleNameEdited();
    };
    SuppressionRuleEditComponent.prototype.setOriginalRuleNameEdited = function () {
        // Ignores the scope string it is calculated during run time and can be changed according to exiting groups and between the portals
        var templateWithoutScope = this.i18nService.get('suppressionRuleEdit_editedRule_name', {
            actionName: this.i18nService.get(this.editedRule.action.nameI18nKey),
            alertTitle: this.editedRule.alertTitle,
            scopeString: '',
        });
        this.originalRuleNameEdited = !this.rule.name.startsWith(templateWithoutScope);
    };
    SuppressionRuleEditComponent.prototype.setRuleFromAlert = function () {
        this.editedRule = this.rulesRepo.createNewItem();
        this.editedRule.createdBy = this.authService.currentUser.name;
        this.editedRule.alertTitle = this.alert.name;
        this.editedRule.ioaDefinitionId = this.alert.ioaDefinitionId;
        this.editedRule.machine = this.alert.machine;
        this.editedRule.threatFamilyName = this.alert.threatFamilyName;
        this.availableIocs = this.alert.availableIocs.concat([this.noIocUsedOption]);
        this.setSelectableScopes();
        this.setMachineDetails();
    };
    SuppressionRuleEditComponent.prototype.setSelectableScopes = function () {
        if (this.isRbacFilteringNecessary == null) {
            // Machine scope is a scope that should be presented anyway. The other scopes depends on isRbacFilteringNecessary value
            this.selectableScopes = this.allSelectableScopes.filter(function (scope) { return scope.type == SuppressionRuleScopeType.Machine; });
        }
        else {
            if (this.isRbacFilteringNecessary) {
                this.selectableScopes = this.allSelectableScopes;
                if (!this.userExposedMachineGroups.length || !this.isDeviceScopeEnabled) {
                    // If the user is exposed to 0 machine groups, we remove the option of suppression scope of machine groups
                    this.selectableScopes = this.selectableScopes.filter(function (scope) { return scope.type != SuppressionRuleScopeType.MachineGroups; });
                }
                if (!this.authService.currentUser.isMdeAdmin) {
                    // If the user isn't global admin, and RBAC is on, we remove the option of suppression scope of any machine
                    this.selectableScopes = this.selectableScopes.filter(function (scope) { return scope.type != SuppressionRuleScopeType.AllOrganization; });
                }
            }
            else {
                // If RBAC is off, we remove the option of suppression scope of machine groups
                this.selectableScopes = this.allSelectableScopes.filter(function (scope) { return scope.type != SuppressionRuleScopeType.MachineGroups; });
            }
        }
        this.machineGroup = this.selectableScopes.filter(function (scope) { return scope.type == 'MachineGroups'; })[0];
    };
    SuppressionRuleEditComponent.prototype.setRbacModel = function () {
        var rbacSettings = {
            mtpPermissions: [MtpPermission.SecurityConfig_Manage],
            mtpWorkloads: this.alert.mtpWorkloads,
            requireAllPermissions: true,
        };
        this.rbacSettingsHidden = __assign({}, rbacSettings, { state: RbacControlState.hidden });
        this.rbacSettingsDisabled = __assign({}, rbacSettings, { state: RbacControlState.disabled });
    };
    SuppressionRuleEditComponent.prototype.setAvailableConditions = function () {
        var _this = this;
        if (this.selectedIoc && this.selectedIoc.type === SuppressionRuleConditionIocType.Any) {
            this.useSelectedIoc = false;
            this.currentAvailableConditions = [];
        }
        else {
            this.useSelectedIoc = true;
            if (this.isEditRuleMode) {
                this.currentAvailableConditions = this.editedRule.conditions.map(function (condition) {
                    var _a;
                    var currentAvailableCondition = {
                        enabled: true,
                        properties: new SuppressionRuleConditionType({
                            pattern: condition.type.pattern,
                            patternErrorText: condition.type.patternErrorText,
                            allowWildCard: condition.type.allowWildCard,
                            propertyName: condition.type.propertyName,
                            dataFieldName: condition.type.dataFieldName,
                            type: condition.type.type,
                            name: condition.type.name,
                            id: condition.id,
                            allowEdit: true,
                        }),
                        context: (_a = {}, _a[condition.type.propertyName] = condition.value, _a),
                    };
                    return currentAvailableCondition;
                });
            }
            else {
                this.currentAvailableConditions = this.allAvailableConditions
                    .filter(function (condition) {
                    return condition.type === _this.selectedIoc.type &&
                        !!_this.selectedIoc.context[condition.propertyName];
                })
                    .map(function (condition) {
                    return {
                        enabled: true,
                        properties: condition,
                        context: clone(_this.selectedIoc.context),
                    };
                });
            }
        }
    };
    SuppressionRuleEditComponent.prototype.setRuleNameTemplateChangedScope = function (action, scope) {
        if (scope.type !== SuppressionRuleScopeType.MachineGroups) {
            this.currentSelectedMachineGroupsIds = [];
        }
        else {
            this.currentSelectedMachineGroupsIds = this.editedRule.deserializedRbacGroupIds;
        }
        this.setRuleNameTemplate(action, scope);
    };
    SuppressionRuleEditComponent.prototype.setRuleNameTemplate = function (action, scope) {
        this.setGroupsDropdownPlaceholder();
        if (!this.originalRuleNameEdited && !this.ruleNameEditedFromView) {
            if (action && scope) {
                var scopeString = void 0;
                switch (scope.type) {
                    case SuppressionRuleScopeType.AllOrganization: {
                        scopeString = 'on any device ';
                        break;
                    }
                    case SuppressionRuleScopeType.Machine: {
                        scopeString =
                            'on device ' +
                                (this.editedRule.machine &&
                                    (this.globalEntityTypesService.getEntityName(Machine, this.editedRule.machine) ||
                                        this.editedRule.machine.id));
                        break;
                    }
                    case SuppressionRuleScopeType.MachineGroups: {
                        var pluralEnding = this.currentSelectedMachineGroupsIds &&
                            this.currentSelectedMachineGroupsIds.length != 1
                            ? 's'
                            : '';
                        scopeString = "on " + this.currentSelectedMachineGroupsIds.length + " device group" + pluralEnding;
                        break;
                    }
                    case SuppressionRuleScopeType.Conditional: {
                        scopeString = 'Base on scope conditions';
                        break;
                    }
                    default:
                        assertNever(scope.type);
                }
                this.editedRule.name = this.i18nService.get('suppressionRuleEdit_editedRule_name', {
                    actionName: this.i18nService.get(action.nameI18nKey),
                    alertTitle: this.editedRule.alertTitle,
                    scopeString: scopeString,
                });
            }
        }
        if (this.isRbacFilteringNecessary && scope) {
            if (scope.type == SuppressionRuleScopeType.MachineGroups) {
                this.disableGroupsDropdown = false;
            }
            else {
                // if user changes his chosen radio button, disable the select group machine option
                this.disableGroupsDropdown = true;
            }
        }
    };
    SuppressionRuleEditComponent.prototype.setRuleNameEditedFromView = function () {
        this.ruleNameEditedFromView = true;
    };
    SuppressionRuleEditComponent.prototype.setCommentEditedFromView = function () {
        this.commentEditedFromView = true;
    };
    SuppressionRuleEditComponent.prototype.setCommentsHistory = function () {
        var _this = this;
        this.ruleHistoryItemsRepo
            .query({ where: { id: this.rule.id } })
            .pipe(map(function (dataSet) { return dataSet.items; }))
            .subscribe(function (items) {
            _this.history = items.map(function (ruleHistoryItem) {
                return new CommentModel({
                    id: ruleHistoryItem.id,
                    timestamp: ruleHistoryItem.timestamp,
                    user: ruleHistoryItem.userName,
                    message: ruleHistoryItem.message,
                    icon: ruleHistoryItem.type && ruleHistoryItem.type.icon,
                });
            });
        });
    };
    SuppressionRuleEditComponent.prototype.addCommentToRule = function (comment) {
        var _this = this;
        this.isSavingComment = true;
        var commentForSave = this.getCommentBackendData(comment);
        this.suppressionRulesBackendService.addCommentToRule(commentForSave).subscribe(function () {
            _this.isSavingComment = false;
            _this.suppressionRuleForm.form.markAsPristine();
            _this.appInsightsService.track({
                type: TrackingEventType.EntitySave,
                id: _this.rulesRepo.entity.singularName,
            });
            _this.dialogsService.showSuccessSnackbar({
                text: 'Comment saved for suppression rule',
            });
            _this.setCommentsHistory();
        }, function (error) {
            _this.isSavingComment = false;
        });
    };
    SuppressionRuleEditComponent.prototype.isSuppressionRuleOnMachineScope = function (scope) {
        return scope.type == SuppressionRuleScopeType.Machine;
    };
    SuppressionRuleEditComponent.prototype.setRuleGroupsInfo = function () {
        var _this = this;
        this.machineGroupsRepo.allItems$
            .pipe(map(function (allMachineGroups) {
            var relevantGroups = allMachineGroups
                .filter(function (group) { return _this.editedRule.deserializedRbacGroupIds.includes(group.id); })
                .map(function (machineGroup) {
                return machineGroup.isUnassignedMachineGroup
                    ? _this.i18nService.get('machineGroup.unassignedGroup.name')
                    : machineGroup.name;
            });
            return "-  " + relevantGroups.join('\n- ');
        }))
            .subscribe(function (relevantGroups) {
            _this.relevantRuleGroups = relevantGroups;
        });
    };
    SuppressionRuleEditComponent.prototype.setGroupsDropdownPlaceholder = function () {
        var numSelectedGroups = this.currentSelectedMachineGroupsIds.length;
        this.groupsDropdownPlaceholder =
            numSelectedGroups > 0
                ? numSelectedGroups > 1
                    ? this.i18nService.get('suppressionRules.edit.groupsDropdownPlaceholderWithInfo', {
                        numGroups: numSelectedGroups,
                    })
                    : this.i18nService.get('suppressionRules.edit.groupsDropdownPlaceholderWithInfo.single')
                : this.i18nService.get('suppressionRules.edit.groupsDropdownPlaceholder');
    };
    SuppressionRuleEditComponent.prototype.saveSuppressionRule = function () {
        var _this = this;
        this.isSaving = true;
        var ruleForSave = this.getRuleBackendData();
        this.paris
            .getRepository(SuppressionRule)
            .save(ruleForSave, null, { comment: this.comment })
            .pipe(finalize(function () { return (_this.isSaving = false); }))
            .subscribe(function (savedSuppressionRule) {
            _this.suppressionRuleForm.form.markAsPristine();
            _this.appInsightsService.track({
                type: TrackingEventType.EntitySave,
                id: _this.rulesRepo.entity.singularName,
            });
            _this.dialogsService.showSuccessSnackbar({
                text: 'Suppression rule was created',
            });
            _this.save.emit(savedSuppressionRule);
        }, function (error) {
            _this.isError = true;
        });
    };
    SuppressionRuleEditComponent.prototype.onChangeRuleStatus = function () {
        this.refreshOn = new Date().valueOf();
        this.changeStatus.emit(this.editedRule);
    };
    SuppressionRuleEditComponent.prototype.getCommentBackendData = function (comment) {
        return {
            ContextObjId: this.rule.id,
            NewValue: comment,
        };
    };
    SuppressionRuleEditComponent.prototype._getMachineGroupCheckListValue = function (machineGroup) {
        return {
            id: machineGroup.id,
            name: machineGroup.isUnassignedMachineGroup
                ? this.i18nService.get('machineGroup.unassignedGroup.name')
                : machineGroup.name,
        };
    };
    SuppressionRuleEditComponent.prototype.getRuleBackendData = function () {
        return new SuppressionRule(Object.assign({}, this.editedRule, {
            isEnabled: true,
            conditions: this.useSelectedIoc
                ? this.currentAvailableConditions
                    .filter(function (condition) { return condition.enabled; })
                    .map(function (condition) {
                    var conditionTypeId = condition.properties.dataFieldName;
                    var suppressionRuleCondition = new SuppressionRuleCondition({
                        id: conditionTypeId,
                        type: condition.properties,
                        value: condition.context[condition.properties.propertyName],
                    });
                    return suppressionRuleCondition;
                })
                : [],
            rbacGroupIds: this.currentSelectedMachineGroupsIds.length
                ? this.currentSelectedMachineGroupsIds.join()
                : null,
            threatFamilyName: this.isThreatFamilyNameRelevant ? this.editedRule.threatFamilyName : null,
        }));
    };
    SuppressionRuleEditComponent.prototype.getScopeTitle = function (scope, machine) {
        switch (scope.type) {
            case SuppressionRuleScopeType.AllOrganization: {
                return this.i18nService.get('suppressionRules.scope.organization');
            }
            case SuppressionRuleScopeType.Machine: {
                return this.i18nService.get('suppressionRules.scope.machine', {
                    machineName: (machine && this.globalEntityTypesService.getEntityName(Machine, machine)) || '',
                });
            }
            case SuppressionRuleScopeType.MachineGroups: {
                var numOfGroups = this.rule && this.rule.deserializedRbacGroupIds.length > 0
                    ? this.rule.deserializedRbacGroupIds.length
                    : null;
                return !numOfGroups || numOfGroups > 1
                    ? this.i18nService.get("suppressionRules.scope.machineGroups", {
                        numGroups: numOfGroups,
                    })
                    : this.i18nService.get("suppressionRules.scope.machineGroups.single");
            }
            case SuppressionRuleScopeType.Conditional: {
                return this.i18nService.get("suppressionRules.scopeType.conditional");
            }
            default:
                assertNever(scope.type);
        }
    };
    SuppressionRuleEditComponent.prototype.initializeScopes = function () {
        var _this = this;
        this.allSelectableScopes = (this.paris
            .getRepository(SuppressionRuleScope)
            .entity.values.filter(function (scope) { return scope.type !== SuppressionRuleScopeType.Conditional; })).map(function (scope) {
            var title = _this.isEditRuleMode
                ? _this.getScopeTitle(scope, _this.editedRule.machine)
                : _this.getScopeTitle(scope, _this.alert.machine);
            return Object.assign({}, scope, { title: title });
        });
        if (this.isEditRuleMode && this.editedRule.machine == null) {
            this.allSelectableScopes = this.allSelectableScopes.filter(function (scope) { return scope.type !== SuppressionRuleScopeType.Machine; });
        }
        if (this.featuresService.isEnabled(Feature.RbacMachineGroups)) {
            this.machineGroupsRepo.allItems$
                .pipe(tap(function (groups) { return (_this.isRbacFilteringNecessary = groups.length > 0); }), mergeMap(function () {
                return _this.isRbacFilteringNecessary
                    ? _this.machinesService.getFullUserExposedMachineGroups()
                    : of(null);
            }), tap(function (userExposedMachineGroups) {
                if (userExposedMachineGroups) {
                    _this.userExposedMachineGroups = userExposedMachineGroups.map(function (machineGroup) {
                        return _this._getMachineGroupCheckListValue(machineGroup);
                    });
                }
            }))
                .subscribe(function () {
                _this.setSelectableScopes();
                _this.isLoadingScopes = false;
            }, function (error) {
                _this.errorLoadingScopeTypes = true;
                _this.isLoadingScopes = false;
            });
        }
        else {
            this.isRbacFilteringNecessary = false;
            this.setSelectableScopes();
            this.isLoadingScopes = false;
        }
        if (this.isEditRuleMode) {
            // set default values in edit mode
            this.setRuleNameTemplateChangedScope(this.editedRule.action, this.editedRule.scope);
            this.editedRule.scope = this.selectableScopes.filter(function (scope) { return scope.type === _this.editedRule.scope.type; })[0];
        }
        else {
        }
    };
    SuppressionRuleEditComponent.prototype.onEdit = function () {
        var _this = this;
        this.suppressionRulesService
            .showRulePanel(SuppressionRulePanelMode.edit, this.editedRule.id, null)
            .then(function (data) {
            if (data) {
                _this.save.emit(_this.editedRule);
                _this.closePanel.emit();
            }
        })
            .catch(function (error) { });
    };
    SuppressionRuleEditComponent.prototype.onDelete = function () {
        var _this = this;
        this.suppressionRulesService.deleteRule(this.editedRule).then(function (data) {
            if (data && data.isConfirm) {
                _this.save.emit(_this.editedRule);
                _this.closePanel.emit();
            }
        });
    };
    SuppressionRuleEditComponent.prototype.onOpenRulePage = function () {
        this.router.navigate(["/preferences2/suppressionRule/" + this.editedRule.id]);
    };
    return SuppressionRuleEditComponent;
}());
export { SuppressionRuleEditComponent };
