<div>
	<ng-template [ngTemplateOutlet]="breadcrumbs"></ng-template>

	<ng-template [ngTemplateOutlet]="notification"></ng-template>

	<div class="upper-bar-wrapper wcd-flex-horizontal"
		 [class.wcd-responsive-shell-padding-none-left]="isScc"
		 [class.wcd-padding-none-horizontal-important]="isScc">
		<ng-container *ngIf="iconName">
			<div class="wcd-entity-icon-only" [ngClass]="iconCssClass" [style.backgroundColor]="iconContainerBackground">
				<wcd-shared-icon [styles]="entityIconStyle" [iconName]="iconName"></wcd-shared-icon>
			</div>
		</ng-container>
		<div class="upper-bar-main-part-wrapper">
			<div class="wcd-flex-vertical title-container">
				<h1 [wcdTooltip]="title">
					{{ title }}
				</h1>
				<div class="wcd-flex-horizontal">
					<ng-container #bottomHeaderEntityViewPlaceHolder></ng-container>
					<div class="overflow-tags-list-container" *ngIf="entityType.hideTagsInEntityComponent && (tags$ | async) as tags">
						<overflow-tags-list [minimumExpendedItems]="0" *ngIf="tags?.length" [tags]="tags"></overflow-tags-list>
					</div>
				</div>
			</div>
			<div class="wcd-flex-vertical wcd-flex-1">
				<ng-template [ngTemplateOutlet]="commandBar"></ng-template>
				<div class="wcd-flex-horizontal-justify-items-end" *ngIf="showMigrationToggle">
					<wcd-toggle
						name="toggleNewPage"
						[isDisabled]="isMigrationToggleOn"
						[checked]="isMigrationToggleOn"
						(change)="toggleMigrationFlag()"
						[falseLabel]="getMigrationToggleTitle(false)"
						[trueLabel]="getMigrationToggleTitle(true)"
					></wcd-toggle>

				</div>
			</div>
		</div>
	</div>

	<ng-template [ngTemplateOutlet]="bottomNotification"></ng-template>

	<ng-template #commandBar>
		<command-bar [id]="entityType.entity.singularName + '_EntityPage'"
					[onlyIconsScreenBreakpoint]="onlyIconsScreenBreakpoint"
					[styles]="commandBarStyles"
					class="wcd-flex-1 darker upper-bar-command-bar"
					[items]="commandBarItems$ | async">
		</command-bar>
	</ng-template>

	<ng-template #breadcrumbs>
		<app-breadcrumbs
			*ngIf="mainAppState.pageMode === entityPageViewMode.Modern || mainAppState.pageMode === entityPageViewMode.Asset"
			[items$]="breadcrumbs$"
			[hidden]="!mainAppState.showBreadcrumbs || !(breadcrumbs$ | async).length">
		</app-breadcrumbs>
	</ng-template>

	<ng-template #notification>
		<fab-message-bar class="upper-bar-notification" *ngIf="entityNotification" [messageBarType]="MessageBarType.info" [isMultiline]="true">
			<div [innerHTML]="entityNotification"></div>
		</fab-message-bar>
	</ng-template>

	<ng-template #bottomNotification>
		<fab-message-bar class="lower-bar-notification" *ngIf="(entityAsyncBottomNotification$ | async) as entityAsyncBottomNotification" [messageBarType]="MessageBarType.warning" [isMultiline]="true">
			<div [innerHTML]="entityAsyncBottomNotification"></div>
		</fab-message-bar>
	</ng-template>
</div>
