/* tslint:disable:template-click-events-have-key-events */
import { Component, EventEmitter, Input, OnInit, Output, ViewChild } from '@angular/core';
import { Router } from '@angular/router';
import { NgForm } from '@angular/forms';
import {
	AadGroup,
	DeviceTypeEntity,
	MachineGroup,
	MachineGroupAssignment,
	MachineGroupMachine,
	MachineGroupResultsPreview,
	MachineGroupRuleProperty,
	MachineGroupSimpleRule,
	MachineGroupSimpleRuleOperator,
	MachineGroupSimpleRuleProperty,
	OperatingSystemPlatform,
	ProtectionLevel, ProtectionLevelEnum, protectionLevelValues,
	UserRole,
	MdeUserRoleActionEnum,
	UserRoleAssignment,
	MtpWorkload,
} from '@wcd/domain';
import { Paris, Repository } from '@microsoft/paris';
import { FormComponent } from '../../../../shared/models/form.component';
import { TabModel } from '../../../../shared/components/tabs/tab.model';
import { DialogsService } from '../../../../dialogs/services/dialogs.service';
import { ConfirmEvent } from '../../../../dialogs/confirm/confirm.event';
import { MachineGroupsService } from '../../services/machine-groups.service';
import { cloneDeep, find, flatMap, omit } from 'lodash-es';
import { I18nService } from '@wcd/i18n';
import { AuthService, UnifiedRbacPermission, UserAuthEnforcementMode } from '@wcd/auth';
import { Group, groupBy } from '../../../../../../../../projects/hunting/src/lib/utils/group-by';
import { ContentState } from '@wcd/contents-state';
import { Feature, FeaturesService, FlavorService } from '@wcd/config';
import { AppFlavorConfig } from '@wcd/scc-common';
import { RbacComponentType } from '../shared/aad-groups-assignment-tab';
import { AppConfigService } from '@wcd/app-config';
import { RbacControlSettings, RbacControlState } from '../../../../rbac/models/rbac-control-settings.model';

const AzureAadLink = "https://portal.azure.com/#blade/Microsoft_AAD_IAM/ActiveDirectoryMenuBlade/Overview";

@Component({
	selector: 'machine-group-edit-enhanced',
	providers: [MachineGroupsService],
	templateUrl: './machine-group-edit-enhanced.component.html',
	styles: [`
        .multi-select {
            min-height: 300px;
        }
        .wcd-table th {
            border-bottom: solid 1px #b3b0ad;
        }
        .wcd-table tbody tr:not(:last-child) {
            border-bottom: solid 1px #d2d0ce;
        }
        .wcd-table tbody tr.noBorder {
            border: 0;
        }
    `]
})
export class MachineGroupEditEnhancedComponent implements OnInit, FormComponent {
	@Input() machineGroup: MachineGroup;

	@Output() saveChanges: EventEmitter<MachineGroup> = new EventEmitter<MachineGroup>();
	@Output() cancel: EventEmitter<void> = new EventEmitter<void>();

	@ViewChild('machineGroupForm', { static: false }) machineGroupForm: NgForm;

	RbacComponentType = RbacComponentType;
	rbacControlSettings: RbacControlSettings;

	contentState = ContentState.Loading;
	repository: Repository<MachineGroup>;
	allProtectionLevels: Array<ProtectionLevel>;
	editedMachineGroup: MachineGroup;
	isSaving = false;
	isLoadingPreviewResults = false;
	isLoadingUserRoles = false;
	shouldShowAadGroupsWithAssignedRoles = false;
	operators: Array<MachineGroupSimpleRuleOperator>;
	selectableOperators: Array<MachineGroupSimpleRuleOperator>;
	osPlatformOptions: Array<OperatingSystemPlatform>;
	selectedOsPlatformValues: Array<string>;
	osPlatformButtonText: string;
	deviceTypeOptions: Array<DeviceTypeEntity>;
	selectedDeviceTypeValues: Array<string>;
	deviceTypeButtonText: string;
	properties: Array<MachineGroupSimpleRuleProperty>;
	rulesByProperties: Array<Group<MachineGroupRuleProperty, MachineGroupSimpleRule>>;
	previewedMachines: Array<MachineGroupMachine>;
	previewError: boolean;
	tabs: Array<TabModel> = [
		{
			id: 'details',
			name: this.i18nService.get('machineGroup.edit.general'),
			data: 0,
		},
		{
			id: 'members',
			name: this.i18nService.get('machineGroup.edit.members'),
			data: 1,
		},
		{
			id: 'preview_members',
			name: this.i18nService.get('machineGroup.edit.previewMembers'),
			data: 2,
		},
		{
			id: 'assignment',
			name: this.i18nService.get('machineGroup.edit.userAccess'),
			data: 3,
		},
	].map(tab => new TabModel(tab));
	currentTab: TabModel = this.tabs[0];
	hasEditPermissions: boolean = false;
	availableAadGroups: Array<AadGroupExtended> = [];
	filteredAvailableAadGroups: Array<AadGroupExtended> = [];
	selectedAadGroups: Array<AadGroupExtended> = [];
	chosenAvailableAadGroups: Array<AadGroupExtended>;
	chosenSelectedAadGroups: Array<AadGroupExtended>;
	filter: string = '';
	allAutomationLevelEnabled: boolean;

	get isDirty(): boolean {
		return this.machineGroupForm.form.dirty;
	}
	get hasRules(): boolean {
		return !!(
			this.editedMachineGroup.rules.length &&
			this.editedMachineGroup.rules.filter(
				(rule: MachineGroupSimpleRule) =>
					!rule.isDeleted &&
					((!rule.operator.valueIsList && !!rule.propertyValue) ||
						(rule.operator.valueIsList &&
							!!rule.propertyValue &&
							JSON.parse(rule.propertyValue).length))
			).length
		);
	}

	get isValid(): boolean {
		return !!(
			(this.editedMachineGroup.isUnassignedMachineGroup ||
				(this.editedMachineGroup.name && this.hasRules)) &&
			this.editedMachineGroup.protectionLevel
		);
	}

	get confirmDeactivateText(): string {
		return this.machineGroup.id ? this.i18nService.get('machineGroup_areYouSure') : this.i18nService.get('machineGroup_areYouSureForNewDeviceGroup');
	}

	get userAccessDescription() {
		return this.shouldShowAadGroupsWithAssignedRoles
			? this.i18nService.get('machineGroup.edit.exposeToGroupsDescription_unifiedRbacSupport')
			: this.i18nService.get('machineGroup.edit.exposeToGroupsDescription');
	}

	constructor(
		private paris: Paris,
		private dialogsService: DialogsService,
		private machineGroupsService: MachineGroupsService,
		private router: Router,
		public authService: AuthService,
		public i18nService: I18nService,
		featureService: FeaturesService,
		flavorService: FlavorService,
		private appConfigService: AppConfigService
	) {
		this.repository = paris.getRepository(MachineGroup);
		this.allAutomationLevelEnabled = flavorService.isEnabled(AppFlavorConfig.settings.deviceGroupsAutomationLevel);
		this.allProtectionLevels = paris
			.getRepository(ProtectionLevel)
			.entity.values.slice()
			.sort((a, b) => {
				return a.order > b.order ? 1 : -1;
			})
			.filter(protectionLevel => this.allAutomationLevelEnabled ||
				protectionLevel.order === protectionLevelValues.find(protectionLevel => protectionLevel.id === ProtectionLevelEnum.NotProtected).order);

		const supportDeviceType = featureService.isEnabled(Feature.DeviceGroupsSupportDeviceType);
		const supportedUserAuthEnforcementModeforUrbac = [UserAuthEnforcementMode.RbacV2, UserAuthEnforcementMode.UnifiedRbac];
		this.shouldShowAadGroupsWithAssignedRoles =
			!featureService.isEnabled(Feature.RbacMachineGroupsForMtp) ||
			!supportedUserAuthEnforcementModeforUrbac.includes(appConfigService.userAuthEnforcementMode);

		this.operators = <Array<MachineGroupSimpleRuleOperator>>(
			paris.getRepository(MachineGroupSimpleRuleOperator).entity.values
		);
		this.selectableOperators = this.operators.filter(
			(operator: MachineGroupSimpleRuleOperator) => !operator.valueIsList
		);
		this.properties = <Array<MachineGroupSimpleRuleProperty>>(
			paris.getRepository(MachineGroupSimpleRuleProperty).entity.values.filter(p => supportDeviceType ? true : p.property != MachineGroupRuleProperty.DeviceType)
		);

		this.osPlatformOptions = paris.getRepository(OperatingSystemPlatform).entity.values.filter(os => os.isBackendSupportedFilter);

		this.deviceTypeOptions = paris.getRepository(DeviceTypeEntity).entity.values;

		this.getDeviceTypeNames();

		if (appConfigService.userAuthEnforcementMode === UserAuthEnforcementMode.UnifiedRbac) {
			//This is granularity change if the Tenant moved to UnifiedRbac
			this.hasEditPermissions = authService.currentUser.hasRequiredMtpPermissionInWorkload(
				UnifiedRbacPermission.ConfigurationAuthorizationManage,
				MtpWorkload.Mdatp);

			this.rbacControlSettings = {
				unifiedPermissions: [UnifiedRbacPermission.ConfigurationAuthorizationManage],
				state: RbacControlState.disabled,
				mtpWorkloads: [MtpWorkload.Mdatp]
			};
		}
		else{
			this.hasEditPermissions = authService.currentUser.hasMdeAllowedUserRoleAction(MdeUserRoleActionEnum.systemSettings);
			this.rbacControlSettings = { permissions: ['systemSettings'], state: RbacControlState.disabled };
		}
	}

	ngOnInit() {
		if (this.machineGroup) {
			this.editedMachineGroup = cloneDeep(this.machineGroup);

			if (this.editedMachineGroup.isUnassignedMachineGroup) {
				this.editedMachineGroup.name = this.i18nService.get('machineGroup.unassignedGroup.name');
				this.editedMachineGroup.description = this.i18nService.get(
					'machineGroup.unassignedGroup.description'
				);
			}

			this.editedMachineGroup.rules.forEach(rule => {
				if (rule.property.valuesEntity) {
					const propertyValue: any = rule.propertyValue;
					let parsedValue = propertyValue;
					try {
						parsedValue = JSON.parse(propertyValue);
					} catch (e) {
						// Property value is not a JSON - it is a simple string.
					}

					// Older rules defined on OsPlatform may have been defined using a single string value (from simple dropdown)
					// In the transition to support array of strings (from dropdown checklist) the old rule is fixed to the new format:
					// The value becomes an array with one object, and the operator is changed from Equals to In.
					if (typeof parsedValue === 'string' && parsedValue) {
						parsedValue = new Array<string>(parsedValue);
						rule.propertyValue = JSON.stringify(parsedValue);
					}

					rule.operator = this.operators.find(
						operator => operator.valueIsList
					);

					if (rule.property.property === MachineGroupRuleProperty.Os) {
						this.selectedOsPlatformValues = parsedValue;
						this.osPlatformButtonText = this.getButtonTextForOsDropdown();
					}
					else if (rule.property.property === MachineGroupRuleProperty.DeviceType) {
						this.selectedDeviceTypeValues = parsedValue;
						this.deviceTypeButtonText = this.getButtonTextForDeviceTypeDropdown();
					}
				}
			});

		} else {
			this.editedMachineGroup = this.repository.createNewItem();
			this.editedMachineGroup.rules = this.properties.map(
				(property: MachineGroupSimpleRuleProperty) => {
					return new MachineGroupSimpleRule({
						property: property,
						operator: property.valuesEntity
							? this.operators.find(operator => operator.valueIsList)
							: this.operators[0],
					});
				}
			);
		}
		if (!this.allAutomationLevelEnabled) {
			this.editedMachineGroup.protectionLevel = new ProtectionLevel(protectionLevelValues.find(protectionLevel => protectionLevel.id === ProtectionLevelEnum.NotProtected));
		}

		this.deviceTypeButtonText = this.getButtonTextForDeviceTypeDropdown();
		this.editedMachineGroup.rules.forEach((rule, index) => rule.correlatedIndex = index);

		// API returns a flat list of rules - group by property:
		this.deviceTypeOptions.forEach((deviceTypeOption: DeviceTypeEntity) => deviceTypeOption.name = this.i18nService.get(deviceTypeOption.i18nNameKey));
		this.rulesByProperties = groupBy(this.editedMachineGroup.rules, machineGroupRule => machineGroupRule.property.property);

		this.contentState = ContentState.Complete;
		if (this.shouldShowAadGroupsWithAssignedRoles) {
			this.setUserGroups();
		}
		else {
			this.selectedAadGroups = this.editedMachineGroup.assignments.map(mg => mg.aadGroup);
		}
	}

	onAddRuleClick = (machineGroupSimpleRuleProperty: MachineGroupSimpleRuleProperty) => {
		// Add both to the editedMachineGroup and to the rulesByProperties
		const newMachineGroupSimpleRule = new MachineGroupSimpleRule();
		newMachineGroupSimpleRule.property = machineGroupSimpleRuleProperty;
		newMachineGroupSimpleRule.operator = new MachineGroupSimpleRuleOperator({ id: 0, nameKey: 'machineGroup_rule_operator_startsWith' });
		newMachineGroupSimpleRule.propertyValue = '';
		newMachineGroupSimpleRule.correlatedIndex = this.editedMachineGroup.rules.length;
		this.editedMachineGroup.rules.push(newMachineGroupSimpleRule);
		this.rulesByProperties.find(group => group.key === machineGroupSimpleRuleProperty.property).items.push(newMachineGroupSimpleRule);
		this.machineGroupForm.form.markAsDirty();
	}

	onRemoveRuleClick = (machineGroupSimpleRule: MachineGroupSimpleRule) => {
		machineGroupSimpleRule.isDeleted = true;
		this.machineGroupForm.form.markAsDirty();
	}

	onBack = () => {
		this.currentTab = this.tabs[this.currentTab.data - 1];
	}

	onNext = () => {
		this.currentTab = this.tabs[this.currentTab.data + 1];
	}

	getPropertyNonDeletedRules(machineGroupRuleProperty: MachineGroupRuleProperty) {
		const propertyGroup = this.rulesByProperties.filter(group => group.key === machineGroupRuleProperty)[0];
		if (propertyGroup) {
			return propertyGroup.items.filter(rule => !rule.isDeleted);
		}
	}

	getDeviceTypeNames() {
		this.deviceTypeOptions.forEach((deviceTypeOption: DeviceTypeEntity) => deviceTypeOption.name = this.i18nService.get(deviceTypeOption.i18nNameKey));
	}

	getSelectableOperatorLabel = (operator: MachineGroupSimpleRuleOperator) => {
		return this.i18nService.get(operator.nameKey);
	}

	getProtectionLevelLabel = (protectionLevel: ProtectionLevel) => {
		return this.i18nService.get(protectionLevel.nameKey);
	}

	saveMachineGroup() {
		if (!this.selectedAadGroups.length) {
			this.dialogsService
				.confirm({
					title: this.i18nService.get('machineGroup.noUserGroupsSelected'),
					text: this.i18nService.get('machineGroup.allUsersWillHave'),
					confirmText: this.i18nService.get('buttons.close'),
					cancelText: this.i18nService.get('buttons.cancel'),
				})
				.then((e: ConfirmEvent) => e.confirmed && this.doSave());
		} else this.doSave();
	}

	goToUserRolesWithConfirm() {
		if (this.isDirty) {
			this.dialogsService
				.confirm({
					title: this.i18nService.get('discardChanges'),
					text: this.i18nService.get('machineGroup.areYouSure'),
					confirmText: this.i18nService.get('buttons.discard'),
				})
				.then((e: ConfirmEvent) => e.confirmed && this.router.navigate(['/preferences2/user_roles']));
		} else this.router.navigate(['/preferences2/user_roles']);
	}

	private doSave() {
		this.isSaving = true;

		this.editedMachineGroup.assignments = this.selectedAadGroups.map(
			(aadGroup: AadGroupExtended) =>
				new MachineGroupAssignment({ aadGroup: new AadGroup(omit(aadGroup, ['helpText', 'exists'])) })
		);

		this.editedMachineGroup.rules = this.editedMachineGroup.rules.filter(rule => !rule.isDeleted);

		this.saveChanges.emit(this.editedMachineGroup);
	}

	previewResults() {
		this.isLoadingPreviewResults = true;
		this.previewError = false;
		const previewMachineGroup = this.editedMachineGroup;
		previewMachineGroup.rules = previewMachineGroup.rules.filter(rule => !rule.isDeleted);

		this.machineGroupsService.getMachineGroupMachinesPreview(previewMachineGroup).subscribe(
			(machinesPreview: Array<MachineGroupResultsPreview>) => {
				this.previewedMachines = flatMap(
					machinesPreview,
					(machineGroup: MachineGroupResultsPreview) => machineGroup.machines
				);
				this.isLoadingPreviewResults = false;
			},
			error => {
				this.previewError = true;
				this.isLoadingPreviewResults = false;
			}
		);
	}

	getButtonTextForOsDropdown(): string {
		if (this.selectedOsPlatformValues && this.selectedOsPlatformValues.length > 0) {
			return this.selectedOsPlatformValues
				.map(id => {
					const osPlatform = find(this.osPlatformOptions, osPlatform => osPlatform.id === id);
					return osPlatform ? osPlatform.name : '';
				})
				.filter(osPlatformName => osPlatformName)
				.toString();
		}
		return null;
	}

	getButtonTextForDeviceTypeDropdown(): string {
		if (this.selectedDeviceTypeValues && this.selectedDeviceTypeValues.length > 0) {
			return this.selectedDeviceTypeValues
				.map(id => {
					const deviceType = find(this.deviceTypeOptions, deviceType => deviceType.id === id);
					return deviceType ? this.i18nService.get(deviceType.i18nNameKey) : '';
				})
				.filter(deviceTypeName => deviceTypeName)
				.toString();
		}
		return null;
	}

	setOsPropertyValueOnChange(rule: MachineGroupSimpleRule) {
		rule.propertyValue = JSON.stringify(this.selectedOsPlatformValues);
		this.osPlatformButtonText = this.getButtonTextForOsDropdown();
	}

	setDeviceTypeValueOnChange(rule: MachineGroupSimpleRule) {
		rule.propertyValue = JSON.stringify(this.selectedDeviceTypeValues);
		this.deviceTypeButtonText = this.getButtonTextForDeviceTypeDropdown();
	}

	private isAadGroupSelected(aadGroupId: string) {
		return this.editedMachineGroup.assignments.some(assignment => assignment.aadGroup.id === aadGroupId);
	}

	private setUserGroups() {
		const userRolesRepo: Repository<UserRole> = this.paris.getRepository(UserRole);
		this.isLoadingUserRoles = true;

		userRolesRepo.allItems$.subscribe(
			(userRoles: Array<UserRole>) => {
				const aadGroupsMap: Map<string, AadGroupUserRoles> = new Map();
				userRoles.forEach((userRole: UserRole) => {
					userRole.assignments.forEach((userRoleAssignment: UserRoleAssignment) => {
						const aadGroupId: string = String(userRoleAssignment.aadGroup.id);
						let groupUserRoles: AadGroupUserRoles = aadGroupsMap.get(aadGroupId);

						if (!groupUserRoles) {
							groupUserRoles = {
								aadGroup: userRoleAssignment.aadGroup,
								userRoles: [userRole],
							};
							aadGroupsMap.set(aadGroupId, groupUserRoles);
							if (this.isAadGroupSelected(aadGroupId)) {
								this.selectedAadGroups.push(userRoleAssignment.aadGroup);
							} else {
								this.availableAadGroups.push(userRoleAssignment.aadGroup);
							}
						} else if (!~groupUserRoles.userRoles.indexOf(userRole))
							groupUserRoles.userRoles.push(userRole);
					});
				}, []);

				const assignmentsDoNotExist = this.editedMachineGroup.assignments.filter(
					assignment =>
						!this.selectedAadGroups.find(group => group.id === assignment.aadGroup.id) &&
						!this.availableAadGroups.find(group => group.id === assignment.aadGroup.id)
				);
				if (assignmentsDoNotExist.length) {
					const groups = assignmentsDoNotExist.map(assignment => assignment.aadGroup);
					this.selectedAadGroups = [...this.selectedAadGroups, ...groups];
				}
				this.selectedAadGroups = this.formatUserGroups(this.selectedAadGroups, aadGroupsMap);
				this.availableAadGroups = this.formatUserGroups(this.availableAadGroups, aadGroupsMap).filter(
					(aadGroup: AadGroupExtended) => aadGroup.exists
				);
				this.filterAadGroups();
				this.isLoadingUserRoles = false;
			},
			error => {
				this.isLoadingUserRoles = false;
			}
		);
	}

	generateHelpTextFromUserRoles(userRoles: UserRole[]) {
		return userRoles.length
			? userRoles.reduce(
				(currHelpText: string, userRole: UserRole) => currHelpText + '\n' + userRole.name,
				this.i18nService.get('userGroups.assignedRoles')
			)
			: this.i18nService.get('userGroups.notAssigned');
	}

	formatUserGroups(
		aadGroups: Array<AadGroup>,
		aadGroupsMap: Map<string, AadGroupUserRoles>
	): AadGroupExtended[] {
		return aadGroups.map(aadGroup => {
			const groupExists = !!aadGroup.name;
			const groupUserRoles = aadGroupsMap.get(aadGroup.id);
			const userRoles = groupUserRoles ? groupUserRoles.userRoles : [];
			return {
				...aadGroup,
				name: groupExists ? aadGroup.name : this.i18nService.get('userRoles.groupDoesNotExist'),
				helpText: groupExists
					? this.generateHelpTextFromUserRoles(userRoles)
					: this.i18nService.get('userRoles.groupDoesNotExistHelp'),
				exists: groupExists,
			};
		});
	}

	setFilterAndApply(filter: string) {
		this.filter = filter;
		this.filterAadGroups();
		this.filterChosen();
	}

	filterAadGroups() {
		if (!this.filter) {
			this.filteredAvailableAadGroups = [...this.availableAadGroups];
		} else {
			this.filteredAvailableAadGroups = this.availableAadGroups.filter(
				aadGroup => aadGroup.name.toLowerCase().indexOf(this.filter.toLowerCase()) > -1
			);
		}
	}

	filterChosen() {
		if (this.chosenAvailableAadGroups) {
			this.chosenAvailableAadGroups = this.chosenAvailableAadGroups.filter(
				aadGroup => !!this.filteredAvailableAadGroups.find(group => group.id === aadGroup.id)
			);
		}
	}

	addSelectedGroups() {
		this.selectedAadGroups = this.selectedAadGroups.concat(this.chosenAvailableAadGroups);
		this.availableAadGroups = this.availableAadGroups.filter(
			aadGroup => !this.selectedAadGroups.find(group => group.id === aadGroup.id)
		);
		this.chosenAvailableAadGroups = [];
		this.filterAadGroups();
		this.machineGroupForm.form.markAsDirty();
	}

	removeSelectedGroups() {
		this.availableAadGroups = this.availableAadGroups.concat(this.chosenSelectedAadGroups);
		this.selectedAadGroups = this.selectedAadGroups.filter(
			aadGroup => !this.availableAadGroups.find(group => group.id === aadGroup.id)
		);
		this.chosenSelectedAadGroups = [];
		this.filterAadGroups();
		this.machineGroupForm.form.markAsDirty();
	}

	// called when URBAC feature is enabled
	applySelectedAadGroupsWithoutRoleAssignmnet(aadGroups: Array<AadGroup>) {
		this.selectedAadGroups = aadGroups;
		this.machineGroupForm.form.markAsDirty();
	}
}

export interface AadGroupUserRoles {
	aadGroup: AadGroup;
	userRoles: Array<UserRole>;
}

interface AadGroupExtended extends AadGroup {
	helpText?: string;
	exists?: boolean;
}
