import { ComponentFactoryResolver, Injectable } from '@angular/core';
import { Paris } from '@microsoft/paris';
import { combineLatest, Observable, of, throwError } from 'rxjs';
import { map } from 'rxjs/operators';
import {
	File,
	FileActionPermission,
	FileActionPermissionReason,
	FileActionPermissionRelationship,
	FileActionPermissionResult,
	FileCurrentInstancesCount,
	FileCurrentInstancesRelationship,
} from '@wcd/domain';
import { DialogsService } from '../../../dialogs/services/dialogs.service';
import { I18nService } from '@wcd/i18n';
import { BlockFileModalComponent } from '../components/actions/block-file-modal.component';
import { QuarantineFileModalComponent } from '../components/actions/quarantine-file-modal.component';
import { FilesBackendService } from './files.backend.service';

export type FileActionFeatureStatus = { disabled: boolean; reason: string };

@Injectable()
export class FileActionsService {
	constructor(
		private readonly paris: Paris,
		private readonly i18nService: I18nService,
		private readonly dialogsService: DialogsService,
		private readonly componentFactoryResolver: ComponentFactoryResolver,
		private readonly filesBackendService: FilesBackendService
	) {}

	async blockFile(file: File, isBlock = true, openActionCenterOnSubmit = false) {
		return this.dialogsService.confirm<{}, BlockFileModalComponent, Partial<BlockFileModalComponent>, {}>(
			{
				componentType: BlockFileModalComponent,
				componentFactoryResolver: this.componentFactoryResolver,
				inputs: {
					file,
					openActionCenterOnSubmit,
					action: isBlock ? 'block' : 'unblock',
				},
				onConfirm: () => {
					this.dialogsService.showSuccessSnackbar({
						text: this.i18nService.get(`file.block.${isBlock ? 'blocked' : 'unblocked'}`),
					});

					return of({});
				},
			}
		);
	}

	async quarantineFile(file: File, openActionCenterOnSubmit = false) {
		return this.dialogsService.confirm<
			{},
			QuarantineFileModalComponent,
			Partial<QuarantineFileModalComponent>,
			{}
		>({
			componentType: QuarantineFileModalComponent,
			componentFactoryResolver: this.componentFactoryResolver,
			inputs: {
				file,
				openActionCenterOnSubmit,
			},
			onConfirm: () => {
				this.dialogsService.showSuccessSnackbar({
					text: this.i18nService.strings.file_quarantine_quarantined,
				});

				return of({});
			},
		});
	}

	getPermissions(file: File): Observable<FileActionPermission> {
		try {
			return this.paris.getRelatedItem<File, FileActionPermission>(
				FileActionPermissionRelationship,
				file
			);
		} catch (error) {
			// This error happens when there's a problem parsing the file, since all of this is synchronous Paris doesn't even create an Observable for it yet,
			// but still throw an error. However, getPermissions returns an Observable, and we don't want every one of the consumers of this API have to deal with both a catchError and a regular try/catch.
			// Therefore catching it "regularly" here and wrapping it in an Observable.
			return throwError(error);
		}
	}

	getObservedFiles(file: File): Observable<FileCurrentInstancesCount> {
		try {
			return this.paris.getRelatedItem<File, FileCurrentInstancesCount>(
				FileCurrentInstancesRelationship,
				file
			);
		} catch (error) {
			// This error happens when there's a problem parsing the file, since all of this is synchronous Paris doesn't even create an Observable for it yet,
			// but still throw an error. However, getObservedFiles returns an Observable, and we don't want every one of the consumers of this API have to deal with both a catchError and a regular try/catch.
			// Therefore catching it "regularly" here and wrapping it in an Observable.
			return throwError(error);
		}
	}

	getQuarantinePermissions(file: File): Observable<FileActionFeatureStatus> {
		return this.getPermissions(file).pipe(
			map<FileActionPermission, FileActionFeatureStatus>(permission => {
				if (permission.result !== FileActionPermissionResult.Allowed) {
					switch (permission.reason) {
						case FileActionPermissionReason.FileCertificateMicrosoftRoot:
						case FileActionPermissionReason.FileCertificateNonMsTrustedSigner:
						case FileActionPermissionReason.NotSupported:
							return { disabled: true, reason: this.getReason(permission.reason) };
					}
				}

				return { disabled: false, reason: this.getReason('Available') };
			})
		);
	}

	getQuarantineStatus(file: File): Observable<FileActionFeatureStatus> {
		return combineLatest(this.getPermissions(file), this.getObservedFiles(file)).pipe(
			map<[FileActionPermission, FileCurrentInstancesCount], FileActionFeatureStatus>(
				([permission, instanceCount]) => {
					if (permission.result !== FileActionPermissionResult.Allowed) {
						switch (permission.reason) {
							case FileActionPermissionReason.FileCertificateMicrosoftRoot:
							case FileActionPermissionReason.FileCertificateNonMsTrustedSigner:
							case FileActionPermissionReason.NotSupported:
								return { disabled: true, reason: this.getReason(permission.reason) };
						}
					}

					return instanceCount.machineCount > 0
						? { disabled: false, reason: this.getReason('Available') }
						: { disabled: true, reason: this.getReason('NotAvailable') };
				}
			)
		);
	}

	downloadFileObservedMachinesCsv(sha1: string) {
		this.filesBackendService.downloadFileObservedMachinesCsv(sha1);
	}

	private getReason(reason: FileActionPermissionReason | 'Available' | 'NotAvailable'): string {
		switch (reason) {
			case FileActionPermissionReason.FileCertificateMicrosoftRoot:
			case FileActionPermissionReason.FileCertificateNonMsTrustedSigner: {
				const publisher = this.i18nService.get(
					`file.quarantine.tooltips.trusted.publishers.${
						reason === FileActionPermissionReason.FileCertificateMicrosoftRoot
							? 'microsoft'
							: 'trusted'
					}`
				);

				return this.i18nService.get('file.quarantine.tooltips.trusted.description', {
					publisher: publisher,
				});
			}
			case FileActionPermissionReason.NotSupported: {
				return this.i18nService.get('file.quarantine.tooltips.notSupported');
			}
			case 'NotAvailable': {
				return this.i18nService.get('file.quarantine.tooltips.notAvailable');
			}
		}

		return this.i18nService.get('file.quarantine.tooltips.available');
	}
}
