var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (b.hasOwnProperty(p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
var __metadata = (this && this.__metadata) || function (k, v) {
    if (typeof Reflect === "object" && typeof Reflect.metadata === "function") return Reflect.metadata(k, v);
};
import { Entity, EntityField, EntityGetMethod, EntityModelBase } from '@microsoft/paris';
import { SuppressionRuleHistoryItemType } from './suppression-rule-history-item-type.entity';
var ɵ0 = function (config) { return config.data.serviceUrls.threatIntel; };
var SuppressionRuleHistoryItem = /** @class */ (function (_super) {
    __extends(SuppressionRuleHistoryItem, _super);
    function SuppressionRuleHistoryItem() {
        return _super !== null && _super.apply(this, arguments) || this;
    }
    Object.defineProperty(SuppressionRuleHistoryItem.prototype, "message", {
        get: function () {
            return this.type ? this.type.getMessage(this) : 'Comment';
        },
        enumerable: true,
        configurable: true
    });
    __decorate([
        EntityField({ data: 'AuditId' }),
        __metadata("design:type", String)
    ], SuppressionRuleHistoryItem.prototype, "id", void 0);
    __decorate([
        EntityField({ data: 'Type' }),
        __metadata("design:type", SuppressionRuleHistoryItemType)
    ], SuppressionRuleHistoryItem.prototype, "type", void 0);
    __decorate([
        EntityField({ data: 'Timestamp' }),
        __metadata("design:type", Date)
    ], SuppressionRuleHistoryItem.prototype, "timestamp", void 0);
    __decorate([
        EntityField({ data: 'CreatedBy' }),
        __metadata("design:type", String)
    ], SuppressionRuleHistoryItem.prototype, "userName", void 0);
    __decorate([
        EntityField({ data: 'NewValue' }),
        __metadata("design:type", Object)
    ], SuppressionRuleHistoryItem.prototype, "newValue", void 0);
    SuppressionRuleHistoryItem = __decorate([
        Entity({
            singularName: 'Suppression Rule History Item',
            pluralName: 'Suppression Rule History Items',
            endpoint: 'AuditHistory',
            fixedData: { entityType: 'SuppressionRule', pageIndex: 1, pageSize: 100 },
            allItemsEndpointTrailingSlash: false,
            baseUrl: ɵ0,
            supportedEntityGetMethods: [EntityGetMethod.query],
        })
    ], SuppressionRuleHistoryItem);
    return SuppressionRuleHistoryItem;
}(EntityModelBase));
export { SuppressionRuleHistoryItem };
export { ɵ0 };
