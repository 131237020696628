<!-- tslint:disable:template-accessibility-label-for -->
<form #actionsForm="ngForm">

	<wcd-expander
		*ngIf="actionCategories[CustomActionAssetType.Machine] as machineActions"
		[(isExpanded)]="machineActions.isExpanded"
		collapsibleClass="wcd-padding-large-left"
		[nodeStyle]="{'margin-left': '-10px'}"
		buttonRole="button"
		[ariaLabel]="'hunting.scheduledMonitorSidePane.sections.machineActions' | i18n"
		(isExpandedChange)="onAnyExpandedChange($event)">

		<span wcd-expander-title class="wcd-font-weight-semibold">
			{{ 'hunting.scheduledMonitorSidePane.sections.machineActions' | i18n}}
			<wcd-help text="{{ 'hunting.scheduledMonitorSidePane.wizardSections.actions.machineActionsHelp' | i18n}}"></wcd-help>
		</span>

		<ng-container *ngTemplateOutlet="customAction; context:{action: userSelections.isolateMachine}"></ng-container>
		<ng-container *ngTemplateOutlet="customAction; context:{action: userSelections.collectInvestigationPackage}"></ng-container>
		<ng-container *ngTemplateOutlet="customAction; context:{action: userSelections.runAntivirusScan}"></ng-container>
		<ng-container *ngTemplateOutlet="customAction; context:{action: userSelections.initiateInvestigation}"></ng-container>
		<ng-container *ngTemplateOutlet="customAction; context:{action: userSelections.restrictExecution}"></ng-container>
	</wcd-expander>

	<wcd-expander
		*ngIf="actionCategories[CustomActionAssetType.File] as fileActions"
		[(isExpanded)]="fileActions.isExpanded"
		label="{{'hunting.scheduledMonitorSidePane.sections.fileActions' | i18n}}"
		labelClass="wcd-font-weight-semibold"
		collapsibleClass="wcd-padding-large-left"
		nodeClass="wcd-margin-small-top"
		[nodeStyle]="{'margin-left': '-10px'}"
		buttonRole="button"
		(isExpandedChange)="onAnyExpandedChange($event)">

		<ng-container *ngTemplateOutlet="customAction; context:{action: userSelections.allowOrBlockFile, contentAfter:allowOrBlockScope}"></ng-container>
		<ng-template #allowOrBlockScope let-action="action">
			<div class="form-group wcd-margin-top">
				<label class="required wcd-full-width">{{ 'hunting.scheduledMonitorSidePane.fields.customActions.chooseGroups' | i18n}}</label>
				<div class="form-group" *ngIf="action.selectableScopes.length > 1">
					<wcd-radiolist name="fileRbacGroupsToggle"
						[(ngModel)]="action.selectedScope"
						[values]="action.selectableScopes"
						(ngModelChange)="onCustomActionChange(action)">
					</wcd-radiolist>
				</div>
				<wcd-checklist-dropdown
					[(ngModel)]="action.rbacGroupIds"
					name="fileRbacGroups"
					placeholder="{{getGroupsDropdownPlaceholder(action.rbacGroupIds)}}"
					[values]="rbacGroups$ | async"
					(ngModelChange)="onCustomActionChange(action)"
					[disableDropdown]="action.isAllMachinesScopeSelected"
					[required]="!action.isAllMachinesScopeSelected">
				</wcd-checklist-dropdown>
			</div>
		</ng-template>

		<ng-container *ngTemplateOutlet="customAction; context:{action: userSelections.quarantineFile}"></ng-container>
	</wcd-expander>

	<wcd-expander
		*ngIf="actionCategories[CustomActionAssetType.User] as userActions"
		[(isExpanded)]="userActions.isExpanded"
		label="{{'hunting.scheduledMonitorSidePane.sections.userActions' | i18n }}"
		labelClass="wcd-font-weight-semibold"
		collapsibleClass="wcd-padding-large-left"
		nodeClass="wcd-margin-small-top"
		[nodeStyle]="{'margin-left': '-10px'}"
		buttonRole="button"
		(isExpandedChange)="onAnyExpandedChange($event)">
		<ng-container *ngTemplateOutlet="customAction; context:{action: userSelections.markUserAsCompromised}"></ng-container>
	</wcd-expander>

	<wcd-expander
		*ngIf="actionCategories[CustomActionAssetType.Email] as emailActions"
		[(isExpanded)]="emailActions.isExpanded"
		label="{{'hunting.scheduledMonitorSidePane.sections.emailActions' | i18n }}"
		labelClass="wcd-font-weight-semibold"
		collapsibleClass="wcd-padding-large-left"
		nodeClass="wcd-margin-small-top"
		[nodeStyle]="{'margin-left': '-10px'}"
		buttonRole="button"
		(isExpandedChange)="onAnyExpandedChange($event)">

		<ng-container *ngTemplateOutlet="customAction; context:{action: userSelections.moveEmailToFolder}"></ng-container>
		<ng-container *ngTemplateOutlet="customAction; context:{action: userSelections.deleteEmail}"></ng-container>
		<ng-container *ngTemplateOutlet="customAction; context:{action: userSelections.blockEmailSender}"></ng-container>
	</wcd-expander>

	<wcd-expander *ngIf="actionCategories[CustomActionAssetType.Url] as urlActions"
		[(isExpanded)]="urlActions.isExpanded"
		label="{{'hunting.scheduledMonitorSidePane.sections.urlActions' | i18n }}"
		labelClass="wcd-font-weight-semibold"
		collapsibleClass="wcd-padding-large-left"
		nodeClass="wcd-margin-small-top"
		[nodeStyle]="{'margin-left': '-10px'}"
		(isExpandedChange)="onAnyExpandedChange($event)">

		<ng-container *ngTemplateOutlet="customAction; context:{action: userSelections.blockEmailUrl}"></ng-container>
	</wcd-expander>

	<ng-template #customAction let-action="action" let-content="content" let-contentAfter="contentAfter">
		<div *ngIf="action" class="wcd-margin-vertical">
			<wcd-checkbox
				[label]="action.name"
				[name]="'checkbox-' + action.actionType"
				[isDisabled]="action.disabled"
				[(ngModel)]="action.checked"
				(ngModelChange)="onSelectionChanged(action)">
			</wcd-checkbox>
			<div *ngIf="action.checked">
				<ng-container *ngIf="content">
					<ng-container *ngTemplateOutlet="content; context:{action: action}"></ng-container>
				</ng-container>

				<ng-container *ngIf="action.options">
					<wcd-radiolist
						[(ngModel)]="action.selectedOption"
						(ngModelChange)="onCustomActionChange(action)"
						[values]="action.options"
						[name]="action.actionType + '-options'"
						[isHorizontal]="true"
						itemClassName="wcd-width-small"
						required>
					</wcd-radiolist>
				</ng-container>

				<div *ngFor="let entity of action.entities">
					<ng-container *ngIf="entity.showSelection">
						<label class="required wcd-full-width">{{ 'hunting.scheduledMonitorSidePane.wizardSections.actions.choose' + entity.entityType | i18n}}:</label>
						<fancy-select
							[(ngModel)]="entity.selectedId"
							(ngModelChange)="onCustomActionChange(action)"
							[values]="entity.selectableIds"
							required
							[name]="action.actionType + '-' + entity.entityType"
							[isBordered]="true"
							labelClass="dropdown-width-medium"
							placeholder="{{ 'hunting.scheduledMonitorSidePane.wizardSections.actions.choose' + entity.entityType | i18n}}"
							[ariaLabel]="((action.selectedOption ? action.selectedOption.name : action.name) + ', ' ) + ('hunting.scheduledMonitorSidePane.wizardSections.actions.choose' + entity.entityType | i18n)">
						</fancy-select>
					</ng-container>
				</div>

				<ng-container *ngIf="contentAfter">
					<ng-container *ngTemplateOutlet="contentAfter; context:{action: action}"></ng-container>
				</ng-container>
			</div>
		</div>
	</ng-template>
</form>
