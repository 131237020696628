/* tslint:disable:template-click-events-have-key-events */
import { ChangeDetectorRef, Component, Injector, OnDestroy, ViewChild } from '@angular/core';
import {
	Alert,
	CyberEvent,
	CyberEventActionTypeName,
	CyberEventAlertsRelationship,
	LegacyUser,
	Process,
} from '@wcd/domain';
import { Subscription } from 'rxjs';
import { EntityPanelComponentBase } from '../../../global_entities/components/entity-panels/entity-panel.component.base';
import { CyberEventEntityDetailsComponent } from '../../../global_entities/components/entity-details/cyber-event.entity-details.component';
import { EntityPanelsService } from '../../../global_entities/services/entity-panels.service';
import { AlertEntityDetailsComponent } from '../../../global_entities/components/entity-details/alert.entity-details.component';
import { Paris, RelationshipRepository } from '@microsoft/paris';
import {
	CyberEventEntityDisplay,
	CyberEventsActionTypesService,
} from '../services/cyber-events-action-types.service';
import { isAccountLinkable } from '@wcd/scc-interface';
import { MdatpMitreService, MitreTechniqueObject } from '@wcd/scc-common';
import { CyberEventMitreTechniqueInfo, GetAlertsByIdsApiCall, Alerts } from '@wcd/domain';
import { CyberEventsUtilsService } from '../services/cyber-events-utils.service';
import { TrackingEventType } from '../../../insights/models/tracking-event-type.enum';

enum CollapsibleID {
	Details = 'cyber-event-entity-panel',
	MitreTechniquesInfo = 'cyber-event-entity-panel-mitre-techniques-info',
	DetectedAlert = 'cyber-event-entity-panel-detected-alert',
	EntitiesDetails = 'cyber-event-entity-panel-entities-details',
	AlertDetails = 'cyber-event-entity-panel-alert-details',
	AlertDescription = 'cyber-event-entity-panel-alert-description',
	AlertRecommendedAction = 'cyber-event-entity-panel-alert-recommended-action',
}

export interface CyberEventPanelOptions {
	/**
	 * Specifies whether to show a link to the machine in the cyber event(s) panel. @default true
	 */
	showMachineLink?: boolean;

	/**
	 * Specifies whether to show the entities graph in the panel. @default true
	 */
	showEntitiesGraph?: boolean;
}

@Component({
	selector: 'cyber-event-entity-panel',
	styleUrls: ['./cyber-event.entity-panel.component.scss'],
	templateUrl: './cyber-event.entity-panel.component.html',
})
export class CyberEventEntityPanelComponent
	extends EntityPanelComponentBase<CyberEvent, CyberEventPanelOptions>
	implements OnDestroy {
	@ViewChild(CyberEventEntityDetailsComponent, { static: false })
	eventDetailsComponent: CyberEventEntityDetailsComponent;
	@ViewChild(AlertEntityDetailsComponent, { static: false })
	alertDetailsComponent: AlertEntityDetailsComponent;

	readonly alertType = Alert;

	get event(): CyberEvent {
		return this.entity;
	}

	collapsibleID = CollapsibleID;

	isAlertRow: boolean = false;
	entityPanelsService: EntityPanelsService;
	eventAlertsRepository: RelationshipRepository<CyberEvent, Alert>;
	alerts: Array<Alert>;
	eventEntities: Array<CyberEventEntityDisplay>;
	activeAlertsSubscription: Subscription;
	mitreTechniquesObjects: Array<MitreTechniqueObject>;

	constructor(
		protected changeDetectorRef: ChangeDetectorRef,
		private readonly injector: Injector,
		private readonly cyberEventsActionTypesService: CyberEventsActionTypesService,
		private readonly cyberEventsUtilsService: CyberEventsUtilsService,
		private readonly paris: Paris
	) {
		super(changeDetectorRef);
		this.entityPanelsService = this.injector.get(EntityPanelsService);
		this.eventAlertsRepository = paris.getRelationshipRepository(CyberEventAlertsRelationship);
		this.setMitreTechniquesObjects();
	}

	ngOnDestroy() {
		this.activeAlertsSubscription && this.activeAlertsSubscription.unsubscribe();
	}

	setEntity(entity: CyberEvent, isExtendedData: boolean = false): void {
		super.setEntity(entity, isExtendedData);

		this.setMitreTechniquesObjects();
		this.isAlertRow = entity.actionType && entity.actionType.id === CyberEventActionTypeName.Alert;
		this.eventDetailsComponent && this.eventDetailsComponent.setEntity(entity);
		this.alertDetailsComponent && this.alertDetailsComponent.setEntity(entity.relatedAlert);

		if (!this.isAlertRow) {
			if (this.options && this.options['entityType'] === 'machines') {
				this.eventAlertsRepository.sourceItem = this.event;
				if (this.event.isCyberData && this.event.alertIds && this.event.alertIds.length) {
					this.activeAlertsSubscription = this.paris
						.apiCall(GetAlertsByIdsApiCall, this.event.alertIds)
						.subscribe((alertsList: Alerts) => {
							this.alerts = alertsList.alerts;
							this.changeDetectorRef.markForCheck();
						});
				} else {
					this.activeAlertsSubscription = this.eventAlertsRepository.query().subscribe((alerts) => {
						this.alerts = alerts.items;
						this.changeDetectorRef.markForCheck();
					});
				}
			}

			this.eventEntities = this.cyberEventsActionTypesService.getEventEntities(this.event);
		} else {
			this.eventAlertsRepository.sourceItem = null;
			this.alerts = [];
		}

		this.changeDetectorRef.markForCheck();
		this.cyberEventsUtilsService.trackCyberEventPanelEvent(
			'CyberEventEntityPanelEvent',
			TrackingEventType.SidePaneToggleButton,
			[this.event]
		);
	}

	openAlertPanel(alert: Alert) {
		this.entityPanelsService.showEntityById(Alert, alert.id, null, {
			back: {
				onClick: () => this.entityPanelsService.closeEntityPanel(Alert),
			},
		});
	}

	isEntityClickable(eventEntity: CyberEventEntityDisplay): boolean {
		const isClickable =
			eventEntity.item &&
			(eventEntity.entityType === Process
				? (<Process>eventEntity.item).file && (<Process>eventEntity.item).file.id
				: eventEntity.entityType === LegacyUser
				? isAccountLinkable(<LegacyUser>eventEntity.item)
				: eventEntity.item.id);

		return !!isClickable;
	}

	setMitreTechniquesObjects() {
		const mitreInfo: Array<CyberEventMitreTechniqueInfo> = this.event && this.event.mitreInfo;

		if (mitreInfo) {
			this.mitreTechniquesObjects = MdatpMitreService.getFilteredMitreTechniquesObjectsFromMitreInfo(
				mitreInfo
			);
		}
	}

	onLinkClick(id: string) {
		this.cyberEventsUtilsService.trackCyberEventPanelEvent(id, TrackingEventType.ExternalLink, [
			this.event,
		]);
	}
}
