/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "@angular/core";
import * as i1 from "@angular/common";
import * as i2 from "./incident-by-alert.component";
import * as i3 from "@microsoft/paris/dist/lib/paris";
import * as i4 from "@angular/router";
var styles_IncidentByAlertComponent = [];
var RenderType_IncidentByAlertComponent = i0.ɵcrt({ encapsulation: 2, styles: styles_IncidentByAlertComponent, data: {} });
export { RenderType_IncidentByAlertComponent as RenderType_IncidentByAlertComponent };
function View_IncidentByAlertComponent_1(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 3, "div", [["class", "wcd-full-height loading-overlay"]], null, null, null, null, null)), (_l()(), i0.ɵted(-1, null, ["\n\t\t\t"])), (_l()(), i0.ɵeld(2, 0, null, null, 0, "i", [["class", "large-loader-icon"]], null, null, null, null, null)), (_l()(), i0.ɵted(-1, null, ["\n\t\t"]))], null, null); }
export function View_IncidentByAlertComponent_0(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵted(-1, null, ["\n\t\t"])), (_l()(), i0.ɵand(16777216, null, null, 1, null, View_IncidentByAlertComponent_1)), i0.ɵdid(2, 16384, null, 0, i1.NgIf, [i0.ViewContainerRef, i0.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i0.ɵted(-1, null, ["\n\t"]))], function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.isLoading; _ck(_v, 2, 0, currVal_0); }, null); }
export function View_IncidentByAlertComponent_Host_0(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 1, "incident-by-alert", [], null, null, null, View_IncidentByAlertComponent_0, RenderType_IncidentByAlertComponent)), i0.ɵdid(1, 114688, null, 0, i2.IncidentByAlertComponent, [i3.Paris, i4.Router, i4.ActivatedRoute], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
var IncidentByAlertComponentNgFactory = i0.ɵccf("incident-by-alert", i2.IncidentByAlertComponent, View_IncidentByAlertComponent_Host_0, {}, {}, []);
export { IncidentByAlertComponentNgFactory as IncidentByAlertComponentNgFactory };
