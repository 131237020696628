import { Alert } from '../../alert/alert.entity';
import { EntityRelationship, EntityRelationshipRepositoryType, RelationshipType } from '@microsoft/paris';
import { EvidenceAlertsSummary, Mailbox } from '@wcd/domain';

@EntityRelationship({
	sourceEntity: Mailbox,
	dataEntity: EvidenceAlertsSummary,
	allowedTypes: [RelationshipType.OneToOne],
	getRelationshipData: (mailbox: Mailbox) => ({ id: mailbox.id }),
	fixedData: { lookBackInDays: 180 },
	endpoint: (_, query) => `evidence/mailbox/${query.where['id']}/alerts`,
	baseUrl: config => config.data.serviceUrls.threatIntel,
})
export class MailboxAlertsRelationship implements EntityRelationshipRepositoryType<Mailbox, Alert> {}
