import { ApiCall, ApiCallModel } from '@microsoft/paris';
import { WcdPortalParisConfig } from '../paris-config.interface';
import { LinkedAlerts } from '../alert/linked-by/linked-alerts.value-object';
import { AggregatedIncidentLinkedBy } from '../alert/linked-by/aggregated-incident-linked-by.value-object';

export interface LinkedAlertsApiCallInput extends AggregatedIncidentLinkedBy {
	alertId: string;
}

@ApiCall({
	name: 'Get linked alerts based on alert id, link type and entity id',
	parseQuery: (aggregatedIncidentLinkedBy: LinkedAlertsApiCallInput) => {
		return {
			params: {
				id: aggregatedIncidentLinkedBy.alertId,
				linkType: aggregatedIncidentLinkedBy.category,
				entityId: aggregatedIncidentLinkedBy.sourceEntity.id,
			},
		};
	},
	endpoint: (_config, _query) => {
		return `linkedAlerts`;
	},
	baseUrl: (config: WcdPortalParisConfig) => config.data.serviceUrls.threatIntel,
	cache: {
		time: 1000 * 60,
	},
	type: LinkedAlerts,
})
export class GetAlertLinkedAlertsApiCall extends ApiCallModel<LinkedAlerts, LinkedAlertsApiCallInput> {}
