import { EntityRelationship, EntityRelationshipRepositoryType, RelationshipType } from '@microsoft/paris';
import { Investigation } from './investigation.entity';
import { InvestigatedMachine } from './investigated-machine.entity';
import { InvestigationAction } from './actions/investigation-action.entity';
import { InfectedEntityType } from '../airs_entity/infected-entity-type.value-object';
import { Alert } from '../alert/alert.entity';
import { RemediatedThreatsStatuses } from '../remediation/remediated-threats-statuses.value-object';
import { AirsEntityTypeResults } from '../airs_entity/airs-entity-type-results.value-object';
import { ExternalSourceResults } from '../services/external-source-results.value-object';
import { WcdPortalParisConfig } from '../paris-config.interface';
import { EvidenceEntity } from '../evidence/evidence.entity';

@EntityRelationship({
	sourceEntity: Investigation,
	dataEntity: InvestigatedMachine,
	foreignKey: 'investigation_id',
	allowedTypes: [RelationshipType.OneToMany],
})
export class InvestigationMachinesRelationship
	implements EntityRelationshipRepositoryType<Investigation, InvestigatedMachine> {}

@EntityRelationship({
	sourceEntity: Investigation,
	dataEntity: Alert,
	foreignKey: 'InvestigationId',
	endpoint: 'AssociatedAlerts',
	allItemsProperty: 'Items',
	allowedTypes: [RelationshipType.OneToMany],
})
export class InvestigationAlertsRelationship
	implements EntityRelationshipRepositoryType<Investigation, Alert> {}

@EntityRelationship({
	sourceEntity: Investigation,
	dataEntity: InvestigationAction,
	foreignKey: 'investigation_id',
	allowedTypes: [RelationshipType.OneToMany],
})
export class InvestigationActionsRelationship
	implements EntityRelationshipRepositoryType<Investigation, InvestigationAction> {}

@EntityRelationship({
	sourceEntity: Investigation,
	dataEntity: InfectedEntityType,
	foreignKey: 'investigation_id',
	allowedTypes: [RelationshipType.OneToMany],
	endpoint: 'entities/infected',
	allItemsEndpointTrailingSlash: false,
	allItemsProperty: 'data',
	baseUrl: (config: WcdPortalParisConfig) => config.data.serviceUrls.automatedIr,
})
export class InvestigationInfectedEntitiesRelationship
	implements EntityRelationshipRepositoryType<Investigation, InfectedEntityType> {}

@EntityRelationship({
	sourceEntity: Investigation,
	dataEntity: EvidenceEntity,
	foreignKey: 'investigation_id',
	allowedTypes: [RelationshipType.OneToMany],
	endpoint: (config, query) => {
		return `entities/evidence/investigation/${query.where['investigation_id']}/unified`;
	},
	allItemsEndpointTrailingSlash: false,
	allItemsProperty: 'data',
	separateArrayParams: true,
	baseUrl: (config: WcdPortalParisConfig) => config.data.serviceUrls.automatedIr,
})
export class InvestigationEvidenceRelationship
	implements EntityRelationshipRepositoryType<Investigation, EvidenceEntity> {}

@EntityRelationship({
	sourceEntity: Investigation,
	dataEntity: RemediatedThreatsStatuses,
	foreignKey: 'investigation_id',
	allowedTypes: [RelationshipType.OneToOne],
	endpoint: 'entities/summary',
	allItemsEndpointTrailingSlash: false,
	baseUrl: (config: WcdPortalParisConfig) => config.data.serviceUrls.automatedIr,
})
export class InvestigationRemediatedThreatsRelationship
	implements EntityRelationshipRepositoryType<Investigation, RemediatedThreatsStatuses> {}

@EntityRelationship({
	sourceEntity: Investigation,
	dataEntity: AirsEntityTypeResults,
	foreignKey: 'investigation_id',
	allowedTypes: [RelationshipType.OneToMany],
	endpoint: 'entities/entity_results',
	allItemsProperty: 'data',
	allItemsEndpointTrailingSlash: false,
	baseUrl: (config: WcdPortalParisConfig) => config.data.serviceUrls.automatedIr,
})
export class InvestigationEntityResultsRelationship
	implements EntityRelationshipRepositoryType<Investigation, AirsEntityTypeResults> {}

@EntityRelationship({
	sourceEntity: Investigation,
	dataEntity: ExternalSourceResults,
	foreignKey: 'investigation_id',
	allowedTypes: [RelationshipType.OneToMany],
	endpoint: 'services/tracking_data',
	allItemsProperty: 'data',
	allItemsEndpointTrailingSlash: false,
	baseUrl: (config: WcdPortalParisConfig) => config.data.serviceUrls.automatedIr,
})
export class InvestigationExternalSourceResultsRelationship
	implements EntityRelationshipRepositoryType<Investigation, ExternalSourceResults> {}
