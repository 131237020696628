var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (b.hasOwnProperty(p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
import { OnDestroy } from '@angular/core';
import { isObservable, ReplaySubject } from 'rxjs';
import { isEqual, isNil, omitBy } from 'lodash-es';
import { AccountType, AccountMode, } from '@wcd/app-config';
import { tenantContextCache } from '@wcd/auth';
import { sccHostService } from '@wcd/scc-interface';
import { SccProxyUtils } from '../../scc-proxy-sevice/scc-proxy-utils';
import * as i0 from "@angular/core";
var demoTenantDomains = [
    'winatpcontoso',
    'winatptestlic11',
    'windowsdefenderatp',
    'winatptestlic12',
    'wdatpcontoso',
    'contoso-us',
    'winatpdemo',
    'wdatpcontosofld',
    'wdatpcontosomtc',
];
var AppConfigModel = /** @class */ (function () {
    function AppConfigModel() {
        this.appInsightsInstrumentationKey = window.config.appInsightsKey;
        this.buildVersion = window.config.buildVersion || sccHostService.getPackageBasePathByPkg() || 'unknown';
        this.isAutomatedIrEnabled$ = new ReplaySubject(1);
        this.serviceUrls = {};
        this.originalServiceUrls = {};
        this.useAppInsights = window.config.useAppInsights;
        this.widgetLookback = 30;
        this.changeableProperties = {};
    }
    AppConfigModel.prototype.setData = function (data) {
        this.appNavigateStartTime = window.mdatp.pageNavigateStartTime;
        this.hotStorageInDays = data.KustoHotStorageInDays;
        this.useAppInsights = data.UseAppInsight;
        this.appInsightsInstrumentationKey = data.AppInsightInstrumentationKey;
        this.appInsightsEndpointUrl = data.AppInsightsEndpointUrl;
        this.onboardingTileDismissed = data.OnboardingTileDismissed;
        // the date returned from the server has strange formatting
        var tenantOnboardingStartTimeStr = data.TenantOnboardingStartTime && data.TenantOnboardingStartTime.match(/\d+/);
        this.onboardingStartTime =
            tenantOnboardingStartTimeStr && new Date(parseInt(tenantOnboardingStartTimeStr[0], 10));
        this._isOnboardingComplete = data.IsOnboardingComplete;
        this.serviceUrls = Object.freeze(SccProxyUtils.getServiceUrls(Object.assign({ automatedIr: '/api' }, data.ServiceUrls), data.Features));
        this.originalServiceUrls = data.ServiceUrls;
        this.dataCenter =
            data.ServiceUrls &&
                data.ServiceUrls.threatIntel &&
                data.ServiceUrls.threatIntel.match(/-(.{3})/)[1];
        this.tenantId = data.AuthInfo && data.AuthInfo.TenantId;
        this.orgId = data.OrgId;
        this.tenantName = data.AuthInfo && data.AuthInfo.TenantName;
        this.isSuspended = data.IsSuspended;
        this.isDeleted = data.IsDeleted;
        this._isMtpEligible = data.IsMtpEligible;
        this._isNominated = data.IsNominated;
        this._isPermittedOnboarding = data.IsPermittedOnboarding;
        this.isAscTenant = !!data.IsAscTenant;
        this.accountType = AccountType[data.AccountType];
        this.accountMode = AccountMode[data.AccountMode];
        this.isCmsEnabled = data.IsCmsEnabled;
        this.isOutbreakContentManagementEnabled = data.IsOutbreakContentManagementEnabled;
        this.isExposedToAllMachineGroups = data.IsExposedToAllMachineGroups;
        this.hasMachineGroups = data.HasMachineGroups;
        this._isAutomatedIrEnabled = data.IsAutomatedIrEnabled;
        this.isAutomatedIrEnabled$.next(this.isAutomatedIrEnabled);
        this._isLiveResponseEnabled = data.AutomatedIrLiveResponse !== false;
        this.isDemoTenant =
            data.AuthInfo &&
                data.AuthInfo.UserName &&
                demoTenantDomains.some(function (tenantName) {
                    return data.AuthInfo.UserName.toLowerCase().includes(tenantName);
                });
        if (this.isDemoTenant)
            this.widgetLookback = 180;
        this.isBilbaoEnabled = !!data.IsBilbaoTenant;
        this.IsServiceNowIntegrationEnabled = !!data.IsServiceNowIntegrationEnabled;
        this.isEvaluationEnabled = !!data.IsEvaluationEnabled;
        this.supportedGeoRegions = data.SupportedGeoRegions;
        this.environmentName = data.EnvironmentName;
        this._mtpConsent = !!data.MtpConsent;
        this.isMdatpActive = data.IsMdatpActive;
        this.isItpActive = data.IsItpActive;
        this.isOatpActive = data.IsOatpActive;
        this.isMapgActive = data.IsMapgActive;
        this.isAadIpActive = data.IsAadIpActive;
        this.isDlpActive = data.IsDlpActive;
        this.isMdiActive = data.IsMdiActive;
        this.mdatpMtpPermissions = data.MdatpMtpPermissions || [];
        this.itpMtpPermissions = data.ItpMtpPermissions || [];
        this.oatpMtpPermissions = data.OatpMtpPermissions || [];
        this.mapgMtpPermissions = data.MapgMtpPermissions || data.MapGMtpPermissions || [];
        this.aadIpMtpPermissions = data.AadIpMtpPermissions || [];
        this.dlpMtpPermissions = data.DlpMtpPermissions || [];
        this.mdiMtpPermissions = data.MdiMtpPermissions || [];
        this.activeMtpWorkloads = data.ActiveMtpWorkloads || [];
        this.isMdatpLicenseExpired = !!data.IsMdatpLicenseExpired;
        this.mdeFlavor = data.MdeFlavor;
        this.magellanOptOut = data.MagellanOptOut;
        this.adIotIntegrationStatus = data.AdIotIntegrationStatus;
        this.tvmLicenses = data.TvmLicenses;
        this.userAuthEnforcementMode = data.UserAuthEnforcementMode;
        this.IsSiemEnabled = data.IsSiemEnabled;
        this.isPortalRedirectionSettingsToggleDisabled = data.IsPortalRedirectionSettingsToggleDisabled;
        this.aatpIntegrationEnabled = data.AatpIntegrationEnabled;
        this.aatpWorkspaceExists = data.AatpWorkspaceExists;
        if (data.IsOnboardingComplete) {
            // data is updated during tenant onboarding, after provisioning
            Object.freeze(this);
        }
    };
    Object.defineProperty(AppConfigModel.prototype, "token", {
        get: function () {
            return !isNil(this.changeableProperties && this.changeableProperties.token)
                ? this.changeableProperties.token
                : this._token;
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(AppConfigModel.prototype, "mtpConsent", {
        get: function () {
            return !isNil(this.changeableProperties && this.changeableProperties.mtpConsent)
                ? this.changeableProperties.mtpConsent
                : this._mtpConsent;
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(AppConfigModel.prototype, "isOnboardingComplete", {
        get: function () {
            return !isNil(this.changeableProperties && this.changeableProperties.isOnboardingComplete)
                ? this.changeableProperties.isOnboardingComplete
                : this._isOnboardingComplete;
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(AppConfigModel.prototype, "isNominated", {
        get: function () {
            return !isNil(this.changeableProperties && this.changeableProperties.isNominated)
                ? this.changeableProperties.isNominated
                : this._isNominated;
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(AppConfigModel.prototype, "isPermittedOnboarding", {
        get: function () {
            return !isNil(this.changeableProperties && this.changeableProperties.isPermittedOnboarding)
                ? this.changeableProperties.isPermittedOnboarding
                : this._isPermittedOnboarding;
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(AppConfigModel.prototype, "isMtpEligible", {
        get: function () {
            return !isNil(this.changeableProperties && this.changeableProperties.isMtpEligible)
                ? this.changeableProperties.isMtpEligible
                : this._isMtpEligible;
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(AppConfigModel.prototype, "isAutomatedIrEnabled", {
        get: function () {
            return !isNil(this.changeableProperties && this.changeableProperties.isAutomatedIrEnabled)
                ? this.changeableProperties.isAutomatedIrEnabled
                : this._isAutomatedIrEnabled;
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(AppConfigModel.prototype, "isLiveResponseEnabled", {
        get: function () {
            return !isNil(this.changeableProperties && this.changeableProperties.isLiveResponseEnabled)
                ? this.changeableProperties.isLiveResponseEnabled
                : this._isLiveResponseEnabled;
        },
        enumerable: true,
        configurable: true
    });
    // Returns 'true' if the tenant has an active workload (has a license + provisioned + opted in). Check BE for updated meaning.
    // Will return 'false' for suspended \ opted out workloads
    AppConfigModel.prototype.hasActiveWorkload = function (mtpWorkload) {
        return this.activeMtpWorkloads.includes(mtpWorkload);
    };
    AppConfigModel.prototype.updateChangeableProperties = function (properties) {
        Object.assign(this.changeableProperties, properties);
        // Updating global authData properties manually for SCC portal
        // Should consider to improve infra to avoid updating the global authData property
        if (properties.hasOwnProperty('isOnboardingComplete'))
            tenantContextCache.appConfig.IsOnboardingComplete = properties.isOnboardingComplete;
        if (properties.hasOwnProperty('isNominated'))
            tenantContextCache.appConfig.IsNominated = properties.isNominated;
        if (properties.hasOwnProperty('isPermittedOnboarding'))
            tenantContextCache.appConfig.IsPermittedOnboarding = properties.isPermittedOnboarding;
        if (properties.hasOwnProperty('mtpConsent'))
            tenantContextCache.appConfig.MtpConsent = properties.mtpConsent;
    };
    AppConfigModel.prototype.equals = function (other) {
        return isEqual(this.withoutObservables(), other.withoutObservables());
    };
    AppConfigModel.prototype.toJson = function () {
        return this.withoutObservables();
    };
    AppConfigModel.prototype.withoutObservables = function () {
        // TODO fix this, I am not sure who thought Partial<this> is a good type here
        return omitBy(this, isObservable);
    };
    AppConfigModel.prototype.ngOnDestroy = function () {
        this.isAutomatedIrEnabled$.complete();
    };
    return AppConfigModel;
}());
export { AppConfigModel };
var AppConfigService = /** @class */ (function (_super) {
    __extends(AppConfigService, _super);
    function AppConfigService() {
        return _super !== null && _super.apply(this, arguments) || this;
    }
    AppConfigService.ngInjectableDef = i0.ɵɵdefineInjectable({ factory: function AppConfigService_Factory() { return new AppConfigService(); }, token: AppConfigService, providedIn: "root" });
    return AppConfigService;
}(AppConfigModel));
export { AppConfigService };
