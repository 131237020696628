import { ApiCall, ApiCallModel, ParisConfig, HttpOptions } from '@microsoft/paris';
import { Machine } from '../../machine.entity';
import * as CreateRequest from '../machine-requests.models';

export interface ExpeditePollingParameters extends CreateRequest.Parameters {
	readonly TimeoutMs: number;
	readonly PollingIntervalMs: number;
}

export interface ExpeditePollingApiRequestBody
	extends CreateRequest.RawApiRequestBody<'ExpeditePollingRequest'> {
	readonly TimeoutMs: number;
	readonly PollingIntervalMs: number;
}

@ApiCall({
	name: 'Expedite C&C polling of machine',
	endpoint: 'requests/create',
	method: 'POST',
	baseUrl: (config: ParisConfig) => config.data.serviceUrls.userRequests,
	parseQuery: ({
					 machine,
	}: ExpeditePollingParameters): HttpOptions<ExpeditePollingApiRequestBody> => ({
		data: {
			MachineId: machine.machineId,
			OsPlatform: machine.os.platform.id,
			SenseClientVersion: machine.senseClientVersion,
			Type: 'ExpeditePollingRequest',
			RequestorComment: 'internal',
			TimeoutMs: 7200000,
			PollingIntervalMs: 3000,
		},
	}),
	parseData: (rawData: CreateRequest.RawApiResponse): CreateRequest.Response => ({
		machineId: rawData.MachineId,
		requestGuid: rawData.RequestGuid,
	}),
})

export class ExpeditePollingMachineApiCall extends ApiCallModel<void, Machine> {}
