var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (b.hasOwnProperty(p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
import { ChangeDetectorRef, OnDestroy } from '@angular/core';
import { Paris } from '@microsoft/paris';
import { ContentState } from '@wcd/contents-state';
import { PrettyNumberService } from '@wcd/prettify';
import { isNil } from 'lodash-es';
import { Subject } from 'rxjs';
import { finalize, shareReplay, takeUntil } from 'rxjs/operators';
import { FileActiveAlertsSummaryRelationship, FileFileStatsRelationship, FileVirusTotalFileReportRelationship, } from '@wcd/domain';
import { EntityPanelComponentBase } from '../../../../global_entities/components/entity-panels/entity-panel.component.base';
import { buildSeveritiesMap, } from '../../../../shared/components/severity-summary/severity-summary.component';
import { Feature, FeaturesService } from '@wcd/config';
import { VirusTotalFileReportService } from '../../services/virus-total-file-report.service';
import { I18nService } from '@wcd/i18n';
var FileEntityPanelComponent = /** @class */ (function (_super) {
    __extends(FileEntityPanelComponent, _super);
    function FileEntityPanelComponent(changeDetectorRef, paris, prettyNumberService, featuresService, virusTotalFileReportService, i18nService) {
        var _this = _super.call(this, changeDetectorRef) || this;
        _this.paris = paris;
        _this.prettyNumberService = prettyNumberService;
        _this.featuresService = featuresService;
        _this.virusTotalFileReportService = virusTotalFileReportService;
        _this.i18nService = i18nService;
        _this.dataAsset = _this.i18nService.get('help_externalLoadError_data_asset');
        _this.alertsSeveritySummaryContentState = ContentState.Loading;
        _this.fileWorldwideStatsContentState = ContentState.Loading;
        _this.fileOrganizationStatsContentState = ContentState.Loading;
        _this.virusTotalContentState = ContentState.Loading;
        _this._destroy$ = new Subject();
        _this.isFileVerdictEnabled = _this.featuresService.isEnabled(Feature.FileVerdict);
        return _this;
    }
    Object.defineProperty(FileEntityPanelComponent.prototype, "file", {
        get: function () {
            return this.entity;
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(FileEntityPanelComponent.prototype, "windowsDefenderAvContext", {
        get: function () {
            var prevalentThreatName = this.entity.prevalentThreatName;
            if (!prevalentThreatName) {
                return null;
            }
            return {
                value: prevalentThreatName,
                icon: 'encyclopedia',
                verticalAlign: 'top',
                link: "http://go.microsoft.com/fwlink/?LinkID=142185&Name=" + prevalentThreatName,
                title: this.i18nService.get('files.entityDetails.sections.detections.fields.windowsDefenderAv.title'),
            };
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(FileEntityPanelComponent.prototype, "virusTotalContext", {
        get: function () {
            if (!this.virusTotalFileReport) {
                return null;
            }
            return this.virusTotalFileReportService.toValueWithExternalLinkContext(this.virusTotalFileReport);
        },
        enumerable: true,
        configurable: true
    });
    FileEntityPanelComponent.prototype.setEntity = function (entity, isExtendedData) {
        if (isExtendedData === void 0) { isExtendedData = false; }
        _super.prototype.setEntity.call(this, entity, isExtendedData);
        if (this.file.id) {
            this.setActiveAlertsSummary();
            this.setVirusTotal();
            this.setFileStats();
        }
    };
    FileEntityPanelComponent.prototype.ngOnDestroy = function () {
        this._destroy$.next(true);
        this._destroy$.unsubscribe();
    };
    FileEntityPanelComponent.prototype.setFileStats = function () {
        var _this = this;
        this.fileWorldwideStatsContentState = ContentState.Loading;
        this.fileOrganizationStatsContentState = ContentState.Loading;
        this.changeDetectorRef.markForCheck();
        var fileStats$ = this.paris
            .getRelatedItem(FileFileStatsRelationship, this.entity)
            .pipe(shareReplay(1));
        fileStats$
            .pipe(finalize(function () { return _this.changeDetectorRef.markForCheck(); }), takeUntil(this._destroy$))
            .subscribe(function (fileStats) {
            _this.worldwideFileStats = {
                count: fileStats.worldwidePrevalence,
                firstSeen: fileStats.worldwideFirstSeen,
                lastSeen: fileStats.worldwideLastSeen,
            };
        }, function () { return (_this.fileWorldwideStatsContentState = ContentState.Error); }, function () {
            return (_this.fileWorldwideStatsContentState =
                _this.worldwideFileStats &&
                    (_this.worldwideFileStats.firstSeen ||
                        _this.worldwideFileStats.lastSeen ||
                        !isNil(_this.worldwideFileStats.count)
                        ? ContentState.Complete
                        : ContentState.Empty));
        });
        fileStats$
            .pipe(finalize(function () { return _this.changeDetectorRef.markForCheck(); }), takeUntil(this._destroy$))
            .subscribe(function (fileStats) {
            return (_this.organizationFileStats = {
                count: fileStats.organizationPrevalence,
                lastSeen: fileStats.organizationLastSeen,
                firstSeen: fileStats.organizationFirstSeen,
            });
        }, function () { return (_this.fileOrganizationStatsContentState = ContentState.Error); }, function () {
            return (_this.fileOrganizationStatsContentState =
                _this.organizationFileStats &&
                    (_this.organizationFileStats.firstSeen ||
                        _this.organizationFileStats.lastSeen ||
                        !isNil(_this.organizationFileStats.count)
                        ? ContentState.Complete
                        : ContentState.Empty));
        });
    };
    FileEntityPanelComponent.prototype.setVirusTotal = function () {
        var _this = this;
        this.virusTotalContentState = ContentState.Loading;
        this.changeDetectorRef.markForCheck();
        this.paris
            .getRelatedItem(FileVirusTotalFileReportRelationship, this.entity)
            .pipe(finalize(function () { return _this.changeDetectorRef.markForCheck(); }), takeUntil(this._destroy$))
            .subscribe(function (virusTotalFileReport) { return (_this.virusTotalFileReport = virusTotalFileReport); }, function () { return (_this.virusTotalContentState = ContentState.Error); }, function () {
            return (_this.virusTotalContentState = _this.virusTotalFileReportService.hasVirusTotalData(_this.virusTotalFileReport)
                ? ContentState.Complete
                : ContentState.Empty);
        });
    };
    FileEntityPanelComponent.prototype.setActiveAlertsSummary = function () {
        var _this = this;
        this.alertsSeveritySummaryContentState = ContentState.Loading;
        this.changeDetectorRef.markForCheck();
        this.paris
            .getRelatedItem(FileActiveAlertsSummaryRelationship, this.entity)
            .pipe(finalize(function () { return _this.changeDetectorRef.markForCheck(); }), takeUntil(this._destroy$))
            .subscribe(function (alertsSeveritySummary) {
            return (_this.alertsSeveritiesCountMap = buildSeveritiesMap(alertsSeveritySummary));
        }, function () { return (_this.alertsSeveritySummaryContentState = ContentState.Error); }, function () {
            return (_this.alertsSeveritySummaryContentState = _this.alertsSeveritiesCountMap
                ? ContentState.Complete
                : ContentState.Empty);
        });
    };
    return FileEntityPanelComponent;
}(EntityPanelComponentBase));
export { FileEntityPanelComponent };
