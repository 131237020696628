import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { StoreBackendBase } from '../../../data/models/store.backend.base';
import { DownloadService } from '../../../shared/services/download.service';
import { ServiceUrlsService } from '@wcd/app-config';
import { getUserTimeZone } from '@wcd/localization';
import { Observable } from 'rxjs';
import { Feature, FeaturesService } from '@wcd/config';

const FOLDER_EXCLUSIONS_API_ENDPOINT = '/acl';
const FOLDER_EXCLUSIONS_API_ENDPOINT_V2 = '/folder_exclusion';

@Injectable()
export class FolderExclusionsBackendService extends StoreBackendBase {
	protected commonParams: { [index: string]: any } = { type: 'folder' };

	constructor(
		http: HttpClient,
		downloadService: DownloadService,
		private serviceUrlsService: ServiceUrlsService,
		private featureService: FeaturesService
	) {
		super(http, downloadService);
	}

	get apiUrl() {
		const path = this.featureService.isEnabled(Feature.AutoIrFolderExclusionService)
			? FOLDER_EXCLUSIONS_API_ENDPOINT_V2
			: FOLDER_EXCLUSIONS_API_ENDPOINT;
		return this.serviceUrlsService.automatedIr + path;
	}

	update(id: number | string, data: any, options?: any): Observable<any> {
		return this.http[this.updateMethod](
			`${this.itemApiUrl || this.apiUrl}/${id}?time_offset=${getUserTimeZone()}&type=folder`,
			data
		);
	}
}
