import { ApiCall, ApiCallModel, HttpOptions } from '@microsoft/paris';
import * as CreateRequest from '../machine-requests.models';
import { RestrictAppExecutionRawApiRequestBodyBase } from './restrict-app-execution.models';

export type RemoveRestrictAppExecutionRawApiRequestBody = RestrictAppExecutionRawApiRequestBodyBase<
	'Unrestrict'
>;
export type RemoveRestrictAppExecutionRawApiResponse = CreateRequest.RawApiResponse;

export type RemoveRestrictAppExecutionParameters = CreateRequest.Parameters;
export type RemoveRestrictAppExecutionResponse = CreateRequest.Response;

@ApiCall({
	name: 'Remove app execution restrictions',
	endpoint: 'requests/create',
	method: 'POST',
	baseUrl: config => config.data.serviceUrls.userRequests,
	parseQuery: ({
		machine,
		comment,
	}: RemoveRestrictAppExecutionParameters): HttpOptions<RemoveRestrictAppExecutionRawApiRequestBody> => ({
		data: {
			MachineId: machine.machineId,
			RequestorComment: comment,
			OsPlatform: machine.os.platform.id,
			SenseClientVersion: machine.senseClientVersion,
			ClientVersion: machine.senseClientVersion,
			PolicyType: 'Unrestrict',
			Type: 'RestrictExecutionRequest',
		},
	}),
	parseData: (rawData: RemoveRestrictAppExecutionRawApiResponse): RemoveRestrictAppExecutionResponse => ({
		machineId: rawData.MachineId,
		requestGuid: rawData.RequestGuid,
	}),
})
export class MachineRemoveAppExecutionRestrictionApiCall extends ApiCallModel<
	RemoveRestrictAppExecutionResponse,
	RemoveRestrictAppExecutionParameters
> {}
