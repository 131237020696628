/* tslint:disable:template-accessibility-label-for */
import { ChangeDetectorRef, Component, ElementRef, EventEmitter, Input, OnInit, Output, ViewChild } from '@angular/core';
import { NgForm } from '@angular/forms';
import {
	MachineGroup,
	NotificationFormat,
	NotificationRule,
	NotificationRuleCondition,
	NotificationRuleSeverityConditionValue,
	MdeUserRoleActionEnum,
	SendTestEmailApiCall,
} from '@wcd/domain';
import { TabModel } from '../../../shared/components/tabs/tab.model';
import { Paris, Repository } from '@microsoft/paris';
import { combineLatest, Observable, of } from 'rxjs';
import { ChecklistValue } from '@wcd/forms';
import { MachinesService } from '../../../@entities/machines/services/machines.service';
import { DialogsService } from '../../../dialogs/services/dialogs.service';
import { I18nService } from '@wcd/i18n';
import { AuthService } from '@wcd/auth';
import { FeaturesService, Feature, FlavorService } from '@wcd/config';
import { mergeMap, tap } from 'rxjs/operators';
import { cloneDeep, some } from 'lodash-es';
import { LiveAnnouncer } from '@angular/cdk/a11y';
import { AppFlavorConfig } from '@wcd/scc-common';

const ALERT_SEVERITY_RULE_CONFIG: { RuleType: number; Values: Array<any> } = {
	RuleType: 1,
	Values: [],
};
const SELECTABLE_MACHINE_GROUP_SCOPE_IDS: Array<string> = ['all', 'specific'];

@Component({
	selector: 'notification-rule-edit',
	templateUrl: './notification-rule-edit.component.html',
})
export class NotificationRuleEditComponent implements OnInit {
	@Input() rule: NotificationRule;

	@Output() save: EventEmitter<NotificationRule> = new EventEmitter<NotificationRule>();
	@Output() cancel: EventEmitter<void> = new EventEmitter<void>();

	@ViewChild('notificationRuleForm', { static: false }) notificationRuleForm: NgForm;
	@ViewChild('emailEl', { static: false }) emailEl: ElementRef<HTMLInputElement>;

	isDeviceScopeEnabled: boolean;
	notificationRulesRepo: Repository<NotificationRule>;
	machineGroupsRepo: Repository<MachineGroup>;
	notificationFormatsRepo: Repository<NotificationFormat>;
	editedRule: NotificationRule;
	createRuleMode: boolean;
	isSaving: boolean;
	isUserAllowedActions: boolean;
	loadingMachineGroups: boolean = true;
	allowSpecificMachineGroups: boolean;
	allowAllMachineGroups: boolean;
	currentEmail: string;
	sendTestStatus: SendTestStatuses = SendTestStatuses.notStarted;
	alertSeverities: Array<NotificationRuleSeverityConditionValue>;
	allMachineGroups: Array<MachineGroup>;
	currentMachineGroups: Array<ChecklistValue> = [];
	availableMachineGroups: Array<ChecklistValue> = [];
	currentMachineGroupScope: ChecklistValue;
	selectableMachineGroupScopes: Array<ChecklistValue>;
	valuesLabelTexts: Map<string, string> = new Map<string, string>();
	tabs: Array<TabModel> = [
		{
			id: 'details',
			name: this.i18nService.get('notificationRules_editRule_generalTabName'),
		},
		{
			id: 'recipients',
			name: this.i18nService.get('notificationRules_editRule_recipientsTabName'),
		},
	].map(tab => new TabModel(tab));
	currentTab = this.tabs[0];
	notificationFormats: Array<NotificationFormat>;
	focusOnRecipient: boolean = false;

	get machineGroupsFieldId(): string {
		return 'notification-rule-machine-groups';
	}

	get SendStatuses() {
		return SendTestStatuses;
	}

	get isDirty(): boolean {
		return this.notificationRuleForm.form.dirty;
	}

	get isValid(): boolean {
		return (
			this.editedRule.name &&
			this.editedRule.recipients.length &&
			this.isGroupsValid &&
			this.isConditionsValid
		);
	}

	get isSendTestEmailDisabled(): boolean {
		return this.editedRule.readonly || !this.isUserAllowedActions || !this.isValid || (this.notificationRuleForm && !this.notificationRuleForm.form.valid);
	}

	get isGroupsValid(): boolean {
		return (
			(this.currentMachineGroupScope && this.currentMachineGroupScope.id === 'all') ||
			!!this.editedRule.machineGroups.length
		);
	}

	get isConditionsValid(): boolean {
		return this.editedRule.conditions.length && !some(this.editedRule.conditions, { isValid: false });
	}

	constructor(
		private paris: Paris,
		private authService: AuthService,
		private dialogsService: DialogsService,
		private i18nService: I18nService,
		private featuresService: FeaturesService,
		private flavorService: FlavorService,
		public machinesService: MachinesService,
		private liveAnnouncer: LiveAnnouncer,
		private changeDetectorRef: ChangeDetectorRef
	) {
		this.isDeviceScopeEnabled = this.flavorService.isEnabled(AppFlavorConfig.settings.deviceGroups);
		this.isUserAllowedActions = this.authService.currentUser.hasMdeAllowedUserRoleAction(
			MdeUserRoleActionEnum.securitySettings
		);
		this.selectableMachineGroupScopes = SELECTABLE_MACHINE_GROUP_SCOPE_IDS.map(scopeId => ({
			id: scopeId,
			name: this.i18nService.get('notificationRules.machineGroups.' + scopeId),
		}));
		this.alertSeverities = paris
			.getRepository(NotificationRuleSeverityConditionValue)
			.entity.values.map(conditionValue => ({
				id: conditionValue.id,
				name: this.i18nService.get('notificationRules_conditions_alertSeverity_' + conditionValue.id),
			}));
		this.notificationRulesRepo = paris.getRepository(NotificationRule);
		this.machineGroupsRepo = paris.getRepository(MachineGroup);
		this.notificationFormatsRepo = paris.getRepository(NotificationFormat);
		this.notificationFormats = this.notificationFormatsRepo.entity.values.filter(
			(format: NotificationFormat) => !format.hidden
		);
		this.getNotificationFormatLabel = this.getNotificationFormatLabel.bind(this);
	}

	ngOnInit() {
		combineLatest(this.setRule(), this.setMachineGroups()).subscribe(() => {
			this._setCurrentMachineGroups(this.editedRule.machineGroups);
		});
		this.createRuleMode = !this.rule;
	}

	getNotificationFormatLabel(format: NotificationFormat): string {
		return this.i18nService.get('notificationRules.formatOptions.' + format.name);
	}

	setMachineGroups(): Observable<Array<MachineGroup>> {
		return this.machineGroupsRepo.allItems$.pipe(
			tap((groups: Array<MachineGroup>) => {
				this.loadingMachineGroups = false;
				this.allMachineGroups = groups;
				this._setAvailableScopes();
			}),
			mergeMap(() => this._getUserExposedRbacGroups())
		);
	}

	setRule(): Observable<void> {
		return new Observable<void>(observer => {
			if (this.rule) {
				this.editedRule = cloneDeep(this.rule);
				this._setCurrentScope();
				this.editedRule.conditions.forEach((condition: NotificationRuleCondition) => {
					condition.values = condition.values.map(conditionValue => ({
						id: conditionValue.id,
						name: this.i18nService.get(
							'notificationRules_conditions_alertSeverity_' + conditionValue.id
						),
					}));
					this.setLabelText(
						condition.type.name,
						condition.values,
						'conditions.' + condition.type.typeName
					);
				});
				observer.next();
				observer.complete();
			} else {
				this.editedRule = this.notificationRulesRepo.createNewItem();
				this.editedRule.formatFlavor = this.notificationFormats.filter(
					(format: NotificationFormat) => format.defaultValue
				);
				this.paris
					.getRepository(NotificationRuleCondition)
					.createItem(ALERT_SEVERITY_RULE_CONFIG)
					.subscribe((alertSeverityRule: NotificationRuleCondition) => {
						this.editedRule.conditions.push(alertSeverityRule);
						observer.next();
						observer.complete();
					});
			}
		});
	}

	saveRule() {
		this.isSaving = true;
		this.notificationRulesRepo.save(this.editedRule).subscribe(
			() => {
				this.isSaving = false;
				this.save.emit(this.editedRule);
			},
			error => {
				this.isSaving = false;
				this.dialogsService.showError({
					title: this.i18nService.get('notificationRules_error_failed_' + this.editedRule.id ? 'update' : 'create'),
					data: error,
				});
			}
		);
	}

	sendTestEmail() {
		// TODO: remove isSendTestEmailValid check from here after fab-button issue fixed. (clickable when disabled)
		if (!this.isSendTestEmailDisabled) {
			this.sendTestStatus = SendTestStatuses.pending;
			this.paris.apiCall(SendTestEmailApiCall, this.editedRule).subscribe(
				() => {
					this.sendTestStatus = SendTestStatuses.success;
				},
				error => {
					this.sendTestStatus = SendTestStatuses.error;
				}
			);
			this.changeDetectorRef.detectChanges();
		}
	}

	getLabelText(fieldId: string, selectAllLabelKey?: string): string {
		return (
			this.valuesLabelTexts.get(fieldId) ||
			this.i18nService.get(
				`notificationRules.${selectAllLabelKey ? selectAllLabelKey + '.' : ''}selectValues`
			)
		);
	}

	setLabelText(fieldId: string, values: Array<ChecklistValue>, selectAllLabelKey?: string) {
		const labelText: string = this._setLabelText(values, selectAllLabelKey),
			currentValue: string = this.valuesLabelTexts.get(fieldId);

		if (!currentValue || currentValue !== labelText) this.valuesLabelTexts.set(fieldId, labelText);
	}

	onMachineGroupsChange(selectedMachineGroups: Array<ChecklistValue>) {
		if (!selectedMachineGroups || !selectedMachineGroups.length)
			this._setCurrentMachineGroups((this.editedRule.machineGroups = []));
		else {
			of(selectedMachineGroups)
				.pipe(
					mergeMap((_selectedMachineGroups: Array<ChecklistValue>) =>
						combineLatest(
							_selectedMachineGroups.map(selectedGroup =>
								this.machineGroupsRepo.getItemById(selectedGroup.id)
							)
						)
					)
				)
				.subscribe((machineGroups: Array<MachineGroup>) => {
					this._setCurrentMachineGroups((this.editedRule.machineGroups = machineGroups));
				});
		}
	}

	onMachineGroupScopeChange(selectedScope: ChecklistValue) {
		if (
			selectedScope.id === 'all' &&
			this.editedRule.machineGroups &&
			this.editedRule.machineGroups.length
		)
			this.editedRule.machineGroups = this.currentMachineGroups = [];
	}

	add(email: string) {
		this.updateEmail(email);
		this.emailEl.nativeElement.focus();
		this.notificationRuleForm.form.markAsDirty();
	}

	updateEmail(email: string) {
		const emailExists = ~this.editedRule.recipients.indexOf(email);
		if (!emailExists) {
			this.editedRule.recipients.splice(0, 0, email);
			this.announceEmailListUpdated();
		};
		this.currentEmail = '';
	}

	removeEmail(email: string) {
		const emailIndex = this.editedRule.recipients.indexOf(email);
		if (~emailIndex) {
			this.editedRule.recipients.splice(emailIndex, 1);
			this.announceEmailListUpdated();
		};
	}

	setCurrentTab(tab?: TabModel){
		if (this.currentTab !== tab){
			this.currentTab = tab ? tab : this.currentTab === this.tabs[0] ? this.tabs[1] : this.tabs[0];
			this.changeDetectorRef.detectChanges();
			setTimeout(()=>{
				this.focusOnRecipient = this.currentTab !== this.tabs[0];
			})
		}
	}


	private _setAvailableScopes() {
		if (this.allMachineGroups.length) {
			if (
				this.authService.currentUser.isMdeAdmin ||
				(this.editedRule && (this.editedRule.readonly || !this.isUserAllowedActions))
			) {
				this.allowAllMachineGroups = this.allowSpecificMachineGroups = true;
			} else {
				this.allowSpecificMachineGroups = true;
			}
		} else
			this.currentMachineGroupScope = this.selectableMachineGroupScopes.find(
				scope => scope.id === 'all'
			);
	}

	private _setLabelText(values: Array<ChecklistValue>, selectAllLabelKey?: string): string {
		if (values.length > 3) {
			return this.i18nService.get('notificationRules.editRule.manyValues', { length: values.length });
		}
		const _values = values.map(value => value.name);
		if (_values.length) return _values.join(', ');
		return this.i18nService.get(
			`notificationRules.${selectAllLabelKey ? selectAllLabelKey + '.' : ''}selectValues`
		);
	}

	private _setCurrentScope() {
		this.currentMachineGroupScope =
			this.editedRule.machineGroups && this.editedRule.machineGroups.length
				? this.selectableMachineGroupScopes.find(scope => scope.id === 'specific')
				: this.selectableMachineGroupScopes.find(scope => scope.id === 'all');
	}

	private _getUserExposedRbacGroups(): Observable<Array<MachineGroup>> {
		return this.machinesService.getFullUserExposedMachineGroups().pipe(
			tap((userExposedMachineGroups?: Array<MachineGroup>) => {
				this.availableMachineGroups = this.allMachineGroups
					.filter((machineGroup: MachineGroup) => {
						return (
							!this.featuresService.isEnabled(Feature.RbacMachineGroups) ||
							(this.editedRule && (this.editedRule.readonly || !this.isUserAllowedActions)) ||
							some(userExposedMachineGroups, { id: machineGroup.id })
						);
					})
					.map((machineGroup: MachineGroup) => this._getMachineGroupCheckListValue(machineGroup));
			})
		);
	}

	private _setCurrentMachineGroups(machineGroups: Array<MachineGroup>) {
		this.currentMachineGroups = machineGroups.map((machineGroup: MachineGroup) =>
			this._getMachineGroupCheckListValue(machineGroup)
		);

		this.setLabelText(this.machineGroupsFieldId, this.currentMachineGroups, 'machineGroups');
	}

	private _getMachineGroupCheckListValue(machineGroup: MachineGroup): ChecklistValue {
		return {
			id: machineGroup.id,
			name: machineGroup.isUnassignedMachineGroup
				? this.i18nService.get('machineGroup.unassignedGroup.name')
				: machineGroup.name,
		};
	}

	private announceEmailListUpdated(){
		this.liveAnnouncer.announce(
			this.i18nService.get('notificationRules.ruleEmails.updated'),
			'assertive',
			300
		);
	}
}

enum SendTestStatuses {
	notStarted = 0,
	pending = 1,
	success = 2,
	error = 3,
}
