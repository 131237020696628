var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (b.hasOwnProperty(p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
import { Identifiable } from '../../../data/models/identifiable.model';
var FolderExclusionModel = /** @class */ (function (_super) {
    __extends(FolderExclusionModel, _super);
    function FolderExclusionModel(data) {
        var _this = _super.call(this, data) || this;
        if (!data) {
            _this._extensions = [];
            _this._fileNames = [];
        }
        return _this;
    }
    Object.defineProperty(FolderExclusionModel.prototype, "extensions", {
        get: function () {
            return this._extensions ? this._extensions.join(', ') : '';
        },
        set: function (value) {
            this._extensions = value ? value.split(/(?:\s+)?,(?:\s+)?/).filter(function (val) { return val !== ''; }) : [];
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(FolderExclusionModel.prototype, "fileNames", {
        get: function () {
            return this._fileNames ? this._fileNames.join(', ') : '';
        },
        set: function (value) {
            this._fileNames = value ? value.split(/(?:\s+)?,(?:\s+)?/).filter(function (val) { return val !== ''; }) : [];
        },
        enumerable: true,
        configurable: true
    });
    FolderExclusionModel.prototype.setData = function (data) {
        _super.prototype.setData.call(this, data);
        this.folder = data.properties.folder;
        this._extensions = data.properties.extensions || [];
        this._fileNames = data.properties.file_names || [];
        this.description = data.properties.description || null;
        this.creationTime = data.creation_time ? new Date(data.creation_time) : new Date();
        this.creatorName = data.creator_name;
    };
    FolderExclusionModel.prototype.getBackendData = function () {
        return {
            folder: this.folder,
            extensions: this._extensions,
            file_names: this._fileNames,
            description: this.description || '',
        };
    };
    return FolderExclusionModel;
}(Identifiable));
export { FolderExclusionModel };
