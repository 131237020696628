var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (b.hasOwnProperty(p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { OnInit, ChangeDetectorRef, OnDestroy } from '@angular/core';
import { MessageBarType } from 'office-ui-fabric-react';
import { NextButtonModeEnum, WizardBaseStep } from '@wcd/wizard';
import { I18nService } from '@wcd/i18n';
import { Paris } from '@microsoft/paris';
import { AuthenticatedScanResult, PostAuthenticatedScanReachabilityTest, GetAuthenticatedScanReachabilityTestOutput, AuthenticatedScanSessionRelationship, AuthenticatedScanSession, ResolveEntityURL, TvmEndPoint, AuthenticatedScanSessionCsvRelationship, AssessmentType, } from '@wcd/domain';
import { DialogsService } from '../../../../../dialogs/services/dialogs.service';
import { interval, of } from 'rxjs';
import { flatMap } from 'rxjs/operators';
import { AssessmentJobService } from '../../services/assessment-job.service';
import { GlobalEntityTypesService } from '../../../../../global_entities/services/global-entity-types.service';
import { AuthenticatedScanResultFieldsService } from '../../services/authenticated-scan-result.fields.service';
import { ScanButtonState } from './scan-button-state.enum';
import { SpinnerSize } from 'office-ui-fabric-react';
import { TvmDownloadService } from '../../../../../tvm/services/tvm-download.service';
import { LiveAnnouncer } from '@angular/cdk/a11y';
var AssessmentJobScanStepComponent = /** @class */ (function (_super) {
    __extends(AssessmentJobScanStepComponent, _super);
    function AssessmentJobScanStepComponent(i18n, paris, dialogsService, changeDetection, assessmentJobService, globalEntityTypesService, fieldsService, tvmDownloadService, liveAnnouncer) {
        var _this = _super.call(this) || this;
        _this.i18n = i18n;
        _this.paris = paris;
        _this.dialogsService = dialogsService;
        _this.changeDetection = changeDetection;
        _this.assessmentJobService = assessmentJobService;
        _this.globalEntityTypesService = globalEntityTypesService;
        _this.fieldsService = fieldsService;
        _this.tvmDownloadService = tvmDownloadService;
        _this.liveAnnouncer = liveAnnouncer;
        _this.getAuthenticatedScanReachabilityTestOutputInterval = 1500;
        _this.scanProgressMaxWaitingPercentage = 15;
        _this.scanProgressIncPercentage = 0.3;
        _this.MessageBarType = MessageBarType;
        _this.ScanButtonState = ScanButtonState;
        _this.scanButtonState = ScanButtonState.Start;
        _this.SpinnerSize = SpinnerSize;
        _this.isWithProgressBar = true;
        _this.fields = function () {
            return _this.data.assessmentJob.scanType === AssessmentType.WindowsAssessment
                ? _this.fieldsService.windowsScanResultFields
                : _this.fieldsService.networkScanResultFields;
        };
        _this.onNext = function () {
            // User will not select ips as test scan won't run
            if (!_this.isTestScanAllowed) {
                _this.data.assessmentJob.target = _this.data.assessmentJob.originalTargetRanges;
                return of(true);
            }
            if (!_this.isSkipped) {
                return of(true);
            }
            _this.dialogsService
                .confirm({
                title: _this.i18n.get('tvm_authenticatedScan_wizard_scan_step_skip_popup_title'),
                text: _this.i18n.get('tvm_authenticatedScan_wizard_scan_step_skip_popup_text'),
                confirmText: _this.i18n.get('tvm_authenticatedScan_wizard_scan_step_skip_popup_confirm_text'),
            })
                .then(function (e) {
                if (e.confirmed) {
                    // User will not select ips as test scan won't run
                    _this.data.assessmentJob.target = _this.data.assessmentJob.originalTargetRanges;
                    _this.goToStep(_this.reviewStep);
                }
            });
            return of(false);
        };
        _this.reviewStep = _this.assessmentJobService.isWindowsScanFeatureEnabled() ? 3 : 2;
        _this.entityType = _this.globalEntityTypesService.getEntityType(AuthenticatedScanResult);
        _this.repository = _this.paris.getRelationshipRepository(AuthenticatedScanSessionRelationship);
        _this.dataViewOptions = __assign({}, _this.entityType.dataViewOptions);
        return _this;
    }
    AssessmentJobScanStepComponent.prototype.ngOnInit = function () {
        var _this = this;
        this.setIsTestAllowed();
        this.setOnNext(this.onNext);
        this.shouldSelectIpAddress = function (authenticatedScanResult) {
            if (_this.data.selectedIps.length === 0 && authenticatedScanResult.scanSuccess) {
                return true;
            }
            return _this.data.selectedIps.includes(authenticatedScanResult.ip);
        };
        this.setNextMode();
    };
    AssessmentJobScanStepComponent.prototype.setIsTestAllowed = function () {
        this.isTestScanAllowed =
            this.assessmentJobService.countIpAddresses(this.data.assessmentJob.originalTargetRanges) <
                this.assessmentJobService.maxAllowedIpAddressesForScan + 1;
    };
    AssessmentJobScanStepComponent.prototype.ngOnDestroy = function () {
        this._testConnectionSubscription && this._testConnectionSubscription.unsubscribe();
    };
    AssessmentJobScanStepComponent.prototype.testConnection = function () {
        var _this = this;
        this.data.canShowScans = false;
        this.data.requiredNewScan = true;
        this.resetProgressBar();
        this.setNextMode();
        this.scanButtonState = ScanButtonState.Stop;
        this.data.startScanTime = new Date().toLocaleString();
        var scan = this.AuthenticatedScanObjectPreProcessingForTestRequest();
        this.paris.apiCall(PostAuthenticatedScanReachabilityTest, scan).subscribe(function (sessionId) {
            _this.authenticatedScanSession = new AuthenticatedScanSession();
            _this.authenticatedScanSession.id = sessionId;
            _this.testConnectionOutput(sessionId);
        }, function (error) {
            _this.changeDetection.markForCheck();
            _this.dialogsService.showError({
                title: _this.i18n.get('error.status.code', { status: error.status }),
                data: _this.i18n.get('tvm.authenticatedScan.creationPanel.testRunError'),
            });
            _this.finishTestConnection();
            _this.scanButtonState = ScanButtonState.Start;
        });
        this.changeDetection.detectChanges();
    };
    AssessmentJobScanStepComponent.prototype.AuthenticatedScanObjectPreProcessingForTestRequest = function () {
        var scan = this.assessmentJobService.preProcessAuthenticatedScanBeforeSendingToBackend(this.data.assessmentJob);
        // For test scan we sent all the ranges in target
        scan.target = scan.originalTargetRanges;
        return scan;
    };
    AssessmentJobScanStepComponent.prototype.testConnectionOutput = function (sessionId) {
        var _this = this;
        this.changeDetection.markForCheck();
        this._testConnectionSubscription = interval(this.getAuthenticatedScanReachabilityTestOutputInterval)
            .pipe(flatMap(function () { return _this.paris.apiCall(GetAuthenticatedScanReachabilityTestOutput, sessionId); }))
            .subscribe(function (response) {
            if (response.endOfFile) {
                _this.scanButtonState = ScanButtonState.Restart;
                _this.repository.sourceItem = _this.authenticatedScanSession;
                _this.data.requiredNewScan = false;
                _this.data.canShowScans = true;
                _this.setNextMode();
                _this.setStepValidation(_this.data.selectedIps.length > 0 &&
                    _this.data.selectedIps.join(',').indexOf('/') < 0);
                _this.setDataViewConfig();
                _this.finishTestConnection();
            }
            else if (response.output.length > 0) {
                //if we are getting an output
                _this.refreshProgressBar(response);
            }
            else if (_this.scanProgressPercentage < _this.scanProgressMaxWaitingPercentage) {
                //while waiting for the agent to start scanning (can take around 40 seconds)
                _this.scanProgressPercentage += _this.scanProgressIncPercentage;
                _this.changeDetection.markForCheck();
            }
        }, function (error) {
            _this.dialogsService.showError({
                title: _this.i18n.get('error.status.code', { status: error.status }),
                data: _this.i18n.get('tvm.authenticatedScan.creationPanel.testOutputError'),
            });
            _this.scanButtonState = ScanButtonState.Start;
            _this.finishTestConnection();
        });
        //give up after 15 minutes
        setTimeout(function () {
            _this.scanButtonState = ScanButtonState.Start;
            _this.finishTestConnection();
        }, 900000);
    };
    AssessmentJobScanStepComponent.prototype.finishTestConnection = function () {
        this._testConnectionSubscription && this._testConnectionSubscription.unsubscribe();
        this.changeDetection.detectChanges();
    };
    AssessmentJobScanStepComponent.prototype.onStopClick = function () {
        this.scanButtonState = ScanButtonState.Start;
        this.finishTestConnection();
    };
    AssessmentJobScanStepComponent.prototype.onItemSelect = function ($event) {
        this.data.selectedIps = $event.items.map(function (item) { return item.ip; });
        this.data.assessmentJob.target = this.data.selectedIps.join(', ');
        // Prevent next on empty selection
        this.setStepValidation(this.data.selectedIps.length > 0);
    };
    AssessmentJobScanStepComponent.prototype.setDataViewConfig = function () {
        var _this = this;
        this.dataViewConfig = {
            showModalOnExport: false,
            exportResults: function (options, format, dataQuery) {
                var url = ResolveEntityURL({
                    endPoint: TvmEndPoint.AuthenticatedScans,
                    entityModelBaseOrRelationship: AuthenticatedScanSessionCsvRelationship,
                    id: _this.authenticatedScanSession.id,
                });
                return _this.tvmDownloadService.downloadCsv({
                    url: url,
                    fileName: 'export-device-assessment-job',
                    dataQuery: dataQuery,
                    alreadyHasQueryParamInUrl: true,
                });
            },
        };
    };
    AssessmentJobScanStepComponent.prototype.setNextMode = function () {
        if (!this.isTestScanAllowed) {
            this.setNextButtonMode(NextButtonModeEnum.Normal);
            this.setStepValidation(true);
            this.isSkipped = false;
        }
        else if (this.data.requiredNewScan) {
            this.setNextButtonMode(NextButtonModeEnum.Skip, 'tvm_authenticatedScan_wizard_scan_step_skip_button');
            this.setStepValidation(true);
            this.isSkipped = true;
        }
        else {
            this.setNextButtonMode(NextButtonModeEnum.Normal);
            this.setStepValidation(this.data.selectedIps.length > 0);
            this.isSkipped = false;
        }
    };
    AssessmentJobScanStepComponent.prototype.resetProgressBar = function () {
        this.sampledScanIPSuccess = '';
        this.sampledScanIPFailure = '';
        this.showedSampledScans = new Map();
        this.scanProgressPercentage = 0;
        this.progressBarLabel = this.i18n.get('tvm_authenticatedScan_wizard_scan_step_progress_bar_loading_text');
        this.announceAction('tvm_authenticatedScan_wizard_scan_step_progress_bar_loading_text');
    };
    AssessmentJobScanStepComponent.prototype.refreshProgressBar = function (response) {
        if (response.totalIpAddresses !== 0) {
            this.progressBarLabel = this.i18n.get('tvm_authenticatedScan_wizard_scan_step_progress_bar_scan_text');
            this.scanProgressPercentage = Math.max((response.scannedIpAddresses * 100) / response.totalIpAddresses, this.scanProgressMaxWaitingPercentage);
            this.announceAction('tvm_authenticatedScan_wizard_scan_step_progress_bar_scan_text');
            var failureTemp = '';
            var i = 0;
            //search successful scan
            while (i < response.output.length) {
                var scan = response.output[i];
                if (scan.scanSuccess && !this.showedSampledScans[scan.ip]) {
                    this.isSampledScanSuccess = true;
                    this.sampledScanIPSuccess = scan.ip;
                    this.showedSampledScans[scan.ip] = true;
                    break;
                }
                else if (failureTemp === '' && !this.showedSampledScans[scan.ip]) {
                    failureTemp = scan.ip;
                }
                i++;
            }
            //no successful scan was found
            if (i === response.output.length) {
                this.isSampledScanSuccess = false;
                this.sampledScanIPFailure = failureTemp;
                this.showedSampledScans[this.sampledScanIPFailure] = true;
            }
        }
        else {
            this.isWithProgressBar = false;
        }
        this.changeDetection.markForCheck();
    };
    AssessmentJobScanStepComponent.prototype.announceAction = function (key) {
        this.liveAnnouncer.announce(this.i18n.get(key), 'assertive', 300);
    };
    return AssessmentJobScanStepComponent;
}(WizardBaseStep));
export { AssessmentJobScanStepComponent };
