import { NgModule } from '@angular/core';
import { GlobalEntitiesModule } from '../../global_entities/global-entities.module';
import { GlobalEntityTypesService } from '../../global_entities/services/global-entity-types.service';
import { SharedModule } from '../../shared/shared.module';
import { MailboxEntityPanelComponent } from './components/mailbox.entity-panel.component';
import { MailboxEntityTypeService } from './services/mailbox.entity-type.service';
import { MailClusterEntityTypeService } from './services/mailCluster.entity-type.service';
import { MailMessageEntityTypeService } from './services/mailMessage.entity-type.service';
import { MailboxAlertsComponent } from './components/mailbox-alerts.component';
import { MailboxDetailsFieldComponent } from './components/mailbox-details-field.component';
import { ReportsModule } from '../../reports/reports.module';
import { AlertsModule } from '../alerts/alerts.module';
import { FabShimmerModule } from '@angular-react/fabric';
import { GoHuntModule } from '../../hunting-go-hunt/hunting-go-hunt.module';

@NgModule({
	imports: [
		SharedModule,
		GlobalEntitiesModule,
		ReportsModule,
		AlertsModule,
		FabShimmerModule,
		GoHuntModule,
	],
	providers: [MailboxEntityTypeService, MailMessageEntityTypeService, MailClusterEntityTypeService],
	declarations: [MailboxEntityPanelComponent, MailboxAlertsComponent, MailboxDetailsFieldComponent],
	entryComponents: [MailboxEntityPanelComponent],
	exports: [MailboxDetailsFieldComponent],
})
export class MailboxEntityTypeModule {
	constructor(globalEntityTypesService: GlobalEntityTypesService) {
		globalEntityTypesService.registerEntityType(MailboxEntityTypeService);
		globalEntityTypesService.registerEntityType(MailMessageEntityTypeService);
		globalEntityTypesService.registerEntityType(MailClusterEntityTypeService);
	}
}
