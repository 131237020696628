var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
import { EntityRelationship, RelationshipType, } from '@microsoft/paris';
import { FileVerdict } from '../file.file-verdict.value-object';
import { Alert } from '../../alert/alert.entity';
var ɵ0 = function (file) { return ({
    fileThreatName: file.threatName,
}); }, ɵ1 = function (dataQuery) {
    var where = dataQuery && dataQuery.where ? dataQuery.where : {};
    return __assign({ detectionSource: 'WindowsDefenderAv' }, where, { lookBackInDays: 180 });
};
var FileVerdictAlertsRelationship = /** @class */ (function () {
    function FileVerdictAlertsRelationship() {
    }
    FileVerdictAlertsRelationship = __decorate([
        EntityRelationship({
            sourceEntity: FileVerdict,
            dataEntity: Alert,
            endpoint: 'AssociatedAlerts',
            allowedTypes: [RelationshipType.OneToMany],
            getRelationshipData: ɵ0,
            allItemsProperty: 'Items',
            allItemsEndpointTrailingSlash: false,
            parseDataQuery: ɵ1,
        })
    ], FileVerdictAlertsRelationship);
    return FileVerdictAlertsRelationship;
}());
export { FileVerdictAlertsRelationship };
export { ɵ0, ɵ1 };
