import { EntityRelationship, EntityRelationshipRepositoryType, RelationshipType } from '@microsoft/paris';
import { Alert } from '../alert/alert.entity';
import { CyberEvent } from './cyber-event.entity';

@EntityRelationship({
	sourceEntity: CyberEvent,
	dataEntity: Alert,
	endpoint: (config, query) => `machines/${query.where['MachineId']}/event/AssociatedAlerts`,
	getRelationshipData: (event: CyberEvent) => {
		return {
			ActionTime: event.actionTime.toISOString(),
			ReportId: event.reportId,
			MachineId: event.machine.id,
		};
	},
	allItemsEndpointTrailingSlash: false,
	allowedTypes: [RelationshipType.OneToMany],
})
export class CyberEventAlertsRelationship implements EntityRelationshipRepositoryType<CyberEvent, Alert> {}
