import { Entity, EntityField } from '@microsoft/paris';
import { Alert } from '../alert/alert.entity';
import {
	AlertStatusTypeId,
	AlertV3StatusType,
	AlertV3StatusTypeMapping,
} from '../alert/alert-status/alert-status.models';
import { RemediationActionTypeActionCount } from '../remediation/remediation-action-type-action-count.value-object';
import { InvestigatedMachine } from '../investigation/investigated-machine.entity';
import { InvestigationStatus } from '../investigation/investigation-status.entity';
import { InvestigatedUser } from '../investigation/investigated-identity.value-object';
import { airsEntityStatusValues } from '../airs_entity/airs-entity-status.entity';
import { airsEntityRemediationStatuses } from '../airs_entity/airs-entity-remediation-status.entity';
import {
	AirsEntityType,
	AirsEntityTypeValue,
	convertEntityTypeCase,
} from '../airs_entity/airs-entity-type.entity';
import { AirsEntityTypeResults } from '../airs_entity/airs-entity-type-results.value-object';
import { MachineStatusType } from '../machine/machine-status.entity.values';
import { Mailbox } from '../mailbox/mailbox.entity';
import { remediationActionTypeValues } from '../remediation/remediation-action-type.values';
import { PendingActionDecisionTypes } from '../remediation/pending_actions/pending-action-decision-types.enum';
import {
	RemediationStatusId,
	RemediationStatusType,
	VerdictStatusType,
} from '../airs_entity/airs-entity-status-types.enum';
import { capitalize, get, isEmpty, isNil, orderBy } from 'lodash-es';
import { PendingTypeEnum } from '../investigation/pending-type.enum';
import { RemediationThreatStatusSummary } from '../remediation/remediation-threat-status-summary';
import { AssetTypeEnum } from './asset-type.enum';
import { InvestigationProviderTypeEnum } from './investigation-provider.enum';
import { AlertV3ActionTypeMap } from '../action-history/alertV3-action-type-types';
import { DetectionSourceType } from '../alert/sources/detection-source.enum';
import { ServiceSourceType } from '../alert/sources/service-source.enum';
import { SeverityType } from '../severity/severity-type.enum';
import { severityValues } from '../severity/severity.entity.values';
import { Investigation } from '../investigation/investigation.entity';
import { PendingAction } from '../remediation/pending_actions/pending-action.entity';
import { EntityDisplayNameFunction } from '../airs_entity/alertV3/entity-display-name.utils';
import {
	InvestigationActionStatusId,
	InvestigationActionStatusType,
} from '../investigation/actions/investigation-action-status.values';
import { RemediationAction } from '../remediation/remediation-action.entity';
import { OfficeUtils } from '../utils/office-utils';
import { EvidenceEntity } from '../evidence/evidence.entity';
import { EvidenceDetectionSourceTypeEnum } from '../evidence/evidence-detection-source-type.enum';
import { InvestigationStatusesMapping } from '../alert/alert-investiation-status-mapping.enum';
import { AlertClassificationId, AlertClassificationType } from '../alert/alert-classification.entity.values';
import {
	InvestigationStatusEnum,
	InvestigationStatusNameEnum,
} from '../investigation/investigation-status.enum';
import { EngineReportParameters } from '../airs_entity/engine-report-parameters.enum';
import {
	getConvertedOfficeActionType,
	mapOfficeActionToRemediationAction,
} from '../investigation/actions/office-action.utils';
import { dateStrToUtc } from '../utils';
import { sccHostService } from '@wcd/scc-interface';

const UNKNOWN = airsEntityStatusValues.find(s => s.type === VerdictStatusType.Unknown).id;
const OFFICE_ALERT_ID_PREFIX = 'fa';
const REMEDIATION_DATE_FIELD = 'RemediationDate';
const NONE = 'None';

export const ANALYSIS_DATE_FIELD = 'AnalysisDate';

@Entity({
	singularName: 'Investigation',
	pluralName: 'Investigations',
	endpoint: 'Find/MtpBatch',
	allItemsProperty: 'results',
	baseUrl: sccHostService.mock.isMockMode ? sccHostService.mock.mockHost + '/<di>' : '<di>',
	separateArrayParams: true,
	cache: {
		time: (mtpInvestigation: MtpInvestigation) => (mtpInvestigation.isRunning ? 2500 : 1000 * 60 * 2),
		max: 10,
	},
	parseItemQuery: (itemId, entity, config, params) =>
		// There's bug in office that for PageSize=1 we don't get all the data, hence, PageSize=50
		`Find/MtpBatch?tenantid=${
			params.tenantId
		}&PageSize=50&Filter=ModelType eq 2 and ContainerUrn eq '${itemId}'`,
	parseData: rawData => {
		const resData = get(rawData, 'ResultData[0].InvestigationDetailsPayload[0]');
		const convertedData = OfficeUtils.convertDataFromAlertV3(resData);

		// filter out mailbox and unknown entities from Evidence
		convertedData.Evidence =
			convertedData &&
			convertedData.Evidence &&
			convertedData.Evidence.filter(ev => {
				const convertedType = getConvertedEntityTypeName(ev.Type);
				const typeId = AirsEntityType[convertedType];
				return convertedType !== AirsEntityTypeValue.Mailbox && typeId != null;
			});

		convertedData.Actions =
			convertedData &&
			convertedData.Actions &&
			convertedData.Actions.reduce((res, action) => {
				// check for 'SubActionType' and set it as ActionType - this is a temp solution until
				// office fix the response and send it as ActionType and not as SubActionType
				const convertedOfficeActionType = getConvertedOfficeActionType(action);

				// change the action status if there's a decision
				const actionStatus =
					action.ActionStatus === InvestigationActionStatusType.Pending &&
					(action.ActionApproval && action.ActionApproval !== NONE)
						? InvestigationActionStatusType.Running
						: action.ActionStatus;

				res.push(
					Object.assign({}, action, {
						ActionType: convertedOfficeActionType,
						ActionStatus: actionStatus,
					})
				);
				return res;
			}, []);
		return convertedData;
	},
})
export class MtpInvestigation extends Investigation {
	@EntityField({ data: 'InvestigationId' })
	// @ts-ignore shared between scc (useDefineForClassFields) and the old portal
	id: string | number;

	@EntityField({ data: 'EndTime', parse: dateStr => dateStrToUtc(dateStr) })
	// @ts-ignore shared between scc (useDefineForClassFields) and the old portal
	endDate: Date;

	@EntityField({ data: 'StartTime', parse: dateStr => dateStrToUtc(dateStr) })
	// @ts-ignore shared between scc (useDefineForClassFields) and the old portal
	startDate: Date;

	@EntityField({ data: 'Title' })
	// @ts-ignore shared between scc (useDefineForClassFields) and the old portal
	title: string;

	@EntityField({ data: 'IsLiveResponse' })
	// @ts-ignore shared between scc (useDefineForClassFields) and the old portal
	isLiveResponse: boolean;

	@EntityField({
		data: 'Status',
		parse: status => {
			// For office investigation we will get Investigation Status as a String so we need to parse it to int
			const intStatus = parseInt(status);
			if (isNaN(intStatus)) {
				return status === InvestigationStatusNameEnum.Failed
					? InvestigationStatusEnum.terminatedBySystem
					: InvestigationStatusesMapping[status];
			}
			return intStatus;
		},
	})
	// @ts-ignore shared between scc (useDefineForClassFields) and the old portal
	status: InvestigationStatus;

	@EntityField({ data: 'RequestedStatus' })
	// @ts-ignore shared between scc (useDefineForClassFields) and the old portal
	requestedStatus: InvestigationStatus;

	@EntityField({
		data: 'Actions',
		parse: actions => {
			return (
				actions &&
				actions
					.filter(
						action =>
							action.ActionStatus === InvestigationActionStatusType.Pending ||
							action.ActionStatus === InvestigationActionStatusType.WaitingForResource
					)
					.reduce((res, action) => {
						// this is a temporary conversion to support V3
						const investigationActionType = AlertV3ActionTypeMap[action.ActionType];
						const actionType = remediationActionTypeValues.find(
							s => s.type === investigationActionType
						);
						const actionTypeId = actionType && actionType.id;
						if (!isNil(actionTypeId)) {
							const actionIdEntry = res.find(d => d.action === actionTypeId);

							if (actionIdEntry) {
								actionIdEntry.count += 1;
							} else {
								res.push({ action: actionTypeId, count: 1 });
							}
						}
						return res;
					}, [])
			);
		},
		arrayOf: RemediationActionTypeActionCount,
	})
	// @ts-ignore shared between scc (useDefineForClassFields) and the old portal
	pendingActionTypes: Array<RemediationActionTypeActionCount>;

	@EntityField({ data: 'IsRemediationDisabled' })
	// @ts-ignore shared between scc (useDefineForClassFields) and the old portal
	isRemediationDisabled: boolean;

	// this field contains both actions that were pending and current pending.
	@EntityField({
		data: 'Actions',
		arrayOf: PendingAction,
		parse: (actions, rawData) => {
			return (
				actions &&
				actions.reduce((res, action) => {
					// for each type - use different field to represent the displayName
					const firstEntityType =
						action.Entities && action.Entities.length !== 0 ? action.Entities[0].Type : '';

					const firstEntityConvertedCase = getConvertedEntityTypeName(firstEntityType);
					const entityDisplayNameFunc = EntityDisplayNameFunction[firstEntityConvertedCase];
					const entityDisplayName = entityDisplayNameFunc
						? entityDisplayNameFunc(action.Entities[0])
						: '';

					const actionEntities =
						action.Entities &&
						action.Entities.reduce((res, entity) => {
							const convertedEntityType = getConvertedEntityTypeName(entity.Type);
							res.push({
								entity_id: entity.Urn,
								entity_type: AirsEntityType[convertedEntityType],
							});
							return res;
						}, []);

					const investigationActionType = AlertV3ActionTypeMap[action.ActionType];
					const actionType =
						investigationActionType &&
						remediationActionTypeValues.find(s => s.type === investigationActionType);

					const endTime = action.EndOfPendingUtc || action.ActionApprovedTime || action.EndTimeUtc;
					res.push({
						entity_name: entityDisplayName,
						action_type: actionType,
						action_id: action.ActionId,
						start_time:
							action.StartTimeUtc &&
							(action.StartTimeUtc.endsWith('Z')
								? action.StartTimeUtc
								: action.StartTimeUtc + 'Z'),
						end_time: endTime && (endTime.endsWith('Z') ? endTime : endTime + 'Z'),
						entities: actionEntities,
						decision: PendingActionDecisionTypes[action.ActionApproval],
						user: action.ApprovedBy,
						PendingType: action.PendingType,
						ActionStatus: action.ActionStatus,
						IsOfficeAction: rawData.InvestigationProvider === InvestigationProviderTypeEnum.OATP,
					});
					return res;
				}, [])
			);
		},
	})
	_allPendingActions: Array<PendingAction>;

	@EntityField({
		data: 'Actions',
		arrayOf: RemediationAction,
		parse: actions => {
			return (
				actions &&
				actions
					.filter(action => action.ActionStatus === InvestigationActionStatusType.Pending)
					.reduce((res, action) => {
						const firstEntity =
							action.Entities && action.Entities.length > 0 ? action.Entities[0] : {};

						const remediationAction = mapOfficeActionToRemediationAction(action, firstEntity);
						if (remediationAction) {
							res.push(remediationAction);
						}

						return res;
					}, [])
			);
		},
	})
	pendingRemediationActions: Array<RemediationAction>;

	@EntityField({
		data: 'Assets',
		arrayOf: InvestigatedMachine,
		parse: assets => {
			const hosts = assets && assets.filter(asset => asset.Type === AssetTypeEnum.host);
			return (
				hosts &&
				hosts.reduce((res, asset) => {
					res.push({
						status: MachineStatusType[asset.Status],
						name: asset.Name,
						ip: asset.Ip,
						users: asset.Users,
						id: asset.Urn || asset.Id,
					});
					return res;
				}, [])
			);
		},
	})
	// @ts-ignore shared between scc (useDefineForClassFields) and the old portal
	machines: Array<InvestigatedMachine>;

	@EntityField({
		data: 'Assets',
		arrayOf: Mailbox,
		parse: assets => {
			const mailboxes = assets && assets.filter(asset => asset.Type === AssetTypeEnum.mailbox);
			return (
				mailboxes &&
				mailboxes.reduce((res, asset) => {
					const threatsKeys = getThreatsNames(asset.ThreatAnalysisSummary);
					res.push({
						MailboxPrimaryAddress: asset.MailboxPrimaryAddress,
						Upn: asset.Upn,
						Verdict: asset.Verdict,
						Urn: asset.Urn,
						DisplayName: asset.DisplayName,
						RiskLevel: asset.RiskLevel,
						RiskKeys: threatsKeys,
						RiskyActivities: threatsKeys ? threatsKeys.length : 0,
					});

					return res;
				}, [])
			);
		},
	})
	mailboxes: Array<Mailbox>;

	@EntityField({
		data: 'Assets',
		arrayOf: InvestigatedUser,
		parse: assets => {
			const users = assets && assets.filter(asset => asset.Type === AssetTypeEnum.user);
			return (
				users &&
				users.reduce((res, asset) => {
					res.push({ upn: asset.Upn });
					return res;
				}, [])
			);
		},
	})
	// @ts-ignore shared between scc (useDefineForClassFields) and the old portal
	investigatedUsers: Array<InvestigatedUser>;

	@EntityField({ data: 'Alerts', parse: alerts => (alerts ? alerts.length : 0) })
	// @ts-ignore shared between scc (useDefineForClassFields) and the old portal
	totalAlertsCount: number;

	@EntityField({
		data: 'Evidence',
		parse: evidence => (evidence && evidence.length ? evidence.length : 0),
		defaultValue: 0,
	})
	threatCount: number;

	@EntityField({
		data: 'Evidence',
		arrayOf: EvidenceEntity,
		parse: (evidence, rawData) => {
			return (
				evidence &&
				evidence.reduce((res, ev) => {
					const convertedEntityType = OfficeEntityTypeValue[ev.Type];
					const convertedEntityTypeName = convertedEntityType ? convertedEntityType.name : '';
					const entityDisplayNameFunc = EntityDisplayNameFunction[convertedEntityTypeName];
					const displayNameValue = entityDisplayNameFunc ? entityDisplayNameFunc(ev) : '';

					// take the last remediationProvider
					const lastRemediationProvider = getLastRemediationProvider(ev.RemediationProviders);

					/*
						TODO:
						 In the future office should add Type (SecurityAlert/InvestigationAction) to RemediationProvider
						 so we can get the 'detectionType' from OfficeEvidenceDetectionSourceEnum.
						 Temporary solution - the only value is 'Alert'
						 (https://microsoft.visualstudio.com/OS/_workitems/edit/26379425)
					 */
					// const detectionType = OfficeEvidenceDetectionSourceEnum[lastRemediationProvider.Type];

					// create detection source object (can be for 'alert' or 'investigation' but for now - only for 'alert')
					const entityDetectionSrc = {};

					const detectionType = !isEmpty(lastRemediationProvider)
						? EvidenceDetectionSourceTypeEnum.alert
						: '';

					if (detectionType) {
						const severity = lastRemediationProvider.Severity
							? severityValues.find(
									val => val.type === SeverityType[lastRemediationProvider.Severity]
							  )
							: '';

						Object.assign(entityDetectionSrc, {
							detection_source_name_key: `evidence_detectionSource_${
								EvidenceDetectionSourceTypeEnum.alert
							}`,
							detection_source_id: lastRemediationProvider.ProviderAlertId,
							detection_type: detectionType,
							additional_data: severity
								? {
										alert: { severity: severity.id },
								  }
								: null,
						});
					}

					// take the last ThreatAnalysisSummary
					const threatsKeys = getThreatsNames(ev.ThreatAnalysisSummary);

					const verdictType = VerdictStatusType[ev.LastVerdict];
					const verdict = airsEntityStatusValues.find(s => s.type === verdictType);

					// 'LastRemediationState' will be empty in case of remediation action not started yet.
					const remediationStatusTypeId = getRemediationStateId(
						ev.LastRemediationState,
						lastRemediationProvider
					);

					res.push({
						id: ev.Urn,
						first_seen: dateStrToUtc(ev.FirstSeen),
						entity_type_id: convertedEntityType ? convertedEntityType.id : '',
						display_name: displayNameValue,
						entity_detection_src: !isEmpty(entityDetectionSrc) ? entityDetectionSrc : null,
						verdict: verdict ? verdict.id : '',
						remediation_status: remediationStatusTypeId || '',
						threatsKeys: threatsKeys,
						is_office_entity:
							rawData.InvestigationProvider === InvestigationProviderTypeEnum.OATP,
						investigation: { investigation_id: rawData.InvestigationId },
						_rawData: ev,
					});

					return res;
				}, [])
			);
		},
	})
	threatEntityTypes: Array<EvidenceEntity>;

	@EntityField({
		data: 'Alerts',
		arrayOf: Alert,
		parse: (alerts, rawData) => {
			return (
				alerts &&
				alerts.reduce((res: Array<{ [index: string]: any }>, alert) => {
					const severity = severityValues.find(val => val.type === SeverityType[alert.Severity]);
					const providerNameCapitalize = alert.ProviderName ? capitalize(alert.ProviderName) : '';

					const impactedEntities = {};
					const machines = [];
					const mailboxes = [];
					const users = [];

					if (alert.RelatedAssetsIds) {
						alert.RelatedAssetsIds.forEach(assetId => {
							if (rawData.Assets) {
								const asset = rawData.Assets.find(asset => asset.Urn === assetId);
								if (asset) {
									switch (asset.Type) {
										case AssetTypeEnum.host:
											machines.push({
												Name: asset.Name,
												MachineId: asset.Urn,
											});
											impactedEntities['Machines'] = machines;
											break;
										case AssetTypeEnum.user:
											users.push({});
											impactedEntities['Users'] = users;
											break;
										case AssetTypeEnum.mailbox:
											mailboxes.push({
												MailboxPrimaryAddress: asset.MailboxPrimaryAddress,
												Upn: asset.Upn,
												Urn: asset.Urn,
											});
											impactedEntities['Mailboxes'] = mailboxes;
											break;
									}
								}
							}
						});
					}

					const alertId =
						rawData.InvestigationProvider === InvestigationProviderTypeEnum.OATP
							? OFFICE_ALERT_ID_PREFIX + alert.ProviderAlertId
							: alert.ProviderAlertId;

					/*
						For office investigation we will get Investigation Status as a String
						and for MDATP investigation we will get it a int so we need to convert it to string
						In addition, temporary solution for office investigation - in case of status 'Failed' - convert to 'Terminated by system'
					 */
					const intInvestigationStatus = parseInt(rawData.Status);
					const investigationStatus = isNaN(intInvestigationStatus)
						? rawData.Status === InvestigationStatusNameEnum.Failed
							? InvestigationStatusNameEnum.TerminatedBySystem
							: rawData.Status
						: InvestigationStatusesMapping[intInvestigationStatus];

					const isFalsePositiveClassification = alert.Status === AlertV3StatusType.Dismissed;
					const convertedAlertStatus = AlertV3StatusTypeMapping[alert.Status];
					const statusId = AlertStatusTypeId[convertedAlertStatus];

					res.push({
						...alert,
						Status: statusId,
						Classification: isFalsePositiveClassification
							? AlertClassificationId[AlertClassificationType.FalsePositive]
							: undefined,
						DetectionSource: DetectionSourceType[alert.ProviderName],
						ProductSource: ServiceSourceType[providerNameCapitalize],
						Title: alert.AlertDisplayName,
						Severity: severity.id,
						AlertId: alertId,
						FirstEventTime: alert.StartTimeUtc,
						LastEventTime: alert.EndTimeUtc,
						FirstSeen: alert.TimeGenerated,
						InvestigationState: investigationStatus,
						InvestigationId: rawData.InvestigationId,
						Categories: [alert.Intent],
						IoaDefinitionId: alert.AlertType,
						AdditionalUxDescription: alert.Description,
						ImpactedEntities: impactedEntities,
						FormattedUxDescription: alert.Description,
					});
					return res;
				}, [])
			);
		},
	})
	alerts: Array<Alert>;

	@EntityField({
		data: 'AnalyzedEntities',
		parse: (entities: { [index: string]: number }) => {
			if (!isEmpty(entities)) {
				return Object.entries(entities)
					.filter(([k]) => AirsEntityType[k] && k !== AirsEntityTypeValue.Mailbox)
					.reduce((count, [_, v]) => count + v, 0);
			}

			return 0;
		},
	})
	// @ts-ignore shared between scc (useDefineForClassFields) and the old portal
	entityCount: number;

	@EntityField({
		data: 'AnalyzedEntities',
		arrayOf: AirsEntityTypeResults,
		parse: (analyzedEntities: Record<Partial<AirsEntityType>, number>, rawData) => {
			if (isEmpty(analyzedEntities)) {
				return;
			}

			const res = Object.entries(analyzedEntities)
				.filter(([k]) => k !== AirsEntityTypeValue.Mailbox)
				.reduce((res, [type, count]) => {
					const typeId = AirsEntityType[type];
					if (typeId !== undefined) {
						res.push({ data: [{ result: UNKNOWN, count: count }], type_id: typeId });
					}
					return res;
				}, []);

			const evidence: Array<{ [index: string]: any }> = rawData.Evidence;

			evidence &&
				evidence.forEach(e => {
					const convertedType = getConvertedEntityTypeName(e.Type);
					const typeId = AirsEntityType[convertedType];
					const verdictType = VerdictStatusType[e.LastVerdict];

					const lastRemediationProvider = getLastRemediationProvider(e.RemediationProviders);
					const remediationStatusTypeId = getRemediationStateId(
						e.LastRemediationState,
						lastRemediationProvider
					);

					// if entity has remediationStatus (failed/remediated) we will not
					// count according to the verdict - only according to the remediation status.
					let entityStatusId;
					if (remediationStatusTypeId) {
						entityStatusId = remediationStatusTypeId;
					} else {
						const statusByVerdict = airsEntityStatusValues.find(s => s.type === verdictType);
						entityStatusId = statusByVerdict && statusByVerdict.id;
					}

					const verdictId = entityStatusId || '';

					const entityTypeEntry = res.find(e => e.type_id === typeId);
					const verdictInEntityTypeEntry = entityTypeEntry.data.find(d => d.result === verdictId);
					if (verdictInEntityTypeEntry) {
						verdictInEntityTypeEntry.count += 1;
					} else {
						entityTypeEntry.data.push({ result: verdictId, count: 1 });
					}
					entityTypeEntry.data.find(d => d.result === UNKNOWN).count -= 1;
				});

			return res;
		},
	})
	entityResults: Array<AirsEntityTypeResults>;

	@EntityField({
		data: 'Evidence',
		parse: (evidence: Array<{ [index: string]: any }>) => {
			if (!evidence) {
				return {
					statusCount: [],
				};
			}

			const statusCountRes = evidence.reduce((res, currentEvidence) => {
				const remediationStatus = currentEvidence.LastRemediationState;

				if (!remediationStatus) {
					return res;
				}
				res[RemediationStatusType[remediationStatus]] =
					(res[RemediationStatusType[remediationStatus]] || 0) + 1;
				return res;
			}, {});

			if (statusCountRes) {
				return {
					statusCount: Object.entries(statusCountRes).map(([k, v]) => ({
						status: k,
						entityCount: v,
					})),
				};
			}

			return null;
		},
	})
	remediationEntities: RemediationThreatStatusSummary;

	@EntityField({ data: 'InvestigationProvider' })
	investigationProvider: InvestigationProviderTypeEnum;

	@EntityField({
		data: 'InvestigationProvider',
		parse: provider => provider === InvestigationProviderTypeEnum.OATP,
	})
	// @ts-ignore shared between scc (useDefineForClassFields) and the old portal
	isOfficeInvestigation: boolean;

	@EntityField({ data: 'CommentCount' })
	// @ts-ignore shared between scc (useDefineForClassFields) and the old portal
	commentCount: number;

	get duration(): number {
		return ((this.endDate || new Date()).valueOf() - this.startDate.valueOf()) / 1000;
	}

	currentPendingActions: Array<PendingAction>;
	shortInvestigationId: string | number;

	constructor(data) {
		super(data);
		this.alert = this.alerts && this.alerts.length ? this.alerts[0] : null;
		this.serviceSource = this.alerts && this.alerts.length ? this.alerts[0].serviceSource : null;

		this.currentPendingActions =
			this._allPendingActions &&
			this._allPendingActions.filter(
				action =>
					action.status.id === InvestigationActionStatusId.Pending ||
					action.status.id === InvestigationActionStatusId.WaitingForResource
			);

		this.pendingActions =
			this._allPendingActions &&
			this._allPendingActions.filter(
				action => !(this.currentPendingActions && this.currentPendingActions.includes(action))
			);

		this.pendingSince = this.getFirstPendingActionTime();
		this.attentionRequiredCount = this.currentPendingUserActions.length;

		// Investigation pendingType
		if (this.status && this.status.isPending) {
			this.pendingType =
				this.currentPendingActions && this.currentPendingActions.length
					? this.currentPendingActions[0].pendingType
					: null;
		}

		this.shortInvestigationId = OfficeUtils.getShortId(this.id);
	}

	get pendingUserActions(): Array<PendingAction> {
		return (this.pendingActions || []).filter(
			(pendingAction: PendingAction) =>
				(pendingAction.pendingType && pendingAction.pendingType.type) === PendingTypeEnum.User
		);
	}

	get pendingResourceActions(): Array<PendingAction> {
		return (this.pendingActions || []).filter(
			(pendingAction: PendingAction) =>
				(pendingAction.pendingType && pendingAction.pendingType.type) === PendingTypeEnum.Resource
		);
	}

	get currentPendingUserActions(): Array<PendingAction> {
		return (this.currentPendingActions || []).filter(
			(pendingAction: PendingAction) =>
				(pendingAction.pendingType && pendingAction.pendingType.type) === PendingTypeEnum.User
		);
	}
	get wasPendingUser(): boolean {
		return (
			this.wasPending &&
			this.pendingActions.some(
				pendingAction =>
					(pendingAction.pendingType && pendingAction.pendingType.type) === PendingTypeEnum.User
			)
		);
	}

	get wasPendingResource(): boolean {
		return (
			this.wasPending &&
			this.pendingActions.some(
				pendingAction =>
					(pendingAction.pendingType && pendingAction.pendingType.type) === PendingTypeEnum.Resource
			)
		);
	}

	get hasPendingActions(): boolean {
		if (!this.currentPendingActions) {
			return false;
		}

		return (
			this.currentPendingActions.filter(action => action.status && action.status.isPending).length > 0
		);
	}

	getFirstPendingActionTime(): Date {
		if (this.status && this.status.isPending && !isEmpty(this.currentPendingActions)) {
			this.currentPendingActions.sort(this.sortActionsByTime);
			return this.currentPendingActions[0].startTime;
		}

		return null;
	}

	sortActionsByTime(a: PendingAction, b: PendingAction) {
		if (a.startTime === b.startTime) return 0;
		return a.startTime > b.startTime ? 1 : -1;
	}
}

/**
 * This is a temporary conversion to convert office entity type case to airs entity type case.
 * We will not use it in the future
 */
export const OfficeEntityTypeValue = {
	url: { name: AirsEntityTypeValue.URL, id: AirsEntityType.URL },
	ip: { name: AirsEntityTypeValue.IP, id: AirsEntityType.IP },
	email: { name: AirsEntityTypeValue.Email, id: AirsEntityType.Email },
	mailCluster: { name: AirsEntityTypeValue.MailCluster, id: AirsEntityType.MailCluster },
	mailMessage: { name: AirsEntityTypeValue.MailMessage, id: AirsEntityType.MailMessage },
	mailbox: { name: AirsEntityTypeValue.Mailbox, id: AirsEntityType.Mailbox },
	SubmissionMail: { name: AirsEntityTypeValue.SubmissionMail, id: AirsEntityType.SubmissionMail },
	MailboxConfiguration: {
		name: AirsEntityTypeValue.MailboxConfiguration,
		id: AirsEntityType.MailboxConfiguration,
	},
	file: { name: AirsEntityTypeValue.File, id: AirsEntityType.File },
};

export function getConvertedEntityTypeName(entityType: string): string {
	return (
		(OfficeEntityTypeValue[entityType] && OfficeEntityTypeValue[entityType].name) ||
		convertEntityTypeCase(entityType) ||
		AirsEntityTypeValue[entityType]
	);
}

export function getLastThreatAnalysisSummary(threatAnalysisSummary) {
	const orderedThreatAnalysisSummary =
		threatAnalysisSummary && orderBy(threatAnalysisSummary, [ANALYSIS_DATE_FIELD], ['desc']);
	return orderedThreatAnalysisSummary && orderedThreatAnalysisSummary.length
		? orderedThreatAnalysisSummary[0]
		: {};
}

export function getThreatsNames(threatAnalysisSummary): Array<string> {
	const lastThreatAnalysisSummary = getLastThreatAnalysisSummary(threatAnalysisSummary);
	return lastThreatAnalysisSummary && lastThreatAnalysisSummary.AnalyzersResult
		? lastThreatAnalysisSummary.AnalyzersResult.map(
				analyzerResult => analyzerResult.ThreatIntelligence.ThreatName
		  )
		: [];
}

export function getLastRemediationProvider(remediationProviders) {
	const orderedRemediationProviders =
		remediationProviders && orderBy(remediationProviders, [REMEDIATION_DATE_FIELD], ['desc']);

	const lastRemediationProvider =
		(orderedRemediationProviders &&
			orderedRemediationProviders.length &&
			orderedRemediationProviders[0].RemediationProvider) ||
		{};
	return Object.assign({}, lastRemediationProvider, {
		ActionType: getConvertedOfficeActionType(lastRemediationProvider),
	});
}

export function getEngineReportData(threatAnalysisSummary) {
	const lastThreatAnalysisSummary = getLastThreatAnalysisSummary(threatAnalysisSummary);

	const analyzersResult = lastThreatAnalysisSummary && lastThreatAnalysisSummary.AnalyzersResult;
	if (analyzersResult && analyzersResult.length) {
		const orderedAnalyzersResult = orderBy(analyzersResult, [ANALYSIS_DATE_FIELD], ['desc']);
		const lastAnalyzerResult =
			(orderedAnalyzersResult && orderedAnalyzersResult.length && orderedAnalyzersResult[0]) || {};

		const engineReportStr: string = lastAnalyzerResult['EngineReport'];
		return engineReportStr && JSON.parse(engineReportStr);
	}

	return null;
}

export function getForwardingSmtpAddressValue(threatAnalysisSummary) {
	const engineReport = getEngineReportData(threatAnalysisSummary);
	const parameters = engineReport && engineReport.Parameters;
	const forwardingSmtpAddressParam =
		parameters &&
		parameters.find(param => param.ParameterName === EngineReportParameters.ForwardingSmtpAddress);
	return (forwardingSmtpAddressParam && forwardingSmtpAddressParam['ParameterValue']) || null;
}

export function getMailboxEntityCommonData(entity) {
	const engineReport = getEngineReportData(entity.ThreatAnalysisSummary);

	return {
		MailboxPrimaryAddress: entity.MailboxPrimaryAddress,
		FirstSeen: entity.FirstSeen,
		ForwardingSmtpAddress: getForwardingSmtpAddressValue(entity.ThreatAnalysisSummary),
		CreatedBy: (engineReport && engineReport.UserId) || null,
		Urn: entity.Urn,
	};
}

export function getRemediationStateId(
	originalRemediationState: string,
	remediationProvider: { [index: string]: any }
): number {
	const remediationStatusType = RemediationStatusType[originalRemediationState];

	if (remediationStatusType && remediationStatusType !== RemediationStatusType.Active) {
		return airsEntityRemediationStatuses.find(s => s.type === remediationStatusType).id;
	}

	if (isEmpty(remediationProvider)) return null;

	if (remediationProvider.ActionStatus === InvestigationActionStatusType.Running) {
		return RemediationStatusId.Running;
	}

	if (remediationProvider.ActionStatus === InvestigationActionStatusType.Pending) {
		// fix for bug 2589293- MtpBatch API doesn't update the action status immediately when an action is taken
		return PendingActionDecisionTypes[remediationProvider.ActionApproval] === PendingActionDecisionTypes.None
			? RemediationStatusId.PendingApproval
			: RemediationStatusId.Running;
	}

	if (PendingActionDecisionTypes[remediationProvider.ActionApproval] === PendingActionDecisionTypes.Declined) {
		return RemediationStatusId.Declined;
	}
}
