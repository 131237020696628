import { NgModule } from '@angular/core';
import { GlobalEntitiesModule } from '../../../global_entities/global-entities.module';
import { GlobalEntityPanelsModule } from '../../../global_entities/global-entity-panels.module';
import { SharedModule } from '../../../shared/shared.module';
import { DataViewsModule } from '../../../dataviews/dataviews.module';
import { GlobalEntityTypesService } from '../../../global_entities/services/global-entity-types.service';
import { PanelsModule } from '@wcd/panels';
import { AssessmentJobFieldsService } from './services/assessment-job.fields.service';
import { AuthenticatedScanResultFieldsService } from './services/authenticated-scan-result.fields.service';
import { AssessmentJobEntityTypeService } from './services/assessment-job.entity-type.service';
import { AuthenticatedScanResultEntityTypeService } from './services/authenticated-scan-result.entity-type.service';
import { AssessmentJobEditPanelComponent } from './components/assessment-job-edit-panel.component';
import { AssessmentJobEditComponent } from './components/assessment-job-edit.component';
import { SnmpAuthTypeComponent } from './components/auth/snmp-auth-type.component';
import { WindowsAuthTypeComponent } from './components/auth/windows-auth-type.component';
import { FabMessageBarModule, FabSpinnerModule } from '@angular-react/fabric';
import { AssessmentJobService } from './services/assessment-job.service';
import { WcdWizardModule } from '@wcd/wizard';
import { AssessmentJobReviewStepComponent } from './components/wizard-steps/assessment-job-review-step.component';
import { AssessmentJobJobDetailsStepComponent } from './components/wizard-steps/assessment-job-job-details-step.component';
import { AssessmentJobJobTypeStepComponent } from './components/wizard-steps/assessment-job-job-type-step.component';
import { AssessmentJobScanStepComponent } from './components/wizard-steps/assessment-job-scan-step.component';
import { AssessmentJobCompletedComponent } from './components/wizard-steps/assessment-job-completed.component';

@NgModule({
	imports: [
		SharedModule,
		FabMessageBarModule,
		GlobalEntitiesModule,
		GlobalEntityPanelsModule,
		DataViewsModule,
		PanelsModule,
		WcdWizardModule,
		FabSpinnerModule,
	],
	declarations: [
		AssessmentJobEditPanelComponent,
		AssessmentJobEditComponent,
		SnmpAuthTypeComponent,
		AssessmentJobJobTypeStepComponent,
		WindowsAuthTypeComponent,
		AssessmentJobJobDetailsStepComponent,
		AssessmentJobScanStepComponent,
		AssessmentJobReviewStepComponent,
		AssessmentJobCompletedComponent,
	],
	exports: [AssessmentJobEditPanelComponent, AssessmentJobEditComponent, SnmpAuthTypeComponent, WindowsAuthTypeComponent],
	entryComponents: [
		AssessmentJobEditPanelComponent,
		AssessmentJobEditComponent,
		SnmpAuthTypeComponent,
		AssessmentJobJobTypeStepComponent,
		WindowsAuthTypeComponent,
		AssessmentJobJobDetailsStepComponent,
		AssessmentJobScanStepComponent,
		AssessmentJobReviewStepComponent,
		AssessmentJobCompletedComponent,
	],
	providers: [
		AssessmentJobFieldsService,
		AuthenticatedScanResultFieldsService,
		AssessmentJobEntityTypeService,
		AuthenticatedScanResultEntityTypeService,
		AssessmentJobService,
	],
})
export class AuthenticatedScansEntityTypeModule {
	constructor(globalEntityTypesService: GlobalEntityTypesService) {
		globalEntityTypesService.registerEntityType(AssessmentJobEntityTypeService);
		globalEntityTypesService.registerEntityType(AuthenticatedScanResultEntityTypeService);
	}
}
