/* tslint:disable:template-accessibility-label-for */
import { ChangeDetectorRef, ElementRef, EventEmitter, OnInit } from '@angular/core';
import { NgForm } from '@angular/forms';
import { MachineGroup, NotificationFormat, NotificationRule, NotificationRuleCondition, NotificationRuleSeverityConditionValue, MdeUserRoleActionEnum, SendTestEmailApiCall, } from '@wcd/domain';
import { TabModel } from '../../../shared/components/tabs/tab.model';
import { Paris } from '@microsoft/paris';
import { combineLatest, Observable, of } from 'rxjs';
import { MachinesService } from '../../../@entities/machines/services/machines.service';
import { DialogsService } from '../../../dialogs/services/dialogs.service';
import { I18nService } from '@wcd/i18n';
import { AuthService } from '@wcd/auth';
import { FeaturesService, Feature, FlavorService } from '@wcd/config';
import { mergeMap, tap } from 'rxjs/operators';
import { cloneDeep, some } from 'lodash-es';
import { LiveAnnouncer } from '@angular/cdk/a11y';
import { AppFlavorConfig } from '@wcd/scc-common';
var ALERT_SEVERITY_RULE_CONFIG = {
    RuleType: 1,
    Values: [],
};
var SELECTABLE_MACHINE_GROUP_SCOPE_IDS = ['all', 'specific'];
var NotificationRuleEditComponent = /** @class */ (function () {
    function NotificationRuleEditComponent(paris, authService, dialogsService, i18nService, featuresService, flavorService, machinesService, liveAnnouncer, changeDetectorRef) {
        var _this = this;
        this.paris = paris;
        this.authService = authService;
        this.dialogsService = dialogsService;
        this.i18nService = i18nService;
        this.featuresService = featuresService;
        this.flavorService = flavorService;
        this.machinesService = machinesService;
        this.liveAnnouncer = liveAnnouncer;
        this.changeDetectorRef = changeDetectorRef;
        this.save = new EventEmitter();
        this.cancel = new EventEmitter();
        this.loadingMachineGroups = true;
        this.sendTestStatus = SendTestStatuses.notStarted;
        this.currentMachineGroups = [];
        this.availableMachineGroups = [];
        this.valuesLabelTexts = new Map();
        this.tabs = [
            {
                id: 'details',
                name: this.i18nService.get('notificationRules_editRule_generalTabName'),
            },
            {
                id: 'recipients',
                name: this.i18nService.get('notificationRules_editRule_recipientsTabName'),
            },
        ].map(function (tab) { return new TabModel(tab); });
        this.currentTab = this.tabs[0];
        this.focusOnRecipient = false;
        this.isDeviceScopeEnabled = this.flavorService.isEnabled(AppFlavorConfig.settings.deviceGroups);
        this.isUserAllowedActions = this.authService.currentUser.hasMdeAllowedUserRoleAction(MdeUserRoleActionEnum.securitySettings);
        this.selectableMachineGroupScopes = SELECTABLE_MACHINE_GROUP_SCOPE_IDS.map(function (scopeId) { return ({
            id: scopeId,
            name: _this.i18nService.get('notificationRules.machineGroups.' + scopeId),
        }); });
        this.alertSeverities = paris
            .getRepository(NotificationRuleSeverityConditionValue)
            .entity.values.map(function (conditionValue) { return ({
            id: conditionValue.id,
            name: _this.i18nService.get('notificationRules_conditions_alertSeverity_' + conditionValue.id),
        }); });
        this.notificationRulesRepo = paris.getRepository(NotificationRule);
        this.machineGroupsRepo = paris.getRepository(MachineGroup);
        this.notificationFormatsRepo = paris.getRepository(NotificationFormat);
        this.notificationFormats = this.notificationFormatsRepo.entity.values.filter(function (format) { return !format.hidden; });
        this.getNotificationFormatLabel = this.getNotificationFormatLabel.bind(this);
    }
    Object.defineProperty(NotificationRuleEditComponent.prototype, "machineGroupsFieldId", {
        get: function () {
            return 'notification-rule-machine-groups';
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(NotificationRuleEditComponent.prototype, "SendStatuses", {
        get: function () {
            return SendTestStatuses;
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(NotificationRuleEditComponent.prototype, "isDirty", {
        get: function () {
            return this.notificationRuleForm.form.dirty;
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(NotificationRuleEditComponent.prototype, "isValid", {
        get: function () {
            return (this.editedRule.name &&
                this.editedRule.recipients.length &&
                this.isGroupsValid &&
                this.isConditionsValid);
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(NotificationRuleEditComponent.prototype, "isSendTestEmailDisabled", {
        get: function () {
            return this.editedRule.readonly || !this.isUserAllowedActions || !this.isValid || (this.notificationRuleForm && !this.notificationRuleForm.form.valid);
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(NotificationRuleEditComponent.prototype, "isGroupsValid", {
        get: function () {
            return ((this.currentMachineGroupScope && this.currentMachineGroupScope.id === 'all') ||
                !!this.editedRule.machineGroups.length);
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(NotificationRuleEditComponent.prototype, "isConditionsValid", {
        get: function () {
            return this.editedRule.conditions.length && !some(this.editedRule.conditions, { isValid: false });
        },
        enumerable: true,
        configurable: true
    });
    NotificationRuleEditComponent.prototype.ngOnInit = function () {
        var _this = this;
        combineLatest(this.setRule(), this.setMachineGroups()).subscribe(function () {
            _this._setCurrentMachineGroups(_this.editedRule.machineGroups);
        });
        this.createRuleMode = !this.rule;
    };
    NotificationRuleEditComponent.prototype.getNotificationFormatLabel = function (format) {
        return this.i18nService.get('notificationRules.formatOptions.' + format.name);
    };
    NotificationRuleEditComponent.prototype.setMachineGroups = function () {
        var _this = this;
        return this.machineGroupsRepo.allItems$.pipe(tap(function (groups) {
            _this.loadingMachineGroups = false;
            _this.allMachineGroups = groups;
            _this._setAvailableScopes();
        }), mergeMap(function () { return _this._getUserExposedRbacGroups(); }));
    };
    NotificationRuleEditComponent.prototype.setRule = function () {
        var _this = this;
        return new Observable(function (observer) {
            if (_this.rule) {
                _this.editedRule = cloneDeep(_this.rule);
                _this._setCurrentScope();
                _this.editedRule.conditions.forEach(function (condition) {
                    condition.values = condition.values.map(function (conditionValue) { return ({
                        id: conditionValue.id,
                        name: _this.i18nService.get('notificationRules_conditions_alertSeverity_' + conditionValue.id),
                    }); });
                    _this.setLabelText(condition.type.name, condition.values, 'conditions.' + condition.type.typeName);
                });
                observer.next();
                observer.complete();
            }
            else {
                _this.editedRule = _this.notificationRulesRepo.createNewItem();
                _this.editedRule.formatFlavor = _this.notificationFormats.filter(function (format) { return format.defaultValue; });
                _this.paris
                    .getRepository(NotificationRuleCondition)
                    .createItem(ALERT_SEVERITY_RULE_CONFIG)
                    .subscribe(function (alertSeverityRule) {
                    _this.editedRule.conditions.push(alertSeverityRule);
                    observer.next();
                    observer.complete();
                });
            }
        });
    };
    NotificationRuleEditComponent.prototype.saveRule = function () {
        var _this = this;
        this.isSaving = true;
        this.notificationRulesRepo.save(this.editedRule).subscribe(function () {
            _this.isSaving = false;
            _this.save.emit(_this.editedRule);
        }, function (error) {
            _this.isSaving = false;
            _this.dialogsService.showError({
                title: _this.i18nService.get('notificationRules_error_failed_' + _this.editedRule.id ? 'update' : 'create'),
                data: error,
            });
        });
    };
    NotificationRuleEditComponent.prototype.sendTestEmail = function () {
        var _this = this;
        // TODO: remove isSendTestEmailValid check from here after fab-button issue fixed. (clickable when disabled)
        if (!this.isSendTestEmailDisabled) {
            this.sendTestStatus = SendTestStatuses.pending;
            this.paris.apiCall(SendTestEmailApiCall, this.editedRule).subscribe(function () {
                _this.sendTestStatus = SendTestStatuses.success;
            }, function (error) {
                _this.sendTestStatus = SendTestStatuses.error;
            });
            this.changeDetectorRef.detectChanges();
        }
    };
    NotificationRuleEditComponent.prototype.getLabelText = function (fieldId, selectAllLabelKey) {
        return (this.valuesLabelTexts.get(fieldId) ||
            this.i18nService.get("notificationRules." + (selectAllLabelKey ? selectAllLabelKey + '.' : '') + "selectValues"));
    };
    NotificationRuleEditComponent.prototype.setLabelText = function (fieldId, values, selectAllLabelKey) {
        var labelText = this._setLabelText(values, selectAllLabelKey), currentValue = this.valuesLabelTexts.get(fieldId);
        if (!currentValue || currentValue !== labelText)
            this.valuesLabelTexts.set(fieldId, labelText);
    };
    NotificationRuleEditComponent.prototype.onMachineGroupsChange = function (selectedMachineGroups) {
        var _this = this;
        if (!selectedMachineGroups || !selectedMachineGroups.length)
            this._setCurrentMachineGroups((this.editedRule.machineGroups = []));
        else {
            of(selectedMachineGroups)
                .pipe(mergeMap(function (_selectedMachineGroups) {
                return combineLatest(_selectedMachineGroups.map(function (selectedGroup) {
                    return _this.machineGroupsRepo.getItemById(selectedGroup.id);
                }));
            }))
                .subscribe(function (machineGroups) {
                _this._setCurrentMachineGroups((_this.editedRule.machineGroups = machineGroups));
            });
        }
    };
    NotificationRuleEditComponent.prototype.onMachineGroupScopeChange = function (selectedScope) {
        if (selectedScope.id === 'all' &&
            this.editedRule.machineGroups &&
            this.editedRule.machineGroups.length)
            this.editedRule.machineGroups = this.currentMachineGroups = [];
    };
    NotificationRuleEditComponent.prototype.add = function (email) {
        this.updateEmail(email);
        this.emailEl.nativeElement.focus();
        this.notificationRuleForm.form.markAsDirty();
    };
    NotificationRuleEditComponent.prototype.updateEmail = function (email) {
        var emailExists = ~this.editedRule.recipients.indexOf(email);
        if (!emailExists) {
            this.editedRule.recipients.splice(0, 0, email);
            this.announceEmailListUpdated();
        }
        ;
        this.currentEmail = '';
    };
    NotificationRuleEditComponent.prototype.removeEmail = function (email) {
        var emailIndex = this.editedRule.recipients.indexOf(email);
        if (~emailIndex) {
            this.editedRule.recipients.splice(emailIndex, 1);
            this.announceEmailListUpdated();
        }
        ;
    };
    NotificationRuleEditComponent.prototype.setCurrentTab = function (tab) {
        var _this = this;
        if (this.currentTab !== tab) {
            this.currentTab = tab ? tab : this.currentTab === this.tabs[0] ? this.tabs[1] : this.tabs[0];
            this.changeDetectorRef.detectChanges();
            setTimeout(function () {
                _this.focusOnRecipient = _this.currentTab !== _this.tabs[0];
            });
        }
    };
    NotificationRuleEditComponent.prototype._setAvailableScopes = function () {
        if (this.allMachineGroups.length) {
            if (this.authService.currentUser.isMdeAdmin ||
                (this.editedRule && (this.editedRule.readonly || !this.isUserAllowedActions))) {
                this.allowAllMachineGroups = this.allowSpecificMachineGroups = true;
            }
            else {
                this.allowSpecificMachineGroups = true;
            }
        }
        else
            this.currentMachineGroupScope = this.selectableMachineGroupScopes.find(function (scope) { return scope.id === 'all'; });
    };
    NotificationRuleEditComponent.prototype._setLabelText = function (values, selectAllLabelKey) {
        if (values.length > 3) {
            return this.i18nService.get('notificationRules.editRule.manyValues', { length: values.length });
        }
        var _values = values.map(function (value) { return value.name; });
        if (_values.length)
            return _values.join(', ');
        return this.i18nService.get("notificationRules." + (selectAllLabelKey ? selectAllLabelKey + '.' : '') + "selectValues");
    };
    NotificationRuleEditComponent.prototype._setCurrentScope = function () {
        this.currentMachineGroupScope =
            this.editedRule.machineGroups && this.editedRule.machineGroups.length
                ? this.selectableMachineGroupScopes.find(function (scope) { return scope.id === 'specific'; })
                : this.selectableMachineGroupScopes.find(function (scope) { return scope.id === 'all'; });
    };
    NotificationRuleEditComponent.prototype._getUserExposedRbacGroups = function () {
        var _this = this;
        return this.machinesService.getFullUserExposedMachineGroups().pipe(tap(function (userExposedMachineGroups) {
            _this.availableMachineGroups = _this.allMachineGroups
                .filter(function (machineGroup) {
                return (!_this.featuresService.isEnabled(Feature.RbacMachineGroups) ||
                    (_this.editedRule && (_this.editedRule.readonly || !_this.isUserAllowedActions)) ||
                    some(userExposedMachineGroups, { id: machineGroup.id }));
            })
                .map(function (machineGroup) { return _this._getMachineGroupCheckListValue(machineGroup); });
        }));
    };
    NotificationRuleEditComponent.prototype._setCurrentMachineGroups = function (machineGroups) {
        var _this = this;
        this.currentMachineGroups = machineGroups.map(function (machineGroup) {
            return _this._getMachineGroupCheckListValue(machineGroup);
        });
        this.setLabelText(this.machineGroupsFieldId, this.currentMachineGroups, 'machineGroups');
    };
    NotificationRuleEditComponent.prototype._getMachineGroupCheckListValue = function (machineGroup) {
        return {
            id: machineGroup.id,
            name: machineGroup.isUnassignedMachineGroup
                ? this.i18nService.get('machineGroup.unassignedGroup.name')
                : machineGroup.name,
        };
    };
    NotificationRuleEditComponent.prototype.announceEmailListUpdated = function () {
        this.liveAnnouncer.announce(this.i18nService.get('notificationRules.ruleEmails.updated'), 'assertive', 300);
    };
    return NotificationRuleEditComponent;
}());
export { NotificationRuleEditComponent };
var SendTestStatuses;
(function (SendTestStatuses) {
    SendTestStatuses[SendTestStatuses["notStarted"] = 0] = "notStarted";
    SendTestStatuses[SendTestStatuses["pending"] = 1] = "pending";
    SendTestStatuses[SendTestStatuses["success"] = 2] = "success";
    SendTestStatuses[SendTestStatuses["error"] = 3] = "error";
})(SendTestStatuses || (SendTestStatuses = {}));
