var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (b.hasOwnProperty(p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
import { OnDestroy, OnInit } from '@angular/core';
import { CyberEvent, MdeUserRoleActionEnum, MachineTimelinePrefetchApiCall, EasyTrialStatus, } from '@wcd/domain';
import { EntityComponentBase } from '../../../global_entities/components/entity-contents.component.base';
import { I18nService } from '@wcd/i18n';
import { TabModel } from '../../../shared/components/tabs/tab.model';
import { Feature, FeaturesService, FlavorService, TvmLicensesAngularService } from '@wcd/config';
import { ActivatedRoute, Router } from '@angular/router';
import { MachinesService } from '../services/machines.service';
import { filter } from 'rxjs/operators';
import { Paris } from '@microsoft/paris';
import { PreferencesService } from '@wcd/config';
import { TIME_RANGE_DEFAULT_PREFERENCE_ID } from '../../../entity-events-timeline/components/entity-events-timeline/entity-events-timeline.component';
import { TimeRangesService } from '../../../shared/services/time-ranges.service';
import { isCustomTimeRangeValue } from '@wcd/date-time-picker';
import { AuthService } from '@wcd/auth';
import { OperatingSystemPlatformCategories, ExpeditePollingMachineApiCall } from '@wcd/domain';
import { MachineReportService } from '../services/machine.report.service';
import { TabsComponent } from '../../../shared/components/tabs/tabs.component';
import { CyberEventsUtilsService } from '../../cyber_events/services/cyber-events-utils.service';
import { AppInsightsService } from 'app/insights/services/app-insights.service';
import { sccHostService } from '@wcd/scc-interface';
import { AppFlavorConfig, TvmLicenseType } from '@wcd/scc-common';
import { TvmTrialStatus, TvmPremiumTrialOffer, TvmStandaloneTrialOffer, TvmPremiumTrialPublicPreviewOffer, TvmStandaloneTrialPublicPreviewOffer, licenseToRoleMap, getStatusFromProduct, } from './tvm-licenses-utils';
var MachineEntityComponent = /** @class */ (function (_super) {
    __extends(MachineEntityComponent, _super);
    function MachineEntityComponent(i18nService, machinesService, router, route, preferencesService, timeRangesService, paris, featuresService, authService, machineReportService, appInsightsService, tvmLicensesService, flavorService) {
        var _this = _super.call(this) || this;
        _this.i18nService = i18nService;
        _this.machinesService = machinesService;
        _this.router = router;
        _this.route = route;
        _this.preferencesService = preferencesService;
        _this.timeRangesService = timeRangesService;
        _this.paris = paris;
        _this.featuresService = featuresService;
        _this.authService = authService;
        _this.machineReportService = machineReportService;
        _this.appInsightsService = appInsightsService;
        _this.tvmLicensesService = tvmLicensesService;
        _this.flavorService = flavorService;
        _this.tabs = [];
        _this.isScc = sccHostService.isSCC;
        _this.extraDeviceDetailsTabs = flavorService.isEnabled(AppFlavorConfig.devices.extraDeviceDetailsTabs);
        _this.isTimelineEnabled = flavorService.isEnabled(AppFlavorConfig.devices.isTimelineEnabled);
        _this.isReactTimelineEnabled = featuresService.isEnabled(Feature.ReactMachineTimeline);
        _this.isPolicyTroubleshootingForDeviceEnable =
            featuresService.isEnabled(Feature.EnablePolicyTroubleshootingForDevice) &&
                flavorService.isEnabled(AppFlavorConfig.routes.policyManagement);
        _this.machineEventsRepo = _this.paris.getRepository(CyberEvent);
        _this.featuresChangeSubscription = _this.featuresService.featureChanged$
            .pipe(filter(function (_a) {
            var featureId = _a.featureId;
            return featureId === Feature.UpgradeMachinePage;
        }))
            .subscribe(function () {
            if (!featuresService.isEnabled(Feature.UpgradeMachinePage)) {
                var params = _this.route.snapshot.queryParams;
                _this.router.navigateByUrl(_this.machinesService.getLegacyMachineLink(_this.machine.machineId, new Date((params && params['to']) || Date.now())));
            }
        });
        _this.hasMdeLicenseWorkloads = flavorService.isEnabled(AppFlavorConfig.settings.mdeWithWorkloads);
        _this.isTvmPublicPreviewTrialEnabled = featuresService.isEnabled(Feature.TvmPremiumTrialPublicPreview);
        _this.shouldShowTvmAdvancedFeaturesTab();
        return _this;
    }
    Object.defineProperty(MachineEntityComponent.prototype, "machine", {
        get: function () {
            return this.entity;
        },
        enumerable: true,
        configurable: true
    });
    MachineEntityComponent.prototype.ngOnInit = function () {
        this.tabs = this.getTabs();
        this.warmUpBackend();
        this.report = this.machineReportService.getModel(this.entity);
        // fire a resize event in order to inform tabs content (specifically timeline that contains timebar picker) they need to be full screened
        if (this.isFullScreen)
            setTimeout(function () {
                window.dispatchEvent(new Event('resize'));
            }, 100);
        //Adding overflow scroll for Scc pages
        var currentTab = this.router.url
            .split('?')[0]
            .split('/')
            .pop();
        this.shouldAddOverflowScroll = this.checkIfShouldAddOverflowScroll(currentTab);
    };
    MachineEntityComponent.prototype.ngAfterViewInit = function () {
        this.appInsightsService.trackEvent('device-page-load', {
            onboardingStatus: this.machine ? this.machine.onboardingStatus : '',
        });
    };
    MachineEntityComponent.prototype.ngOnDestroy = function () {
        this.featuresChangeSubscription && this.featuresChangeSubscription.unsubscribe();
    };
    MachineEntityComponent.prototype.onActivate = function () {
        /*
        Select the right tab, when navigating
        */
        if (this.tabsComponent && this.tabs) {
            var id_1 = this.router.url
                .split('?')[0]
                .split('/')
                .pop();
            this.tabsComponent.selectTab(this.tabs.find(function (x) { return x.id.toLowerCase() === id_1 || x.id === id_1; }));
            this.shouldAddOverflowScroll = this.checkIfShouldAddOverflowScroll(id_1);
        }
    };
    MachineEntityComponent.prototype.getTabs = function () {
        var _this = this;
        var tabs = [];
        if (this.hasMdeLicenseWorkloads) {
            tabs.push({
                id: 'main',
                name: this.i18nService.get(this.featuresService.isEnabled(Feature.IncidentsPhase2)
                    ? 'incidents.title'
                    : 'machines.tabs.alerts'),
                routerLink: './main',
                disabled: !this.authService.currentUser.hasMdeAllowedUserRoleAction(MdeUserRoleActionEnum.viewData),
            });
        }
        if (this.machine.aadDeviceId && this.isPolicyTroubleshootingForDeviceEnable) {
            tabs.push({
                id: 'machine-configuration-troubleshooting',
                name: this.i18nService.get('machines.tabs.configuration'),
                routerLink: './configuration',
            });
        }
        if (this.machine.senseMachineId) {
            if (this.hasMdeLicenseWorkloads && this.isTimelineEnabled) {
                tabs.push({
                    id: 'timeline',
                    name: this.i18nService.get('machines.tabs.timeline'),
                    routerLink: './timeline',
                    disabled: !this.authService.currentUser.hasMdeAllowedUserRoleAction(MdeUserRoleActionEnum.viewData),
                });
                if (this.isReactTimelineEnabled) {
                    tabs.push({
                        id: 'timeline2',
                        name: this.i18nService.get('machines.tabs.timeline.react'),
                        routerLink: './timeline2',
                        disabled: !this.authService.currentUser.hasMdeAllowedUserRoleAction(MdeUserRoleActionEnum.viewData),
                    });
                }
            }
            if ((this.extraDeviceDetailsTabs || this.shouldShowTvmBasicTab()) &&
                this.featuresService.isEnabled(Feature.TvmPages) &&
                this.machinesService.supportTvmTabs(this.entity)) {
                tabs.push({
                    id: 'recommendations',
                    name: this.i18nService.get('machines.tabs.recommendations'),
                    routerLink: './recommendations',
                    routerLinkQueryParams: {
                        filters: 'status=Active',
                    },
                    shouldForceActive: function () {
                        return (_this.router.url
                            .split('?')[0]
                            .split('/')
                            .pop() === 'recommendations');
                    },
                }, {
                    id: 'software-inventory',
                    name: this.i18nService.get('machines.tabs.softwareInventory'),
                    routerLink: './software-inventory',
                    routerLinkQueryParams: {
                        ordering: '-isNormalized',
                    },
                });
                if (this.shouldShowTvmPremiumTab(Feature.TvmBrowserExtensions)) {
                    tabs.push({
                        id: 'extensions',
                        name: this.i18nService.strings.machines_tabs_extensionsInventory,
                        routerLink: './extensions',
                    });
                }
                tabs.push({
                    id: 'vulnerabilities',
                    name: this.i18nService.get('machines.tabs.vulnerabilities'),
                    routerLink: './vulnerabilities',
                });
                if (this.shouldShowMissingKbsTab()) {
                    tabs.push({
                        id: 'missingkbs',
                        name: this.machine.os.platform.category === OperatingSystemPlatformCategories.Linux
                            ? this.i18nService.strings.tvm_common_securityBulletins
                            : this.i18nService.strings.machines_tabs_missingKbs,
                        routerLink: './missingKbs',
                    });
                }
                if (this.shouldShowBaselineTab()) {
                    tabs.push({
                        id: 'baseline-compliance',
                        name: this.i18nService.strings.tvm_baseline_title,
                        routerLink: './baseline-compliance',
                    });
                }
                if (this.shouldShowTvmPremiumTab(Feature.TvmCertificateAssessment)) {
                    tabs.push({
                        id: 'certificate-inventory',
                        name: this.i18nService.strings.machines_tabs_certificateInventory,
                        routerLink: './certificate-inventory',
                    });
                }
                if (this.tvmTrialStatus == TvmTrialStatus.preTrial ||
                    // this.tvmTrialStatus == TvmTrialStatus.nonEasyPreTrial ||
                    this.tvmTrialStatus == TvmTrialStatus.tvmStandalonePreTrial
                // || this.tvmTrialStatus == TvmTrialStatus.tvmStandaloneNonEasyPreTrial
                ) {
                    tabs.push({
                        id: 'advanced-features',
                        name: this.i18nService.strings.machines_tabs_AdvancedFeatures,
                        routerLink: './advanced-features',
                    });
                }
            }
        }
        tabs.unshift({
            id: 'overview',
            name: this.i18nService.get('machines.tabs.overview'),
            routerLink: './overview',
        });
        return tabs.map(function (tab) { return new TabModel(tab); });
    };
    // Send a dummy call to make sure that this machine is fetched to backend cache, in order to speed up timeline first load.
    MachineEntityComponent.prototype.warmUpBackend = function () {
        var _this = this;
        var rangeId = this.preferencesService.getPreference(TIME_RANGE_DEFAULT_PREFERENCE_ID) || 'month';
        var duration = this.timeRangesService.all.find(function (range) { return range.id === rangeId; });
        var to = moment()
            .utc()
            .toDate();
        var from = moment()
            .utc()
            .subtract((duration && !isCustomTimeRangeValue(duration) && duration.value) || 30, 'days')
            .toDate();
        var dataQuery = {
            where: {
                machineId: this.machine.machineId,
                machineDnsName: this.machine.name,
                entityType: 'machines',
                entityId: this.machine.id,
                lastSeen: this.machine.lastSeen.toISOString(),
                SenseClientVersion: this.machine.senseClientVersion,
                fromDate: from.toISOString(),
                toDate: to.toISOString(),
                pageSize: 200,
                useCyberData: CyberEventsUtilsService.shouldTimelineUseOneCyber(this.featuresService),
                generateIdentityEvents: this.featuresService.isEnabled(Feature.MachineTimelineGenerateMdiEvents),
            },
        };
        if (this.isTimelineEnabled) {
            if (CyberEventsUtilsService.shouldPrefetchTimeline(this.featuresService)) {
                this.paris.apiCall(MachineTimelinePrefetchApiCall, dataQuery).subscribe();
            }
            else {
                this.machineEventsRepo.query(dataQuery).subscribe();
            }
        }
        if (CyberEventsUtilsService.shouldWarmUpResponse(this.featuresService)) {
            setTimeout(function () {
                try {
                    _this.paris.apiCall(ExpeditePollingMachineApiCall, _this.machine).subscribe();
                }
                catch (_a) {
                    // handle case where user left machine page
                }
            }, 60000);
        }
    };
    // Missing KB's should be displayed for windows machines or Linux machines (will be called Security Bulletins in that case)
    MachineEntityComponent.prototype.shouldShowMissingKbsTab = function () {
        return (((!this.featuresService.isEnabled(Feature.TvmPremium) || this.shouldShowTvmBasicTab()) &&
            ((this.machine.isManagedByMdatp || !this.machine.isMdatp) &&
                this.featuresService.isEnabled(Feature.TvmMachineMissingKbs) &&
                this.machine.os.platform.id !== OperatingSystemPlatformCategories.macOS &&
                this.machine.os.platform.category !== OperatingSystemPlatformCategories.Android &&
                this.machine.os.platform.category !== OperatingSystemPlatformCategories.iOS)) ||
            this.machine.os.platform.category === OperatingSystemPlatformCategories.Linux);
    };
    MachineEntityComponent.prototype.shouldShowBaselineTab = function () {
        return (this.shouldShowTvmPremiumTab(Feature.TvmBaselineCompliance) &&
            (this.machine.isManagedByMdatp || !this.machine.isMdatp) &&
            ([
                OperatingSystemPlatformCategories.Windows11,
                OperatingSystemPlatformCategories.Windows10,
                OperatingSystemPlatformCategories.WindowsServer2022,
                OperatingSystemPlatformCategories.WindowsServer2019,
                OperatingSystemPlatformCategories.WindowsServer2016,
                OperatingSystemPlatformCategories.WindowsServer2012R2,
                OperatingSystemPlatformCategories.WindowsServer2008R2,
            ].includes(this.machine.os.platform.id) ||
                (this.shouldShowTvmPremiumTab(Feature.TvmBaselineComplianceForLinux) &&
                    this.machine.os.platform.category === OperatingSystemPlatformCategories.Linux)));
    };
    MachineEntityComponent.prototype.shouldShowTvmPremiumTab = function (feature) {
        return (this.isScc &&
            this.featuresService.isEnabled(feature) &&
            this.tvmLicensesService.isEnabled(TvmLicenseType.TvmPremium));
    };
    MachineEntityComponent.prototype.shouldShowTvmBasicTab = function () {
        return (this.tvmLicensesService.isEnabled(TvmLicenseType.TvmBasic) ||
            // TODO elkamin: delete this code once the nibiru code is in
            this.flavorService.isEnabled({ mdeFlavors: ['Smb'] }));
    };
    MachineEntityComponent.prototype.shouldShowTvmAdvancedFeaturesTab = function () {
        var _this = this;
        if (!this.isScc) {
            this.tvmTrialStatus = TvmTrialStatus.none;
            return;
        }
        sccHostService.ajax
            .request({
            method: 'get',
            url: "<di>/Find/TrialOffer",
            params: {
                tenantid: sccHostService.loginUser.tenantId,
                Filter: "Sku eq " + (this.isTvmPublicPreviewTrialEnabled
                    ? TvmPremiumTrialPublicPreviewOffer
                    : TvmPremiumTrialOffer),
            },
        })
            .then(function (tvmPremiumEasyTrialAxiosResponse) {
            if (_this.tvmLicensesService.isEnabled(TvmLicenseType.TvmPremium)) {
                _this.tvmTrialStatus = TvmTrialStatus.premium;
                return;
            }
            sccHostService.auth.isInRole('IsAadCompanyAdmin').then(function (isGlobalAdmin) {
                sccHostService.auth
                    .isInRoles(licenseToRoleMap.get(TvmLicenseType.TvmPremium))
                    .then(function (roles) {
                    var tvmPremiumEasyTrialResponse = tvmPremiumEasyTrialAxiosResponse.data;
                    var tvmPremiumStatus = getStatusFromProduct(tvmPremiumEasyTrialResponse);
                    // Show pre trial experience only if:
                    // 1) TvmPremiumTrial feature or TvmPremiumTrialPublicPreview feature is enabled
                    // 2) Eligible to TvmPremium trial according to roles
                    // 3) According to EasyTrial API, the tenant can start trial for TvmPremium
                    if ((_this.featuresService.isEnabled(Feature.TvmPremiumTrial) ||
                        _this.featuresService.isEnabled(Feature.TvmPremiumTrialPublicPreview)) &&
                        roles.includes(true) &&
                        tvmPremiumStatus == EasyTrialStatus.PreTrial) {
                        _this.tvmTrialStatus = TvmTrialStatus.preTrial;
                        _this.tabs = _this.getTabs();
                    }
                    // Show pre trial non-easy trial experience only if:
                    // 1) TvmPremiumTrial feature and TvmPremiumTrialPublicPreview feature is disabled
                    // 2) TvmPremiumTrialNonEasyTrial feature is enabled
                    // 3) According to EasyTrial API, the tenant can start trial for TvmPremium
                    // 4) Has global admin permission
                    else if (!(_this.featuresService.isEnabled(Feature.TvmPremiumTrial) ||
                        _this.featuresService.isEnabled(Feature.TvmPremiumTrialPublicPreview)) &&
                        _this.featuresService.isEnabled(Feature.TvmPremiumTrialNonEasyTrial) &&
                        tvmPremiumStatus == EasyTrialStatus.PreTrial &&
                        isGlobalAdmin) {
                        _this.tvmTrialStatus = TvmTrialStatus.nonEasyPreTrial;
                        _this.tabs = _this.getTabs();
                    }
                    else {
                        sccHostService.ajax
                            .request({
                            method: 'get',
                            url: "<di>/Find/TrialOffer",
                            params: {
                                tenantid: sccHostService.loginUser.tenantId,
                                Filter: "Sku eq " + (_this.isTvmPublicPreviewTrialEnabled
                                    ? TvmStandaloneTrialPublicPreviewOffer
                                    : TvmStandaloneTrialOffer),
                            },
                        })
                            .then(function (tvmStandaloneEasyTrialAxiosResponse) {
                            sccHostService.auth
                                .isInRoles(licenseToRoleMap.get(TvmLicenseType.TvmBasic))
                                .then(function (standaloneRoles) {
                                var tvmStandaloneEasyTrialResponse = tvmStandaloneEasyTrialAxiosResponse.data;
                                var tvmStandaloneStatus = getStatusFromProduct(tvmStandaloneEasyTrialResponse);
                                // Show pre trial experience for standalone only if:
                                // 1) TvmPremiumTrial feature is enabled or TvmPremiumTrialPublicPreview feature is enabled
                                // 2) Eligible to TvmStandalone trial according to roles
                                // 3) According to EasyTrial API, the tenant can start trial for TvmStandalone
                                // 4) According to TvmLicenses Service, TvmBasic disabled
                                if ((_this.featuresService.isEnabled(Feature.TvmPremiumTrial) ||
                                    _this.featuresService.isEnabled(Feature.TvmPremiumTrialPublicPreview)) &&
                                    standaloneRoles.includes(true) &&
                                    tvmStandaloneStatus == EasyTrialStatus.PreTrial &&
                                    !_this.tvmLicensesService.isEnabled(TvmLicenseType.TvmBasic)) {
                                    _this.tvmTrialStatus =
                                        TvmTrialStatus.tvmStandalonePreTrial;
                                    _this.tabs = _this.getTabs();
                                }
                                // Show standalone pre trial non-easy trial experience only if:
                                // 1) TvmPremiumTrial feature and TvmPremiumTrialPublicPreview feature is disabled
                                // 2) TvmPremiumTrialNonEasyTrial feature is enabled
                                // 3) According to EasyTrial API, the tenant can start trial for TvmStandalone
                                // 4) Has global admin permission
                                else if (!(_this.featuresService.isEnabled(Feature.TvmPremiumTrial) ||
                                    _this.featuresService.isEnabled(Feature.TvmPremiumTrialPublicPreview)) &&
                                    _this.featuresService.isEnabled(Feature.TvmPremiumTrialNonEasyTrial) &&
                                    tvmStandaloneStatus == EasyTrialStatus.PreTrial &&
                                    isGlobalAdmin) {
                                    _this.tvmTrialStatus =
                                        TvmTrialStatus.tvmStandaloneNonEasyPreTrial;
                                    _this.tabs = _this.getTabs();
                                }
                            });
                        })
                            .catch(function () {
                            _this.tvmTrialStatus = TvmTrialStatus.none;
                        });
                    }
                });
            });
        })
            .catch(function () {
            _this.tvmTrialStatus = TvmTrialStatus.none;
        });
    };
    MachineEntityComponent.prototype.checkIfShouldAddOverflowScroll = function (currentTab) {
        return (this.isScc &&
            (currentTab === 'recommendations' ||
                currentTab === 'software-inventory' ||
                currentTab === 'vulnerabilities' ||
                currentTab === 'missingKbs' ||
                currentTab === 'certificate-inventory' ||
                currentTab === 'extensions'));
    };
    return MachineEntityComponent;
}(EntityComponentBase));
export { MachineEntityComponent };
